import { EtSession } from 'src/graphql/models/User';

export const getUserETSessionStatus = (
  etSessions?: EtSession[] | null
): string => {
  if (etSessions && etSessions.length && etSessions.some((s) => s.complete)) {
    return 'Complete';
  }
  else if (etSessions && etSessions.length) {
    return 'Started';
  }
  else {
    return 'Not Started';
  }
};
