import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteProps, useLocation } from 'react-router-dom';
import { useHingeHealthSecurityContext } from '../../components/auth-shared';
import Spinner from '../../components/spinner/Spinner';
import { DispatchThunk } from '../../redux/actions';
import { actionCreators } from '../../redux/actions/auth';
import { RootState } from '../../redux/reducers';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';
import { redirectCttToCtp } from '../../features';
import { getCareTeamProductsAppUrl } from '../../constant';

type Props = RouteProps;

const OktaRoute = ( props: Props ) => {
  const location = useLocation();
  const { hingeHealthAuthClient, hingeHealthAuthState } = useHingeHealthSecurityContext();
  const dispatch: DispatchThunk = useDispatch();
  const [renderRoute, setRenderRoute] = React.useState(false);
  const [isRedirectChecked, setIsRedirectChecked] = React.useState(false);

  const isRedirectCttToCtpEnabled = useSelector(
    (state: RootState) => selectTreatmentValue(state.splitReducer, redirectCttToCtp) === 'on'
  );

  const isSplitReady = useSelector((state: RootState) => state.splitReducer.isReady);

  React.useEffect(() => {
    const asyncCheckOktaAuth = async() => {
      const isAuthenticated = await hingeHealthAuthClient.isAuthenticated();

      if (!isAuthenticated) {
        hingeHealthAuthClient.signInWithRedirect({ originalUri: location.pathname});
      } else {
        dispatch(actionCreators.validateOkta())
          .then(() => setRenderRoute(true));
      }
    };

    if (!hingeHealthAuthState) {
      return;
    }

    asyncCheckOktaAuth();
  }, [hingeHealthAuthClient, hingeHealthAuthState]);

  React.useEffect(() => {
    if (isSplitReady && isRedirectCttToCtpEnabled) {
      window.location.replace(getCareTeamProductsAppUrl());
    }

    const timer = setTimeout(() => {
      if (isSplitReady) {
        setIsRedirectChecked(true);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [isSplitReady, isRedirectCttToCtpEnabled, isRedirectChecked]);

  if (!hingeHealthAuthState?.isAuthenticated || !renderRoute || !isSplitReady || !isRedirectChecked) {
    return <Spinner />;
  }

  return <Route {...props}/>;
};

export default React.memo(OktaRoute);
