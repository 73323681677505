import * as React from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { RootState } from '../../redux/reducers';
import {
  ComputedSortedConversationItem,
  isTaskUserComplete,
  selectOnboardedUsers,
  selectUserConversationItem
} from '../../redux/selectors';
import ConversationListItem from './ConversationListItem';
import { TaskKind } from '../../api-client/interfaces';
import './TodoUserListItem.scss';

interface TodoUserListItemProps {
  userId: number;
  todo: string;
  selectedUserId?: number;
  setSelectedUserId(userId: number): void;
  setSelectedConversationId(conversationId: string): void;
}

interface ConnectProps {
  conversationItem?: ComputedSortedConversationItem;
  isTaskComplete: boolean;
  isUserOnboarded: boolean;
}

type Props = TodoUserListItemProps & ConnectProps;

const TodoUserListItem: React.FC<Props> = ({
  conversationItem,
  selectedUserId,
  setSelectedUserId,
  setSelectedConversationId,
  isTaskComplete,
  isUserOnboarded
}) => {
  if (!conversationItem) return null;

  return (
    <div className={`${isTaskComplete || isUserOnboarded ? 'todo-user-list-item__dimmed' : ''}`}>
      <ConversationListItem
        conversationItem={conversationItem}
        selectedUserId={selectedUserId}
        setSelectedUserId={setSelectedUserId}
        setSelectedConversationId={setSelectedConversationId}
      />
    </div>
  );
};

const mapStateToProps = (
  state: RootState,
  { userId, todo }: TodoUserListItemProps
) => ({
  conversationItem: selectUserConversationItem(state, { userId }),
  isTaskComplete: isTaskUserComplete(state, { kind: todo as TaskKind, userId }),
  isUserOnboarded: selectOnboardedUsers(state).some(id => id === userId)
});

export default connect(mapStateToProps)(React.memo(TodoUserListItem, isEqual));
