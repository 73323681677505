import { createSelector } from 'reselect';
import { RootState } from '../reducers';

export function selectGoalsToEdit(state: RootState) {
  return state.goalsEditor;
}

export const selectAddGoal = createSelector(
  selectGoalsToEdit,
  (goalsToEdit) => goalsToEdit.newGoal
);

export const selectEditedGoal = createSelector(
  [selectGoalsToEdit, (_: {}, p: { goalId: string }) => p.goalId],
  (goalsToEdit, goalId) => goalsToEdit.get('goals').get(goalId)
);
