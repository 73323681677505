import * as React from 'react';
import { connect } from 'react-redux';
import { selectModalOpen } from '../../redux/selectors/ui-state';
import { RootState } from '../../redux/reducers';
import { Modal } from '@material-ui/core';
import { DispatchMap } from '../../redux/actions/interfaces';
import { updateIsOpen } from '../../redux/actions/ui-state';
import './HHModal.scss';

interface ModalContent {
  title: string;
  body: string | JSX.Element;
  primaryBtnText?: string;
  secondaryBtnText?: string;
}

interface InlineStyleProps {
  [key: string]: string | number;
}

interface ModalProps {
  modalContent: ModalContent;
  modalClassName?: string;
  onCloseFn?(): void;
  clickOkFn?(): void;
  onBackdropClickFn?(): void;
  disableBackDropClick?: boolean;
  container?: React.ReactInstance | null;
  inlineStyle?: InlineStyleProps;
  backdropStyle?: InlineStyleProps;
}

type ConnectProps = DispatchMap<{
  modalOpen: boolean;
  updateOpen: typeof updateIsOpen;
}>;

const HHModal: React.FC<ModalProps & ConnectProps> = (props) => {
  const handleClose = React.useCallback(() => {
    props.onCloseFn && props.onCloseFn();
    props.updateOpen(false);
  }, []);

  const handleClickOk = React.useCallback(() => {
    props.clickOkFn && props.clickOkFn();
  }, []);

  const handleOnBackDropClick = React.useCallback(() => {
    !props.disableBackDropClick && props.updateOpen(false);
  }, []);

  return (
    <Modal
      className={`${props.modalClassName || ''} modal`}
      open={props.modalOpen}
      onBackdropClick={handleOnBackDropClick}
      container={props.container}
      style={props.inlineStyle}
      BackdropProps={{ style: props.backdropStyle}}
    >
      <div className='modal__container'>
        <div className='modal__title__container'>
          <div className='modal__title'>
            {props.modalContent.title}
          </div>
        </div>
        <div className='modal__body'>
          {props.modalContent.body}
        </div>
        <div className='modal__buttons'>
          <button onClick={handleClose} className='modal__button modal__button__secondary'>
            {props.modalContent.secondaryBtnText}
          </button>
          <button onClick={handleClickOk} className='modal__button modal__button__primary'>
            {props.modalContent.primaryBtnText}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: RootState, props: ModalProps) => ({
  modalOpen: selectModalOpen(state)
});

const mapDispatchToProps = {
  updateOpen: updateIsOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(HHModal);
