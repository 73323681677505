import * as React from 'react';
import * as moment from 'moment-timezone';
import {
  Typography
} from '@material-ui/core';
import { isNullOrUndefined } from 'util';
import { DEFAULT_TIMEZONE } from '../../utils/user/timezone';
import {
  achievementsConfig,
  isAchievementsConfigKey,
  getAchievementsByType,
  AchievementsByType
} from '../../utils/user/achievements';
import { Achievement } from '../../api-client/interfaces';
import { UserRecord } from '../../redux/reducers';
import './AllAchievementsContext.scss';

interface Props {
  user?: UserRecord;
  achievements?: Achievement[];
}

const NoAchievementsAvailable = ({ firstName }: { firstName: string }) => (
  <>
    <Typography variant="h5" gutterBottom>
      {firstName}'s Achievements
    </Typography>
    <p className="achievement-context__na">N/A</p>
  </>
);

const AchievementsSectionList = ({
  sectionTitle,
  achievements,
  timezone
}: {
  sectionTitle: string;
  achievements: Achievement[];
  timezone: string;
}) => {
  if (achievements.length === 0) {
    return null;
  }

  return (
    <div>
      <p className="all-achievements__section-title">{sectionTitle}</p>
      <ul className="all-achievements__list">
        {
          achievements.map(achievement => {
            if(!isAchievementsConfigKey(achievement.key)) {
              return null;
            }
            const config = achievementsConfig[achievement.key];
            const achievedDate = moment(achievement.achievedDate).tz(timezone).format('M/D/YY');
            return (
              <li className="all-achievements__list-item" key={achievement.key}>
                {`${config.title} on ${achievedDate}`}
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};

const AllAchievementsContext: React.FC<Props> = ({
  user,
  achievements
}) => {
  if (!user) {
    return null;
  }

  const userFirstName = user.get('firstName');
  const timezone = user.get('timezone') || DEFAULT_TIMEZONE;

  if (isNullOrUndefined(achievements) || achievements.length === 0) {
    return <NoAchievementsAvailable firstName={userFirstName} />;
  }

  const achievementsByType: AchievementsByType = getAchievementsByType(achievements);

  return (
    <div className="all-achievements__container">
      <Typography variant="h5" gutterBottom>
        {userFirstName}'s Achievements
      </Typography>
      <AchievementsSectionList
        sectionTitle="Activity streak"
        achievements={achievementsByType.activityStreak}
        timezone={timezone}
      />
      <AchievementsSectionList
        sectionTitle="3x playlist streak"
        achievements={achievementsByType.threePlaylistStreak}
        timezone={timezone}
      />
      <AchievementsSectionList
        sectionTitle="Exercise reps"
        achievements={achievementsByType.exerciseReps}
        timezone={timezone}
      />
      <AchievementsSectionList
        sectionTitle="Celebrations"
        achievements={achievementsByType.celebrations}
        timezone={timezone}
      />
    </div>
  );
};

export default AllAchievementsContext;
