import { connect } from 'react-redux';
import GoalDeleteModal from '../../components/user/GoalDeleteModal';
import { actionCreators as goalEditingActions } from '../../redux/actions/goals';

export interface GoalDeleteModalContainerProps {
  deleteGoalId: string;
  userId: number;
}

// CT-10 this is a stub for goal adding
const mapDispatchToProps = {
  deleteGoal: goalEditingActions.deleteGoal
};

export default connect(null, mapDispatchToProps)(GoalDeleteModal);
