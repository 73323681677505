import * as React from 'react';

import { Table, TableBody, Box } from 'grommet';
import { FollowUps, Basics, PainBasic, Indication } from '../../graphql/models';
import {
  SCREENER_RESPONSES,
  ScreenerHeader,
  ScreenerRow,
  ScreenerCell,
  ScreenerText,
  ScreenerField
} from '../../containers/communications/ScreenerManager';
import { isNullOrUndefined } from '../../utils/helpers';

export type ScreenerPainProps = Pick<FollowUps, 'avoided_exercises'> & Basics & PainBasic & Pick<Indication, 'name'>;

const ScreenerPain: React.FC<ScreenerPainProps> = ({
  avoid,
  avoided_exercises,
  description,
  name
}) => (
  <Box style={{ padding: '6px 20px' }}>
    <ScreenerHeader fill>
      <strong>Pain</strong>
    </ScreenerHeader>
    <Table>
      <TableBody>
        <ScreenerRow>
          <ScreenerField size="1/4">{`Describe your ${name} pain`}</ScreenerField>
          <ScreenerCell>{description || 'N/A'}</ScreenerCell>
        </ScreenerRow>
        <ScreenerRow style={{ border: 'none' }}>
          <ScreenerField size="1/4">Avoid specific exercises</ScreenerField>
          <ScreenerCell>
            {!isNullOrUndefined(avoid) && (
              <Box direction="column" flex>
                <ScreenerText>{`${SCREENER_RESPONSES[avoid]}`}</ScreenerText>
                {avoided_exercises && (
                  <ScreenerText>{avoided_exercises}</ScreenerText>
                )}
              </Box>
            )}
          </ScreenerCell>
        </ScreenerRow>
      </TableBody>
    </Table>
  </Box>
);

export default ScreenerPain;
