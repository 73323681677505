import * as React from 'react';
import styled from 'styled-components';
import {
  Breadcrumb,
  Grid,
  Image,
  Icon,
  Header,
  Dropdown,
  Form,
  Popup,
  InputOnChangeData,
  Message
} from 'semantic-ui-react';
import { IconButton, Typography, Box, Grid as MaterialGrid } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import { connect } from 'react-redux';
import * as moment from 'moment-timezone';
import * as luxon from 'luxon';
import { Link } from 'react-router-dom';
import 'twix';
import { sortBy, startCase } from 'lodash';
import { MESSAGING_PATH } from '../../../routes';
import StrikeThroughUser from '../../../components/user/StrikeThroughUser';
import { renderTags } from '../../phx/tasks/Tag';
import { StreamKind, Admin, Coverages, User } from '../../../api-client';
import { Status, EnrollmentStatus, GetUserById, Pathway } from '../../../graphql/models/bff';
import { DispatchMap } from '../../../redux/actions/interfaces';
import { selectFeatureFlags, selectUser } from '../../../redux/selectors';
import { selectAdmin } from '../../../redux/selectors/admin';
import { RootState, UserRecord, PERSONALITY_TAGS } from '../../../redux/reducers';
import { actionCreators } from '../../../redux/actions/admin';
import { RED, GREEN, BLACK } from '../../../colors';
import { isNullOrUndefined } from 'util';
import { isNumber } from '../../../utils/number';
import { DEFAULT_TIMEZONE } from '../../../utils/user/timezone';
import { getContactText, getContactEmail, getContactCall } from '../../../utils/user/communication-preferences';
import { ERR_DROPDOWN_FAIL } from '../../../errors';
import ContactEditModal from './ContactEditModal';
import TagCommentModal from '../../../containers/user/TagCommentModal';
import { putUserFirstName } from '../../../redux/actions/user';
import { DropdownOnChangeHandler } from '../../../typings/semantic-ui-react';
import UserStatusLabelContainer from '../../../containers/communications/UserStatusLabelContainer';
import { ZOOM_PHONE_CALL_URI, HELPSCOUT_URI, JUST_CALL_URI } from '../../../constant';
import JoinCallButton from '../../video/JoinCallButton';
import './UserProfileBanner.scss';
import UserStatusDropdown from '../../../containers/communications/UserStatusDropdown';
import EnrollmentStatusModal from './EnrollmentStatusModal';
import { VideoVisitIcon } from './VideoVisitIcon';
import { getTimeSinceOnboarded } from '../../../containers/communications/ActivityEngagementContainer';
import { lowMedTierTags } from '../../../features';
import { SPLIT_LOWMEDTIER_DROPDOWN_TAGS } from '../../../redux/reducers/user';
import { useMaternityWeek } from '../../../utils/hooks/useMaternityWeek';
import { Indication } from '../../../graphql/models/bff/MemberCondition';
import { NON_UNIFIED_USER_DEFAULT_INDICATION_TEXT } from '../../communications/UserPosterSummary';

const PERSONALITY_TAG_OPTIONS = PERSONALITY_TAGS.map(t => ({
  text: t.toUpperCase(),
  value: t
}));

const SPLIT_LOWMEDTIER_DROPDOWN_TAG_OPTIONS = SPLIT_LOWMEDTIER_DROPDOWN_TAGS.map(t => ({
  text: t.toUpperCase(),
  value: t
}));

export const StyledHeader = styled(Header)`
  padding-right: 10px;
`;

export const StyledTypography = styled(Typography)`
  && {
    line-height: 22px;
  }
`;

export const StyledUserInfoRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const StyledDiv = styled.div`
  padding: 5px 10px 0px 0px;
`;

export const StyledButtonContainer = styled.div`
  margin-bottom: 14px;
`;

export const StyledDropdownContainer = styled.div`
  margin-bottom: 14px;
  margin-left: auto;
`;

export const TagList = styled.div`
  line-height: 2rem;
  margin-bottom: 1rem;
`;

export const ActiveLabel = styled.span`
  color: ${(props: { active?: boolean }) => {
    if (props.active === undefined) return BLACK;
    if (props.active) return GREEN;
    return RED;
  }};
`;

interface UserProfileBannerProps {
  user: GetUserById;
  enrollmentStatus?: EnrollmentStatus | null;
  coverages?: Coverages;
  changeEnrollmentStatus: (status: Status) => unknown;
  isZoomPhoneCallEnabled: boolean;
  indications: Indication[];
  isMultiIndicationUser: boolean;
}

interface UserProfileBannerState {
  showError: boolean;
  editedName?: string | null;
  contactEditActive: boolean;
  nameEditActive: boolean;
  tagCommentActive: boolean;
  showRemoveTag: boolean;
  selectedTag: string;
  showEnrollmentStatusModal: boolean;
  indications: JSX.Element[];
}

interface PhysicalTherapistInfoProps {
  name: string;
  isVideoVisitEligible: boolean | undefined;
}

type ConnectProps = DispatchMap<{
  selectedUser?: UserRecord;
  admin?: Admin;
  putUserFirstName: typeof putUserFirstName;
  getAdmin: typeof actionCreators.getAdmin;
  lowMedTierTagsEnabled?: boolean;
}>;

export type Props = UserProfileBannerProps & ConnectProps;

enum DaysOfWeek {
  SUN,
  MON,
  TUE,
  WED,
  THU,
  FRI,
  SAT
}

export function isActiveThisWeek(lastActiveDate?: string | null, timezone?: string | null) {
  const zone = timezone || DEFAULT_TIMEZONE;
  const mondayStart = moment.tz(zone).startOf('isoWeek');

  return lastActiveDate && moment.tz(lastActiveDate, zone).diff(mondayStart) >= 0 ? (
    <ActiveLabel active>Yes</ActiveLabel>
  ) : (
    <ActiveLabel>No</ActiveLabel>
  );
}

export const showEngagement = (streak?: string | null) => {
  const streakAsNumber = streak && parseInt(streak, 10);
  if (isNullOrUndefined(streakAsNumber) || streakAsNumber === 0) {
    return <div>N/A</div>;
  }

  return streakAsNumber > 0 ? (
    <ActiveLabel active={true}>{`Active ${streak}W`}</ActiveLabel>
  ) : (
    <ActiveLabel active={false}>{`Inactive ${Math.abs(streakAsNumber as number)}W`}</ActiveLabel>
  );
};

export const getDateOnboarded = (firstEtDate: string) =>
  luxon.DateTime.fromISO(firstEtDate).toLocaleString(luxon.DateTime.DATE_FULL) +
  ' (' + getTimeSinceOnboarded(firstEtDate) + ')';

export function getActivePathway(user: GetUserById): Pathway | undefined {
  return user?.pathways && user?.pathways.length ? user.pathways[0] : undefined;
}

export function isWeekdays(days: number[]): boolean {
  return (
    days.length === 5 &&
    days[0] === DaysOfWeek.MON &&
    days[1] === DaysOfWeek.TUE &&
    days[2] === DaysOfWeek.WED &&
    days[3] === DaysOfWeek.THU &&
    days[4] === DaysOfWeek.FRI
  );
}

export function isWeekends(days: number[]): boolean {
  return days.length === 2 && days[0] === DaysOfWeek.SAT && days[1] === DaysOfWeek.SUN;
}

export function getCurrentWeek(pathway?: Pathway | null) {
  return pathway ? pathway.currentWeek : null;
}

export function getCurrentLevel(pathway?: Pathway | null) {
  return pathway ? pathway.currentLevel : null;
}

export function getCurrentStream(pathway?: Pathway | null) {
  if (!pathway) {
    return 'Not available';
  }
  switch (pathway.currentExerciseTherapyStream) {
    case StreamKind.low_activity:
      return 'Low';
    case StreamKind.medium_activity:
      return 'Medium';
    case StreamKind.high_activity:
      return 'High';
    default:
      return 'Not available';
  }
}

export function getVacationRange(user: User) {
  const { vacationInfo } = user;

  if (!vacationInfo) return undefined;

  const { vacationStartDate, vacationEndDate } = vacationInfo;

  if (!vacationStartDate || !vacationEndDate) return undefined;

  const timezone = user.timezone || DEFAULT_TIMEZONE;

  return moment.tz(vacationStartDate, timezone).twix(moment.tz(vacationEndDate, timezone), true);
}

export function getVacationStartDate(user: User) {
  return user.vacationInfo && user.vacationInfo.vacationStartDate;
}

export function getUserClientName(user: GetUserById) {
  return user.client && user.client.name;
}

export function isTagClient(tag: string, clientName?: string | null) {
  return clientName && clientName.toLowerCase() === tag.toLowerCase();
}

const MidDot: React.FC<{ className?: string }> = ({ className }) => (
  <Typography className={className} variant='overline'>
    {' \u00b7 '}
  </Typography>
);

export const PhysicalTherapistInfo = (props: PhysicalTherapistInfoProps) => {
  const { name, isVideoVisitEligible } = props;
  return (
    <div style={{display: 'flex', gap: '10px'}}>
      { name }
      <VideoVisitIcon isEligible={isVideoVisitEligible}/>
    </div>
  );
};

export const UserInfoRow: React.FC<{ description: string; value?: string | JSX.Element | null }> = ({
  description,
  value
}) => (
  <StyledUserInfoRowContainer>
    <MaterialGrid item xs={6}>
      <StyledTypography variant='subtitle1' color='textSecondary'>
        {description}
      </StyledTypography>
    </MaterialGrid>
    <MaterialGrid item xs={6}>
      <Typography variant='body1' color='textPrimary'>
        {value || ''}
      </Typography>
    </MaterialGrid>
  </StyledUserInfoRowContainer>
);

export const UserLevelOrMatWeek: React.FC<{ level?: number | null | undefined; activePathway?: Pathway }> = ({
  level, activePathway
}) => {
  const { maternityWeek, isMaternity } = useMaternityWeek(activePathway);
  const weekOrLevel = isMaternity ? maternityWeek : level;
  const levelText = isMaternity ? 'MAT. WEEK' : 'LEVEL';
  return <Typography variant='overline'>{`${levelText} ${isNumber(weekOrLevel) ? weekOrLevel : 'N/A'}`}</Typography>;
};

class UserProfileBanner extends React.PureComponent<Props, UserProfileBannerState> {
  constructor(props: Props) {
    super(props);

    if (!props.admin?.id) {
      return;
    }
    props.getAdmin({ id: props.admin.id });
  }

  state: UserProfileBannerState = {
    ...this.state,
    showError: false,
    contactEditActive: false,
    nameEditActive: false,
    tagCommentActive: false,
    showRemoveTag: false,
    editedName: undefined,
    selectedTag: '',
    showEnrollmentStatusModal: false,
    indications: []
  };

  handleShowContactEdit = () => {
    this.setState({ contactEditActive: true });
  };

  handleNameEditClick = (ev: React.FormEvent<HTMLElement>) => {
    ev.preventDefault();
    this.setState({ nameEditActive: !this.state.nameEditActive });
  };

  handleNameEditSubmit = (ev: React.FormEvent<HTMLElement>) => {
    if (!this.props.selectedUser || !this.state.editedName) {
      this.handleNameEditClose();
      return;
    }
    ev.preventDefault();
    this.props
      .putUserFirstName({
        user: this.props.selectedUser,
        firstName: this.state.editedName
      })
      .then(() => {
        this.handleNameEditClose();
      })
      .catch(e => {
        this.setState({ showError: true });
        throw new Error(`Unable to save user name: ${e}`);
      });
  };

  handleNameChange = (ev: object, data: InputOnChangeData) => {
    this.setState({ editedName: data.value });
  };

  handleTagSelect: DropdownOnChangeHandler = (e, data) => {
    if (e.type === 'blur') return;
    if (typeof data.value !== 'string') throw new Error(ERR_DROPDOWN_FAIL);

    const selectedTag = data.value.toLowerCase().trim();

    this.setState({
      tagCommentActive: true,
      selectedTag
    });
  };

  closeTagModal = () => this.setState({ tagCommentActive: false, selectedTag: '', showRemoveTag: false });

  handleNameEditClose = () => this.setState({ nameEditActive: !this.state.nameEditActive });

  handleContactEditClose = () => this.setState({ contactEditActive: false });

  handleRemoveTag = (tag: string) => {
    this.setState({
      selectedTag: tag.toLowerCase().trim(),
      tagCommentActive: true,
      showRemoveTag: true
    });
  };

  showTagDelete = (tag: string) => {
    const clientName = getUserClientName(this.props.user);
    return !isTagClient(tag, clientName);
  };

  createCoverageElements = (coverages: Coverages, isVideoVisitEligible: boolean | undefined) =>
    Object.keys(coverages).map(key => {
      const titleCasedKey = startCase(key);

      if (key === 'physicalTherapist') {
        return (
          <UserInfoRow
            key={key}
            description={titleCasedKey}
            value={<PhysicalTherapistInfo name={coverages[key].name} isVideoVisitEligible={isVideoVisitEligible} />}
          />
        );
      }
      return <UserInfoRow key={key} description={titleCasedKey} value={coverages[key].name} />;
    });

  handleShowEnrollmentStatusModal = (toggleOption: boolean) =>
    this.setState({ showEnrollmentStatusModal: toggleOption });

  getIndicationString = (indication: Indication): string => {
    const isPrimaryIndication = indication.priority === 0;
    const priorityString = isPrimaryIndication ? ' (P)' : '';
    return `${indication.kind}.${indication.bodyPart}.${indication.acuity} ${priorityString}`;
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<UserProfileBannerState>): void {
    if (prevProps.indications !== this.props.indications
      || prevProps.isMultiIndicationUser !== this.props.isMultiIndicationUser) {

      const indicationsElements: JSX.Element[] = [];

      if (this.props.isMultiIndicationUser) {
        this.props.indications.forEach((indication) => {
          indicationsElements.push(
            <Typography
              key={indication.urn}
              variant='body1'
              color='textPrimary'>
              {this.getIndicationString(indication)}
            </Typography>
          );
        });
      } else {
        indicationsElements.push(
          <Typography
            key={'non-unified-member-indication-text'}
            variant='body1'
            color='textPrimary'>
            {NON_UNIFIED_USER_DEFAULT_INDICATION_TEXT}
          </Typography>
        );
      }

      this.setState({ ...prevState, indications: indicationsElements });
    }
  }

  render() {
    if (!this.props.user || !this.props.selectedUser) return null;

    const {
      enrollmentStatus,
      selectedUser,
      user,
      coverages,
      indications,
      changeEnrollmentStatus
    } = this.props;

    const activePathway = getActivePathway(user);
    const contactText = getContactText(selectedUser);
    const contactCall = getContactCall(selectedUser);
    const contactEmail = getContactEmail(selectedUser);
    const vacationRange = getVacationRange(selectedUser);
    const vacationStartDate = getVacationStartDate(selectedUser) || '…';
    const currentWeek = getCurrentWeek(activePathway);
    const currentLevel = getCurrentLevel(activePathway);
    const currentStream = getCurrentStream(activePathway);
    const firstEtDate = user?.pathways && user.pathways[0]?.firstEtAt;
    const tagsDropdownOptions = this.props.lowMedTierTagsEnabled
      ? [...SPLIT_LOWMEDTIER_DROPDOWN_TAG_OPTIONS, ...PERSONALITY_TAG_OPTIONS]
      : PERSONALITY_TAG_OPTIONS;

    return (
      <div className='user-profile-banner'>
        <ContactEditModal
          open={this.state.contactEditActive}
          userId={parseInt(user.id, 10)}
          editedUser={selectedUser}
          onClose={this.handleContactEditClose}
        />
        <Grid columns={3} divided padded relaxed>
          <Grid.Column>
            <Grid columns={2}>
              <Grid.Column width={4}>
                <Image src={user.avatarUrl || `https://api.adorable.io/avatars/128/${user.id}`} circular size='large' />
              </Grid.Column>
              <Grid.Column textAlign='left' width={12}>
                <Breadcrumb>
                  <Breadcrumb.Section>My Teams</Breadcrumb.Section>
                  <Breadcrumb.Divider icon='right angle' />
                  <Breadcrumb.Section active>ID {user.id}</Breadcrumb.Section>
                </Breadcrumb>
                <Popup
                  position='bottom left'
                  open={this.state.nameEditActive}
                  trigger={
                    <StyledHeaderContainer>
                      <StyledHeader as='h1' onClick={this.handleNameEditClick}>
                        <StrikeThroughUser user={selectedUser} />
                      </StyledHeader>
                      <StyledDiv>
                        <UserStatusLabelContainer userId={parseInt(user.id, 10)} />
                      </StyledDiv>
                      <StyledButtonContainer>
                        <IconButton
                          aria-label='edit name'
                          size='small'
                          style={{ marginLeft: 5 }}
                          onClick={this.handleNameEditClick}
                        >
                          <CreateIcon />
                        </IconButton>
                      </StyledButtonContainer>
                      <StyledDropdownContainer>
                        <UserStatusDropdown
                          userId={parseInt(user.id, 10)}
                          handleShowEnrollmentStatusModal={() => this.handleShowEnrollmentStatusModal(true)}
                          anchorPositionLeft={470}
                          anchorPositionTop={180}
                          onlyShowEnrollmentOptions
                          enrollmentStatus={enrollmentStatus}
                        />
                      </StyledDropdownContainer>
                      {this.state.showError && <Message error>Unable to save first name. Please try again.</Message>}
                    </StyledHeaderContainer>
                  }
                  content={
                    <Form onSubmit={this.handleNameEditSubmit}>
                      <Form.Group>
                        <Form.Input
                          placeholder='First name'
                          name='firstName'
                          value={isNullOrUndefined(this.state.editedName) ? user.firstName : this.state.editedName}
                          onChange={this.handleNameChange}
                        />
                        <Form.Button onClick={this.handleNameEditClick} secondary>
                          Cancel
                        </Form.Button>
                        <Form.Button primary type='submit' content='Save' />
                      </Form.Group>
                    </Form>
                  }
                />
                <TagList>
                  {renderTags(selectedUser.tags, {
                    onDelete: this.handleRemoveTag,
                    showDelete: this.showTagDelete
                  })}
                </TagList>
                <Dropdown
                  text='Add tag'
                  button
                  floating
                  options={tagsDropdownOptions}
                  search
                  allowAdditions
                  selection
                  onChange={this.handleTagSelect}
                />
                <TagCommentModal
                  userId={selectedUser.id}
                  tag={this.state.selectedTag}
                  isDelete={this.state.showRemoveTag}
                  open={this.state.tagCommentActive}
                  onClose={this.closeTagModal}
                />
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column>
            <MaterialGrid container direction='row' spacing={2}>
              <MaterialGrid item xs={8}>
                <div className='pathway-info'>
                  <Typography variant='overline'>{`WEEK ${isNumber(currentWeek) ? currentWeek : 'N/A'}`}</Typography>
                  <MidDot className='divider' />
                  <UserLevelOrMatWeek level={currentLevel} activePathway={activePathway}/>
                  <MidDot className='divider' />
                  <Typography variant='overline'>STREAM: {`${currentStream.toUpperCase()}`}</Typography>
                </div>
              </MaterialGrid>
              <MaterialGrid item xs={12}>
                <UserInfoRow description='Company' value={user?.client?.name} />
                <UserInfoRow description='Team' value={user?.team?.name} />
                {coverages &&
                  this.createCoverageElements(coverages, user.userFeatures?.videoVisitEligibility)}
                <UserInfoRow description='Active this week?' value={isActiveThisWeek(user.lastActive, user.timezone)} />
                <UserInfoRow
                  description='Engagement'
                  value={currentWeek && currentWeek >= -1 ? showEngagement(user?.engagementStreak) : 'N/A'}
                />
                {!isNullOrUndefined(firstEtDate) &&
                  <UserInfoRow
                    description='Date onboarded'
                    value={getDateOnboarded(firstEtDate)}
                  />
                }
                {
                  indications && indications.length > 0 &&
                  <UserInfoRow
                    description='Program Indication'
                    value={<Box>
                      {this.state.indications}
                    </Box>}
                  />
                }
              </MaterialGrid>
            </MaterialGrid>
          </Grid.Column>
          <Grid.Column>
            <Grid columns={2}>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <strong>{'CONTACT'}</strong>
                  <IconButton
                    aria-label='edit contact information'
                    size='small'
                    style={{ marginLeft: 5 }}
                    onClick={this.handleShowContactEdit}
                  >
                    <CreateIcon />
                  </IconButton>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                {vacationRange && vacationStartDate ? (
                  <Grid.Column width={8}>
                    <strong>
                      {vacationRange.contains(moment())
                        ? 'On Vacation'
                        : moment().isBefore(vacationStartDate)
                          ? 'Next Vacation'
                          : 'Last Vacation'}
                    </strong>
                    <p>{vacationRange.format()}</p>
                  </Grid.Column>
                ) : (
                  <Grid.Column>
                    <strong>No vacation info</strong>
                  </Grid.Column>
                )}
                <Grid.Column>
                  <Header as='h4'>
                    {contactText ? (
                      <Link to={MESSAGING_PATH} target='_blank'>
                        <Icon circular inverted color={'blue'} name='commenting' />
                      </Link>
                    ) : (
                      <a>
                        <Icon circular inverted color={'grey'} name='commenting' />
                      </a>
                    )}
                    <Header.Content>
                      {contactText ? 'Send SMS' : 'No SMS'}
                      <Header.Subheader>{user.phoneNumber}</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column />
                <Grid.Column>
                  <Header as='h4'>
                    {contactCall ? (
                      <a href={this.props.isZoomPhoneCallEnabled ? ZOOM_PHONE_CALL_URI : JUST_CALL_URI}
                        target='_blank'>
                        <Icon circular inverted color={'blue'} name='phone' />
                      </a>
                    ) : (
                      <a>
                        <Icon circular inverted color={'grey'} name='phone' />
                      </a>
                    )}
                    <Header.Content>
                      {contactCall ? 'Call' : 'No Call'}
                      <Header.Subheader>{user.phoneNumber}</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column />
                <Grid.Column>
                  <Header as='h4'>
                    {contactEmail ? (
                      <a href={HELPSCOUT_URI} target='_blank'>
                        <Icon circular inverted color={'blue'} name='mail' />
                      </a>
                    ) : (
                      <a>
                        <Icon circular inverted color={'grey'} name='mail' />
                      </a>
                    )}
                    <Header.Content>
                      {contactEmail ? 'Send Email' : 'No Email'}
                      <Header.Subheader>{user.email ? user.email : ''}</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column />
                <Grid.Column>
                  <Header as='h4'>
                    <JoinCallButton userId={selectedUser.id} />
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid>
        {this.state.showEnrollmentStatusModal && (
          <EnrollmentStatusModal
            modalShown={this.state.showEnrollmentStatusModal}
            toggleModalShown={this.handleShowEnrollmentStatusModal}
            enrollmentStatus={enrollmentStatus}
            changeEnrollmentStatus={changeEnrollmentStatus}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, props: UserProfileBannerProps) => ({
  selectedUser: selectUser(state, { userId: parseInt(props.user.id, 10) }),
  admin: selectAdmin(state),
  lowMedTierTagsEnabled: selectFeatureFlags(state).get(lowMedTierTags),
  indications: sortBy(props.indications, 'priority')
});

const mapDispatchToProps = {
  putUserFirstName,
  getAdmin: actionCreators.getAdmin
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileBanner);
