import * as React from 'react';
import * as moment from 'moment-timezone';
import * as sanitizeHtml from 'sanitize-html';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar, Button } from '@material-ui/core';
import './MessageListItemV2.scss';

import { DEFAULT_TIMEZONE } from '../../../utils/user/timezone';
import { Admin, MessagePartyType, MessageStatusV2 } from '../../../api-client/interfaces';
import { UserRecord, MessageV2Record } from '../../../redux/reducers';
import { DispatchThunk, messageV2Actions } from '../../../redux/actions';
import { actionCreators as adminActions} from '../../../redux/actions/admin';
import { selectAdmin } from '../../../redux/selectors/admin';
import UserStatusLabelContainer from '../../../containers/communications/UserStatusLabelContainer';
import DumpItemIds from '../debug/DumpItemIds';
import UnreadLabel from '../UnreadLabel';
import ModalWrapper from '../../modal/ModalWrapper';
import EditScheduledMessageModalV2 from './EditScheduledMessageModalV2';

export interface MessageListItemV2Props {
  user: UserRecord;
  message: MessageV2Record;
  deleteScheduledMessage: typeof messageV2Actions.deleteMessage;
  updateMessage: typeof messageV2Actions.updateMessage;
  markMessageAsRead(): void;
}

export const modifyMessage = (msg: string) => {
  if (!msg) {
    return '';
  }
  return msg.split(' ').map((word) => {
    if (word.startsWith('https://') || word.startsWith('http://')) {
      return `<a style="cursor: pointer" target='_blank' href=${word}>${word}</a>`;
    }
    return word;
  }).join(' ');
};

const MessageListItemV2: React.FC<MessageListItemV2Props> = ({
  user,
  deleteScheduledMessage,
  updateMessage,
  markMessageAsRead,
  message
}) => {
  const { uuid, isUnread, body, sender, status, createdAt, transmittedAt, sendAt } = message;
  const isScheduled = status === MessageStatusV2.Pending && sendAt;
  // TODO: this assumes admin is need for BAT-1321 markAsRead action. remove if unneeded
  const dispatchThunk: DispatchThunk = useDispatch();

  const [currentAdmin, setCurrentAdmin] = React.useState<Admin>(useSelector(selectAdmin));
  React.useEffect(() => {
    dispatchThunk(adminActions.getAdmin({ id: currentAdmin.id })).then((admin) => {
      setCurrentAdmin(admin);
    });
  }, []);

  const messagePartyType = sender.type;
  const isMessageFromUser = messagePartyType === MessagePartyType.User ? true : false;
  const name = isMessageFromUser
    ? `${user.firstName} ${user.lastName}`
    : 'Coach';
  const messageTimestamp =
  isMessageFromUser ||
  status === MessageStatusV2.Pending
    ? createdAt
    : transmittedAt;
  const modifiedMessage = modifyMessage(body);

  const [messageIsUnread, setMessageIsUnread] = React.useState<boolean>(isUnread);
  const adminUuid = currentAdmin.uuid;
  const handleMarkMessageAsRead = () => {
    if (!adminUuid) return;
    setMessageIsUnread(false);

    // TODO: update with BAT-1321 markAsRead action
    // dispatchThunk(messageV2Actions.markAsRead({
    //   uuid,
    //   adminUuid,
    //   isUnread: false
    // }))
    //   .then((res: unknown) => {
    //     setMessageIsUnread(false);
    //   });
  };

  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const handleDeleteModalOpen = (event: React.MouseEvent<{}>): void => {
    event.stopPropagation();
    setOpenDeleteModal(true);
  };
  const handleDeleteModalClose = () => {
    setOpenDeleteModal(false);
  };
  const deleteContentProps = {
    title: 'Delete scheduled message',
    body: 'Are you sure you want to delete this scheduled message?',
    confirmBtnText: 'DELETE',
    dismissBtnText: 'CANCEL'
  };
  const handleDeleteScheduledMessageClick = () => deleteScheduledMessage(message.uuid);

  const [openEditModal, setOpenEditModal] = React.useState<boolean>(false);
  const handleEditModalOpen = (event: React.MouseEvent<{}>): void => {
    event.stopPropagation();
    setOpenEditModal(true);
  };

  return (
    <>
      {openDeleteModal && (
        <ModalWrapper
          modalClassName='delete-scheduled-message-modal'
          modalContent={deleteContentProps}
          onConfirm={handleDeleteScheduledMessageClick}
          onDismiss={handleDeleteModalClose}
          openModal={openDeleteModal}
        />
      )}
      {openEditModal && (
        <EditScheduledMessageModalV2
          userTimezone={user.timezone || DEFAULT_TIMEZONE}
          message={message}
          updateMessage={updateMessage}
          showEditModal={openEditModal}
          setShowEditModal={setOpenEditModal}
        />
      )}
      <div className='message-item'>
        {isMessageFromUser ? (
          (user.avatarUrl && (
            <Avatar
              className='message-item__img'
              imgProps={{ height: 'inherit', width: 'inherit' }}
              src={user.avatarUrl}
            />
          )) || (
            <Avatar
              style={{
                width: '28px',
                height: '28px',
                backgroundColor: '#CFD8DC'
              }}
              src={''}
            />
          )
        ) : (
          <Avatar
            style={{
              backgroundColor: '#0F5472',
              opacity: '0.9',
              maxWidth: '28px',
              maxHeight: '28px'
            }}
          >
            C
          </Avatar>
        )}
        <div className='message-item__contents'>
          <div className='message-item__contents__header'>
            <div className='message-item__contents__header__info'>
              <div className='message-item__contents__header__info__user'>
                <DumpItemIds msg={{ id: uuid }} />
                <div className='message-item__contents__header__info__user__name'>
                  {name}
                </div>
                {isMessageFromUser && (
                  <div className='message-item__contents__header__info__user__status'>
                    <UserStatusLabelContainer userId={user.id} />
                  </div>
                )}
              </div>
              <div className='message-item__contents__header__info__timestamp'>
                {!isScheduled &&
                  moment
                    .tz(messageTimestamp, DEFAULT_TIMEZONE)
                    .format('ddd MMM D, h:mm a')}
              </div>
            </div>
            { messageIsUnread && (
              <UnreadLabel />
            )}
          </div>
          <div
            className={`message-item__contents__body
              ${messageIsUnread ? 'message-item__contents__body--unread' : ''}
              ${status === MessageStatusV2.Error ? 'message-item--failed' : ''}`}
            dangerouslySetInnerHTML={{
              __html: modifiedMessage ? sanitizeHtml(modifiedMessage) : ''
            }}
          />
          {!isScheduled &&
            !isMessageFromUser && (
            <div
              id='message-status'
              className={`message-item__contents__status ${
                status === MessageStatusV2.Error
                  ? 'message-item__contents__status--failed'
                  : ''
              }`}
            >
              {`Status: ${status}`}
            </div>
          )}
          {!isScheduled && messageIsUnread && (
            <Button
              variant='text'
              disableElevation
              color='primary'
              onClick={handleMarkMessageAsRead}
              className='message-item__mark-read'
            >
              Mark as read
            </Button>
          )}
          {isScheduled &&
            sendAt &&
            user.timezone && (
            <div className='message-item__contents__scheduled'>
              <div
                className='message-item__contents__scheduled message-item__contents__scheduled__stamp'
                id='message-status'
              >
                {`Scheduled for ${moment(sendAt)
                  .tz(user.timezone)
                  .format('ddd M/D/YYYY h:mm A z')}`}
              </div>
              <div className='message-item__contents__scheduled__button'>
                <button
                  id='delete-scheduled-message-button'
                  className='message-item__contents__scheduled__button
                    message-item__contents__scheduled__button--delete'
                  onClick={handleDeleteModalOpen}
                >
                    DELETE
                </button>
                <span
                  id='message-status'
                  className='message-item__contents__scheduled__button__divide'
                >
                  {' '}
                    |{' '}
                </span>
                <button
                  id='edit-scheduled-message-button'
                  className='message-item__contents__scheduled
                    message-item__contents__scheduled__button'
                  onClick={handleEditModalOpen}
                >
                    EDIT
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MessageListItemV2;
