import { useQuery } from '@apollo/client';
import * as React from 'react';
import { Loader } from 'semantic-ui-react';
import { GET_USER_TYPE } from '../../../graphql/queries';
import { User } from '../../../graphql/models';
import { Location } from 'history';
import { UserTypeVariables } from '../../../graphql/interfaces';
import { ERR_USER_ID_INVALID, ERR_USER_TYPE_FAIL } from '../../../errors';
import { useUserId } from '../../../utils/hooks/useUserId';

interface Response {
  user: User;
}

interface WrappedProps {
  location?: Location;
  userUuid?: string | null;
  id?: number;
}

interface UserTypeProps {
  phoenix: React.ComponentType<WrappedProps>;
  legacy: React.ComponentType<WrappedProps>;
}

export type UserTypeContainerProps = WrappedProps & UserTypeProps;

const UserTypeContainer: React.FunctionComponent<UserTypeContainerProps> = ({
  ...props
}) => {
  const userId = useUserId();
  const { phoenix: PhxComponent, legacy: LegacyComponent } = props;

  if (userId === undefined) throw new Error(ERR_USER_ID_INVALID);

  const { loading, error, data } = useQuery<Response, UserTypeVariables>(
    GET_USER_TYPE,
    { variables: { id: userId.toString() } }
  );

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'centered' }}>
        <Loader>Loading user ID {userId}...</Loader>
      </div>
    );
  }
  if (error) throw new Error(ERR_USER_TYPE_FAIL);

  if (!data || !data.user) return null;

  if (data.user.phoenix) {
    return <PhxComponent id={userId} userUuid={data.user.uuid} location={props.location} />;
  } else {
    return <LegacyComponent id={userId} userUuid={data.user.uuid} location={props.location} />;
  }
};

export default UserTypeContainer;
