import * as React from 'react';
import { AuthRedirectCallback } from '../components/auth-shared/index';
import Spinner from '../components/spinner/Spinner';

const AuthCallbackContainer: React.FC = () =>
  <AuthRedirectCallback
    loadingComponent={<Spinner />}
  />;


export default AuthCallbackContainer;
