import * as React from 'react';
import './TodoList.scss';
import TodoListItem from './TodoListItem';
import { Team } from '../../graphql/models';
import { OrderedDoTaskKindTitles, ClinicalTaskKindTitles, UnreadMessageTitles, TaskKind } from '../../api-client';
import { actionCreators as uiStateActions } from '../../redux/actions/ui-state';
import { DispatchMap } from '../../redux/actions/interfaces';
import { selectFeatureFlags } from '../../redux/selectors';
import { connect } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { healthLogNotesFilterFlag, painUptickFilterFlag } from '../../features';

interface TasksProps {
  selectedTeam?: Team;
  selectedTodo?: string;
  setSelectedTodo(task?: string): void;
  setSelectedTeam(team?: Team): void;
  setSelectedTeamId(teamId: string): void;
  taskKindToFeatureFlagMapping: Map<string, object>;
  selectedTeamId?: string;
}

type ConnectProps = DispatchMap<{
  getTaskCountByKind: typeof uiStateActions.getTaskCountByKind;
}>;
type Props = TasksProps & ConnectProps;
const TodoList: React.FC<Props> = props => {
  const { taskKindToFeatureFlagMapping, selectedTodo, setSelectedTodo } = props;

  React.useEffect(() => {
    props.getTaskCountByKind();

    if (!props.selectedTeamId) {
      props.setSelectedTodo('unreadMessage');
    }
  }, []);
  const selectTodo = (task: string) => {
    setSelectedTodo(task);
    props.setSelectedTeam(undefined);
    props.setSelectedTeamId('');
  };

  return (
    <div className='todo-list' data-testid='todo-list'>
      <div className='todo-list-title'>TO DO's</div>
      {Object.keys(UnreadMessageTitles).map(key => (
        <TodoListItem
          key={key}
          selectedTeam={props.selectedTeam}
          selectedTodo={selectedTodo}
          todoKey='unreadMessage'
          taskName={UnreadMessageTitles[key]}
          setSelectedTodo={selectTodo}
        />
      ))}

      <div className='todo-list-separator' />

      {Object.keys(ClinicalTaskKindTitles)
        .filter(taskKind => {
          if (
            taskKindToFeatureFlagMapping.get(TaskKind.readyForCoaching) &&
            (taskKind === TaskKind.firstEverEtAttempted || taskKind === TaskKind.firstEverEtSessionCompleted)
          ) {
            return false;
          }
          if (!taskKindToFeatureFlagMapping.get(healthLogNotesFilterFlag) && taskKind === TaskKind.healthLogNote) {
            return false;
          }
          if (!taskKindToFeatureFlagMapping.get(painUptickFilterFlag) && taskKind === TaskKind.painUptick) {
            return false;
          }
          return true;
        })
        .map(
          key =>
            (!taskKindToFeatureFlagMapping.has(key) || taskKindToFeatureFlagMapping.get(key)) && (
              <TodoListItem
                key={key}
                selectedTeam={props.selectedTeam}
                selectedTodo={selectedTodo}
                todoKey={key}
                taskName={ClinicalTaskKindTitles[key]}
                setSelectedTodo={selectTodo}
              />
            )
        )}

      <div className='todo-list-separator' />
      {Object.keys(OrderedDoTaskKindTitles).map(
        key =>
          (!taskKindToFeatureFlagMapping.has(key) || taskKindToFeatureFlagMapping.get(key)) && (
            <TodoListItem
              key={key}
              selectedTeam={props.selectedTeam}
              selectedTodo={selectedTodo}
              todoKey={key}
              taskName={OrderedDoTaskKindTitles[key]}
              setSelectedTodo={selectTodo}
            />
          )
      )}
      <div className='todo-list-separator-no-margin' />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  taskKindToFeatureFlagMapping: selectFeatureFlags(state)
});

const mapDispatchToProps = {
  getTaskCountByKind: uiStateActions.getTaskCountByKind
};

export default connect(mapStateToProps, mapDispatchToProps)(TodoList);
