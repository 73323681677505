import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import * as React from 'react';
import styled from 'styled-components';
import { Segment, Input, Button, Grid, Form } from 'semantic-ui-react';
import {
  InputOnChangeHandler,
  FormOnSubmitHandler
} from '../../typings/semantic-ui-react';
import { SingleDatePicker } from 'react-dates';
import { GoalRecord } from '../../redux/reducers';
import { DispatchMap } from '../../redux/actions/interfaces';
import { actionCreators as goalActions } from '../../redux/actions/goals';
import { actionCreators as goalEditorActions } from '../../redux/actions/goals-editor';
import * as moment from 'moment-timezone';

export type GoalSegmentEditorProps = DispatchMap<{
  saveGoal: typeof goalActions.post | typeof goalActions.put;
  editGoalDueDate: typeof goalEditorActions.editGoalDueDate;
  editGoalContent: typeof goalEditorActions.editGoalContent;
  focusInput?: boolean;
  onClose(): void;
  userId: number;
  goal: GoalRecord;
}>;

interface State {
  focusedDateField: boolean;
  inputValue: string | undefined;
}

const StyledInput = styled(Input)`
  border: 0 !important;
  padding-right: 5px;
  padding-bottom: 5px;
  width: 90%;
`;

class GoalSegmentEditor extends React.PureComponent<
GoalSegmentEditorProps,
State
> {
  state = {
    focusedDateField: false,
    inputValue: ''
  };

  handleInputChange: InputOnChangeHandler = (_, d) =>
    this.setState({ inputValue: d.value });

  handleDateFocusChange = () =>
    this.setState({ focusedDateField: !this.state.focusedDateField });

  handleDateChange = (date: moment.Moment | null) => {
    const { id } = this.props.goal;
    this.props.editGoalDueDate(id, date ? date.toISOString() : null);
  };

  handleSubmit: FormOnSubmitHandler = ev => {
    ev.preventDefault();
    const { goal, onClose, userId } = this.props;
    this.props.editGoalContent(goal.id, this.state.inputValue);
    this.props.saveGoal(userId, goal.id);
    onClose();
  };

  render() {
    const { goal } = this.props;

    return (
      <Segment>
        <Form onSubmit={this.handleSubmit}>
          <div style={{ marginTop: '10px', marginLeft: '5px' }}>
            <StyledInput
              required
              focus={!!this.props.focusInput}
              onChange={this.handleInputChange as never}
              defaultValue={goal.content}
              placeholder="Start here to create a new goal!"
            />
          </div>
          <div style={{ margin: '10px 0 10px 5px' }}>
            <span style={{ paddingRight: '5px' }}>Due:</span>
            <SingleDatePicker
              required
              focused={this.state.focusedDateField}
              date={
                this.props.goal.done_by
                  ? moment.utc(this.props.goal.done_by)
                  : null
              }
              onDateChange={this.handleDateChange as never}
              onFocusChange={this.handleDateFocusChange}
              id="due-date"
            />
            {goal.completed_at && (
              <span style={{ paddingLeft: '5px' }}>
                Completed: {moment.utc(goal.completed_at).format('ll')}
              </span>
            )}
          </div>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column floated="right">
                <Button
                  onClick={this.props.onClose}
                  type="button"
                  size="mini"
                  secondary
                >
                  Cancel
                </Button>
                <Button style={{ marginLeft: '5px' }} size="mini" primary>
                  Save
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>
    );
  }
}

export default GoalSegmentEditor;
