import * as React from 'react';
import { Location } from 'history';

import { ERR_USER_ID_INVALID } from '../../../errors';
import UserNavBar from '../../../components/phx/nav/UserNavBar';
import UserProfileBannerContainer from './UserProfileBannerContainer';
import UserTasksHistoryContainer from '../UserTasksHistoryContainer';

export interface UserHistoryContainerProps {
  id?: number;
  location?: Location;
}

export const WrappedComponent: React.FunctionComponent<UserHistoryContainerProps> = ({ id, location }) => {
  if (id === undefined) throw new Error(ERR_USER_ID_INVALID);
  return (
    <div>
      <UserProfileBannerContainer id={id} />
      <div style={{ width: '95%', margin: 'auto' }}>
        <UserNavBar location={location} id={id} />
        <UserTasksHistoryContainer userId={id} />
      </div>
    </div>
  );
};

export default WrappedComponent;
