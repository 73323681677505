import { useQuery } from '@apollo/client';
import * as React from 'react';

import { Loader } from 'semantic-ui-react';
import { User } from '../../graphql/models/';
import { GET_USER_EDUCATION_CONTEXT } from '../../graphql/queries/User';
import { ERR_USER_PARSE_FAIL } from '../../errors';
import EducationContext from '../../components/user/EducationContext';

interface Response {
  user?: User | null;
}

interface Props {
  userId: number;
}

const EducationContextContainer: React.FC<Props> = ({ userId }) => {
  const { loading, error, data } = useQuery<Response, { id: string }>(
    GET_USER_EDUCATION_CONTEXT,
    { variables: { id: userId.toString() } }
  );
  if (loading) {
    return <div><Loader active inline size="tiny" /></div>;
  }

  if (error) {
    throw new Error(`${ERR_USER_PARSE_FAIL}: ${error}`);
  }

  if (data && data.user) {
    return <EducationContext user={data.user} />;
  }

  return null;
};

export default EducationContextContainer;
