/* eslint-disable @typescript-eslint/indent */
import * as moment from 'moment-timezone';
import { Pathway, UserStatus } from '../graphql/models';
import { EnrollmentStatus } from '../graphql/models/bff';

/**
 * It's valid for any JSON coming down the wire to omit most properties or set them to null; this type makes it easier
 * to declare them. It maps over the generic type properties to make sure they're all optional, readonly, and possibly
 * null.
 *
 * Be careful that nested objects also use this type as well (if appropriate).
 *
 * Non optional properties **SHOULD** be validated by the parser.
 */
type OptionalProperties<T> = { readonly [K in keyof T]?: T[K] | null };

export interface Entity {
  readonly id: number;
}

export interface CompositeEntity {
  readonly id: string;
}

export interface MessageEntity {
  readonly id: string | number;
}

export interface EntityWithUuid extends Entity {
  readonly uuid: string;
}

export interface Admin
  extends Entity,
    OptionalProperties<{
      email: string;
      role: string;
      firstName: string;
      lastName: string;
      avatarUrl: string;
      avatarFileName: string;
      avatarContentType: string;
      avatarFileSize: string;
      coachBio: string;
      coachCity: string;
      coachState: string;
      coachPhoneDirect: string;
      coachPhoneIndirect: string;
      coachScheduleUrl: string;
      oldCoachId: number;
      avatarUpdatedAt: string;
      invitationToken: string;
      invitationCreatedAt: string;
      invitationSentAt: string;
      invitationAcceptedAt: string;
      invitationLimit: string;
      invitedById: string;
      invitedByType: string;
      invitationsCount: string;
      provider: string;
      uid: string;
      uuid: string;
      timezoneName: string;
      preferences: string;
      signatureFileName: string;
      signatureContentType: string;
      signatureFileSize: string;
      signatureUpdatedAt: string;
      videoUrls: {};
      oauthUid: string;
      oauthProvider: string;
      coachQualifications: string;
    }> {}

export enum GoalStatus {
  DONE = 'done',
  ONGOING = 'ongoing'
}

export interface Goal
  extends CompositeEntity,
    OptionalProperties<{
      content: string;
      status: GoalStatus;
      completed_at: string;
      done_by: string | null;
      /* vvv fields for new User Type from BFF vvv */
      // Note that the ID from the BFF is a number, though we're treating it as a string here
      completedAt: string;
      doneBy: string | null;
    }> {}

export interface Client {
  id: number;
  name: string;
  logo: string;
}

export type CommunicationPreferences = OptionalProperties<{
  contactStartHour: number;
  contactEndHour: number;
  contactNative: boolean;
  contactCall: boolean;
  contactText: boolean;
  contactEmail: boolean;
  contactDaysOfWeek: number[];
}>;

export type VacationInfo = OptionalProperties<{
  vacationStartDate: string | moment.Moment;
  vacationEndDate: string | moment.Moment;
}>;

export interface RecentMessage {
  conversation_id: string;
  user_id: number;
  team_id: number;
  newest_unseen_message: Message;
}

export interface UserMessagingMetaData {
  messageUuids: string[];
  totalPages?: number;
  page?: number;
  hasUnread?: boolean;
}

export interface UserMessagePreview {
  body: string;
  transmittedAt?: string;
  createdAt?: string;
}

export interface User
  extends Entity,
    OptionalProperties<{
      avatarUrl: string;
      addressOne: string;
      addressTwo: string;
      bio: string;
      bmi: number;
      city: string;
      client: Client;
      contactMethod: string;
      communicationPreferences: CommunicationPreferences;
      country: string;
      currentWeek: number;
      dateOfBirth: string;
      engagementStreak: number;
      email: string;
      firstName: string;
      gender: string;
      goals: string[];
      height: number;
      name: string;
      lastActive: string;
      lastSignInAt: string;
      lastName: string;
      lastNps: number;
      lastActivityAt: string;
      lastTaskResolvedAt: string;
      messaging: UserMessagingMetaData;
      messagePreview: UserMessagePreview;
      occupation: string;
      phoenix: boolean;
      painLogs: number[];
      pathways: Pathway[];
      phoneNumber: string;
      postalCode: string;
      screener: number;
      surgery: number;
      surgeryLogs: number[];
      stars: number;
      stateOfResidence: string;
      tags: string[];
      teamId: number;
      team: Team;
      timezone: string;
      timezoneId: string;
      vacationInfo: VacationInfo;
      weekIndex: number;
      weeklyMetrics: string[];
      weight: number;
      workouts: string[];
      conversationIds: string[];
      coachingQuestions: CoachingQuestions;
      userStatus: UserStatus;
      tasks: Task<AnyTaskExtras>[];
      uuid: string;
      coverages: Coverages;
      carePlans: CarePlan[];
      enrollmentStatus: EnrollmentStatus[];
    }> {}

export type CoachingQuestions = OptionalProperties<{
  key_reasons: string;
  imagine_free: string;
  foresee_barriers: string;
}>;

// TODO: CT-1677 remove obsolete task kinds
export enum TaskKind {
  painUptick = 'PainUptick',
  healthLogNote = 'HealthLogNote',
  surgeryIncr = 'SurgeryIncr',
  reEngager = 'ReEngager',
  threeDayInactive = 'three_day_inactive',
  tenDayInactive = 'ten_day_inactive',
  fifteenDayInactive = 'fifteen_day_inactive',
  accountabilityOutreach = 'accountability_outreach',
  inactiveThisWeek = 'inactive_this_week',
  oneWeekInactive = 'one_week_inactive',
  inactiveTwoWeeks = 'two_week_inactive',
  inactiveThreeWeeks = 'three_week_inactive',
  inactiveFourWeeks = 'four_week_inactive',
  inactiveFiveWeeks = 'five_week_inactive',
  firstEverEtAttempted = 'first_ever_et_session_attempted',
  firstEverEtSessionCompleted = 'first_ever_et_session_completed',
  firstEtSessionThisWeek = 'first_et_session_this_week',
  thirdEtSessionThisWeek = 'third_et_session_this_week',
  thirteenPlusInactiveLastWeek = 'thirteen_plus_inactive_last_week',
  thirteenPlusActiveLastWeek = 'thirteen_plus_active_last_week',
  thirteenPlusActiveThisWeek = 'thirteen_plus_active_this_week',
  newHighUtilizationUser = 'new_high_utilization_user',
  yearTwoInactiveLastWeek = 'year_two_inactive_last_week',
  yearTwoActiveLastWeek = 'year_two_active_last_week',
  yearTwoActiveThisWeek = 'year_two_active_this_week',
  userTransitionToOnboarding = 'user_transition_to_onboarding',
  userTransitionToCoaching = 'user_transition_to_coaching',
  returningUserToOnboard = 'returning_user_to_onboard',
  kitDeliveredNoEt = 'kit_delivered_no_et',
  ensoYearTwo = 'enso_year_two',
  performSurgeryOutreach = 'perform_surgery_outreach',
  readyForCoaching = 'ready_for_coaching',
  default = ''
}

export const OrderedDoTaskKindTitles: { [key: string]: string } = {
  [TaskKind.returningUserToOnboard]: 'Returning User to Onboard',
  [TaskKind.userTransitionToOnboarding]: 'New User to Onboard',
  [TaskKind.userTransitionToCoaching]: 'New User to Coach',
  [TaskKind.kitDeliveredNoEt]: 'Kit Delivered No Et',
  [TaskKind.inactiveThisWeek]: 'Inactive This Week',
  [TaskKind.oneWeekInactive]: '1+ Week Inactive',
  [TaskKind.inactiveTwoWeeks]: '2+ Weeks Inactive',
  [TaskKind.inactiveThreeWeeks]: '3+ Weeks Inactive',
  [TaskKind.inactiveFourWeeks]: '4+ Weeks Inactive',
  [TaskKind.inactiveFiveWeeks]: '5+ Weeks Inactive',
  [TaskKind.firstEtSessionThisWeek]: 'First ET This Week',
  [TaskKind.thirdEtSessionThisWeek]: 'Third ET This Week',
  [TaskKind.accountabilityOutreach]: 'Accountability',
  [TaskKind.thirteenPlusInactiveLastWeek]: 'Wk 13+ Inactive Last Wk',
  [TaskKind.thirteenPlusActiveLastWeek]: 'Wk 13+ Active Last Wk',
  [TaskKind.thirteenPlusActiveThisWeek]: 'Wk 13+ Active This Wk',
  [TaskKind.yearTwoInactiveLastWeek]: 'Y2 Inactive Last Wk',
  [TaskKind.yearTwoActiveLastWeek]: 'Y2 Active Last Wk',
  [TaskKind.yearTwoActiveThisWeek]: 'Y2 Active This Wk',
  [TaskKind.ensoYearTwo]: 'Enso'
};

export const UnreadMessageTitles: { [key: string]: string } = {
  unreadMessage: 'Unread Messages'
};

export const ClinicalTaskKindTitles: { [key: string]: string } = {
  [TaskKind.surgeryIncr]: 'Surgery Trend',
  [TaskKind.painUptick]: 'Pain Uptick',
  [TaskKind.healthLogNote]: 'Health Log Note',
  [TaskKind.firstEverEtAttempted]: 'First Ever ET Attempted',
  [TaskKind.firstEverEtSessionCompleted]: 'First Ever ET',
  [TaskKind.reEngager]: 'Re-engager',
  [TaskKind.performSurgeryOutreach]: 'EMO',
  [TaskKind.readyForCoaching]: 'Ready for Coaching'
};

export enum StreamKind {
  low_activity = 'LOW_ACTIVITY',
  medium_activity = 'MEDIUM_ACTIVITY',
  high_activity = 'HIGH_ACTIVITY'
}

export enum TaskStatuses {
  Pending = 'pending',
  Completed = 'completed',
  Rejected = 'rejected',
  Expired = 'expired',
  Obsolete = 'obsolete'
}

export interface Task<T>
  extends Entity,
    OptionalProperties<{
      coachId: number;
      category: TaskCategories;
      kind: TaskKind;
      extras: T;
      status: TaskStatuses;
      resolutionNote: string;
      user: number;
      team: number;
      createdAt: string | Date;
      updatedAt: string | Date;
    }> {}

export interface Team
  extends Entity,
    OptionalProperties<{
      name: string;
      usersCount: number;
      avatarUrl: string;
      currentWeek: number;
      productType: string;
      users: number[];
      newestUnseenMessages: number[];
      /* vvv Deprecated vvv */
      currentTeamWeekNumber: number;
    }> {}

export interface RuleDrivenExtras {
  increase?: number;
  weeks_reengaged?: number | null;
  note: string | null;
}

export interface SurgeryIncrExtras
  extends RuleDrivenExtras,
    OptionalProperties<{
      diff_1yr: number | null;
      diff_2yr: number | null;
      diff_5yr: number | null;
      last_log_1yr: number | null;
      last_log_2yr: number | null;
      last_log_5yr: number | null;
      diff1yr: number | null;
      diff2yr: number | null;
      diff5yr: number | null;
      lastLog1yr: number | null;
      lastLog2yr: number | null;
      lastLog5yr: number | null;
    }> {}

export interface SurgeryTrendsExtras
  extends RuleDrivenExtras,
    OptionalProperties<{
      weekIndex: number | null;
      diff: number | null;
      baselineLikelihood: number | null;
      currentLikelihood: number | null;
      referenceDiff: number | null;
      upcomingSurgeries: boolean;
      proceduresHaving: string | null;
    }> {}

export type RuleDrivenTask = Task<RuleDrivenExtras>;
export type SurgeryIncrTask = Task<SurgeryIncrExtras | SurgeryTrendsExtras>;

export interface TeamFeedExtras {
  team: Team;
  client: string;
  feed_count: number;
  text: string;
  url?: string;
}

export type TeamFeedTask = Task<TeamFeedExtras>;

export interface FunnelExtras {
  team: Team;
  description: string;
  num_users?: number;
  increase?: number;
  lastActive?: string;
  link_title?: string;
  link_url?: string;
}

export interface RestExtras {
  team: Team;
  note: string;
  score: number;
  source_title: string;
  source_url: string;
}

export interface YearTwoExtras {
  totalProgramWeeks?: number;
  yearTwoStartsAt?: string;
}

export interface EnsoYearTwoExtras {
  totalProgramWeeks?: number;
  ensoStartsAt?: string;
}

export type AnyTaskExtras = RuleDrivenExtras | SurgeryIncrExtras | SurgeryTrendsExtras;

export type AnyTask = RuleDrivenTask | SurgeryIncrTask;

export enum TaskCategories {
  Clinical = 'clinical',
  Engagement = 'engagement'
}

export interface TaskKinds {
  kind: TaskKind;
}

export const TaskDescriptions: { [key: string]: string } = {
  [TaskKind.reEngager]: 'User has reengaged.',
  /* eslint-disable max-len */
  [TaskKind.newHighUtilizationUser]:
    'Has been flagged as high-utilization risk based on their latest health survey. Please perform a warm hand-off to their PT via email.',
  [TaskKind.inactiveThisWeek]: 'Has not been active yet this week(Mon - Sun)',
  [TaskKind.thirteenPlusInactiveLastWeek]: 'This week 13+ user has been inactive last week',
  [TaskKind.thirteenPlusActiveLastWeek]: 'This week 13+ user has been active last week',
  [TaskKind.thirteenPlusActiveThisWeek]: 'This week 13+ user has been active this week',
  [TaskKind.oneWeekInactive]: 'Has not been active for 1 or more weeks',
  [TaskKind.inactiveTwoWeeks]: 'Has not been active for 2 or more weeks',
  [TaskKind.inactiveThreeWeeks]: 'Has not been active for 3 or more weeks',
  [TaskKind.inactiveFourWeeks]: 'Has not been active for 4 or more weeks',
  [TaskKind.inactiveFiveWeeks]: 'Has not been active for 5 or more weeks',
  [TaskKind.accountabilityOutreach]: 'Has not received a message from you in 3 days',
  [TaskKind.firstEverEtAttempted]: 'Attempted their first ever ET session',
  [TaskKind.firstEverEtSessionCompleted]: 'Completed their first ever ET session',
  [TaskKind.firstEtSessionThisWeek]: 'Completed their 1st ET session this week(Mon - Sun)',
  [TaskKind.thirdEtSessionThisWeek]: 'Completed their 3rd ET session this week(Mon - Sun)',
  [TaskKind.yearTwoInactiveLastWeek]: 'Was inactive last week',
  [TaskKind.yearTwoActiveLastWeek]: 'Was active last week',
  [TaskKind.yearTwoActiveThisWeek]: 'Has been active this week',
  [TaskKind.userTransitionToOnboarding]: 'Ready to receive their first message from their concierge',
  [TaskKind.userTransitionToCoaching]: 'Ready to receive their first message from their coach',
  [TaskKind.kitDeliveredNoEt]:
    'Member has not started their first ever ET session yet and their kit was delivered at least 2 days ago',
  [TaskKind.performSurgeryOutreach]: 'Has been identified as a high risk for surgery by HingeConnect or Surgery Tag',
  [TaskKind.readyForCoaching]: 'Ready to be transferred to coach'
};

export interface Note {
  id: number;
  author: {
    name: string;
    uuid: string;
  };
  userUuid?: string | null;
  body: string;
  noteType: NoteType | string;
  status: NoteStatus;
  createdAt: string;
  pdfReady: boolean;
  finishedAt?: string | null;
}

export enum NoteStatus {
  Draft = 'draft',
  Final = 'final'
}

export enum NoteType {
  CoachCall = 'Coach Call',
  ResourceShared = 'Resource Shared',
  ParticipantOps = 'Participant Ops',
  Goals = 'Goals',
  PersonalizationAndBarriers = 'Personalization/Barriers',
  CoachClinicalNote = 'Coach Clinical-Related Note',
  Miscellaneous = 'Miscellaneous',
  EmailHighlights = 'Email Highlights',
  PTEvaluation = 'PT Evaluation',
  PTFollowUpVisit = 'PT Follow Up Visit',
  PTDischargeNote = 'PT Discharge Note',
  PTPlanOfCare = 'PT Plan Of Care',
  PTConsultNote = 'PT Consult Note',
  PTCall = 'PT Call',
  PTCancellation = 'PT Cancellation',
  PTNoCall = 'PT No Call/No Show',
  PTReferralToHCP = 'PT Referral to HCP',
  BehavioralHealthConcern = 'Behavioral Health Concern',
  EMOIntakeCall = 'EMO Intake Call',
  EMOAdministrativeNote = 'EMO Administrative Note',
  EMONPNote = 'EMO NP Note',
  EMOPhysicianNote = 'EMO Physician Note',
  EMOReportSummary = 'EMO Report Summary',
  EMOCarePlan = 'EMO Care Plan'
}

export interface Workout
  extends CompositeEntity,
    OptionalProperties<{
      name: string;
      points_earned: number;
      points_max: number;
      updated_at: string;
      occurred_at: string;
      week_index: number;
    }> {}

export interface PainLog
  extends Entity,
    OptionalProperties<{
      created_at: string;
      note: string;
      pain: number;
      secondary_metric: number;
      week_index: number;
    }> {}

export interface SurgeryLog
  extends Entity,
    OptionalProperties<{
      submitted_at: string | Date;
      created_at: string | Date;
      surgery_free_text: string;
      week_index: number;
      surgery_likelihood_1yr: number;
      surgery_likelihood_2yr: number;
      surgery_likelihood_5yr: number;
    }> {}

export interface HealthSurgerySurvey {
  readonly id: number;
  submitted_at: string;
  week_index: number;
  surgery_likelihood_1yr: number;
  surgery_likelihood_5yr: number;
}

export interface Screener
  extends Entity,
    OptionalProperties<{
      created_at: string | Date;
      submitted_at: string | Date;
      pain: number;
      surgery_likelihood_1yr: number;
      surgery_likelihood_2yr: number;
      surgery_likelihood_5yr: number;
      surgery_free_text: string;
      secondary_metric: number;
    }> {}

export interface EducationReads
  extends CompositeEntity,
    OptionalProperties<{
      article_name: string;
      content_url: string;
      closed_at: string;
    }> {}

/** Weekly metric collection should have a composite primary key of `${user.id}${week_index}`. */
export type WeeklyMetric = OptionalProperties<{
  active: boolean;
  aerobic_workouts_goal: number;
  aerobic_workouts: number;
  begin_at: string;
  education_reads_goal: number;
  education_reads: number;
  exercises_goal: number;
  exercises: number;
  pain_log: number;
  points_earned: number;
  points_goal: number;
  week_index: number;
}>;

export enum MessageDirection {
  In = 'in',
  Out = 'out'
}

export enum MessageStatus {
  Failed = 'failed',
  Draft = 'draft',
  Delivered = 'delivered',
  Scheduled = 'scheduled',
  Transmitted = 'transmitted',
  Transmitting = 'transmitting',
  Pending = 'pending'
}

export enum MessageStatusV2 {
  Error = 'error',
  Pending = 'pending',
  Received = 'received',
  Transmitted = 'transmitted'
}

export enum MessageV2Types {
  Conversation = 'Conversation',
  Reminder = 'Reminder'
}

export interface MessageSocketData
  extends MessageEntity,
    OptionalProperties<{
      body: string;
      direction: MessageDirection;
      created_at: string;
      scheduled_for: string;
      status: MessageStatus;
      seen_at: string;
      conversation_id: string;
      transmitted_at: string;
    }> {}

export interface Message
  extends CompositeEntity,
    OptionalProperties<{
      body: string;
      direction: MessageDirection;
      createdAt: string;
      scheduledFor: string;
      status: MessageStatus;
      seenAt: string;
      conversationId: string;
      transmittedAt: string;
      userTimezone?: string;
    }> {}

export enum MessagePartyType {
  Admin = 'Admin',
  User = 'User'
}

export interface MessagePartyIdentifier {
  uuid: string;
  type: MessagePartyType;
}

export interface MessageV2 {
  uuid: string;
  type: string;
  status: MessageStatusV2;
  body: string;
  isUnread: boolean;
  sender: MessagePartyIdentifier;
  recipient: MessagePartyIdentifier;
  sendAt: string | null;
  transmittedAt: string;
  createdAt: string;
}

export interface Conversation
  extends CompositeEntity,
    OptionalProperties<{
      userId: number;
      teamId: number;
      type: string;
      subject: string;
      unseenMessageIds: string[];
      mostRecentMessageId: string;
      messageIds: string[];
      scheduledMessageIds: string[];
      createdAt: string;
      updatedAt: string;
      totalPages: number;
      currentPage: number;
    }> {}

export enum StatusNames {
  vacation = 'vacation',
  sick = 'sick',
  family_emergency = 'family_emergency',
  other = 'other',
  technical_issue = 'technical_issue',
  clinical_issue = 'clinical_issue'
}

export interface DateRange {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface Achievement {
  id: number;
  key: string;
  achievedDate: string;
}

export enum MassMessageTemplate {
  Week4InactiveFirst = 'week4InactiveFirst',
  Week4InactiveSecond = 'week4InactiveSecond',
  Week5NeverActive = 'week5NeverActive',
  Week5PreviouslyActive = 'week5PreviouslyActive',
  Week13PlusInactiveFor4Weeks = 'week13PlusInactiveFor4Weeks'
}

export const TemplateTodoMap: {
  [key: string]: TaskKind;
} = {
  [MassMessageTemplate.Week4InactiveFirst]: TaskKind.inactiveFourWeeks,
  [MassMessageTemplate.Week4InactiveSecond]: TaskKind.inactiveFourWeeks,
  [MassMessageTemplate.Week5NeverActive]: TaskKind.inactiveFiveWeeks,
  [MassMessageTemplate.Week5PreviouslyActive]: TaskKind.inactiveFiveWeeks,
  [MassMessageTemplate.Week13PlusInactiveFor4Weeks]: TaskKind.inactiveFourWeeks
};

export enum MessageSubheading {
  Week1Through12 = 'Wk 1-12',
  Week13Plus = 'Wk 13+'
}

export const TemplateSubheadingMap: {
  [key: string]: MessageSubheading;
} = {
  [MassMessageTemplate.Week4InactiveFirst]: MessageSubheading.Week1Through12,
  [MassMessageTemplate.Week4InactiveSecond]: MessageSubheading.Week1Through12,
  [MassMessageTemplate.Week13PlusInactiveFor4Weeks]: MessageSubheading.Week13Plus
};

export interface MassMessage {
  messageType: MassMessageTemplate;
  body: string;
  interpolations: string[];
}

export const TemplateInfoMap: {
  [key: string]: string;
} = {
  /* eslint-disable max-len */
  [MassMessageTemplate.Week4InactiveFirst]:
    'Users are eligible to receive this message if they have been inactive for 4 consecutive weeks and are in wks 1-12.',
  /* eslint-disable max-len */
  [MassMessageTemplate.Week4InactiveSecond]:
    'Users are eligible to receive this message if they have been inactive for 4 consecutive weeks and already received a 4+wk inactive task this week.',
  /* eslint-disable max-len */
  [MassMessageTemplate.Week5NeverActive]:
    'Users are eligible to receive this message if they have been inactive for 5 consecutive weeks and have never been active. Users who receive this message will automatically receive a strikethrough.',
  /* eslint-disable max-len */
  [MassMessageTemplate.Week5PreviouslyActive]:
    'Users are eligible to receive this message if they have been inactive for 5 consecutive weeks and have previously been active. Users who receive this message will automatically receive a strikethrough.',
  /* eslint-disable max-len */
  [MassMessageTemplate.Week13PlusInactiveFor4Weeks]:
    'Users are eligible to receive this message if they have been inactive for 4 consecutive weeks and are in wks 13+.'
};

interface Interpolation {
  name: string;
  value?: string | null;
}

export interface SendMassMessageUser {
  userUuid?: string | null;
  userPhoneNumber?: string | null;
  interpolations: Interpolation[];
}

export interface Coverages {
  [key: string]: {
    role: string;
    uuid: string;
    name: string;
  };
}

// TODO: add other fields from getUserById when we implement the request here ...ATL/OWL tickets?
export interface MessageData
  extends OptionalProperties<{
    page?: number;
    itemsPerPage?: number;
    hasNextPage?: boolean;
    hasUnread?: boolean;
  }> {
  messages: MessageV2[];
}
export interface GetUserById
  extends EntityWithUuid,
    OptionalProperties<{
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      team: Team;
      lastActive: string;
      lastSignInAt: string;
      weeksInactive: number;
      email: string;
      phoneNumber: string;
      bio: string;
      dateOfBirth: string;
      bmi: string;
      height: number;
      weight: number;
      occupation: string;
      gender: string;
      addressOne: string;
      addressTwo: string;
      city: string;
      postalCode: string;
      stateOfResidence: string;
      country: string;
      timezone: string;
      completeWeeklyActionablesCount: number;
      nps: number;
      surgeryLikelihoodNextYear: number;
      surgeryLikelihoodNext2Years: number;
      teamTimezone: string;
      contactMethod: string | null;
      tags: string[];
      vacationInfo: VacationInfo;
      client: Client;
      goals: Goal[]; // This differs from the existing User type's field.
      messageData: MessageData;
      enrollmentStatus: EnrollmentStatus[];
      engagementStreak: string;
    }> {}

export const StateAbbreviationsMap: { [key: string]: string } = {
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming'
};

export const StateRegulationCopy: { [key: string]: string } = {
  RX_REQD_FOR_DIAGNOSED_CHRONIC: 'Obtain an Rx before a video visit if no prior diagnosis in the past 90d',
  RX_REQD_FOR_30_DAY_PLUS_RETURN: 'Obtain an Rx if patient is returning for similar condition after 30d',
  HCP_DETAILS_REQD_IF_NOT_REFERRED: 'Obtain physician details before a video visit if not referred',
  INFORM_HCP_AFTER_FIRST_VISIT_DAYS: 'Inform physician 5d after a video visit'
};

export interface StateRegulation {
  key: string;
  value: string;
}

export enum CarePlanStatus {
  Open = 'Open',
  Closed = 'Closed'
}

export interface CarePlanItem {
  id: string;
  key: string;
  currentValue: string;
}

export interface Icd10 {
  code: string;
  description: string;
}

export enum Prescription {
  None = 'None',
  Obtained = 'Obtained'
}

export interface CarePlan {
  id: string;
  userId: string;
  title: string;
  status: CarePlanStatus;
  icd10: Icd10[];
  items: CarePlanItem[];
  expectedDischargeAt: string | Date;
  dischargeReason: string | null;
  openedAt: string | Date;
  closedAt?: string | Date | null;
}

export interface UserDataForCarePlan {
  uuid: string;
  stateOfResidence: string;
  pathways: Pathway[];
}

export enum ProgramName {
  Chronic = 'chronic',
  Acute = 'acute',
  Perisurgical = 'perisurgical',
  Prevention = 'prevention',
  ExpertMedicalOpinion = 'expert_medical_opinion',
  UnifiedV1 = 'unified_v1'
}

export enum IndicationName {
  Back = 'back',
  Neck = 'neck',
  Knee = 'knee',
  Shoulder = 'shoulder',
  Hip = 'hip',
  WholeBody = 'whole_body',
  Foot = 'foot',
  PelvicMaternity = 'pelvic_maternity',
  PelvicUrge = 'pelvic_urge',
  PelvicHypo = 'pelvic_hypo',
  PelvicPain = 'pelvic_pain'
}

export enum IndicationNameV3 {
  Back = 'Acute back',
  Neck = 'Acute neck',
  Knee = 'Acute knee',
  Shoulder = 'Acute shoulder',
  Hip = 'Acute hip',
  Foot = 'Acute foot',
  WholeBody = 'Prevention whole body',
  PelvicMaternity = 'Chronic pelvic maternity',
  PelvicUrge = 'Chronic pelvic urge',
  PelvicHypo = 'Chronic pelvic hypo',
  PelvicPain = 'Chronic pelvic pain'
}

export interface ProgramIndication {
  identifier: string;
  programName: ProgramName;
  indicationName: IndicationName | IndicationNameV3;
}

export enum TrackingStatus {
  UNKNOWN = 'Unknown',
  PRE_TRANSIT = 'Pre-transit',
  TRANSIT = 'In transit',
  DELIVERED = 'Delivered',
  RETURNED = 'Returned',
  FAILURE = 'Error',
  AVAILABLE_FOR_PICKUP = 'Pickup available',
  CANCELLED = 'Cancelled',
  LATE = 'Late'
}

export enum EnrollmentStatusTextMap {
  OptInTitle = 'Mark opted-in',
  OptOutTitle = 'Mark opted-out',
  OptInContent = 'Please confirm that this user has expressed a desire to opt-in to the Hinge Health program.',
  OptOutContent = 'Please confirm that this user has expressed a desire to opt-out of the Hinge Health program.',
  OptInStrikeThrough = 'Confirming will automatically remove the strike-through for this user, if they have contact methods set.',
  OptOutStrikeThrough = 'Confirming will automatically add a strike-through to this user.'
}

export enum PathwayStage {
  Onboarding = 'onboarding',
  Coaching = 'coaching'
}

export enum MemberAlertCategory {
  ClinicalIssues = 'ClinicalIssues',
  FamilyEmergency = 'FamilyEmergency',
  Other = 'Other',
  Sick = 'Sick',
  TechIssues = 'TechIssues',
  Vacation = 'Vacation'
}

export enum MemberAlertStatusEnum {
  Active = 'Active',
  Expired = 'Expired',
  Upcoming = 'Upcoming'
}

export interface MemberAlert {
  __typename?: 'MemberAlert';
  adminUuid: string;
  category: MemberAlertCategory;
  createdAt: string;
  description?: string | null;
  endAt: string;
  id: string;
  paused?: boolean | null;
  remindAt?: string | null;
  startAt: string;
  updatedAt: string;
  updatedByUuid?: string | null;
  userUuid: string;
}
