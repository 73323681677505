import * as moment from 'moment-timezone';
import { RootState } from '../reducers';
import { ConversationRecord } from '../reducers/conversations';
import { UserRecord } from '../reducers/user';
import { MessageDirection } from '../../api-client/interfaces';
import { createSelector } from 'reselect';
import * as R from 'ramda';
import { selectUser } from './user';

import { convertStrToNum } from '../../utils/number';
import { isNullOrUndefined } from 'util';
import { MessageRecord, State as MessagesState } from '../reducers/messages';

export const selectConversationsState = (state: RootState) => state.conversations;

const selectMessagesState = (state: RootState) => state.messages;

export const selectConversations = (state: RootState) => state.conversations.toArray();

export const selectConversation = (
  state: RootState,
  props: { conversationId: string }
): ConversationRecord | undefined => state.conversations.get(props.conversationId);

export const selectConversationUnseenMessages = createSelector(
  [selectConversation],
  conversation => conversation && conversation.unseenMessageIds
);

export const selectNewestUnseenMessage = createSelector(
  [selectConversationUnseenMessages, selectMessagesState],
  (unseenMessageIds, messagesState) => {
    if (!unseenMessageIds) return undefined;

    return messagesState.get(unseenMessageIds[0]);
  }
);

/**
 * From a conversation, selects a message to preview to the user
 *
 * @param messagesState The message state as stored in Redux
 * @param conversation
 * @returns A single MessageRecord from the provided conversation
 */
export const selectMessageToPreview = (
  messagesState: MessagesState,
  conversation?: ConversationRecord
): MessageRecord | undefined => {
  const newestUnseenMessage: MessageRecord | undefined =
    conversation && conversation.unseenMessageIds ? messagesState.get(conversation.unseenMessageIds[0]) : undefined;

  if (newestUnseenMessage) {
    return newestUnseenMessage;
  } else {
    return (
      (conversation && conversation.mostRecentMessageId &&
        messagesState.get(conversation.mostRecentMessageId)) ||
      undefined
    );
  }
};

/* Selects all conversations for the user */
export const makeSelectUserConversationsFactory = () =>
  createSelector(
    [selectUser, selectConversations],
    (user, conversations): ConversationRecord[] => {
      if (!user) return [];

      const userConversations = conversations.filter(conversation => conversation.userId === convertStrToNum(user.id));

      return userConversations;
    }
  );

export interface ConversationPreview {
  conversation: ConversationRecord;
  messagePreview?: MessageRecord;
}

export interface ComputedSortedConversationItem extends ConversationPreview {
  user: UserRecord;
}

export const isUserUnseenMessage = (message: MessageRecord) =>
  !message.seenAt && message.direction === MessageDirection.In;


export const conversationComparator = (a: ConversationPreview, b: ConversationPreview) => {
  const aMessagePreview = a.messagePreview;
  const bMessagePreview = b.messagePreview;

  if (!aMessagePreview && !bMessagePreview) return 0;
  if (!aMessagePreview) return 1;
  if (isNullOrUndefined(aMessagePreview.transmittedAt) && isNullOrUndefined(aMessagePreview.createdAt)) {
    return 1;
  }
  if (!bMessagePreview) return -1;
  if (isNullOrUndefined(bMessagePreview.transmittedAt) && isNullOrUndefined(bMessagePreview.createdAt)) {
    return -1;
  }
  const aDate = aMessagePreview.transmittedAt || (aMessagePreview.createdAt as string);
  const bDate = bMessagePreview.transmittedAt || (bMessagePreview.createdAt as string);

  if (isUserUnseenMessage(aMessagePreview) && !isUserUnseenMessage(bMessagePreview)) {
    return -1;
  }
  if (!isUserUnseenMessage(aMessagePreview) && isUserUnseenMessage(bMessagePreview)) {
    return 1;
  }

  return moment.utc(bDate).diff(moment.utc(aDate));
};

export const selectConversationPreviews = createSelector(
  [selectConversations, selectMessagesState],
  (conversations, messages): ConversationPreview[] =>
    conversations.map(c => ({
      conversation: c,
      messagePreview: selectMessageToPreview(messages, c)
    }))
);

export const selectUserConversationPreviews = createSelector(
  [selectUser, selectConversationPreviews],
  (user, conversationPreviews) => {
    if (!user) return [];
    return conversationPreviews.filter(
      conversationPreview =>
        conversationPreview.conversation.userId === convertStrToNum(user.id)
    );
  }
);

export const selectUserConversationItem = createSelector(
  [selectUser, selectUserConversationPreviews],
  (user, userConversationPreviews): ComputedSortedConversationItem | undefined => {
    if (!user) return undefined;
    const conversationPreview = R.sort(conversationComparator, userConversationPreviews)[0];
    return { ...conversationPreview, user };
  }
);

const removeUndefined = (items: (ComputedSortedConversationItem | undefined)[]) =>
  items.filter(i => i !== undefined) as ComputedSortedConversationItem[];

export const selectConversationItems = (
  state: RootState,
  props: { userIds: number[] }
): ComputedSortedConversationItem[] => {
  const selectedConversationItems = props.userIds.map(userId => selectUserConversationItem(state, { userId }));

  return removeUndefined(selectedConversationItems);
};

export const makeSelectSortedUsersByConversations = () =>
  createSelector(
    [selectConversationItems],
    conversationItems => conversationItems.sort(conversationComparator)
  );


/**
 * This is shouldn't be memoized because it needs to
 * recompute every tick of the heartbeat.
 */
export const doesTeamHaveUnreadMessages = (state: RootState, props: { teamId: number }) => {
  const conversationsWithUnseen = selectConversations(state).filter(
    conversation =>
      conversation.teamId === props.teamId && conversation.unseenMessageIds && conversation.unseenMessageIds.length > 0
  );

  return conversationsWithUnseen.length > 0;
};

export const selectConversationTotalPages = createSelector(
  [selectConversation],
  conversation => conversation && conversation.totalPages
);

export const selectConversationCurrentPage = createSelector(
  [selectConversation],
  conversation => conversation && conversation.currentPage
);
