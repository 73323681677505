import * as React from 'react';
import { useDispatch } from 'react-redux';
import { SHOW_NOTIFICATION } from '../../../redux/constants';
import './EnsoYearTwoDetail.scss';

import { EnsoYearTwoExtras } from '../../../api-client/interfaces';
import { formatEnsoProgress } from './commons';

const DEFAULT_PROGRAM_WEEKS = 12;
interface Props {
  userId: number;
  staticDescription: string;
  extras: EnsoYearTwoExtras;
}

const EnsoYearTwoTaskDetails = ({
  staticDescription,
  extras,
  userId
}: Props) => {
  const dispatch = useDispatch();
  const { totalProgramWeeks = DEFAULT_PROGRAM_WEEKS, ensoStartsAt } = extras;

  React.useEffect(() => {
    if (!ensoStartsAt) {
      dispatch({
        payload: {
          message: `Missing enso tag starts at date for user ${userId}. Please add an Enso tag for this user.`,
          httpStatus: 'error',
          type: 'error'
        },
        type: SHOW_NOTIFICATION
      });
    }
  }, []);

  return (
    <div className='enso-year-two-task-details'>
      <div data-testid='staticDescription'>{staticDescription}</div>
      <div data-testid='ensoProgress'>{formatEnsoProgress(totalProgramWeeks, ensoStartsAt)}</div>
    </div>
  );
};

export default EnsoYearTwoTaskDetails;
