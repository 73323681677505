export const GET_MESSAGES_FOR_USER = `
query getUserById($userId: ID!, $adminUuid: ID!, $itemsPerPage: Int, $page: Int) {
	getUserById(userId: $userId) {
	  id
    firstName
    lastName
    lastActive
    phoneNumber
    messageData(adminUuid: $adminUuid, itemsPerPage: $itemsPerPage, page: $page) {
      messages {
        uuid
        type
        status
        body
        isUnread
        sender {
          uuid
          type
        }
        recipient {
          uuid
          type
        }
        createdAt
        updatedAt
        sendAt
        transmittedAt
      }
    }
  }
}
`;

export const CREATE_MESSAGE = `
mutation createMessage(
  $body: String!,
  $sendAt: String,
  $senderUuid:String!,
  $recipientUuid: String!,
  $recipientNumber: String!
) {
  createMessage(
    body: $body,
    sendAt: $sendAt,
    senderUuid: $senderUuid,
    recipientUuid:$recipientUuid,
    recipientNumber: $recipientNumber
  ) {
    uuid
    type
    status
    body
    isUnread
    sender {
      uuid
      type
    }
    recipient {
      uuid
      type
    }
    createdAt
    updatedAt
    sendAt
    transmittedAt
  }
}
`;
