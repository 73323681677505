import * as React from 'react';
import { Typography } from '@material-ui/core';
import ModalWrapper from '../../components/modal/ModalWrapper';

interface OnboardedTransitionConfirmModalProps {
  open: boolean;
  onDismiss(): void;
  onConfirm(): void;
  errorMessage?: string;
  disableConfirmButton: boolean;
}

const confirmModalProps = {
  title: 'Confirm transfer to coach',
  body: (
    <div>
      <Typography variant='body1' color='textSecondary'>
        Please confirm that you would like to transfer this user to their primary coach. This action cannot be undone.
      </Typography>
      <br></br>
      <div>
        <Typography variant='body1' color='textSecondary'>
          Confirming this action will:
        </Typography>
        <ul>
          <li>
            <Typography variant='body1' color='textSecondary'>
              Remove the user from your caseload
            </Typography>
          </li>
          <li>
            <Typography variant='body1' color='textSecondary'>
              Notify the primary coach
            </Typography>
          </li>
        </ul>
      </div>
    </div>
  ),
  confirmBtnText: 'CONFIRM',
  dismissBtnText: 'CANCEL'
};

const OnboardedTransitionConfirmModal: React.FC<OnboardedTransitionConfirmModalProps> = ({
  open,
  onDismiss,
  onConfirm,
  errorMessage,
  disableConfirmButton
}) => (
  <ModalWrapper
    openModal={open}
    modalContent={confirmModalProps}
    onDismiss={onDismiss}
    onConfirm={onConfirm}
    errorContent={errorMessage}
    disableConfirmButton={disableConfirmButton}
  />
);

export default OnboardedTransitionConfirmModal;
