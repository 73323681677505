import { TypedRecord, recordify } from 'typed-immutable-record';
import { Map, Set } from 'immutable';

import { RootAction } from '../actions';
import {
  SELECT_USER,
  SELECT_ALL_USERS,
  CLEAR_SELECTED_USER,
  CLEAR_ALL_USERS,
  SEARCH_USER
} from '../constants';
import { ChooseUserAction, ClearUserAction, ChooseAllUserAction, SearchUserAction } from '../actions/team-page';
import { update } from '../../utils/entity';

interface TeamPageMap {
  selectedUserIds: Set<number>;
  selectedUsersByTeam: Map<string, Set<number>>;
  searchUsersConfig: Map<string, string[]>;
  searchString?: string;
}

export interface TeamPageRecord extends TypedRecord<TeamPageRecord>, TeamPageMap { }

export type State = TeamPageRecord;

export const initialState: State = recordify<TeamPageMap, TeamPageRecord>({
  selectedUserIds: Set(),
  selectedUsersByTeam: Map(),
  searchUsersConfig: Map({
    keys: [
      'firstName',
      'lastName',
      'id',
      'tags'
    ]  // property keys to search users with. FIXME CA-138 implement recursive keys
  }),
  searchString: undefined
});

const selectUser = (state: State, action: ChooseUserAction) => {
  const { teamName, userId } = action.payload;
  let newState = state.update('selectedUserIds', (userIds) => userIds && userIds.add(userId));
  if (teamName) {
    let teams = state.get('selectedUsersByTeam');
    const selectedUsersByTeam = teams.update(teamName, (set?: Set<number>) => set ? set.add(userId) : Set([userId]));

    teams = teams.mergeDeepWith(update, selectedUsersByTeam);
    newState = newState.set('selectedUsersByTeam', teams);
  }
  return newState;
};

const clearSelectedUser = (state: State, action: ClearUserAction) => {
  const { teamName, userId } = action.payload;
  let newState = state.update('selectedUserIds', (userIds) => userIds && userIds.delete(userId));
  if (teamName) {
    let teams = state.get('selectedUsersByTeam');
    const selectedUsersByTeam = teams.update(teamName, (set?: Set<number>) => set && set.delete(userId));

    teams = teams.mergeDeepWith(update, selectedUsersByTeam);
    newState = newState.set('selectedUsersByTeam', teams);
  }
  return newState;
};

const selectAllUsers = (state: State, action: ChooseAllUserAction) => {
  const { userIds } = action.payload;
  return state.set('selectedUserIds', Set(userIds));
};

const setSearchConfig = (state: State, action: SearchUserAction) => {
  const { searchString } = action.payload;
  if (searchString) {
    return state.set('searchString', searchString);
  }
  return state;
};

export const reducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case SELECT_USER:
      return selectUser(state, action);
    case SELECT_ALL_USERS:
      return selectAllUsers(state, action);
    case CLEAR_SELECTED_USER:
      return clearSelectedUser(state, action);
    case CLEAR_ALL_USERS:
      return initialState;
    case SEARCH_USER:
      return setSearchConfig(state, action);
    default:
      return state;
  }
};
