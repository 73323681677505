import { Conversation, ConversationsResponse, Message, TodoResponse } from '../../api-client';
import { ConversationsListParams } from '../../api-client/endpoints';

import { ThunkAction, PayloadAction } from './interfaces';
import { getApiClient, selectAdmin } from '../selectors';
import * as C from '../constants';
import { TeamRecord, ConversationRecord, UserRecord } from '../reducers';
import { ERR_CONVERSATIONS_PARSE_FAIL } from '../../errors';
import { AddUnseenMessagesCountAction, UpdateUnseenMessagesCountAction, UpdateTaskKindResultsAction } from './ui-state';
import { GET_ALL_CONVERSATIONS, GET_CONVERSATIONS } from '../../graphql/queries';
import * as Sentry from '@sentry/browser';

export interface UpdateUserConversationPayload {
  user: UserRecord;
  conversations: number[];
}

export interface UpdateTeamUnseenMessagesPayload {
  team: TeamRecord;
  newest_unseen_messages: number[];
}

export interface UpdateConversationUnseenMessagesPayload {
  conversation: ConversationRecord;
  message: Message;
}

export interface AddUnseenMessagesPayload {
  conversation: ConversationRecord;
  unseen_messages?: Message[] | null;
}

export interface AddUnseenMessagesAction extends PayloadAction<TodoResponse> {
  type: typeof C.ADD_UNSEEN_MESSAGES_TO_LIST | typeof C.ADD_UNSEEN_MESSAGES;
}

export type ConversationActions = AddUnseenMessagesAction;

export function getUnseenMessages(
  params?: ConversationsListParams,
  noUpdate?: boolean
): ThunkAction<Promise<TodoResponse | void>> {
  return (dispatch, getState) => {
    const state = getState();
    const api = getApiClient(state);
    const kind = 'unreadMessage';
    const admin = selectAdmin(state);

    return api.conversations
      .unseen_messages({
        query: GET_ALL_CONVERSATIONS,
        variables: { adminUuid: admin.uuid }
      })
      .then(res => {
        let countByUsers = {};
        if (res.entities.conversations) {
          countByUsers = actionCreators.getFormattedCountByUsers(Object.values(res.entities.conversations));
        }
        if (noUpdate) {
          dispatch<UpdateUnseenMessagesCountAction>({
            payload: countByUsers,
            type: C.UPDATE_MULTI_UNREAD_MESSAGE_COUNT
          });
          dispatch<AddUnseenMessagesAction>({
            payload: res,
            type: C.ADD_UNSEEN_MESSAGES
          });
        } else {
          dispatch({ payload: res, type: C.ENTITIES_FETCHED });
          dispatch<UpdateTaskKindResultsAction>({
            type: C.UPDATE_TASK_KIND_RESULTS,
            payload: {
              kind,
              teamIds: res.result.teams,
              userIds: res.result.users,
              conversationIds: res.result.conversations
            }
          });
          dispatch<AddUnseenMessagesCountAction>({
            payload: countByUsers,
            type: C.ADD_UNREAD_MESSAGE_COUNT
          });
          dispatch<AddUnseenMessagesAction>({
            payload: res,
            type: C.ADD_UNSEEN_MESSAGES_TO_LIST
          });
        }
      })
      .catch(e => {
        Sentry.captureException(`${ERR_CONVERSATIONS_PARSE_FAIL}, error message: ${e.message}`);
        return Promise.reject(e);
      });
  };
}

export const actionCreators = {
  getConversations(params: ConversationsListParams): ThunkAction<Promise<ConversationsResponse[] | void>> {
    return (dispatch, getState) => {
      const state = getState();
      const api = getApiClient(state);
      const adminUuid = selectAdmin(state)?.uuid;

      const getConversationsEndpoint = params?.callNewEndpoint ? api.conversations.listNew : api.conversations.list;

      return getConversationsEndpoint({
        query: GET_CONVERSATIONS,
        variables: {
          page: 0,
          adminUuid,
          ...params
        }
      })
        .then(res => {
          dispatch({ payload: res, type: C.ENTITIES_FETCHED });
        })
        .catch(e => {
          Sentry.captureException(`${ERR_CONVERSATIONS_PARSE_FAIL}, error message: ${e.message}`);

          return Promise.reject(e);
        });
    };
  },
  getFormattedCountByUsers(conversations: Conversation[]): { userId: number; count: number }[] {
    return conversations.filter((conversation) => conversation.teamId)?.map((conversation: Conversation) => ({
      userId: conversation.userId || -1,
      count: conversation.unseenMessageIds?.length || 0
    })) || {};
  }
};
