import { OptionalProperties } from '../index';

export enum MessageStatusV2 {
  Error = 'error',
  Pending = 'pending',
  Received = 'received',
  Transmitted = 'transmitted'
}

export enum MessagePartyType {
  Admin = 'Admin',
  User = 'User'
}

export enum MessageV2Type {
  Conversation = 'Conversation',
  Reminder = 'Reminder'
}

export type MessagePartyIdentifier = OptionalProperties<{
  uuid: string;
  type: MessagePartyType;
}>;

export type MessageV2 = OptionalProperties<{
  uuid: string;
  type: MessageV2Type;
  status: MessageStatusV2;
  body: string;
  isUnread: boolean;
  sender: MessagePartyIdentifier;
  recipient: MessagePartyIdentifier;
  sendAt: string | null;
  transmittedAt: string;
  createdAt: string;
}>;

export interface MessageData
  extends OptionalProperties<{
    page?: number;
    itemsPerPage?: number;
    hasNextPage?: boolean;
    hasUnread?: boolean;
  }> {
  messages: MessageV2[];
}
