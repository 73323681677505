
export function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function pluralize(word: string, count: number) {
  if ((count <= 1 && count >= -1) || isNaN(count)) return word;
  return `${word}s`;
}

export const toNormalizedWords = (val: string): string => {
  const response = val
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, function(str) {
      return str.toUpperCase();
    })
    .trim();

  return response;
};
