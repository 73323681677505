import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import * as React from 'react';
import styled from 'styled-components';
import { SingleDatePicker, SingleDatePickerShape } from 'react-dates';
import { Today } from '@material-ui/icons';
import { TEXT_SECONDARY } from '../../colors';

type Props = SingleDatePickerShape;

const StyledTodayIcon = styled(Today)`
  color: ${TEXT_SECONDARY};
`;

const StyledSingleDatePickerWrapper = styled.div`
  display: flex;
  border-radius: 0;
  border-bottom: 1px solid ${TEXT_SECONDARY};
  .SingleDatePickerInput {
    display: flex;
  }
  .SingleDatePickerInput_calendarIcon {
    padding: 0;
  }
  .DateInput_input__focused {
    border-bottom: 0;
  }
`;

const Datepicker: React.FC<Props> = ({
  date,
  onDateChange,
  focused,
  onFocusChange,
  id,
  placeholder,
  small = true,
  numberOfMonths = 1,
  noBorder = true,
  customInputIcon = <StyledTodayIcon />,
  inputIconPosition = 'after'
}) => (
  <StyledSingleDatePickerWrapper>
    <SingleDatePicker
      date={date}
      onDateChange={onDateChange}
      focused={focused}
      onFocusChange={onFocusChange}
      id={id}
      placeholder={placeholder}
      small={small}
      numberOfMonths={numberOfMonths}
      noBorder={noBorder}
      customInputIcon={customInputIcon}
      inputIconPosition={inputIconPosition}
    />
  </StyledSingleDatePickerWrapper>
);

export default Datepicker;
