import * as React from 'react';
import { useDispatch } from 'react-redux';
import { SHOW_NOTIFICATION } from '../../../redux/constants';
import './YearTwoDetail.scss';

import { YearTwoExtras } from '../../../api-client/interfaces';
import { formatYearTwoProgress } from './commons';

const DEFAULT_PROGRAM_WEEKS = 12;
interface Props {
  userId: number;
  staticDescription: string;
  extras: YearTwoExtras;
}

const YearTwoTaskDetails: React.FC<Props> = ({
  staticDescription,
  extras,
  userId
}) => {
  const dispatch = useDispatch();
  const { totalProgramWeeks = DEFAULT_PROGRAM_WEEKS, yearTwoStartsAt } = extras;

  React.useEffect(() => {
    if (!yearTwoStartsAt) {
      dispatch({
        payload: {
          message: `Missing year two starts at date for user ${userId}. Please add a Year 2 tag for this user.`,
          httpStatus: 'error',
          type: 'error'
        },
        type: SHOW_NOTIFICATION
      });
    }
  }, []);

  return (
    <div className="year-two-task-details">
      <div data-testid="staticDescription">{staticDescription}</div>
      <div data-testid="yearTwoProgress">{formatYearTwoProgress(totalProgramWeeks, yearTwoStartsAt)}</div>
    </div>
  );
};

export default YearTwoTaskDetails;
