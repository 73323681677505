import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import * as React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Header, Form, Message } from 'semantic-ui-react';
import * as moment from 'moment-timezone';
import * as R from 'ramda';

import { UserRecord } from '../../../redux/reducers';
import { putUserContactInfo, EditContactParams } from '../../../redux/actions/user';
import { DispatchMap } from '../../../redux/actions/interfaces';
import {
  InputOnChangeHandler,
  FormOnSubmitHandler,
  DropdownOnChangeHandler,
  CheckboxOnChangeHandler
} from '../../../typings/semantic-ui-react';
import { DateRangePicker } from 'react-dates';
import { Moment } from 'moment';
import { mixpanelTrack } from '../../../mixpanel/mixpanel';

type contactMethods = 'email' | 'call' | 'sms';

// contact methods are separated by single spaces
// the api recognizes null as a valid value for the lack of contact methods
export const removeContactMethod = (
  toDelete: contactMethods,
  userContactMethod?: string | null
): string | null => {
  if (!userContactMethod) return null;
  const indexOfMethod = userContactMethod.indexOf(toDelete);
  const listOfMethods = userContactMethod.split(' ');

  if (indexOfMethod === 0 && listOfMethods.length === 1) {
    return null;
  } else if (indexOfMethod === -1) {
    return userContactMethod;
  } else {
    return R.without(toDelete, listOfMethods).join(' ');
  }
};

export const addContactMethod = (
  toAdd: contactMethods,
  userContactMethod?: string | null
): string => {
  if (!userContactMethod) return toAdd;
  // contact method already exists, don't duplicate
  if (userContactMethod.indexOf(toAdd) > -1) return userContactMethod;
  return `${userContactMethod} ${toAdd}`;
};

const alwaysFalse = R.always(false);
const states = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME',
  'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NM', 'NV', 'NH', 'NJ', 'NY' /*  🍎  */, 'NC', 'ND', 'OH', 'OK', 'OR',
  'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY', 'AS', 'DC', 'FM', 'GU', 'MH', 'MP',
  'PW', 'PR', 'VI', 'AE', 'AA', 'AP'
];
const stateOptions = states.map((state) => ({ text: state, value: state }));

export interface ContactEditModalProps {
  open: boolean;
  onClose(): void;
  userId: number;
  editedUser: UserRecord | undefined;
}

type ConnectProps = DispatchMap<{
  putUserContactInfo: typeof putUserContactInfo;
}>;

type Props = ContactEditModalProps & ConnectProps;

interface State extends EditContactParams {
  focusedDateInput: 'endDate' | 'startDate' | null;
  showError: boolean;
}

class ContactEditModal extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showError: false,
      focusedDateInput: null,
      phoneNumber: '',
      email: '',
      addressOne: '',
      city: '',
      stateOfResidence: '',
      postalCode: '',
      contactMethod: '',
      vacationInfo: {
        vacationStartDate: null,
        vacationEndDate: null
      }
    };
  }

  state: State = {
    showError: false,
    focusedDateInput: null,
    phoneNumber: this.props.editedUser?.phoneNumber,
    email: this.props.editedUser?.email,
    addressOne: this.props.editedUser?.addressOne,
    city: this.props.editedUser?.city,
    stateOfResidence: this.props.editedUser?.stateOfResidence,
    postalCode: this.props.editedUser?.postalCode,
    contactMethod: this.props.editedUser?.contactMethod,
    vacationInfo: {
      vacationStartDate: this.props.editedUser?.vacationInfo?.vacationStartDate ?
        moment(this.props.editedUser.vacationInfo.vacationStartDate) : null,
      vacationEndDate: this.props.editedUser?.vacationInfo?.vacationEndDate ?
        moment(this.props.editedUser.vacationInfo.vacationEndDate) : null
    }
  };

  componentDidMount() {
    this.initializeStateFromProps(this.props.editedUser);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.editedUser !== this.props.editedUser || (prevProps.open !== this.props.open && this.props.open)) {
      this.initializeStateFromProps(this.props.editedUser);
    }
  }
  initializeStateFromProps(editedUser: UserRecord | undefined) {
    if (!editedUser) return;

    const {
      phoneNumber,
      email,
      addressOne,
      city,
      stateOfResidence,
      postalCode,
      contactMethod,
      vacationInfo
    } = editedUser;
    this.setState({
      phoneNumber: phoneNumber || '',
      email: email || '',
      addressOne: addressOne || '',
      city: city || '',
      stateOfResidence: stateOfResidence || '',
      postalCode: postalCode || '',
      contactMethod: contactMethod || '',
      vacationInfo: {
        vacationStartDate: vacationInfo?.vacationStartDate ? moment(vacationInfo.vacationStartDate) : null,
        vacationEndDate: vacationInfo?.vacationEndDate ? moment(vacationInfo.vacationEndDate) : null
      }
    });
  }

  editContactCall = (checked: boolean) => {
    this.setState({
      contactMethod: checked ? addContactMethod('call', this.state.contactMethod) :
        removeContactMethod('call', this.state.contactMethod)
    });
  };

  editContactEmail = (checked: boolean) => {
    this.setState({
      contactMethod: checked ? addContactMethod('email', this.state.contactMethod) :
        removeContactMethod('email', this.state.contactMethod)
    });
  };

  editContactText = (checked: boolean) => {
    this.setState({
      contactMethod: checked ? addContactMethod('sms', this.state.contactMethod) :
        removeContactMethod('sms', this.state.contactMethod)
    });
  };

  handleAddress1Change: InputOnChangeHandler = (_, d) => this.setState({ addressOne: d.value });

  handleDatesChange = (d: { startDate: Moment | null; endDate: Moment | null }) => {
    this.setState({ vacationInfo: { vacationStartDate: d.startDate, vacationEndDate: d.endDate } });
  };

  handleCityChange: InputOnChangeHandler = (_, d) => this.setState({ city: d.value });

  handleContactCallChange: CheckboxOnChangeHandler = (_, d) => this.editContactCall(!!d.checked);

  handleContactEmailChange: CheckboxOnChangeHandler = (_, d) => this.editContactEmail(!!d.checked);

  handleContactTextChange: CheckboxOnChangeHandler = (_, d) => this.editContactText(!!d.checked);

  handleDateFocusChange = (focused: 'endDate' | 'startDate' | null) => this.setState({ focusedDateInput: focused });

  handleEmailChange: InputOnChangeHandler = (_, d) => this.setState({ email: d.value });

  handlePhoneChange: InputOnChangeHandler = (_, d) => this.setState({ phoneNumber: d.value });

  handlePostalCodeChange: InputOnChangeHandler = (_, d) => this.setState({ postalCode: d.value });

  handlePutResolve = () => {
    this.props.onClose();
  };

  handleStateChange: DropdownOnChangeHandler = (_, d) => {
    d.value && this.setState({ stateOfResidence: d.value as string });
  };

  handleSubmit: FormOnSubmitHandler = (ev) => {
    ev.preventDefault();
    this.submit();
  };

  submit = () => {
    mixpanelTrack('Contact details update', { Origin: '/user' });
    const {
      phoneNumber,
      email,
      addressOne,
      city,
      stateOfResidence,
      postalCode,
      contactMethod,
      vacationInfo
    } = this.state;
    const latestContactDetails: Partial<UserRecord> = {
      phoneNumber,
      email,
      addressOne,
      city,
      stateOfResidence,
      postalCode,
      contactMethod,
      vacationInfo
    };
    const initialContactDetails = this.props.editedUser;
    const modifiedContactFields = Object.keys(latestContactDetails).filter(
      key =>
        initialContactDetails?.[key as keyof UserRecord] !== latestContactDetails?.[key as keyof UserRecord]
    );
    const modifiedContactData: Partial<EditContactParams> = {};
    modifiedContactFields.forEach(key => {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      (modifiedContactData as any)[key] = (latestContactDetails as any)[key];
    });
    this.props
      .putUserContactInfo({
        userId: this.props.userId,
        contactInfo: modifiedContactData as EditContactParams
      }).then(() => this.props.onClose())
      .catch((e) => {
        this.setState({ showError: true });

        throw new Error(`Unable to save contact: ${e}`);
      });
  };

  resetState = () => {
    const { editedUser } = this.props;
    if (editedUser) {
      const {
        phoneNumber,
        email,
        addressOne,
        city,
        stateOfResidence,
        postalCode,
        contactMethod,
        vacationInfo
      } = editedUser;

      this.setState({
        phoneNumber: phoneNumber || '',
        email: email || '',
        addressOne: addressOne || '',
        city: city || '',
        stateOfResidence: stateOfResidence || '',
        postalCode: postalCode || '',
        contactMethod: contactMethod || '',
        vacationInfo: {
          vacationStartDate: vacationInfo?.vacationStartDate ? moment(vacationInfo.vacationStartDate) : null,
          vacationEndDate: vacationInfo?.vacationEndDate ? moment(vacationInfo.vacationEndDate) : null
        }
      });
    }
  };

  onClose = () => {
    this.resetState();
    this.props.onClose();
  };

  render() {
    return (
      <Modal className="scrolling" dimmer
        size="small" open={this.props.open} style={{ marginTop: '-400px' }}>
        <Modal.Header>Edit Contact</Modal.Header>
        <Modal.Content scrolling>
          <Form onSubmit={this.handleSubmit}>
            <Header size="small" dividing>Contact Information</Header>
            <Form.Input
              label="Phone"
              placeholder="(000) 000-0000"
              value={this.state.phoneNumber || ''}
              onChange={this.handlePhoneChange}
            />
            <Form.Input
              label="Email"
              placeholder="user@company.com"
              value={this.state.email || ''}
              onChange={this.handleEmailChange}
            />
            <Form.Input
              label="Street"
              placeholder="999 Any Lane"
              value={this.state.addressOne
                || ''}
              onChange={this.handleAddress1Change}
            />
            <Form.Group>
              <Form.Input
                width={8}
                label="City"
                placeholder="San Francisco"
                value={this.state.city || ''}
                onChange={this.handleCityChange}
              />
              <Form.Dropdown
                selection
                search
                fluid
                width={4}
                label="State"
                options={stateOptions}
                value={this.state.stateOfResidence || ''}
                onChange={this.handleStateChange}
              />
              <Form.Input
                width={4}
                label="Zip"
                placeholder="00000"
                value={this.state.postalCode || ''}
                onChange={this.handlePostalCodeChange}
              />
            </Form.Group>
            <Header size="small" dividing>Communication Preferences</Header>
            <Form.Group>
              <Form.Checkbox
                label="SMS"
                checked={!!this.state.contactMethod && this.state.contactMethod.indexOf('sms') > -1}
                onChange={this.handleContactTextChange}
              />
              <Form.Checkbox
                label="Call"
                checked={!!this.state.contactMethod && this.state.contactMethod.indexOf('call') > -1}
                onChange={this.handleContactCallChange}
              />
              <Form.Checkbox
                label="Email"
                checked={!!this.state.contactMethod && this.state.contactMethod.indexOf('email') > -1}
                onChange={this.handleContactEmailChange}
              />
            </Form.Group>
            <Header size="small" dividing>Vacation</Header>
            <DateRangePicker
              startDate={this.state.vacationInfo.vacationStartDate || null}
              endDate={this.state.vacationInfo.vacationEndDate || null}
              onDatesChange={this.handleDatesChange}
              focusedInput={this.state.focusedDateInput}
              onFocusChange={this.handleDateFocusChange}
              openDirection="up"
              isOutsideRange={alwaysFalse}
              endDateId="end-date"
              startDateId="start-date"
            />
            {this.state.showError &&
              <Message error>An error occurred while saving the contact preferences. Please try again later.</Message>}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={this.onClose}>
            Cancel
          </Button>
          <Button primary onClick={this.submit}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  putUserContactInfo
};

export default connect(undefined, mapDispatchToProps)(ContactEditModal);
