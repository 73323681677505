import { recordify, TypedRecord } from 'typed-immutable-record';

import { AuthAction, AuthState } from '../actions/auth';
import {
  CLEAR_AUTH,
  OAUTH_LOGIN,
  SET_AUTH_EMAIL,
  SET_AUTH_PASSWORD,
  ADD_AUTH
} from '../constants/auth';

const authDefaults: AuthState = {
  email: '',
  password: '',
  error: undefined,
  baseUri: undefined,
  bffUri: undefined,
  'access-token': undefined,
  client: undefined,
  uid: undefined,
  uuid: undefined,
  id: undefined
};

export interface State extends TypedRecord<State>, AuthState {}

export const initialState = recordify<AuthState, State>(authDefaults);

export const reducer = (state = initialState, action: AuthAction): State => {
  switch (action.type) {
    case OAUTH_LOGIN:
      if (!action.error) {
        return state.merge(action.payload);
      }
      return state;
    case ADD_AUTH:
      const payload = { ...action.payload } as AuthState;
      if (!action.error && payload['access-token'] && payload.uid) {
        return state.merge(payload);
      }
      return state.merge({
        'access-token': undefined,
        client: undefined,
        uid: undefined,
        uuid: undefined
      });
    case CLEAR_AUTH:
      return initialState;
    case SET_AUTH_EMAIL:
      return state.set('email', action.payload);
    case SET_AUTH_PASSWORD:
      return state.set('password', action.payload);
    default:
      return state;
  }
};
