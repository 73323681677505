import { connect } from 'react-redux';

import { RootState } from '../../redux/reducers';
import { selectUser, makeSelectUserGoalFactory } from '../../redux/selectors';
import UserGoalsSection from '../../components/user/UserGoalsSection';

export interface UserGoalsSectionProps {
  userId: number;
}

const mapStateToProps = (state: RootState, props: UserGoalsSectionProps) => {
  const selectUserGoals = makeSelectUserGoalFactory();
  return {
    user: selectUser(state, { userId: props.userId }),
    goals: selectUserGoals(state, { userId: props.userId })
  };
};

export default connect(mapStateToProps)(UserGoalsSection);
