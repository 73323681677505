import * as React from 'react';
import { connect } from 'react-redux';
import { TaskStatuses, TaskKind } from '../../api-client/interfaces';
import { selectClinicalAndReengagerTasks } from '../../redux/selectors';
import { RootState, TaskRecord } from '../../redux/reducers';
import { actionCreators as taskActions, RESOLVED_TASKS_PER_PAGE } from '../../redux/actions/tasks';
import { DispatchMap } from '../../redux/actions/interfaces';
import { ClinicalTaskKindTitles } from '../../api-client';

import CompletedTasks from '../../components/tasks/CompletedTasks';
import { isEqual } from 'lodash';

type ConnectProps = DispatchMap<{
  completedTasks: TaskRecord[];
  getTasks: typeof taskActions.getList;
  highUtilTasksEnabled?: boolean;
}>;

class CompletedTasksContainer extends React.PureComponent<ConnectProps> {
  componentDidMount() {
    this.fetchCompletedTasks();
  }

  fetchCompletedTasks = () => this.props.getTasks && this.props.getTasks({
    status: TaskStatuses.Completed, itemsPerPage: RESOLVED_TASKS_PER_PAGE
  });

  render() {
    const titles = Object.keys(ClinicalTaskKindTitles) as TaskKind[];

    return <CompletedTasks tasks={this.props.completedTasks} taskKindTitles={titles} />;
  }
}

const mapStateToProps = (state: RootState, props: {}) => ({
  completedTasks: selectClinicalAndReengagerTasks(state, TaskStatuses.Completed)
});

const mapDispatchToProps = {
  getTasks: taskActions.getList
};

export default connect(mapStateToProps, mapDispatchToProps)
(React.memo(CompletedTasksContainer, isEqual));
