import { PayloadAction, ThunkAction } from './index';
import * as C from '../constants';
import { Goal } from '../../api-client';
import { GoalRecord } from '../reducers';

export interface EditGoalAction
  extends PayloadAction<{ goalToEdit: GoalRecord }> {
  payload: { goalToEdit: GoalRecord };
  type: typeof C.EDIT_GOAL;
}

export interface EditorGoalPropAction<T extends keyof Goal>
  extends PayloadAction<{
    key: T;
    value: Goal[T];
    goalId: string;
  }> {
  type: typeof C.EDIT_GOAL_PROPS;
}

export type GoalEditorActions =
  | EditGoalAction
  | EditorGoalPropAction<keyof Goal>;

export const actionCreators = {
  placeGoalToEdit(goalToEdit: GoalRecord): ThunkAction<void> {
    return dispatch => {
      dispatch<EditGoalAction>({ payload: { goalToEdit }, type: C.EDIT_GOAL });
    };
  },
  editGoalContent(goalId: string, content: string): ThunkAction<void> {
    return dispatch => {
      dispatch<EditorGoalPropAction<'content'>>({
        payload: { key: 'content', value: content, goalId },
        type: C.EDIT_GOAL_PROPS
      });
    };
  },
  editGoalDueDate(goalId: string, date: string | null): ThunkAction<void> {
    return dispatch => {
      dispatch<EditorGoalPropAction<'done_by'>>({
        payload: {
          key: 'done_by',
          value: date,
          goalId
        },
        type: C.EDIT_GOAL_PROPS
      });
    };
  }
};
