import * as React from 'react';
import styled from 'styled-components';
import { camelize } from 'humps';
import { CarePlanStatus, CarePlanItem } from '../../api-client/interfaces';
import { CarePlanProps, UpdateCarePlanFields } from '../../containers/user/CarePlansContainer';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core';
import { MoreVert, ReportProblemOutlined, ErrorOutline } from '@material-ui/icons';
import { fade } from '@material-ui/core/styles';
import * as moment from 'moment-timezone';
import theme from '../../theme';
import { SUCCESS_TEXT, WARNING_TEXT_ONE, WARNING_TEXT_TWO, WARNING_DARK } from '../../colors';
import { capitalize } from 'lodash';
import { isNumber } from '../../utils/number';

const DA_TWO_WEEK_LIMIT = 14;
const DA_ONE_WEEK_LIMIT = 7;
const VISIT_LIMIT_WARNING_LOW = 2;
const VISIT_LIMIT_WARNING_HIGH = 1;

const CarePlanItemKeyDescription: { [key: string]: string } = {
  video_visit: 'Total PT visits',
  duration: 'DA limit (days)',
  inform_hcp: 'Inform HCP (days)',
  prescription_obtained: 'Prescription',
  in_person_hcp_visit: 'In-person HCP visit'
};

interface CarePlanItemHash {
  [key: string]: {
    description: string;
    warningTwo?: boolean;
    warningOne?: boolean;
    secondary?: boolean;
    value: string | number;
  };
}

enum ItemKeyWarnings {
  Duration = 'duration',
  VideoVisit = 'video_visit',
  InformHcp = 'inform_hcp'
}

export enum FinalizedRuleValues {
  Na = 'N/A',
  Done = 'Done'
}

interface CarePlanCardProps {
  carePlan: CarePlanProps;
  moreAction(event: React.MouseEvent<{}>, fields: UpdateCarePlanFields): void;
}

const StyledCard = styled(Card)`
  margin-bottom: 32px;
  padding: 8px;
`;

const StyledCardHeader = styled(CardHeader)`
  .MuiCardHeader-content {
    display: flex;
    flex-direction: row;
  }
`;

const StyledSubheaderDiv = styled.div`
  margin-left: 16px;
`;

const StyledCardContent = styled.div`
  .MuiCardContent-root {
    padding: 0 16px 16px 16px;
  }
`;

export const parseVisitCount = (visitCountDisplay: string): [number, number] => {
  const visitCountArr = visitCountDisplay.split('/');

  const visitCountNumerator = parseInt(visitCountArr[0], 10);
  const visitCountDenominator = parseInt(visitCountArr[1], 10);

  return [visitCountNumerator, visitCountDenominator];
};

const StyledCarePlanStatusChip = styled.div.attrs<{ status: CarePlanStatus }>(({ status }) => ({ status }))<{
  status: CarePlanStatus;
}>`
  .MuiChip-root {
    background-color: ${props => props.status === CarePlanStatus.Open && fade(theme.palette.success.dark, 0.17)};
    color: ${props => props.status === CarePlanStatus.Open && SUCCESS_TEXT};
  }
`;

export const CarePlanStatusChip: React.FC<{ status: CarePlanStatus }> = ({ status }) => (
  <StyledCarePlanStatusChip status={status}>
    <Chip size='small' label={capitalize(status)} />
  </StyledCarePlanStatusChip>
);

const processItemProps = (item: CarePlanItem, status: CarePlanStatus) => {
  switch (item.key) {
    case ItemKeyWarnings.Duration:
      return daLimitWarning(item.currentValue, status);
    case ItemKeyWarnings.VideoVisit:
      return visitCountWarning(item.currentValue, status);
    case ItemKeyWarnings.InformHcp:
      return informHcpWarning(item.currentValue);
    default:
      return { value: item.currentValue };
  }
};

export const processCarePlanItems = (items: CarePlanItem[], status: CarePlanStatus) =>
  items.reduce((hash: CarePlanItemHash, item) => {
    const itemProps = processItemProps(item, status);
    hash[camelize(item.key)] = {
      ...itemProps,
      description: CarePlanItemKeyDescription[item.key]
    };

    return hash;
  }, {});

export const CarePlanColumn: React.FC<{
  description: string;
  value: string[] | string | number | JSX.Element;
  alignEnd?: boolean;
}> = ({ description, value, alignEnd = false }) => {
  const renderVal = (valueToRender: string[] | string | number | JSX.Element): JSX.Element | JSX.Element[] => {
    if (typeof valueToRender === 'string' || isNumber(valueToRender)) {
      return (
        <Typography align={alignEnd ? 'right' : 'inherit'} variant='body2' color='textPrimary'>
          {valueToRender}
        </Typography>
      );
    }

    if (Array.isArray(valueToRender)) {
      return valueToRender.map(val => (
        <Typography key={val} align={alignEnd ? 'right' : 'inherit'} variant='body2' color='textPrimary'>
          {val}
        </Typography>
      ));
    }

    return valueToRender;
  };

  return (
    <Grid container item direction='row' spacing={1}>
      <Grid item xs={7}>
        <Typography variant='body2' color='textSecondary'>
          {description}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        {renderVal(value)}
      </Grid>
    </Grid>
  );
};

const daLimitWarning = (daLimit: string, status: CarePlanStatus) => {
  if (daLimit === FinalizedRuleValues.Na) {
    return { secondary: true, value: daLimit };
  }

  if (status === CarePlanStatus.Closed) {
    return { secondary: true, value: FinalizedRuleValues.Na };
  }

  const daysUntilLimit = parseInt(daLimit, 10);

  if (daysUntilLimit <= DA_ONE_WEEK_LIMIT) {
    return { warningTwo: true, value: daysUntilLimit };
  }

  if (daysUntilLimit <= DA_TWO_WEEK_LIMIT) {
    return { warningOne: true, value: daysUntilLimit };
  }

  return { value: daysUntilLimit };
};

const informHcpWarning = (informHcp: string) => {
  if (informHcp === FinalizedRuleValues.Na || informHcp === FinalizedRuleValues.Done) {
    return { secondary: true, value: informHcp };
  }

  const daysUntilLimit = parseInt(informHcp, 10);

  return { warningTwo: true, value: daysUntilLimit };
};

export const visitCountWarning = (visitCountDisplay: string, status?: CarePlanStatus) => {
  const [visitCount, totalVisitCount] = parseVisitCount(visitCountDisplay);

  if (status === CarePlanStatus.Closed) {
    return { secondary: true, value: visitCountDisplay };
  }

  if (totalVisitCount - visitCount <= VISIT_LIMIT_WARNING_HIGH) {
    return { warningTwo: true, value: visitCountDisplay };
  }

  if (totalVisitCount - visitCount <= VISIT_LIMIT_WARNING_LOW) {
    return { warningOne: true, value: visitCountDisplay };
  }

  return { value: visitCountDisplay };
};

export interface RegulationColumnProps {
  warningOne?: boolean;
  warningTwo?: boolean;
  secondary?: boolean;
  value: string | number;
}

export const RegulationColumn: React.FC<RegulationColumnProps> = ({ warningOne, warningTwo, secondary, value }) => {
  const StyledWarningIconDiv = styled.div`
    padding-top: 6px;
  `;

  if (warningOne) {
    return (
      <Grid container spacing={1} alignItems='center'>
        <Grid xs={8} item>
          <Typography variant='body2' align='right' style={{ color: WARNING_TEXT_ONE }}>
            {value}
          </Typography>
        </Grid>
        <Grid xs={4} item>
          <StyledWarningIconDiv>
            <ReportProblemOutlined htmlColor={WARNING_DARK} />
          </StyledWarningIconDiv>
        </Grid>
      </Grid>
    );
  }

  if (warningTwo) {
    return (
      <Grid container spacing={1} alignItems='center'>
        <Grid xs={8} item>
          <Typography variant='body2' align='right' style={{ color: WARNING_TEXT_TWO }}>
            {value}
          </Typography>
        </Grid>
        <Grid xs={4} item>
          <StyledWarningIconDiv>
            <ErrorOutline color='error' />
          </StyledWarningIconDiv>
        </Grid>
      </Grid>
    );
  }

  if (secondary) {
    return (
      <Grid item xs={8}>
        <Typography variant='body2' color='textSecondary' align='right'>
          {value}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid item xs={8}>
      <Typography variant='body2' color='inherit' align='right'>
        {value}
      </Typography>
    </Grid>
  );
};

interface CarePlanActionsMenuProps {
  moreAction(event: React.MouseEvent<{}>, fields: UpdateCarePlanFields): void;
  fields: UpdateCarePlanFields;
}

export const CarePlanActionsMenu: React.FC<CarePlanActionsMenuProps> = ({ moreAction, fields }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuSelect = (event: React.MouseEvent<{}>) => {
    moreAction(event, fields);
    setAnchorEl(null);
  };

  const handleClose = () => setAnchorEl(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event?.currentTarget);

  return (
    <>
      <IconButton aria-controls='update-menu' onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id='update-menu' open={open} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItem onClick={handleMenuSelect}>Update</MenuItem>
      </Menu>
    </>
  );
};

const CarePlanCard: React.FC<CarePlanCardProps> = ({ carePlan, moreAction }) => {
  const { id, title, status, items, diagnoses,
          expectedDischargeAt, openedAt, userId, closedAt, dischargeReason } = carePlan;
  const processedItems = processCarePlanItems(items, status);

  const { duration, videoVisit, informHcp, prescriptionObtained, inPersonHcpVisit } = processedItems;

  return (
    <StyledCard>
      <StyledCardHeader
        title={
          <Typography variant='h6' color='textPrimary'>
            {title}
          </Typography>
        }
        subheader={
          <StyledSubheaderDiv>
            <CarePlanStatusChip status={status} />
          </StyledSubheaderDiv>
        }
        action={<CarePlanActionsMenu moreAction={moreAction} fields={{ id, status, items, userId }} />}
      ></StyledCardHeader>
      <StyledCardContent>
        <CardContent>
          <Grid container direction='row' spacing={2}>
            <Grid container item xs={5} direction='row'>
              <Grid container direction='column' spacing={1}>
                {duration && (
                  <CarePlanColumn
                    alignEnd
                    description={duration.description}
                    value={
                      <RegulationColumn
                        {...duration}
                        secondary={status === CarePlanStatus.Closed || duration.value === FinalizedRuleValues.Na}
                      />
                    }
                  />
                )}
                {videoVisit && (
                  <CarePlanColumn
                    alignEnd
                    description={videoVisit.description}
                    value={<RegulationColumn {...videoVisit} />}
                  />
                )}
                {informHcp && (
                  <CarePlanColumn
                    alignEnd
                    description={informHcp.description}
                    value={<RegulationColumn {...informHcp} />}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container item xs={1}>
              <Divider orientation='vertical' variant='middle' flexItem />
            </Grid>
            <Grid container item xs={6}>
              <CarePlanColumn description={'Date opened'} value={moment(openedAt).format('L')} />
              <CarePlanColumn
                description={closedAt ? 'Date closed' : 'Expected discharge'}
                value={moment(closedAt || expectedDischargeAt).format('L')}
              />
              {closedAt && (
                <CarePlanColumn
                  description={'Discharge reason'}
                  value={dischargeReason || 'None selected'}
                />
              )}
              <CarePlanColumn description='Diagnosis' value={diagnoses} />
              {prescriptionObtained && (
                <CarePlanColumn description={prescriptionObtained.description} value={prescriptionObtained.value} />
              )}
              {inPersonHcpVisit && (
                <CarePlanColumn description={inPersonHcpVisit.description} value={inPersonHcpVisit.value} />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </StyledCardContent>
    </StyledCard>
  );
};

export default CarePlanCard;
