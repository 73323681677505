import * as React from 'react';
import * as moment from 'moment-timezone';
import { isNullOrUndefined } from 'util';
import { Achievement } from '../../api-client/interfaces';
import { UserRecord } from '../../redux/reducers';
import { DEFAULT_TIMEZONE } from '../../utils/user/timezone';
import {
  achievementsConfig,
  getAchievementsCompletedThisWeek,
  isAchievementsConfigKey
} from '../../utils/user/achievements';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { WHITE } from '../../colors';
import styled from 'styled-components';

interface Props {
  achievements?: Achievement[];
  user?: UserRecord;
}

const listItemStyles = {
  flexFlow: 'column wrap',
  alignItems: 'start',
  paddingLeft: 20,
  paddingTop: 0
};

const StyledList = styled(List)`
  && {
    width: 100%;
    padding-top: 0;
    background-color: ${WHITE}
  }
`;

const StyledTypography = styled(Typography)`
  && {
    padding: 20px 0 20px 20px;
    font-size: 18px;
    margin: 0
  }
`;

const NoAchievementsAvailable = () => (
  <StyledList dense data-testid="no-achievements-available">
    <StyledTypography>Recent Achievements</StyledTypography>
    <ListItem style={listItemStyles}>
      <ListItemText secondary='None' data-testid="no-achievements"/>
    </ListItem>
  </StyledList>
);

const AchievementsContext: React.FC<Props> = ({ achievements, user }) => {
  const timezone = user?.get('timezone') || DEFAULT_TIMEZONE;

  if (isNullOrUndefined(achievements)) {
    return <NoAchievementsAvailable />;
  }

  const recentAchievements = getAchievementsCompletedThisWeek(achievements);

  const recentAchievementsList = achievements.map(achievement => {
    if(!isAchievementsConfigKey(achievement.key)) {
      return <div key={achievement.key}>
        <ListItemText secondary='None' data-testid="no-achievements"/>
      </div>;
    }
    const config = achievementsConfig[achievement.key];
    const achievedDate = moment(achievement.achievedDate).tz(timezone).format('MMM D, YYYY');
    return (
      <div key={achievement.key}>
        <ListItemText secondary={config.section} data-testid="achievement-section-title"/>
        <ListItemText primary={config.title} data-testid="achievement-info" />
        <ListItemText secondary={achievedDate} data-testid="achievement-date"/>
      </div>
    );
  });

  return recentAchievements.length === 0
    ? <NoAchievementsAvailable />
    : (
      <StyledList dense>
        <StyledTypography>Recent Achievements</StyledTypography>
        <ListItem style={listItemStyles}>
          {recentAchievementsList}
        </ListItem>
      </StyledList>
    );
};

export default AchievementsContext;
