import * as React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './UserNotesEditor.scss';
import { NoteStatus } from '../../api-client/interfaces';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';
import { spellCheck } from '../../features';

interface UserNotesEditorProps {
  noteStatus: NoteStatus;
  noteBody: EditorState;
  onEditorChange(editorState: EditorState): void;
}

type Props = UserNotesEditorProps;

const UserNotesEditor: React.FC<Props> = ({ noteStatus, noteBody, onEditorChange }) => {
  const [focused, setFocused] = React.useState(false);
  const spellCheckEnabled = useSelector((state: RootState) =>
    selectTreatmentValue(state.splitReducer, spellCheck) === 'on');
  const isFinal = noteStatus === NoteStatus.Final;

  const setFocus = React.useCallback(() => setFocused(true), []);

  const setBlur = React.useCallback(() => setFocused(false), []);

  return (
    <Editor
      spellCheck={spellCheckEnabled}
      handlePastedText={() => false}
      editorState={noteBody}
      toolbarClassName='user-notes-editor__toolbar'
      toolbar={{
        options: ['blockType', 'inline', 'list'],
        inline: {
          options: ['bold', 'italic'],
          className: 'user-notes-editor__toolbar__inline'
        },
        list: {
          options: ['unordered', 'ordered']
        },
        blockType: {
          inDropdown: true,
          options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
        }
      }}
      placeholder="Start note..."
      wrapperClassName={`user-notes-editor ${focused ? 'user-notes-editor--focused' : ''}
      ${isFinal ? 'user-notes-editor--final' : ''}`}
      editorClassName={`user-notes-editor__area ${isFinal ? 'user-notes-editor__area--final' : ''}`}
      onEditorStateChange={onEditorChange}
      readOnly={isFinal}
      toolbarHidden={isFinal}
      onFocus={setFocus}
      onBlur={setBlur}
    />
  );
};

export default UserNotesEditor;
