import * as React from 'react';
import { Label, Icon } from 'semantic-ui-react';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import { SemanticSizes } from '../../../typings/semantic';
import { isNumber } from '../../../utils/number';
import { ValueOf } from '../../../utils/type-helpers';

import {
  RED,
  ORANGE,
  YELLOW,
  BLUE,
  PURPLE,
  GREY,
  BLACK,
  WHITE,
  PHX_FLAG,
  BACK_TAG,
  KNEE_TAG,
  PRIMARY_MAIN
} from '../../../colors';

export interface RenderTagOptions {
  size?: SemanticSizes;
  showDelete?(tag: string): boolean;
  maxTags?: number;
  onDelete?(tag: string): void;
  sortFn?(a: string, b: string): number;
  onlyShowExpTags?: boolean;
}

interface TagProps {
  tag: string;
  size?: SemanticSizes;
  showDelete?(tag: string): boolean;
  onDelete?(tag: string): void;
}

interface DeleteTagIconProps {
  tag: string;
  onDelete?(tag: string): void;
}

const tagColorMap: { [key: string]: string } = {
  phx: PHX_FLAG,
  knee: KNEE_TAG,
  back: BACK_TAG,
  pain: RED,
  'pain flare': RED,
  surgery_increase: BLUE,
  surgery: BLUE,
  nonresp: PURPLE,
  inactive: YELLOW,
  disengager: ORANGE,
  high_util: PRIMARY_MAIN,
  dmc_full: PRIMARY_MAIN,
  dmc_mvp: PRIMARY_MAIN
};

const tagTextWhite = [PHX_FLAG, BLACK, PRIMARY_MAIN];

export const TAG_NAMES = {
  french: 'french',
  spanish: 'spanish',
  highUtil: 'high_util',
  dmcFull: 'dmc_full',
  dmcMvp: 'dmc_mvp',
  phx: 'phx',
  knee:'knee',
  back: 'back',
  hip:'hip',
  shoulder: 'shoulder',
  pain: 'pain',
  painFlare: 'pain-flare',
  disengager: 'disengager',
  nonresponder: 'nonresp',
  surgeryIncrease:'surgery_increase',
  inactive: 'inactive',
  stiffness: 'stiffness',
  qol: 'qol',
  reEngager: 're-engager',
  surgery: 'surgery',
  weight: 'weight',
  extrinsic: 'extrinsic',
  depression: 'depression',
  anxiety: 'anxiety',
  passiveCoping: 'pass-coping',
  fearAvoid: 'fear-avoid',
  yearTwo: 'year 2',
  noMarketing: 'no-marketing',
  expLowTier: 'exp-low-tier',
  expMedTier: 'exp-med-tier',
  houseCallScheduled: 'house-call-scheduled',
  houseCallComplete: 'house-call-complete',
  popUpVisitCompleted: 'pop-up-visit-completed'
};

export const NON_DELETABLE_TAGS: ValueOf<typeof TAG_NAMES>[] = [
  TAG_NAMES.dmcFull,
  TAG_NAMES.dmcMvp,
  TAG_NAMES.phx,
  TAG_NAMES.knee,
  TAG_NAMES.back,
  TAG_NAMES.hip,
  TAG_NAMES.shoulder,
  TAG_NAMES.pain,
  TAG_NAMES.painFlare,
  TAG_NAMES.surgeryIncrease,
  TAG_NAMES.stiffness,
  TAG_NAMES.qol,
  TAG_NAMES.nonresponder,
  TAG_NAMES.inactive,
  TAG_NAMES.reEngager,
  TAG_NAMES.highUtil
];

export const getTagColor = (tag: string): string => tagColorMap[tag] || GREY;
export const isTagNonDeletable = (tag: string): boolean => NON_DELETABLE_TAGS.includes(tag);
export const getTagTextColor = (tag: string): string =>
  tagTextWhite.indexOf(tagColorMap[tag]) < 0 ? BLACK : WHITE;

export const isDmcFullAndHighUtilAndNotNonResponder = (tag: string, _: number, tags: string[]) => {
  /**
   * BAT-1190 - Special case to handle business logic: only
   * render high util tags if a user has a dmc_full tag.
   */
  if (tag.includes(TAG_NAMES.highUtil) && !tags.includes(TAG_NAMES.dmcFull)) return false;
  if (tag.includes(TAG_NAMES.nonresponder)) return false;
  return true;
};

export class DeleteTagIcon extends React.PureComponent<DeleteTagIconProps> {
  handleClick = () => {
    this.props.onDelete && this.props.onDelete(this.props.tag);
  };

  render() {
    return <Icon name="delete" onClick={this.handleClick} />;
  }
}

const Tag: React.FunctionComponent<TagProps> = ({
  tag,
  showDelete,
  size,
  onDelete
}) => {
  const tagLowerCase = tag.toLowerCase();

  const renderTagLabel = (tagLabel: string) => (
    isTagExperimentIndicator(tagLabel)
      ? renderExperimentIndicatorTag(tagLabel)
      : tagLabel.toUpperCase()
  );

  const renderExperimentIndicatorTag = (tagLabel: string) =>
    (
      <div style={{display: 'flex', gap: '5px'}}>
        <EmojiObjectsOutlinedIcon style={{fontSize: '10px'}}/>
        {tagLabel.slice(4).toUpperCase()}
      </div>
    );

  return (
    <Label
      style={{
        backgroundColor: getTagColor(tagLowerCase),
        color: getTagTextColor(tagLowerCase)
      }}
      size={size || 'small'}
    >
      <div style={{display: 'flex'}}>
        {renderTagLabel(tag)}
        {showDelete && showDelete(tag) && !isTagNonDeletable(tag) && (
          <DeleteTagIcon onDelete={onDelete} tag={tag} />
        )}
      </div>
    </Label>
  );
};

export default Tag;

const tagPriorityOrder: ValueOf<typeof TAG_NAMES>[] = [
  TAG_NAMES.french,
  TAG_NAMES.spanish,
  TAG_NAMES.highUtil,
  TAG_NAMES.dmcFull,
  TAG_NAMES.dmcMvp,
  TAG_NAMES.phx,
  TAG_NAMES.knee,
  TAG_NAMES.back,
  TAG_NAMES.hip,
  TAG_NAMES.shoulder,
  TAG_NAMES.pain,
  TAG_NAMES.painFlare,
  TAG_NAMES.disengager,
  TAG_NAMES.nonresponder,
  TAG_NAMES.surgeryIncrease,
  TAG_NAMES.inactive
];

export const isTagExperimentIndicator = (tag: string): boolean =>
  tag.toLowerCase().startsWith('exp-') || tag.toLowerCase().startsWith('exp_');

function getTagOrder(tag: string, tagOrder: string[]) {
  const tagIdx = tagOrder.indexOf(tag.toLowerCase());
  return tagIdx === -1 ? tagOrder.length : tagIdx;
}

export function sortTagsByPriority(a: string, b: string) {
  const aPriorityIndex = getTagOrder(a, tagPriorityOrder);
  const bPriorityIndex = getTagOrder(b, tagPriorityOrder);

  if (isTagExperimentIndicator(a) && isTagExperimentIndicator(b)) return (a.slice(4) < b.slice(4)) ? -1 : 1;
  if (isTagExperimentIndicator(a)) return -1;
  if (isTagExperimentIndicator(b)) return 1;
  return aPriorityIndex - bPriorityIndex;
}

export const filterByExperimentIndicator = (tags: string[]): string[] =>
  tags.filter(isTagExperimentIndicator);

/** Generate an array of Tag nodes for the given user. */
export function renderTags(
  tags?: string[] | null,
  options: RenderTagOptions = {}
): JSX.Element[] {
  if (!tags) return [];

  let sortedTags = tags.slice(0);

  if (options.sortFn === undefined) {
    sortedTags = sortedTags.sort(sortTagsByPriority);
  }
  if (options.sortFn !== undefined) {
    sortedTags = sortedTags.sort(options.sortFn);
  }
  if (options.onlyShowExpTags !== undefined) {
    sortedTags = filterByExperimentIndicator(sortedTags);
  }
  if (options.maxTags !== undefined) {
    sortedTags = sortedTags.slice(0, options.maxTags);
  }

  return sortedTags
    .filter(isDmcFullAndHighUtilAndNotNonResponder)
    .map(tag => (
      <Tag
        key={tag}
        tag={tag}
        size={options.size}
        showDelete={options.showDelete}
        onDelete={options.onDelete}
      />
    ));
}

export function getHiddenTags(
  tags?: string[] | null,
  options: RenderTagOptions = {}
): string[] {
  if (!tags || !isNumber(options.maxTags) || options.maxTags >= tags.length) {
    return [];
  }

  let sortedTags = tags.slice(0);

  sortedTags = options.sortFn
    ? sortedTags.sort(options.sortFn)
    : sortedTags.sort(sortTagsByPriority);

  const hiddenTags = sortedTags.slice(options.maxTags);

  return hiddenTags;
}
