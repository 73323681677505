import * as React from 'react';
import { connect } from 'react-redux';
import {
  OrderedDoTaskKindTitles,
  TaskKind,
  MassMessageTemplate,
  MassMessage,
  TemplateSubheadingMap
} from '../../api-client/interfaces';
import MassMessagingTemplate from './MassMessagingTemplate';
import './MassMessagingTodo.scss';

import { DispatchMap } from '../../redux/actions/interfaces';
import { getTaskUsers } from '../../redux/actions/tasks';
import { storeTaskUsersByTemplate } from '../../redux/actions/ui-state';
import { selectTodoTemplates, selectIncompleteTemplates } from '../../redux/selectors/ui-state';
import { RootState } from '../../redux/reducers/index';

interface MassMessageTodoProps {
  todo: TaskKind;
  templateRes: MassMessage[];
  selectedTemplate?: MassMessageTemplate;
  setSelectedTemplate(template?: MassMessageTemplate): void;
  markTodoComplete(todo: TaskKind): void;
}

type ConnectProps = DispatchMap<{
  fetchTaskUsers: typeof getTaskUsers;
  storeUsersByTemplate: typeof storeTaskUsersByTemplate;
  todoTemplates: MassMessageTemplate[];
  incompleteTemplates: MassMessageTemplate[];
}>;

const sortByMessageType = (a: MassMessage, b: MassMessage) => {
  if (a.messageType < b.messageType) {
    return -1;
  }

  if (a.messageType > b.messageType) {
    return 1;
  }

  return 0;
};

const MassMessagingTodo: React.FC<MassMessageTodoProps & ConnectProps> = ({
  todo,
  templateRes,
  selectedTemplate,
  setSelectedTemplate,
  fetchTaskUsers,
  storeUsersByTemplate,
  todoTemplates,
  markTodoComplete,
  incompleteTemplates
}) => {
  const templates = templateRes.map(r => r.messageType);

  const [fetchStatus, setFetchStatus] = React.useState<'loading' | 'error' | 'success'>('loading');

  React.useEffect(() => {
    const fetchUsers = async() => {
      await fetchTaskUsers(todo)
        .then(res => {
          if (res.result.users.length) {
            storeUsersByTemplate(templates);
            setFetchStatus('success');
          } else {
            markTodoComplete(todo);
          }
        })
        .catch(err => {
          setFetchStatus('error');
        });
    };

    fetchUsers();
  }, []);

  React.useEffect(() => {
    if (incompleteTemplates.length === 0 && fetchStatus === 'success') {
      setTimeout(() => markTodoComplete(todo), 800);
    }
  }, [incompleteTemplates]);

  return (
    <div className={`mass-messaging__todo ${todoTemplates.length ? '' : 'mass-messaging__todo--empty'}`}>
      <div
        className={`mass-messaging__todo__header ${
          incompleteTemplates.length === 0 && fetchStatus !== 'loading' ? 'mass-messaging__todo__header--completed' : ''
        }`}
      >
        {OrderedDoTaskKindTitles[todo]}
      </div>
      <div>
        {templateRes.sort(sortByMessageType).map(massMessage => (
          <MassMessagingTemplate
            key={massMessage.messageType}
            todo={todo}
            massMessage={massMessage}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            subHeading={TemplateSubheadingMap[massMessage.messageType]}
          />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState, props: MassMessageTodoProps) => ({
  todoTemplates: selectTodoTemplates(state, props.todo),
  incompleteTemplates: selectIncompleteTemplates(state, props.todo)
});

const mapDispatchToProps = {
  fetchTaskUsers: getTaskUsers,
  storeUsersByTemplate: storeTaskUsersByTemplate
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MassMessagingTodo);
