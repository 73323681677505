import * as React from 'react';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';
import {
  CircularProgress,
  Paper,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableSortLabel,
  TableBody
} from '@material-ui/core';
import { LOADING_BLUE } from '../colors';

const StyledDiv = styled.div`
  position: fixed;
  left: 51.33%; 
  bottom: 0;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  && {
    color: ${LOADING_BLUE};
  }
`;

const StyledTableCell = styled(TableCell)`
  && {
    padding: 3px 16px;
  }
`;

export interface HeadCell {
  id: string;
  label: string;
  sortable?: boolean;
  sortFn?(a: BodyRow, b: BodyRow): number;
  numeric?: boolean;
  disablePadding?: boolean;
}

export interface BodyRow {
  id: string;
  columns: BodyColumn[];
};

export interface BodyColumn {
  id: string;
  label: string | JSX.Element | number;
  sortValue?: string | number | Date;
  numeric?: boolean;
  disablePadding?: boolean;
  onClick?(event: React.MouseEvent<{}>): void;
}

interface TableWrapperProps {
  loadMore: () => void;
  hasNextPage?: boolean;
  loading: boolean;
  headers: HeadCell[];
  rows: BodyRow[];
  defaultOrderBy?: string;
  defaultOrder?: Order;
}

type Order = 'asc' | 'desc';

const TableWrapper: React.FC<TableWrapperProps> = ({
  hasNextPage,
  loading,
  loadMore,
  headers,
  rows,
  defaultOrderBy = '',
  defaultOrder = 'desc'
}) => {
  const [order, setOrder] = React.useState<Order>(defaultOrder);
  const [orderBy, setOrderBy] = React.useState<string>(defaultOrderBy);

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const defaultComparatorFn = (a: BodyRow, b: BodyRow) => {
    const aColumn = a.columns.find(col => orderBy === col.id);
    const bColumn = b.columns.find(col => orderBy === col.id);

    if (!aColumn || !aColumn.sortValue) return 1;
    if (!bColumn || !bColumn.sortValue) return -1;

    if (aColumn.sortValue < bColumn.sortValue) {
      return 1;
    }

    if (aColumn.sortValue > bColumn.sortValue) {
      return -1;
    }

    return 0;
  };

  const getComparator = (orderProp: string, headerCells: HeadCell[]) => {
    const headerCell = headerCells.find(header => header.id === orderProp);

    return headerCell?.sortFn || defaultComparatorFn;
  };

  const comparatorFn = getComparator(orderBy, headers);

  const sortedRows: BodyRow[] =
    order === 'desc'
      ? rows.slice(0).sort(comparatorFn)
      : rows.slice(0).sort(comparatorFn).reverse();

  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple-table'>
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <TableCell
                key={header.id}
                align={header.numeric ? 'right' : 'left'}
                padding={header.disablePadding ? 'none' : 'default'}
                sortDirection={header.sortable && orderBy === header.id ? order : false}
              >
                {header.sortable ?
                  <TableSortLabel
                    active={orderBy === header.id}
                    direction={orderBy === header.id ? order : 'asc'}
                    onClick={() => handleSort(header.id)}
                  >
                    {header.label}
                  </TableSortLabel> : header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            sortedRows.map((row, index) => (
              <>
                <TableRow key={row.id}>
                  {
                    row.columns.map(column => (
                      <StyledTableCell
                        key={column.id}
                        align={column.numeric ? 'right' : 'left'}
                        onClick={column.onClick}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))
                  }
                </TableRow>
                {
                  hasNextPage &&
                  index === sortedRows.length - 5 &&
                  <Waypoint onEnter={() => loadMore()} scrollableAncestor={window}/>
                }
              </>
            ))
          }
        </TableBody>
      </Table>
      { loading && <StyledDiv><StyledCircularProgress size={32} /></StyledDiv> }
    </TableContainer>
  );
};

export default TableWrapper;
