import { TypedRecord, makeTypedFactory } from 'typed-immutable-record';
import { Map } from 'immutable';

import { PainLog } from '../../api-client';
import { RootAction } from '../actions';
import { ENTITIES_FETCHED } from '../constants';
import { createEntityMap, mergeUpdate } from '../../utils/entity';

export interface PainLogRecord extends TypedRecord<PainLogRecord>, PainLog { }

export const createPainLogRecord = makeTypedFactory<PainLog, PainLogRecord>({
  id: NaN,
  created_at: undefined,
  note: undefined,
  pain: undefined,
  secondary_metric: undefined,
  week_index: undefined
});

export type State = Map<number, PainLogRecord>;

export const initialState: State = Map();

export const reducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case ENTITIES_FETCHED:
      return action.payload.entities.painLogs
        ? mergeUpdate(state, createEntityMap(createPainLogRecord, action.payload.entities.painLogs))
        : state;
    default:
      return state;
  }
};
