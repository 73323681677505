import * as moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { RootState, TeamRecord, UserRecord } from '../reducers';
import { createTaskRecord, TaskRecord } from '../reducers/tasks';
import {
  RuleDrivenTask,
  AnyTaskExtras,
  Task,
  TaskCategories,
  TaskKind,
  TaskStatuses,
  OrderedDoTaskKindTitles,
  ClinicalTaskKindTitles
} from '../../api-client';

import { UserStatus } from '../../graphql/models';
import { RESOLVED_TASKS_STATUSES } from '../../utils/tasks';
import { DEFAULT_TIMEZONE } from '../../utils/user/timezone';
import { isNullOrUndefined } from '../../utils/helpers';

import { selectUser } from './user';
import { selectTeam } from './team-page';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';
import { FEATURE_FLAGS_TO_TASK_KINDS, FeatureFlagToTaskKinds } from '../../features';


export type DenormalizedTask = Omit<Task<undefined>, 'user' | 'team'> & {
  user: UserRecord;
  team: TeamRecord;
};

export const REQUIRED_COMMENT_TASKS = [TaskKind.surgeryIncr];

interface TransformedTask extends DenormalizedTask {
  formattedKind: string;
  userFullName: string;
  currentTeamWeekNumber: number | string;
  createdAt: string;
  teamName?: string | null;
  userId?: number | null;
  userStatus?: UserStatus | null;
  lastActive: string | number;
}

function convertToOneBasedIndex(weekIndex?: number | null): number | string {
  // CT-1690 can this be reused?
  const INDEX_OFFSET = 1;
  if (isNullOrUndefined(weekIndex)) return '-';
  return weekIndex + INDEX_OFFSET;
}

function formatDoTaskKind(kind?: TaskKind | null): string {
  if (!kind) return '';
  return OrderedDoTaskKindTitles[kind];
}

export function sortDescUpdatedAt(a: TaskRecord, b: TaskRecord) {
  if (!a.updatedAt || !b.updatedAt) return 1;
  const diff = moment(a.updatedAt).diff(b.updatedAt);
  if (diff < 0) return 1;
  if (diff > 0) return -1;
  return diff;
}

const getUserId = (_: {}, p: { userId: number }) => p.userId;

/**
 * Get the entire task map
 */
export function selectTasks(state: RootState) {
  return state.tasks;
}

/**
 * Gets a specific task based on id, creates an empty task record
 * if none exists.
 */
export function selectTask(state: RootState, props: { id: number }) {
  return state.tasks.get(props.id, createTaskRecord({ id: props.id }));
}

/**
 * Get pending tasks
 * TODO: CT-1680 support api for old tasks
 */

export const selectPendingTasks = createSelector(
  selectTasks,
  (tasks) => tasks.filter((task) => task
    ? task.status === TaskStatuses.Pending
    : false)
);

/**
 * Get all overview tasks
 */
export const selectPendingTasksArray = createSelector(
  selectPendingTasks,
  (tasks) => tasks.toArray()
);

/**
 * Get all completed tasks
 */
export const selectCompletedTasks = createSelector(
  selectTasks,
  (tasks) => tasks.filter((t) => {
    if (!t || !t.status) return false;
    return [TaskStatuses.Completed, TaskStatuses.Rejected].indexOf(t.status) > -1;
  }).toArray()
);

/**
 * Get all 'resolved' tasks
 */
export const selectResolvedTasks = createSelector(
  selectTasks,
  (tasks) => tasks.filter((t) => (t && t.status) ? RESOLVED_TASKS_STATUSES.indexOf(t.status) !== -1 : false)
);

/**
 * Get all tasks scoped to a user.
 */
export const selectUserTasks = createSelector(
  [selectTasks, getUserId],
  (tasks, userId) => tasks.filter((t) => !!t && t.user === userId)
);

/**
 * Get all pending tasks scoped to a user
 */
export const selectUserPendingTasks = createSelector(
  [selectPendingTasks, getUserId],
  (tasks, userId) => tasks.filter((t) => !!t && t.user === userId).toArray()
);

/**
 * Select all completed/resolved/rejected/expired tasks
 * created for this user. In the future, this will be moved to a history page
 */
export const selectUserHistoryTasks = createSelector(
  [selectResolvedTasks, getUserId],
  (tasks, userId) => tasks.filter(
    (t) => !!t && t.user === userId).sort(sortDescUpdatedAt).toArray() as RuleDrivenTask[] || []
);

export const selectOrderedTaskEntities = (state: RootState) => state.entities.tasks || [];

export const selectTaskCreatedAt = (task: Task<AnyTaskExtras> | DenormalizedTask) => task?.createdAt || '';


const calculateLastActiveDays = (user?: UserRecord): number => {
  const lastActive = user?.lastActive;
  // eslint-disable-next-line id-blacklist
  if (!lastActive) return Number.MAX_VALUE;
  return moment().diff(moment.utc(lastActive)
    .tz(user?.timezone || DEFAULT_TIMEZONE)
    .startOf('hour'),
  'days');
};

export const transformTask = (task: DenormalizedTask): TransformedTask => ({
  ...task,
  formattedKind: formatDoTaskKind(task?.kind),
  userFullName: `${task?.user?.firstName} ${task?.user?.lastName}`,
  userStatus: task?.user?.userStatus,
  teamName: task.team?.name,
  currentTeamWeekNumber: convertToOneBasedIndex(task.user?.weekIndex),
  userId: task.user?.id,
  lastActive: calculateLastActiveDays(task.user),
  createdAt: moment.utc(selectTaskCreatedAt(task)).tz(DEFAULT_TIMEZONE).format('M/D, h:mm a')
});

export const denormalizeTask = (task: TaskRecord, state: RootState): DenormalizedTask => ({
  ...task.toJS(),
  user: selectUser(state, { userId: !isNullOrUndefined(task.user) ? task.user : 0 }),
  team: selectTeam(state, { teamId: !isNullOrUndefined(task.team) ? task.team : 0 })
});

export const filterOutDisabledTasks = (task: TaskRecord, disabledTaskKinds: TaskKind[]) =>
  task ? task.kind && !disabledTaskKinds.includes(task.kind) : false;


export const selectTaskKindsDisabledByFeatureFlags = (
  state: RootState,
  featureFlaggedTasks: FeatureFlagToTaskKinds
) =>
  Object.entries(featureFlaggedTasks).reduce((acc, value: [string, TaskKind[]]) => {
    if (selectTreatmentValue(state.splitReducer, value[0]) !== 'on') {
      return acc.concat(...value[1]);
    }
    return acc;
  }, [] as TaskKind[]);

/**
 * Select distributed outreach tasks and transform them to objects
 * consumable by the tasks table
 */
export const selectDistributedOutreachTasks = (state: RootState) => {
  const taskIdsByStatus = selectOrderedTaskEntities(state).get(TaskStatuses.Pending) || [];
  const disabledTaskKinds = selectTaskKindsDisabledByFeatureFlags(state, FEATURE_FLAGS_TO_TASK_KINDS);

  const tasks = selectTasks(state);
  const orderedTasks = taskIdsByStatus.map<TaskRecord>(id => tasks.get(id)).filter(t => !isNullOrUndefined(t));
  const tasksWithoutDisabledTasks = orderedTasks.filter(task => filterOutDisabledTasks(task, disabledTaskKinds));

  return tasksWithoutDisabledTasks.filter(task => {
    const isDoTask = task.category === TaskCategories.Engagement && task.kind !== TaskKind.reEngager;
    return isDoTask;
  }).map(
    task => transformTask(denormalizeTask(task, state))
  );
};

export const selectClinicalAndReengagerTasks = (state: RootState, status: TaskStatuses) => {
  const disabledTaskKinds = selectTaskKindsDisabledByFeatureFlags(state, FEATURE_FLAGS_TO_TASK_KINDS);

  const taskEntities = selectOrderedTaskEntities(state).get(status);
  const tasks = selectTasks(state);

  if (!taskEntities || !taskEntities.length) return [];

  const tasksByStatus = taskEntities.map(id => tasks.get(id));

  const tasksByStatusWithoutDisabled = tasksByStatus.filter(task => filterOutDisabledTasks(task, disabledTaskKinds));

  return tasksByStatusWithoutDisabled
    .filter(task => task && (task.category === TaskCategories.Clinical || task.kind === TaskKind.reEngager)) || [];
};

export const isClinicalTaskKind = (taskKind?: TaskKind | null) =>
  !isNullOrUndefined(taskKind) && taskKind in ClinicalTaskKindTitles;

export const isRequiredCommentTaskKind = (taskKind?: TaskKind | null) =>
  !isNullOrUndefined(taskKind) && REQUIRED_COMMENT_TASKS.indexOf(taskKind) >= 0;
