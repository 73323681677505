export const GET_USER_PATHWAY_STATS = 'GET_USER_PATHWAY_STATS';
export const PUT_USER = 'PUT_USER';
export const PUT_USER_NAME = 'PUT_USER_NAME';
export const PUT_USER_CONTACT_INFO = 'PUT_USER_CONTACT_INFO';
export const ADD_USER_TAG = 'ADD_USER_TAG';
export const REMOVE_USER_TAG = 'REMOVE_USER_TAG';
export const APPEND_NEW_USER_MESSAGES = 'APPEND_NEW_MESSAGES';
export const PREPEND_OLD_USER_MESSAGES = 'PREPEND_OLD_MESSAGES';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const SET_USER_PREVIEW_MESSAGE = 'SET_USER_PREVIEW_MESSAGE';
export const SET_USER_HAS_UNREAD = 'SET_USER_HAS_UNREAD';
export const MARK_USER_ONBOARDED = 'MARK_USER_ONBOARDED';
export const RESET_USERS_ONBOARDED = 'RESET_USERS_ONBOARDED';
export const UPDATE_USER_ENROLLMENT_STATUS = 'UPDATE_USER_ENROLLMENT_STATUS';
