import * as React from 'react';
import './TodoListItem.scss';
import { NavLink } from 'react-router-dom';

import { Team } from '../../graphql/models';
import { RootState } from '../../redux/reducers';
import { connect } from 'react-redux';
import { selectCountByTaskKind } from '../../redux/selectors/ui-state';
import { clearTaskUserCounts } from '../../redux/actions/ui-state';
import { DispatchMap } from '../../redux/actions/interfaces';
import { MESSAGING_PATH } from '../../routes';

interface TodoListItemProps {
  todoKey: string;
  messageKey?: string;
  taskName: string;
  selectedTodo?: string;
  selectedTeam?: Team;
  setSelectedTodo(task: string): void;
  counter?: number;
}

type ConnectProps = DispatchMap<{
  resetTaskUserCounts?: typeof clearTaskUserCounts;
}>;

type Props = TodoListItemProps & ConnectProps;

const TodoListItem: React.FC<Props> = props => {
  const { todoKey, taskName, selectedTodo, setSelectedTodo, selectedTeam, resetTaskUserCounts } = props;
  const [routeSelected, setRouteSelected] = React.useState<boolean>(false);

  const handleOnClick = React.useCallback(() => {
    if (resetTaskUserCounts) {
      resetTaskUserCounts({ todoKey });
    }
    setSelectedTodo(todoKey);
  }, [todoKey]);
  return (
    // Since deep linking for tasks is not required, we're linking out to
    // the /messaging absolute.
    <NavLink
      to={MESSAGING_PATH}
      className={(isActive) => {
        setRouteSelected(isActive);
        return isActive ? 'tasks-list-item--selected' : '';
      }}
    >
      <div
        className={`tasks-list-item 
          ${!selectedTeam && routeSelected && selectedTodo === todoKey && 'tasks-list-item--selected'}`}
        onClick={handleOnClick}>
        <div className="tasks-list-item-name">{taskName}</div>
        {!!props.counter &&
          <div className="tasks-list-item__count">
            {props.counter}
          </div>
        }
      </div>
    </NavLink>
  );
};
const mapDispatchToProps = {
  resetTaskUserCounts: clearTaskUserCounts
};

const mapStateToProps = (state: RootState, props: Props) => ({
  counter: selectCountByTaskKind(state, props.todoKey)
});

export default connect(mapStateToProps, mapDispatchToProps)(TodoListItem);
