export const JUST_CALL_URI = 'https://justcall.io/dialer';
export const HELPSCOUT_URI = 'https://secure.helpscout.net';
export const ZOOM_PHONE_CALL_URI = 'https://app.zoom.us/wc/phone';

export function getBffUrl() {
  return process.env.REACT_APP_BFF_URL || 'http://localhost:8085';
}

export function getApiUrl() {
  return process.env.REACT_APP_API_URL || 'http://localhost:3000';
}

export function getClientUrl() {
  return process.env.REACT_APP_COACH_TOOLS_ORIGIN || 'http://localhost:8000';
}

export function getBasiliskGraphqlUrl() {
  return process.env.REACT_APP_GRAPHQL_URL || 'http://localhost:8085/api/graphql/coach_tools';
}

export function getNestBffUrl() {
  return process.env.REACT_APP_NEST_BFF_URL || 'http://localhost:8086';
}

export function getCareTeamProductsAppUrl() {
  return process.env.REACT_APP_CARE_TEAM_PRODUCTS_ORIGIN || 'http://localhost:8002';
}
