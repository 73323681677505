import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from '../../redux/actions';
import { Pathway, User } from '../../graphql/models';
import { GET_USER_INFO } from '../../graphql/queries';
import { RootState, UserRecord } from '../../redux/reducers';
import { selectUser } from '../../redux/selectors';
import { ApolloError, useQuery } from '@apollo/client';
import { getUserActivePathway } from './getUserActivePathway';

interface Response {
  user: User;
}

export interface UseUserQueryOutput {
  user: UserRecord;
  pathway?: Pathway;
  loading: boolean;
  error: ApolloError | undefined;
}

const useUserQuery = (userId: number): UseUserQueryOutput => {

  const { data, loading, error } = useQuery<Response, { id: string }>(
    GET_USER_INFO,
    {
      variables: { id: userId.toString() },
      fetchPolicy: 'no-cache'
    }
  );
  const dispatchThunk = useDispatch();
  const user = useSelector((state: RootState) => selectUser(state, { userId }));

  React.useEffect(()=>{
    if (data?.user) {
      const saveUsersThunk = userActions.saveUsersToRedux([data.user]);
      saveUsersThunk(dispatchThunk);
    }
  }, [userId, data]);

  return {
    user,
    pathway: getUserActivePathway(user),
    loading: Boolean(loading && !user),
    error
  };
};

export default useUserQuery;
