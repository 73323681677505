import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { actionCreators as tasksActions, RESOLVED_TASKS_PER_PAGE } from '../../redux/actions/tasks';
import { DispatchMap } from '../../redux/actions/interfaces';
import { RootState, UserRecord } from '../../redux/reducers';
import { selectUserHistoryTasks } from '../../redux/selectors/tasks';
import { selectUser } from '../../redux/selectors/user';
import { RuleDrivenTask, TaskStatuses, TaskKind } from '../../api-client';
import HistoryTaskCard from '../../components/tasks/HistoryTaskCard';

const HistoryContainer = styled.div`
  margin: 0 auto;
  width: 700px;
`;

export interface UserTasksHistoryContainerProps {
  userId: number;
}

type ConnectProps = DispatchMap<{
  listTasks: typeof tasksActions.getList;
  tasks: RuleDrivenTask[];
  user?: UserRecord;
  highUtilTasksEnabled?: boolean;
}>;

type Props = UserTasksHistoryContainerProps & ConnectProps;

class UserTasksHistoryContainer extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.listTasks(
      { userId: this.props.userId, status: TaskStatuses.Completed, itemsPerPage: RESOLVED_TASKS_PER_PAGE });
    this.props.listTasks(
      { userId: this.props.userId, status: TaskStatuses.Rejected, itemsPerPage: RESOLVED_TASKS_PER_PAGE });
    this.props.listTasks(
      { userId: this.props.userId, status: TaskStatuses.Obsolete, itemsPerPage: RESOLVED_TASKS_PER_PAGE });
  }

  render() {
    const { tasks, user } = this.props;
    const tasksToDisplay = this.props.highUtilTasksEnabled ?
      tasks : tasks.filter(t => t.kind !== TaskKind.newHighUtilizationUser);

    return (user && tasks) ? (
      <HistoryContainer>
        {tasks.length ? tasksToDisplay.map((task) => (
          <HistoryTaskCard key={task.id} task={task} user={user} />
        )) : <p>Nothing in this user's history.</p>}
      </HistoryContainer>
    ) : null;
  }
}

const mapStateToProps = (state: RootState, props: UserTasksHistoryContainerProps) => ({
  tasks: selectUserHistoryTasks(state, props),
  user: selectUser(state, props)
});

const mapDispatchToProps = {
  listTasks: tasksActions.getList
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTasksHistoryContainer);
