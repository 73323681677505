import { getApiUrl, getBffUrl } from '../constant';
import { createEndpoint } from './Endpoint';
import * as E from './endpoints';

export interface ApiConfig {
  baseUri?: string;
  bffUri?: string;
}

export interface AuthInfo {
  'access-token'?: string | null;
  client?: string | null;
  uid?: string | null;
  uuid?: string | null;
  id?: string | null;
}

export default class ApiClient {
  auth: {
    login: E.LoginEndpoint;
    logout: E.LogoutEndpoint;
    bffValidate: E.BffValidateEndpoint;
    refresh: E.RefreshEndpoint;
  };

  admin: {
    get: E.GetAdminEndpoint;
  };

  tasks: {
    list: E.ListTasksEndpoint;
    complete: E.CompleteTaskEndpoint;
    completeOutreach: E.CompleteOutreachTaskEndpoint;
    tasksbyCount: E.TasksByCountEndpoint;
    users: E.TaskUsersEndpoint;
    getMassMessageTemplates: E.GetMassMessageTemplatesEndpoint;
    sendMassMessages: E.SendMassMessagesEndpoint;
  };

  notes: {
    create: E.CreateNoteEndpoint;
    get: E.GetNotesEndpoint;
    update: E.UpdateNoteEndpoint;
    delete: E.DeleteNoteEndpoint;
    export: E.ExportNoteEndpoint;
  };

  users: {
    getPathwayStats: E.GetUserPathwayStatsEndpoint;
    getUsers: E.GetUsersEndpoint;
    update: E.UpdateUserEndpoint;
    addTag: E.AddUserTag;
    removeTag: E.DeleteUserTag;
    getUserAchievements: E.GetUserAchievementsEndpoint;
    getUsersByTeam: E.GetUsersByTeamEndpoint;
    updateUserEnrollmentStatus: E.UpdateUserEnrollmentStatus;
    getUsersEnrollmentStatus: E.GetUsersEnrollmentStatus;
  };

  goals: {
    get: E.GetGoalsEndpoint;
    update: E.UpdateGoalEndpoint;
    post: E.PostGoalEndpoint;
    delete: E.DeleteGoalEndpoint;
  };

  teams: {
    getTeams: E.GetTeamsEndpoint;
  };

  messages: {
    getMessages: E.GetMessagesEndpoint;
    getMessagesNew: E.GetMessagesEndpoint;
    getScheduledMessages: E.GetScheduledMessagesEndpoint;
    getScheduledMessagesNew: E.GetScheduledMessagesEndpoint;
    update: E.UpdateMessageEndpoint;
    updateNew: E.UpdateMessageEndpoint;
    post: E.PostMessageEndpoint;
    postNew: E.PostMessageEndpoint;
    delete: E.DeleteMessageEndpoint;
    deleteNew: E.DeleteMessageEndpoint;
    markAsRead: E.MarkAsReadEndpoint;
    markAsReadNew: E.MarkAsReadEndpoint;
  };

  messagesV2: {
    get: E.GetMessagesEndpointV2;
    create: E.CreateMessageEndpointV2;
    update: E.UpdateMessagesEndpointV2;
    delete: E.DeleteMessageEndpointV2;
    deleteNew: E.DeleteMessageEndpointV2;
  };

  conversations: {
    list: E.GetConversationsEndpoint;
    listNew: E.GetConversationsEndpoint;
    unseen_messages: E.GetUnseenMessagesEndpoint;
  };

  videoCall: {
    post: E.CreateVideoCallEndpoint;
    createJwt: E.CreateJwtEndpoint;
    getVideoCalls: E.GetVideoCallsEndpoint;
  };

  constructor() {
    this.auth = {
      login: createEndpoint(this, E.login),
      logout: createEndpoint(this, E.logout),
      bffValidate: createEndpoint(this, E.bffValidate),
      refresh: createEndpoint(this, E.refresh)
    };
    this.admin = {
      get: createEndpoint(this, E.getAdmin)
    };
    this.tasks = {
      list: createEndpoint(this, E.listTasks),
      complete: createEndpoint(this, E.completeTask),
      completeOutreach: createEndpoint(this, E.completeOutreachTask),
      tasksbyCount: createEndpoint(this, E.tasksByCount),
      users: createEndpoint(this, E.getTaskUsers),
      getMassMessageTemplates: createEndpoint(this, E.getMassMessageTemplates),
      sendMassMessages: createEndpoint(this, E.sendMassMessages)
    };
    this.users = {
      getUsers: createEndpoint(this, E.getUsers),
      getPathwayStats: createEndpoint(this, E.getUserPathwayStats),
      update: createEndpoint(this, E.updateUser),
      addTag: createEndpoint(this, E.addUserTag),
      removeTag: createEndpoint(this, E.deleteUserTag),
      getUserAchievements: createEndpoint(this, E.getUserAchievements),
      getUsersByTeam: createEndpoint(this, E.getUsersByTeam),
      updateUserEnrollmentStatus: createEndpoint(this, E.updateUserEnrollmentStatus),
      getUsersEnrollmentStatus: createEndpoint(this, E.getUsersEnrollmentStatus)
    };
    this.teams = {
      getTeams: createEndpoint(this, E.getTeams)
    };
    this.goals = {
      get: createEndpoint(this, E.getGoals),
      update: createEndpoint(this, E.updateGoal),
      post: createEndpoint(this, E.postGoal),
      delete: createEndpoint(this, E.deleteGoal)
    };
    this.messages = {
      getMessages: createEndpoint(this, E.getMessages),
      getMessagesNew: createEndpoint(this, E.getMessagesNew),
      getScheduledMessages: createEndpoint(this, E.getScheduledMessages),
      getScheduledMessagesNew: createEndpoint(this, E.getScheduledMessagesNew),
      update: createEndpoint(this, E.updateMessage),
      updateNew: createEndpoint(this, E.updateMessageNew),
      post: createEndpoint(this, E.postMessage),
      postNew: createEndpoint(this, E.postMessageNew),
      delete: createEndpoint(this, E.deleteMessage),
      deleteNew: createEndpoint(this, E.deleteMessageNew),
      markAsRead: createEndpoint(this, E.markAsRead),
      markAsReadNew: createEndpoint(this, E.markAsReadNew)
    };
    this.messagesV2 = {
      get: createEndpoint(this, E.getMessagesV2),
      create: createEndpoint(this, E.createMessageV2),
      update: createEndpoint(this, E.updateMessageV2),
      delete: createEndpoint(this, E.deleteMessageV2),
      deleteNew: createEndpoint(this, E.deleteMessageV2)
    };
    this.conversations = {
      list: createEndpoint(this, E.getConversations),
      listNew: createEndpoint(this, E.getConversationsNew),
      unseen_messages: createEndpoint(this, E.getUnseenMessages)
    };
    this.videoCall = {
      post: createEndpoint(this, E.createVideoCall),
      createJwt: createEndpoint(this, E.createJwt),
      getVideoCalls: createEndpoint(this, E.getVideoCalls)
    };
    this.notes = {
      create: createEndpoint(this, E.createNote),
      get: createEndpoint(this, E.getNotes),
      update: createEndpoint(this, E.updateNote),
      delete: createEndpoint(this, E.deleteNote),
      export: createEndpoint(this, E.exportNote)
    };
  }

  getBaseUri() {
    return getApiUrl();
  }

  getBffUri() {
    return getBffUrl();
  }
}
