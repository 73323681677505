import * as initTrace from 'debug';
import { datadogRum } from '@datadog/browser-rum';
import {
  GenericAction,
  PayloadAction,
  VoidAction,
  ThunkAction
} from './interfaces';
import * as errors from '../../errors';
import {
  CLEAR_AUTH,
  SET_AUTH_EMAIL,
  SET_AUTH_PASSWORD,
  SET_ADMIN,
  REMOVE_ADMIN,
  OAUTH_LOGIN,
  ADD_AUTH,
  SHOW_NOTIFICATION
} from '../constants';
import {
  selectAuthEmail,
  selectAuthPassword,
  getApiClient,
  selectAdmin
} from '../selectors';
import { Admin } from '../../api-client';
import { SetAdminAction, RemoveAdminAction } from './admin';
import { AuthInfo } from '../../api-client/ApiClient';
import { convertStrToNum } from '../../utils/number';
import { initSplitSdk, getTreatments } from '@splitsoftware/splitio-redux';
import { REGISTER_TREATMENT_LIST } from '../../features';
import { parseCookie } from '../../utils/cookie';
import { AddErrorAction, ClearErrorAction } from './ui-state';
import { authActions } from '.';
import { GET_ADMIN } from '../../graphql/queries';
export interface AuthState {
  email?: string;
  password?: string;
  error?: boolean;
  baseUri?: string;
  bffUri?: string;
  'access-token'?: string | null;
  client?: string | null;
  uid?: string | null;
  uuid?: string | null;
  id?: string | null;
}

export interface AddAuthAction extends PayloadAction<AuthState> {
  type: typeof ADD_AUTH;
}
export interface ClearAuthAction extends VoidAction {
  type: typeof CLEAR_AUTH;
}

export interface SetAuthAction extends PayloadAction<string> {
  type:
  | typeof SET_AUTH_EMAIL
  | typeof SET_AUTH_PASSWORD;
}

export interface OAuthLoginAction extends PayloadAction<AuthInfo | undefined> {
  type: typeof OAUTH_LOGIN;
}

export interface OAuthLoginFailure extends GenericAction<undefined, undefined> {
  type: typeof OAUTH_LOGIN;
}

export type AuthAction =
  | OAuthLoginAction
  | OAuthLoginFailure
  | ClearAuthAction
  | SetAuthAction
  | AddAuthAction;

const debug = initTrace('ca:redux:actions:auth');

export const actionCreators = {
  initSplitio(): ThunkAction<void> {
    return async(dispatch, getState) => {
      const state = getState();
      const admin = state.admin;
      const auth = state.auth;

      const attributes = {
        coachId: admin.id ? admin.id.toString() : '',
        coachEmail: admin.email as string,
        uid: auth.uid as string,
        adminUuid: admin.uuid ? admin.uuid.toString() : ''
      };

      const sdkBrowserConfig = {
        core: {
          authorizationKey: process.env.REACT_APP_SPLIT_IO_AUTH_KEY as string,
          key: attributes.coachEmail
        }
      };

      const getSplitTreatments = () =>
        getTreatments({ splitNames: REGISTER_TREATMENT_LIST, attributes });
      dispatch(
        initSplitSdk({
          config: sdkBrowserConfig,
          onReady: getSplitTreatments
        })
      );
    };
  },
  /** Clear out all authentication state - use this when done with a login form. */
  clear(): ClearAuthAction {
    return { type: CLEAR_AUTH };
  },
  /**
   * Attempt to obtain authentication credentials. The credentials are automatically saved by the API client to
   * localStorage, so nothing needs to be done with the result.
   *
   * The email and password must be set before dispatching this action.
   */
  login(): ThunkAction<Promise<{ data: Admin }>> {
    return (dispatch, getState) => {
      const state = getState();
      const api = getApiClient(state);
      const email = selectAuthEmail(state);
      const password = selectAuthPassword(state);
      return api.auth
        .login({ email, password })
        .then(res => {
          dispatch<SetAdminAction>({ payload: res.data, type: SET_ADMIN });

          dispatch<ClearErrorAction>({
            payload: { message: '', httpStatus: 0, type: '' },
            type: SHOW_NOTIFICATION
          });
          return res;
        })
        .catch(err => {
          debug('login failed', err);
          throw err;
        });
    };
  },
  validateOkta(): ThunkAction<Promise<Admin>> {
    return (dispatch, getState) => {
      const api = getApiClient(getState());
      const currentAdmin = selectAdmin(getState());
      if (currentAdmin?.uuid) {
        return Promise.resolve(currentAdmin);
      }
      return api.admin.get({ query: GET_ADMIN })
        .then(res => {
          if (res.data === false) {
            throw new Error(errors.ERR_ADMIN_PARSE_FAIL);
          }
          const admin: Admin = res.data.admin;
          const authInfo = {
            uid: admin.email,
            uuid: admin.uuid,
            id: `${admin.id}`
          };
          const { uid, uuid } = authInfo;
          const adminInfo: Admin = { id: admin.id, email: uid, uuid , role:admin.role};
          dispatch<AddAuthAction>({ type: ADD_AUTH, payload: authInfo });
          dispatch<SetAdminAction>({ type: SET_ADMIN, payload: adminInfo });
          return res.data.admin;
        })
        .catch(err => {
          debug('login okta failed', err);
          throw err;
        });
    };
  },
  validate(): ThunkAction<Promise<{}>> {
    return (dispatch, getState) => {
      const api = getApiClient(getState());
      return api.auth
        .bffValidate()
        .then(res => {
          const c = parseCookie(document.cookie);
          const authInfo = {
            uid: c.uid,
            uuid: c.uuid,
            'access-token': c['access-token'],
            id: c.id,
            client: c.client
          };
          const { id, uid, uuid } = authInfo;
          const parsedId = convertStrToNum(id);
          const adminInfo: Admin = { id: parsedId, email: uid, uuid };
          dispatch<AddAuthAction>({ type: ADD_AUTH, payload: authInfo });
          dispatch<SetAdminAction>({ type: SET_ADMIN, payload: adminInfo });
          return res;
        })
        .catch(err => {
          debug('login failed', err);
          throw err;
        });
    };
  },

  /**
   * When a request returns a 401 make a request to refresh the
   * auth-access-token.  The refresh-token cookie is sent automatically
   * to create a new JWT with the auth-service, which in turn are sent
   * back as new auth-access-token & refresh-token cookies.
   */
  refresh(): ThunkAction<Promise<{}>> {
    return (dispatch, getState) => {
      const api = getApiClient(getState());
      return api.auth
        .refresh()
        .then(() => true)
        .catch(async(err) => {
          dispatch<AddErrorAction>({
            payload: {
              message: 'Session has expired. Save your local work and login again.',
              httpStatus: 401,
              type: 'warning'
            },
            type: SHOW_NOTIFICATION
          });

          try {
            dispatch(authActions.logout());
          } catch {}

          throw err;
        });
    };
  },

  /**
   * Deauthenticates the coach and removes any stored credentials from localstorage.
   * This action will remove any credentials even if the logout request failed.
   */
  logout(): ThunkAction<Promise<{}>> {
    return (dispatch, getState) => {
      const apiClient = getApiClient(getState());

      return apiClient.auth
        .logout()
        .then(() => {
          dispatch<RemoveAdminAction>({ type: REMOVE_ADMIN });
          dispatch<ClearAuthAction>({ type: CLEAR_AUTH });
          return true;
        })
        .catch(e => {
          throw new Error(`${errors.E28_ERR_FAILED_TO_LOGOUT} ${e}`);
        });
    };
  },
  /**
   * Set the email address to use for authentication.
   *
   * @param email The user's email address.
   */
  setEmail(email: string): SetAuthAction {
    return { payload: email, type: SET_AUTH_EMAIL };
  },
  /**
   * Set the password to use for authentication.
   *
   * @param password The user's password.
   */
  setPassword(password: string): SetAuthAction {
    return { payload: password, type: SET_AUTH_PASSWORD };
  },
  initDataDogUser(): ThunkAction<void> {
    return async(_, getState) => {
      const state = getState();
      const admin = state.admin;
      const { uuid, email } = admin;


      if (process.env.REACT_APP_ENV !== 'production') {
        datadogRum.setUser({
          id: uuid as string,
          email: email as string
        });
      }
    };
  }
};
