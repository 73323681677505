import { useQuery } from '@apollo/client';
import * as React from 'react';
import { GET_USER_INFO } from '../../../graphql/queries';
import { User } from '../../../graphql/models/User';
import { Segment, Header, Grid } from 'semantic-ui-react';
import { makeEmailFilteredUri, trackClick } from '../PathwayLinksSegment';

const SCREENING_PAGE = 'screenings';
const SCOPE_ALL_PARAMS = { scope: 'all' };

interface PathwayLinksContainerProps {
  id: number;
}

interface Response {
  user: User;
}

const PathwayLinksContainer: React.FC<PathwayLinksContainerProps> = ({
  id
}) => {
  const { loading, error, data } = useQuery<Response, { id: string }>(
    GET_USER_INFO,
    { variables: { id: id.toString() } }
  );
  if (loading) return <Grid />;
  if (error) {
    return (
      <Segment padded="very">
        <Grid>
          <div>Failed to load Admin Panel links</div>
        </Grid>
        );
      </Segment>
    );
  }

  const userInfo: User = data && data.user ? data.user : { id: id.toString() };

  return (
    <Segment padded="very">
      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column>
            <Header>Links</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Column>
          <Header>
            <a
              target={'_blank'}
              onClick={() => trackClick('Weight logs')}
              href={makeEmailFilteredUri('body_measurements', userInfo)}
            >
              Weight logs
            </a>
          </Header>
        </Grid.Column>
        <Grid.Column>
          <Header>
            <a
              target={'_blank'}
              onClick={() => trackClick('Article reads')}
              href={makeEmailFilteredUri('article_reads', userInfo)}
            >
              Article reads
            </a>
          </Header>
        </Grid.Column>
        <Grid.Column>
          <Header>
            <a
              target={'_blank'}
              onClick={() => trackClick('Screener')}
              href={makeEmailFilteredUri(
                SCREENING_PAGE,
                userInfo,
                SCOPE_ALL_PARAMS
              )}
            >
              Screener
            </a>
          </Header>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default PathwayLinksContainer;
