import { OptionalProperties } from '../index';

export enum Status {
  OPT_IN = 'opt_in',
  OPT_OUT = 'opt_out'
}
export type EnrollmentStatus = OptionalProperties<{
  __typename: string;
  id: string;
  status: Status.OPT_IN | Status.OPT_OUT;
  createdAt: string;
  userUuid: string;
}>;
