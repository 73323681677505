import { ApolloClient, ApolloProvider, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import * as React from 'react';
import { connect } from 'react-redux';
import { setContext } from '@apollo/client/link/context';
import { v4 as uuidv4 } from 'uuid';

import { getAuthHeaders } from '../../redux/selectors';

import { RootState } from '../../redux/reducers';

import { DispatchMap } from '../../redux/actions/interfaces';
import { AuthInfo } from '../../api-client/ApiClient';

import { parseCookie } from '../../utils/cookie';
import { mixpanelInit } from '../../mixpanel/mixpanel';

import { getBasiliskGraphqlUrl, getBffUrl, getNestBffUrl } from '../../constant';
import errorLink from '../../api-client/errorLink';

import { formatAuthorizationHeader, getOktaAccessToken } from '../../utils/helpers';

export enum ApiClientNames {
  BFF = 'koaBff',
  NestBFF = 'nestBff',
  Basilisk = 'basilisk'
}

type ConnectProps = DispatchMap<{
  headers: AuthInfo | {};
}>;

type Props = ConnectProps;

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        user: (existingData, { args, toReference }) => existingData || toReference({ __typename: 'User', id: args?.id })
      }
    }
  }
});

export const setCorrelationIdLink = setContext((_, previousContext) => ({
  headers: {
    ...previousContext.headers,
    'x-hh-request-id': uuidv4()
  }
}));

export const setAuthHeadersLink = setContext((_, previousContext) => {
  const oktaAccessToken = getOktaAccessToken();

  let headers = { ...previousContext.headers };

  if (previousContext.clientName !== ApiClientNames.BFF) {
    const parsedCookie = parseCookie(document.cookie);
    const parsedBearerToken = parsedCookie['auth-access-token'];
    const cookieHeaders: Record<string, string> = {
      uid: parsedCookie.uid,
      uuid: parsedCookie.uuid,
      'access-token': parsedCookie['access-token'],
      id: parsedCookie.id,
      client: parsedCookie.client,
      authorization: formatAuthorizationHeader(parsedBearerToken)
    };
    /**
     * With okta auth some cookies are not defined we need to ensure
     * that the undefined value won't be sended in the request headers
     */
    Object
      .keys(cookieHeaders)
      .forEach(
        (key: string) => !cookieHeaders[key] && delete cookieHeaders[key]
      );
    headers = {
      ...headers,
      ...cookieHeaders
    };
  }

  if (oktaAccessToken) {
    headers.authorization = formatAuthorizationHeader(oktaAccessToken);
  }

  return { headers };
});

const basiliskHttpLink = new HttpLink({
  uri: getBasiliskGraphqlUrl(),
  credentials: 'include'
});

const bffHttpLink = new HttpLink({
  uri: `${getBffUrl()}/graphql`,
  credentials: 'include'
});

const nestBffHttpLink = new HttpLink({
  uri: `${getNestBffUrl()}/graphql`,
  credentials: 'include'
});

const koaBffBasiliskSplitLink = ApolloLink.split(
  operation => operation.getContext().clientName === ApiClientNames.BFF,
  bffHttpLink,
  basiliskHttpLink
);

export const bffSplitLink = ApolloLink.split(
  operation => operation.getContext().clientName === ApiClientNames.NestBFF,
  nestBffHttpLink,
  koaBffBasiliskSplitLink
);

const baseLink = ApolloLink.from([setCorrelationIdLink, setAuthHeadersLink, errorLink()]);

const link = baseLink.concat(bffSplitLink);

export const apolloClient = new ApolloClient<{}>({ cache, link });

/**
 * An Apollo provider customized to read configuration data from a redux store.
 *
 * This component functions just like ApolloProvider, except the client is
 * configured automatically based on state from the redux store. This allows for
 * changing the client configuration while the app is still running. It is
 * particularly important for supporting authentication (which might have to be
 * fetched from persistent storage) and pointing to a different graphql endpoint
 * at runtime for testing purposes.
 *
 * Use of PureComponent is vital here, because we really don't want to be
 * reinstantiating apollo clients for every redux state change.
 */
class ApolloContainer extends React.Component<Props> {
  render() {
    const { children, headers } = this.props;

    mixpanelInit({
      Origin: `${window.location.href}`,
      client: (headers as AuthInfo).client as string
    });

    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
  }
}

const mapStateToProps = (state: RootState) => ({
  headers: getAuthHeaders(state)
});

export default connect(mapStateToProps)(ApolloContainer);
