import * as React from 'react';
import { getClientUrl } from '../../../constant';


export const isProduction = getClientUrl() === 'https://coach.hingehealth.com';
export const DEBUG_MODE = process.env.REACT_APP_DEBUG_MODE;
interface Props {
  isTest?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const DumpItemIds: React.FC<Props> = (props) => {
  if ((!isProduction || props.isTest) && DEBUG_MODE) {
    return (
      <>
        {Object.entries(props).map(([key, value]) => {
          if (!value) return null;
          return <strong style={{ color: 'red' }} id={String(value.id)}>{`${key}_id: ${value.id} `}</strong>;
        })}
      </>
    );
  }
  return null;
};

export default DumpItemIds;
