import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import mixpanelMiddleware from '../mixpanel/mixpanelMiddleware';
import { rootReducer } from './reducers';

export const enableDebugging =
  process.env.NODE_ENV === 'development' ||
  process.env.ACTIVE_ENV === 'staging';
export const history = createBrowserHistory();

const composeEnhancers =
  (enableDebugging &&
    window &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || // eslint-disable-line @typescript-eslint/no-explicit-any
  compose;

export function getMiddleware(opts?: { enableLogger: boolean }) {
  // configure middlewares
  const middlewares: Middleware[] = [
    routerMiddleware(history),
    mixpanelMiddleware,
    thunk
  ];

  /* istanbul ignore if */
  if (opts && opts.enableLogger) {
    middlewares.push(
      createLogger({
        collapsed: true,
        duration: true,
        diff: true
      })
    );
  }
  return composeEnhancers(applyMiddleware(...middlewares));
}
// new store for test
export function configureStore(opts?: { enableLogger: boolean }) {
  const enhancer =  getMiddleware({ enableLogger: enableDebugging });
  return createStore(rootReducer(history), enhancer);
}
