export const SEND_MASS_MESSAGES = `
  mutation sendMassMessages(
    $adminUuid: String,
    $messageTemplate: String!,
    $users: MassMessageUsersType
  ) {
    sendMassMessages(
      adminUuid: $adminUuid,
      messageTemplate: $messageTemplate,
      users: $users
    )
  }
`;
