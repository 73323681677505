import { useQuery, useLazyQuery } from '@apollo/client';
import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { SEARCH_USERS, GET_BULK_USERS } from '../../../graphql/queries/User';
import { User } from '../../../graphql/models/User';
import { mergeUserData, getUserUuids } from '../../../utils/bulkUsers';
import { ApiClientNames } from '../../apollo/ApolloContainer';
import TeamResultsTable from '../../../components/phx/teams/TeamResultsTable';

const StyledDiv = styled.div`
  color: grey;
  position: absolute;
  left: 50%; 
  top: 60%;
`;

const ErrorMessage = 'Failed to load users.';
const NoUsersMessage = 'No users found.';

interface SearchResultsContainerProps {
  searchValue: string;
}

interface SearchUsersResponse {
  userSearch: User[];
}

interface BulkUsersResponse {
  getUsersByUuids: User[];
}

const SearchResultsContainer: React.FC<SearchResultsContainerProps> = ({
  searchValue
}) => {
  const { loading, error, data } = useQuery<
  SearchUsersResponse,
  { query: string }
  >(SEARCH_USERS, {
    variables: { query: searchValue },
    fetchPolicy: 'network-only'
  });

  const searchUsers: User[] = data?.userSearch || [];

  const [ getBulkUsers, { loading: bulkUsersLoading, error: bulkUsersError, data: bulkUsersData }] =
  useLazyQuery<BulkUsersResponse, { uuids: string[] }>(GET_BULK_USERS,
    {
      fetchPolicy: 'network-only'
    });

  React.useEffect(() => {
    if (!!searchUsers.length) {
      getBulkUsers({
        variables: { uuids: getUserUuids(searchUsers) },
        context: { clientName: ApiClientNames.BFF }
      });
    }
  }, [!!searchUsers.length]);

  const bulkUsers: User[] = bulkUsersData?.getUsersByUuids || [];

  if (error || bulkUsersError) return <StyledDiv>{ErrorMessage}</StyledDiv>;

  if (loading || bulkUsersLoading) {
    return <StyledDiv>
      <CircularProgress />
    </StyledDiv>;
  }

  if (!data ||
    !searchUsers.length ||
    bulkUsersData?.getUsersByUuids.length === 0) {
    return <StyledDiv>{NoUsersMessage}</StyledDiv>;
  }

  const users = mergeUserData(searchUsers, bulkUsers);

  return <TeamResultsTable users={users} />;
};

export default SearchResultsContainer;
