import * as React from 'react';
import { noop } from 'lodash';

export interface NotificationType {
  message: string;
  httpStatus: number;
  type: string;
}

interface NotificationProps {
  children?: React.ReactNode;
}

export const NotificationContext = React.createContext({
  notification: {message: '', httpStatus: 0, type: ''},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addNotification: (data: NotificationType) => {},
  removeNotification: noop
});

export default function NotificationProvider(props: NotificationProps) {
  const [notification, setNotification] = React.useState({message: '', httpStatus: 0, type: ''});

  const removeNotification = () => setNotification({message: '', httpStatus: 0, type: ''});

  const addNotification = (data: NotificationType) => setNotification(data);

  const contextValue = {
    notification,
    addNotification: React.useCallback((data: NotificationType) => addNotification(data), []),
    removeNotification: React.useCallback(() => removeNotification(), [])
  };
  return (
    <NotificationContext.Provider value={contextValue}>
      {props.children}
    </NotificationContext.Provider>
  );
};
