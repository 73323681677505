import * as React from 'react';
import { useSelector } from 'react-redux';
import { useQuery, gql } from '@apollo/client';
import { Image, Menu } from 'semantic-ui-react';
import { People, List, Chat, Help, LocalHospital } from '@material-ui/icons';
import Campaign from '../../images/campaignSvg';
import { Link } from 'react-router-dom';
import * as logo from '../../images/logo.svg';
import { isEqual } from 'lodash';
import { ApiClientNames } from '../apollo/ApolloContainer';
import { RootState } from '../../redux/reducers';
import { openMassMessagingTab } from '../../containers/massmessaging/MassMessagingContainer';
import { selectAdmin } from '../../redux/selectors';
import { isPtRole } from '../../utils/admin/roles';
import { GET_ADMIN } from '../../graphql/queries';
import { Admin } from '../../api-client/interfaces';

export interface NavBarProps {
  activeItem?: string;
}

const NavBar: React.FunctionComponent<NavBarProps> = props => {
  const adminId = useSelector((state: RootState) => selectAdmin(state).id);
  const adminRes = useQuery<{ admin: Admin }, { id: number }>(
    gql`
      ${GET_ADMIN}
    `,
    {
      variables: { id: adminId },
      context: { clientName: ApiClientNames.BFF }
    }
  );

  return (
    <Menu vertical icon inverted borderless style={{ height: '100vh' }}>
      <Menu.Item name='home'>
        <Image height='35' width='35' src={logo} />
      </Menu.Item>
      <Link to='/tasks'>
        <Menu.Item link active={props && props.activeItem === 'tasks'} name='tasks'>
          <List />
        </Menu.Item>
      </Link>
      <Link to='/teams'>
        <Menu.Item link active={props && props.activeItem === 'teams'} name='users'>
          <People />
        </Menu.Item>
      </Link>
      <Link to='/messaging'>
        <Menu.Item link active={props && props.activeItem === 'messaging'} name='mail outline'>
          <Chat />
        </Menu.Item>
      </Link>
      {adminRes?.data?.admin?.role && isPtRole(adminRes.data.admin.role) && (
        <Link to='/care-plans'>
          <Menu.Item link active={props && props.activeItem === 'care-plans'}>
            <LocalHospital />
          </Menu.Item>
        </Link>
      )}
      <Menu.Item
        link
        active={props && props.activeItem === 'massmessaging'}
        name='users'
        onClick={openMassMessagingTab}
      >
        <Campaign />
      </Menu.Item>
      <a href={'https://docs.google.com/document/d/1l36b2EGaUYrkeJ6fhmg20B5E94r8JXkHg5jXaELsU7E'} target='_blank'>
        <Menu.Item link name='question circle'>
          <Help />
        </Menu.Item>
      </a>
    </Menu>
  );
};

export default React.memo(NavBar, isEqual);
