import * as React from 'react';
import { Loader } from 'semantic-ui-react';
import { throttle } from 'lodash';
import { Box } from 'grommet';

export interface InfiniteScrollerProps {
  loadMore(): void;
  currentPage: number;
  isLoading: boolean;
  indexToRender?: number;
  reversed?: boolean;
  totalPages?: number;
  style?: React.CSSProperties;
  parentRef?: React.RefObject<HTMLDivElement>;
  scrollToTop?: boolean;
  resetScrollToTop?(): void;
}

export const FIRST_ELEMENT_INDEX = 0;
// threshold to calculate if the scrollbar is at or above this position
export const THRESHOLD = 15;

const THROTTLE_WAIT = 300;

const ERROR_NO_SCROLL_REF = new Error(
  'No ref is specified for div.scroll-parent'
);
const ERROR_NO_LIST_REF = new Error('No ref is specified for div.list-parent');

/** STYLES */
const ScrollParentStyle: React.CSSProperties = {
  width: '100%',
  overflowY: 'auto'
};

export default class InfiniteScroller extends React.Component<InfiniteScrollerProps, typeof Object> {
  scrollParentRef: React.RefObject<HTMLDivElement>;
  listParentRef: React.RefObject<HTMLDivElement>;

  constructor(props: InfiniteScrollerProps) {
    super(props);
    this.scrollParentRef = React.createRef();
    this.listParentRef = React.createRef();
  }

  componentDidMount() {
    const node = this.props.parentRef?.current || this.getScrollRef();
    if (node) {
      node.addEventListener('scroll', this.handleScroll, {
        passive: true,
        capture: false
      });
    }
    if (this.isInitialRender(this.props) && this.props.reversed) {
      this.scrollToPosition(this.getTotalHeightOfList());
    }
  }

  componentDidUpdate(prevProps: InfiniteScrollerProps) {
    if (this.props.currentPage !== prevProps.currentPage || this.props.indexToRender !== prevProps.indexToRender) {
      if (this.isInitialRender(prevProps) && this.props.reversed) {
        this.scrollToPosition(this.getTotalHeightOfList());
      } else {
        this.reversedRetainScrollPosition();
      }
    }

    if (this.props.scrollToTop && this.props.resetScrollToTop) {
      this.scrollToPosition(0);
      this.props.resetScrollToTop();
    }
  }

  componentWillUnmount() {
    const node = this.getScrollRef();
    if (node) {
      node.removeEventListener('scroll', this.handleScroll);
    }
  }

  render() {
    return (
      <div
        className="scroll-parent"
        style={{...ScrollParentStyle, ...{...this.props.style}}}
        ref={this.scrollParentRef}
      >
        <div style={{ textAlign: 'center' }}>
          {this.props.isLoading && (
            <Box>
              <Loader active inline="centered" size="medium" />
            </Box>
          )}
        </div>
        <div className="list-parent" ref={this.listParentRef}>
          {this.props.children}
        </div>
      </div>
    );
  }

  handleScroll = throttle(() => {
    const scrollRef = this.props.parentRef?.current || this.getScrollRef();
    const listRef = this.getListParentRef();
    if (!scrollRef || !listRef) return;

    if (this.calculateOffset(scrollRef, listRef) <= THRESHOLD && !this.props.isLoading) {
      this.props.loadMore();
    }
  }, THROTTLE_WAIT);

  scrollToPosition = (position: number) => {
    const node = this.getScrollRef();
    if (!node) throw ERROR_NO_SCROLL_REF;
    node.scrollTop = position;
  };

  calculateScrollPosition = () => {
    const { indexToRender } = this.props;
    if (!indexToRender) return 0;
    const listParentRef = this.getListParentRef();
    const scrollParentRef = this.getScrollRef();

    if (!listParentRef) throw ERROR_NO_LIST_REF;
    if (!scrollParentRef) throw ERROR_NO_SCROLL_REF;
    if (indexToRender <= FIRST_ELEMENT_INDEX) return 0;

    /** HTMLCollection is not an array, hence no reduce.
     * It's not worth the additional overhead to convert
     * to an array just for the sake of making the logic cleaner
     */
    const { children } = listParentRef;

    let accumulator = 0;
    for (let i = 0; i < indexToRender; i++) {
      accumulator += children[i].clientHeight;
    }
    return accumulator;
  };

  isInitialRender = (prevProps: InfiniteScrollerProps) => (
    prevProps.currentPage === 0 &&
      this.props.currentPage === 0
  );
  /**
   * Is the current scroll bar between these two positions?
   *
   * @param startPosition: upper bound of visible range
   * @param stopPosition: lower bound of visible range
   */
  calculateOffset = (parent: HTMLDivElement, child: HTMLDivElement): number => {
    if (this.props.reversed) {
      return parent.scrollTop;
    } else {
      return child.clientHeight - parent.scrollTop - parent.clientHeight;
    }
  };

  getTotalHeightOfList = () => {
    const node = this.getScrollRef();
    if (!node) throw ERROR_NO_SCROLL_REF;
    return Math.round(node.scrollHeight - node.offsetHeight);
  };

  reversedRetainScrollPosition = () => {
    if (
      this.props.reversed &&
      !this.props.isLoading &&
      this.props.currentPage > 0
    ) {
      return this.scrollToPosition(this.calculateScrollPosition());
    }
  };

  getScrollRef = () => this.scrollParentRef.current;
  getListParentRef = () => this.listParentRef.current;
}
