import LoginForm from '../../components/auth/LoginForm';
import { connect } from 'react-redux';

import { RootState } from '../../redux/reducers';
import { authActions } from '../../redux/actions';
import {
  selectAuthEmail,
  selectAuthPassword
} from '../../redux/selectors/auth';

const mapStateToProps = (state: RootState) => ({
  email: selectAuthEmail(state),
  isUnread: selectAuthPassword(state),
  title: 'Login'
});

const mapDispatchToProps = {
  onSubmit: authActions.login,
  onChangeEmail: authActions.setEmail,
  onChangePassword: authActions.setPassword
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);
