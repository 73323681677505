import { ISplitState } from '@splitsoftware/splitio-redux/lib/types';

export { AdminRecord } from './admin';
export { PERSONALITY_TAGS, UserRecord } from './user';
export { TeamRecord } from './team';
export { PainLogRecord } from './pain-log';
export { ScreenerRecord } from './screener';
export { SurgeryLogRecord } from './surgery-log';
export { WeeklyMetricRecord } from './weekly-metric';
export { WorkoutRecord } from './workout';
export { GoalRecord } from './goals';
export { MessageRecord } from './messages';
export { MessageV2Record } from './messages.v2';
export { ConversationRecord } from './conversations';
export { TaskRecord } from './tasks';
import { combineReducers } from 'redux';
import { RouterState, connectRouter } from 'connected-react-router';
import { History } from 'history';

import { reducer as auth, State as AuthState } from './auth';
import { reducer as painLog, State as PainLogState } from './pain-log';
import { reducer as screener, State as ScreenerState } from './screener';
import { reducer as tasks, State as TasksState } from './tasks';
import { reducer as admin, State as AdminState } from './admin';
import { reducer as user, State as UserState } from './user';
import { reducer as ui, State as UiState } from './ui-state';
import {
  reducer as weeklyMetric,
  State as WeeklyMetricState
} from './weekly-metric';
import { reducer as workout, State as WorkoutState } from './workout';
import { reducer as teamPage, State as TeamPageState } from './team-page';
import { reducer as teams, State as TeamState } from './team';
import { reducer as surgeryLog, State as SurgeryLogState } from './surgery-log';
import { reducer as goals, State as GoalState } from './goals';
import { reducer as messages, State as MessagesState } from './messages';
import {
  reducer as goalsEditor,
  State as GoalsEditorState
} from './goals-editor';
import {
  reducer as conversations,
  State as ConversationState
} from './conversations';
import { reducer as pubsub, State as PubsubState } from './pubsub';
import { reducer as entities, State as EntitiesState } from './entities';
import { splitReducer } from '@splitsoftware/splitio-redux';
import { debugReducer, mergeReducers } from './debug';
import { reducer as messagesV2, State as MessagesV2State } from './messages.v2';

export interface RootState {
  ui: UiState;
  admin: AdminState;
  auth: AuthState;
  painLog: PainLogState;
  surgeryLog: SurgeryLogState;
  screener: ScreenerState;
  tasks: TasksState;
  user: UserState;
  weeklyMetric: WeeklyMetricState;
  workout: WorkoutState;
  teamPage: TeamPageState;
  teams: TeamState;
  goals: GoalState;
  goalsEditor: GoalsEditorState;
  conversations: ConversationState;
  messages: MessagesState;
  messagesV2: MessagesV2State;
  pubsub: PubsubState;
  router: RouterState;
  entities: EntitiesState;
  splitReducer: ISplitState;
}
export const rootReducer = (history: History) =>
  combineReducers<RootState>({
    ui,
    admin,
    auth,
    painLog,
    screener,
    surgeryLog,
    tasks,
    teams,
    user,
    weeklyMetric,
    workout,
    teamPage,
    goals,
    goalsEditor,
    conversations,
    messages,
    messagesV2,
    pubsub,
    entities,
    splitReducer: process.env.NODE_ENV === 'test' ? mergeReducers(splitReducer,debugReducer) : splitReducer,
    router: connectRouter(history)
  });
