import { useQuery } from '@apollo/client';
import * as React from 'react';
import { GET_USER_ENGAGEMENT_STREAK } from '../../../graphql/queries';
import EngagementStreakV2 from '../../../components/phx/teams/search/EngagementStreakV2';
import EngagementStreakV1 from '../../../components/phx/teams/search/EngagementStreakV1';
import { User } from '../../../graphql/models';

interface EngagementStreakContainerProps {
  id: string;
  displayV2Components: boolean | undefined;
}

interface EngagementStreakResponse {
  user?: User | null;
}

const EngagementStreakContainer: React.FC<EngagementStreakContainerProps> = ({
  id,
  displayV2Components
}) => {
  const { data } = useQuery<EngagementStreakResponse, { id: string }>(
    GET_USER_ENGAGEMENT_STREAK,
    { variables: { id } }
  );


  if (data && data.user) {
    return !displayV2Components ?
      <EngagementStreakV1 engagementStreak={data.user.engagementStreak} /> :
      <EngagementStreakV2 engagementStreak={data.user.engagementStreak} />;
  }
  return null;
};

export default EngagementStreakContainer;
