import * as React from 'react';
import { isEqual } from 'lodash';

import MessageListItemV2 from './MessageListItemV2';
import InfiniteScroller from '../../ui/InfiniteScroller';
import { MessageV2Record, UserRecord } from '../../../redux/reducers';
import { messageV2Actions } from '../../../redux/actions';

export interface MessageListV2Props {
  deleteMessage: typeof messageV2Actions.deleteMessage;
  fetchNextPage(): void;
  indexToRender: number;
  isLoading: boolean;
  markMessageAsRead(): void;
  messages: MessageV2Record[];
  page: number;
  totalPages?: number;
  updateMessage: typeof messageV2Actions.updateMessage;
  user: UserRecord;
}
const MessageListV2: React.FC<MessageListV2Props> = (props) => (
  <InfiniteScroller
    reversed
    currentPage={props.page}
    indexToRender={props.indexToRender}
    loadMore={props.fetchNextPage}
    isLoading={props.isLoading}
    totalPages={props.totalPages}
    style={{ paddingLeft: '16px' }}
  >
    {props.messages.map((message) => (
      <MessageListItemV2
        key={message.uuid}
        user={props.user}
        message={message}
        updateMessage={props.updateMessage}
        deleteScheduledMessage={props.deleteMessage}
        markMessageAsRead={props.markMessageAsRead}
      />
    ))}
  </InfiniteScroller>
);

export default React.memo(MessageListV2, isEqual);
