import * as React from 'react';
import { Location } from 'history';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectAdmin, selectUser } from '../../redux/selectors';
import UserNavBar from '../../components/phx/nav/UserNavBar';
import { RootState } from '../../redux/reducers';
import UserProfileBannerContainer from './phx/UserProfileBannerContainer';
import CarePlansContainer from './CarePlansContainer';
import NotFoundPage from '../../404';
import { ApiClientNames } from '../apollo/ApolloContainer';
import { GET_ADMIN } from '../../graphql/queries';
import { Admin } from '../../api-client/interfaces';
import { isPtRole } from '../../utils/admin/roles';
import { useUserId } from '../../utils/hooks/useUserId';


export interface Props {
  location?: Location;
}

const StyledContainer = styled.div`
  width: 95%;
  margin: auto;
`;

export const UserCarePlansContainer: React.FC<Props> = ({ location }) => {
  const userId = useUserId();
  const adminId = useSelector((state: RootState) => selectAdmin(state).id);
  const user = useSelector((state: RootState) => selectUser(state, {userId}));
  const { uuid } = user || {};
  const adminRes = useQuery<{ admin: Admin }, { id: number }>(
    gql`${GET_ADMIN}`,
    {
      variables: { id: adminId },
      context: { clientName: ApiClientNames.BFF }
    }
  );

  return adminRes?.data?.admin?.role && !isPtRole(adminRes.data.admin.role) ? (
    <NotFoundPage />
  ) : (
    <>
      <UserProfileBannerContainer id={userId} />
      <StyledContainer>
        <UserNavBar location={location} id={userId} />
        {
          adminRes.data?.admin.uuid &&
          uuid &&
          <CarePlansContainer adminUuid={adminRes.data.admin.uuid} userUuid={uuid} />
        }
      </StyledContainer>
    </>
  );
};

export default UserCarePlansContainer;
