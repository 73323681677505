import { UserMessagePreview } from '../../api-client';
import { RootState } from '../reducers';

/** Selects all users from state */
export function selectUsers(state: RootState) {
  return state.user.toArray();
}

/** Selects users by ids */
export function selectUsersByIds(
  state: RootState,
  props: { userIds?: number[] }
) {
  return props.userIds && props.userIds.map(id => state.user.get(id));
}

/**
 * Attempt to look up the given user from the store.
 *
 * If the user does not exist an empty entity will instead be returned: all properties will be left undefined except for
 * `id`.
 */
export function selectUser(state: RootState, props: { userId: number }) {
  return state.user.get(props.userId);
}

export function selectUserList(state: RootState, props: { userIds: number[] }) {
  return props.userIds.map(userId => selectUser(state, { userId }));
}

export const filterUserIdsByTeamId = (state: RootState, props: { userIds: number[]; teamId: number }) =>
  props.userIds.filter(userId => selectUser(state, { userId }).teamId === props.teamId);

export const getUserMessagePreview = (state: RootState, props: { userId: number }): UserMessagePreview | null => {
  const user = selectUser(state, { userId: props.userId });
  if (!user) return null;
  return user.messagePreview || null;
};

export const doesUserHaveUnreadMessages = (state: RootState, { userId }: { userId: number }): boolean => {
  const user = selectUser(state, { userId } );
  if (!user) return false;
  return user.messaging.hasUnread || false;
};
