import {
  OrderedDoTaskKindTitles,
  TasksResponse,
  TaskStatuses,
  TodoResponse,
  MassMessageTemplate,
  TemplateTodoMap,
  EnrollmentStatusResponse
} from '../../api-client';
import { ListTaskParams, MassMessageTemplateResponse } from '../../api-client/endpoints';
import {
  COMPLETE_TASK,
  REJECT_TASK,
  UPDATE_TASK_ORDER,
  UPDATE_TASK_KIND_RESULTS,
  REMOVE_TEMPLATE_USER_IDS,
  SHOW_NOTIFICATION,
  ENTITIES_FETCHED
} from '../constants';
import { ThunkAction, PayloadAction } from './interfaces';
import {
  getApiClient,
  groupTemplateUserIds,
  selectSendMassMessageUsers,
  selectTemplateUserIds,
  selectAdmin
} from '../selectors';
import { UpdateTaskKindResultsAction } from './ui-state';
import { GET_TASKS, GET_MASS_MESSAGING_TEMPLATES, GET_BULK_USERS } from '../../graphql/queries';
import { SEND_MASS_MESSAGES } from '../../graphql/mutations';
import { mixpanelTrack } from '../../mixpanel/mixpanel';
import { store } from '../../store';
import { pick, merge, map } from 'lodash';

// action payload
interface BaseTaskAction {
  status: TaskStatuses;
  message?: string;
  id?: number;
}

interface CompleteTaskInterface extends BaseTaskAction {
  isRejection?: boolean;
}

interface TaskListOrderInterface {
  status?: TaskStatuses;
  orderBy: number[];
}

// action types
export interface CompleteTaskAction extends PayloadAction<CompleteTaskInterface> {
  type: typeof COMPLETE_TASK;
}

export interface RejectTaskAction extends PayloadAction<CompleteTaskInterface> {
  type: typeof REJECT_TASK;
}

export interface UpdateTaskListOrderAction extends PayloadAction<TaskListOrderInterface> {
  payload: TaskListOrderInterface;
  type: typeof UPDATE_TASK_ORDER;
}

export interface RemoveTemplateUserIdsAction extends PayloadAction<{ template: MassMessageTemplate }> {
  payload: { template: MassMessageTemplate };
  type: typeof REMOVE_TEMPLATE_USER_IDS;
}

export type TaskAction =
  | CompleteTaskAction
  | RejectTaskAction
  | UpdateTaskListOrderAction
  | RemoveTemplateUserIdsAction;

const TASKS_PER_PAGE = 1000;
export const RESOLVED_TASKS_PER_PAGE = 30;

// getTaskUsers calls the CTT-BFF GQL endpoint, then uses the UUIDs
//   from that call to make another call to get enrollmentStatus for
//   those users.
export const getTaskUsers = (kind: string): ThunkAction<Promise<TodoResponse>> => (dispatch, getState) => {
  const state = getState();
  const api = getApiClient(state);
  const networkRequestPromises = [];
  const getTasks = api.tasks
    .users(
      {
        query: GET_TASKS,
        variables: { kind }
      },
      { kind }
    )
    .catch(e => {
      store.dispatch({
        payload: {
          message: `Cannot fetch users by task kind: ${OrderedDoTaskKindTitles[kind]}`,
          httpStatus: e.status,
          type: 'error'
        },
        type: SHOW_NOTIFICATION
      });
      return e;
    });
  // push above promise into Promise.all below
  networkRequestPromises.push(getTasks);

  // second query using the UUIDs from the first call to get enrollment statuses
  const getEnrollmentStatuses: Promise<EnrollmentStatusResponse | void> = getTasks.then(res => {
    const uuids = map(res.entities.users, value => value.uuid);
    if (!uuids.length) {
      /**
       * Graphql will throw an error if we pass an empty array to the getUsersEnrollmentStatus
       * query
       */
      return new Promise(resolve => resolve());
    }
    return api.users.getUsersEnrollmentStatus(
      {
        query: GET_BULK_USERS,
        variables: { uuids }
      },
      { uuids }
    );
  });
  // push above promise into Promise.all below
  networkRequestPromises.push(getEnrollmentStatuses);

  // use data from first and second call to update entities which then gets fed
  //   to redux
  return Promise.all(networkRequestPromises)
    .then(responses => {
      const [tasksResponse, enrollmentStatusResponse] = responses;
      let entitiesFetchedPayload = tasksResponse;

      // only merge data if we ran the enrollment status network request promise
      if (enrollmentStatusResponse) {
        const enrollmentStatusUsers = { ...enrollmentStatusResponse.entities.users };
        for (const userId in enrollmentStatusUsers) {
          if (enrollmentStatusUsers[userId].hasOwnProperty(userId)) {
            enrollmentStatusUsers[userId] = pick(enrollmentStatusUsers[userId], ['id', 'enrollmentStatus']);
          }
        }
        // merge enrollmentStatus data into users object
        const mergedUserData = merge({}, enrollmentStatusUsers, tasksResponse.entities.users);
        entitiesFetchedPayload = { ...tasksResponse, entities: { ...tasksResponse.entities, users: mergedUserData } };
      }

      dispatch({ payload: entitiesFetchedPayload, type: ENTITIES_FETCHED });
      dispatch<UpdateTaskKindResultsAction>({
        type: UPDATE_TASK_KIND_RESULTS,
        payload: {
          kind,
          teamIds: tasksResponse.result.teams,
          userIds: tasksResponse.result.users,
          conversationIds: tasksResponse.entities.conversations
            ? Object.keys(tasksResponse.entities.conversations)
            : []
        }
      });
      return tasksResponse;
    })
    .catch(e => {
      store.dispatch({
        payload: {
          message: `Cannot fetch users by task kind: ${OrderedDoTaskKindTitles[kind]}`,
          httpStatus: e.status,
          type: 'error'
        },
        type: SHOW_NOTIFICATION
      });
      return e;
    });
};

export const getMassMessageTemplates = (): ThunkAction<Promise<MassMessageTemplateResponse>> => (_, getState) => {
  const api = getApiClient(getState());
  return api.tasks.getMassMessageTemplates({ query: GET_MASS_MESSAGING_TEMPLATES }).then(res => res);
};

export const sendMassMessagesTemplate = (template: MassMessageTemplate): ThunkAction<Promise<void>> => (
  dispatch,
  getState
) => {
  const api = getApiClient(getState());

  const allMessageUsers = groupTemplateUserIds(getState(), template);
  const selectedMassMessageUsers = selectSendMassMessageUsers(getState(), template);
  const selectedUser = selectTemplateUserIds(getState(), template);
  mixpanelTrack(`EligibleMM_${template}`, {
    TaskType: TemplateTodoMap[template],
    Origin: '/mass-messaging',
    UserCount: allMessageUsers.length
  });
  mixpanelTrack(`ReceivedMM_${template}`, {
    TaskType: TemplateTodoMap[template],
    Origin: '/mass-messaging',
    UserCount: selectedUser?.length
  });
  const adminUuid = selectAdmin(getState()).uuid;

  return api.tasks
    .sendMassMessages({
      query: SEND_MASS_MESSAGES,
      variables: {
        adminUuid,
        messageTemplate: template,
        users: selectedMassMessageUsers
      }
    })
    .then(() => {
      dispatch<RemoveTemplateUserIdsAction>({ payload: { template }, type: REMOVE_TEMPLATE_USER_IDS });
    });
};

export const actionCreators = {
  getList(params: ListTaskParams): ThunkAction<Promise<TasksResponse | void>> {
    return (dispatch, getState) => {
      const api = getApiClient(getState());
      return api.tasks
        .list(
          {
            query: GET_TASKS,
            variables: {
              ...params,
              itemsPerPage: params.itemsPerPage || TASKS_PER_PAGE
            }
          },
          {}
        )
        .then(res => {
          const taskIds = res.result;
          dispatch({ payload: res, type: ENTITIES_FETCHED });

          dispatch<UpdateTaskListOrderAction>({
            payload: { orderBy: taskIds, status: params.status },
            type: UPDATE_TASK_ORDER
          });

          return res;
        })
        .catch(() => {
          console.log('No Task');
        });
    };
  },
  complete(message: string, id?: number, isRejection?: boolean): ThunkAction<Promise<void>> {
    return (dispatch, getState) => {
      const api = getApiClient(getState());
      const status = isRejection ? TaskStatuses.Rejected : TaskStatuses.Completed;
      const p = { message, status };
      if (id === undefined) {
        return Promise.reject(new Error('Cannot complete a task without passing the task id'));
      } else if (isRejection && !message) {
        return Promise.reject(new Error('Message is required for task rejections'));
      } else {
        return api.tasks.complete(p, { id }).then(() => {
          if (isRejection) {
            dispatch<RejectTaskAction>({
              payload: { ...p, isRejection, id },
              type: REJECT_TASK
            });
          } else {
            dispatch<CompleteTaskAction>({
              payload: { ...p, id },
              type: COMPLETE_TASK
            });
          }
        });
      }
    };
  },
  completeOutreachTask(message: string, id?: number, isRejection?: boolean): ThunkAction<Promise<void>> {
    return (dispatch, getState) => {
      const api = getApiClient(getState());
      const status = isRejection ? TaskStatuses.Rejected : TaskStatuses.Completed;
      const p = { message, status };

      if (id === undefined) {
        return Promise.reject(new Error('Cannot complete a outreach task without passing the task id'));
      } else if (isRejection && !message) {
        return Promise.reject(new Error('Message is required for outreach task rejections'));
      }
      return api.tasks.completeOutreach(p, { id }).then(() => {
        if (isRejection) {
          dispatch<RejectTaskAction>({
            payload: { ...p, isRejection, id },
            type: REJECT_TASK
          });
        } else {
          dispatch<CompleteTaskAction>({
            payload: { ...p, id },
            type: COMPLETE_TASK
          });
        }
      });
    };
  }
};
