import { useQuery } from '@apollo/client';
import * as React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import { GREY } from '../../../colors';
import { GET_TEAMS } from '../../../graphql/queries/Teams';
import { TeamsResponse } from '../../../graphql/models/TeamsResponse';
import TeamAccordion from '../../../components/phx/teams/TeamAccordion';

const StyledDiv = styled.div`
  color: ${GREY};
  position: absolute;
  left: 50%; 
  top: 60%;
`;

const ErrorMessage = 'Failed to load teams.';
const NoTeamsMessage = 'No teams found.';

interface Response {
  teams: TeamsResponse[];
}

interface TeamResultsContainerProps {
  onboardingView: boolean;
}

const TeamResultsContainer: React.FC<TeamResultsContainerProps> = ({ onboardingView }) => {
  const {loading, error, data } = useQuery<Response>(
    GET_TEAMS,
    {
      variables: { page: 0, itemsPerPage: 250 },
      fetchPolicy: 'network-only'
    }
  );

  if (error) return <StyledDiv>{ErrorMessage}</StyledDiv>;

  if (loading) {
    return <StyledDiv>
      <CircularProgress />
    </StyledDiv>;
  }

  if (!data || !data.teams.length) {
    return (<StyledDiv>{NoTeamsMessage}</StyledDiv>);
  }

  return <TeamAccordion onboardingView={onboardingView} teams={data.teams} />;
};

export default TeamResultsContainer;
