import * as React from 'react';
import styled from 'styled-components';
import { CircularProgress, Typography, Divider } from '@material-ui/core';
import { GET_USER_REMINDER_CONTEXT } from '../../graphql/queries/User';
import { useQuery } from '@apollo/client';
import { User } from '../../graphql/models/User';
import ReminderContext from '../../components/communications/RemindersContext';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { SUCCESS_ICON, SUCCESS_TEXT } from '../../colors';

export const ErrorMessage = 'Failed to load reminders.';
export const EmptyData = 'No data to display.';

interface ReminderConProps {
  userId: number;
}

interface Response {
  user?: User | null;
}

const StyledDiv = styled.div`
  padding-left: 20px;
  font-size: 14px;
`;

const StyledTypography = styled(Typography)`
  && {
    padding: 20px;
    font-size: 18px;
  }
`;

const StyledIconWrapper = styled.div`
  display: inherit;  
  padding: 20px 20px 0 0;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const notificationsIcon = (active: boolean | null | undefined, method: string | null | undefined) => {
  if (!active) {
    return <StyledIconWrapper data-testid='notifications-off'>
      <NotificationsOffIcon />
      <Typography>Off</Typography>
    </StyledIconWrapper>;
  }

  return <StyledIconWrapper data-testid='notifications-on'>
    <NotificationsNoneIcon style={{ color: SUCCESS_ICON }}/>
    <Typography style={{ color: SUCCESS_TEXT }}>On ({method})</Typography>
  </StyledIconWrapper>;
};


export const RemindersContainer: React.FC<ReminderConProps> = ({ userId }) => {
  const { loading, error, data } = useQuery<Response, {id: string}>(GET_USER_REMINDER_CONTEXT, {
    variables: { id: userId.toString() }
  });

  if (error) return <StyledDiv>{ErrorMessage}</StyledDiv>;

  if (loading) {
    return <StyledDiv>
      <CircularProgress />
    </StyledDiv>;
  }

  if (!data || !data.user) return <StyledDiv>{EmptyData}</StyledDiv>;

  const remindersLength = data.user?.reminders?.length || 0;
  const currentReminder = data.user?.reminders?.[remindersLength - 1];
  const remindersActive = currentReminder?.active;
  const remindersMethod = currentReminder?.deliveryMethod;

  return (
    <>
      <StyledWrapper>
        <StyledTypography>Reminders</StyledTypography>
        {notificationsIcon(remindersActive, remindersMethod)}
      </StyledWrapper>
      <ReminderContext reminders={data.user?.reminders} />
      <Divider />
    </>
  );
};
