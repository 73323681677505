import * as React from 'react';
import { Box, Grid } from '@material-ui/core';
import { EnrollmentStatus, Status, GetUserById } from '../../graphql/models/bff';
import UserPosterSummary from './UserPosterSummary';
import UserPosterHeader from './UserPosterHeader';

interface Props {
  user: GetUserById;
  enrollmentStatus?: EnrollmentStatus | null;
  changeEnrollmentStatus: (status: Status) => unknown;
}

const UserPoster: React.FC<Props> = ({ user, enrollmentStatus, changeEnrollmentStatus }) => (
  <Box style={{ padding: 20 }} data-testid={`user-poster-${user.id}`}>
    <Grid container>
      <UserPosterHeader
        user={user}
        enrollmentStatus={enrollmentStatus}
        changeEnrollmentStatus={changeEnrollmentStatus}
      />
      <UserPosterSummary user={user} />
    </Grid>
  </Box>
);

export default UserPoster;
