import { convertArrayToHash } from './helpers';
import { User } from '../graphql/models';
import * as Sentry from '@sentry/browser';

export const mergeUserData = (userData: User[], bulkUsersData: User[]) => {
  const bulkUsersHash = convertArrayToHash(bulkUsersData, 'id');

  return userData.map(user => ({
    ...bulkUsersHash[user.id],
    ...user
  }));
};

export const getUserUuids = (users: User[]) => users.reduce((uuidsArr: string[], user: User) => {
  if (user.uuid) {
    uuidsArr.push(user.uuid);
  } else {
    Sentry.captureMessage(`userId: ${user.id} missing uuid`);
  }
  return uuidsArr;
}, []);
