import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Person } from '@material-ui/icons';
import { Typography, Avatar  } from '@material-ui/core';
import {
  useQuery
} from '@apollo/client';
import { UserProfileRoutes } from '../utils/user/routes';
import { ApiClientNames } from './apollo/ApolloContainer';
import {
  GET_USER_BASIC_INFO
} from '../graphql/queries';

interface UserBasicInfoResponse {
  getUserById: {
    id: string;
    firstName: string;
    lastName: string;
    avatarUrl: string;
  };
}

const StyledAvatar = styled(Avatar)`
  && {
    height: 32px;
    width: 32px;
  }
  margin-right: 16px;
`;

const StyledContainer = styled.div`
  display: flex;
`;

const StyledUserLink = styled.div`
  padding-top: 8px;
`;

const AdminCarePlansUserDisplay: React.FC<{ userUuid: string }> = ({ userUuid }) => {
  const { data } = useQuery<UserBasicInfoResponse, {
    userUuid: string; }>(GET_USER_BASIC_INFO,
    {
      variables: { userUuid },
      context: { clientName: ApiClientNames.BFF }
    }
  );

  const user = data?.getUserById || '';

  return (
    <StyledContainer>
      { user &&
        <>
          <StyledAvatar
            alt={`${user.firstName} ${user.lastName}`}
            src={user.avatarUrl}
          >
            <Person />
          </StyledAvatar>
          <StyledUserLink>
            <Link to={`/user/${user.id}/${UserProfileRoutes.CarePlans}`}>
              <Typography variant='body2'>{`${user.firstName} ${user.lastName}`}</Typography>
            </Link>
          </StyledUserLink>
        </>
      }
    </StyledContainer>
  );
};

export default AdminCarePlansUserDisplay;
