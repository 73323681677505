import { createSelector } from 'reselect';

import ApiClient from '../../api-client/ApiClient';
// ATL-1180 refactor the apiclient as a singleton that can be used by callers. There's no reason
// to make this tied to redux.
export const getApiClient = createSelector(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {} ,
  () => new ApiClient()
);


