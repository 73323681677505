import * as React from 'react';
import { connect } from 'react-redux';
import './MassMessagingTemplate.scss';
import { RootState } from '../../redux/reducers';
import { sendMassMessagesTemplate } from '../../redux/actions/tasks';
import { selectTemplateUserIds, groupTemplateUserIds } from '../../redux/selectors/ui-state';
import { DispatchMap } from '../../redux/actions/interfaces';
import { ExpandMore, ChevronRight } from '@material-ui/icons';
import { MassMessageTemplate, TaskKind, MassMessage, TemplateInfoMap } from '../../api-client/interfaces';

enum MessageTemplateTitles {
  week4InactiveFirst = 'First Message',
  week4InactiveSecond = 'Second Message',
  week5NeverActive = 'Never Active Message',
  week5PreviouslyActive = 'Previously Active Message',
  week13PlusInactiveFor4Weeks = 'First Message'
}

interface MassMessagingTemplateProps {
  todo: TaskKind;
  massMessage: MassMessage;
  subHeading?: string;
  selectedTemplate?: MassMessageTemplate;
  setSelectedTemplate(template?: MassMessageTemplate): void;
}

type ConnectProps = DispatchMap<{
  totalTemplateUserIds?: number[];
  selectedTemplateUserIds?: number[];
  sendMassMessages: typeof sendMassMessagesTemplate;
}>;

const MassMessagingTemplate: React.FC<MassMessagingTemplateProps & ConnectProps> = ({
  totalTemplateUserIds,
  selectedTemplateUserIds,
  massMessage,
  subHeading,
  selectedTemplate,
  setSelectedTemplate,
  sendMassMessages
}) => {
  const { body } = massMessage;
  const template = massMessage.messageType;
  const draftRef = React.useRef<HTMLDivElement | null>(null);
  const sendClicked = React.useRef(false);
  const [draftHeight, setDraftHeight] = React.useState<number | undefined>(undefined);
  const [completed, setCompleted] = React.useState(false);

  const completeMassMessageTemplate = (t: MassMessageTemplate) => {
    setDraftHeight(draftRef?.current?.offsetHeight);
    sendMassMessages(t);
    setSelectedTemplate(undefined);
    sendClicked.current = true;
  };

  const selectTemplate = (t: MassMessageTemplate) => {
    setSelectedTemplate(t);
  };

  React.useEffect(() => {
    if (sendClicked.current) {
      setCompleted(true);
    }
  }, [sendClicked.current]);

  const subHeadingDiv = subHeading && subHeading.length && (
    <div className='mass-messaging__todo__draft__desc__qualifying-heading' data-testid='sub-heading'>
      {subHeading}
    </div>);

  return totalTemplateUserIds && totalTemplateUserIds.length ? (<div
    ref={draftRef}
    style={completed ? { height: 0 } : { height: draftHeight || 'auto' }}
    className={`mass-messaging__todo__draft
      ${template === selectedTemplate ?
      'mass-messaging__todo__draft--selected' : ''}
      ${completed ? 'mass-messaging__todo__draft--completed' : ''}
      `}>
    <button className={`mass-messaging__todo__draft__button
        ${selectedTemplate === template
      ? 'mass-messaging__todo__draft__button--selected' : ''}`}
    onClick={() => selectTemplate(template)}>
      <div className='mass-messaging__todo__draft__desc'>
        <div className='mass-messaging__todo__draft__desc__name'>
          {MessageTemplateTitles[template]}
        </div>
        <div className='mass-messaging__todo__draft__desc mass-messaging__todo__draft__desc__count'>
          {selectedTemplateUserIds && selectedTemplateUserIds.length}
        </div>
        {subHeadingDiv}
      </div>
      {template === selectedTemplate ?
        <ChevronRight className='mass-messaging__todo__draft__desc' /> :
        <ExpandMore className='mass-messaging__todo__draft__desc' />}
    </button>
    <div className={`mass-messaging__todo__draft__panel
        ${selectedTemplate === template || completed
      ? 'mass-messaging__todo__draft__panel--selected' : ''}`}>
      <div className='mass-messaging__todo__draft__info'>{TemplateInfoMap[template]}</div>
      <div className='mass-messaging__todo__draft__template'>{body}</div>
      <div className='mass-messaging__todo__draft__send__container'>
        <button
          disabled={selectedTemplateUserIds && selectedTemplateUserIds.length ? false : true}
          onClick={() => completeMassMessageTemplate(template)}
          className={`mass-messaging__button
          ${selectedTemplateUserIds && selectedTemplateUserIds.length ? '' : 'mass-messaging__button--disabled'}`}>
          SEND
        </button>
      </div>
    </div>
  </div>) : null;
};

const mapStateToProps = (state: RootState, props: MassMessagingTemplateProps) => ({
  selectedTemplateUserIds: selectTemplateUserIds(state, props.massMessage.messageType),
  totalTemplateUserIds: groupTemplateUserIds(state, props.massMessage.messageType)
});

const mapDispatchToProps = ({
  sendMassMessages: sendMassMessagesTemplate
});

export default connect(mapStateToProps, mapDispatchToProps)(MassMessagingTemplate);
