import * as React from 'react';
import { ThemeContext, Box, TextInput } from 'grommet';
import { RefreshRounded } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { RootState, UserRecord } from '../../redux/reducers';
import { FormSearch, FormClose } from 'grommet-icons';
import ConversationListItem from './ConversationListItem';
import {
  ComputedSortedConversationItem,
  makeSelectSortedUsersByConversations,
  selectFeatureFlags,
  selectOnboardedUsers,
  selectInCtpIframe
} from '../../redux/selectors';
import ConversationTabContainer from '../../containers/communications/ConversationTabContainer';
import ConversationContainerV2 from '../../containers/communications/v2/ConversationContainerV2';
import { communicationsServiceMessaging } from '../../features';
import { User } from 'src/graphql/models';
import UserProfileCommunicationContainer from '../../containers/communications/UserProfileCommunicationContainer';
import { connect } from 'react-redux';
import { DispatchMap } from '../../redux/actions/interfaces';
import { isEqual } from 'lodash';
import './TeamUserSearch.scss';
import EmptyCommunicationState from './EmptyCommunicationState';
import { useHistory, useParams } from 'react-router-dom';
import { MESSAGING_PATH } from '../../routes';

const TeamUserSearchStyles = {
  global: {
    control: {
      border: {
        radius: '8px',
        color: '#CFD8DC',
        width: '2px'
      }
    },
    drop: {
      background: 'white',
      shadowSize: 'medium',
      extend: `
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
          overflow: hidden;
          border-radius: 4px;
          margin-top: 5px;
          border: 1px solid rgb(224, 224, 224);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
        `
    },
    elevation: {
      dark: {
        medium: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'
      },
      light: {
        medium: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'
      }
    },
    input: {
      weight: 400
    },
    font: {
      size: '14px',
      font: 'Lato'
    }
  },
  textInput: {
    extend: () => `
      padding-left: 35px;
      height: 100%;
      font-size: 14px;
      &:focus {
        box-shadow: none;
        border: 2px solid rgb(144, 164, 174);
      }`,
    container: {
      extend: () => `
        padding: 10px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        border-radius: 10px;
        &:focus {
        box-shadow: none;
        border-color: initial;
      }
      `
    },
    suggestions: {
      extend: () => `
        display: flex;
        flex-flow: column;
        justify-content: center;
        button {
          padding: 5px 10px 5px 10px;
          &:hover {
            color: rgb(0, 130, 121);
          }
          &:focus {
           box-shadow: none;
          }
          &:first-child {
            padding-top: 5px
        }
      `
    }
  }
};

interface TeamUserSearchProps {
  users: User[];
  teamId: string;
  teamName: string;
  currentWeekNumber: number;
  refreshFn(): void;
  isUserViewingCtm?: boolean;
}

type ConnectProps = DispatchMap<{
  sortedConversationItems: ComputedSortedConversationItem[];
  communicationsServiceMessagingEnabled?: boolean;
  onboardedUsers: number[];
  inCtpIframe: boolean;
}>;

export type Props = TeamUserSearchProps & ConnectProps;

export const sortByFirstName = (userA: UserRecord, userB: UserRecord) => {
  if (!userA.firstName && !userB.firstName) return 0;
  if (!userA.firstName) return 1;
  if (!userB.firstName) return -1;

  const userAFirstNameLetter = userA.firstName[0].toLowerCase();
  const userBFirstNameLetter = userB.firstName[0].toLowerCase();

  if (userAFirstNameLetter < userBFirstNameLetter) return -1;
  if (userAFirstNameLetter > userBFirstNameLetter) return 1;
  return 0;
};

export const removeNonAlphaNumericChars = (searchStrLowerCase: string) =>
  searchStrLowerCase.replace(/[^0-9a-z: ]/g, '\\$1');

export const isUserSearched = (user: UserRecord, searchText: string) => {
  const userIdAndName = `${user.id}${user.firstName || ''}${user.lastName || ''}`;
  const userIdAndNameProcessed = userIdAndName.toLowerCase().trim();

  const searchStringProcessed = removeNonAlphaNumericChars(searchText.toLowerCase().trim());

  const searchTextRegExp = new RegExp(searchStringProcessed, 'i');
  return userIdAndNameProcessed.match(searchTextRegExp);
};

function isUserIdNumber(userId?: number | null): userId is number {
  return typeof userId === 'number';
}

const isUserOnboarded = (onboardedUsers: number[], userId: number) =>
  onboardedUsers.some((id: number) => id === userId);

const TeamUserSearch: React.FC<Props> = ({
  communicationsServiceMessagingEnabled,
  currentWeekNumber,
  onboardedUsers,
  sortedConversationItems,
  teamId,
  refreshFn,
  teamName,
  inCtpIframe,
  isUserViewingCtm
}) => {
  const [searchedUserName, setSearchedUserName] = React.useState<string>('');
  const [selectedUserId, setSelectedUserId] = React.useState<number>();
  const [selectedConversationId, setSelectedConversationId] = React.useState<string>();
  const params = useParams<{ userId: string }>();
  const history = useHistory();

  let isSelectedUserInList = inCtpIframe;

  React.useEffect(() => {
    if (params.userId && sortedConversationItems?.length) {
      const parsedUserId = parseInt(params.userId, 10);
      setSelectedUserId(parsedUserId);

      const conversationItem = sortedConversationItems.find(convo => convo.user.id === parsedUserId);
      setSelectedConversationId(conversationItem?.conversation?.id);
    } else if (
      sortedConversationItems.length &&
        sortedConversationItems[0].user &&
        sortedConversationItems[0].conversation
    ) {
      const user = sortedConversationItems[0].user;
      setSelectedUserId(user.id);
      setSelectedConversationId(sortedConversationItems[0].conversation.id);

      /** Set the default loaded user id in the url if teamId exists  */
      if (teamId) {
        history.push(`${MESSAGING_PATH}/teams/${teamId}/users/${user.id}`);
      }
    } else {
      setSelectedUserId(undefined);
    }
  }, [teamId, params.userId, sortedConversationItems]);


  const createUserOptions = () => {
    if (!sortedConversationItems.length) {
      return <></>;
    }
    if (searchedUserName) {
      return sortedConversationItems
        .filter(user => isUserSearched(user.user, searchedUserName))
        .map((user) => {
          if (user.user.id === selectedUserId) {
            isSelectedUserInList = true;
          }
          return (
            <div
              data-testid="listItemWrapper"
              className={`${isUserOnboarded(onboardedUsers, user.user.id) ? 'search-user-list-item__dimmed' : ''}`}
            >
              <ConversationListItem
                key={btoa(unescape(encodeURIComponent(JSON.stringify(user.user))))}
                conversationItem={user}
                selectedUserId={selectedUserId}
                setSelectedUserId={setSelectedUserId}
                setSelectedConversationId={setSelectedConversationId}
              />
            </div>
          );
        });
    } else {
      return sortedConversationItems
        .map((user) => {
          if (user.user.id === selectedUserId) {
            isSelectedUserInList = true;
          }
          return  (
            <div
              data-testid="listItemWrapper"
              className={`${isUserOnboarded(onboardedUsers, user.user.id) ? 'search-user-list-item__dimmed' : ''}`}
            >
              <ConversationListItem
                key={btoa(unescape(encodeURIComponent(JSON.stringify(user.user))))}
                conversationItem={user}
                selectedUserId={selectedUserId}
                setSelectedUserId={setSelectedUserId}
                setSelectedConversationId={setSelectedConversationId}
                teamId={teamId}
              />
            </div>
          );
        });
    }
  };
  const resetUserOptions = () => {
    setSearchedUserName('');
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = event.target;
    setSearchedUserName(newValue);
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value: newValue } = event.target as HTMLInputElement;
    setSearchedUserName(newValue);
  };

  const conditionallyRenderConversationContainer = () => {
    const validSelectedUserId = selectedUserId && isSelectedUserInList;
    if (!validSelectedUserId) {
      return null;
    } else if (isUserIdNumber(selectedUserId) && isUserOnboarded(onboardedUsers, selectedUserId)) {
      return <EmptyCommunicationState />;
    } else if (communicationsServiceMessagingEnabled && isUserIdNumber(selectedUserId)) {
      return  <ConversationContainerV2 userId={selectedUserId} />;
    } else if (selectedConversationId && isSelectedUserInList && isUserIdNumber(selectedUserId)) {
      return (
        <ConversationTabContainer
          userId={selectedUserId}
          activeConversationId={selectedConversationId}
          setActiveConversationId={setSelectedConversationId}
        />
      );
    }

    return null;
  };

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', height: '100vh' }}>
      { (!inCtpIframe || isUserViewingCtm) && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRight: 'solid 1px #CFD8DC',
            width: '325px'
          }}
        >
          <div className='team-user-search-header'>
            <div className='team-user-search-heading__text'>
              <span style={{ fontSize: '15px', fontWeight: 'bold', paddingRight: '8px' }}>{teamName}</span>
              <span style={{ fontSize: '13px', color: 'rgb(118, 139, 148)' }}>{`Wk ${currentWeekNumber}`}</span>
            </div>
            {!!onboardedUsers.length && (
              <IconButton
                className='team-user-search-heading__refresh-icon'
                aria-controls='refresh'
                onClick={refreshFn}
              >
                <RefreshRounded />
              </IconButton>
            )}
          </div>
          <ThemeContext.Extend value={TeamUserSearchStyles}>
            <div className='team-user-search-bar'>
              <div className='team-user-search-bar__contents'>
                <Box
                  style={{
                    position: 'absolute',
                    left: '10%',
                    marginLeft: '-12px',
                    top: '50%',
                    marginTop: '-12px',
                    zIndex: 3
                  }}
                >
                  <FormSearch />
                </Box>
                <TextInput
                  className={'search'}
                  placeholder='Search users'
                  type='search'
                  value={searchedUserName}
                  onChange={onChange}
                  onKeyUp={onKeyUp}
                />
                {searchedUserName && (
                  <Box
                    style={{
                      position: 'absolute',
                      right: '8%',
                      marginLeft: '-12px',
                      top: '50%',
                      marginTop: '-12px',
                      cursor: 'pointer'
                    }}
                  >
                    <FormClose onClick={resetUserOptions} />
                  </Box>
                )}
              </div>
            </div>
            <div className='user-options-container'>
              <div className='team-user-search-bar__shadow-cover' />
              {createUserOptions()}
            </div>
          </ThemeContext.Extend>
        </div>
      )}
      {conditionallyRenderConversationContainer()}
      {selectedConversationId &&
        selectedUserId &&
        isSelectedUserInList &&
        !isUserOnboarded(
          onboardedUsers,
          selectedUserId
        ) && (<UserProfileCommunicationContainer userId={selectedUserId} key={selectedUserId} />)}
    </div>
  );
};

const mapStateToProps = (state: RootState, props: TeamUserSearchProps) => {
  const userIds = props.users.map(user => parseInt(user.id, 10));
  const selectSortedUsersByConversations = makeSelectSortedUsersByConversations();
  return {
    sortedConversationItems: selectSortedUsersByConversations(state, {
      userIds
    }).filter(conversationItems => !!conversationItems.user),
    communicationsServiceMessagingEnabled: selectFeatureFlags(state).get(communicationsServiceMessaging),
    onboardedUsers: selectOnboardedUsers(state),
    inCtpIframe: selectInCtpIframe(state)
  };
};

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(TeamUserSearch, isEqual));
