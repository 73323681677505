import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { DispatchThunk } from '../../redux/actions';
import UserNoteContainer from '../user/UserNoteContainer';
import { selectAdmin } from '../../redux/selectors/admin';
import './UserNoteManager.scss';
import {
  createNote,
  deleteNote,
  getNotes,
  exportNote
} from '../../redux/actions/notes';
import { actionCreators as adminActions} from '../../redux/actions/admin';
import { mixpanelTrack } from '../../mixpanel/mixpanel';
import { Admin, Note, NoteStatus } from '../../api-client/interfaces';
import { RequestStatus } from '../../api-client/endpoints';
import InfiniteScroller from '../../components/ui/InfiniteScroller';
import * as EmptyStateFolder from '../../images/emptyStateFolder.png';

const NOTES_PER_PAGE = 10;


export interface UserNoteManagerProps {
  userId: number;
  userUuid?: string | null;
  view?: string;
}

interface NoteDetail {
  note: Note;
  isNewNote: boolean;
}

const Image = styled.img`
    margin: 20px;
    max-width: 232px;
`;

const StyledTypography = styled(Typography)`
    color: rgba(0, 0, 0, 0.54);
`;

export const EmptyNotesState: React.FC = () =>
  <>
    <Image
      src={EmptyStateFolder}
      alt="empty state folder"
    />
    <StyledTypography
      align="center"
      variant="body2"
      data-testid="emptyStateText"
    >
        No notes added
    </StyledTypography>
  </>;

const UserNoteManager = ({ userId, userUuid, view }: UserNoteManagerProps) => {
  const [noteDetails, setNoteDetails] = React.useState<NoteDetail[]>([]);
  const [requestedNoteDetailIds, setRequestedNoteDetailIds] = React.useState<Set<number>>(new Set());
  const [noteContainersElements, setNoteContainersElements] = React.useState<React.ReactNode[]>([]);
  const [createNoteRequestStatus, setCreateNoteRequestStatus] = React.useState<RequestStatus | null>(null);
  const [getNotesRequestStatus, setGetNotesRequestStatus] = React.useState<RequestStatus>(RequestStatus.PENDING);
  const [currentAdmin, setCurrentAdmin] = React.useState<Admin>(useSelector(selectAdmin));
  const [currentPage, setCurrentPage] = React.useState(0);
  const [maxPageReached, setMaxPageReached] = React.useState(false);
  const [scrollToTop, setScrollToTop] = React.useState(false);
  const dispatchThunk: DispatchThunk = useDispatch();

  const isOverview = view === 'userOverview';
  const userNoteManagerContainerClasses = `usernote-manager${isOverview ? '--overview' : ''} notes`;

  const disableAddButton = createNoteRequestStatus === RequestStatus.PENDING ||
    createNoteRequestStatus === RequestStatus.ERROR;

  const addNote = () => {
    if (disableAddButton) return;

    setCreateNoteRequestStatus(RequestStatus.PENDING);

    dispatchThunk(createNote(userUuid)).then(res => {
      const { id, author, createdAt, pdfReady } = res;
      setCreateNoteRequestStatus(RequestStatus.SUCCESS);

      const createdNote: Note = {
        id,
        author,
        noteType: '',
        body: '',
        createdAt,
        status: NoteStatus.Draft,
        pdfReady
      };
      setNoteDetails((currentNoteDetails) => [
        { note: createdNote, isNewNote: true }, ...currentNoteDetails
      ]);
      setScrollToTop(true);
    }).catch(() => {
      setCreateNoteRequestStatus(RequestStatus.ERROR);
    });

  };

  const resetScrollToTop = React.useCallback(() => setScrollToTop(false), []);

  const handleDeleteDraftNote = React.useCallback((id: number) => () => {
    dispatchThunk(deleteNote({ id })).then(() => {
      setNoteDetails((currentNoteDetails) => {
        const index = currentNoteDetails.findIndex((noteDetail) => noteDetail.note.id === id);
        currentNoteDetails.splice(index, 1);
        return [...currentNoteDetails];
      });
    });
  }, []);

  const handleNoteExpand = React.useCallback((note: Note) => () => {
    if (currentAdmin.uuid !== note.author.uuid) {
      mixpanelTrack('User Note Expanded', {
        Origin: isOverview ? '/user' : '/messaging',
        AdminUuid: currentAdmin.uuid,
        NoteAuthor: note.author.name,
        NoteId: note.id,
        NoteLabel: note.noteType,
        NoteCreated: note.finishedAt || null
      });
    }
  }, []);

  const handleExportNote = React.useCallback((note: Note) => () => {
    mixpanelTrack('Exported_Note', {
      Origin: isOverview ? '/user' : '/messaging',
      AdminUuid: currentAdmin.uuid,
      NoteAuthor: note.author.name,
      NoteId: note.id,
      NoteLabel: note.noteType
    });

    dispatchThunk(exportNote(note.id))
      .then((presignedUrl) => {
        window.open(presignedUrl);
      });
  }, []);

  const getContent = () => noteDetails.length > 0
    ? (<div className='usernote-manager__notes-container'>{noteContainersElements}</div>)
    : (<div className='usernote-manager__empty'><EmptyNotesState /></div>);

  const fetchPagedNotes = () => {
    if (maxPageReached) return;

    const nextPage = currentPage + 1;
    setGetNotesRequestStatus(RequestStatus.PENDING);
    dispatchThunk(getNotes({ userUuid, page: nextPage, itemsPerPage: NOTES_PER_PAGE, userId })).then((res) => {
      setGetNotesRequestStatus(RequestStatus.SUCCESS);

      if (res.data.length) {
        const updatedNoteDetails = res.data
          .filter((noteRes) => !requestedNoteDetailIds.has(noteRes.id))
          .map((noteRes) => ({
            note: {
              ...noteRes,
              noteType: noteRes.noteType || '',
              body: noteRes.body || ''
            },
            isNewNote: false
          }));

        setNoteDetails((prevNoteDetails) => (prevNoteDetails.concat(updatedNoteDetails)));
        setRequestedNoteDetailIds(new Set(noteDetails.map(noteDetail => noteDetail.note.id)));
        setCurrentPage(nextPage);
      } else {
        setMaxPageReached(true);
      }
    });
  };

  React.useEffect(() => {
    dispatchThunk(adminActions.getAdmin({ id: currentAdmin.id })).then((admin) => {
      setCurrentAdmin(admin);
    });
  }, []);

  React.useEffect(() => {
    setGetNotesRequestStatus(RequestStatus.PENDING);
    dispatchThunk(getNotes({ userUuid, page: currentPage, itemsPerPage: NOTES_PER_PAGE, userId })).then((res) => {
      setGetNotesRequestStatus(RequestStatus.SUCCESS);
      const updatedNoteDetails = res.data.map((noteRes) => ({
        note: {
          ...noteRes,
          noteType: noteRes.noteType || '',
          body: noteRes.body || ''
        },
        isNewNote: false
      }));

      setNoteDetails(updatedNoteDetails);
      setRequestedNoteDetailIds(new Set(updatedNoteDetails.map(noteDetail => noteDetail.note.id)));
      setGetNotesRequestStatus(RequestStatus.SUCCESS);
    });
  }, []);

  React.useEffect(() => {
    const noteDetailsArray = noteDetails.map((noteDetail) => {
      const { isNewNote } = noteDetail;
      let { note } = noteDetail;
      note = { ...note, userUuid };
      return (
        <UserNoteContainer
          key={`user-note-container-${note.id}`}
          note={note}
          isNewNote={isNewNote}
          onDeleteDraft={handleDeleteDraftNote(note.id)}
          onExpandNote={handleNoteExpand(note)}
          onExportNote={handleExportNote(note)}
        />
      );
    });
    setNoteContainersElements(noteDetailsArray);
  }, [noteDetails]);

  return (
    <div className={userNoteManagerContainerClasses}>
      <div className='usernote-manager__header'>
        <div className={'usernote-manager__title'} >
          <Typography variant='h5' gutterBottom className={isOverview ? '' : 'hidden'}>
            Notes
          </Typography>
        </div>
        <div className='usernote-manager__add-button' onClick={addNote}>
          <div>Add Note</div>
          <AddIcon className='usernote-manager__add-button__icon'/>
        </div>
      </div>
      <InfiniteScroller
        isLoading={getNotesRequestStatus === RequestStatus.PENDING}
        loadMore={fetchPagedNotes}
        currentPage={currentPage}
        scrollToTop={scrollToTop}
        style={isOverview ? { height: '900px' } : {}}
        resetScrollToTop={resetScrollToTop}
      >
        {getContent()}
      </InfiniteScroller>
    </div>
  );
};

export default UserNoteManager;
