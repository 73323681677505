import * as React from 'react';
import { Popup, List } from 'semantic-ui-react';

interface OverflowMenuProps {
  triggeredBy?: JSX.Element;
  open?: boolean;
  triggerDone(message?: string): void;
  triggerRejection(message?: string): void;
  onOpen?(): void;
  context?: React.RefObject<HTMLDivElement>;
}

class DistributedOutreachMenu extends React.PureComponent<OverflowMenuProps> {
  onEmailDone = () => {
    this.props.triggerDone('Task completed by email.');
  };

  onMessageDone = () => {
    this.props.triggerDone('Task completed by scheduled message.');
  };

  onCallDone = () => {
    this.props.triggerDone('Task completed by phone call.');
  };

  onReject = () => {
    this.props.triggerRejection('Won\'t Do.');
  };
  render() {
    return <Popup style={{padding: '0px'}} hideOnScroll={true} context={this.props?.context}
      trigger={this.props.triggeredBy}
      on="click" open={this.props.open} onOpen={this.props.onOpen}>
      <List style={{padding: '0px'}} selection verticalAlign="middle">
        <div style={{padding: '15px 15px 10px 15px', color: 'rgba(69, 90, 100, 0.7)'}}>
          <List.Content>
            Completed by
          </List.Content>
        </div>
        <List.Item style={{color: 'rgb(38, 50, 56)', padding: '10px 15px'}}
          onClick={this.onMessageDone}>
          <List.Content >
           Scheduled Message
          </List.Content>
        </List.Item>
        <List.Item style={{color: 'rgb(38, 50, 56)', padding: '10px 15px'}}
          onClick={this.onEmailDone}>
          <List.Content>
           Email
          </List.Content>
        </List.Item>
        <List.Item style={{padding: '10px 15px',
                           paddingBottom: '15px',
                           borderRadius: '0px', color: 'rgb(38, 50, 56)'}}
        onClick={this.onCallDone}>
          <List.Content>
            Phone Call
          </List.Content>
        </List.Item>
        <List.Item style={{padding: '0px 15px'}}>
          <List.Content style={{borderBottom: '1px solid rgb(207, 216, 220)'}}>
          </List.Content>
        </List.Item>
        <List.Item style={{padding: '15px', color: 'rgb(38, 50, 56)'}}
          onClick={this.onReject}>
          <List.Content>
            Won't Do
          </List.Content>
        </List.Item>
      </List>
    </Popup>;
  }
}

export default DistributedOutreachMenu;
