import { DateTime } from 'luxon';

export const { days, hours, minutes, seconds, milliseconds } = {
  days: 7,
  hours: 24,
  minutes: 60,
  seconds: 60,
  milliseconds: 1000
};

export function calculateWeeksBetween(
  olderDate: string,
  newerDateTime = Date.now()
) {
  const yearTwoDate = new Date(olderDate);

  const diffInWeeks =
    (newerDateTime - yearTwoDate.getTime()) /
    (days * hours * minutes * seconds * milliseconds);

  return diffInWeeks <= 0 ? 0 : Math.round(diffInWeeks);
}

// Format for MMM D, YYYY or Jan 1, 2021
export const formatDate_MMM_D_YYYY = (value: string | number | Date) => {
  const date = new Date(value);
  const day = date.toLocaleString('default', { day: '2-digit', timeZone: 'UTC' });
  const month = date.toLocaleString('default', { month: 'short', timeZone: 'UTC' });
  const year = date.toLocaleString('default', { year: 'numeric', timeZone: 'UTC' });
  return `${month} ${day}, ${year}`;
};

// Format for M/D-M/D or 1/19-1/26
export const getWeekDateRange = (date: DateTime) => {
  const weekStart = date.startOf('week').toLocaleString({month: 'numeric', day: 'numeric'});
  const weekEnd = date.endOf('week').toLocaleString({month: 'numeric', day: 'numeric'});
  return weekStart + '-' + weekEnd;
};

export const toShortDate = (date: string): string =>
  DateTime.fromISO(date).toLocaleString({
    ...DateTime.DATE_SHORT,
    year: '2-digit'
  });
