import * as React from 'react';
import styled from 'styled-components';

const Message = styled.div`
  color: grey;
  position: absolute; 
  left: 50%;
  top: 60%;
`;

interface DataMessageProps {
  message: string;
}

const DataMessage: React.FC<DataMessageProps> = ({ message }) =>
  <Message>{message}</Message>;


export default DataMessage;
