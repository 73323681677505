import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { PainLog, Screener } from '../../api-client';
import { formatDiff, isNumber } from '../../utils/number';
import * as moment from 'moment-timezone';

interface PainTableProps {
  productType: string;
  painLogs: PainLog[];
  screener: Screener;
  timezone: string;
}

function renderTableRows({ painLogs, screener, timezone }: PainTableProps) {
  let lastWeekIndex: number | null | undefined;

  return painLogs.map((p) => {
    const painDiff = (isNumber(screener.pain) && isNumber(p.pain)) ? p.pain - screener.pain : NaN;
    const secondaryDiff = (isNumber(screener.secondary_metric) && isNumber(p.secondary_metric))
      ? p.secondary_metric - screener.secondary_metric
      : NaN;
    const r = (
      <Table.Row key={p.id}>
        <Table.Cell singleLine>{lastWeekIndex !== p.week_index ? `Week ${p.week_index}` : ''}</Table.Cell>
        <Table.Cell>{p.pain}</Table.Cell>
        <Table.Cell positive={painDiff < -20} negative={painDiff > 20}>{formatDiff(painDiff)}</Table.Cell>
        <Table.Cell>{p.secondary_metric}</Table.Cell>
        <Table.Cell positive={secondaryDiff < -20} negative={secondaryDiff >= 20}>
          {formatDiff(secondaryDiff)}
        </Table.Cell>
        <Table.Cell singleLine={false}>{p.note}</Table.Cell>
        <Table.Cell singleLine>{p.created_at ? moment(p.created_at).tz(timezone).format('LLL z') : 'N/A'}</Table.Cell>
      </Table.Row>
    );
    lastWeekIndex = p.week_index;
    return r;
  });
}

const renderScreeningRow = ({ screener }: PainTableProps) => (
  <Table.Row>
    <Table.Cell>Screener</Table.Cell>
    <Table.Cell>{screener.pain}</Table.Cell>
    <Table.Cell>–</Table.Cell>
    <Table.Cell>{screener.secondary_metric}</Table.Cell>
    <Table.Cell>–</Table.Cell>
    <Table.Cell>–</Table.Cell>
    <Table.Cell>–</Table.Cell>
  </Table.Row>
);

const getStiffnessLabel = (productType: string) => productType === 'back' ? 'QOL' : 'Stiffness';

const PainTable: React.FunctionComponent<PainTableProps> = (props) => (
  <Table celled striped>
    <Table.Header>
      <Table.HeaderCell>Week</Table.HeaderCell>
      <Table.HeaderCell>Pain</Table.HeaderCell>
      <Table.HeaderCell>All-Time Pain</Table.HeaderCell>
      <Table.HeaderCell>{getStiffnessLabel(props.productType)}</Table.HeaderCell>
      <Table.HeaderCell>All-Time {getStiffnessLabel(props.productType)}</Table.HeaderCell>
      <Table.HeaderCell>Note</Table.HeaderCell>
      <Table.HeaderCell>Local Timestamp</Table.HeaderCell>
    </Table.Header>
    <Table.Body>
      {renderTableRows(props)}
      {renderScreeningRow(props)}
    </Table.Body>
  </Table>
);

export default PainTable;
