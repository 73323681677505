import * as React from 'react';
import Check from '@material-ui/icons/Check';
import Problem from '@material-ui/icons/ReportProblemOutlined';
import Error from '@material-ui/icons/ErrorOutline';
import {
  ERROR_ICON,
  ERROR_TEXT,
  SUCCESS_ICON,
  SUCCESS_TEXT,
  WARNING_DARK,
  WARNING_TEXT_ONE
} from '../../../colors';
import { getTrackingStatus, trackingStatus } from '../../../utils/user/trackingStatus';
import { User } from '../../../graphql/models';
import styled from 'styled-components';

const iconMargin = '0px 6px -5px 0px';

const DeliveredIcon = styled(Check)`
  color: ${SUCCESS_ICON};
  margin: ${iconMargin};
`;

const DeliveredText = styled.span`
  color: ${SUCCESS_TEXT};
`;

const ErrorIcon = styled(Error)`
  color: ${ERROR_ICON};
  margin: ${iconMargin};
`;

const ErrorText = styled.span`
  color: ${ERROR_TEXT};
`;

const IndentedText = styled.span`
  padding-left: 27px;
`;

const LateIcon = styled(Problem)`
  color: ${WARNING_DARK};
  margin: ${iconMargin};
`;

const LateText = styled.span`
  color: ${WARNING_TEXT_ONE};
`;

interface StatusIconParams {
  status?: string | null;
  users?: User[];
}

const hasIcons = (users: User[] | undefined) =>
  users && users.some(u =>
    getTrackingStatus(trackingStatus(u)) === 'Delivered' ||
    getTrackingStatus(trackingStatus(u)) === 'Error' ||
    getTrackingStatus(trackingStatus(u)) === 'Late'
  );

const statusIcon = ({ status, users }: StatusIconParams) => {
  switch (status) {
    case 'DELIVERED':
      return (
        <DeliveredText>
          <DeliveredIcon />
          {getTrackingStatus(status)}
        </DeliveredText>
      );
    case 'FAILURE':
      return (
        <ErrorText>
          <ErrorIcon />
          {getTrackingStatus(status)}
        </ErrorText>
      );
    case 'LATE':
      return (
        <LateText>
          <LateIcon />
          {getTrackingStatus(status)}
        </LateText>
      );
    default:
      return hasIcons(users) ? (
        <IndentedText>{getTrackingStatus(status)}</IndentedText>
      ) : (
        <span>{getTrackingStatus(status)}</span>
      );
  }
};

interface ShipmentStatusProps {
  shipmentStatus?: string | null;
  users?: User[];
}

const ShipmentStatus: React.FC<ShipmentStatusProps> = ({ shipmentStatus, users }) =>
  statusIcon({ status: shipmentStatus, users });

export default ShipmentStatus;
