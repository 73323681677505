import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Person } from '@material-ui/icons';
import {
  Avatar,
  Chip,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { User } from '../../../graphql/models';
import { DispatchMap } from '../../../redux/actions/interfaces';
import { userActions } from '../../../redux/actions';
import { UserProfileRoutes } from '../../../utils/user/routes';
import { sortTeamUsersByName } from '../../../utils/helpers';
import { getUserETSessionStatus } from '../../../utils/user/etSessionStatus';
import { trackingStatus } from '../../../utils/user/trackingStatus';
import ShipmentStatus from '../user/ShipmentStatus';
import FirstETStatus from '../user/FirstETStatus';
import StrikeThroughUser from '../../user/StrikeThroughUser';
import DataMessage from '../../errors/DataMessage';

const StyledChip = styled(Chip)`
  && {
    margin: 3px 6px 3px 0;
    font-size: 14px;
  }
`;

const StyledPopover = styled(Popover)`
  pointer-events: none;
`;

const StyledTableCell = styled(TableCell)`
  && {
    padding: 7px 10px;
  }
`;

const StyledTableRow = styled(TableRow)`
  && {
    padding: 7px 10px;
  }
`;

const StyledAvatar = styled(Avatar)`
  && {
    height: 32px;
    width: 32px;
    margin-right: 16px;
  }
`;

const UserAvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AdditionalTags = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

const MoreTagsCount = styled.span`
  margin-left: 4px;
  width: 48px;
  height: 24px;
`;

const errorMessage = 'Failed to load users.';

type ConnectProps = DispatchMap<{
  saveUsersToRedux: typeof userActions.saveUsersToRedux;
}>;

interface OnboardingTeamTableProps {
  users: User[];
}

interface TableColumnHeader {
  title: string;
  align?: 'left' | 'right';
}

const columnHeaders: Array<TableColumnHeader> = [
  {
    title: 'Name'
  },
  {
    title: 'ID'
  },
  {
    title: 'Tags'
  },
  {
    title: 'Kit Status'
  },
  {
    title: '1st ET'
  }
];

const OnboardingTeamTable: React.FC<OnboardingTeamTableProps & ConnectProps> = ({ saveUsersToRedux, users }) => {
  const [anchorEl, setAnchorEl] = React.useState(document.activeElement);
  const [popoverId, setPopoverId] = React.useState<string>('');

  const sortedUsers = users && users.slice().sort(sortTeamUsersByName);

  saveUsersToRedux(sortedUsers || []);

  const renderUserTags = (tags: string[]) =>
    tags.map(tag => <StyledChip size='small' key={tag} label={tag} variant='outlined' />);

  const handlePopoverOpen = (event: React.MouseEvent, id: string) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(id);
  };

  const handlePopoverClose = (event: React.MouseEvent, id: string) => {
    if (event.currentTarget.id === id) {
      setAnchorEl(null);
      setPopoverId('');
    }
  };

  if (!users || !users.length) return <DataMessage message={errorMessage} />;

  return (
    <TableContainer>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            {columnHeaders.map(({ title, align = 'left' }) => (
              <React.Fragment key={title}>
                <StyledTableCell align={align}>{title}</StyledTableCell>
              </React.Fragment>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedUsers &&
            sortedUsers.map(user => (
              <StyledTableRow key={user.id}>
                <StyledTableCell component='th' scope='row'>
                  <UserAvatarWrapper>
                    <StyledAvatar alt={`${user.firstName} ${user.lastName}`} src={user.avatarUrl as string}>
                      <Person />
                    </StyledAvatar>
                    <Link to={`/user/${user.id}/${UserProfileRoutes.Engagement}`}>
                      <StrikeThroughUser user={user} />
                    </Link>
                  </UserAvatarWrapper>
                </StyledTableCell>
                <StyledTableCell align='left'>{user.id}</StyledTableCell>
                <StyledTableCell align='left'>
                  {user.tags && user.tags.length && renderUserTags(user.tags.slice(0, 2))}
                  {user.tags && user.tags.length > 2 ? (
                    <>
                      <MoreTagsCount
                        id={user.id}
                        aria-owns={Boolean(anchorEl) ? 'additional-tags' : undefined}
                        aria-haspopup='true'
                        onMouseEnter={e => handlePopoverOpen(e, user.id)}
                        onMouseLeave={e => handlePopoverClose(e, user.id)}
                      >
                        {`+${user.tags && user.tags.length > 2 ? user.tags.length - 2 : ''}`}
                      </MoreTagsCount>
                      <StyledPopover
                        id='additional-tags'
                        open={popoverId === user.id}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                      >
                        <AdditionalTags>{renderUserTags(user.tags.slice(2, user.tags.length))}</AdditionalTags>
                      </StyledPopover>
                    </>
                  ) : null}
                </StyledTableCell>
                <StyledTableCell align='left'>
                  <ShipmentStatus shipmentStatus={trackingStatus(user)} users={sortedUsers} />
                </StyledTableCell>
                <StyledTableCell align='left'>
                  <FirstETStatus status={getUserETSessionStatus(user.etSessions)} users={sortedUsers} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapDispatchToProps = {
  saveUsersToRedux: userActions.saveUsersToRedux
};

export default connect(undefined, mapDispatchToProps)(OnboardingTeamTable);
