import { useQuery } from '@apollo/client';
import * as React from 'react';
import styled from 'styled-components';
import { Box, ThemeContext, TableRow, TableCell, Text, Anchor } from 'grommet';
import { Loader } from 'semantic-ui-react';
import * as moment from 'moment-timezone';
import { User, Stratification } from '../../graphql/models';
import ScreenerBackground from '../../components/communications/ScreenerBackground';
import { SCREENER_HEADER_GRAY, BORDER_GRAY, BACKGROUND_GRAY, HINGE_LINK } from '../../colors';
import ScreenerPain, { ScreenerPainProps } from '../../components/communications/ScreenerPain';
import ScreenerSurgery, { ScreenerSurgeryProps } from '../../components/communications/ScreenerSurgery';
import { getUserActivePathway } from '../../utils/user/getUserActivePathway';
import { GET_USER_PERISURGICAL_DETAILS, GET_USER_SCREENING_INFO } from '../../graphql/queries/User';
import FullScreenerLink from '../../components/communications/FullScreenerLink';
import { ApiClientNames } from '../apollo/ApolloContainer';
import { GetUserById } from '../../graphql/models/bff/GetUserById';
import { PerisurgicalDetail } from '../../graphql/models/bff/PerisurgicalDetail';
import { ProgramName } from '../../api-client/interfaces';
export const SCREENER_RESPONSES = ['No', 'Yes'];

const ScreenerTabStyles = {
  table: {
    extend: {
      fontSize: '14px'
    }
  }
};

export const ScreenerHeader = styled(Box)`
  border-radius: 5px;
  background-color: ${SCREENER_HEADER_GRAY};
  font-size: 14px;
  padding: 6px 12px;
`;

export const ScreenerRow = styled(TableRow)`
  border-bottom: 1px solid;
  border-bottom-color: ${BORDER_GRAY};
`;

export const ScreenerCell = styled(TableCell)`
  padding: 12px;
  line-height: 20px;
`;

export const ScreenerField = styled(TableCell)`
  padding: 12px;
  line-height: 20px;
  width: 104px;
`;

export const ScreenerText = styled(Text)`
  padding: 6px 0px;
  line-height: 20px;
  font-size: 14px;
`;

export const ScreenerDateResolved = styled(Text)`
  margin-top: 1px;
  font-size: 13px;
  color: ${BACKGROUND_GRAY};
`;

export const ScreenerLink = styled(Anchor)`
  font-size: 13px;
  color: ${HINGE_LINK};
`;

interface ScreenerManagerProps {
  userId: number;
  userUuid: string | null | undefined;
}

interface ScreeningInfoResponse {
  user: User;
}

interface PerisurgicalResponse {
  getUserById: GetUserById;
}

const selectScreenerPain = (stratification?: Stratification | null, name?: string): ScreenerPainProps => ({
  description: stratification?.basics?.description || stratification?.pain_basic?.pain_description,
  avoid: stratification?.basics?.avoid,
  avoided_exercises: stratification?.red_flag_follow_ups?.avoided_exercises,
  name: name || ''
});

const selectScreenerSurgery = (
  perisurgicalDetails: PerisurgicalDetail[],
  programName: ProgramName | undefined,
  stratification?: Stratification | null,
  name?: string): ScreenerSurgeryProps => ({
  impact: stratification?.impact,
  isPerisurgicalMember: programName === ProgramName.Perisurgical,
  follow_ups: {
    upcoming_surgeries: stratification?.red_flag_follow_ups?.upcoming_surgeries,
    future_surgery: stratification?.red_flag_follow_ups?.future_surgery
  },
  name: name || '',
  perisurgicalDetails
});

const ScreenerManager: React.FC<ScreenerManagerProps> = ({ userId, userUuid }) => {
  const { loading, error, data } = useQuery<
  ScreeningInfoResponse,
  { id: string }
  >(GET_USER_SCREENING_INFO, {
    variables: { id: userId.toString() }
  });

  const surgeryUserId = userUuid || userId.toString();

  const { loading: surgeryLoading, error: surgeryError, data: surgeryData } = useQuery<
  PerisurgicalResponse,
  { userId: string | number }
  >(GET_USER_PERISURGICAL_DETAILS, {
    variables: { userId: surgeryUserId },
    context: {
      clientName: ApiClientNames.BFF
    }
  });

  if (loading && surgeryLoading) {
    return (
      <Box pad='xsmall' flex justify='evenly'>
        <Loader active inline='centered' size='medium' />
      </Box>
    );
  }

  if (error || surgeryError) throw new Error('Unable to fetch user screening info');

  if (data && data.user && surgeryData && surgeryData.getUserById.perisurgicalDetails) {
    const { bio, coachingQuestions } = data.user;
    const pathway = getUserActivePathway(data.user);
    const indicationName = pathway?.indication?.name;
    const stratification = pathway?.screening?.stratification;
    const dateResolved = pathway?.screening?.resolvedAt;
    const backgroundProps = { ...coachingQuestions, bio };
    const perisurgicalDetails = surgeryData.getUserById.perisurgicalDetails;
    const programName = surgeryData.getUserById?.pathways ?
      surgeryData.getUserById.pathways[0].programIndication?.programName : undefined;
    const painProps = selectScreenerPain(stratification, indicationName);
    const surgeryProps = selectScreenerSurgery(perisurgicalDetails, programName, stratification, indicationName);

    return (
      <Box pad='xsmall' flex justify='evenly'>
        <Box style={{ padding: '6px 20px' }} flex justify='between' direction='row'>
          <ScreenerDateResolved>
            {dateResolved ? `Date created: ${moment(dateResolved).format('ll')}` : ''}
          </ScreenerDateResolved>
          <FullScreenerLink user={data.user} size='small' />
        </Box>
        <ThemeContext.Extend value={ScreenerTabStyles}>
          <ScreenerBackground {...backgroundProps} />
          <ScreenerPain {...painProps} />
          <ScreenerSurgery {...surgeryProps} />
        </ThemeContext.Extend>
      </Box>
    );
  }
  return null;
};

export default ScreenerManager;
