import * as React from 'react';
import { VelocityComponent } from 'velocity-react';
import styled from 'styled-components';

import * as spin0 from '../../images/spin-0.svg';
import * as spin1 from '../../images/spin-1.svg';
import * as spin2 from '../../images/spin-2.svg';
import * as spin3 from '../../images/spin-3.svg';
import * as spin4 from '../../images/spin-4.svg';
import { SemanticSizes } from '../../typings/semantic';
import { Easing } from '../../utils/animation';

const BG_FADE = {
  duration: 1000,
  easing: Easing.SHARP,
  animation: {
    opacity: 0.4
  }
};

const BG_SOLID = {
  delay: 300,
  duration: 800,
  easing: Easing.STANDARD,
  animation: {
    opacity: 1
  }
};

const FG_SPIN = {
  duration: 600,
  loop: true,
  easing: Easing.STANDARD,
  animation: {
    opacity: [0, 1]
  }
};

const FG_FADE = {
  duration: 300,
  easing: Easing.ACCELERATION,
  animation: {
    opacity: 0
  }
};

const SpinnerSpan = styled.span`
  display: inline-block;
  position: relative;
`;

const OverlayImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`;

export interface SpinnerProps {
  active?: boolean;
  size?: SemanticSizes;
  'data-testid'?: string;
}

export interface DefaultProps {
  size: SemanticSizes;
}

class Spinner extends React.PureComponent<SpinnerProps> {
  static defaultProps: DefaultProps = { size: 'medium' };

  render() {
    const bg = this.props.active ? BG_FADE : BG_SOLID;
    const fg = this.props.active ? FG_SPIN : FG_FADE;
    const sizeClass = `custom-square ${this.props.size}`;

    return (
      <SpinnerSpan data-testid={this.props['data-testid']} className={sizeClass}>
        <VelocityComponent runOnMount { ...bg }>
          <OverlayImg src={spin0} className={sizeClass} />
        </VelocityComponent>
        <VelocityComponent runOnMount { ...fg } delay={0}>
          <OverlayImg src={spin1} className={sizeClass} />
        </VelocityComponent>
        <VelocityComponent runOnMount { ...fg } delay={100}>
          <OverlayImg src={spin2} className={sizeClass} />
        </VelocityComponent>
        <VelocityComponent runOnMount { ...fg } delay={200}>
          <OverlayImg src={spin3} className={sizeClass} />
        </VelocityComponent>
        <VelocityComponent runOnMount { ...fg } delay={300}>
          <OverlayImg src={spin4} className={sizeClass} />
        </VelocityComponent>
      </SpinnerSpan>
    );
  }
}

export default Spinner;
