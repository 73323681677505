import * as React from 'react';
import * as moment from 'moment-timezone';
import { isNullOrUndefined } from 'util';
import { Achievement } from '../../api-client/interfaces';
import { UserRecord } from '../../redux/reducers';
import { DEFAULT_TIMEZONE } from '../../utils/user/timezone';
import {
  achievementsConfig,
  getAchievementsCompletedThisWeek,
  isAchievementsConfigKey
} from '../../utils/user/achievements';
import './RecentAchievementsContext.scss';

interface Props {
  achievements?: Achievement[];
  user?: UserRecord;
}

const NoAchievementsAvailable = () => <p className="achievement-context__na">N/A</p>;

const AchievementsContext: React.FC<Props> = ({ achievements, user }) => {
  const timezone = user?.get('timezone') || DEFAULT_TIMEZONE;

  if (isNullOrUndefined(achievements)) {
    return <NoAchievementsAvailable />;
  }

  const recentAchievements = getAchievementsCompletedThisWeek(achievements);

  return recentAchievements.length === 0
    ? <NoAchievementsAvailable />
    : (
      <div>
        <ul className="achievement-context__list">
          {
            recentAchievements.map(achievement => {
              if(!isAchievementsConfigKey(achievement.key)) {
                return null;
              }
              const config = achievementsConfig[achievement.key];
              const achievedDate = moment(achievement.achievedDate).tz(timezone).format('M/D/YY');
              return (
                <li className="achievement-context__list-item" key={achievement.key}>
                  {`${config.section}: ${config.title} on ${achievedDate}`}
                </li>
              );
            })
          }
        </ul>
      </div>
    );
};

export default AchievementsContext;
