export const GET_NOTES = `
  query getNotes($userUuid: String!, $itemsPerPage: Int!, $page: Int!) {
    getNotes(userUuid: $userUuid, itemsPerPage: $itemsPerPage, page: $page)
  }
`;

export const EXPORT_NOTE = `
  query exportNote($id: Int!) {
    exportNote(id: $id)
  }
`;
