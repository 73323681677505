export enum AdminRoles {
  super = 'super',
  coach = 'coach',
  analyst = 'analyst',
  staff = 'staff',
  dev = 'dev',
  physical_therapist = 'physical_therapist'
}

export const isPtRole = (role: string) => role === AdminRoles.physical_therapist;
export const isSuperRole = (role: string) => role === AdminRoles.super;
