export const UPDATE_TASK_COUNT = 'UPDATE_TASK_COUNT';
export const ADD_TASK_COUNT = 'ADD_TASK_COUNT';
export const UPDATE_UNREAD_MESSAGE_COUNT = 'UPDATE_UNREAD_MESSAGE_COUNT';
export const UPDATE_TASK_KIND_RESULTS = 'UPDATE_TASK_KIND_RESULTS';
export const UPDATE_TASK_USER_COUNT = 'UPDATE_TASK_USER_COUNT';
export const RESET_DO_TASK_USER_COUNT = 'RESET_DO_TASK_USER_COUNT';
export const CLEAR_TASK_USER_COUNTS = 'CLEAR_TASK_USER_COUNTS';
export const ADD_UNREAD_MESSAGE_COUNT = 'ADD_UNREAD_MESSAGE_COUNT';
export const UPDATE_MULTI_TASK_USER_COUNT = 'UPDATE_MULTI_TASK_USER_COUNT';
export const UPDATE_MULTI_UNREAD_MESSAGE_COUNT = 'UPDATE_MULTI_UNREAD_MESSAGE_COUNT';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const STORE_TEMPLATE_USER_IDS = 'STORE_TEMPLATE_USER_IDS';
export const TOGGLE_SELECT_TEMPLATE_USER_ID = 'TOGGLE_SELECT_TEMPLATE_USER_ID';
export const UPDATE_MODAL_OPEN = 'UPDATE_MODAL_OPEN';
export const REMOVE_TEMPLATE_USER_IDS = 'REMOVE_TEMPLATE_USER_IDS';
export const UPDATE_TEAM_RESULTS = 'UPDATE_TEAM_RESULTS';
export const SET_IN_CTP_IFRAME = 'SET_IN_CTP_IFRAME';
