import * as React from 'react';
import { Location } from 'history';
import { ERR_USER_ID_INVALID } from '../../errors';

import UserNavBar from '../../components/nav/UserNavBar';
import UserProfileBannerContainer from '../user/phx/UserProfileBannerContainer';
// MSK-539 - Change this into an actual history trail when the time comes
import UserTasksHistoryContainer from './UserTasksHistoryContainer';

export interface UserHistoryContainerProps {
  id?: number;
  location?: Location;
}

export const WrappedComponent: React.FunctionComponent<UserHistoryContainerProps> = ({ id, location }) => {
  if (id === undefined) throw new Error(ERR_USER_ID_INVALID);
  return (
    <div>
      <UserProfileBannerContainer id={id} />
      <UserNavBar location={location} id={id} />
      <UserTasksHistoryContainer userId={id} />
    </div>
  );
};

export default WrappedComponent;
