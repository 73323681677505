import { connect } from 'react-redux';

import { RootState } from '../../redux/reducers';
import GoalSegmentEditor from '../../components/user/GoalSegmentEditor';
import { actionCreators as goalActions } from '../../redux/actions/goals';
import { actionCreators as goalEditorActions } from '../../redux/actions/goals-editor';
import { selectEditedGoal } from '../../redux/selectors/goals-editor';

export interface GoalEditContainerProps {
  userId: number;
  goalId: string;
  focusInput?: boolean;
  onClose?(): void;
}

const mapStateToProps = (state: RootState, props: GoalEditContainerProps) => ({
  goal: selectEditedGoal(state, props)
});

const mapDispatchToProps = {
  saveGoal: goalActions.put,
  editGoalContent: goalEditorActions.editGoalContent,
  editGoalDueDate: goalEditorActions.editGoalDueDate
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalSegmentEditor);
