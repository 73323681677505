import * as React from 'react';
import { OrderedDoTaskKindTitles, ClinicalTaskKindTitles } from '../../api-client/interfaces';

const TITLES: { [key: string]: string } = {
  ...ClinicalTaskKindTitles,
  ...OrderedDoTaskKindTitles
};

interface TitleProps {
  kind: string;
}

const TaskTitle: React.FunctionComponent<TitleProps> = ({ kind }) => (
  <span>{TITLES[kind] || ''}</span>
);

export default TaskTitle;
