import * as React from 'react';
import { GetUserById, EnrollmentStatus, Status } from '../../graphql/models/bff';
import UserPoster from '../../components/communications/UserPoster';

export interface UserPosterManagerProps {
  userData?: GetUserById | null;
  enrollmentStatus: EnrollmentStatus | null;
  changeEnrollmentStatus: (status: Status) => unknown;
}

const UserPosterManager: React.FC<UserPosterManagerProps> = ({
  userData,
  enrollmentStatus,
  changeEnrollmentStatus
}) =>
  userData ? (
    <UserPoster
      user={userData}
      enrollmentStatus={enrollmentStatus}
      changeEnrollmentStatus={changeEnrollmentStatus}
    />
  ) : null;

export default UserPosterManager;
