import * as React from 'react';
import styled from 'styled-components';
import { RecentExerciseTherapyContainer } from './RecentExerciseTherapyContainer';
import { EnsoUtilizationContainer } from './EnsoUtilizationContainer';
import { Typography, Divider } from '@material-ui/core';
import { GetUserById } from '../../graphql/models/bff';
import EducationContextContainer from '../../containers/user/EducationContextContainer';
import EngagementStreakContainer from '../../containers/teams/phx/EngagementStreakContainer';
import { days, hours, milliseconds, minutes, seconds } from '../../utils/datetime';
import { TEXT_SECONDARY } from '../../colors';
import { useSelector } from 'react-redux';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';
import { RootState } from '../../redux/reducers';
import { ensoUtilization } from '../../features';

export const ErrorMessage = 'Failed to load.';
export const EmptyData = 'No data to display.';

interface ActivityEngagementConProps {
  userId: number;
  userData?: GetUserById | null;
  dataLoading: boolean;
}

const StyledTypographyActivityTitle = styled(Typography)`
  && {
    padding: 20px 0 20px 20px;
    font-size: 18px;
    display: flex;
    margin: 0
  }
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledEngagementAndOnboardedContainer = styled.div`
  flex: 0 1 auto;
`;

export const StyledTypographySinceOnboarded = styled(Typography)`
&& {
  font-size: 14px;
  text-align: right;
  padding-right: 20px;
  color: ${TEXT_SECONDARY};
}
`;

export const getTimeSinceOnboarded = (firstEtAt: string) => {
  const currentDate = new Date();
  const todayAtMidnightMs = currentDate.setHours(0,0,0,0);
  const firstEtAtMidnightMs = new Date(firstEtAt).setHours(0,0,0,0);
  const oneWeekAgoAtMidnightMs = new Date(todayAtMidnightMs).setDate(new Date(todayAtMidnightMs).getDate() - 7);
  const msSinceFirstEt = todayAtMidnightMs - firstEtAtMidnightMs;

  if (firstEtAtMidnightMs >= oneWeekAgoAtMidnightMs) {
    const diffInDays = Math.ceil(msSinceFirstEt / (milliseconds * seconds * minutes * hours));
    return `Onboarded ${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
  }

  const diffInWeeks = Math.ceil(msSinceFirstEt / (milliseconds * seconds * minutes * hours * days));

  return `Onboarded ${diffInWeeks}W ago`;
};

export const ActivityEngagementContainer: React.FC<ActivityEngagementConProps> = ({
  userId,
  userData,
  dataLoading
}) => {
  const ensoUtilizationEnabled = useSelector((state: RootState) =>
    selectTreatmentValue(state.splitReducer, ensoUtilization) === 'on'
  );
  const firstEtDate = userData?.pathways && userData.pathways[0]?.firstEtAt;
  const userHasEnso = userData?.tags?.includes('enable_enso') || false;

  return (
    <>
      <StyledHeaderWrapper>
        <StyledTypographyActivityTitle>Activity</StyledTypographyActivityTitle>
        <StyledEngagementAndOnboardedContainer>
          <EngagementStreakContainer id={`${userId}`} displayV2Components />
          {firstEtDate &&
            <StyledTypographySinceOnboarded>{getTimeSinceOnboarded(firstEtDate)}</StyledTypographySinceOnboarded>
          }
        </StyledEngagementAndOnboardedContainer>
      </StyledHeaderWrapper>
      <RecentExerciseTherapyContainer
        pathways={userData?.pathways}
        dataLoading={dataLoading}
        timezone={userData?.timezone}
      />
      <EducationContextContainer userId={userId} />
      { (userHasEnso && ensoUtilizationEnabled) &&
        <>
          <Divider />
          <EnsoUtilizationContainer userUuid={userData?.uuid} />
        </>
      }
      <Divider />
    </>
  );};
