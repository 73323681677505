import * as React from 'react';
import GoalSegmentContainer from '../../containers/user/GoalSegmentContainer';
import { Segment } from 'semantic-ui-react';
import { GoalRecord } from '../../redux/reducers';

interface GoalSegmentsProps {
  goals: GoalRecord[];
  userId: number;
}

const GoalSegments: React.FunctionComponent<GoalSegmentsProps> = (props) => (
  <Segment.Group>
    {props.goals.map((goal) => (
      <GoalSegmentContainer userId={props.userId} key={goal.id} goal={goal} />
    ))}
  </Segment.Group>
);

export default GoalSegments;
