import { GetNotesParams, UpdateNoteParams, DeleteNoteParams } from '../../api-client/endpoints';
import { ThunkAction } from './interfaces';
import { getApiClient, selectAdmin } from '../selectors';
import { Note } from '../../api-client/interfaces';
import { CREATE_NOTE, UPDATE_NOTE, DELETE_NOTE } from '../../graphql/mutations';
import { GET_NOTES, EXPORT_NOTE } from '../../graphql/queries';
import {
  ERR_CREATE_NOTE,
  ERR_GET_NOTES,
  ERR_DELETE_NOTE,
  ERR_SAVING_NOTE
} from '../../errors';
import { SHOW_NOTIFICATION } from '../constants';

export const createNote = (userUuid?: string | null): ThunkAction<Promise<Note>> =>
  (_, getState) => {
    const state = getState();
    const api = getApiClient(state);

    const adminUuid: string | null | undefined = selectAdmin(state).uuid;

    if (!userUuid || !adminUuid) throw new Error('missing user and admin uuid!');

    return api.notes.create({
      query: CREATE_NOTE,
      variables: { adminUuid, userUuid }
    }).then(res => {
      if (res.createNote === false) {
        throw new Error(`${ERR_CREATE_NOTE}: user uuid: ${userUuid}`);
      }
      return res.createNote;
    }).catch((err) => {
      throw new Error(
        `Cannot create note for user uuid: ${userUuid}: ${err.message}`
      );
    });
  };


export const getNotes = (params: GetNotesParams): ThunkAction<Promise<{ data: Note[] }>> =>
  (_, getState) => {
    const { userUuid, page, itemsPerPage, userId } = params;
    const state = getState();
    const api = getApiClient(state);

    if (!userUuid) throw new Error(`Cannot fetch notes. User ${userId} is missing a uuid!`);

    return api.notes.get({
      query: GET_NOTES,
      variables: { userUuid, page, itemsPerPage }
    }).then(res => {
      if (res.getNotes === false) {
        throw new Error(`${ERR_GET_NOTES} user uuid: ${userUuid}`);
      }

      return res.getNotes;
    }).catch((err) => {
      throw new Error(`Cannot fetch notes for user uuid: ${userUuid}, ${err.message}`);
    });
  };

export const updateNote = (params: UpdateNoteParams): ThunkAction<Promise<Note>> =>
  (_, getState) => {
    const { id, author, userUuid, noteType, body, status } = params;
    const state = getState();
    const api = getApiClient(state);

    return api.notes.update({
      query: UPDATE_NOTE,
      variables: { id, noteType, body, status }
    }).then(res => {
      if (res.updateNote === false) {
        throw new Error(`${ERR_SAVING_NOTE}`);
      }
      return res.updateNote;
    }).catch((err) => {
      throw new Error(`${err.message},
      note id: ${id}, admin uuid: ${author.uuid}, user uuid: ${userUuid}`);
    });
  };

export const deleteNote = (params: DeleteNoteParams): ThunkAction<Promise<{}>> => (dispatch, getState) => {
  const { id } = params;
  const state = getState();
  const api = getApiClient(state);

  return api.notes.delete({
    query: DELETE_NOTE,
    variables : { id }
  }).then(res => {
    if (res.deleteNote === false) {
      dispatch({
        payload: {
          message: `${ERR_DELETE_NOTE}`,
          httpStatus: 0,
          type: 'error'
        },
        type: SHOW_NOTIFICATION
      });
    }
    return res.deleteNote;
  }).catch((err) => {
    dispatch({
      payload: {
        message: `${ERR_DELETE_NOTE}`,
        httpStatus: 0,
        type: 'error'
      },
      type: SHOW_NOTIFICATION
    });
    return err;
  });
};

export const exportNote = (id: number): ThunkAction<Promise<string>> =>
  (_, getState) => {
    const state = getState();
    const api = getApiClient(state);

    if (!id) throw new Error('Cannot export note. Missing note id!');

    return api.notes.export({
      query: EXPORT_NOTE,
      variables: { id }
    })
      .then(exportNoteResponse => exportNoteResponse.exportNote.presignedUrl) // TODO: BAT-1139
      .catch((err) => {
        throw new Error(`Cannot export note, ${err.message}`);
      });
  };
