import * as React from 'react';
import * as Moment from 'moment';
import { ApiClientNames } from '../apollo/ApolloContainer';
import { useMutation } from '@apollo/client';
import ModalWrapper from '../../components/modal/ModalWrapper';
import CreateCarePlanModalContent from './CreateCarePlanModalContent';
import CreateCarePlanDetailsModalContent from './CreateCarePlanDetailsModalContent';
import { CarePlan, CarePlanStatus, Icd10, Prescription, UserDataForCarePlan } from '../../api-client/interfaces';
import { CREATE_CARE_PLAN } from '../../graphql/mutations/CarePlan';
import { Pathway } from '../../graphql/models/Pathway';
import { GET_USER_CARE_PLANS, GET_USER_DATA_FOR_CARE_PLANS } from '../../graphql/queries/User';
import { CARE_PLANS_PER_PAGE, currentPage, DischargeReasons } from './CarePlansContainer';

export interface Props {
  openCreateCarePlan: boolean;
  diagnosesOptions: Icd10[];
  closeCarePlanContainer(): void;
  userUuid: string;
  adminUuid: string;
  pathway: Pathway;
  stateOfResidence: string;
}

interface Hash {
  [key: string]: string;
}

const fieldDefaults = {
  carePlanName: '',
  status: '' as '',
  selectedOpenDate: Moment(),
  selectedExpectedDischargeDate: null,
  selectedClosedDate: Moment(),
  dischargeReason: '' as '',
  diagnoses: [],
  prescription: '' as ''
};

interface CreateCarePlanVariables {
  userId: string;
  adminId: string;
  pathwayId: string;
  title: string;
  locale: string;
  program: string;
  indication: string;
  status: CarePlanStatus | string;
  icd10: string[];
  expectedDischargeAt: string;
  dischargeReason: string | null;
  prescription: string;
  openedAt: string;
  closedAt: string | null;
}

const CreateCarePlanContainer: React.FC<Props> = ({
  openCreateCarePlan,
  diagnosesOptions,
  closeCarePlanContainer,
  userUuid,
  adminUuid,
  pathway,
  stateOfResidence
}) => {
  const { programIndication } = pathway;
  const { programName, indicationName } = programIndication;

  // modal status
  const [openCreateModal, setOpenCreateModal] = React.useState<boolean>(openCreateCarePlan);
  const [openPlanDetailsModal, setOpenPlanDetailsModal] = React.useState<boolean>(false);

  // CreateCarePlanModalContent
  const [carePlanName, setCarePlanName] = React.useState<string>(fieldDefaults.carePlanName);
  const [status, setStatus] = React.useState<CarePlanStatus | ''>(fieldDefaults.status);

  const handleCarePlanNameChange = React.useCallback((event: React.ChangeEvent<{ value: string }>) => {
    if (event.target.value) {
      setCarePlanName(event.target.value);
    }
  }, []);

  const handleStatusChange = React.useCallback((event: React.ChangeEvent<{ value: CarePlanStatus | '' }>) => {
    if (event.target.value) {
      setStatus(event.target.value);

      if (event.target.value === CarePlanStatus.Closed) {
        setDischargeReason(DischargeReasons.MetAllOrMostGoals);
      } else {
        setDischargeReason(fieldDefaults.dischargeReason);
      }
    }
  }, []);

  const CreateCarePlanModalContentProps = {
    title: 'Create care plan',
    body: (
      <CreateCarePlanModalContent
        carePlanName={carePlanName}
        status={status}
        onCarePlanNameChange={handleCarePlanNameChange}
        onStatusChange={handleStatusChange}
      />
    ),
    confirmBtnText: 'NEXT',
    dismissBtnText: 'CANCEL'
  };

  const disableNextButton = !(carePlanName && status);

  const handlePlanDetailsModalOpen = () => setOpenPlanDetailsModal(true);

  const handleCreatePlanModalClose = () => {
    setOpenCreateModal(false);
    closeCarePlanContainer();
    resetFields();
  };

  // CreateCarePlanDetailsModalContent
  const [selectedOpenDate, setSelectedOpenDate] = React.useState<Moment.Moment | null>(fieldDefaults.selectedOpenDate);
  const [selectedExpectedDischargeDate, setSelectedExpectedDischargeDate] = React.useState<Moment.Moment | null>(
    fieldDefaults.selectedExpectedDischargeDate
  );
  const [selectedClosedDate, setSelectedClosedDate] = React.useState<Moment.Moment | null>(
    fieldDefaults.selectedClosedDate
  );
  const [dischargeReason, setDischargeReason] = React.useState<DischargeReasons | ''>(fieldDefaults.dischargeReason);
  const [diagnoses, setDiagnoses] = React.useState<string[]>(fieldDefaults.diagnoses);
  const [prescription, setPrescription] = React.useState<Prescription | ''>(fieldDefaults.prescription);

  const handleOpenDateChange = React.useCallback((date: Moment.Moment | null) => {
    if (date) {
      setSelectedOpenDate(date);
    }
  }, []);

  const handleExpectedDischargeDateChange = React.useCallback((date: Moment.Moment | null) => {
    if (date) {
      setSelectedExpectedDischargeDate(date);
    }
  }, []);

  const handleClosedDateChange = React.useCallback((date: Moment.Moment | null) => {
    if (date) {
      setSelectedClosedDate(date);
    }
  }, []);

  const handleDischargeReasonChange = React.useCallback(
    (event: React.ChangeEvent<{ value: DischargeReasons | '' }>) => {
      if (event.target.value) {
        setDischargeReason(event.target.value);
      }
    }, []);

  const handleDiagnosesChange = React.useCallback((event: React.ChangeEvent<{ value: string[] }>) => {
    if (event.target.value) {
      setDiagnoses(event.target.value);
    }
  }, []);

  const handlePrescriptionChange = React.useCallback((event: React.ChangeEvent<{ value: Prescription | '' }>) => {
    if (event.target.value) {
      setPrescription(event.target.value);
    }
  }, []);

  const createCarePlanDetailsModalContentProps = {
    title: 'Create care plan',
    body: (
      <CreateCarePlanDetailsModalContent
        status={status}
        selectedOpenDate={selectedOpenDate}
        onOpenDateChange={handleOpenDateChange}
        selectedExpectedDischargeDate={selectedExpectedDischargeDate}
        onExpectedDischargeDateChange={handleExpectedDischargeDateChange}
        selectedClosedDate={selectedClosedDate}
        onClosedDateChange={handleClosedDateChange}
        dischargeReason={dischargeReason}
        onDischargeReasonChange={handleDischargeReasonChange}
        prescription={prescription}
        onPrescriptionChange={handlePrescriptionChange}
        diagnoses={diagnoses}
        onDiagnosesChange={handleDiagnosesChange}
        diagnosesOptions={diagnosesOptions}
      />
    ),
    confirmBtnText: 'FINISH',
    dismissBtnText: 'BACK'
  };

  const icd10DescriptionsToCode = (): string[] => {
    const descriptionToCodeLookup = diagnosesOptions.reduce((hash: Hash, el) => {
      hash[el.description] = el.code;
      return hash;
    }, {});
    return diagnoses.map(description => descriptionToCodeLookup[description]);
  };

  const resetFields = () => {
    setCarePlanName(fieldDefaults.carePlanName);
    setStatus(fieldDefaults.status);
    setSelectedOpenDate(fieldDefaults.selectedOpenDate);
    setSelectedExpectedDischargeDate(fieldDefaults.selectedExpectedDischargeDate);
    setSelectedClosedDate(fieldDefaults.selectedClosedDate);
    setDischargeReason(fieldDefaults.dischargeReason);
    setDiagnoses(fieldDefaults.diagnoses);
    setPrescription(fieldDefaults.prescription);
  };

  const [createCarePlan, { error }] = useMutation<{ createCarePlan: CarePlan }, CreateCarePlanVariables>(
    CREATE_CARE_PLAN,
    {
      context: {
        clientName: ApiClientNames.NestBFF
      }
    }
  );
  if (error) {
    throw new Error('Unable to create care plan');
  }

  const createExpectedDischargeAt = React.useCallback(
    () =>
      status === CarePlanStatus.Closed
        ? (selectedClosedDate?.toISOString() as string)
        : (selectedExpectedDischargeDate?.toISOString() as string),
    [status, selectedClosedDate, selectedExpectedDischargeDate]
  );

  const handleFinishClick = React.useCallback(() => {
    createCarePlan({
      variables: {
        userId: userUuid,
        pathwayId: pathway.uuid,
        adminId: adminUuid,
        title: carePlanName,
        locale: stateOfResidence,
        program: programName,
        indication: indicationName,
        status: status.toLowerCase(),
        icd10: icd10DescriptionsToCode(),
        expectedDischargeAt: createExpectedDischargeAt(),
        dischargeReason: dischargeReason || null,
        prescription,
        openedAt: selectedOpenDate?.toISOString() as string,
        closedAt: status === CarePlanStatus.Closed && selectedClosedDate ? selectedClosedDate.toISOString() : null
      },
      update(cache, { data }) {
        const cachedCPData = cache.readQuery<{ user: { carePlans: CarePlan[] } }>({
          query: GET_USER_CARE_PLANS,
          variables: { uuid: userUuid, itemsPerPage: CARE_PLANS_PER_PAGE, page: currentPage }
        });

        const cachedUserCPData = cache.readQuery<{ getUserById: UserDataForCarePlan }>({
          query: GET_USER_DATA_FOR_CARE_PLANS,
          variables: { userUuid }
        });

        if (cachedCPData) {
          cache.writeQuery({
            query: GET_USER_CARE_PLANS,
            variables: { uuid: userUuid, itemsPerPage: CARE_PLANS_PER_PAGE, page: currentPage },
            data: {
              user: {
                ...cachedCPData?.user,
                carePlans: [...cachedCPData.user.carePlans, data?.createCarePlan]
              }
            }
          });
        }

        if (cachedUserCPData) {
          cache.writeQuery({
            query: GET_USER_DATA_FOR_CARE_PLANS,
            variables: { userUuid },
            data: {
              getUserById: {
                ...cachedUserCPData?.getUserById
              }
            }
          });
        }
      }
    }).then(() => {
      setOpenPlanDetailsModal(false);
      setOpenCreateModal(false);
      closeCarePlanContainer();
      resetFields();
    });
  }, [
    carePlanName,
    status,
    prescription,
    selectedExpectedDischargeDate,
    selectedOpenDate,
    selectedClosedDate,
    diagnoses
  ]);

  const handlePlanDetailsModalBackClick = () => setOpenPlanDetailsModal(false);

  const handlePlanDetailsModalBackDropClick = () => {
    setOpenPlanDetailsModal(false);
    setOpenCreateModal(false);
    closeCarePlanContainer();
    resetFields();
  };

  const disableFinishButton = !(
    selectedOpenDate &&
    (status === CarePlanStatus.Open ? selectedExpectedDischargeDate : selectedClosedDate) &&
    diagnoses.length &&
    prescription
  );

  return (
    <>
      <ModalWrapper
        modalContent={CreateCarePlanModalContentProps}
        onConfirm={handlePlanDetailsModalOpen}
        onDismiss={handleCreatePlanModalClose}
        openModal={openCreateModal}
        disableConfirmButton={disableNextButton}
      />
      <ModalWrapper
        modalContent={createCarePlanDetailsModalContentProps}
        onConfirm={handleFinishClick}
        onDismiss={handlePlanDetailsModalBackClick}
        onBackdropClick={handlePlanDetailsModalBackDropClick}
        openModal={openPlanDetailsModal}
        disableConfirmButton={disableFinishButton}
      />
    </>
  );
};

export default CreateCarePlanContainer;
