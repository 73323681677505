import * as React from 'react';
import { TooltipProps, TooltipPayload } from 'recharts';

export interface EngTooltipPayload extends TooltipPayload {
  color?: string;
  payload?: EngTooltipDatum;
}

export interface EngTooltipDatum {
  Playlist: number | null;
  PlaylistCount: number | null;
  PlaylistGoal: number | null;
  ET: number | null;
  ETCount: number | null;
  ETGoal: number | null;
  Edu: number | null;
  EduCount: number | null;
  EduGoal: number | null;
  Logging: number | null;
  LoggingCount: number | null;
  LoggingGoal: number | null;
  [key: string]: number | null;
}

export const toolTipStyle = {
  margin: 0,
  padding: '10px',
  backgroundColor: '#fff',
  borderRadius: '15px',
  border: '2px solid #ccc',
  whiteSpace: 'nowrap' as 'nowrap',
  lineHeight: '24px',
  left: 300
};

export const metricRowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1px'
};

function createWeekLabel(label?: string) {
  if (!label) return '';
  const weekIndex = label.slice(1).split('\n')[0];

  return `Week ${weekIndex}`;
}

const EngagementTooltip: React.FunctionComponent<TooltipProps> = props => {
  const { active, payload, label } = props;

  if (active && payload) {
    const engPayload: EngTooltipPayload[] = [...payload].reverse();

    return (
      <div style={toolTipStyle}>
        <p><strong>{createWeekLabel(label as string)}</strong></p>
        {engPayload.map(p => {
          const engDatum = p.payload;
          const count = `${p.name}Count`;
          const goal = `${p.name}Goal`;

          if (engDatum) {
            return (
              <p style={metricRowStyle} key={p.name}>
                <span style={{ color: p.color }}>{p.name}</span>
                <strong style={{ paddingLeft: '10px' }}>{`${engDatum[count]} / ${
                  engDatum[goal]
                }`}</strong>
              </p>
            );
          }
          return null;
        })}
      </div>
    );
  }
  return null;
};

export default EngagementTooltip;
