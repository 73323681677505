import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { WeeklyMetric, Screener } from '../../api-client';
import { formatDiff, isNumber } from '../../utils/number';
import { sortWeeklyMetricReverseWeek } from '../../redux/selectors/weekly-metric';
import { Map } from 'immutable';

interface EngagementTableProps {
  weeklyMetrics: Map<string, WeeklyMetric>;
  weeklyAveragePain: number[];
  screener: Screener;
}

const EngagementTable: React.FunctionComponent<EngagementTableProps> =
  ({ screener, weeklyMetrics, weeklyAveragePain }) => (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Week</Table.HeaderCell>
          <Table.HeaderCell>Active?</Table.HeaderCell>
          <Table.HeaderCell>Point Goal</Table.HeaderCell>
          <Table.HeaderCell>Edu</Table.HeaderCell>
          <Table.HeaderCell>Pacing</Table.HeaderCell>
          <Table.HeaderCell>Aerobic</Table.HeaderCell>
          <Table.HeaderCell>Average Pain</Table.HeaderCell>
          <Table.HeaderCell>All-Time Pain</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {weeklyMetrics.toSeq().sort(sortWeeklyMetricReverseWeek).valueSeq().map((m, i) => {
          if (m === undefined || !isNumber(m.week_index)) return null;
          const averagePainScore: number | undefined = weeklyAveragePain[m.week_index];
          const allTimePain = (screener.pain && averagePainScore) ? averagePainScore - screener.pain : NaN;
          return (
            <Table.Row key={i}>
              <Table.Cell>Week {m.week_index}</Table.Cell>
              <Table.Cell negative={!m.active}>{m.active ? 'Yes' : 'No'}</Table.Cell>
              <Table.Cell
                positive={!!m.points_earned && !!m.points_goal && m.points_earned >= m.points_goal}
                negative={m.points_earned === 0}>
                {m.points_earned}/{m.points_goal}
              </Table.Cell>
              <Table.Cell
                positive={!!m.education_reads && !!m.education_reads_goal &&
                m.education_reads >= m.education_reads_goal}
                negative={m.education_reads === 0}>
                {m.education_reads}/{m.education_reads_goal}
              </Table.Cell>
              <Table.Cell
                positive={!!m.exercises && !!m.exercises_goal && m.exercises >= m.exercises_goal}
                negative={m.exercises === 0}>
                {m.exercises}/{m.exercises_goal}
              </Table.Cell>
              <Table.Cell
                positive={
                  !!m.aerobic_workouts && !!m.aerobic_workouts_goal && m.aerobic_workouts >= m.aerobic_workouts_goal}
                negative={m.aerobic_workouts === 0}>
                {m.aerobic_workouts}/{m.aerobic_workouts_goal}
              </Table.Cell>
              <Table.Cell>{averagePainScore}</Table.Cell>
              <Table.Cell positive={allTimePain < -20} negative={allTimePain > 20}>
                {formatDiff(allTimePain)}
              </Table.Cell>
            </Table.Row>
          );
        })}
        <Table.Row>
          <Table.Cell>Screener</Table.Cell>
          <Table.Cell>–</Table.Cell>
          <Table.Cell>–</Table.Cell>
          <Table.Cell>–</Table.Cell>
          <Table.Cell>–</Table.Cell>
          <Table.Cell>–</Table.Cell>
          <Table.Cell>{screener.pain}</Table.Cell>
          <Table.Cell>–</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );

export default EngagementTable;
