import { TypedRecord, recordify } from 'typed-immutable-record';
import { Map } from 'immutable';
import { EntityKind } from '../../api-client/parsers';
import * as R from 'ramda';
import { RootAction } from '../actions';
import { UPDATE_TASK_ORDER, COMPLETE_TASK, REJECT_TASK } from '../constants/index';
import { TaskStatuses } from '../../api-client';

export type TaskEntityState = Map<TaskStatuses, number[]>;

interface EntitiesState {
  [EntityKind.TASKS]: TaskEntityState;
}

const entitiesDefaults: EntitiesState = {
  tasks: Map()
};

const updateTaskOrderByStatus = (
  state: TaskEntityState,
  status: TaskStatuses,
  taskList: number[]
): TaskEntityState => state.set(status, taskList);

export const addTaskToStatus = (status: TaskStatuses, taskId: number) => (
  state: TaskEntityState
) => state.update(status, (taskList?: number[]) => taskList ? taskList.concat([taskId]) : [taskId]);

export const removeTaskFromStatus = (status: TaskStatuses, taskId: number) => (
  state: TaskEntityState
) => state.update(status, (taskList?: number[]) =>
  taskList ? taskList.filter(id => id !== taskId) : []
);

export interface State extends TypedRecord<State>, EntitiesState {}

export const initialState = recordify<EntitiesState, State>(entitiesDefaults);

export const reducer = (state = initialState, action: RootAction): State => {
  switch (action.type) {
    case UPDATE_TASK_ORDER:
      const { status, orderBy } = action.payload;
      if (!status) return state;
      return state.set(
        EntityKind.TASKS,
        updateTaskOrderByStatus(state.tasks, status, orderBy)
      );
    case COMPLETE_TASK: {
      if (!action.payload.id) return state;
      const { id } = action.payload;

      return state.withMutations(s => {
        s.update(EntityKind.TASKS, (entityTasks: TaskEntityState) =>
          entityTasks.withMutations(mutableTasks => {
            R.compose(
              addTaskToStatus(TaskStatuses.Completed, id),
              removeTaskFromStatus(TaskStatuses.Pending, id)
            )(mutableTasks);
          })
        );
      });
    }
    case REJECT_TASK: {
      if (!action.payload.id) return state;
      const { id } = action.payload;

      return state.withMutations(s => {
        s.update(EntityKind.TASKS, (entityTasks: TaskEntityState) =>
          entityTasks.withMutations(mutableTasks => {
            R.compose(
              addTaskToStatus(TaskStatuses.Rejected, id),
              removeTaskFromStatus(TaskStatuses.Pending, id)
            )(mutableTasks);
          })
        );
      });
    }
    default:
      return state;
  }
};
