import * as React from 'react';
import * as moment from 'moment-timezone';
import styled from 'styled-components';
import { Box, Text } from 'grommet';
import { Popup, Divider, Label } from 'semantic-ui-react';
import { StatusNames } from '../../api-client/interfaces';
import { UserStatus } from '../../graphql/models';
import { NameOptions } from './UserStatusForm';
import { RED, BACKGROUND_RED, LABEL_GRAY } from '../../colors';

interface UserStatusLabelProps {
  status?: UserStatus | null;
}

export const StyledLabelText = styled(Text)`
  font-size: 14px;
`;

export const StatusIcons: { [key in StatusNames]: string } = {
  [StatusNames.vacation]: '🌴',
  [StatusNames.sick]: '🤒',
  [StatusNames.family_emergency]: '⚠️',
  [StatusNames.technical_issue]: '🔧',
  [StatusNames.clinical_issue]: '🏥',
  [StatusNames.other]: '❗️'
};

const isUpcoming = (date: moment.Moment): boolean =>
  moment().diff(date, 'days') < 0;

const isExpired = (date: moment.Moment): boolean =>
  moment().diff(date, 'days') > 0;

const UserStatusTrigger: React.FC<{ status: UserStatus }> = props => {
  const { name, startsOn, endsOn } = props.status;

  if (startsOn && isUpcoming(moment(startsOn))) {
    return (
      <Box
        style={{ filter: 'grayscale(1)', opacity: '0.8', color: LABEL_GRAY }}
        {...props}
      >
        <StyledLabelText>{name && StatusIcons[name]} Upcoming</StyledLabelText>
      </Box>
    );
  }

  if (endsOn && isExpired(moment(endsOn))) {
    return (
      <Box flex data-testid="user-status-icon-expired" direction="row" {...props}>
        <StyledLabelText>{name && StatusIcons[name]}</StyledLabelText>
        <Label
          size="small"
          style={{
            color: RED,
            backgroundColor: BACKGROUND_RED,
            height: '24px'
          }}
          content="Expired"
        />
      </Box>
    );
  }

  return (
    <Box data-testid="user-status-icon-default" {...props}>
      <StyledLabelText>
        {name && StatusIcons[name]} {endsOn && moment(endsOn).format('MMM D')}
      </StyledLabelText>
    </Box>
  );
};

const UserStatusContent: React.FC<{ status: UserStatus }> = ({ status }) => {
  const { name, startsOn, endsOn, description } = status;

  return (
    <Box data-testid="user-status-content">
      <b>{name && NameOptions[StatusNames[name]]}</b>
      <div>
        {startsOn &&
          endsOn &&
          `${moment(startsOn).format('MMM DD')} - ${moment(endsOn).format(
            'MMM DD'
          )}`}
      </div>
      <Divider hidden />
      <div>{description}</div>
    </Box>
  );
};

const UserStatusLabel: React.FC<UserStatusLabelProps> = ({ status }) =>
  status ? (
    <Popup
      trigger={<UserStatusTrigger status={status} />}
      position="bottom center"
    >
      <Popup.Content>
        <UserStatusContent status={status} />
      </Popup.Content>
    </Popup>
  ) : null;

export default UserStatusLabel;
