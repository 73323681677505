import { TypedRecord, makeTypedFactory } from 'typed-immutable-record';
import { Map } from 'immutable';
import * as R from 'ramda';
import * as initTrace from 'debug';

import { User, UserMessagingMetaData } from '../../api-client';
import { TAG_NAMES } from '../../components/phx/tasks/Tag';

import { RootAction } from '../actions';
import { ERR_TAG_NO_USER } from '../../errors';
import {
  ENTITIES_FETCHED,
  ADD_USER_TAG,
  REMOVE_USER_TAG,
  POST_GOALS,
  DELETE_GOAL,
  PUT_USER_NAME,
  PUT_USER_CONTACT_INFO,
  PREPEND_OLD_USER_MESSAGES,
  APPEND_NEW_USER_MESSAGES,
  SET_USER_HAS_UNREAD,
  UPDATE_USER_ENROLLMENT_STATUS
} from '../constants';
import {
  createEntityMap,
  createSingleEntityMap,
  mergeUpdate
} from '../../utils/entity';

import { ValueOf } from '../../utils/type-helpers';

const debug = initTrace('ca:redux:reducers:user');

/** Not a complete list, just the common ones to suggest in a dropdown. */
export const PERSONALITY_TAGS: ValueOf<typeof TAG_NAMES>[] = [
  TAG_NAMES.disengager,
  TAG_NAMES.surgery,
  TAG_NAMES.yearTwo,
  TAG_NAMES.weight,
  TAG_NAMES.extrinsic,
  TAG_NAMES.depression,
  TAG_NAMES.anxiety,
  TAG_NAMES.passiveCoping,
  TAG_NAMES.fearAvoid,
  TAG_NAMES.noMarketing,
  TAG_NAMES.houseCallScheduled,
  TAG_NAMES.houseCallComplete,
  TAG_NAMES.popUpVisitCompleted
];

export const SPLIT_LOWMEDTIER_DROPDOWN_TAGS: ValueOf<typeof TAG_NAMES>[] = [
  TAG_NAMES.expLowTier,
  TAG_NAMES.expMedTier
];

export interface UserRecord extends TypedRecord<UserRecord>, User {
  messaging: UserMessagingMetaData;
}

export const createUserRecord = makeTypedFactory<User, UserRecord>({
  id: NaN,
  addressOne: undefined,
  addressTwo: undefined,
  avatarUrl: undefined,
  bio: undefined,
  bmi: undefined,
  city: undefined,
  client: undefined,
  communicationPreferences: undefined,
  contactMethod: undefined,
  country: undefined,
  currentWeek: undefined,
  dateOfBirth: undefined,
  email: undefined,
  engagementStreak: undefined,
  firstName: undefined,
  gender: undefined,
  goals: undefined,
  height: undefined,
  lastActive: undefined,
  lastSignInAt: undefined,
  lastName: undefined,
  lastNps: undefined,
  lastActivityAt: undefined,
  lastTaskResolvedAt: undefined,
  messaging: {
    messageUuids: [],
    totalPages: undefined,
    page: undefined,
    hasUnread: false
  },
  messagePreview: undefined,
  occupation: undefined,
  painLogs: undefined,
  pathways: undefined,
  phoenix: undefined,
  phoneNumber: undefined,
  postalCode: undefined,
  screener: undefined,
  stateOfResidence: undefined,
  surgeryLogs: undefined,
  tags: undefined,
  tasks: undefined,
  team: undefined,
  timezone: undefined,
  timezoneId: undefined,
  weekIndex: undefined,
  weeklyMetrics: undefined,
  weight: undefined,
  workouts: undefined,
  conversationIds: undefined,
  vacationInfo: { vacationStartDate: undefined, vacationEndDate: undefined },
  userStatus: { userId: undefined, startsOn: undefined, endsOn: undefined },
  teamId: undefined,
  uuid: undefined,
  enrollmentStatus: undefined
});

export type State = Map<number, UserRecord>;

export const initialState: State = Map();

const mergeIntoSet = (set: Set<string>, values: string[]) => {
  values.forEach((uuid) => {
    if (set.has(uuid)) {
      set.delete(uuid);
    }
    set.add(uuid);
  });
};

const prependMessageUuids = (user: UserRecord, messageUuids: string[]) => {
  const currentUserMessagingUuids = user.messaging.messageUuids;
  const mergedMessageUuids = new Set<string>();

  // Prepending messages occurs when we've scrolled up in the message list.
  // we return messages in descending time order, so we need to reverse it.
  const reversedMessageUuids = [...messageUuids].reverse();
  reversedMessageUuids.forEach(uuid => mergedMessageUuids.add(uuid));

  mergeIntoSet(mergedMessageUuids, currentUserMessagingUuids);

  const mergedMessagingObj = { ...user.messaging, messageUuids: Array.from(mergedMessageUuids) };
  return user.set('messaging', mergedMessagingObj);
};

const appendMessageUuids = (user: UserRecord, messageUuids: string[]) => {
  const currentUserMessagingUuids = user.messaging.messageUuids;
  const mergedMessageUuids = new Set<string>();

  currentUserMessagingUuids.forEach(uuid => mergedMessageUuids.add(uuid));

  mergeIntoSet(mergedMessageUuids, messageUuids);

  const mergedMessagingObj = { ...user.messaging, messageUuids: Array.from(mergedMessageUuids) };
  return user.set('messaging', mergedMessagingObj);
};

const updateHasUnread = (user: UserRecord, hasUnread: boolean) => {
  const updatedMessagingObj = { ...user.messaging, hasUnread };
  return user.set('messaging', updatedMessagingObj);
};

export const reducer = (state = initialState, action: RootAction) => {
  let tags: string[];

  switch (action.type) {
    case ADD_USER_TAG: {
      if (!action.payload.user) throw new Error(ERR_TAG_NO_USER);
      tags = R.uniq(
        action.payload.user.get('tags', []).concat([action.payload.tag])
      );
      debug('adding tag', action.payload.tag, tags);
      return mergeUpdate(
        state,
        createSingleEntityMap(
          createUserRecord,
          action.payload.user.set('tags', tags)
        )
      );
    }
    case REMOVE_USER_TAG: {
      if (!action.payload.user) throw new Error(ERR_TAG_NO_USER);
      tags = R.without(
        [action.payload.tag],
        action.payload.user.get('tags', [])
      );
      debug('removing tag', action.payload.tag, tags);
      return mergeUpdate(
        state,
        createSingleEntityMap(
          createUserRecord,
          action.payload.user.set('tags', tags)
        )
      );
    }
    case ENTITIES_FETCHED: {
      return action.payload.entities.users
        ? mergeUpdate(
          state,
          createEntityMap(createUserRecord, action.payload.entities.users)
        )
        : state;
    }
    case POST_GOALS: {
      const user = action.payload.user;
      if (!user) return state;
      return mergeUpdate(
        state,
        createSingleEntityMap(
          createUserRecord,
          user.set('goals', [action.payload.goalId, ...user.get('goals', [])])
        )
      );
    }
    case DELETE_GOAL: {
      const userToDeleteGoal = action.payload.user;
      const deletedUserGoals = userToDeleteGoal.goals
        ? R.without([action.payload.goalId], userToDeleteGoal.goals)
        : [];
      return action.payload.goalId
        ? mergeUpdate(
          state,
          createSingleEntityMap(
            createUserRecord,
            userToDeleteGoal.set('goals', [...deletedUserGoals])
          )
        )
        : state;
    }
    case PUT_USER_NAME: {
      if (!action.payload.editedUser) return state;

      return mergeUpdate(state, createSingleEntityMap(createUserRecord, action.payload.editedUser));
    }
    case PUT_USER_CONTACT_INFO: {
      if (!action.payload.editedUser) return state;

      return mergeUpdate(state, createSingleEntityMap(createUserRecord, action.payload.editedUser));
    }
    case PREPEND_OLD_USER_MESSAGES: {
      const { user, messageUuids } = action.payload;
      if (!user) {
        return state;
      }

      const updatedUserRecord = prependMessageUuids(user, messageUuids);
      return mergeUpdate(state, createSingleEntityMap(createUserRecord, updatedUserRecord));
    }
    case APPEND_NEW_USER_MESSAGES: {
      const { user, messageUuids } = action.payload;
      if (!user) {
        return state;
      }

      const updatedUserRecord = appendMessageUuids(user, messageUuids);
      return mergeUpdate(state, createSingleEntityMap(createUserRecord, updatedUserRecord));
    }
    case SET_USER_HAS_UNREAD: {
      const { user, hasUnread } = action.payload;
      if (!user) return state;

      const updatedUserRecord = updateHasUnread(user, hasUnread);
      return mergeUpdate(
        state,
        createSingleEntityMap(
          createUserRecord,
          updatedUserRecord
        )
      );
    }
    case UPDATE_USER_ENROLLMENT_STATUS: {
      const { user, enrollmentStatus } = action.payload;
      return mergeUpdate(
        state,
        createSingleEntityMap(
          createUserRecord,
          user.set('enrollmentStatus', enrollmentStatus)
        )
      );
    }
    default:
      return state;
  }
};
