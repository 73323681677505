import * as React from 'react';
import { connect } from 'react-redux';
import { WeeklyMetric, User, Workout } from '../../api-client';
import { userActions } from '../../redux/actions';
import { selectWeeklyMetrics, selectUser } from '../../redux/selectors';
import { RootState } from '../../redux/reducers';
import { DispatchMap } from '../../redux/actions/interfaces';
import { Map } from 'immutable';
import ExerciseChart from '../../components/charts/ExerciseChart';
import { Segment, Header } from 'semantic-ui-react';
import ExerciseTable from '../../components/charts/ExerciseTable';
import { Store } from 'redux';
import { selectWorkoutsByUser } from '../../redux/selectors/workout';
import { generateWeekRange } from '../../utils/number';
import { DEFAULT_TIMEZONE } from '../../utils/user/timezone';

interface ExerciseStatsSegmentProps {
  store?: Store<RootState>;
  userId: number;
}

interface StateProps {
  user: User;
  weeklyMetrics: Map<string, WeeklyMetric>;
  workouts: Workout[];
}

type DispatchProps = DispatchMap<{
  getPathwayStats: typeof userActions.getPathwayStats;
}>;

type Props = ExerciseStatsSegmentProps & StateProps & DispatchProps;

class ExerciseStatsSegment extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.getPathwayStats(this.props.userId);
  }

  render() {
    if (!this.props.user) return null;
    const headerText = this.props.user.firstName ? `${this.props.user.firstName}'s Exercise Therapy` : undefined;
    const weekRange = this.props.user.team ?
      generateWeekRange(this.props.user.team.currentTeamWeekNumber || 0) : [0, 13];
    return (
      <Segment>
        <Header>{headerText}</Header>
        <ExerciseChart
          startWeek={weekRange[0]}
          endWeek={weekRange[1]}
          weeklyMetrics={this.props.weeklyMetrics}
          user={this.props.user}
          workouts={this.props.workouts}
        />
        <ExerciseTable timezone={this.props.user.timezone || DEFAULT_TIMEZONE} workouts={this.props.workouts} />
      </Segment>
    );
  }
}

const mapStateToProps = (state: RootState, props: ExerciseStatsSegmentProps): StateProps => ({
  user: selectUser(state, props),
  weeklyMetrics: selectWeeklyMetrics(state),
  workouts: selectWorkoutsByUser(state, props)
});

const mapDispatchToProps = {
  getPathwayStats: userActions.getPathwayStats
};

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseStatsSegment);
