import * as React from 'react';
import styled from 'styled-components';
import Check from '@material-ui/icons/Check';
import Problem from '@material-ui/icons/ReportProblemOutlined';
import {
  SUCCESS_ICON,
  SUCCESS_TEXT,
  WARNING_DARK,
  WARNING_TEXT_ONE
} from '../../../colors';
import { User } from '../../../graphql/models';
import { getUserETSessionStatus } from '../../../utils/user/etSessionStatus';

const iconMargin = '0px 6px -5px 0px';

const CompletedIcon = styled(Check)`
  color: ${SUCCESS_ICON};
  margin: ${iconMargin};
`;

const CompletedText = styled.span`
  color: ${SUCCESS_TEXT};
`;

const IndentedText = styled.span`
  padding-left: 27px;
`;

const StartedIcon = styled(Problem)`
  color: ${WARNING_DARK};
  margin: ${iconMargin};
`;

const StartedText = styled.span`
  color: ${WARNING_TEXT_ONE};
`;

interface StatusIconParams {
  status?: string | null;
  users: User[];
}

const hasIcons = (users: User[]) =>
  users.some(u => getUserETSessionStatus(u.etSessions) === 'Complete' ||
    getUserETSessionStatus(u.etSessions) === 'Started'
  );


const statusIcon = ({ status, users }: StatusIconParams) => {
  switch (status) {
    case 'Complete':
      return <CompletedText>
        <CompletedIcon />
        {status}
      </CompletedText>;
    case 'Started':
      return <StartedText>
        <StartedIcon />
        {status}
      </StartedText>;
    default:
      return hasIcons(users) ?
        <IndentedText>{status}</IndentedText> :
        <span>
          {status}
        </span>;
  }
};

interface FirstETStatusProps {
  status?: string | null;
  users: User[];
}

const FirstETStatus: React.FC<FirstETStatusProps> = ({ status, users }) =>
  statusIcon({ status, users });

export default FirstETStatus;
