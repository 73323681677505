import { TypedRecord, makeTypedFactory } from 'typed-immutable-record';
import { Map } from 'immutable';

import { Workout } from '../../api-client';
import { RootAction } from '../actions';
import { ENTITIES_FETCHED } from '../constants';
import { createStringEntityMap, mergeUpdate } from '../../utils/entity';

export interface WorkoutRecord extends TypedRecord<WorkoutRecord>, Workout {}

export const createWorkoutRecord = makeTypedFactory<Workout, WorkoutRecord>({
  id: '',
  name: undefined,
  points_earned: undefined,
  points_max: undefined,
  updated_at: undefined,
  occurred_at: undefined,
  week_index: undefined
});

export type State = Map<string, WorkoutRecord>;

export const initialState: State = Map();

export const reducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case ENTITIES_FETCHED:
      return action.payload.entities.workouts
        ? mergeUpdate(
          state,
          createStringEntityMap(
            createWorkoutRecord,
            action.payload.entities.workouts
          )
        )
        : state;
    default:
      return state;
  }
};
