import * as sanitizeHtml from 'sanitize-html';

export const sanitizeOptions: sanitizeHtml.IOptions = {
  allowedTags: [],
  allowedAttributes: {},
  nonTextTags: ['style', 'script', 'textarea', 'noscript']
};

export const messagePreviewOptions: sanitizeHtml.IOptions = {
  allowedTags: [],
  allowedAttributes: {}
};
