import * as React from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { RootState, TeamRecord } from '../../redux/reducers';
import {
  selectTeam,
  selectTodoTeamUserIds
} from '../../redux/selectors';
import TodoUserListItem from './TodoUserListItem';
import './TodoTeamList.scss';
import { v4 as uuid } from 'uuid';

interface TodoTeamListProps {
  teamId: number;
  selectedUserId?: number;
  todo: string;
  setSelectedUserId(userId: number): void;
  setSelectedConversationId(conversationId: string): void;
}

interface ConnectProps {
  team: TeamRecord;
  userIdsByTeam: number[];
}

type Props = TodoTeamListProps & ConnectProps;


const TodoTeamList: React.FC<Props> = ({
  team,
  userIdsByTeam,
  selectedUserId,
  setSelectedUserId,
  setSelectedConversationId,
  todo
}) => (
  <div className='team-container'>
    <div className='team-container__info'>
      <span className='team-container__info__name'>{team.name}</span>
      <span className='team-container__info__week'>{`Wk ${team.currentTeamWeekNumber}`}</span>
    </div>
    <div>
      {userIdsByTeam.map((userId) => (
        <TodoUserListItem
          key={uuid()}
          userId={userId}
          todo={todo}
          selectedUserId={selectedUserId}
          setSelectedUserId={setSelectedUserId}
          setSelectedConversationId={setSelectedConversationId}
        />
      ))}
    </div>
  </div>
);


const mapStateToProps = (
  state: RootState,
  { teamId, todo }: TodoTeamListProps
) => ({
  team: selectTeam(state, { teamId }),
  userIdsByTeam: selectTodoTeamUserIds(state, { teamId, todo })
});
export default connect(mapStateToProps)(React.memo(TodoTeamList, isEqual));
