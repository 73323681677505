import { RootState } from '../reducers';
import { TaskKind, MassMessageTemplate, TemplateTodoMap, SendMassMessageUser } from '../../api-client/';
import { createSelector } from 'reselect';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';
import {
  communicationsServiceMessaging,
  nonOnboardedUsersView,
  kitDeliveredNoEt,
  ensoYearTwo,
  lowMedTierTags,
  useNewMessagingBffEndpoint,
  memberAlertsCutover,
  useNewMessagingBffPostMessageEndpoint,
  useNewMessagingBffMarkAsReadEndpoint,
  useNewMessagingBffConversationsEndpoint,
  useNewMessagingBffUpdateMessageEndpoint,
  useNewMessagingBffDeleteMessageEndpoint,
  readyForCoaching,
  healthLogNotesFilterFlag,
  painUptickFilterFlag,
  zoomPhoneCallEnabled,
  redirectCttToCtp
} from '../../features';
import { filterUserIdsByTeamId, selectUser } from './user';
import { selectUserConversationItem, selectConversation } from './conversations';
import { isNumber, isNullOrUndefined } from 'util';
import { uniq } from 'ramda';
import * as moment from 'moment-timezone';

export const selectTodoTeamIds = (state: RootState, todo: string): number[] =>
  (state.ui.taskKindResults && state.ui.taskKindResults[todo] && state.ui.taskKindResults[todo].teamIds) || [];

export const selectTodoUserIds = (state: RootState, todo: string) =>
  state.ui.taskKindResults && state.ui.taskKindResults[todo] && state.ui.taskKindResults[todo].userIds;

export const selectCountByTaskKind = (state: RootState, kind: string) => state.ui.taskCounts[kind];

export const selectNotification = (state: RootState) => state.ui.notification;

export const selectUserCountsByTaskKind = (state: RootState, kind: string) => state.ui.taskKind[kind];

export const selectUserCountByTaskKind = (
  state: RootState,
  props: { kind?: TaskKind | string | null; userId?: number | null }
) => {
  const { kind, userId } = props;
  if (!kind || !userId) return undefined;
  return state.ui.taskKind && state.ui.taskKind[kind] && state.ui.taskKind[kind][userId];
};

export const showTodoRefresh = (state: RootState, kind: TaskKind | string) => state.ui.showTodoRefresh[kind];

export const isTaskUserComplete = createSelector([selectUserCountByTaskKind], taskKindUserCount => {
  if (taskKindUserCount === undefined) return false;

  return taskKindUserCount === 0;
});

export const selectHasMoreTasks = (state: RootState, kind: TaskKind | string) => state.ui.hasMoreTasks[kind];

export const selectUserTasksCount = (state: RootState, userId: number) => {
  let totalCount = 0;

  if (!state.ui || !state.ui.taskKind) return totalCount;

  Object.keys(state.ui.taskKind).forEach(kind => {
    if (kind !== 'unreadMessage' && state.ui.taskKind[kind][userId]) {
      if (kind === 'HealthLogNote' && selectTreatmentValue(state.splitReducer, healthLogNotesFilterFlag) !== 'on') {
        return;
      }
      if (kind === 'PainUptick' && selectTreatmentValue(state.splitReducer, painUptickFilterFlag) !== 'on') {
        return;
      }
      totalCount += state.ui.taskKind[kind][userId];
    }
  });
  return totalCount;
};

export const selectTodoConversationIds = (state: RootState, todo: string) =>
  state.ui.taskKindResults && state.ui.taskKindResults[todo] && state.ui.taskKindResults[todo].conversationIds;

/**
 * This selector takes a team id and a todo's conversation ids (presorted by most recent to oldest
 * by the backend), a returns a list of conversations' associated user ids. We filter out duplicate
 * ids as multiple conversations can have the same user.
 */
export const filterTodoUserIdsByTeamConversationIds = (
  state: RootState,
  { todo, teamId }: { todo: string; teamId: number }
) => {
  const todoConversationIds = selectTodoConversationIds(state, todo);

  if (!todoConversationIds) return [];

  const filteredTeamUserIds = todoConversationIds
    .filter(conversationId => selectConversation(state, { conversationId })?.teamId === teamId)
    .map(conversationId => selectConversation(state, { conversationId })?.userId)
    .filter(isNumber);

  return uniq(filteredTeamUserIds);
};

export const selectTodoTeamUserIds = (state: RootState, { todo, teamId }: { todo: string; teamId: number }) =>
  todo === 'unreadMessage'
    ? filterTodoUserIdsByTeamConversationIds(state, { todo, teamId })
    : filterUserIdsByTeamId(state, { userIds: selectTodoUserIds(state, todo), teamId });

export const selectFirstUserTodo = (state: RootState, todo: string) => {
  const teamIds = selectTodoTeamIds(state, todo);

  if (!teamIds || !teamIds.length) return undefined;

  const firstTeamUserIds = selectTodoTeamUserIds(state, { todo, teamId: teamIds[0] });

  return selectUserConversationItem(state, { userId: firstTeamUserIds[0] });
};

export const hasActiveStatus = createSelector(
  selectUser,
  user => user.userStatus && user.userStatus.endsOn && moment().diff(user.userStatus.endsOn, 'days') <= 0
);

export const hasCompletedTaskThisWeek = (date?: string | null) => {
  if (isNullOrUndefined(date)) return false;
  const dateMs = new Date(date).getTime();
  const dateOneWeekAgoMs = Date.now() - 7 * 24 * 3600 * 1000;

  return dateMs > dateOneWeekAgoMs;
};

const is13PlusInactiveFor4WeeksUser = (state: RootState, userId: number) => {
  const { teams, user } = state;
  const currentWeek =
    user.get(userId).teamId && teams.find(t => (t ? t.id === user.get(userId).teamId : false)).currentTeamWeekNumber;
  return currentWeek && currentWeek >= 13;
};

export const isSecondMassMessageUser = createSelector(selectUser, user =>
  hasCompletedTaskThisWeek(user.lastTaskResolvedAt)
);

export const isNeverActiveUser = createSelector(selectUser, user => isNullOrUndefined(user.lastActivityAt));

export const groupTemplateUserIds = (state: RootState, template: MassMessageTemplate) => {
  const todoUserIds = selectTodoUserIds(state, TemplateTodoMap[template]);
  if (!todoUserIds) return [];

  const userIdsWithoutStatus = todoUserIds.filter(userId => !hasActiveStatus(state, { userId }));

  switch (template) {
    case MassMessageTemplate.Week13PlusInactiveFor4Weeks:
      return userIdsWithoutStatus.filter(
        userId => !isSecondMassMessageUser(state, { userId }) && is13PlusInactiveFor4WeeksUser(state, userId)
      );
    case MassMessageTemplate.Week4InactiveFirst:
      return userIdsWithoutStatus.filter(
        userId => !isSecondMassMessageUser(state, { userId }) && !is13PlusInactiveFor4WeeksUser(state, userId)
      );
    case MassMessageTemplate.Week4InactiveSecond:
      return userIdsWithoutStatus.filter(userId => isSecondMassMessageUser(state, { userId }));
    case MassMessageTemplate.Week5NeverActive:
      return userIdsWithoutStatus.filter(userId => isNeverActiveUser(state, { userId }));
    case MassMessageTemplate.Week5PreviouslyActive:
      return userIdsWithoutStatus.filter(userId => !isNeverActiveUser(state, { userId }));
    default:
      return [];
  }
};

export const selectTemplateUserIds = (state: RootState, template: MassMessageTemplate) =>
  state.ui.templateUserIds && state.ui.templateUserIds[template];

export const selectTodoTemplates = (state: RootState, todo: TaskKind) =>
  state.ui.templateUserIds &&
  (Object.keys(state.ui.templateUserIds).filter(
    template => TemplateTodoMap[template] === todo
  ) as MassMessageTemplate[]);

export const selectModalOpen = (state: RootState) => state.ui.modalOpen;
export const selectSendMassMessageUsers = (
  state: RootState,
  template: MassMessageTemplate
): SendMassMessageUser[] | undefined => {
  const userIds = selectTemplateUserIds(state, template);

  if (!userIds || !userIds.length) return undefined;

  return userIds.map(userId => {
    const user = selectUser(state, { userId });
    return {
      userUuid: user.uuid,
      userPhoneNumber: user.phoneNumber,
      interpolations: [{ name: 'user.first_name', value: user.firstName }]
    };
  });
};

export const selectIncompleteTemplates = (state: RootState, todo: TaskKind) => {
  const todoTemplates = selectTodoTemplates(state, todo);

  return todoTemplates.filter(t => state.ui.templateUserIds[t]?.length);
};

export const selectFeatureFlags = (state: RootState) => {
  const featureFlags = new Map();

  featureFlags.set(
    communicationsServiceMessaging,
    selectTreatmentValue(state.splitReducer, communicationsServiceMessaging) === 'on'
  );
  featureFlags.set(nonOnboardedUsersView, selectTreatmentValue(state.splitReducer, nonOnboardedUsersView) === 'on');
  featureFlags.set(TaskKind.kitDeliveredNoEt, selectTreatmentValue(state.splitReducer, kitDeliveredNoEt) === 'on');
  featureFlags.set(TaskKind.ensoYearTwo, selectTreatmentValue(state.splitReducer, ensoYearTwo) === 'on');
  featureFlags.set(TaskKind.readyForCoaching, selectTreatmentValue(state.splitReducer, readyForCoaching) === 'on');
  featureFlags.set(lowMedTierTags, selectTreatmentValue(state.splitReducer, lowMedTierTags) === 'on');
  featureFlags.set(
    useNewMessagingBffEndpoint,
    selectTreatmentValue(state.splitReducer, useNewMessagingBffEndpoint) === 'on'
  );
  featureFlags.set(memberAlertsCutover, selectTreatmentValue(state.splitReducer, memberAlertsCutover) === 'on');
  featureFlags.set(
    useNewMessagingBffPostMessageEndpoint,
    selectTreatmentValue(state.splitReducer, useNewMessagingBffPostMessageEndpoint) === 'on'
  );
  featureFlags.set(
    useNewMessagingBffMarkAsReadEndpoint,
    selectTreatmentValue(state.splitReducer, useNewMessagingBffMarkAsReadEndpoint) === 'on'
  );
  featureFlags.set(
    useNewMessagingBffConversationsEndpoint,
    selectTreatmentValue(state.splitReducer, useNewMessagingBffConversationsEndpoint) === 'on'
  );
  featureFlags.set(
    useNewMessagingBffUpdateMessageEndpoint,
    selectTreatmentValue(state.splitReducer, useNewMessagingBffUpdateMessageEndpoint) === 'on'
  );
  featureFlags.set(
    useNewMessagingBffDeleteMessageEndpoint,
    selectTreatmentValue(state.splitReducer, useNewMessagingBffDeleteMessageEndpoint) === 'on'
  );
  featureFlags.set(
    healthLogNotesFilterFlag,
    selectTreatmentValue(state.splitReducer, healthLogNotesFilterFlag) === 'on'
  );
  featureFlags.set(painUptickFilterFlag, selectTreatmentValue(state.splitReducer, painUptickFilterFlag) === 'on');

  featureFlags.set(zoomPhoneCallEnabled, selectTreatmentValue(state.splitReducer, zoomPhoneCallEnabled) === 'on');

  featureFlags.set(redirectCttToCtp, selectTreatmentValue(state.splitReducer, redirectCttToCtp) === 'on');

  return featureFlags;
};

export const selectUserIdsForTeam = (state: RootState, teamId: number) => state.ui.teamResults[teamId]?.userIds;

export const selectOnboardedUsers = (state: RootState) => state.ui.onboardedUsers;

export const selectInCtpIframe = (state: RootState) => state.ui.inCtpIframe;
