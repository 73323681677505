import * as moment from 'moment-timezone';
import { EtSession as ET } from 'src/graphql/models/bff/EtSession';

export const sortByDate = (a: ET, b: ET) => {
  if (!a.startedAt) {
    if (b.startedAt) {
      return -1;
    }

    return 0;
  } else if (!b.startedAt) {
    if (a.startedAt) {
      return 1;
    }

    return 0;
  }

  return moment(a.startedAt).isBefore(moment(b.startedAt)) ? 1 : -1;
};
