import * as React from 'react';
import { Box } from 'grommet';
import {
  ScreenerRow,
  ScreenerCell,
  ScreenerText,
  ScreenerField
} from '../../containers/communications/ScreenerManager';
import { PerisurgicalDetail } from '../../graphql/models/bff/PerisurgicalDetail';

export interface PerisurgicalDetailsProps {
  perisurgicalDetails: PerisurgicalDetail[];
}

export const SurgeryTypeMap: { [key: string]: string } = {
  total_knee_replacement: 'Total knee replacement',
  revision_knee_replacement: 'Revision knee replacement',
  partial_knee_replacement: 'Partial knee replacement',
  acl_repair: 'ACL repair',
  pcl_repair: 'PCL repair',
  cartilage_repair: 'Cartilage repair',
  ligament_repair: 'Ligament repair',
  tendon_repair: 'Tendon repair',
  meniscus_surgery: 'Meniscus surgery',
  lateral_release: 'Lateral release',
  micro_fracture: 'Microfracture',
  other: 'Other'
};

const displayPerisurgicalTypes = (perisurgicalDetails: PerisurgicalDetail[]) =>
  perisurgicalDetails.map(detail => (
    <ScreenerCell>
      <Box direction='column' flex>
        <ScreenerText>{SurgeryTypeMap[detail.surgeryType]}</ScreenerText>
      </Box>
    </ScreenerCell>
  ));

const displayPerisurgicalDates = (perisurgicalDetails: PerisurgicalDetail[]) =>
  perisurgicalDetails.map(detail => {
    const surgeryDate = new Date(detail.surgeryDate);
    return (
      <ScreenerCell>
        <Box direction='column' flex>
          <ScreenerText>{surgeryDate.toLocaleDateString('en-us', { timeZone: 'utc' })}</ScreenerText>
        </Box>
      </ScreenerCell>
    );
  });

const PerisurgicalDetails: React.FC<PerisurgicalDetailsProps> = ({ perisurgicalDetails }) => (
  <>
    <ScreenerRow>
      <ScreenerField size='1/4'>Surgeries or procedures</ScreenerField>
      {displayPerisurgicalTypes(perisurgicalDetails)}
    </ScreenerRow>
    <ScreenerRow>
      <ScreenerField size='1/4'>Surgery or procedure date</ScreenerField>
      {displayPerisurgicalDates(perisurgicalDetails)}
    </ScreenerRow>
  </>
);

export default PerisurgicalDetails;
