export const GET_ALL_CONVERSATIONS = `
  query allConversations($adminUuid: String!) {
    allConversations(
        adminUuid: $adminUuid
      )
  }
`;

export const GET_CONVERSATIONS = `
  query conversations(
    $itemsPerPage: Int!,
    $userIds: [Int],
    $types: [String!]!,
    $page: Int!,
    $userUuids: [String],
    $adminUuid: String
  ) {
    conversations(
        userIds: $userIds,
        types: $types,
        itemsPerPage: $itemsPerPage,
        page: $page,
        userUuids: $userUuids,
        adminUuid: $adminUuid 
    ) 
  }
`;
