/** This module contains various math and number related utility functions. */

/** This could be stored as an array where
 * the numerical value is inferred by the index of the array.
 * But the lookup for an object property is faster so I found this
 * data structure to be appropriate.
 */
export const WORDS_TO_NUMBERS_MAP: { [key: string]: number } = {
  'zero': 0,
  'one': 1,
  'two': 2,
  'three': 3,
  'four': 4,
  'five': 5,
  'six': 6,
  'seven': 7,
  'eight': 8,
  'nine': 9,
  'ten': 10,
  'eleven': 11,
  'twelve': 12,
  'thirteen': 13,
  'fourteen': 14,
  'fifteen': 15,
  'sixteen': 16,
  'seventeen': 17,
  'eighteen': 18,
  'nineteen': 19,
  'twenty': 20
};

export function isNumber(a: unknown): a is number {
  return typeof a === 'number' && !isNaN(a);
}

export function formatDiff(a: number): string {
  if (isNaN(a)) {
    return '–';
  }
  return `${a <= 0 ? '' : '+'}${a}`;
}
/** Beyond Week 12, we generate the most recent 12 weeks (inclusive of last week). */
export function generateWeekRange(currentWeek: number): [number, number] {
  return currentWeek <= 12 ? [0, 13] : [currentWeek - 12, currentWeek + 1];
}

export function getRandomIndex(length: number): number {
  return Math.floor(Math.random() * length);
}

export function formatToInt(value: number): number {
  return Math.floor(value);
}

export function convertGramsToLbs(valueGrams: number): number {
  const weight_lbs = (valueGrams / 1000) * 2.2;
  return formatToInt(weight_lbs);
}

export function convertStrToNum(numString: unknown): number {
  return parseInt(`${numString}`, 10);
}

export function wordsToTensDigits(word: string): number {
  const lowerCased = word.toLocaleLowerCase();
  if (!(lowerCased in WORDS_TO_NUMBERS_MAP)) return NaN;
  return WORDS_TO_NUMBERS_MAP[lowerCased];
}
