import { useEffect } from 'react';
import { ApiClientNames } from '../../containers/apollo/ApolloContainer';
import { GET_PREGNANCY_DETAILS } from '../../graphql/queries/User';
import { useLazyQuery } from '@apollo/client';
import { Pathway } from '../../graphql/models/bff';
import { IndicationNameV3 } from '../../api-client';

export const useMaternityWeek = (pathway?: Pathway | null): { maternityWeek: number | null; isMaternity: boolean } => {
  const indicationName = pathway?.programIndication?.indicationName;
  const pathwayUuid = pathway?.uuid;
  const [getUserPregnancyDetails, { data }] = useLazyQuery(GET_PREGNANCY_DETAILS, {
    variables: { pregnancyDetailsGetInput: { pathwayUuid } },
    context: { clientName: ApiClientNames.NestBFF }
  });
  const isMaternity = indicationName === IndicationNameV3.PelvicMaternity;
  const maternityWeek: number = isMaternity ? data?.pregnancyDetailsGet?.maternityWeek : null;
  useEffect(() => {
    if (isMaternity) {
      getUserPregnancyDetails();
    }
  }, [getUserPregnancyDetails, indicationName]);
  return { maternityWeek, isMaternity };
};
