import { createSelector } from 'reselect';

import { RootState } from '../reducers';
import { createScreenerRecord } from '../reducers/screener';
import { selectUser } from './user';

export function selectScreeners(state: RootState) {
  return state.screener;
}

export const selectScreener = createSelector(
  selectScreeners, selectUser,
  (screeners, user) => user && user.screener ? screeners.get(user.screener) : createScreenerRecord({ id: NaN })
);
