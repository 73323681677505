import * as React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Location } from 'history';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { UserProfileRoutes } from '../../../utils/user/routes';
import { selectAdmin } from '../../../redux/selectors';
import { GET_ADMIN } from '../../../graphql/queries';
import { Admin } from '../../../api-client/interfaces';
import { isPtRole } from '../../../utils/admin/roles';
import { ApiClientNames } from '../../../containers/apollo/ApolloContainer';
import { PRIMARY } from '../../../colors';

export const getPathName = (location?: Location) =>
  location ? location.pathname : '';

const activeTab = (location: string) => {
  switch (true) {
    case location.includes(UserProfileRoutes.Engagement):
      return 1;
    case location.includes(UserProfileRoutes.History):
      return 2;
    case location.includes(UserProfileRoutes.CarePlans):
      return 3;
    case location.includes(UserProfileRoutes.StateRegulations):
      return 4;
    default:
      return 0;
  }
};

export interface UserNavBarProps {
  location?: Location;
  id: number;
}

export interface LinkTabProps {
  href: string;
  label: string;
}

const StyledTabs = styled(Tabs)`
  && {
    height: 48px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

const StyledTab = styled(Tab)`
  && {
    max-width: 120px;
  }
`;

const LinkTab = (props: LinkTabProps) => (
  <StyledTab
    // @ts-ignore (for type error from using Link as a component prop with styled components)
    to={props.href}
    component={Link}
    {...props}
  />
);

export const UserNavBar: React.FunctionComponent<UserNavBarProps> = props => {
  const adminId = useSelector((state: RootState) => selectAdmin(state).id);

  const adminRes = useQuery<{ admin: Admin }, { id: number }>(gql`${GET_ADMIN}`,
    {
      variables: { id: adminId },
      context: { clientName: ApiClientNames.BFF }
    }
  );

  return (
    <StyledTabs
      variant="fullWidth"
      value={activeTab(getPathName(props.location))}
      textColor="primary"
      TabIndicatorProps={{ style: { background: PRIMARY } }}
    >
      <LinkTab label="Overview" href={`/user/${props.id}`} />
      <LinkTab label="Pathway" href={`/user/${props.id}/${UserProfileRoutes.Engagement}`} />
      <LinkTab label="History" href={`/user/${props.id}/${UserProfileRoutes.History}`} />
      {adminRes?.data?.admin?.role && isPtRole(adminRes.data.admin.role) &&
          <LinkTab label="Care Plans" href={`/user/${props.id}/${UserProfileRoutes.CarePlans}`} />
      }
      <LinkTab label="State Regulations" href={`/user/${props.id}/${UserProfileRoutes.StateRegulations}`} />
    </StyledTabs>
  );
};

export default UserNavBar;
