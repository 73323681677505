import * as React from 'react';
import { connect } from 'react-redux';
import MassMessagingUserRow from './MassMessagingUserRow';
import { MassMessageTemplate } from '../../api-client/interfaces';
import { RootState } from '../../redux/reducers';
import { DispatchMap } from '../../redux/actions/interfaces';
import { groupTemplateUserIds, selectTemplateUserIds } from '../../redux/selectors';

interface MassMessagingUserTableProps {
  template?: MassMessageTemplate;
}

type ConnectProps = DispatchMap<{
  userIds?: number[];
  selectedUserIds?: number[];
}>;

const MassMessagingUserTable: React.FC<MassMessagingUserTableProps & ConnectProps> = ({ userIds, template }) => (
  <div className='mass-messaging__recipients'>
    <div className='mass-messaging__recipients__header'>Recipient List</div>
    <div className='mass-messaging__recipients__list'>
      {template ?
        <table className='mass-messaging-user-table'>
          <thead>
            <tr>
              <th />
              <th>Name</th>
              <th>Team</th>
            </tr>
          </thead>
          <tbody>
            {
              userIds && userIds.length && userIds.map(id => (
                <MassMessagingUserRow key={id} userId={id} template={template} />
              ))
            }
          </tbody>
        </table>
        : <div className='mass-messaging__recipients--empty'>Select a message draft to see the recipients</div>}
    </div>
  </div>
)
;

const mapStateToProps = (state: RootState, props: MassMessagingUserTableProps) => ({
  userIds: props.template ? groupTemplateUserIds(state, props.template) : [],
  selectedUserIds: props.template ? selectTemplateUserIds(state, props.template) : []
});

export default connect(mapStateToProps)(MassMessagingUserTable);
