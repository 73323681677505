import * as React from 'react';
import { Header, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import * as R from 'ramda';

import * as CompletedImg from '../../images/completed.svg';
import { TaskKind } from '../../api-client/interfaces';
import TaskGroup from './TaskGroup';
import { TaskRecord } from '../../redux/reducers';

interface CompletedTasksProps {
  tasks: TaskRecord[];
  taskKindTitles: TaskKind[];
}

const StyledSection = styled.div`
  padding-top: 3%;
`;

const groupTasks = R.groupBy((task: TaskRecord) => task.kind as TaskKind);

const CompletedTasks: React.FunctionComponent<CompletedTasksProps> = ({ tasks, taskKindTitles }) => {
  const groupedTasks = tasks && groupTasks(tasks);

  return (
    <div>
      {tasks && <StyledSection>
        <Image src={CompletedImg} size="mini" centered />
        <Header as="h2" textAlign="center">Completed Tasks!</Header>
        {taskKindTitles.map(kind => {
          const keyedTasks = groupedTasks[kind];
          return keyedTasks &&
          <TaskGroup key={kind} kind={kind} tasks={keyedTasks} taskCount={keyedTasks.length} completed />;
        })}
      </StyledSection>}
    </div>
  );
};

export default CompletedTasks;
