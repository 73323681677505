// /<reference path="../src/typings/globals.d.ts" />
import * as Sentry from '@sentry/browser';

// The watchdog sets up some global hooks to make sure uncaught exceptions are reported, then takes the user to the fail
// page where they can apporpriately alert someone to the failure.
// Right now most of the functionality depends on @sentry/browser (Sentry's javascript client),
// but if Sentry is ruled out in
// the future some real work will be done here to hook into event handlers and window.onerror.
// To temporarily disable the redirect to /fail, set `window.___watchdogCollar = true`.

/*
 *          ,
 *          |`-.__
 *          / ' _/
 *         ****`
 *        /    }
 *       /  \ /
 *   \ /`   \\\
 *    `\    /_\\
 *     `~~~~~``~`
 */
const BIG_DAWG =
  '       ,\n       |`-.__\n       / \' _/\n      ****`\n     /    }\n    /  \\ /\n\\ /`   \\\\\\\n `\\    /_\\\\\n' +
  '  `~~~~~``~`\n';

/**
 * A full list of global objects that are potentially DOM event emitters, and will have their `addEventListener` methods
 * wrapped with error handling code in `wrapEventListeners` below. The first two are all that's needed for
 * Chrome/Opera/Firefox, the rest are necessary for Safari and IE11.
 */
// const EVENT_TARGETS = [
//   'EventTarget', 'Window', 'Node', 'ApplicationCache', 'AudioTrackList', 'ChannelMergerNode',
//   'CryptoOperation', 'EventSource', 'FileReader', 'HTMLUnknownElement', 'IDBDatabase', 'IDBRequest',
//   'IDBTransaction',
//   'KeyOperation', 'MediaController', 'MessagePort', 'ModalWindow', 'Notification', 'SVGElementInstance', 'Screen',
//   'TextTrack', 'TextTrackCue', 'TextTrackList', 'WebSocket', 'WebSocketWorker', 'Worker', 'XMLHttpRequest',
//   'XMLHttpRequestEventTarget', 'XMLHttpRequestUpload', 'document'
// ];

/**
 * How long to wait before reporting a rejected Promise as unhandled and treating it as a global error. This only works
 * with the [bluebird](http://bluebirdjs.com) Promise library.
 *
 * Promises are tricky since you get an object reference you can hang on to and asynchronously attach a `.catch` handler
 * to later on. Of course, doing so is considered poor practice and makes the DAWG angry.
 */
// const PROMISE_REJECT_THRESHOLD = 1000;

/**
 * Without a sentry instance the watchdog will still
 * largely work, but it will always follow the `handleErrorWithFailedReport` path.
 */
/**
 * These regexps test for error messages that should **not** trigger the default watchdog behavior.
 * These will instead emit a `watchdogWarning` event and log a warning to the console - it's up to the application to
 * consume these events and report/act on them appropriately.
 */
const WARNING_CODES = ['CA-E15', 'CA-E16', 'CA-E17', 'CA-E18', 'CA-E19'];

/**
 * Check for invariant conditions that would prevent the watchdog from running correctly.
 */
function checkPrereqs() {
  // eslint-disable-next-line
  if (typeof console === 'undefined' || console.log === undefined) {
    // What else can be done here to attempt recovery? This would be pretty bad.
    throw new Error('PANIC: Console API is not available!');
  }
}

function emitWarning(message: string) {
  console.warn(message);
  window.dispatchEvent(new CustomEvent('watchdogWarning', { detail: message }));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getMessage(data: any) {
  return (
    data &&
    data.exception &&
    data.exception.values &&
    data.exception.values.length > 0 &&
    data.exception.values[0].value
  );
}

function gotoFail(message?: string, eventId?: string) {
  let uri = '/fail';

  if (message) {
    if (WARNING_CODES.some(r => message.startsWith(r))) {
      if (message.startsWith('CA-E18')) uri = '/';
      emitWarning(message);
      return;
    }

    uri += '?m=' + encodeURIComponent(message);
    if (eventId) uri += '&e=' + encodeURIComponent(eventId);
  }

  if (typeof ___watchdogCollar !== 'undefined' && ___watchdogCollar) {
    console.warn('Watchdog collar enabled; not redirecting to fail page.');
    return;
  }

  window.location.href = window.location.origin + uri;
}

function initSentry() {
  if (!Sentry) {
    throw new Error(
      'PANIC: Sentry client not available; watchdog will not work!'
    );
  }

  window.document.addEventListener('sentrySuccess', handleError);
  window.document.addEventListener('sentryError', handleErrorWithFailedReport);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleError(ev: any) {
  console.error(BIG_DAWG + '\nError report submitted.', ev);

  gotoFail(getMessage(ev.data), ev.data.event_id);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleErrorWithFailedReport(ev: any) {
  console.error(BIG_DAWG + '\nFailed to submit error report.', ev);

  gotoFail(getMessage(ev.data));
}

/**
 * Initialize the watchdog, setting up hooks to catch unhandled exceptions and reporting them via sentry when possible.
 */
function initWatchdog() {
  try {
    checkPrereqs();
    monkeypatchConsole();
    initSentry();
    console.info(BIG_DAWG + '\nWatchdog initialized. Woof.');
  } catch (err) {
    console.log(err);
    gotoFail('CA-E13');
  }
}

/**
 * Call this method to patch missing console methods. In most browser environments this should never be needed.
 */
function monkeypatchConsole() {
  console.info = console.info || console.log;
  console.warn = console.warn || console.log;
  console.error = console.error || console.log;
}

export { initWatchdog };
