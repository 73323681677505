import 'react-dates/lib/css/_datepicker.css';

import * as React from 'react';
import { connect } from 'react-redux';
import { Form, Button, Text, Box } from 'grommet';
import MessageField from '../../components/communications/MessageField';
import * as moment from 'moment';
import { DEFAULT_TIMEZONE } from '../../utils/user/timezone';
import { completeUserDOTaskCounts } from '../../redux/actions/ui-state';
import { MessageComposed, actionCreators as messagingActions } from '../../redux/actions/messages';
import { DispatchMap } from '../../redux/actions/interfaces';
import { selectConversation, selectUser, selectAdmin, selectFeatureFlags } from '../../redux/selectors';
import { RootState, ConversationRecord, UserRecord } from '../../redux/reducers';
import { Admin } from '../../api-client/interfaces';

import { mixpanelTrack } from '../../mixpanel/mixpanel';
import ScheduleMessageModal from '../../components/communications/ScheduleMessageModal';
import UserLocalTimeText from './UserLocalTimeText';
import { HINGE_BLUE } from '../../colors';
import { useNewMessagingBffPostMessageEndpoint } from '../../features';

export const MAX_CHARACTER_COUNT = 160;

interface MessageComposerContainerProps {
  userId: number;
  conversationId: string;
}

type ConnectProps = DispatchMap<{
  postMessage: typeof messagingActions.postMessage;
  resetUserDOTaskCounts: typeof completeUserDOTaskCounts;
  conversation?: ConversationRecord;
  user?: UserRecord;
  admin: Admin;
  useNewMessagingBffEndpoint: boolean;
}>;

type Props = MessageComposerContainerProps & ConnectProps;

const MessageComposerContainer: React.FC<Props> = ({
  userId,
  user,
  conversationId,
  conversation,
  postMessage,
  resetUserDOTaskCounts,
  admin,
  useNewMessagingBffEndpoint
}) => {
  const [composedMessageBody, setComposedMessageBody] = React.useState('');
  const [characterCount, setCharacterCount] = React.useState(MAX_CHARACTER_COUNT);
  const [textDisabled, toggleTextDisable] = React.useState(true);
  const [showScheduleModal, setShowScheduleModal] = React.useState(false);

  React.useEffect(() => {
    setComposedMessageBody('');
    toggleTextDisable(true);
    setCharacterCount(MAX_CHARACTER_COUNT);
  }, [conversationId]);

  const handleCharacterCount = (composedBody: string) => {
    setCharacterCount(MAX_CHARACTER_COUNT - composedBody.length);
  };

  const handleComposeMessageBody = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const composedBody = e.target.value;

    setComposedMessageBody(composedBody);

    composedBody.length ? toggleTextDisable(false) : toggleTextDisable(true);

    handleCharacterCount(composedBody);
  };

  const handleSubmitMessage = React.useCallback(() => {
    const messageComposed: MessageComposed = {
      body: composedMessageBody,
      adminUuid: admin?.uuid,
      userUuid: user?.uuid,
      userPhoneNumber: user?.phoneNumber
    };
    toggleTextDisable(true);

    postMessage(conversationId, messageComposed, useNewMessagingBffEndpoint)
      .then(() => {
        mixpanelTrack('Message Sent', {
          Origin: '/messaging',
          ConversationType: conversation?.type
        });
        setComposedMessageBody('');
        handleCharacterCount('');
        toggleTextDisable(true);
        resetUserDOTaskCounts(userId);
      })
      .catch(e => {
        toggleTextDisable(false);
        throw e;
      });
  }, [composedMessageBody]);

  const handleScheduleMessage = React.useCallback(
    (scheduledFor: moment.Moment) => {
      const messageComposed: MessageComposed = {
        body: composedMessageBody,
        adminUuid: admin?.uuid,
        userUuid: user?.uuid,
        userPhoneNumber: user?.phoneNumber,
        scheduledFor: scheduledFor.format('YYYY-MM-DD HH:mm'),
        userTimezone: user?.timezone
      };

      postMessage(conversationId, messageComposed, useNewMessagingBffEndpoint).then(() => {
        mixpanelTrack('Scheduled Message', {
          Origin: '/messaging',
          ConversationType: conversation?.type
        });
        setComposedMessageBody('');
        handleCharacterCount('');
        toggleTextDisable(true);
        setShowScheduleModal(false);
      });
    },
    [composedMessageBody]
  );

  const handleScheduleClick = () => setShowScheduleModal(true);

  return (
    <Box style={{ margin: '0px 5px' }}>
      {showScheduleModal && (
        <ScheduleMessageModal
          userTimezone={user?.timezone || DEFAULT_TIMEZONE}
          scheduleMessage={handleScheduleMessage}
          showModal={showScheduleModal}
          setShowModal={setShowScheduleModal}
        />
      )}
      <Box justify='start'>
        {!!user?.timezone && (
          <UserLocalTimeText data-testid='user-local-time' timezone={user.timezone} />
        )}
      </Box>

      <Form>
        <MessageField
          key={conversationId}
          onChange={handleComposeMessageBody}
          value={composedMessageBody}
          focusIndicator={textDisabled}
        />
        <Box gap='50%' alignContent='around' width='100%' align='stretch' direction='row' style={{ paddingTop: '5px' }}>
          <Box justify='start'>
            <Text size='small'>{`Character count: ${characterCount}`}</Text>
          </Box>
          <Box justify='end' direction='row'>
            <Button
              id='schedule-button'
              style={{ fontSize: '12px', marginRight: '15px' }}
              color={HINGE_BLUE}
              plain={true}
              label='SCHEDULE'
              disabled={textDisabled}
              onClick={handleScheduleClick}
            />
            <Button
              style={{ color: 'white', fontSize: '12px' }}
              primary
              color={HINGE_BLUE}
              type='submit'
              label='SEND'
              disabled={textDisabled}
              onClick={handleSubmitMessage}
            />
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

const mapStateToProps = (state: RootState, props: MessageComposerContainerProps) => ({
  admin: selectAdmin(state),
  conversation: selectConversation(state, {
    conversationId: props.conversationId
  }),
  user: selectUser(state, {
    userId: props.userId
  }),
  useNewMessagingBffEndpoint: selectFeatureFlags(state).get(useNewMessagingBffPostMessageEndpoint)
});

const mapDispatchToProps = {
  postMessage: messagingActions.postMessage,
  resetUserDOTaskCounts: completeUserDOTaskCounts
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageComposerContainer);
