import * as React from 'react';
import { Grid, Button, Header, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import LoginFormContainer from '../../containers/auth/LoginContainer';
import * as logo from '../../images/logo.svg';
import { Redirect, withRouter } from 'react-router';
import { useHingeHealthSecurityContext } from '../auth-shared';

export interface Props {
  hasAuth: boolean;
}

const StyledContainer = styled.div`
  height: 100%;
  text-align: center;
  max-width: 450px;
`;

const DISPLAY_LOGIN_FORM =
  (process.env.NODE_ENV === 'production' && process.env.REACT_APP_STAGING === 'true') ||
  process.env.NODE_ENV !== 'production' ||
  process.env.CI === 'true';

export const LoginFailedMessage: React.FunctionComponent<Props> = (props) => {
  if (!props.hasAuth && props.hasAuth !== undefined) {
    return (
      <Grid.Row>
        <div style={{ color: 'red' }}>You are currently signed out.</div>
      </Grid.Row>
    );
  }
  return null;
};

const OAuthLoginPage = () => {
  const [hasAuth, setHasAuth] = React.useState(false);
  const [isAuthPending, setIsAuthPending] = React.useState(true);
  const { hingeHealthAuthClient, hingeHealthAuthState } = useHingeHealthSecurityContext();

  React.useEffect(() => {
    const checkAuthentication = async() => {
      await postAuth();
    };

    checkAuthentication();
  }, [hingeHealthAuthState]);

  const postAuth = async() => {
    try {
      if (await hingeHealthAuthClient.isAuthenticated()) {
        setHasAuth(true);
      }
    } finally {
      setIsAuthPending(false);
    }
  };

  const handleOktaLogin = () => {
    hingeHealthAuthClient.signInWithRedirect({ originalUri: '/tasks' });
  };

  const renderLoginFormOrNull = () => {
    if (isAuthPending) {
      return null;
    }

    return (
      <Grid
        container
        centered
        verticalAlign="middle"
        style={{ alignItems: 'center', height: '100%' }}
      >
        <Grid.Row centered verticalAlign="middle">
          <StyledContainer>
            <Grid
              verticalAlign="middle"
              textAlign="center"
              style={{ height: '100%' }}
            >
              <Grid.Row>
                <Header as="h3">
                  {/* Need to use Image as icons are really designed only be a single color */}
                  <Image src={logo.default} />
                </Header>
              </Grid.Row>
              <LoginFailedMessage hasAuth={hasAuth} />
              <Grid.Row />
              <Grid.Row>
                <Button
                  id="okta-login"
                  style={{ marginTop: 15 }}
                  onClick={handleOktaLogin}
                  size="huge"
                  primary
                >
                  Okta Login
                </Button>
              </Grid.Row>
            </Grid>
          </StyledContainer>
          {DISPLAY_LOGIN_FORM && <LoginFormContainer />}
        </Grid.Row>
      </Grid>
    );
  };

  return (
    <>
      {hasAuth && (<Redirect to={'/tasks'} />)}
      {!hasAuth && renderLoginFormOrNull()}
    </>
  );
};

export default withRouter(OAuthLoginPage);
