import * as React from 'react';
import styled from 'styled-components';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import {
  CarePlanActionOptions,
  CarePlanItemActionOptions,
  CarePlanUpdateConfirmProps
} from './UpdateCarePlanContainer';
import Datepicker from './Datepicker';
import { DischargeReasons } from './CarePlansContainer';

type UpdateCarePlanModalConfirmProps = CarePlanUpdateConfirmProps<CarePlanActionOptions | CarePlanItemActionOptions>;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 16px;
`;

const StyledDateContainer = styled.div`
  padding-top: 16px;
  max-width: 160px;
`;

const StyledFormControl = styled(FormControl)`
  max-width: 160px;
`;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 208,
      width: 280
    }
  }
};

const UpdateCarePlanModalConfirm: React.FC<UpdateCarePlanModalConfirmProps> = ({
  name,
  description,
  dateProps,
  dischargeReasonProps
}) => {
  const [dateFocused, setDateFocused] = React.useState(false);
  const handleOpenDateFocusChange = ({ focused }: Record<string, boolean | null>) => {
    setDateFocused(focused || false);
  };
  const dischargeReasons = Object.values(DischargeReasons);

  return (
    <StyledDiv>
      <Typography variant='body1' color='textSecondary' align='left'>
        {description}
      </Typography>
      {dateProps && (
        <StyledDateContainer>
          {dateProps.caption && (
            <Typography variant='caption' color='textSecondary'>
              {dateProps.caption}
            </Typography>
          )}
          <Datepicker
            date={dateProps.selectedUpdateDate}
            onDateChange={dateProps.handleSetSelectedUpdateDate}
            focused={dateFocused}
            onFocusChange={handleOpenDateFocusChange}
            id={name}
            placeholder={dateProps.placeholder}
          />
        </StyledDateContainer>
      )}
      {dischargeReasonProps && (
        <StyledFormControl margin='dense'>
          <InputLabel id='discharge-reason-dropdown-label'>Discharge reason</InputLabel>
          <Select
            className='discharge-reason-dropdown-select'
            labelId='discharge-reason-dropdown-label'
            value={dischargeReasonProps.selectedDischargeReason || ''}
            onChange={dischargeReasonProps.handleSetSelectedDischargeReason}
            MenuProps={MenuProps}
          >
            {dischargeReasons.map((reason: string) => (
              <MenuItem key={reason} value={reason} style={{ whiteSpace: 'normal' }}>
                <Typography variant='subtitle2'>{reason}</Typography>
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      )}
    </StyledDiv>
  );
};

export default UpdateCarePlanModalConfirm;
