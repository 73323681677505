import { EnrollmentStatusTextMap } from '../../api-client';
import { User } from '../../graphql/models/User';
import { User as APIUser } from '../../api-client/interfaces';
import { UserRecord } from 'src/redux/reducers';
import { isNullOrUndefined } from '../helpers';
import { GetUserById } from '../../graphql/models/bff';
import { EnrollmentStatus, Status } from '../../graphql/models/bff/EnrollmentStatus';

export const enrollmentStatusText = (enrollmentStatus: Status) => ({
  content:
    enrollmentStatus === Status.OPT_IN ? EnrollmentStatusTextMap.OptOutContent : EnrollmentStatusTextMap.OptInContent,
  title: enrollmentStatus === Status.OPT_IN ? EnrollmentStatusTextMap.OptOutTitle : EnrollmentStatusTextMap.OptInTitle,
  strikeThroughWarning:
    enrollmentStatus === Status.OPT_IN
      ? EnrollmentStatusTextMap.OptOutStrikeThrough
      : EnrollmentStatusTextMap.OptInStrikeThrough
});

export const userOptedOut = (enrollmentStatus: EnrollmentStatus[] | undefined | null) => {
  const userEnrollmentStatus = enrollmentStatus && enrollmentStatus[0];

  return !!(userEnrollmentStatus?.status === Status.OPT_OUT);
};

export const inverseStatus = (status?: Status | null) => {
  // default to opt_in
  let inversedStatus = Status.OPT_IN;

  if (!!status) {
    inversedStatus = status === Status.OPT_IN ? Status.OPT_OUT : Status.OPT_IN;
  }

  return inversedStatus;
};

export const getEnrollmentStatus = (user?: GetUserById): EnrollmentStatus | null =>
  user && user?.enrollmentStatus?.length ? user.enrollmentStatus[0] : null;

export const enableUserStrikeThrough = (user: User | APIUser | UserRecord): boolean =>
  isNullOrUndefined(user.contactMethod) || userOptedOut(user.enrollmentStatus);
