import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { TaskStatuses, TaskKind } from '../../api-client/interfaces';
import { Image } from 'semantic-ui-react';


import * as FirstImg from '../../images/firstthingsfirst.svg';

import { RootState, TaskRecord } from '../../redux/reducers';
import { actionCreators as taskActions } from '../../redux/actions/tasks';
import { DispatchMap } from '../../redux/actions/interfaces';
import { selectClinicalAndReengagerTasks } from '../../redux/selectors';

import CompletedTasksContainer from './CompletedTasksContainer';
import FirstThingsFirst from '../../components/tasks/FirstThingsFirst';
import TasksTableContainer from './table/TasksTableContainer';
import { isEqual } from 'lodash';
import { Box, Snackbar, Typography } from '@material-ui/core';
import { ClinicalTaskKindTitles } from '../../api-client';

const StyledContainer = styled.div`
  height: 100%;
  margin: 0 auto;
`;

const PaddedDiv = styled.div`
  padding-top: 2%;
`;

const StyledSection = styled.div`
  padding-top: 3%;
`;

type ConnectProps = DispatchMap<{
  taskList: TaskRecord[];
  getTasks: typeof taskActions.getList;
  highUtilTasksEnabled?: boolean;
}>;
type Props = ConnectProps;

type State = Readonly<{
  snackbarOpen: boolean;
  message: string;
}>;

class TaskOverviewContainer extends React.PureComponent<Props, State> {
  state: State = {
    snackbarOpen: false,
    message: ''
  };
  componentDidMount() {
    this.fetchPendingTasks();
  }

  fetchPendingTasks = () => {
    this.props.getTasks &&
      this.props.getTasks({ status: TaskStatuses.Pending });
  };

  showSnackbar = (msg: string) => {
    this.setMessage(msg);
    this.setState({
      snackbarOpen: true
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false
    });
  };

  setMessage = (msg: string) => {
    this.setState({
      message: msg
    });
  };

  render() {
    const { taskList } = this.props;
    const titles = Object.keys(ClinicalTaskKindTitles) as TaskKind[];

    return (
      <StyledContainer>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <div>
            <Typography variant='h4' style={{textAlign: 'center', marginBottom: '10px'}}>
              My Daily Tasks
            </Typography>
            <StyledSection>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Image src={FirstImg} size="mini" centered />
              </div>
              <FirstThingsFirst
                showSnackBar={this.showSnackbar}
                tasks={taskList}
                taskKindTitles={titles}
              />
              <div>
                <TasksTableContainer showSnackBar={this.showSnackbar} />
              </div>
              <PaddedDiv></PaddedDiv>
            </StyledSection>
            <CompletedTasksContainer />
          </div>
        </Box>
        {this.state.message.length > 0 &&
          <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            autoHideDuration={3000}
            message={this.state.message}
            onClose={this.closeSnackbar}
            open={this.state.snackbarOpen}
          />
        }
      </StyledContainer>
    );
  }
}

const mapStateToProps = (state: RootState, props: {}) => ({
  taskList: selectClinicalAndReengagerTasks(state, TaskStatuses.Pending)
});

const mapDispatchToProps = {
  getTasks: taskActions.getList
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(TaskOverviewContainer, isEqual));
