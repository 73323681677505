export const ADD_TAG =`
  mutation addTag($tag: String!, $comment: String!, $userId: Int!)   {
    addTag(tag: $tag, comment: $comment, userId: $userId)
  }
`;

export const DELETE_TAG =`
  mutation deleteTag($tag: String!, $comment: String!, $userId: Int!)   {
    deleteTag(tag: $tag, comment: $comment, userId: $userId)
  }
`;
