import * as React from 'react';
import { connect } from 'react-redux';
import { RefreshRounded, InfoOutlined } from '@material-ui/icons';
import { selectAdmin } from '../../redux/selectors/admin';
import { RootState } from '../../redux/reducers';
import { Admin } from '../../api-client/interfaces';
import * as Sentry from '@sentry/browser';
import './HingeError.scss';

const errorMessage = 'A server error has occurred. Please refresh the page.';

interface HingeErrorState {
  hasError: boolean;
  hingeError?: Error;
}

interface ConnectProps {
  admin: Admin;
}

class HingeError extends React.PureComponent<{} & ConnectProps, HingeErrorState> {
  constructor(props: {} & ConnectProps) {
    super(props);
    this.state = { hasError: false };
  }
  sentryScopeSet = false;

  render() {
    const { hasError, hingeError } = this.state;
    if (hasError && hingeError && hingeError.message) {
      return (
        <div className='error'>
          <div className='info'>
            <InfoOutlined />
            <div className='message'>{errorMessage}</div>
          </div>
          <div className='info error--refresh' onClick={this.reloadPage}>
            <RefreshRounded />
            <div className='error--refresh__text'>Refresh</div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }

  componentDidMount() {
    if (this.props.admin?.email && !this.sentryScopeSet) {
      const email = this.props.admin.email;
      Sentry.configureScope(scope => scope.setUser({ email }));
      this.sentryScopeSet = true;
    }
  }

  componentDidUpdate() {
    if (this.props.admin?.email && !this.sentryScopeSet) {
      const email = this.props.admin.email;
      Sentry.configureScope(scope => scope.setUser({ email }));
      this.sentryScopeSet = true;
    }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    Sentry.captureException(error);
  }

  static getDerivedStateFromError(error: Error): HingeErrorState {
    return { hasError: true, hingeError: error };
  }
  reloadPage = () => window.location.reload();
}

const mapStateToProps = (state: RootState, props: {}) => ({
  admin: selectAdmin(state)
});

export default connect(mapStateToProps)(HingeError);
