import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Label } from 'semantic-ui-react';
import {
  Typography
} from '@material-ui/core';
import * as R from 'ramda';

import { DispatchThunk, tasksActions } from '../../redux/actions';
import { TaskRecord, RootState } from '../../redux/reducers';
import TaskGroup from '../../components/tasks/TaskGroup';
import { TaskKind, TaskStatuses, ClinicalTaskKindTitles } from '../../api-client';
import { selectUser } from '../../redux/selectors/index';
import { selectUserPendingTasks } from '../../redux/selectors/tasks';

const TasksContainer = styled.div`
  padding-top: 0rem;
`;

interface UserTasksContainerProps {
  userId: number;
  className?: string;
}

const groupByKind = R.groupBy((t: TaskRecord) => t.kind || '');

const UserTasksContainer: React.FC<UserTasksContainerProps> = ({ userId }) => {
  const dispatchThunk: DispatchThunk = useDispatch();
  React.useEffect(() => {
    dispatchThunk(tasksActions.getList({ status: TaskStatuses.Pending }));
  }, []);

  const tasks = useSelector((state: RootState) => selectUserPendingTasks(state, { userId }));
  const user = useSelector((state: RootState) => selectUser(state, { userId }));

  if (!user) return null;


  const clinicalTaskKinds = Object.keys(ClinicalTaskKindTitles);;
  const filteredTasks = tasks.filter((task) => task.kind && clinicalTaskKinds.indexOf(task.kind) >= 0);

  const groupedTasks = groupByKind(filteredTasks);

  return (
    <TasksContainer>
      {user.firstName ? (
        <Typography variant="h5" gutterBottom>
          {user.firstName}'s Tasks{' '}
          <Label circular>{filteredTasks.length}</Label>
        </Typography>
      ) : (
        <Typography variant="h5" gutterBottom>
          Loading...
        </Typography>
      )}
      {clinicalTaskKinds.map(key => {
        const keyedTasks = groupedTasks[key];

        return keyedTasks && (
          <TaskGroup
            kind={key as TaskKind}
            key={key}
            taskCount={keyedTasks.length}
            tasks={keyedTasks}
            inProfile
          />
        );
      })}
    </TasksContainer>
  );
};

export default UserTasksContainer;
