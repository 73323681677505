import * as React from 'react';
// import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { authActions } from '../../redux/actions';
import { DispatchMap } from '../../redux/actions/interfaces';
import { History } from 'history';
import { ERR_UNAUTHORIZED, ERR_FORBIDDEN, ERR_CONNECTION_FAIL } from '../../errors';
import * as initTrace from 'debug';
import { Message, Image, Header, Modal } from 'semantic-ui-react';
import * as cesarFace from '../../images/cesar.png';

const debug = initTrace('ca:containers:async:CesarMilan');

interface CesarMilanProps {
  history?: History;
  location?: Location;
}

type ConnectProps = DispatchMap<{
  logout: typeof authActions.logout;
}>;

type Props = CesarMilanProps & ConnectProps;

interface State {
  portalMessage: string | undefined;
}

/**
 * This container monitors watchdog events (hence, Cesar!) to display a notification when connection errors occur, and
 * redirects back to login when auth failures occur outside of the login page.
 */
class CesarMilan extends React.PureComponent<Props, State> {
  state: State = { portalMessage: undefined };

  componentDidMount() {
    window.addEventListener('watchdogWarning', this.handleWatchdogWarning, false);
  }

  componentWillUnmount() {
    window.removeEventListener('watchdogWarning', this.handleWatchdogWarning, false);
  }

  handlePortalClose = () => {
    this.setState({ portalMessage: undefined });
  };

  handleWatchdogWarning = (ev: Event) => {
    switch ((ev as CustomEvent).detail) {
      case ERR_UNAUTHORIZED:
      case ERR_FORBIDDEN:
        if (this.props.location && this.props.location.pathname === '/') return;
        debug('401/403 received from backend, redirecting to login.');
        // TODO: CA-XXX set up a redirect to go back to where we were after successful login.
        this.props.logout();
        this.props.history && this.props.history.replace('/');
        break;
      case ERR_CONNECTION_FAIL:
        this.setState({ portalMessage: 'A connection error has occurred. Please try again later.' });
        break;
      default:
        return;
    }
  };

  render() {
    return (
      <Modal
        basic
        size="small"
        open={this.state.portalMessage !== undefined}
        onClose={this.handlePortalClose}
        closeOnDocumentClick
      >
        <Message negative onDismiss={this.handlePortalClose} onClick={this.handlePortalClose}>
          <Header><Image src={cesarFace} />{' '}{this.state.portalMessage}</Header>
          {/* <Message.Header>{this.state.portalMessage}</Message.Header> */}
        </Message>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  logout: authActions.logout
};

// export default withRouter(connect(undefined, mapDispatchToProps)(CesarMilan));
export default connect(undefined, mapDispatchToProps)(CesarMilan);
