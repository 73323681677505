import * as React from 'react';
import { isNullOrUndefined } from 'util';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import styled from 'styled-components';
import {
  RED,
  GREEN } from '../../../../colors';
import { Typography } from '@material-ui/core';

interface EngagementStreakProps {
  engagementStreak?: number | null;
}

const TrendingUpWrapper = styled.div`
    display: flex;
    color: ${GREEN};
    padding: 20px 20px 0 0;
    justify-content: right;
`;

const TrendingDownWrapper = styled.div`
    display: flex;
    color: ${RED};
    padding: 20px 20px 0 0;
    justify-content: right;
`;

const StyledTypography = styled(Typography)`
  && {
    padding-left: 10px;
  }
`;

class EngagementStreak extends React.PureComponent<EngagementStreakProps> {
  render() {
    if (isNullOrUndefined(this.props.engagementStreak) || this.props.engagementStreak === 0) {
      return null;
    }
    return (
      <>
        {
          this.props.engagementStreak > 0
            ? <TrendingUpWrapper data-testid="trending-up">
              <TrendingUpIcon />
              <StyledTypography>Active {`${this.props.engagementStreak}W`}</StyledTypography>
            </TrendingUpWrapper>
            : <TrendingDownWrapper data-testid="trending-down">
              <TrendingDownIcon />
              <StyledTypography>Inactive {`${Math.abs(this.props.engagementStreak)}W`}</StyledTypography>
            </TrendingDownWrapper>
        }
      </>
    );
  }
}

export default EngagementStreak;
