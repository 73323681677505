import * as React from 'react';
import { Popup, List } from 'semantic-ui-react';
import './OverflowMenu.scss';

interface OverflowMenuProps {
  triggeredBy?: JSX.Element;
  open?: boolean;
  triggerModalOpen?(): void;
  triggerRejection(): void;
  onOpen?(): void;
  context?: React.RefObject<HTMLDivElement>;
  withoutNote?: boolean;
}

class OverflowMenu extends React.PureComponent<OverflowMenuProps> {

  render() {
    return <Popup hideOnScroll={true} context={this.props?.context} trigger={this.props.triggeredBy}
      on="click" open={this.props.open} onOpen={this.props.onOpen}>
      <List selection verticalAlign="middle">
        {!this.props.withoutNote && <List.Item>
          <List.Content onClick={this.props.triggerModalOpen}>
            <div className='task-overflow-options'>Complete with Note</div>
          </List.Content>
        </List.Item>}
        <List.Item>
          <List.Content onClick={this.props.triggerRejection}>
            <div className='task-overflow-options'>
            Won't Do
            </div>
          </List.Content>
        </List.Item>
      </List>
    </Popup>;
  }
}

export default OverflowMenu;
