import * as React from 'react';
import { connect } from 'react-redux';
import {
  RouteComponentProps,
  RouteProps
} from 'react-router-dom';
import { RootState } from '../../redux/reducers';
import { selectAdmin } from '../../redux/selectors/admin';
import { authActions } from '../../redux/actions/index';
import { setInCtpIframe } from '../../redux/actions/ui-state';
import { DispatchMap } from '../../redux/actions/interfaces';
import ConditionalRenderComponent from './ConditionalRenderComponent';
import { selectFeatureFlags } from '../../redux/selectors';
import OktaPrivateRoute from './OktaPrivateRoute';
import { HingeHealthSecurityContext } from '../../components/auth-shared/hinge-health-security-context';

export const WINDOW_EVENT_TOKEN_REQUEST = 'getAuthToken';
export const IN_CTP_IFRAME_EVENT = 'inCtpIFrame';

export interface PrivateRouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: | React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  isGrommet?: boolean;
}

interface State {
  isAuthPending?: boolean;
  initDataDogUser: boolean;
}

type ConnectProps = DispatchMap<{
  admin?: { id: number; email: string };
  featureFlags?: Map<string, boolean>;
  checkOktaAuth: typeof authActions.validateOkta;
  checkAuth: typeof authActions.validate;
  initSplitio(): void;
  initDataDogUser(): void;
  setInCtpIframe: typeof setInCtpIframe;
}>;

type Props = PrivateRouteProps & RouteProps & ConnectProps;

export class PrivateRoute extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isAuthPending: true,
      initDataDogUser: false
    };
  }
  initSplit = false;

  handleTokenReceived = async(event: Event) => {
    const messageEvent = event as MessageEvent;
    const data = messageEvent.data;

    if (data?.type === IN_CTP_IFRAME_EVENT) {
      this.props.setInCtpIframe(true);
    }
  };

  async componentDidMount() {
    try {
      window.addEventListener('message', this.handleTokenReceived);
      window.parent.postMessage(
        WINDOW_EVENT_TOKEN_REQUEST,
        document.location.ancestorOrigins?.[0] || location.origin
      );
      this.initUserServices();
      this.setState({ isAuthPending: false });
    } catch(e) {}
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleTokenReceived);
  }

  initUserServices() {
    if (this.props.admin?.email && !this.initSplit && !this.state.initDataDogUser) {
      this.props.initSplitio();
      this.props.initDataDogUser();
      this.initSplit = true;
      this.setState({ initDataDogUser: true });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    this.initUserServices();
  }

  render() {
    const { component: Component, path, admin, children, ...restProps } = this.props;
    if (this.state.isAuthPending) {
      return <></>;
    }
    const routeProps = {
      ...restProps,
      render: ( props: RouteComponentProps ) => <ConditionalRenderComponent
        isGrommet={this.props.isGrommet}
        routeProps={props}
        Component={Component}
      >
        {this.props.children
          ? React.Children.only(this.props.children)
          : null}
      </ConditionalRenderComponent>
    };
    return <OktaPrivateRoute {...routeProps}/>;
  }
}

PrivateRoute.contextType = HingeHealthSecurityContext;

const mapStateToProps = (state: RootState) => ({
  admin: selectAdmin(state),
  featureFlags: selectFeatureFlags( state )
});

const mapDispatchToProps = {
  checkAuth: authActions.validate,
  checkOktaAuth: authActions.validateOkta,
  initSplitio: authActions.initSplitio,
  initDataDogUser: authActions.initDataDogUser,
  setInCtpIframe
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
