import * as React from 'react';
import { Location } from 'history';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { UserProfileRoutes } from '../../utils/user/routes';
import { PRIMARY } from '../../colors';

export const getPathName = (location?: Location) =>
  location ? location.pathname : '';

const activeTab = (location: string) => {
  switch (true) {
    case location.includes(UserProfileRoutes.Engagement):
      return 1;
    case location.includes(UserProfileRoutes.History):
      return 2;
    default:
      return 0;
  }
};

export interface UserNavBarProps {
  location?: Location;
  id: number;
}

export interface LinkTabProps {
  href: string;
  label: string;
}

const StyledTabs = styled(Tabs)`
  && {
    height: 48px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

const StyledTab = styled(Tab)`
  && {
    max-width: 120px;
  }
`;

const LinkTab = (props: LinkTabProps) => (
  <StyledTab
    // @ts-ignore (for type error from using Link as a component prop with styled components)
    to={props.href}
    component={Link}
    color="primary"
    {...props}
  />
);

export const UserNavBar: React.FunctionComponent<UserNavBarProps> = props => (
  <StyledTabs
    variant="fullWidth"
    value={activeTab(getPathName(props.location))}
    textColor="primary"
    TabIndicatorProps={{ style: { background: PRIMARY } }}
  >
    <LinkTab label="Overview" href={`/user/${props.id}`} />
    <LinkTab label="Pathway" href={`/user/${props.id}/${UserProfileRoutes.Engagement}`} />
    <LinkTab label="History" href={`/user/${props.id}/${UserProfileRoutes.History}`} />
  </StyledTabs>
);

export default UserNavBar;
