export const MEMBER_BANNER_TEST_IDS = {
  PAUSED_ICON: 'paused-status-icon',
  ACTIVE_ICON: 'active-status-icon',
  UPCOMING_ICON: 'upcoming-status-icon',
  PAUSED_CHIP_ICON: 'paused-chip-icon',
  ACTIVE_CHIP_ICON: 'active-chip-icon',
  ALERT_ICON_MODAL: 'alert-icon-modal',
  ALERT_ICON: 'alert-icon',
  ALERT_ICON_CHIP: 'alert-icon-chip',
  ALERT_MODAL_HAS_MORE: 'alert-modal-has-more'
};
