import { TypedRecord, makeTypedFactory } from 'typed-immutable-record';
import { Map } from 'immutable';

import { Message, MessageV2, MessageDirection } from '../../api-client';
import { RootAction } from '../actions';
import {
  DELETE_SCHEDULED_MESSAGE,
  ENTITIES_FETCHED,
  FETCH_MESSAGE,
  RECEIVE_UNSEEN_MESSAGE,
  RECEIVE_MESSAGE_STATUS, ADD_UNSEEN_MESSAGES
} from '../constants';
import {
  mergeUpdate,
  createEntityMap,
  createStringEntityMap,
  createSingleStringEntityMap
} from '../../utils/entity';

export interface MessageRecord extends TypedRecord<MessageRecord>, Message { }

export const createMessageRecord = makeTypedFactory<Message | MessageV2, MessageRecord>({
  id: '',
  conversationId: '',
  body: undefined,
  direction: undefined,
  createdAt: undefined,
  scheduledFor: undefined,
  status: undefined,
  seenAt: undefined,
  transmittedAt: undefined
});

// TODO: CT-1222 sometimes entity ID is a number, sometimes it is a string...we need it to be one or the other.
export type State = Map<number | string, MessageRecord>;

export const initialState: State = Map();

export const reducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case DELETE_SCHEDULED_MESSAGE:
      return action.payload.messageId
        ? state.delete(action.payload.messageId.toString())
        : state;
    case ENTITIES_FETCHED:
      return action.payload.entities.messages
        ? mergeUpdate(
          state,
          createStringEntityMap(
            createMessageRecord,
            action.payload.entities.messages
          )
        )
        : state;
    case FETCH_MESSAGE:
      return action.payload.entities.messages
        ? mergeUpdate(
          state,
          createStringEntityMap(
            createMessageRecord,
            action.payload.entities.messages
          )
        )
        : state;
    case RECEIVE_UNSEEN_MESSAGE:
      if (action.payload.message) {
        const newMessage: Message = {
          ...action.payload.message,
          direction: MessageDirection.In
        };
        return mergeUpdate(
          state,
          createSingleStringEntityMap(createMessageRecord, newMessage)
        );
      }
      return state;
    case ADD_UNSEEN_MESSAGES:
      return action.payload.entities.messages
        ? mergeUpdate(
          state,
          createEntityMap(
            createMessageRecord,
            action.payload.entities.messages
          )
        )
        : state;
    case RECEIVE_MESSAGE_STATUS:
      if (action.payload.message) {
        return mergeUpdate(
          state,
          createSingleStringEntityMap(createMessageRecord, action.payload.message)
        );
      }
      return state;
    default:
      return state;
  }
};
