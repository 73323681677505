import { RootState, WorkoutRecord } from '../reducers';
import { createSelector } from 'reselect';
import { selectUser } from './user';

function compareReverseOccurredAt(a: WorkoutRecord, b: WorkoutRecord) {
  if (!b.occurred_at) return 1;
  if (!a.occurred_at) return -1;
  return b.occurred_at.localeCompare(a.occurred_at);
}

export function selectWorkouts(state: RootState) {
  return state.workout;
}

export const selectWorkoutsByUser = createSelector(
  selectWorkouts,
  selectUser,
  (workouts, user) => user && user.workouts
    ? user.workouts
      .map(workoutId => workouts.get(workoutId))
      .sort(compareReverseOccurredAt)
    : []
);
