import * as React from 'react';
import * as moment from 'moment-timezone';
import * as sanitizeHtml from 'sanitize-html';
import { NavLink, generatePath, useParams } from 'react-router-dom';

import { MessageDirection } from '../../api-client/interfaces';
import { messagePreviewOptions } from '../../utils/messaging/sanitize';
import { ComputedSortedConversationItem } from '../../redux/selectors/conversations';
import DumpItemIds from './debug/DumpItemIds';
import UserStatusLabelContainer from '../../containers/communications/UserStatusLabelContainer';
import UnreadLabel from './UnreadLabel';
import StrikeThroughUser from '../../components/user/StrikeThroughUser';
import './ConversationListItem.scss';
import { renderTags, isTagExperimentIndicator } from '../phx/tasks/Tag';
import styled from 'styled-components';
import { experimentTags } from '../../features';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';
import { MESSAGING_PATH, TEAM_PATH, USER_PATH } from '../../routes';

export interface ConversationListItemProps {
  setSelectedUserId(userId: number): void;
  setSelectedConversationId(conversationId: string): void;
  conversationItem: ComputedSortedConversationItem;
  selectedUserId?: number;
  teamId?: string;
}

function formatDate(date?: string | null) {
  if (!date) return '-';
  return moment(date).fromNow();
}

export const TagList = styled.div`
  line-height: 2rem;
  padding-bottom: .5rem;
`;
interface TagListProps {
  userTags?: string[] | null;
  className: string;
}
export const ExpTagList: React.FunctionComponent<TagListProps> = ({
  userTags,
  className
}) => {
  const hasExpTags = userTags?.some(isTagExperimentIndicator);

  return hasExpTags ?
    <TagList className={className}>
      {renderTags(userTags, { maxTags: 2, onlyShowExpTags: true })}
    </TagList>
    : null;
};

const ConversationListItem: React.FC<ConversationListItemProps> = props => {
  const { conversationItem, setSelectedUserId, setSelectedConversationId, selectedUserId } = props;
  const { conversation, user, messagePreview } = conversationItem;

  const scrollParentRef = React.useRef(null);

  const messageTimestamp = messagePreview?.transmittedAt || messagePreview?.createdAt;
  const direction = messagePreview?.direction;
  const seenAt = messagePreview?.seenAt;

  const [isUnread, toggleUnread] = React.useState(false);

  const { teamId } = useParams<{ teamId?: string }>();

  const experimentTagsEnabled = useSelector((state: RootState) =>
    selectTreatmentValue(state.splitReducer, experimentTags) === 'on'
  );

  React.useEffect(() => (direction === MessageDirection.In && !seenAt ? toggleUnread(true) : toggleUnread(false)), [
    isUnread,
    seenAt,
    messagePreview
  ]);

  const handleSelectedUser = () => {
    setSelectedUserId(Number(user.id));
    setSelectedConversationId(conversation?.id);
  };

  return (
    <NavLink
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={
        generatePath(teamId
          ? `${MESSAGING_PATH}${TEAM_PATH}${USER_PATH}`
          : '/messaging',
        { userId: user.id, teamId }
        )
      }
    >
      <div
        onClick={handleSelectedUser}
        className={
          `conversation-item
          ${selectedUserId === user.id ? 'conversation-item--selected' : ''} 
          ${isUnread ? 'conversation-item--unread' : ''}`
        }
        ref={scrollParentRef}
      >

        <img className='conversation-item__img' src={(user && user.avatarUrl) || ''} />
        <div className='conversation-item__contents'>
          <div className='conversation-item__contents__user'>
            <div className='conversation-item__contents__user__info'>
              <div className='conversation-item__contents__user__info__name'>
                <DumpItemIds user={user} conv={conversation} msg={messagePreview} />
                {user && <StrikeThroughUser user={user} />}
              </div>
              <div className='conversation-item__contents__user__info__status'>
                <UserStatusLabelContainer userId={user.id} />
              </div>
            </div>
            {isUnread && (
              <div className='conversation-item__contents__user__unread'>
                <UnreadLabel />
              </div>
            )}
          </div>
          {experimentTagsEnabled && (
            <ExpTagList userTags={user?.tags} className='conversation-item__contents__tags' />
          )}
          <div>
            <div
              className='conversation-item__contents__preview'
              dangerouslySetInnerHTML={{
                __html:
                  messagePreview && messagePreview.body
                    ? sanitizeHtml(messagePreview.body, messagePreviewOptions)
                    : 'No message from this user.'
              }}
            />
            <div className='conversation-item__contents__timestamp'>
              {messagePreview ? formatDate(messageTimestamp) : ''}
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  );
};

const getCircularReplacer = () => {
  const seen = new WeakSet();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (key: any, value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

const areEqual = (prevProps: ConversationListItemProps, nextProps: ConversationListItemProps) =>
  JSON.stringify(prevProps, getCircularReplacer()) === JSON.stringify(nextProps, getCircularReplacer());

export default React.memo(ConversationListItem, areEqual);
