import CoachNavLayout from './CoachNavLayout';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { PrivateRouteProps } from './PrivateRoute';
import { RouteComponentProps } from 'react-router';
import { Grommet } from 'grommet';
import HingeError from '../../components/errors/HingeError';
import NotificationProvider from '../../components/errors/NotificationProvider';
import Notification from '../../components/errors/Notification';
import { connect } from 'react-redux';
import { pubsubActions } from '../../redux/actions';
import { DispatchMap } from '../../redux/actions/interfaces';
import { RootState } from '../../redux/reducers';
import { isSplitConnected } from '../../redux/selectors/split';
import { isSocketConnected } from '../../redux/selectors/pubsub';

interface ConditionalRenderProps {
  routeProps: RouteComponentProps;
  Component?: PrivateRouteProps['component'];
  isGrommet?: boolean;
  children?: React.ReactNode;
  isSplitConnected: boolean;
  isSocketConnected: boolean;
}

type ConnectProps = DispatchMap<{
  createConnection: typeof pubsubActions.createConnection;
  destroyConnection: typeof pubsubActions.destroyConnection;
}>;

type Props = ConditionalRenderProps & ConnectProps;

const getCircularReplacer = () => {
  const seen = new WeakSet();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (key: any, value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

class ConditionalRenderComponent extends React.Component<Props, typeof Object> {

  shouldComponentUpdate(nextProps: Readonly<Props>) {
    return JSON.stringify(nextProps, getCircularReplacer()) !== JSON.stringify(this.props, getCircularReplacer());
  }

  componentDidMount(): void {
    if (this.props.isSplitConnected) {
      this.props.createConnection && this.props.createConnection();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!prevProps.isSplitConnected && this.props.isSplitConnected && !this.props.isSocketConnected) {
      this.props.createConnection && this.props.createConnection();
    }
  }

  componentWillUnmount(): void {
    this.props.destroyConnection && this.props.destroyConnection();
  }

  render() {
    const { Component, children, routeProps, isGrommet } = this.props;
    if (Component && !isGrommet) {
      return (
        <CoachNavLayout {...{...{hideTopBarNav: false}}}>
          <HingeError>
            <NotificationProvider>
              <Component {...routeProps} />
              <Notification />
            </NotificationProvider>
          </HingeError>
        </CoachNavLayout>
      );
    } else if (children) {
      return (
        <CoachNavLayout {...{...{hideTopBarNav: false}}}>
          <HingeError>
            <NotificationProvider>
              {React.cloneElement(React.Children.only(children as React.ReactElement<{}>), routeProps, null)}
              <Notification />
            </NotificationProvider>
          </HingeError>
        </CoachNavLayout>
      );
    } else if (Component && isGrommet) {
      return (
        <Grommet full>
          <CoachNavLayout {...{...{hideTopBarNav: true}}}>
            <HingeError>
              <NotificationProvider>
                <Component {...routeProps} />
                <Notification />
              </NotificationProvider>
            </HingeError>
          </CoachNavLayout>
        </Grommet>
      );
    } else {
      return (
        <Redirect
          push={true}
          to={{
            pathname: '/',
            state: { from: routeProps.location }
          }}
        />
      );
    }
  }
}

const mapStateToProps = (
  state: RootState
) => ({
  isSplitConnected: isSplitConnected(state),
  isSocketConnected: isSocketConnected(state)
});

const mapDispatchToProps = {
  createConnection: pubsubActions.createConnection,
  destroyConnection: pubsubActions.destroyConnection
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConditionalRenderComponent);
