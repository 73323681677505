import styled from 'styled-components';

interface StrikeThroughProps {
  enabled?: boolean;
  'data-testid'?: string;
}

const StrikeThrough = styled.span`
  text-decoration: ${(props: StrikeThroughProps) => props.enabled ? 'line-through' : ''};
`;

export default StrikeThrough;
