import { SurgeryLog } from '../../api-client';
import { RootState } from '../reducers';
import { createSurgeryLogRecord } from '../reducers/surgery-log';
import { selectUser } from './user';
import { createSelector } from 'reselect';
import { isNumber } from '../../utils/number';
import { takeLast } from 'ramda';

export function selectSurgeryLogs(state: RootState) {
  return state.surgeryLog;
}

export function sortSurgeryReverseWeek(a?: SurgeryLog, b?: SurgeryLog) {
  if (a === undefined || !isNumber(a.week_index) || !a.submitted_at) return -1;
  if (b === undefined || !isNumber(b.week_index) || !b.submitted_at) return 1;

  return b.week_index - a.week_index;
}

export const selectSurgeryLogsByUser = createSelector(
  [selectSurgeryLogs, selectUser],
  (surgeryLogs, user) => user && user.surgeryLogs ? user.surgeryLogs.map((id) => surgeryLogs.get(id)) : []
);

export const selectSortedSurgeryLogsFactory = () => createSelector(
  [selectSurgeryLogsByUser],
  (surgeryLogs) => surgeryLogs ? surgeryLogs.sort(sortSurgeryReverseWeek) : []
);

export const selectLastSurgeryLogFactory = () => createSelector(
  [selectSortedSurgeryLogsFactory()],
  (surgeryLogs) => surgeryLogs[0] || createSurgeryLogRecord({ id: NaN })
);

export const selectInitialSurgeryLogFactory = () => createSelector(
  [selectSortedSurgeryLogsFactory()],
  (surgeryLogs) => takeLast(1, surgeryLogs)[0] || createSurgeryLogRecord({ id: NaN })
);
