import { gql } from '@apollo/client';

export const FIND_ALL_MEMBER_ALERTS = gql`
query findAllMemberAlerts($getMemberAlertInput: GetMemberAlertInput!) {
  findAllMemberAlerts(getMemberAlertInput: $getMemberAlertInput) {
    memberAlerts {
      id
      userUuid
      adminUuid
      category
      description
      startAt
      endAt
      remindAt
      createdAt
      updatedAt
      updatedByUuid
      paused
    }
    page
    itemsPerPage
    hasNextPage
    count
  }
}
`;
