import * as React from 'react';
import styled from 'styled-components';
import { List, ListItem, ListItemText, Divider, ListItemIcon } from '@material-ui/core';
import { DateTime } from 'luxon';
import { TEXT_SECONDARY, WHITE } from '../../colors';
import { EtSessionGroupings } from '../../graphql/models/bff/EtSession';
import Sensor from '../../images/sensorSvg';
import Sensorless from '../../images/sensorOffSvg';
import { sortByDate } from '../../utils/user/etSessionSortByDate';
import { sortGroupByCurrentWeek } from '../../utils/user/etSessionGroupingsSortByWeek';
import { isNullOrUndefined } from '../../utils/helpers';

export interface RecentExerciseTherapyProps {
  etSessionGroupings: EtSessionGroupings[];
  timezone?: string | null;
}

const ListContentWrapper = styled.div`
  width: 100%;
`;

const SensorIcons = styled(ListItemIcon)`
  && {
    min-width: 30px;
  }
`;

const StyledList = styled(List)`
  && {
    width: 100%;
    display: flex;
    background-color: ${WHITE};
  }
`;

const StyledListItemWeekNumber = styled(ListItemText)`
  && {
    min-width: 60px;
    padding: 4px 0 4px 0;
    align-items: center;
  }
`;

const DateListItemText = styled(ListItemText)`
  && {
    color: ${TEXT_SECONDARY};
    flex: inherit;
  }
`;

const DefinitionLevelListItemText = styled(ListItemText)`
  && {
    max-width: 60px;
  }
`;

const NoSessionListItemText = styled(ListItemText)`
  && {
    color: ${TEXT_SECONDARY};
  }
`;

const Title = styled.p`
  color: ${TEXT_SECONDARY};
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 32px;
`;

const Wrapper = styled.div`
  padding: 0 20px 0 20px;
`;

const listItemStyles = {
  justifyContent: 'space-between'
};

export const recentExerciseTitle = 'RECENT EXERCISE THERAPY';
export const noSessionMessage = 'No sessions.';
export const etSessionNotStartedMessage = 'Et session not started.';
const tabletOption = (option?: boolean | null) => {
  if (option === null) return 'N/A';

  return option ? 'BYOD' : 'HH Tablet';
};

const etSessionDateTimeFormat = (startedAt: string, timezone: string) =>
  DateTime.fromISO(startedAt, { zone: timezone }).toFormat('EEEE t');

const RecentExerciseTherapy: React.FC<RecentExerciseTherapyProps> = ({ etSessionGroupings, timezone }) => (
  <Wrapper>
    <Title>{recentExerciseTitle}</Title>
    <Divider />
    {etSessionGroupings.sort(sortGroupByCurrentWeek).map(etSessionGroup =>
      etSessionGroup.weekNumber !== null ? (
        <div key={etSessionGroup.weekNumber}>
          <StyledList dense>
            <StyledListItemWeekNumber primary={`Wk ${etSessionGroup.weekNumber}`} />
            <ListContentWrapper>
              {etSessionGroup.etSessions.length ? (
                [...etSessionGroup.etSessions].sort(sortByDate).map(etSession =>
                  (etSession.startedAt && (
                    <ListItem style={listItemStyles} key={`${etSession.startedAt}`}>
                      <DefinitionLevelListItemText primary={`L${etSession.definitionLevel}`} />
                      <SensorIcons>{etSession.sensorless ? <Sensorless /> : <Sensor />}</SensorIcons>
                      <ListItemText data-testid='etSession-BYOD' primary={`${tabletOption(etSession.byod)}`} />
                      <DateListItemText primary={`${!isNullOrUndefined(timezone) ?
                        etSessionDateTimeFormat(etSession.startedAt, timezone) : 'N/A'}`}
                      />
                    </ListItem>
                  ))
                )
              ) : (
                <ListItem style={listItemStyles}>
                  <NoSessionListItemText primary={noSessionMessage} />
                </ListItem>
              )}
            </ListContentWrapper>
          </StyledList>
          <Divider />
        </div>
      ) : (
        <div key={etSessionGroup.weekNumber}>
          <StyledList dense>
            <ListItem style={listItemStyles}>
              <NoSessionListItemText primary={etSessionNotStartedMessage} />
            </ListItem>
          </StyledList>
          <Divider />
        </div>
      )
    )}
  </Wrapper>
);

export default RecentExerciseTherapy;
