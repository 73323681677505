import { PayloadAction, ThunkAction } from './interfaces';
import * as C from '../constants';
import { TasksResponse } from '../../api-client/parsers';
import { NotificationType } from '../../components/errors/NotificationProvider';
import { TaskKind, MassMessageTemplate } from '../../api-client';
import { getApiClient, groupTemplateUserIds } from '../selectors';

export enum TaskSocketEvent {
  TaskCountUpdate = 'task_count_update'
}

export interface ReceiveTaskData {
  kind: string;
  count: number;
}

export interface TaskSocketResponse {
  event: TaskSocketEvent;
  timestamp: string;
  data: ReceiveTaskData[];
}

export interface FormattedUserMessageCount {
  userId: number;
  count: number;
}

export interface ReceiveUnseenMessageCountAction extends PayloadAction<FormattedUserMessageCount> {
  payload: FormattedUserMessageCount;
  type: typeof C.UPDATE_UNREAD_MESSAGE_COUNT;
}
export const DoTaskKinds = [
  TaskKind.threeDayInactive,
  TaskKind.tenDayInactive,
  TaskKind.fifteenDayInactive,
  TaskKind.accountabilityOutreach,
  TaskKind.kitDeliveredNoEt,
  TaskKind.inactiveThisWeek,
  TaskKind.oneWeekInactive,
  TaskKind.inactiveTwoWeeks,
  TaskKind.inactiveThreeWeeks,
  TaskKind.inactiveFourWeeks,
  TaskKind.inactiveFiveWeeks,
  TaskKind.firstEverEtAttempted,
  TaskKind.firstEverEtSessionCompleted,
  TaskKind.firstEtSessionThisWeek,
  TaskKind.thirdEtSessionThisWeek,
  TaskKind.thirteenPlusActiveLastWeek,
  TaskKind.thirteenPlusActiveThisWeek,
  TaskKind.thirteenPlusInactiveLastWeek,
  TaskKind.userTransitionToOnboarding,
  TaskKind.userTransitionToCoaching,
  TaskKind.readyForCoaching
];

interface TaskUserAndCount {
  todoKey: string;
  count: number;
  userId: number;
}

export interface ResetDoTaskAction extends PayloadAction<{ userId: number }> {
  payload: { userId: number };
  type: typeof C.RESET_DO_TASK_USER_COUNT;
}

export const receiveTaskSocketEvent = ({ data }: TaskSocketResponse): ThunkAction<void> => dispatch => {
  const updatedTaskCount: { [key: string]: number } = {};

  data.map(kindAndCount => {
    updatedTaskCount[kindAndCount.kind] = kindAndCount.count;
  });

  return dispatch(receiveUpdatedTaskCount(updatedTaskCount));
};

export interface UpdateCountTask extends PayloadAction<{ [key: string]: number }> {
  payload: { [key: string]: number };
  type: typeof C.UPDATE_TASK_COUNT;
}

// eslint-disable-next-line max-len
export interface AddUnseenMessagesCountAction extends PayloadAction<FormattedUserMessageCount[] | {}> {
  payload: FormattedUserMessageCount[] | {};
  type: typeof C.ADD_UNREAD_MESSAGE_COUNT;
}
// eslint-disable-next-line max-len
export interface UpdateUnseenMessagesCountAction extends PayloadAction<FormattedUserMessageCount[] | {}> {
  payload: FormattedUserMessageCount[] | {};
  type: typeof C.UPDATE_MULTI_UNREAD_MESSAGE_COUNT;
}

export interface UpdateTaskKindResultsAction extends PayloadAction<TaskKindResultsInterface> {
  type: typeof C.UPDATE_TASK_KIND_RESULTS;
}

export interface UpdateTeamResultsAction extends PayloadAction<TeamResultsInterface> {
  type: typeof C.UPDATE_TEAM_RESULTS;
}

export interface ClearTaskUserCountsAction extends PayloadAction<Pick<TaskUserAndCount, 'todoKey'>> {
  payload: Pick<TaskUserAndCount, 'todoKey'>;
  type: typeof C.CLEAR_TASK_USER_COUNTS;
}

export interface AddErrorAction extends PayloadAction<NotificationType> {
  payload: NotificationType;
  type: typeof C.SHOW_NOTIFICATION;
}

export interface ClearErrorAction extends PayloadAction<NotificationType> {
  payload: NotificationType;
  type: typeof C.SHOW_NOTIFICATION;
}

interface TaskKindResultsInterface {
  kind: string;
  teamIds: number[];
  userIds: number[];
  conversationIds: string[];
}

interface TeamResultsInterface {
  teamId: number;
  userIds: number[];
}

interface ToggleTemplateUserId {
  toggleSelect: boolean;
  template: MassMessageTemplate;
  userId: number;
}

export interface ToggleTemplateUserIdAction extends PayloadAction<ToggleTemplateUserId> {
  payload: ToggleTemplateUserId;
  type: typeof C.TOGGLE_SELECT_TEMPLATE_USER_ID;
}

export interface UpdateTaskUserCountAction extends PayloadAction<TaskUserAndCount> {
  payload: TaskUserAndCount;
  type: typeof C.UPDATE_TASK_USER_COUNT;
}

interface TasksUserAndCount {
  tasksWithCount: { [key: string]: number };
  userId: number;
}

export interface UpdateTasksUserCountAction extends PayloadAction<TasksUserAndCount> {
  payload: TasksUserAndCount;
  type: typeof C.UPDATE_MULTI_TASK_USER_COUNT;
}

type TemplateAndUserIds = {
  [key in MassMessageTemplate]?: number[];
};

export interface StoreTemplateUsersAction extends PayloadAction<TemplateAndUserIds> {
  payload: TemplateAndUserIds;
  type: typeof C.STORE_TEMPLATE_USER_IDS;
}
interface SetModalInterface {
  openState: boolean;
}

export interface SetModalAction extends PayloadAction<SetModalInterface> {
  payload: SetModalInterface;
  type: typeof C.UPDATE_MODAL_OPEN;
}

export const receiveUpdatedTaskCount = (params: { [key: string]: number }): ThunkAction<void> => dispatch =>
  dispatch<UpdateCountTask>({
    payload: params,
    type: C.UPDATE_TASK_COUNT
  });

export interface SetInCTPIframeAction {
  payload: { flag: boolean };
  type: typeof C.SET_IN_CTP_IFRAME;
}

export type UiStateAction =
  | UpdateCountTask
  | ReceiveUnseenMessageCountAction
  | UpdateUnseenMessagesCountAction
  | AddUnseenMessagesCountAction
  | ResetDoTaskAction
  | UpdateTasksUserCountAction
  | AddErrorAction
  | ClearErrorAction
  | ClearTaskUserCountsAction
  | UpdateTaskUserCountAction
  | UpdateTaskKindResultsAction
  | StoreTemplateUsersAction
  | ToggleTemplateUserIdAction
  | SetModalAction
  | UpdateTeamResultsAction
  | SetInCTPIframeAction;

export const updateTaskUserAndCount = (payload: TaskUserAndCount): ThunkAction<UpdateTaskUserCountAction> => dispatch =>
  dispatch({ payload, type: C.UPDATE_TASK_USER_COUNT });

export const updateMultiplesTaskUserAndCount = (
  payload: TasksUserAndCount
): ThunkAction<UpdateTasksUserCountAction> => dispatch => dispatch({ payload, type: C.UPDATE_MULTI_TASK_USER_COUNT });

export const clearTaskUserCounts = (
  payload: Pick<TaskUserAndCount, 'todoKey'>
): ThunkAction<ClearTaskUserCountsAction> => dispatch => dispatch({ payload, type: C.CLEAR_TASK_USER_COUNTS });

export const completeUserDOTaskCounts = (userId: number): ThunkAction<ResetDoTaskAction> => dispatch =>
  dispatch({ payload: { userId }, type: C.RESET_DO_TASK_USER_COUNT });

export const storeTaskUsersByTemplate = (templates: MassMessageTemplate[]): ThunkAction<StoreTemplateUsersAction> => (
  dispatch,
  getState
) => {
  const taskUserIdsByTemplate: TemplateAndUserIds = {};

  templates.forEach(template => {
    taskUserIdsByTemplate[template] = groupTemplateUserIds(getState(), template);
  });

  return dispatch({
    payload: taskUserIdsByTemplate,
    type: C.STORE_TEMPLATE_USER_IDS
  });
};

export const setInCtpIframe = (flag: boolean): ThunkAction<SetInCTPIframeAction> => dispatch => dispatch({
  payload: { flag },
  type: C.SET_IN_CTP_IFRAME
});

export const actionCreators = {
  getTaskCountByKind(): ThunkAction<Promise<TasksResponse | void>> {
    return (dispatch, getState) => {
      const api = getApiClient(getState());
      return api.tasks
        .tasksbyCount({})
        .then(res => {
          dispatch<UpdateCountTask>({
            payload: res.taskCounts,
            type: C.UPDATE_TASK_COUNT
          });
        })
        .catch(() => {
          console.log('failed to fetch tasks count');
        });
    };
  }
};

export const toggleSelectTemplateUserId = (
  toggleStatus: boolean,
  id: number,
  template: MassMessageTemplate
): ThunkAction<ToggleTemplateUserIdAction> => dispatch =>
  dispatch({
    payload: { toggleSelect: toggleStatus, userId: id, template },
    type: C.TOGGLE_SELECT_TEMPLATE_USER_ID
  });

export const updateIsOpen = (openState: boolean): ThunkAction<SetModalAction> => dispatch =>
  dispatch({ payload: { openState }, type: C.UPDATE_MODAL_OPEN });
