import * as mixpanelJS from 'mixpanel-browser';
import * as R from 'ramda';

const mixpanel = mixpanelJS as mixpanelJS.Mixpanel;
const token = process.env.REACT_APP_MIXPANEL_KEY || null;
const isTest = process.env.NODE_ENV === 'test' || process.env.NODE_ENV === undefined;

interface MixpanelProperties {
  Origin: string;
  client?: string;
  TaskType?: string | null;
  TaskLink?: string | null;
  PathwayLinkType?: string | null;
  TagType?: string | null;
  CompletionMethod?: string | null;
  GoalId?: string | null;
  AdminUuid?: string | null;
  AdminId?: number | null;
  ConversationType?: string | null;
  Feeling?: string | null;
  StatusType?: string | null;
  FilterBy?: string | null;
  CompanyName?: string | null;
  UserCount?: number;
  NoteAuthor?: string | null;
  NoteId?: number | null;
  NoteLabel?: string | null;
  NoteCreated?: string | null;
  UserUuid?: string | null;
  UserId?: number | null;
  Enabled?: boolean | null;
  VideoMuted?: boolean | null;
  MessageId?: string | number | null;
  ConversationId?: string | null;
}

export const initProperties: MixpanelProperties = {
  Origin: '',
  client: '',
  TaskType: null,
  TaskLink: null,
  PathwayLinkType: null,
  TagType: null,
  CompletionMethod: null,
  GoalId: null,
  AdminUuid: null,
  AdminId: null,
  ConversationType: null,
  Feeling: null,
  StatusType: null,
  FilterBy: null,
  UserCount: 0,
  NoteAuthor: null,
  NoteId: null,
  NoteLabel: null,
  NoteCreated: null,
  UserUuid: null,
  UserId: null,
  Enabled: null,
  VideoMuted: null
};

export const formatOrigin = (pathname: string): string =>
  // strip user ids
  pathname.replace(/\d+\//, '').replace(/\/$/, '');

export const mixpanelInit = (properties: MixpanelProperties) => {
  if (token && !isTest) {
    mixpanel.init(token);
    mixpanel.register(properties);
  }
};

export const mixpanelTrack = (
  event: string,
  properties: MixpanelProperties
) => {
  if (!token || isTest) return;

  mixpanelInit(properties);

  const { Origin } = properties;
  const formattedOrigin = formatOrigin(Origin);
  const propsWithNull = R.mergeDeepLeft(properties, initProperties);
  const propertiesToReport = { ...propsWithNull, Origin: formattedOrigin };

  mixpanel.track(event, propertiesToReport);
};
