import * as React from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment-timezone';
import { Schedule } from '@material-ui/icons';
import { Box, TextField, Typography } from '@material-ui/core';
import { actionCreators as messagingActions } from '../../redux/actions/messages';
import { DispatchMap } from '../../redux/actions/interfaces';
import { selectMessage } from '../../redux/selectors/messages';
import { MessageRecord, RootState } from '../../redux/reducers/';
import ModalWrapper from '../modal/ModalWrapper';
import { MAX_CHARACTER_COUNT } from '../../containers/communications/MessageComposerContainer';
import * as sanitizeHtml from 'sanitize-html';
import { sanitizeOptions } from '../../utils/messaging/sanitize';
import { useNewMessagingBffUpdateMessageEndpoint } from '../../features';
import { selectFeatureFlags } from '../../redux/selectors';

const REG_EXP_SIGNATURE = /<(\w+)\s[^>]*class=\"coach-signature\"[^>]*>[\s\S]*\1>.*\/>$/;

interface EditScheduledMessageModalProps {
  userTimezone: string;
  conversationId: string;
  messageId: string;
  userId: number;
  userUuid?: string;
  showEditModal: boolean;
  setShowEditModal(visible: boolean): void;
}

export type ConnectProps = DispatchMap<{
  message?: MessageRecord;
  editScheduledMessage: typeof messagingActions.updateMessage;
  useNewMessagingBffEndpoint: boolean;
}>;

type Props = EditScheduledMessageModalProps & ConnectProps;

// TODO: CT-1282 deprecate reapplying native coach signature
export const applyCoachSignature = (
  messageToEdit: string,
  originalBody?: string | null
): string => {
  const coachSignatureMatch = originalBody && originalBody.match(REG_EXP_SIGNATURE);
  return coachSignatureMatch && coachSignatureMatch.length ?
    `${messageToEdit}${coachSignatureMatch[0]}` : messageToEdit;
};

const stripCoachSignature = (body: string) => body.replace(REG_EXP_SIGNATURE, '');

export const cleanMessageBody = (body: MessageRecord['body']) => {
  if (!body) return '';
  return sanitizeHtml(stripCoachSignature(body), sanitizeOptions);
};

export const EditScheduleMessageModal: React.FC<Props> = ({
  conversationId,
  userTimezone,
  message,
  userId,
  userUuid,
  showEditModal,
  setShowEditModal,
  editScheduledMessage,
  useNewMessagingBffEndpoint
}) => {
  if (!message) return null;
  const { id, body } = message;

  const editableBody = cleanMessageBody(body);

  const [editedMessageBody, setEditedMessageBody] = React.useState<string>(
    editableBody
  );
  const [submitDisabled, toggleSubmitDisable] = React.useState<boolean>(true);
  const [characterCount, setCharacterCount] = React.useState(
    MAX_CHARACTER_COUNT - editableBody.length
  );

  const dismissModal = () => setShowEditModal(false);

  const handleCharacterCount = (composedBody: string) => {
    setCharacterCount(MAX_CHARACTER_COUNT - composedBody.length);
  };

  const handleEditMessageBody = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const editedBody = sanitizeHtml(e.target.value, sanitizeOptions);

    setEditedMessageBody(editedBody);

    handleCharacterCount(editedBody);

    editedBody === editableBody || !editedBody.length
      ? toggleSubmitDisable(true)
      : toggleSubmitDisable(false);
  };

  const handleSubmitEditScheduledMessage = () => {
    // TODO: CT-1282 deprecate reapplying native coach signature
    const editedBody = applyCoachSignature(editedMessageBody, body);

    editScheduledMessage(
      userId,
      { ...message, body: editedBody },
      { id, conversationId },
      userUuid,
      useNewMessagingBffEndpoint
    ).then(() => {
      dismissModal();
    });
  };

  const modalContent = {
    title: 'Edit Scheduled Message',
    body: (
      <Box padding='10px'>
        <Typography
          variant="subtitle2"
          style={{ paddingBottom: '20px' }}
          color="textSecondary"
        >{`User's local time: ${moment()
            .tz(userTimezone)
            .format('LT z')}`}</Typography>
        <Box>
          <TextField
            fullWidth={true}
            multiline={true}
            rows={5}
            variant={'outlined'}
            onChange={handleEditMessageBody}
            value={editedMessageBody}
          />
          <Box justifyContent="start" style={{ paddingTop: '20px' }}>
            <Typography variant="subtitle2">{`Character count: ${characterCount}`}</Typography>
          </Box>
        </Box>
        <div
          style={{ paddingTop: '10px', display: 'flex', alignContent: 'center', gap: '30px', flexWrap: 'wrap' }}
        >
          <Box>
            <Schedule/>
          </Box>
          <Box>
            <Typography variant="body2">
              {(message.scheduledFor &&
                moment(message.scheduledFor)
                  .tz(userTimezone)
                  .format('MM/DD/YYYY')) ||
                ''}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">
              {(message.scheduledFor &&
                moment(message.scheduledFor)
                  .tz(userTimezone)
                  .format('hh:mm a')) ||
                ''}
            </Typography>
          </Box>
          <Typography variant="body2">
            {moment.tz(userTimezone).format('z')}
          </Typography>
        </div>
      </Box>
    ),
    confirmBtnText: 'Save',
    dismissBtnText: 'Cancel'
  };

  return (
    <ModalWrapper
      modalContent={modalContent}
      disableConfirmButton={submitDisabled}
      onConfirm={handleSubmitEditScheduledMessage}
      onDismiss={dismissModal}
      openModal={showEditModal}
    />
  );
};

const mapStateToProps = (
  state: RootState,
  props: EditScheduledMessageModalProps
) => ({
  message: selectMessage(state, { messageId: props.messageId }),
  useNewMessagingBffEndpoint: selectFeatureFlags(state).get(useNewMessagingBffUpdateMessageEndpoint)
});

const mapDispatchToProps = {
  editScheduledMessage: messagingActions.updateMessage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditScheduleMessageModal);
