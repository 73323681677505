import { RootAction } from '../actions';
import {
  SET_CONNECTION_STATUS
} from '../constants/index';
import { recordify, TypedRecord } from 'typed-immutable-record';

interface PubsubState {
  disconnected: boolean;
}

const pubsubDefaults: PubsubState = {
  disconnected: true
};

export interface State extends TypedRecord<State>, PubsubState {}

export const initialState = recordify<PubsubState, State>(pubsubDefaults);

export const reducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case SET_CONNECTION_STATUS:
      return state.set('disconnected', action.payload.disconnected);
    default:
      return state;
  }
};
