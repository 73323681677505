import * as React from 'react';
import {
  Grid,
  Icon,
  Header,
  Accordion,
  Label,
  Segment,
  Message,
  SemanticCOLORS
} from 'semantic-ui-react';
import styled from 'styled-components';

// Task Actions
import TaskCompleteModal from '../../containers/tasks/TaskCompleteModal';
import { getKindColor } from '../../utils/tasks';
import { TaskKind } from '../../api-client/interfaces';
import { TaskRecord } from '../../redux/reducers';

import TaskTitle from './TaskTitle';
import TaskCardContainer from '../../containers/tasks/TaskCardContainer';

interface TaskGroupProps {
  tasks?: TaskRecord[];
  taskCount?: number;
  kind: TaskKind;
  completed?: boolean;
  inProfile?: boolean;
  showSnackBar?(msg: string): void;
}

interface State {
  open: boolean;
  overflowOpen: boolean;
  taskCompleteModalOpened: boolean;
  isRejection: boolean;
  rescheduleModalOpened: boolean;
}

interface DivProp {
  readonly completed?: boolean;
  readonly inProfile?: boolean;
}
export const StyledDiv = styled.div<DivProp>`
  max-width: 620px;
  text-decoration: ${props => (props.completed ? 'line-through' : 'inherited')};
  margin: ${props => (props.inProfile ? 'none' : 'auto')};
`;

/**
 * TaskGroup - houses away groups of tasks in an accordion. Accepted props:
 *
 * @param inProfile<boolean>: passes to task card. hides user info from task
 * @param kind<string>: kind of task, used in title
 * @param tasks<TaskRecord[]>: list of task objects
 * @param taskCount<number>: number of tasks
 */
class TaskGroup extends React.PureComponent<TaskGroupProps, State> {
  state: State = {
    open: false,
    overflowOpen: false,
    taskCompleteModalOpened: false,
    isRejection: false,
    rescheduleModalOpened: false
  };

  handleClick = () => {
    this.setState({
      open: !this.state.open
    });
  };

  handleOpenOverflow = () => {
    this.setState({
      overflowOpen: true
    });
  };

  handleCheckClick = () => {
    this.setState({ taskCompleteModalOpened: true, overflowOpen: false });
  };

  handleOpenRejectionClick = () => {
    this.setState({
      taskCompleteModalOpened: true,
      isRejection: true,
      overflowOpen: false
    });
  };

  handleCloseCheckModal = () =>
    this.setState({
      taskCompleteModalOpened: false,
      isRejection: false
    });

  render() {
    // TODO: CT-2213 use window location instead of inProfile
    const { tasks, kind, completed, taskCount, inProfile } = this.props;
    const taskIds = tasks && tasks.map(task => task.id);

    // change color based on completion status
    const extraProps: { color?: SemanticCOLORS } = {};
    if (completed) {
      extraProps.color = 'green';
    }
    return (
      <StyledDiv inProfile={!!inProfile}>
        <TaskCompleteModal
          taskIds={taskIds}
          taskKind={kind}
          isRejection={this.state.isRejection}
          open={this.state.taskCompleteModalOpened}
          onClose={this.handleCloseCheckModal}
        />
        <Accordion>
          <Accordion.Title
            active={this.state.open}
            onClick={this.handleClick}
            style={{ padding: 0 }}
          >
            <StyledDiv completed={!!completed}>
              <Grid
                as={Segment}
                color={getKindColor(kind, { isSemantic: true })}
                style={{ paddingBottom: 0, margin: 0 }}
              >
                <Grid.Row columns={3} verticalAlign="middle">
                  <Grid.Column width={3} />
                  <Grid.Column width={10}>
                    <Header as="h1" size="small" style={{ display: 'inline' }}>
                      <TaskTitle kind={kind} />
                    </Header>
                    <Label style={{ marginLeft: 15 }} circular>
                      {taskCount || 0}
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={2} floated="right">
                    {this.state.open ? (
                      <Icon name="caret down" />
                    ) : (
                      <Icon name="caret left" />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </StyledDiv>
          </Accordion.Title>
          <Accordion.Content active={this.state.open} style={{ paddingTop: 0 }}>
            {tasks &&
              tasks.map(task => {
                if (task && !task.kind) {
                  return (
                    <Message negative content="Task kind doesn't exist!" />
                  );
                }
                return (
                  <TaskCardContainer
                    key={task.id}
                    task={task}
                    expanded={this.state.open}
                    {...this.props}
                    grouped
                  />
                );
              })}
          </Accordion.Content>
        </Accordion>
      </StyledDiv>
    );
  }
}

export default TaskGroup;
