import * as React from 'react';
import styled from 'styled-components';
import {
  FormControl,
  InputLabel,
  Select,
  Chip,
  Input,
  MenuItem,
  Typography,
  TextField
} from '@material-ui/core';
import * as Moment from 'moment';
import { CarePlanStatus, Icd10, Prescription } from '../../api-client/interfaces';
import Datepicker from './Datepicker';
import { DischargeReasons } from './CarePlansContainer';

export interface Props {
  status: CarePlanStatus | '';
  selectedOpenDate: Moment.Moment | null;
  onOpenDateChange(date: Moment.Moment | null): void;
  selectedExpectedDischargeDate: Moment.Moment | null;
  onExpectedDischargeDateChange(date: Moment.Moment | null): void;
  selectedClosedDate: Moment.Moment | null;
  onClosedDateChange(date: Moment.Moment | null): void;
  dischargeReason: DischargeReasons | '';
  onDischargeReasonChange(event: React.ChangeEvent<{ value: string }>): void;
  prescription: Prescription | '';
  onPrescriptionChange(event: React.ChangeEvent<{ value: string }>): void;
  diagnoses: string[];
  onDiagnosesChange(event: React.ChangeEvent<{ value: unknown }>): void;
  diagnosesOptions: Icd10[];
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 250px;
`;

const StyledDatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;

const StyledFormControl = styled(FormControl)`
  width: 200px;
`;

const StyledChips = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 6;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200
    }
  }
};

const StyledTextField = styled(TextField)`
  width: 200px;
`;

const StyledDischargeReasonTextField = styled(TextField)`
  max-width: 100%;
`;

const CreateCarePlanDetailsModalContent: React.FC<Props> = ({
  status,
  selectedOpenDate,
  onOpenDateChange,
  selectedExpectedDischargeDate,
  onExpectedDischargeDateChange,
  selectedClosedDate,
  onClosedDateChange,
  dischargeReason,
  onDischargeReasonChange,
  prescription,
  onPrescriptionChange,
  onDiagnosesChange,
  diagnoses,
  diagnosesOptions
}) => {
  const isStatusOpen = status === CarePlanStatus.Open;
  const [openDateFocused, setOpenDateFocused] = React.useState<boolean>(false);

  const handleOpenDateFocusChange = ({ focused }: Record<string, boolean | null>) => {
    setOpenDateFocused(focused || false);
  };

  const [expectedDischargeDateFocused, setExpectedDischargeDateFocused] = React.useState<boolean>(false);

  const handleExpectedDischargeDateFocusChange = ({ focused }: Record<string, boolean | null>) => {
    setExpectedDischargeDateFocused(focused || false);
  };

  const [closedDateFocused, setClosedDateFocused] = React.useState<boolean>(false);

  const handleClosedDateFocusChange = ({ focused }: Record<string, boolean | null>) => {
    setClosedDateFocused(focused || false);
  };

  return (
    <StyledContainer>
      <StyledDatePickerContainer>
        {selectedOpenDate && (
          <Typography variant='caption' color='textSecondary'>
            Date opened
          </Typography>
        )}
        <Datepicker
          date={selectedOpenDate}
          onDateChange={onOpenDateChange}
          focused={openDateFocused}
          onFocusChange={handleOpenDateFocusChange}
          id='open-date'
          placeholder='Date opened'
          numberOfMonths={1}
        />
      </StyledDatePickerContainer>
      <StyledDatePickerContainer>
        {isStatusOpen ? (
          <>
            {selectedExpectedDischargeDate && (
              <Typography variant='caption' color='textSecondary'>
                Expected discharge
              </Typography>
            )}
            <Datepicker
              date={selectedExpectedDischargeDate}
              onDateChange={onExpectedDischargeDateChange}
              focused={expectedDischargeDateFocused}
              onFocusChange={handleExpectedDischargeDateFocusChange}
              id='expected-discharge-date'
              placeholder='Expected discharge'
            />
          </>
        ) : (
          <>
            {selectedClosedDate && (
              <Typography variant='caption' color='textSecondary'>
                Date closed
              </Typography>
            )}
            <Datepicker
              date={selectedClosedDate}
              onDateChange={onClosedDateChange}
              focused={closedDateFocused}
              onFocusChange={handleClosedDateFocusChange}
              id='closed-date'
              placeholder='Date closed'
            />
          </>
        )}
      </StyledDatePickerContainer>
      {isStatusOpen ? (
        <></>
      ) : (
        <StyledDischargeReasonTextField
          select
          label='Discharge reason'
          value={dischargeReason}
          onChange={onDischargeReasonChange}
          disabled={status === CarePlanStatus.Closed}
        >
          {Object.entries(DischargeReasons).map((item: string[]) => (
            <MenuItem
              key={item[0]}
              value={item[1]}
              style={{ whiteSpace: 'normal' }}
            >
              <Typography variant='subtitle2'>{item[1]}</Typography>
            </MenuItem>
          ))}
        </StyledDischargeReasonTextField>
      )}
      <StyledFormControl margin='dense'>
        <InputLabel>Diagnoses</InputLabel>
        <Select
          multiple
          value={diagnoses}
          onChange={onDiagnosesChange}
          input={<Input />}
          renderValue={selected => (
            <StyledChips>
              {(selected as string[]).map(value => (
                <Chip key={value} label={value} />
              ))}
            </StyledChips>
          )}
          MenuProps={MenuProps}
        >
          {diagnosesOptions.map((item: Icd10) => (
            <MenuItem
              key={item.code}
              value={item.description}
              style={{ whiteSpace: 'normal' }}
            >
              <Typography variant='subtitle2'>{item.description}</Typography>
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
      <StyledTextField
        select
        label='Prescription'
        value={prescription}
        onChange={onPrescriptionChange}
      >
        {Object.entries(Prescription).map((item: string[]) => (
          <MenuItem
            key={item[0]}
            value={item[1]}
            style={{ whiteSpace: 'normal' }}
          >
            <Typography variant='subtitle2'>{item[1]}</Typography>
          </MenuItem>
        ))}
      </StyledTextField>
    </StyledContainer>
  );
};

export default CreateCarePlanDetailsModalContent;
