import * as React from 'react';
import { Location } from 'history';
import styled from 'styled-components';
import { ERR_USER_ID_INVALID } from '../../../errors';
import UserProfileBannerContainer from './UserProfileBannerContainer';
import UserNavBar from '../../../components/phx/nav/UserNavBar';
import PathwayStatsMenu from './PathwayStatsMenu';
import EngagementStatsContainer from './EngagementStatsContainer';
import PathwayLinksContainer from './PathwayLinksContainer';

const Content = styled.div`
  margin-bottom: 2rem;
`;

export interface WrappedProps {
  location?: Location;
  id?: number;
}

const PhxUserEngagementContainer: React.FunctionComponent<WrappedProps> = ({ location, id }) => {
  if (id === undefined) throw new Error(ERR_USER_ID_INVALID);
  return (
    <Content>
      <div style={{ width: '95%', margin: 'auto' }}>
        <UserProfileBannerContainer id={id} />
        <UserNavBar location={location} id={id} />
        <PathwayStatsMenu pathname={location ? location.pathname : ''} userId={id} />
        <EngagementStatsContainer id={id} />
        <PathwayLinksContainer id={id} />
      </div>
    </Content>
  );
};

export default PhxUserEngagementContainer;
