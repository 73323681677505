import { useQuery } from '@apollo/client';
import * as React from 'react';
import styled from 'styled-components';
import { Container, Typography, Table, TableHead, TableRow, TableCell, TableBody, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import { mixpanelTrack } from '../../mixpanel/mixpanel';
import {
  Admin,
  StateRegulationCopy,
  StateRegulation,
  StateAbbreviationsMap
} from '../../api-client/interfaces';
import {
  GET_STATE_REGULATIONS,
  GET_USER_STATE_OF_RESIDENCE
} from '../../graphql/queries/User';
import { ApiClientNames } from '../apollo/ApolloContainer';
import { selectAdmin } from '../../redux/selectors/admin';

interface Props {
  userId: number;
}

interface StateRegulationsProps {
  stateOfResidence: string;
}

const StyledContainer = styled(Container)`
  padding: 24px;
`;

const StyledTable = styled(Table)`
  .MuiTableCell-root {
    border-bottom: none;
  }
`;

const StyledDiv = styled.div`
  padding: 16px;
`;

export const StateRegulations: React.FC<StateRegulationsProps> = ({ stateOfResidence }) => {
  const { data } = useQuery<{ stateRegulations: StateRegulation[] }, { stateOfResidence: string }>(
    GET_STATE_REGULATIONS,
    {
      variables: { stateOfResidence },
      context: { clientName: ApiClientNames.BFF }
    }
  );
  const regulations = data?.stateRegulations || [];

  if (!regulations.length) return null;

  const formatValue = (value: string) => {
    const formattedValue = capitalize(value);
    if (!formattedValue || formattedValue === 'No') {
      return 'No';
    } else {
      return 'Yes';
    }
  };

  const regulationRows = regulations.map((regulation) => {
    const formattedValue = formatValue(regulation.value);

    return StateRegulationCopy[regulation.key] && (
      <TableRow key={regulation.key}>
        <TableCell>
          <Typography variant="body1">{StateRegulationCopy[regulation.key]}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1">
            <Box fontWeight={formattedValue === 'Yes' && 'fontWeightBold'}>{formattedValue}</Box>
          </Typography>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <StyledDiv>
        <Typography variant="body1">
          {`State of residence: ${StateAbbreviationsMap[stateOfResidence]} (${stateOfResidence})`}
        </Typography>
      </StyledDiv>
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body1"><strong>Regulation</strong></Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1"><strong>Action required?</strong></Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {regulationRows}
        </TableBody>
      </StyledTable>
    </>
  );
};

const StateRegulationsContainer: React.FC<Props> = ({ userId }) => {
  const [currentAdmin] = React.useState<Admin>(useSelector(selectAdmin));
  const { data } = useQuery<{ getUserById: { stateOfResidence: string } }, { userId: number }>(
    GET_USER_STATE_OF_RESIDENCE,
    {
      variables: { userId },
      context: { clientName: ApiClientNames.BFF }
    }
  );

  React.useEffect(() => {
    mixpanelTrack('State Regulations Viewed', {
      Origin: '/state-regulations',
      AdminId: currentAdmin.id,
      UserId: userId
    });
  }, []);

  if (data?.getUserById?.stateOfResidence) {
    return (
      <StyledContainer maxWidth={false}>
        <StateRegulations stateOfResidence={data.getUserById.stateOfResidence} />
      </StyledContainer>
    );
  }

  return null;
};

export default StateRegulationsContainer;
