import * as moment from 'moment';
import { Achievement } from 'src/api-client/interfaces';

type AchievementTypes =
  | 'activityStreak'
  | 'threePlaylistStreak'
  | 'exerciseReps'
  | 'celebrations';

export type AchievementKeys =
  | 'reps_100'
  | 'reps_250'
  | 'reps_500'
  | 'reps_1000'
  | 'reps_2000'
  | 'activity_streak_2_week'
  | 'activity_streak_4_week'
  | 'activity_streak_8_week'
  | 'activity_streak_20_week'
  | 'activity_streak_52_week'
  | 'three_playlist_streak_1_week'
  | 'three_playlist_streak_3_week'
  | 'three_playlist_streak_5_week'
  | 'three_playlist_streak_10_week'
  | 'three_playlist_streak_25_week'
  | 'weekend_wellness'
  | 'team_player'
  | 'active_birthday';

export const isAchievementsConfigKey = (str: string): str is AchievementKeys =>
  achievementsConfig.hasOwnProperty(str);

export const getAchievementsCompletedThisWeek = (achievements: Achievement[]) => {
  const today = moment();
  return achievements.filter(achievement => {
    if (!achievement.achievedDate) {
      return false;
    }
    const achievedDate = moment(achievement.achievedDate);
    const daysAgo = today.diff(achievedDate, 'days');
    return daysAgo > 7 ? false : true;
  });
};

export type AchievementsByType = {[key in AchievementTypes]: Achievement[]};

export const getAchievementsByType = (achievements: Achievement[]): AchievementsByType => {
  const sortedAchievements: AchievementsByType  = {
    activityStreak: [],
    threePlaylistStreak: [],
    exerciseReps: [],
    celebrations: []
  };

  achievements.map((achievement) => {
    const { key } = achievement;
    if(!isAchievementsConfigKey(key)) {
      return;
    }
    const config = achievementsConfig[key];
    sortedAchievements[config.type].push(achievement);
  });

  return sortedAchievements;
};

export interface AchievementConfig {
  type: AchievementTypes;
  section: string;
  title: string;
};

export type AchievementsConfig = {
  [key in AchievementKeys]: AchievementConfig;
};

export const achievementsConfig: AchievementsConfig = {
  activity_streak_2_week: {
    type: 'activityStreak',
    section: 'Activity streak',
    title: '2-week streak'
  },
  activity_streak_4_week: {
    type: 'activityStreak',
    section: 'Activity streak',
    title: '4-week streak'
  },
  activity_streak_8_week: {
    type: 'activityStreak',
    section: 'Activity streak',
    title: '8-week streak'
  },
  activity_streak_20_week: {
    type: 'activityStreak',
    section: 'Activity streak',
    title: '20-week streak'
  },
  activity_streak_52_week: {
    type: 'activityStreak',
    section: 'Activity streak',
    title: '52-week streak'
  },
  three_playlist_streak_1_week: {
    type: 'threePlaylistStreak',
    section: '3x playlist streak',
    title: '1-week streak'
  },
  three_playlist_streak_3_week: {
    type: 'threePlaylistStreak',
    section: '3x playlist streak',
    title: '3-week streak'
  },
  three_playlist_streak_5_week: {
    type: 'threePlaylistStreak',
    section: '3x playlist streak',
    title: '5-week streak'
  },
  three_playlist_streak_10_week: {
    type: 'threePlaylistStreak',
    section: '3x playlist streak',
    title: '10-week streak'
  },
  three_playlist_streak_25_week: {
    type: 'threePlaylistStreak',
    section: '3x playlist streak',
    title: '25-week streak'
  },
  reps_100: {
    type: 'exerciseReps',
    section: 'Exercise reps',
    title: '100 reps'
  },
  reps_250: {
    type: 'exerciseReps',
    section: 'Exercise reps',
    title: '250 reps'
  },
  reps_500: {
    type: 'exerciseReps',
    section: 'Exercise reps',
    title: '500 reps'
  },
  reps_1000: {
    type: 'exerciseReps',
    section: 'Exercise reps',
    title: '1000 reps'
  },
  reps_2000: {
    type: 'exerciseReps',
    section: 'Exercise reps',
    title: '2000 reps'
  },
  weekend_wellness: {
    type: 'celebrations',
    section: 'Celebrations',
    title: 'Weekend wellness'
  },
  active_birthday: {
    type: 'celebrations',
    section: 'Celebrations',
    title: 'Active birthday'
  },
  team_player: {
    type: 'celebrations',
    section: 'Celebrations',
    title: 'Team player'
  }
};

