import * as moment from 'moment';
import { TrackingStatus } from '../../api-client';
import { User } from '../../graphql/models';

export const lateDelivery = (estimatedDeliveryAt: string) =>
  moment(estimatedDeliveryAt).isBefore(Date.now()) ? 'LATE' : null;

export const trackingStatus = (user: User) => {
  if (user.latestShipment) {
    return (
      lateDelivery(user.latestShipment.estimatedDeliveryAt) ||
      user.latestShipment.trackingStatus
    );
  }
  return '';
};

export const getTrackingStatus = (status?: string | null) => {
  switch(status) {
    case 'UNKNOWN':
      return TrackingStatus.UNKNOWN;
    case 'PRE_TRANSIT':
      return TrackingStatus.PRE_TRANSIT;
    case 'TRANSIT':
      return TrackingStatus.TRANSIT;
    case 'DELIVERED':
      return TrackingStatus.DELIVERED;
    case 'RETURNED':
      return TrackingStatus.RETURNED;
    case 'FAILURE':
      return TrackingStatus.FAILURE;
    case 'AVAILABLE_FOR_PICKUP':
      return TrackingStatus.AVAILABLE_FOR_PICKUP;
    case 'CANCELLED':
      return TrackingStatus.CANCELLED;
    case 'LATE':
      return TrackingStatus.LATE;
    default:
      return 'Null';
  }
};
