import * as React from 'react';
import {
  Typography
} from '@material-ui/core';
import styled from 'styled-components';

import { GoalRecord } from '../../redux/reducers';
import GoalSegments from './GoalSegments';
import ToggleGoalAddButton from './ToggleGoalAddButton';
import GoalAddContainer from '../../containers/user/GoalAddContainer';
import { UserRecord } from '../../redux/reducers/user';

const GoalsContent = styled.div`
  margin-top: 0rem;
  margin-bottom: 0rem;
`;

export interface UserGoalsSectionProps {
  user?: UserRecord;
  goals: GoalRecord[];
}

interface State {
  closeAddForm: boolean;
  openAddForm: boolean;
}

class UserGoalsSection extends React.PureComponent<UserGoalsSectionProps, State> {
  state = {
    closeAddForm: false,
    openAddForm: false
  };

  handleCloseForm = () => this.setState({ openAddForm: false });

  handleOpenForm = () => this.setState({ openAddForm: true, closeAddForm: false });

  render() {
    if (!this.props.user) return null;
    const userPending = !this.props.user.firstName;
    const { goals } = this.props;
    return (
      <GoalsContent>
        <Typography variant="h5" gutterBottom>
          {userPending ? 'Loading…' : `${this.props.user.firstName}'s Goals`}
          {goals.length > 0 && <ToggleGoalAddButton
            isAddFormDisplayed={this.state.openAddForm}
            onClose={this.handleCloseForm}
            onOpen={this.handleOpenForm}
          />}
        </Typography>
        {((goals.length === 0 || this.state.openAddForm) && !this.state.closeAddForm) && <GoalAddContainer
          userId={this.props.user.id} onClose={this.handleCloseForm} />}
        {goals.length > 0 && <GoalSegments userId={this.props.user.id} goals={goals} />}
      </GoalsContent>
    );
  }
}

export default UserGoalsSection;
