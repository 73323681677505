import * as React from 'react';
import { useQuery } from '@apollo/client';
import { Loader } from 'semantic-ui-react';
import { GET_USER_STATUS } from '../../graphql/queries/User';
import { User } from '../../graphql/models';
import UserStatusLabel from '../../components/communications/UserStatusLabel';
import { FIND_ALL_MEMBER_ALERTS } from '../../graphql/queries/MemberAlert';
import { ApiClientNames } from '../../containers/apollo/ApolloContainer';
import MemberAlertIcon from '../../components/memberalerts/MemberAlertIcon';

interface Props {
  userId: number;
  uuid?: string | null;
}

interface Response {
  user: User;
}

interface Variables {
  id: string;
}

const UserStatusLabelContainer: React.FC<Props> = ({ userId, uuid }) => {
  const { loading, error, data } = useQuery<Response, Variables>(GET_USER_STATUS, {
    variables: { id: userId.toString() }
  });

  let memberAlerts;
  let memberAlertLoading;
  if (!!uuid) {
    const {loading: findMemberAlertsLoading, data: allMemberAlertData } = useQuery(FIND_ALL_MEMBER_ALERTS, {
      variables: {
        getMemberAlertInput: {
          userUuids: [uuid],
          status: ['Active', 'Upcoming']
        }
      },
      context: { clientName: ApiClientNames.NestBFF }
    });

    memberAlertLoading = findMemberAlertsLoading;
    memberAlerts = allMemberAlertData?.findAllMemberAlerts?.memberAlerts;
  }

  const showMemberAlerts = memberAlerts && memberAlerts?.length > 0;

  if (loading || memberAlertLoading) return <Loader size='small' active />;

  if (error) throw new Error('Unable to fetch user status');

  return showMemberAlerts ? (
    <MemberAlertIcon
      memberAlerts={memberAlerts}
    />
  ) : (
    <UserStatusLabel status={data?.user?.userStatus} />
  );
};

export default UserStatusLabelContainer;
