import { gql } from '@apollo/client';

export const EDIT_USER_INFO = `
  mutation editUserInfo($user: LegacyUserType!) {
    editUserInfo(user: $user)
  }
`;

export const CHANGE_ENROLLMENT_STATUS = gql`
  mutation PostStatus(
    $status: String, 
    $userUuid: String
  ) {
    createStatus(
      status: $status,
      userUuid: $userUuid
    ) {
      status
    }
  }
`;

export const CREATE_USER_ENROLLMENT_STATUS = gql`
  mutation createStatus($userUuid: String!, $status: String!) {
    createStatus(userUuid: $userUuid, status: $status) {
      id
      status
      createdAt
      userUuid
    }
  }
`;
