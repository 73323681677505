import * as React from 'react';
import * as moment from 'moment-timezone';
import * as sanitizeHtml from 'sanitize-html';
import { Schedule } from '@material-ui/icons';
import { Box, TextField, Typography } from '@material-ui/core';
import { DispatchThunk, messageV2Actions } from '../../../redux/actions';
import { MessageV2Record } from '../../../redux/reducers';
import ModalWrapper from '../../modal/ModalWrapper';
import { MAX_CHARACTER_COUNT } from '../../../containers/communications/MessageComposerContainer';
import { sanitizeOptions } from '../../../utils/messaging/sanitize';
import { useDispatch } from 'react-redux';

interface Props {
  userTimezone: string;
  message: MessageV2Record;
  updateMessage: typeof messageV2Actions.updateMessage;
  showEditModal: boolean;
  setShowEditModal(visible: boolean): void;
}

const EditScheduledMessageModalV2: React.FC<Props> = ({
  userTimezone,
  message,
  showEditModal,
  updateMessage,
  setShowEditModal
}) => {
  if (!message) return null;
  const dispatch: DispatchThunk = useDispatch();

  const { uuid, body, sendAt } = message;

  const [editedMessageBody, setEditedMessageBody] = React.useState<string>(body);
  const [submitDisabled, setSubmitDisabled] = React.useState<boolean>(true);
  const [characterCount, setCharacterCount] = React.useState(
    MAX_CHARACTER_COUNT - editedMessageBody.length
  );

  const dismissModal = () => setShowEditModal(false);

  const handleCharacterCount = (composedBody: string) => {
    setCharacterCount(MAX_CHARACTER_COUNT - composedBody.length);
  };

  const handleEditMessageBody = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const editedBody = sanitizeHtml(e.target.value, sanitizeOptions);

    setEditedMessageBody(editedBody);

    handleCharacterCount(editedBody);

    editedBody === body || !editedBody.length
      ? setSubmitDisabled(true)
      : setSubmitDisabled(false);
  };

  const handleSubmitEditScheduledMessage = () => {
    dispatch(updateMessage({
      uuid,
      body: editedMessageBody,
      sendAt
    })).then(() => {
      dismissModal();
    });
  };

  const modalContent = {
    title: 'Edit Scheduled Message',
    body: (
      <Box padding='10px'>
        <Typography
          variant="subtitle2"
          style={{ paddingBottom: '20px' }}
          color="textSecondary"
        >{`User's local time: ${moment()
            .tz(userTimezone)
            .format('LT z')}`}</Typography>
        <Box>
          <TextField
            fullWidth={true}
            multiline={true}
            rows={5}
            variant={'outlined'}
            onChange={handleEditMessageBody}
            value={editedMessageBody}
          />
          <Box justifyContent="start" style={{ paddingTop: '20px' }}>
            <Typography variant="subtitle2">{`Character count: ${characterCount}`}</Typography>
          </Box>
        </Box>
        <div
          style={{ paddingTop: '10px', display: 'flex', alignContent: 'center', gap: '30px', flexWrap: 'wrap' }}
        >
          <Box>
            <Schedule/>
          </Box>
          <Box>
            <Typography variant="body2">
              {(sendAt &&
                moment(sendAt)
                  .tz(userTimezone)
                  .format('MM/DD/YYYY')) ||
                ''}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">
              {(sendAt &&
                moment(sendAt)
                  .tz(userTimezone)
                  .format('hh:mm a')) ||
                ''}
            </Typography>
          </Box>
          <Typography variant="body2">
            {moment.tz(userTimezone).format('z')}
          </Typography>
        </div>
      </Box >
    ),
    confirmBtnText: 'Save',
    dismissBtnText: 'Cancel'
  };

  return (
    <ModalWrapper
      modalContent={modalContent}
      disableConfirmButton={submitDisabled}
      onConfirm={handleSubmitEditScheduledMessage}
      onDismiss={dismissModal}
      openModal={showEditModal}
    />
  );
};

export default EditScheduledMessageModalV2;
