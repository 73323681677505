import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Search as SearchIcon } from '@material-ui/icons';
import {
  Button,
  Grid,
  Typography,
  Tab,
  Tabs,
  TextField,
  InputAdornment
} from '@material-ui/core';
import { debounce } from 'lodash';
import { RootState } from '../../../redux/reducers';
import { selectFeatureFlags } from '../../../redux/selectors';
import OnboardingContainer from './OnboardingContainer';
import TeamTabPanel from '../../../components/phx/teams/TeamTabPanel';
import TeamTableContainer from './TeamTableContainer';
import { MESSAGING_PATH } from '../../../routes';
import { nonOnboardedUsersView } from '../../../features';

const ButtonWrapper = styled.div`
  display: inline-block;
  padding-top: 4px;
`;

const HeaderWrapper = styled.div`
  float: left; 
  padding-top: 6px;
`;

const StyledTableContainerWrapper = styled.div` 
  padding-top: 40px;
  width: 100%;
`;

const StyledGridRoot = styled(Grid)`
  padding: 16px 40px;
`;

const StyledTab = styled(Tab)`
  && {
    max-width: 120px;
  }  
`;

const StyledTabs = styled(Tabs)`
  && {
    height: 48px;
    margin-top: 24px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

interface TeamsContainerProps {
  featureFlags: Map<string, object>;
}

const TeamsContainer: React.FC<TeamsContainerProps> = ({ featureFlags }) => {
  const [isSearching, setIsSearching] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [tabIndex, setTabIndex] = React.useState<number>(0);

  const onboardingView = tabIndex === 1;

  const handleRedirect = () => {
    window.open(MESSAGING_PATH);
  };

  const handleTabChange = (e: unknown, value: number) => {
    setTabIndex(value);
  };

  const setDebouncedValue = debounce((value: string) => {
    setSearchValue(value);
  }, 500);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDebouncedValue(e.target.value);
    setIsSearching(!!e.target.value.length);
  };

  const TeamsHeaderContainer = () => (
    <>
      <Grid item md={2} xs={3}>
        <HeaderWrapper>
          <Typography display='inline' align="left" variant="h4" component="h1">
            My Teams
          </Typography>
        </HeaderWrapper>
      </Grid>
      <Grid item md={7} xs={3}>
        <ButtonWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRedirect}
          >
        Messaging
          </Button>
        </ButtonWrapper>
      </Grid>
      <Grid item md={3} xs={6}>
        <TextField
          id="outlined-size-small"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          onChange={handleSearchChange}
          disabled={tabIndex === 1}
        />
      </Grid>
    </>
  );

  return (
    <>
      { featureFlags.get(nonOnboardedUsersView) ? (
        <StyledGridRoot container>
          {TeamsHeaderContainer()}
          <Grid item xs={12} md={12}>
            <StyledTabs
              value={tabIndex}
              indicatorColor='primary'
              textColor='primary'
              variant="fullWidth"
              onChange={handleTabChange}
              aria-label='Teams tabs'
            >
              <StyledTab label='All' data-testid="all-tab"/>
              <StyledTab label='Onboarding' data-testid="onboarding-tab" />
            </StyledTabs>
            <TeamTabPanel value={tabIndex} index={0}>
              {!onboardingView && <TeamTableContainer
                isSearching={isSearching}
                onboardingView={onboardingView}
                searchValue={searchValue}
              />}
            </TeamTabPanel>
            <TeamTabPanel value={tabIndex} index={1}>
              {onboardingView && <OnboardingContainer
                isSearching={isSearching}
                onboardingView={onboardingView}
                searchValue={searchValue}
              />}
            </TeamTabPanel>
          </Grid>
        </StyledGridRoot>
      ) : (
        <StyledGridRoot container>
          {TeamsHeaderContainer()}
          <StyledTableContainerWrapper>
            <TeamTableContainer
              isSearching={isSearching}
              onboardingView={onboardingView}
              searchValue={searchValue}
            />
          </StyledTableContainerWrapper>
        </StyledGridRoot>
      )
      }
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  featureFlags: selectFeatureFlags(state)
});

export default connect(mapStateToProps)(TeamsContainer);
