import * as React from 'react';
import * as moment from 'moment-timezone';
import { Table } from 'semantic-ui-react';
import { ScreenerRecord } from '../../redux/reducers';
import { SurgeryLog } from '../../api-client';
import { formatDiff, isNumber } from '../../utils/number';

interface SurgeryTableProps {
  surgeryLogs: SurgeryLog[];
  screener: SurgeryLog | ScreenerRecord;
  timezone: string;
}

enum CellWidths {
  Week = 1,
  Surgery = 1,
  Note = 6,
  Timestamp = 3
}

/* eslint-disable max-len */
export const makeAllTimeSurgeryDiff = (
  {
    surgery_likelihood_1yr,
    surgery_likelihood_2yr,
    surgery_likelihood_5yr
  }: SurgeryLog,
  screener: SurgeryLog | ScreenerRecord
) => ({
  surgery1yrDiff:
    isNumber(surgery_likelihood_1yr) &&
      isNumber(screener.surgery_likelihood_1yr)
      ? surgery_likelihood_1yr - screener.surgery_likelihood_1yr
      : NaN,
  surgery2yrDiff:
    isNumber(surgery_likelihood_2yr) &&
      isNumber(screener.surgery_likelihood_2yr)
      ? surgery_likelihood_2yr - screener.surgery_likelihood_2yr
      : NaN,
  surgery5yrDiff:
    isNumber(surgery_likelihood_5yr) &&
      isNumber(screener.surgery_likelihood_5yr)
      ? surgery_likelihood_5yr - screener.surgery_likelihood_5yr
      : NaN
});

export const formatScreenerDate = (screener: ScreenerRecord | SurgeryLog, timezone: string) => {
  if (screener.submitted_at) {
    return moment(screener.submitted_at)
      .tz(timezone)
      .format('LLL z');
  }
  if (screener.created_at) {
    return moment(screener.created_at)
      .tz(timezone)
      .format('LLL z');
  }

  return '-';
};

export const displaySurgeryLikelihood = (surgery_number?: number | null) =>
  isNumber(surgery_number) ? surgery_number : '–';

function renderTableRows({ surgeryLogs, screener, timezone }: SurgeryTableProps) {
  return surgeryLogs.map(s => {
    const allTimeDiffs = makeAllTimeSurgeryDiff(s, screener);

    return (
      <Table.Row key={s.id}>
        <Table.Cell singleLine>
          {s.week_index ? `Week ${s.week_index}` : ''}
        </Table.Cell>
        <Table.Cell>
          {displaySurgeryLikelihood(s.surgery_likelihood_1yr)}
        </Table.Cell>
        <Table.Cell
          positive={allTimeDiffs.surgery1yrDiff < 0}
          negative={allTimeDiffs.surgery1yrDiff > 0}
        >
          {formatDiff(allTimeDiffs.surgery1yrDiff)}
        </Table.Cell>
        <Table.Cell>
          {displaySurgeryLikelihood(s.surgery_likelihood_2yr)}
        </Table.Cell>
        <Table.Cell
          positive={allTimeDiffs.surgery2yrDiff < 0}
          negative={allTimeDiffs.surgery2yrDiff > 0}
        >
          {formatDiff(allTimeDiffs.surgery2yrDiff)}
        </Table.Cell>
        <Table.Cell>
          {displaySurgeryLikelihood(s.surgery_likelihood_5yr)}
        </Table.Cell>
        <Table.Cell
          positive={allTimeDiffs.surgery5yrDiff < 0}
          negative={allTimeDiffs.surgery5yrDiff > 0}
        >
          {formatDiff(allTimeDiffs.surgery5yrDiff)}
        </Table.Cell>
        <Table.Cell singleLine={false}>{s.surgery_free_text}</Table.Cell>
        <Table.Cell singleLine>
          {s.submitted_at
            ? moment(s.submitted_at)
              .tz(timezone)
              .format('LLL z')
            : 'N/A'}
        </Table.Cell>
      </Table.Row>
    );
  });
}

const renderScreeningRow = ({ screener, timezone }: SurgeryTableProps) => (
  <Table.Row>
    <Table.Cell>Screener</Table.Cell>
    <Table.Cell>
      {isNumber(screener.surgery_likelihood_1yr)
        ? screener.surgery_likelihood_1yr
        : '–'}
    </Table.Cell>
    <Table.Cell>–</Table.Cell>
    <Table.Cell>
      {isNumber(screener.surgery_likelihood_2yr)
        ? screener.surgery_likelihood_2yr
        : '–'}
    </Table.Cell>
    <Table.Cell>–</Table.Cell>
    <Table.Cell>
      {isNumber(screener.surgery_likelihood_5yr)
        ? screener.surgery_likelihood_5yr
        : '–'}
    </Table.Cell>
    <Table.Cell>–</Table.Cell>
    <Table.Cell>{screener.surgery_free_text}</Table.Cell>
    <Table.Cell>{formatScreenerDate(screener, timezone)}</Table.Cell>
  </Table.Row>
);

const SurgeryTable: React.FunctionComponent<SurgeryTableProps> = props => (
  <Table celled striped>
    <Table.Header>
      <Table.HeaderCell width={CellWidths.Week}>Week</Table.HeaderCell>
      <Table.HeaderCell width={CellWidths.Surgery}>1 Yr</Table.HeaderCell>
      <Table.HeaderCell width={CellWidths.Surgery}>
        1 Yr. All-Time
      </Table.HeaderCell>
      <Table.HeaderCell width={CellWidths.Surgery}>2 Yr.</Table.HeaderCell>
      <Table.HeaderCell width={CellWidths.Surgery}>
        2 Yr. All-Time
      </Table.HeaderCell>
      <Table.HeaderCell width={CellWidths.Surgery}>5 Yr.</Table.HeaderCell>
      <Table.HeaderCell width={CellWidths.Surgery}>
        5 Yr. All-Time
      </Table.HeaderCell>
      <Table.HeaderCell width={CellWidths.Note}>Free Text</Table.HeaderCell>
      <Table.HeaderCell width={CellWidths.Timestamp}>
        Local Timestamp
      </Table.HeaderCell>
    </Table.Header>
    <Table.Body>
      {renderTableRows(props)}
      {renderScreeningRow(props)}
    </Table.Body>
  </Table>
);

export default SurgeryTable;
