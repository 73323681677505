import { onError } from '@apollo/client/link/error';
import { fromPromise } from 'apollo-link';
import { GraphQLError } from 'graphql';
import { store } from '../store';
import { DispatchThunk, authActions } from '../redux/actions';

let pendingRequests: Function[] = [];
const storage = window.localStorage;

const setIsRefreshing = (value: boolean) => storage.setItem('isRefreshing', `${value}`);
setIsRefreshing(false);

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback());
  pendingRequests = [];
};

const addPendingRequest = (pendingRequest: Function) => {
  pendingRequests.push(pendingRequest);
};

const dispatchThunk: DispatchThunk = store.dispatch;

interface NetworkError extends Error {
  statusCode?: number;
}
// @ts-ignore
const errorLink = () => onError(({
  response,
  graphQLErrors,
  networkError,
  operation,
  forward
}) => {
  const nwError = networkError ? networkError as NetworkError : null;
  const isRefreshing = storage.getItem('isRefreshing');

  if (graphQLErrors) {
    graphQLErrors.forEach((err: GraphQLError) => console.warn('GQL Error:', err.message, err.locations, err.path));
  }

  if (nwError && nwError?.statusCode === 401) {
    let forwardOperations;
    if (!isRefreshing || isRefreshing === 'false') {
      setIsRefreshing(true);

      forwardOperations = fromPromise(
        dispatchThunk(authActions.refresh())
          .then(() => {
            resolvePendingRequests();
            return true;
          }).catch(() => {
            pendingRequests = [];
            return false;
          })
          .finally(() => {
            setIsRefreshing(false);
          })
      ).filter(value => Boolean(value));
    } else {
      forwardOperations = fromPromise(
        new Promise(resolve => {
          addPendingRequest(() => resolve(undefined));
        })
      );
    }

    return forwardOperations.flatMap(() => forward(operation));
  }
});

export default errorLink;
