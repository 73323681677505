import { gql } from '@apollo/client';
import ApiClient, { AuthInfo } from './ApiClient';
import { v4 as uuidv4 } from 'uuid';
import { ApiClientNames, apolloClient } from '../containers/apollo/ApolloContainer';
import {
  ERR_MISCONFIGURED_ENDPOINT,
  ERR_GET_JSON,
  ERR_MISSING_URI_PARAMS,
  ERR_CONNECTION_FAIL,
  ERR_UNAUTHORIZED,
  ERR_FORBIDDEN
} from '../errors';

import { parseCookie } from '../utils/cookie';
import { formatAuthorizationHeader, getOktaAccessToken } from '../utils/helpers';

export type CacheInfo = 'default' | 'no-store' | 'reload' | 'no-cache' | 'force-cache' | undefined;

export interface EndpointConfig<P, T> {
  authenticated?: boolean;
  bff?: boolean;
  nestBff?: boolean;
  method?: string;
  headers?: {
    [key: string]: string;
  };
  cache?: CacheInfo;
  uriPath?: string;
  uriTemplate?(p: P): string;
  isQuery?: boolean;
  isMutation?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse(data?: any): T;
}

interface Payload {
  query?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables?: any;
}

export type Endpoint<P, T> = (payload?: object, uriParams?: P, authInfo?: AuthInfo) => Promise<T>;

const defaultPayload = {};

export function createEndpoint<P, T>(apiClient: ApiClient, endpointConfig: EndpointConfig<P, T>): Endpoint<P, T> {
  const config: EndpointConfig<P, T> = {
    authenticated: true,
    method: 'GET',
    headers: {
      'x-hh-request-id': uuidv4()
    },
    cache: undefined,
    ...endpointConfig
  };

  if (config.uriPath === undefined && config.uriTemplate === undefined) {
    throw new Error(ERR_MISCONFIGURED_ENDPOINT);
  }

  function constructHeaders(authInfo?: AuthInfo) {
    return config.authenticated
      ? { ...parseCookie(document.cookie), ...config.headers, ...authInfo }
      : { ...config.headers, ...authInfo };
  }
  function constructReqOptions(payload: object, retryCount: number, authInfo?: AuthInfo): Promise<RequestInit> {
    const headers = constructHeaders(authInfo) as { [k: string]: string };
    const oktaAccessToken = getOktaAccessToken();
    const { cache, method } = config;

    if (oktaAccessToken) {
      headers.authorization = formatAuthorizationHeader(oktaAccessToken);
    }

    if (method === 'GET' && Object.keys(payload).length !== 0) {
      throw new Error(ERR_GET_JSON);
    } else {
      headers['content-type'] = 'application/json';
    }

    return Promise.resolve({
      method,
      mode: 'cors',
      referrerPolicy: 'no-referrer',
      headers: new Headers(headers),
      body: Object.keys(payload).length > 0 ? JSON.stringify(payload) : undefined,
      cache,
      redirect: 'follow'
    });
  }

  async function fetchEndpoint(payload: Payload = defaultPayload, uriParams?: P, authInfo?: AuthInfo): Promise<T> {
    const baseUri = config.bff ? apiClient.getBffUri() : apiClient.getBaseUri();
    try {
      // route query to the old bff
      if (config.bff && config.isQuery) {
        return apolloClient
          .query({
            query: gql`
              ${payload.query}
            `,
            variables: payload.variables,
            context: { clientName: ApiClientNames.BFF },
            fetchPolicy: 'network-only'
          })
          .then((result: unknown) => config.parse(result));
      }

      if (config.bff && config.isMutation) {
        return apolloClient
          .mutate({
            mutation: gql`
              ${payload.query}
            `,
            variables: payload.variables,
            context: { clientName: ApiClientNames.BFF },
            fetchPolicy: 'no-cache'
          })
          .then((result: unknown) => config.parse(result));
      }

      // route query to the nest bff
      if (config.nestBff && config.isQuery) {
        return apolloClient
          .query({
            query: gql`
              ${payload.query}
            `,
            variables: payload.variables,
            context: { clientName: ApiClientNames.NestBFF },
            fetchPolicy: 'network-only'
          })
          .then((result: unknown) => config.parse(result));
      }

      if (config.nestBff && config.isMutation) {
        return apolloClient
          .mutate({
            mutation: gql`
              ${payload.query}
            `,
            variables: payload.variables,
            context: { clientName: ApiClientNames.NestBFF },
            fetchPolicy: 'no-cache'
          })
          .then((result: unknown) => config.parse(result));
      }

      const options = await constructReqOptions(payload, 0, authInfo);

      const constructUri = () => {
        if (config.uriTemplate) {
          if (uriParams === undefined) throw new Error(ERR_MISSING_URI_PARAMS);
          return `${baseUri}${config.uriTemplate(uriParams)}`;
        } else {
          return `${baseUri}${config.uriPath}`;
        }
      };
      return fetch(constructUri(), config.bff ? { ...options, ...{ credentials: 'include' } } : options)
        .then(resp => {
          if (resp.ok) {
            return resp.status === 204 ? {} : resp.json();
          }
          return Promise.reject(resp);
        })
        .then(data => config.parse(data))
        .catch(err => {
          if (err.status === 401) {
            return Promise.reject(ERR_UNAUTHORIZED);
          } else if (err.status === 403) {
            return Promise.reject(Object.assign(new Error(ERR_FORBIDDEN), err));
          } else if (err.message === 'Failed to fetch') {
            return Promise.reject(ERR_CONNECTION_FAIL);
          } else {
            return Promise.reject(Object.assign(new Error(err.statusText), err));
          }
        });
    } catch (e) {
      return Promise.reject(e);
    }
  }

  return fetchEndpoint;
}
