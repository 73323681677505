import  * as React from 'react';
import { useHingeHealthSecurityContext } from '.';

interface CapabilityProps {
  /** an array of `can` permissions will be treated as an && conditional */
  can: string | string[];
  children: React.ReactNode;
}

const Capability = ({ can, children }: CapabilityProps): JSX.Element => {
  const { capabilities } = useHingeHealthSecurityContext();
  const [showChildren, setShowChildren] = React.useState(false);

  React.useEffect(() => {
    const allowedActions = capabilities.userCapabilities;
    /** If `can` is an empty string or array then we show the children since
     * there is no 'permission' required to see it */
    if (can.length === 0) {
      setShowChildren(true);
    } else if (allowedActions) {
      if (typeof can === 'string' && allowedActions.has(can)) {
        setShowChildren(true);
      } else if (Array.isArray(can)) {
        const hasAllPerms = can.every(perm => allowedActions.has(perm));
        setShowChildren(hasAllPerms);
      } else {
        setShowChildren(false);
      }
    } else {
      setShowChildren(false);
    }
  }, [capabilities.userCapabilities, can]);

  return <>{showChildren ? children : null}</>;
};

export default Capability;
