import { SET_ADMIN, REMOVE_ADMIN } from '../constants';
import { PayloadAction, ThunkAction, VoidAction } from './interfaces';
import { Admin } from '../../api-client';
import { getApiClient } from '../selectors';
import { ERR_ADMIN_PARSE_FAIL } from '../../errors';
import { AdminURIParams } from '../../api-client/endpoints';
import { GET_ADMIN } from '../../graphql/queries';

export interface SetAdminAction extends PayloadAction<Admin> {
  payload: Admin;
  type: typeof SET_ADMIN;
}

export interface RemoveAdminAction extends VoidAction {
  type: typeof REMOVE_ADMIN;
}

export const actionCreators = {
  getAdmin(params: AdminURIParams): ThunkAction<Promise<Admin>> {
    return (dispatch, getState) => {
      const api = getApiClient(getState());
      return api.admin.get({ query: GET_ADMIN, variables: { id: params.id } })
        .then(res => {
          if (res.data === false) {
            throw new Error(ERR_ADMIN_PARSE_FAIL);
          }

          dispatch<SetAdminAction>({ type: SET_ADMIN, payload: res.data.admin });

          return res.data.admin;
        })
        .catch(e => {
          throw new Error(`${ERR_ADMIN_PARSE_FAIL}: ${e}`);
        });
    };
  }
};
