import { useLazyQuery } from '@apollo/client';

import * as React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import TeamAccordion from '../../../components/phx/teams/TeamAccordion';
import { GET_ONBOARDING_TEAMS } from '../../../graphql/queries/Teams';
import { ApiClientNames } from '../../apollo/ApolloContainer';
import { sortTeamByCurrentWeek } from '../../../utils/helpers';

import { Onboarding } from '../../../graphql/models/Onboarding';

const StyledDiv = styled.div`
  color: grey;
  position: absolute;
  left: 50%; 
  top: 60%;
`;

export const ErrorMessage = 'Failed to load teams.';
export const EmptyTeams = 'No teams to display.';
interface OnboardingContainerProps {
  isSearching: boolean;
  searchValue: string;
  onboardingView: boolean;
}

interface Response {
  teams: Onboarding[];
}

const OnboardingContainer: React.FC<OnboardingContainerProps> = ({
  isSearching,
  searchValue,
  onboardingView
}) => {
  const [getOnboardingTeams, { loading, error, data }] = useLazyQuery<Response, { onboarding: boolean }>(
    GET_ONBOARDING_TEAMS,
    {
      variables: { onboarding: true },
      context: { clientName: ApiClientNames.BFF },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-and-network'
    }
  );

  React.useEffect(() => {
    if (onboardingView && !data && !error) {
      getOnboardingTeams();
    }
  }, [onboardingView, data, error]);

  if (error) return <StyledDiv>{ErrorMessage}</StyledDiv>;

  if (loading) {
    return <StyledDiv>
      <CircularProgress />
    </StyledDiv>;
  }

  if (!data || !data.teams.length) return <StyledDiv>{EmptyTeams}</StyledDiv>;

  return isSearching ?
    // TODO: Add search results functionality https://hingehealth.atlassian.net/browse/ATL-163
    (<TeamAccordion teams={data.teams.slice().sort(sortTeamByCurrentWeek)} onboardingView/> ) :
    (<TeamAccordion teams={data.teams.slice().sort(sortTeamByCurrentWeek)} onboardingView/>);
};

export default OnboardingContainer;
