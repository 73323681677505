import * as React from 'react';
import { ApiClientNames } from '../apollo/ApolloContainer';
import { CircularProgress } from '@material-ui/core';
import { EmptyData, ErrorMessage, StyledDiv } from './RecentExerciseTherapyContainer';
import { EnsoUsage } from '../../graphql/models/User';
import { EnsoUtilization } from '../../components/communications/EnsoUtilization';
import { GET_USER_ENSO_USAGE } from '../../graphql/queries/User';
import { useQuery } from '@apollo/client';

interface Response {
  ensoTwoWeekUsage?: EnsoUsage | null;
}

interface Props {
  userUuid: string | undefined;
}

export const EnsoUtilizationContainer: React.FC<Props> = ({ userUuid }) => {
  const { loading, error, data } = useQuery<Response, { userUuid: string | undefined }>(
    GET_USER_ENSO_USAGE, {
      variables: { userUuid },
      context: { clientName: ApiClientNames.BFF }
    });

  if (loading) {
    return <StyledDiv>
      <CircularProgress data-testid='circular-progress' />
    </StyledDiv>;
  }

  if (error) {
    return <StyledDiv>
      {ErrorMessage}
    </StyledDiv>;
  }

  if (!data || !data.ensoTwoWeekUsage) {
    return <StyledDiv>
      {EmptyData}
    </StyledDiv>;
  }

  return (
    <>
      <EnsoUtilization ensoUsage={data.ensoTwoWeekUsage} />
    </>
  );
};
