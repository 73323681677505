import * as React from 'react';
import { Tabs, Tab, ThemeContext } from 'grommet';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import './ConversationTabContainer.scss';
import MessageListContainer from './MessageListContainer';
import MessageComposerContainer from './MessageComposerContainer';
import {
  RootState,
  ConversationRecord
} from '../../redux/reducers';
import {
  selectConversation,
  makeSelectUserConversationsFactory
} from '../../redux/selectors';
import { isProduction } from '../../components/communications/debug/DumpItemIds';
import { ConversationKind } from '../../utils/conversations/helpers';

const ConversationTabStyles = {
  tabs: {
    header: {
      extend: {
        padding: '0 0 0 10px',
        fontSize: '14px !important',
        borderBottom: 'solid 1px #CFD8DC',
        display: 'flex',
        justifyContent: 'flex-start'
      }
    },
    panel: {
      extend: {
        height: '100%'
      }
    },
    extend: {
      minWidth: '425px',
      paddingTop: '12px'
    }
  }
};

interface ConversationTabContainerProps {
  userId: number;
  activeConversationId: string;
  setActiveConversationId(conversationId: string): void;
}

interface ConnectProps {
  conversations: ConversationRecord[];
  activeConversation?: ConversationRecord;
}

type Props = ConnectProps & ConversationTabContainerProps;

export const conversationTabOrder: ConversationKind[] = [
  ConversationKind.SMS,
  ConversationKind.NATIVE
];

export const getActiveIndex = (
  conversationType?: string | null,
  conversationLength?: number
): number => !conversationLength ||
  conversationLength >= conversationTabOrder.length ||
  conversationType
  ? conversationTabOrder.indexOf(conversationType as ConversationKind)
  : 0;

const getTabTitle = (type?: string | null) => {
  switch (type) {
    case ConversationKind.SMS:
      return 'SMS';
    case ConversationKind.NATIVE:
      return 'NATIVE';
    default:
      return '';
  }
};

const getTabTitleWithId = (id?: string, type?: string | null) => {
  if (!isProduction) return `convId: ${id} ${getTabTitle(type)}`;
  return getTabTitle(type);
};

export const getConversationTabOrder = (
  type: ConversationKind,
  conversationOrder: ConversationKind[]
) => {
  const convoTypeIdx = conversationOrder.indexOf(type);

  return convoTypeIdx === -1 ? conversationOrder.length : convoTypeIdx;
};

const sortConversationByTabOrder = (
  a: ConversationRecord,
  b: ConversationRecord
) => {
  if (!a || !a.type) return 1;
  if (!b || !b.type) return -1;
  const aTypeIndex = getConversationTabOrder(
    a.type as ConversationKind,
    conversationTabOrder
  );
  const bTypeIndex = getConversationTabOrder(
    b.type as ConversationKind,
    conversationTabOrder
  );

  if (aTypeIndex < bTypeIndex) return -1;
  if (aTypeIndex > bTypeIndex) return 1;

  return 0;
};

const ConversationTabContainer: React.FC<Props> = ({
  userId,
  activeConversation,
  activeConversationId,
  setActiveConversationId,
  conversations
}) => {
  const sortedConversations = conversations.sort(sortConversationByTabOrder);
  const [activeTab, setActiveTab] = React.useState<number>(0);

  const switchTabs = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  React.useEffect(() => {
    const conversationToSwitch = sortedConversations[activeTab];
    setActiveConversationId(conversationToSwitch.id);
  }, [activeTab, sortedConversations]);

  return (
    <ThemeContext.Extend value={ConversationTabStyles}>
      <Tabs className='tabs-container' onActive={switchTabs} activeIndex={activeTab}>
        {sortedConversations
          .filter(conversation => conversation.type === ConversationKind.SMS)
          .map(conversation => (
            <Tab
              key={conversation.id}
              title={
                process.env.DEBUG_MODE
                  ? getTabTitleWithId(conversation.id, conversation.type)
                  : getTabTitle(conversation.type)
              }
            >
              <div className='tab-content-container'>
                <div className='message-list-box'>
                  <MessageListContainer
                    key={conversation.id}
                    conversationId={conversation.id}
                    userId={userId}
                    activeConversationId={activeConversationId}
                  />
                </div>
                <div className='message-composer-box'>
                  <MessageComposerContainer
                    key={conversation.id}
                    userId={userId}
                    conversationId={activeConversationId}
                  />
                </div>
              </div>
            </Tab>
          ))}
      </Tabs>
    </ThemeContext.Extend>
  );
};

const mapStateToProps = (state: RootState, props: ConversationTabContainerProps) => {
  const selectUserConversations = makeSelectUserConversationsFactory();
  return {
    conversations: selectUserConversations(state, { userId: props.userId }),
    activeConversation: selectConversation(state, {
      conversationId: props.activeConversationId
    })
  };
};

export default connect(mapStateToProps)(React.memo(ConversationTabContainer, isEqual));
