import * as React from 'react';
import { connect } from 'react-redux';
import { SurgeryLog, User } from '../../api-client';
import { userActions } from '../../redux/actions';
import { DispatchMap } from '../../redux/actions/interfaces';
import {
  selectUser,
  selectScreener,
  selectInitialSurgeryLogFactory,
  selectSortedSurgeryLogsFactory
} from '../../redux/selectors';
import { RootState, ScreenerRecord } from '../../redux/reducers';
import SurgeryTable from '../../components/charts/SurgeryTable';
import { Segment, Header, Message } from 'semantic-ui-react';
import { DEFAULT_TIMEZONE } from '../../utils/user/timezone';
import { Store } from 'redux';
import { dropLast } from 'ramda';
import * as moment from 'moment-timezone';

interface SurgeryStatsSegmentProps {
  store?: Store<RootState>;
  userId: number;
}

interface StateProps {
  user?: User;
  screener: ScreenerRecord;
  initialLog: SurgeryLog;
  surgeryLogs: SurgeryLog[];
}

type DispatchProps = DispatchMap<{
  getPathwayStats: typeof userActions.getPathwayStats;
}>;

type Props = SurgeryStatsSegmentProps & StateProps & DispatchProps;

export const isScreenerAndInitialLogSame = (screener: ScreenerRecord, initLog: SurgeryLog, timezone: string) => {
  const screenerDate = screener.created_at || '';
  const initLogDate = initLog.submitted_at || '';

  return moment.tz(screenerDate, timezone).isSame(moment.tz(initLogDate, timezone), 'day');
};

class SurgeryStatsSegment extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.getPathwayStats(this.props.userId);
  }

  getProductType = (user: User) => (user.team && user.team.productType) ? user.team.productType : '';

  getTimezone = (user: User) => user.timezone || DEFAULT_TIMEZONE;

  render() {
    if (!this.props.user) return null;

    const timezone = this.getTimezone(this.props.user);
    const headerText = this.props.user.firstName ? `${this.props.user.firstName}'s Surgery` : undefined;
    const surgeryLogsWithoutInitialLog: SurgeryLog[] = dropLast(1, this.props.surgeryLogs);
    const logsForSurgeryTable = isScreenerAndInitialLogSame(this.props.screener, this.props.initialLog, timezone) ?
      surgeryLogsWithoutInitialLog : this.props.surgeryLogs;
    const screener = isScreenerAndInitialLogSame(this.props.screener, this.props.initialLog, timezone) ?
      this.props.initialLog : this.props.screener;

    return (
      <Segment>
        <Header>{headerText}</Header>
        {this.props.screener
          ?
          <SurgeryTable
            timezone={timezone}
            screener={screener}
            surgeryLogs={logsForSurgeryTable}
          />
          : <Message error icon="exclamation" header="Screener data is missing!" />
        }
      </Segment>
    );
  }
}

const mapStateToProps = (state: RootState, props: SurgeryStatsSegmentProps): StateProps => {
  const selectSortedSurgeryLogs = selectSortedSurgeryLogsFactory();
  const selectInitialSurgeryLog = selectInitialSurgeryLogFactory();
  return {
    initialLog: selectInitialSurgeryLog(state, props),
    user: selectUser(state, props),
    screener: selectScreener(state, props),
    surgeryLogs: selectSortedSurgeryLogs(state, props)
  };
};

const mapDispatchToProps = {
  getPathwayStats: userActions.getPathwayStats
};

export default connect(mapStateToProps, mapDispatchToProps)(SurgeryStatsSegment);
