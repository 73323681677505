import { gql } from '@apollo/client';

export const GET_ADMIN = `
  query admin($id: Int) {
    admin(id: $id)
  }
`;

export const GET_ADMIN_CARE_PLANS = gql`
  query GetAdminCarePlans($itemsPerPage: Int, $page: Int) {
    admin {
      carePlanData(itemsPerPage: $itemsPerPage, page: $page) {
        hasNextPage       
        carePlans {
          id
          userId
          title
          status
          items {
            id
            key
            currentValue
          }
          icd10 {
            code
            description
          }
          expectedDischargeAt
          openedAt
          closedAt
        }
      }
    }
  }
`;
