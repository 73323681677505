import { TypedRecord, makeTypedFactory } from 'typed-immutable-record';
import { Map } from 'immutable';

import { Goal } from '../../api-client';
import { RootAction } from '../actions';
import * as C from '../constants';
import { mergeUpdate, createStringEntityMap } from '../../utils/entity';

export interface GoalRecord extends TypedRecord<GoalRecord>, Goal {}

export const createGoalRecord = makeTypedFactory<Goal, GoalRecord>({
  id: '',
  content: undefined,
  completed_at: undefined,
  // TODO: ATL-312 will handle doneBy vs done_by key inconsistency from BFF
  done_by: undefined,
  doneBy: undefined,
  status: undefined
});

export type State = Map<string, GoalRecord>;

export const initialState: State = Map();

export const reducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case C.ENTITIES_FETCHED:
      return action.payload.entities.goals
        ? mergeUpdate(
          state,
          createStringEntityMap(
            createGoalRecord,
            action.payload.entities.goals
          )
        )
        : state;
    case C.UPDATE_GOAL:
      const updateGoal = action.payload.goal;
      return updateGoal ? state.set(updateGoal.id, updateGoal) : state;
    case C.DELETE_GOAL:
      const goalId = action.payload.goalId;
      if (!goalId) return state;
      return state.delete(goalId);
    default:
      return state;
  }
};
