import * as React from 'react';
import { Box } from 'grommet';
import { connect } from 'react-redux';
import { DispatchMap } from '../../redux/actions/interfaces';
import { isEqual } from 'lodash';

import { actionCreators as messagingActions } from '../../redux/actions/messages';
import { RootState, MessageRecord, UserRecord } from '../../redux/reducers';
import MessageList from '../../components/communications/MessageList';
import {
  selectConversationMessages,
  selectUser,
  selectConversationScheduledMessages,
  selectConversationTotalPages,
  selectConversationCurrentPage,
  selectFeatureFlags
} from '../../redux/selectors';
import { isNullOrUndefined } from '../../utils/helpers';
import { Loader } from 'semantic-ui-react';
import { useNewMessagingBffEndpoint } from '../../features';

const INITIAL_PAGE = 0;

export type ConnectProps = DispatchMap<{
  scheduledMessages: MessageRecord[];
  messages: MessageRecord[];
  totalPages?: number | null;
  currentPage?: number | null;
  getMessages: typeof messagingActions.getMessages;
  getScheduledMessages: typeof messagingActions.getScheduledMessages;
  user?: UserRecord;
  useNewMessagingBffEndpoint?: boolean;
}>;

interface MessagesListContainerProps {
  conversationId: string;
  activeConversationId: string;
  userId: number;
}

export type Props = ConnectProps & MessagesListContainerProps;

interface State {
  loading: boolean;
}
export interface FetchMoreMessagesParams {
  currentPage: number;
  items_per_page?: number;
}

const ITEMS_PER_PAGE = 25;

class MessagesListContainer extends React.Component<Props, State> {
  indexToRender = 0;
  currentPage = 0;
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false
    };
  }
  componentDidMount() {
    this.state = {
      loading: true
    };
    if (this.props.conversationId === this.props.activeConversationId && isNullOrUndefined(this.props.currentPage)) {
      this.fetchNewMessage({ currentPage: INITIAL_PAGE }).then(() => {
        this.fetchScheduledMessages().then(() => {
          this.setState({
            loading: false
          });
        });
      });
    }
  }

  fetchNewMessage = ({currentPage, items_per_page}: FetchMoreMessagesParams) =>
    this.props.getMessages({
      conversationId: this.props.activeConversationId,
      itemsPerPage: items_per_page,
      page: currentPage,
      userId: this.props.user?.uuid ?? ''
    },
    this.props.useNewMessagingBffEndpoint);

  fetchScheduledMessages = () =>
    this.props.getScheduledMessages({
      conversationId: this.props.activeConversationId,
      userId: this.props.user?.uuid ?? '',
      scheduled: true
    },
    this.props.useNewMessagingBffEndpoint);

  fetchPagedMessage = () => {
    const { currentPage, totalPages } = this.props;
    if (isNullOrUndefined(totalPages) || isNullOrUndefined(currentPage) || currentPage === totalPages) {
      return;
    }
    const nextPage = currentPage + 1;
    this.fetchNewMessage({ currentPage: nextPage, items_per_page: ITEMS_PER_PAGE });
  };

  shouldComponentUpdate(nextProps: Readonly<Props>) {
    // The entire purpose of this method is to set the scroll position
    // via the indexToRender value.
    if (
      nextProps.messages.length - this.props.messages.length === 1 &&
      nextProps.messages[nextProps.messages.length - 1] &&
      this.props.messages[this.props.messages.length - 1]
    ) {
      // this forces scroll to bottom when a new inbound or outbound message is created
      this.indexToRender = nextProps.messages.length - 1;
    } else if (
      nextProps.scheduledMessages.length - this.props.scheduledMessages.length === 1 &&
      nextProps.scheduledMessages[nextProps.scheduledMessages.length - 1] &&
      this.props.scheduledMessages[this.props.scheduledMessages.length - 1]
    ) {
      // this forces scroll to bottom when a new scheduled message is created
      this.indexToRender = nextProps.messages.length + nextProps.scheduledMessages.length - 1;
    } else if (
      nextProps.currentPage !== this.props.currentPage ||
      nextProps.scheduledMessages.length - this.props.scheduledMessages.length > 1 ||
      nextProps.messages.length - this.props.messages.length > 1
    ) {
      this.currentPage = isNullOrUndefined(this.props.currentPage) ? INITIAL_PAGE : Number(nextProps.currentPage);
      // this forces the component to retain the scroll position from the previous render.
      // this is triggered on paginated calls
      this.indexToRender =
        nextProps.messages.length + nextProps.scheduledMessages.length - this.currentPage * ITEMS_PER_PAGE - 1;
    }
    return true;
  }

  render() {
    if (!this.props.user) return null;

    return (
      <Box pad={{ left: '16px' }} style={{ width: 'inherit' }}>
        {this.props.messages.length + this.props.scheduledMessages.length > 0 && (
          <MessageList
            isLoading={false}
            totalPages={this.props.totalPages}
            fetchPagedMessage={this.fetchPagedMessage}
            currentPage={this.currentPage}
            messages={this.props.messages.concat(this.props.scheduledMessages)}
            user={this.props.user}
            conversationId={this.props.activeConversationId}
            indexToRender={this.indexToRender}
          />
        )}
        {this.state.loading && <Loader active inline='centered' size='medium' />}
      </Box>
    );
  }
}

const mapStateToProps = (state: RootState, props: MessagesListContainerProps) => ({
  messages: selectConversationMessages(state, {
    conversationId: props.activeConversationId
  }),
  scheduledMessages: selectConversationScheduledMessages(state, {
    conversationId: props.activeConversationId
  }),
  user: selectUser(state, { userId: props.userId }),
  totalPages: selectConversationTotalPages(state, {
    conversationId: props.activeConversationId
  }),
  currentPage: selectConversationCurrentPage(state, {
    conversationId: props.activeConversationId
  }),
  useNewMessagingBffEndpoint: selectFeatureFlags(state).get(useNewMessagingBffEndpoint)
});

const mapDispatchToProps = {
  getMessages: messagingActions.getMessages,
  getScheduledMessages: messagingActions.getScheduledMessages
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MessagesListContainer, isEqual));
