import { Entity, OptionalProperties } from '../index';

export enum GoalStatuses {
  DONE = 'done',
  ONGOING = 'ongoing'
}

export interface Goal
  extends Entity,
  OptionalProperties<{
    // Note that the ID from the BFF is a number, though we're treating it as a string here
    completedAt: string;
    content: string;
    doneBy: string | null;
    status: GoalStatuses;
    userId: number;
  }> {}
