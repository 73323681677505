import { useQuery } from '@apollo/client';
import * as React from 'react';
import { GET_USER_STATUS } from '../../graphql/queries/User';
import { User } from '../../graphql/models/User';
import { Status, EnrollmentStatus } from '../../graphql/models/bff';
import { isNullOrUndefined } from 'util';
import { StatusTitleOptions } from '../../containers/communications/UserStatusFormContainer';
import { enrollmentStatusText } from '../../utils/user/enrollmentStatus';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import styled from 'styled-components';

interface Props {
  userId: number;
  setStatusShowModal?: () => void;
  deleteStatusShowModal?: () => void;
  handleShowEnrollmentStatusModal(): void;
  anchorPositionLeft: number;
  anchorPositionTop: number;
  onlyShowEnrollmentOptions: boolean;
  enrollmentStatus?: EnrollmentStatus | null;
}

const ErrorMessage = styled.div`
  color: grey;
  font-size: 12px;
`;

interface Response {
  user: User;
}

interface Variables {
  id: string;
}

interface DropdownOption {
  label: StatusTitleOptions;
  onClick?: () => void;
}

const UserStatusDropdown: React.FC<Props> = ({
  userId,
  setStatusShowModal,
  handleShowEnrollmentStatusModal,
  deleteStatusShowModal,
  anchorPositionLeft,
  anchorPositionTop,
  onlyShowEnrollmentOptions,
  enrollmentStatus
}) => {
  const { error, data } = useQuery<Response, Variables>(GET_USER_STATUS, {
    variables: { id: userId.toString() }
  });

  const status = enrollmentStatus?.status || Status.OPT_IN;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option: DropdownOption) => {
    option.onClick && option.onClick();
    setAnchorEl(null);
  };

  if (error) return <ErrorMessage>Unable to fetch user status.</ErrorMessage>;

  const setEnrollmentStatusText = () => enrollmentStatusText(status).title;

  const enrollmentStatusOption = { label: setEnrollmentStatusText(), onClick: handleShowEnrollmentStatusModal };

  const setStatusMenuOption: DropdownOption[] = [
    { label: 'Set a status', onClick: setStatusShowModal },
    enrollmentStatusOption
  ];

  const updateStatusMenuOption: DropdownOption[] = [
    { label: 'Edit status', onClick: setStatusShowModal },
    { label: 'Remove status', onClick: deleteStatusShowModal },
    enrollmentStatusOption
  ];

  const setEnrollmentStatusOption: DropdownOption[] = [
    { label: setEnrollmentStatusText(), onClick: handleShowEnrollmentStatusModal }
  ];

  const statusDropdownOptions = isNullOrUndefined(data?.user?.userStatus)
    ? setStatusMenuOption
    : updateStatusMenuOption;

  const configureDropDownOptions = onlyShowEnrollmentOptions ? setEnrollmentStatusOption : statusDropdownOptions;

  return (
    <>
      <IconButton aria-label='more' aria-haspopup='true' onClick={handleClick} data-testid={`user-drop-down-${userId}`}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        data-testid='status-dropdown-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClickAway}
        anchorReference='anchorPosition'
        anchorPosition={{ top: anchorPositionTop, left: anchorPositionLeft }}
      >
        {configureDropDownOptions.map(option => (
          <MenuItem key={option.label} onClick={() => handleMenuItemClick(option)} data-testid={option.label}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default UserStatusDropdown;
