import { createSelector } from 'reselect';
import { RootState } from '../reducers';
import { User } from '../../api-client';
import { createTeamRecord } from '../reducers/team';
/**
 * Get all selected userids
 */
export function selectTeamPage(state: RootState) {
  return state.teamPage;
}

/**
 * Get the user slice of state
 */
function selectUserState(state: RootState) {
  return state.user;
}

/**
 * Select the teams slice
 */
export function selectTeamsState(state: RootState) {
  return state.teams;
}

/**
 * select individual team
 * If the teamdoes not exist an empty entity will instead be returned: all properties will be left undefined except for
 * `id`.
 */
export const selectTeam = (state: RootState, props: { teamId: number }) =>
  selectTeamsState(state).get(
    props.teamId,
    createTeamRecord({ id: props.teamId })
  );

/**
 * Get all actively selected user rows
 */
export const selectChosenUserIds = createSelector(
  selectTeamPage,
  slice => slice.get('selectedUserIds')
);

/**
 * Predicate for determining if current user is selected
 */
export const isUserSelected = createSelector(
  selectTeamPage,
  (_: {}, p: { userId: number }) => p.userId,
  (slice, userId: number) =>
    !!slice.get('selectedUserIds').find((item: number) => item === userId)
);

/**
 * Get selected user rows by team name, returns empty array by default
 */
export const selectChosenUserIdsByTeam = createSelector(
  selectTeamPage,
  (_: {}, p: { teamName: string }) => p.teamName,
  (slice, teamName: string) => {
    const selectedMembers = slice.getIn(['selectedUsersByTeam', teamName]);
    return selectedMembers ? selectedMembers.toArray() : [];
  }
);

/**
 * Return users matched by the search term across all configured keys
 */
export const selectSearchedUsers = createSelector(
  selectUserState,
  selectTeamPage,
  (users, teamPage) => {
    const searchString = teamPage.get('searchString');
    if (!searchString) {
      return [];
    }

    const searchFields = teamPage.get('searchUsersConfig').get('keys');
    // naive exhaustive search on all search fields using regex lookahead
    // FIXME CA-138 use indices to make this faster
    const removeNonAlphaNumericChars = (searchStr: string) =>
      searchStr.replace(/[^0-9a-z: ]/g, '\\$1');
    const searchStringProcessed = removeNonAlphaNumericChars(
      searchString.toLowerCase().trim()
    );
    const searchText = `^(?=.*\\b${searchStringProcessed
      .split(/\s+/)
      .join('\\b)(?=.*\\b')}).*$`;

    const reg = RegExp(searchText, 'i');
    return users
      .filter(user => searchFields.some((field: string) => {
        let userProp = user && user.get(field);
        const team = user && user.get('team');
        if (typeof userProp !== 'string') {
          userProp = `${String(userProp)} ${team && team.name}`;
        }

        if (
          searchStringProcessed.indexOf('week:') === 0 ||
            searchStringProcessed.indexOf('w:') === 0
        ) {
          // CA-138 search will do an exact match on prefix for week
          const extractWeekSearched = searchStringProcessed
            .split(':')[1]
            .match(/\d+/g);
          const extractedWeek = extractWeekSearched && extractWeekSearched[0];
          return (
            !!user &&
              extractedWeek &&
              user.currentWeek === parseInt(extractedWeek, 10)
          );
        }
        return userProp ? reg.test(userProp.replace(/\s+/g, ' ')) : false;
      }))
      .toArray();
  }
);

export function sortTeamUsersByName(a: User, b: User) {
  if (!a.firstName) return -1;
  if (!b.firstName) return 1;

  const nameA = a.firstName.toUpperCase();
  const nameB = b.firstName.toUpperCase();

  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;

  return 0;
}

/**
 * Select all teams
 */
export const selectAllTeams = createSelector(
  selectTeamsState,
  slice => slice.toArray().reverse() || []
);

/**
 * Select all team ids
 */
export const selectAllTeamIds = createSelector(
  selectTeamsState,
  teams => teams.keySeq().toArray()
);
/**
 * Select all users from a given team
 */
export const selectTeamUsers = createSelector(
  [selectTeam, selectUserState],
  (team, users): User[] => {
    if (!team || !team.users) return [];
    return team.users.map(userId => users.get(userId));
  }
);

export const selectSortedTeamUsersFactory = () =>
  createSelector(
    [selectTeamUsers],
    teamUsers => (teamUsers ? teamUsers.sort(sortTeamUsersByName) : [])
  );
