import { connect } from 'react-redux';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';

import {
  selectDistributedOutreachTasks,
  selectFeatureFlags
} from '../../../redux/selectors';
import { RootState } from '../../../redux/reducers';

import { TasksTable } from '../../../components/tasks/table';
import * as React from 'react';
import { OrderedDoTaskKindTitles, Task, TaskKind } from '../../../api-client';
import './TasksTableContainer.scss';
import { mixpanelTrack } from '../../../mixpanel/mixpanel';
import { DispatchMap } from '../../../redux/actions/interfaces';

interface TasksTableProps {
  showSnackBar(msg: string): void;
  taskKindToFeatureFlagMapping?: Map<string, object>;
}

type ConnectProps = DispatchMap<{
  tasks?: Task<TaskKind>[];
}>;

type Props = ConnectProps & TasksTableProps;

const TasksTableContainer: React.FC<Props> = (props) => {
  const { tasks, taskKindToFeatureFlagMapping } = props;

  const filteredTasks =
    tasks &&
    tasks.filter(
      (task) =>
        task.kind &&
        taskKindToFeatureFlagMapping &&
        (!taskKindToFeatureFlagMapping.has(task.kind) ||
          taskKindToFeatureFlagMapping.get(task.kind))
    );

  const taskKindTitle = OrderedDoTaskKindTitles;
  const [checkedItems, setCheckedItems] = React.useState<{ m: string[] }>({
    m: []
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    if (isChecked) {
      checkedItems.m.push(item);
    } else {
      checkedItems.m = checkedItems.m.filter((d) => item !== d);
    }
    setCheckedItems({ m: checkedItems.m });
    mixpanelTrack('Task Filter', {
      FilterBy: checkedItems.m.toString(),
      Origin: '/tasks'
    });
  };

  const reset = () => {
    mixpanelTrack('Task Filter', {
      FilterBy: [].toString(),
      Origin: '/tasks'
    });
    setCheckedItems({ m: [] });
  };

  const taskItems = () =>
    Object.keys(taskKindTitle).map(
      (title) =>
        taskKindToFeatureFlagMapping &&
        (!taskKindToFeatureFlagMapping.has(title) ||
          taskKindToFeatureFlagMapping.get(title)) && (
          <div className="task-container" key={title}>
            <label className="task-container__item">
              <input
                name={title}
                type="checkbox"
                checked={checkedItems.m.indexOf(title) >= 0}
                onChange={handleChange}
              />
              {checkedItems.m.indexOf(title) >= 0 ? (
                <CheckBox className="checkmark" />
              ) : (
                <CheckBoxOutlineBlank className="checkmark-empty" />
              )}
              {taskKindTitle[title]}
            </label>
          </div>
        )
    );

  return (
    <div style={{ display: props.tasks?.length ? 'flex' : 'none' }}>
      <div>
        {!!filteredTasks?.length && (
          <TasksTable
            showSnackBar={props.showSnackBar}
            checkedItems={checkedItems}
            tasks={filteredTasks}
          />
        )}
      </div>
      {!!filteredTasks?.length && (
        <div className="filter-container">
          <div className="filter-container__inner">
            <div className="filter-header">
              <div className="filter-header__title">Filter</div>
              <div className="filter-header__reset" onClick={reset}>
                Reset
              </div>
            </div>
            <div className="task-kind-label">Task Kind</div>
            {taskItems()}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  taskKindToFeatureFlagMapping: selectFeatureFlags(state),
  tasks: selectDistributedOutreachTasks(state) || []
});

export default connect(mapStateToProps, null)(TasksTableContainer);
