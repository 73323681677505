import * as React from 'react';
import { TextArea, TextAreaProps } from 'grommet';
import styled from 'styled-components';
import * as autosize from 'autosize';

const MAX_HEIGHT = 250;

const StyledTextArea = styled(TextArea)`
  font-size: 14px;
  resize: none;
  overflow: auto;
  max-height: ${MAX_HEIGHT}px;
`;

export default class MessageField extends React.PureComponent<TextAreaProps> {
  textAreaRef: React.RefObject<HTMLTextAreaElement | null>;

  constructor(props: TextAreaProps) {
    super(props);
    this.textAreaRef = React.createRef();
  }

  componentDidMount() {
    if (this.textAreaRef.current) {
      autosize(this.textAreaRef.current);
      autosize.update(this.textAreaRef.current);
    }
  }

  componentDidUpdate() {
    if (!this.props.value && this.textAreaRef.current) {
      autosize.update(this.textAreaRef.current);
    }
  }

  componentWillUnmount() {
    if (this.textAreaRef.current) {
      autosize.destroy(this.textAreaRef.current);
    }
  }

  render() {
    return (
      <StyledTextArea
        // styled component ref doesn't work https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={this.textAreaRef as any}
        fill={this.props.fill}
        onChange={this.handleChange}
        value={this.props.value}
        focusIndicator={this.props.focusIndicator}
      />
    );
  }

  handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.onChange && this.props.onChange(e);
  };
}
