import * as React from 'react';
import {
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
  Bar
} from 'recharts';
import MultilineTick from './MultilineTick';
import { DARKER_NAV_BAR, GREEN } from '../../colors';
import { User, Workout, WeeklyMetric } from '../../api-client';
import { isNumber, formatToInt } from '../../utils/number';
import * as moment from 'moment-timezone';
import { Map } from 'immutable';
import { DEFAULT_TIMEZONE } from '../../utils/user/timezone';
import { HANDLE_RESIZE_WAIT } from '../../utils/chart';

interface ExerciseChartDatum {
  dayLabel: string;
  weekGoal: number;
  dayPoints: number[];
}

interface ExerciseChartProps {
  startWeek: number;
  endWeek: number;
  user: User;
  workouts: Workout[];
  weeklyMetrics: Map<string, WeeklyMetric>;
}

function filterWorkoutByDay(date: moment.Moment, workout: Workout, timezone?: string | null): boolean {
  return date.isSame(moment(workout.occurred_at || '').tz(timezone || DEFAULT_TIMEZONE), 'day');
}

export function generateChartData(props: ExerciseChartProps): ExerciseChartDatum[] {
  const { startWeek, endWeek, user, workouts, weeklyMetrics } = props;
  const data = new Array<ExerciseChartDatum>();

  if (!user.team || !isNumber(user.team.currentTeamWeekNumber)) return [];
  const currentWeek = user.team.currentTeamWeekNumber;
  const startDate = moment.tz(user.timezone || DEFAULT_TIMEZONE)
    .startOf('isoWeek').subtract(currentWeek, 'weeks').add(startWeek, 'weeks');
  let weekPoints = 0;
  let weekGoal = 0;

  for (let i = (startWeek * 7); i < (endWeek * 7); i++ , startDate.add(1, 'day')) {
    const dayWorkouts = workouts.filter(w => filterWorkoutByDay(startDate, w, user.timezone));
    const weekIndex = Math.floor(i / 7);
    if (i % 7 === 0) {
      weekPoints = 0;
      const m = weeklyMetrics.get(`${user.id}:${weekIndex}`);
      if (m && m.points_goal) weekGoal = formatToInt(m.points_goal);
    }

    const dayPoints = dayWorkouts.reduce((acc, w) =>
      acc + (isNumber(w.points_earned) ? formatToInt(w.points_earned) : 0), 0);

    data.push({
      dayLabel: `W${weekIndex}\n${startDate.format('M/D')}`,
      dayPoints: [weekPoints, weekPoints + dayPoints],
      weekGoal
    });
    weekPoints += dayPoints;
  }

  return data;
}

const ExerciseChart: React.FunctionComponent<ExerciseChartProps> = (props) => (
  <ResponsiveContainer debounce={HANDLE_RESIZE_WAIT} width="100%" height={480}>
    <ComposedChart data={generateChartData(props)}>
      <XAxis dataKey="dayLabel" type="category" interval={6} tick={<MultilineTick />} height={42} />
      <YAxis tickFormatter={(n) => `${n} pts`} />
      <CartesianGrid />
      <Bar
        dataKey="dayPoints"
        minPointSize={1}
        name="Daily points earned"
        fill={GREEN}
        legendType="line"
      />
      <Line
        dataKey="weekGoal"
        name="Points goal"
        type="stepAfter"
        legendType="line"
        dot={false}
        activeDot={false}
        strokeDasharray="8 2"
        stroke={DARKER_NAV_BAR}
      />
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <Tooltip formatter={(v: any) => typeof v === 'number' ? v : v[1] - v[0]} />
    </ComposedChart>
  </ResponsiveContainer>
);

export default ExerciseChart;
