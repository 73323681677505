import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  AudioTrack,
  VideoTrack
} from 'twilio-video';
import {
  CreateJwtMutationVariables
} from '../../api-client/endpoints';
import { ERR_JOIN_CALL } from '../../errors';
import { videoCallActions, DispatchThunk } from '../../redux/actions';
import { selectAdmin } from '../../redux/selectors/admin';
import { captureException } from '@sentry/browser';
import { getMostRecentUnfinishedVideoCall } from '../../redux/selectors/videocall';
import useUserQuery from '../../utils/user/useUserQuery';
import { createRoomIdentifier } from './utils';

type AudioRef = React.RefObject<HTMLAudioElement>;
type VideoRef = React.RefObject<HTMLAudioElement>;

type UseAttachTrackToRefArgs = {
  tracks: AudioTrack[];
  ref: AudioRef;
} | {
  tracks: VideoTrack[];
  ref: VideoRef;
};

export const useAttachTrackToRef = ({tracks, ref}: UseAttachTrackToRefArgs) => {
  React.useEffect(() => {
    const track: AudioTrack | VideoTrack | undefined = tracks[0];
    if (track?.attach && ref?.current) {
      track.attach(ref.current);
    }
    return () => {
      if (track?.detach) {
        track.detach();
      }
    };
  }, [tracks]);
};

interface GetVideoCAllsArgs {
  userUuid: string;
  dispatch: DispatchThunk;
}

export const getVideoCalls = ({ userUuid, dispatch }: GetVideoCAllsArgs) => {
  const createVideoCallThunk = videoCallActions.getVideoCalls({
    userUuid
  });
  return dispatch(createVideoCallThunk);
};

interface CreateVideoCallArgs {
  adminUuid: string;
  userUuid: string;
  identifier: string;
  basiliskPathwayId: number | null | undefined;
  dispatch: DispatchThunk;
}

export const createVideoCall = ({
  adminUuid,
  userUuid,
  identifier,
  basiliskPathwayId,
  dispatch }: CreateVideoCallArgs) => {
  const createVideoCallThunk = videoCallActions.createVideoCall({
    adminUuid,
    userUuid,
    identifier,
    basiliskPathwayId,
    scheduledAt: new Date().toISOString()
  });
  return dispatch(createVideoCallThunk);
};

interface CreateJwtArgs extends CreateJwtMutationVariables {
  dispatch: DispatchThunk;
}

export const createJwt = ({uuid, identifier, dispatch}: CreateJwtArgs) => {
  const createJwtThunk = videoCallActions.createJwt({ uuid, identifier });
  return dispatch(createJwtThunk);
};

export interface UseJwtTokenForVideoCallOutput {
  token: string | undefined;
  canRejoin: boolean;
}

export const useJwtTokenForVideoCall = (userId: number): UseJwtTokenForVideoCallOutput => {
  const [loading, setLoading] = React.useState(false);
  const [canRejoin, setCanRejoin] = React.useState<boolean>(false);
  const [token, setToken] = React.useState<string | undefined>(undefined);
  const admin = useSelector(selectAdmin);
  const { user, pathway }  = useUserQuery(userId);
  const basiliskPathwayId = pathway ? Number(pathway.id) : null;
  const adminUuid: string | undefined = admin?.uuid;
  const userUuid: string | null | undefined = user?.uuid;

  const dispatchThunk: DispatchThunk = useDispatch();

  React.useEffect(() => {
    const fetchTokenForVideo = async() => {
      if (loading || !adminUuid || !userUuid || !basiliskPathwayId) {
        return;
      }

      setLoading(true);
      const timestamp = new Date();
      const identifier = createRoomIdentifier(userUuid, adminUuid, timestamp);

      try {
        const getVideoCallsResponse = await getVideoCalls({ userUuid, dispatch: dispatchThunk });
        if ('error' in getVideoCallsResponse.getVideoCalls) {
          throw Error(`getVideoCalls query failed: ${JSON.stringify(getVideoCallsResponse.getVideoCalls.error)}`);
        }
        const latestVideoCall = getMostRecentUnfinishedVideoCall(getVideoCallsResponse.getVideoCalls);

        if(latestVideoCall) {
          setCanRejoin(true);
        }
        const createVideoCallResponse = await createVideoCall({
          adminUuid, userUuid, identifier, basiliskPathwayId, dispatch: dispatchThunk
        });
        if ('error' in createVideoCallResponse.createVideoCall) {
          throw Error(
            'createVideoCall mutation failed: ' + JSON.stringify(createVideoCallResponse.createVideoCall.error)
          );
        }

        const videoCall = createVideoCallResponse.createVideoCall;


        const uuid = videoCall.adminUuid;
        const identifierFromVideoRecord = videoCall.identifier;
        if (!uuid || !identifierFromVideoRecord) {
          throw Error(`Video ${videoCall.id} has no adminUuid or identifier`);
        }
        const createJwtResponse = await createJwt({
          uuid, identifier: identifierFromVideoRecord, dispatch: dispatchThunk
        });
        if ('error' in createJwtResponse.createJwt) {
          throw Error('createJwt mutation failed: ' + JSON.stringify(createJwtResponse.createJwt.error));
        }
        const jwtToken = createJwtResponse.createJwt.token;
        setToken(jwtToken);
      } catch(error) {
        captureException(new Error(`${ERR_JOIN_CALL}, ${error}`));
      }
      setLoading(false);
    };

    fetchTokenForVideo();

    return () => setLoading(false);
  }, [adminUuid, userUuid, basiliskPathwayId]);

  return { token, canRejoin};
};
