import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { Avatar } from '@material-ui/core';
import { actionCreators as messagingActions } from '../../redux/actions/messages';
import { MessageRecord, UserRecord, RootState } from '../../redux/reducers';
import { DispatchMap } from '../../redux/actions/interfaces';
import { selectMessage, isUnreadMessage } from '../../redux/selectors/messages';
import * as moment from 'moment-timezone';
import { DEFAULT_TIMEZONE } from '../../../src/utils/user/timezone';
import * as sanitizeHtml from 'sanitize-html';
import UnreadLabel from './UnreadLabel';
import { MessageDirection, MessageStatus } from '../../api-client/interfaces';
import DumpItemIds from './debug/DumpItemIds';
import ModalWrapper from '../modal/ModalWrapper';
import EditScheduledMessageModal from './EditScheduledMessageModal';
import UserStatusLabelContainer from '../../containers/communications/UserStatusLabelContainer';
import { isEqual } from 'lodash';
import './MessageListItem.scss';
import { isNullOrUndefined } from '../../utils/helpers';
import {
  useNewMessagingBffMarkAsReadEndpoint,
  useNewMessagingBffDeleteMessageEndpoint
} from '../../features';
import { selectAdmin, selectFeatureFlags } from '../../redux/selectors';
import { AdminRoles } from '../../utils/admin/roles';


interface MessageListItemProps {
  user: UserRecord;
  conversationId: string;
  messageId: string;
}

type ConnectProps = DispatchMap<{
  message?: MessageRecord;
  deleteMessage: typeof messagingActions.deleteMessage;
  markAsRead: typeof messagingActions.markAsRead;
  isUnread: boolean;
  useNewMarkAsReadEndpoint?: boolean;
  useNewDeleteMessageEndpoint?: boolean;
}>;

export const modifyMessage = (msg: string) => {
  if (!msg) {
    return '';
  }
  return msg
    .split(' ')
    .map(word => {
      if (word.startsWith('https://') || word.startsWith('http://')) {
        return `<a style="cursor: pointer" target='_blank' href=${word}>${word}</a>`;
      }
      return word;
    })
    .join(' ');
};

export type Props = MessageListItemProps & ConnectProps;

export const getRoleName = (role: string) => {
  switch (role) {
    case AdminRoles.super:
      return 'Super';
    case AdminRoles.coach:
      return 'Coach';
    case AdminRoles.physical_therapist:
      return 'PT';
    default:
      return 'OS';
  }
};
const MessageListItem: React.FC<Props> = ({
  user,
  conversationId,
  markAsRead,
  deleteMessage,
  message,
  isUnread,
  useNewMarkAsReadEndpoint,
  useNewDeleteMessageEndpoint
}) => {
  const currentAdmin = useSelector(selectAdmin);
  if (!message) return null;
  const { direction, body, id } = message;
  const adminRole = currentAdmin.role ?? 'unknown';
  const name = message.direction === MessageDirection.In
    ? `${user.firstName} ${user.lastName}`
    : getRoleName(adminRole);

  const userUuid = isNullOrUndefined(user.uuid) ? undefined : user.uuid;

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showEditScheduledModal, setShowEditScheduledModal] = React.useState(false);
  const [markAsSeenClicked, setMarkAsSeenClicked] = React.useState(false);
  const handleMarkMessageAsSeen = async() => {
    setMarkAsSeenClicked(true);
    try {
      await markAsRead(
        user.id,
        {
          id,
          body,
          seenAt: moment().toString(),
          direction
        },
        {
          conversationId,
          id
        },
        userUuid,
        useNewMarkAsReadEndpoint
      );
    } catch {
      setMarkAsSeenClicked(false);
    }
  };

  const deleteContentProps = {
    title: 'Confirm Delete Scheduled Message',
    body: 'Are you sure you want to delete this scheduled message?',
    confirmBtnText: 'DELETE',
    dismissBtnText: 'CANCEL'
  };

  const handleScheduleMessageDelete = () =>
    deleteMessage(message.id, conversationId, useNewDeleteMessageEndpoint);
  const handleDeleteClick = () => setShowDeleteModal(true);
  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const handleEditScheduledClick = () => setShowEditScheduledModal(true);

  const messageTimestamp =
    message.direction === MessageDirection.In ||
      ((message.status === MessageStatus.Transmitting) || (message.status === MessageStatus.Pending))
      ? message.createdAt
      : message.transmittedAt;

  const modifiedMessage = modifyMessage(message.body as string);

  return (
    <>
      {showDeleteModal && (
        <ModalWrapper
          modalClassName='scheduled-message-delete'
          modalContent={deleteContentProps}
          onConfirm={handleScheduleMessageDelete}
          onDismiss={handleDeleteModalClose}
          openModal={showDeleteModal}
        />
      )}
      {showEditScheduledModal && (
        <EditScheduledMessageModal
          userId={user.id}
          userUuid={user.uuid ?? undefined}
          userTimezone={user.timezone || DEFAULT_TIMEZONE}
          conversationId={conversationId}
          messageId={message.id}
          showEditModal={showEditScheduledModal}
          setShowEditModal={setShowEditScheduledModal}
        />
      )}
      <div className={'message-item'}>
        {direction === MessageDirection.In ? (
          (user.avatarUrl && (
            <Avatar
              className='message-item__img'
              imgProps={{ height: 'inherit', width: 'inherit' }}
              src={user.avatarUrl}
            />
          )) || (
            <Avatar
              style={{
                width: '28px',
                height: '28px',
                backgroundColor: '#CFD8DC'
              }}
              src={''}
            />
          )
        ) : (
          <Avatar
            style={{
              backgroundColor: '#0F5472',
              opacity: '0.9',
              maxWidth: '28px',
              maxHeight: '28px'
            }}
          >
            {adminRole.charAt(0).toUpperCase()}
          </Avatar>
        )}
        <div className='message-item__contents'>
          <div className='message-item__contents__header'>
            <div className='message-item__contents__header__info'>
              <div className='message-item__contents__header__info__user'>
                <DumpItemIds conv={{ id: conversationId }} msg={message} />
                <div className='message-item__contents__header__info__user__name'>{name}</div>
                {direction === MessageDirection.In && (
                  <div className='message-item__contents__header__info__user__status'>
                    <UserStatusLabelContainer userId={user.id} />
                  </div>
                )}
              </div>
              <div className='message-item__contents__header__info__timestamp'>
                {message.status !== MessageStatus.Scheduled &&
                  moment.tz(messageTimestamp, DEFAULT_TIMEZONE).format('ddd MMM D, h:mm a')}
              </div>
            </div>
            <div
              className={
                isUnread ? 'message-item__contents__header__unread' : 'message-item__contents__header__unread--hide'
              }
            >
              <UnreadLabel />
            </div>
          </div>
          <div
            className={`message-item__contents__body
              ${isUnread ? 'message-item__contents__body--unread' : ''}
              ${message.status === 'failed' ? 'message-item--failed' : ''}`}
            dangerouslySetInnerHTML={{
              __html: modifiedMessage ? sanitizeHtml(modifiedMessage) : ''
            }}
          />
          {message.status !== MessageStatus.Scheduled && message.direction === MessageDirection.Out && (
            <div
              id='message-status'
              className={`message-item__contents__status ${
                message.status === 'failed' ? 'message-item__contents__status--failed' : ''
              }`}
            >
              {`Status: ${message.status ? message.status : 'N/A'}`}
            </div>
          )}
          {message.status !== MessageStatus.Scheduled && !markAsSeenClicked && (
            <a
              className={isUnread ? 'message-item__mark-read' : 'message-item__mark-read--hide'}
              onClick={handleMarkMessageAsSeen}
            >
              Mark as read
            </a>
          )}
          {message.status === MessageStatus.Scheduled && message.scheduledFor && user.timezone && (
            <div className='message-item__contents__scheduled'>
              <div
                className='message-item__contents__scheduled message-item__contents__scheduled__stamp'
                id='message-status'
              >
                {`Scheduled for ${moment(message.scheduledFor)
                  .tz(user.timezone)
                  .format('ddd M/D/YYYY h:mm A z')}`}
              </div>
              <div className='message-item__contents__scheduled__button'>
                <button
                  id='delete-scheduled-message-button'
                  className='message-item__contents__scheduled__button
                    message-item__contents__scheduled__button--delete'
                  onClick={handleDeleteClick}
                >
                  DELETE
                </button>
                <span id='message-status' className='message-item__contents__scheduled__button__divide'>
                  {' '}
                  |{' '}
                </span>
                <button
                  id='edit-scheduled-message-button'
                  className='message-item__contents__scheduled
                    message-item__contents__scheduled__button'
                  onClick={handleEditScheduledClick}
                >
                  EDIT
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState, props: MessageListItemProps) => ({
  message: selectMessage(state, { messageId: props.messageId }),
  isUnread: isUnreadMessage()(state, { messageId: props.messageId }),
  useNewMarkAsReadEndpoint: selectFeatureFlags(state).get(useNewMessagingBffMarkAsReadEndpoint),
  useNewDeleteMessageEndpoint: selectFeatureFlags(state).get(useNewMessagingBffDeleteMessageEndpoint)
});

const mapDispatchToProps = {
  deleteMessage: messagingActions.deleteMessage,
  markAsRead: messagingActions.markAsRead
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MessageListItem, isEqual));


