import * as React from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { ERR_TASK_USER_PARSE_FAIL } from '../../errors';
import { RootState } from '../../redux/reducers/index';
import './MassMessagingContainer.scss';
import MassMessagingUserTable from '../../components/massmessaging/MassMessagingUserTable';
import MassMessagingTodo from '../../components/massmessaging/MassMessagingTodo';
import { TaskKind, MassMessageTemplate, TemplateTodoMap, MassMessage } from '../../api-client/interfaces';
import { getMassMessageTemplates } from '../../redux/actions/tasks';
import { DispatchMap } from '../../redux/actions/interfaces';
import { MASS_MESSAGING_PATH } from '../../routes';

type Props = DispatchMap<{
  fetchMassMessageTemplates: typeof getMassMessageTemplates;
}>;

type fetchStatusState = 'loading' | 'error' | 'success';

export const openMassMessagingTab = () => window.open(MASS_MESSAGING_PATH);
export const closeMassMessagingTab = () => window.close();

/* TODO: fetch message templates by todo */
const groupTemplatesByTodo = (templateRes: MassMessage[], todo: TaskKind) =>
  templateRes.filter(res => selectTemplatesByTodo(res, todo));

const selectTemplatesByTodo = (massMessage: MassMessage, todo: TaskKind): boolean =>
  TemplateTodoMap[massMessage.messageType] === todo;

const MassMessagingContainer: React.FC<Props> = ({ fetchMassMessageTemplates }) => {
  const todos = [TaskKind.inactiveFourWeeks, TaskKind.inactiveFiveWeeks];
  const [templatesRes, setTemplatesRes] = React.useState<MassMessage[]>([]);
  const [fetchStatus, setFetchStatus] = React.useState<fetchStatusState>('loading');

  const [selectedMassMessageTemplate, setSelectedMassMessageTemplate] = React.useState<MassMessageTemplate | undefined>(
    undefined
  );
  const [incompleteTodos, setIncompleteTodos] = React.useState<TaskKind[]>(todos);

  React.useEffect(() => {
    const fetchTemplates = async() => {
      await fetchMassMessageTemplates()
        .then(res => {
          setTemplatesRes(res.data.massMessagingTemplates);
          setFetchStatus('success');
        })
        .catch(() => {
          setFetchStatus('error');
        });
    };

    fetchTemplates();
  }, []);

  const markTodoComplete = (completeTodo: TaskKind) => {
    setIncompleteTodos(prevTodos => prevTodos.filter(t => t !== completeTodo));
  };

  if (fetchStatus === 'loading') {
    return (
      <div>
        <Loader active inline size='tiny' />
      </div>
    );
  }

  if (fetchStatus === 'error') {
    throw new Error(`${ERR_TASK_USER_PARSE_FAIL}`);
  }

  return (
    <div>
      <div className='mass-messaging__container'>
        <div className='mass-messaging__todos'>
          <div className='mass-messaging__todos__header'>Mass Message Drafts</div>
          <div className='mass-messaging__todos__body'>
            {templatesRes && templatesRes.length && incompleteTodos.length ? (
              todos.map(todo => (
                <MassMessagingTodo
                  key={todo}
                  todo={todo}
                  templateRes={groupTemplatesByTodo(templatesRes, todo)}
                  selectedTemplate={selectedMassMessageTemplate}
                  setSelectedTemplate={setSelectedMassMessageTemplate}
                  markTodoComplete={markTodoComplete}
                />
              ))
            ) : (
              <div className='mass-messaging__todos__complete'>
                <span className='mass-messaging__todos__complete__text'>No mass messages drafts to review.</span>
                <span className='mass-messaging__todos__complete__text'>
                  Return to CTM to complete tasks for individual users.
                </span>
                <button className='mass-messaging__button' onClick={closeMassMessagingTab}>
                  CLOSE TAB
                </button>
              </div>
            )}
          </div>
        </div>
        <MassMessagingUserTable template={selectedMassMessageTemplate} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
});

const mapDispatchToProps = {
  fetchMassMessageTemplates: getMassMessageTemplates
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MassMessagingContainer);
