import { createMuiTheme } from '@material-ui/core/styles';
import { PRIMARY, PRIMARY_MAIN, PRIMARY_DISABLED, SECONDARY } from './colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: PRIMARY_DISABLED,
      main: PRIMARY
    },
    secondary: {
      main: SECONDARY
    },
    highUtil: {
      main: PRIMARY_MAIN
    },
    dmcFull: {
      main: PRIMARY_MAIN
    },
    dmcMvp: {
      main: PRIMARY_MAIN
    }
  },
  typography: {
    fontFamily: 'Lato',
    body2: {
      fontSize: 14
    }
  }
});

export default theme;
