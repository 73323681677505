import * as React from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { ApiClientNames } from '../apollo/ApolloContainer';
import VideoCall from './VideoCall';
import VideoCallControls from './VideoCallControls';
import { Location } from 'history';
import { connect, Room, Participant as ParticipantType, LocalVideoTrack, LocalAudioTrack } from 'twilio-video';
import './VideoCall.scss';
import * as Sentry from '@sentry/browser';
import { useJwtTokenForVideoCall } from './hooks';
import { ERR_CONNECT_CALL } from '../../errors';
import UserNoteManager from '../communications/UserNoteManager';
import { GET_USER_UUID } from '../../graphql/queries/index';
import { mixpanelTrack } from '../../mixpanel/mixpanel';
import { selectAdmin } from '../../redux/selectors/admin';
import { useUserId } from '../../utils/hooks/useUserId';


interface WrappedProps {
  location?: Location;
}

interface Props {
  id: number;
  token: string;
  canRejoin: boolean;
}

const VideoCallContainer = (props: Props) => {
  const { data } = useQuery<{ getUserById: { uuid: string }}>(
    GET_USER_UUID,
    {
      variables: { userId: props.id },
      context: { clientName: ApiClientNames.BFF }
    }
  );
  const admin = useSelector(selectAdmin);
  const [joining, setJoining] = React.useState<boolean>(false);
  const [room, setRoom] = React.useState<Room | null>(null);
  const [remoteParticipants, setRemoteParticipants] = React.useState<Array<ParticipantType>>([]);
  const [localParticipant, setLocalParticipant] = React.useState<ParticipantType | undefined>(undefined);
  const [videoEnabled, SetVideoEnabled] = React.useState(false);
  const [isMicOn, setIsMicOn] = React.useState(true);

  const participantConnected = React.useCallback((participant: ParticipantType) => {
    setRemoteParticipants(prevParticipants => [...prevParticipants, participant]);
  }, []);

  const participantDisconnected = React.useCallback((participant: ParticipantType) => {
    setRemoteParticipants(prevParticipants =>
      prevParticipants.filter(p => p !== participant)
    );
  }, []);

  const hasConnected = room?.localParticipant.state === 'connected';

  const onDisconnect = () => {
    setRoom(null);
    setLocalParticipant(undefined);
    setRemoteParticipants([]);
    mixpanelTrack('PT Ends Video Call',
      { Origin: `/user/${props.id}/videocall`, AdminUuid: admin.uuid, UserUuid: data?.getUserById.uuid });
  };

  React.useEffect(() => () => {
    if (room) {
      room.disconnect();
    }
  },[room]);

  const onConnect = () => {
    setJoining(true);
    connect(props.token, {
      audio: true,
      video: true
    }).then((currentRoom: Room) => {
      mixpanelTrack('PT Clicks Join Video Visit',
        { Origin: `/user/${props.id}/videocall`, AdminUuid: admin.uuid, UserUuid: data?.getUserById.uuid });
      setJoining(false);
      setLocalParticipant(currentRoom.localParticipant);
      setRoom(currentRoom);
      SetVideoEnabled(true);
      setIsMicOn(true);
      currentRoom.on('participantConnected', participantConnected);
      currentRoom.on('participantDisconnected', participantDisconnected);
      currentRoom.participants.forEach(participantConnected);
    }).catch(e => {
      setJoining(false);
      const error = new Error(`${ERR_CONNECT_CALL}, ${e.message}`);
      console.error(error);
      Sentry.captureException(error);
    });
  };

  const onToggleConnect = () => {
    if (hasConnected) {
      onDisconnect();
      return;
    }
    onConnect();
  };

  const onToggleCamera = () => {
    localParticipant?.videoTracks.forEach(publication => {
      const track = publication?.track as LocalVideoTrack;
      const isVideoEnabled = track.isEnabled;
      isVideoEnabled ? track.disable() : track.enable();
      SetVideoEnabled(!isVideoEnabled);
      mixpanelTrack('Video Call Camera Toggled',
        { Origin: `/user/${props.id}/videocall`, AdminUuid: admin.uuid, Enabled: !isVideoEnabled });
    });
  };

  const onToggleMute = () => {
    localParticipant?.audioTracks.forEach(publication => {
      const track = publication?.track as LocalAudioTrack;
      const openMic = track.isEnabled;
      openMic ? track.disable() : track.enable();
      setIsMicOn(!openMic);
      mixpanelTrack('Mute Video Call Toggled',
        { Origin: `/user/${props.id}/videocall`, AdminUuid: admin.uuid, VideoMuted: !openMic });
    });
  };

  return (
    <div className='video-call-container'>
      <div className='video-call'>
        {localParticipant && (
          <VideoCall
            remoteParticipants={remoteParticipants}
            localParticipant={localParticipant}
            isMicOn={isMicOn}
          />
        )}
        <VideoCallControls
          hasConnected={hasConnected}
          onToggleConnect={onToggleConnect}
          onToggleCamera={onToggleCamera}
          videoEnabled={videoEnabled}
          onToggleMute={onToggleMute}
          isMicOn={isMicOn}
          joining={joining}
          canRejoin={props.canRejoin}
        />
      </div>
      {data?.getUserById?.uuid &&
      <div className='user-note'>
        <UserNoteManager userId={props.id} userUuid={data.getUserById.uuid}/>
      </div>}
    </div>
  );
};

const VideoCallContainerWrapper = (props: WrappedProps) => {
  const userId = useUserId();

  if (!userId) {
    return null;
  }

  const { token, canRejoin } = useJwtTokenForVideoCall(userId);
  if (!token) {
    return null;
  }

  return <VideoCallContainer id={userId} token={token} canRejoin={canRejoin} />;
};

export default VideoCallContainerWrapper;
