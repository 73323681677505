import * as React from 'react';
import { Box, DataTable, DataTableProps, Text } from 'grommet';
import styled from 'styled-components';
import TableHeader from './TableHeader';
import { UserStatus } from '../../../graphql/models';
import UserStatusLabel from '../../communications/UserStatusLabel';
import TasksTableEt from './TasksTableEt';
import { Button, Icon } from 'semantic-ui-react';
import DistributedOutreachMenu from '../DistributedOutreachMenu';
import TaskCompleteModal from '../../../containers/tasks/TaskCompleteModal';
import { actionCreators as tasksActions } from '../../../redux/actions/tasks';
import { DispatchMap } from '../../../redux/actions/interfaces';
import { connect } from 'react-redux';
import { mixpanelTrack } from '../../../mixpanel/mixpanel';
import { selectAdmin } from '../../../redux/selectors';
import { RootState } from '../../../redux/reducers';
import { Admin } from '../../../api-client';

interface TasksTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tasks: any[];
  showSnackBar?(msg: string): void;
  checkedItems: { m: string[] };
}

type ConnectProps = DispatchMap<{
  completeOutreach: typeof tasksActions.completeOutreachTask;
  admin: Admin;
}>;

type Props = TasksTableProps & ConnectProps;
const NameAndStatus: React.FC<{ fullName: string; status?: UserStatus }> = ({ fullName, status }) => (
  <Box direction="row" flex>
    <span style={status?.userId ?
      { paddingTop: '5px', paddingRight: '6px' } : { paddingTop: '1px' }}>{fullName}</span>
    <Box pad={{ left: 'xsmall', top: '2px', bottom: 'xxsmall' }}>
      <UserStatusLabel status={status} />
    </Box>
  </Box>
);

const StyledDataTable = styled(DataTable)`
  table {
    border-radius: 4px 4px 0px 0px;
    overflow: hidden;
  }
  tr td:first-child {
    padding: 3px 16px;
  }
  tr td:last-child {
    padding: 3px 16px;
  }
  tr {
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    border-left: 1px solid rgb(212, 212, 213);
    border-right: 1px solid rgb(212, 212, 213);
    border-bottom: 1px solid rgb(212, 212, 213);
  }
  tr:nth-child(even) {
    background-color: rgb(236, 239, 241);
  }
  thead tr:first-child {
    border-top: 1px solid rgb(207, 216, 220);
    border-left: 1px solid rgb(207, 216, 220);
    border-radius: 4px 4px 0px 0px;
    border-right: 1px solid rgb(207, 216, 220);
    background: rgb(236, 239, 241);
    line-height: 28px;
  }
  th {
    padding: 10px 10px;
  }
  td {
    padding: 8px;
    height: 48px;
  }
  tr:nth-child(odd) {
    background-color: 'white;
  }
  div[class^="Resizer"] {
    display: none;
  }
`;

const columns: DataTableProps['columns'] = [
  {
    align: 'start',
    property: 'teamName',
    header: <TableHeader title="Team Name" />,
    render: datum => <Text size="small">{datum?.teamName}</Text>
  },
  {
    align: 'end',
    property: 'currentTeamWeekNumber',
    header: <TableHeader title="Wk" />,
    render: datum => <Text size="small">{datum?.currentTeamWeekNumber}</Text>,
    sortable: true
  },
  {
    align: 'start',
    property: 'userFullName',
    header: <TableHeader title="User Name" />,
    render: datum => <NameAndStatus fullName={datum?.userFullName} status={datum?.userStatus} />,
    sortable: true
  },
  {
    align: 'end',
    property: 'userId',
    header: <TableHeader title="User Id" />,
    render: datum => <Text size="small">{datum?.userId}</Text>,
    sortable: true
  },
  {
    align: 'start',
    property: 'formattedKind',
    header: <TableHeader title="Task Kind" />,
    render: datum => <Text size="small">{datum.formattedKind}</Text>,
    sortable: true
  },
  {
    align: 'end',
    property: 'lastActive',
    header: <TableHeader title="Last Active" />,
    render: datum => <Text size="small">
      {/* eslint-disable-next-line id-blacklist */}
      {`${datum.lastActive ===  Number.MAX_VALUE ? 'N/A' : datum.lastActive + 'd'}`}
    </Text>,
    sortable: true
  },
  {
    align: 'end',
    property: 'et',
    header: <TableHeader title="ETs this wk" />,
    render: datum => <TasksTableEt id={datum?.userId} phoenix={datum?.user.phoenix} />,
    sortable: true
  },
  {
    align: 'end',
    property: 'createdAt',
    header: <TableHeader title="Created (PT)" />,
    render: datum => <Text size="small">{datum.createdAt}</Text>,
    sortable: true
  }
];

const TasksTable: React.FC<Props> = ({ admin, tasks, completeOutreach, showSnackBar, checkedItems }) => {
  const [modalOpened, setModalOpened] = React.useState(false);
  const [message, setMessage] = React.useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedTask, setSelectedTask] = React.useState({} as any);
  const [tasksData, setTasksData] = React.useState({ t: tasks });
  React.useEffect(() => {
    if (tasksData.t.length === 0 || checkedItems.m.length === 0) {
      setTasksData({ t: tasks });
    }
    if (checkedItems.m.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setTasksData({ t: tasks.filter((t: any) => checkedItems.m.indexOf(t.kind) >= 0) });
    }
  }, [tasks, checkedItems?.m?.length]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCheckClick = (task: any, msg?: string) => {
    if (msg) {
      completeOutreach(msg, task.id, false);
      mixpanelTrack('Task Closed', {
        TaskType: task.kind,
        CompletionMethod: msg,
        Origin: '/tasks',
        FilterBy: checkedItems.m.toString(),
        UserId: task.userId,
        AdminId: admin.id
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setTasksData({ t: tasksData.t.filter((data: any) => data.id !== task.kind) });
      showSnackBar && showSnackBar(msg);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOpenRejectionClick = (task: any, msg?: string) => {
    setSelectedTask(task);
    setModalOpened(true);
    if (msg) {
      setMessage(msg);
    }
  };

  const handleCloseCheckModal = (completed?: boolean) => {
    setModalOpened(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setTasksData({ t: tasksData.t.filter((data: any) => data.id !== selectedTask.id) });
    if (!!completed && message) {
      showSnackBar && showSnackBar(message);
    }
  };
  return (
    <Box align="center" style={{ padding: '48px 0' }}>
      <TaskCompleteModal
        taskId={selectedTask.id}
        taskKind={selectedTask.kind}
        isRejection={true}
        open={modalOpened}
        onClose={handleCloseCheckModal}
      />
      <StyledDataTable
        style={{
          borderRadius: '4px 4px 0 0',
          overflow: 'hidden',
          width: '986px'
        }}
        columns={columns.concat([{
          property: '',
          header: '',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          render: (datum: any) => (<><DistributedOutreachMenu
            triggeredBy={
              <Button
                style={{ background: 'transparent', padding: 0 }}
                icon={<Icon name="ellipsis vertical" bordered={false} />}
              />
            }
            triggerDone={(msg: string) => handleCheckClick(datum, msg)}
            triggerRejection={(msg: string) => handleOpenRejectionClick(datum, msg)}
          /></>)
        }])}
        data={tasksData.t}
        primaryKey="id"
        step={10000}
        pad="xxsmall"
        background={{
          header: 'rgb(236, 239, 241)'
        }}
        sortable
      />
    </Box>
  );
};

const mapDispatchToProps = {
  completeOutreach: tasksActions.completeOutreachTask
};

const mapStateToProps = (state: RootState) => ({
  admin: selectAdmin(state)
});


export default connect(mapStateToProps, mapDispatchToProps)(TasksTable);
