import * as React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import { Header } from 'semantic-ui-react';
import { TaskKind } from '../../api-client/interfaces';
import { RootState, TaskRecord } from '../../redux/reducers';

import TaskGroup from './TaskGroup';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';
import { useSelector } from 'react-redux';
import { healthLogNotesFilterFlag, painUptickFilterFlag } from '../../features';

const StyledSection = styled.div`
  padding: 1em 0;
`;

export interface Props {
  tasks: TaskRecord[];
  showSnackBar?(msg: string): void;
  taskKindTitles: TaskKind[];
}

const groupTasks = R.groupBy((task: TaskRecord) => task.kind || TaskKind.default);

const FirstThingsFirst: React.FunctionComponent<Props> = ({ tasks, showSnackBar, taskKindTitles }) => {
  const groupedTasks = groupTasks(tasks);
  const [filteredTaskKindTitles, setFilteredTaskKindTitles] = React.useState<TaskKind[]>(taskKindTitles);

  // This will filter the taskKindTitles based on the treatment flag
  const healthLogNotesEnabled = useSelector(
    (state: RootState) => selectTreatmentValue(state.splitReducer, healthLogNotesFilterFlag) === 'on'
  );
  const painUptickEnabled = useSelector(
    (state: RootState) => selectTreatmentValue(state.splitReducer, painUptickFilterFlag) === 'on'
  );

  React.useEffect(() => {
    setFilteredTaskKindTitles(taskKindTitles);
  }, [taskKindTitles]);

  React.useEffect(() => {
    if (!healthLogNotesEnabled) {
      setFilteredTaskKindTitles(prevTaskKindTitles =>
        prevTaskKindTitles.filter(taskKind => taskKind !== TaskKind.healthLogNote)
      );
    }
    if (!painUptickEnabled) {
      setFilteredTaskKindTitles(prevTaskKindTitles =>
        prevTaskKindTitles.filter(taskKind => taskKind !== TaskKind.painUptick)
      );
    }
  }, [healthLogNotesEnabled, painUptickEnabled, setFilteredTaskKindTitles, taskKindTitles]);

  return (
    <StyledSection>
      <Header as='h2' textAlign='center'>
        First things first!
      </Header>
      {tasks && (
        <div>
          {filteredTaskKindTitles.map(key => {
            const keyedTasks = groupedTasks[key];
            if (keyedTasks) {
              return (
                <TaskGroup
                  kind={key}
                  key={key}
                  taskCount={keyedTasks.length}
                  tasks={keyedTasks}
                  showSnackBar={showSnackBar}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
      )}
    </StyledSection>
  );
};

export default FirstThingsFirst;
