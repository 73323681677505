import { TypedRecord, recordify } from 'typed-immutable-record';
import { Admin } from '../../api-client';
import { RootAction } from '../actions';
import { SET_ADMIN, REMOVE_ADMIN } from '../constants';

export interface AdminRecord extends TypedRecord<AdminRecord>, Admin {}
export type State = AdminRecord;

export const adminDefault: Admin = {
  id: NaN,
  email: undefined,
  role: undefined,
  firstName: undefined,
  lastName: undefined,
  avatarUrl: undefined,
  avatarFileName: undefined,
  avatarContentType: undefined,
  avatarFileSize: undefined,
  coachBio: undefined,
  coachCity: undefined,
  coachState: undefined,
  coachPhoneDirect: undefined,
  coachPhoneIndirect: undefined,
  coachScheduleUrl: undefined,
  oldCoachId: undefined,
  avatarUpdatedAt: undefined,
  invitationToken: undefined,
  invitationCreatedAt: undefined,
  invitationSentAt: undefined,
  invitationAcceptedAt: undefined,
  invitationLimit: undefined,
  invitedById: undefined,
  invitedByType: undefined,
  invitationsCount: undefined,
  provider: undefined,
  uid: undefined,
  uuid: undefined,
  timezoneName: undefined,
  preferences: undefined,
  signatureFileName: undefined,
  signatureContentType: undefined,
  signatureFileSize: undefined,
  signatureUpdatedAt: undefined,
  videoUrls: undefined,  oauthUid: undefined,
  oauthProvider: undefined,
  coachQualifications: undefined
};


export const createAdminRecord = (admin: Admin) => recordify<Admin, AdminRecord>(admin);
export const initialState = createAdminRecord(adminDefault);

export const reducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case SET_ADMIN:
      return createAdminRecord(action.payload);
    case REMOVE_ADMIN:
      return initialState;
    default:
      return state;
  }
};
