export const CREATE_NOTE = `
  mutation CreateNote($adminUuid: String!, $userUuid: String!) {
    createNote(adminUuid: $adminUuid, userUuid: $userUuid)
  }
`;

export const UPDATE_NOTE = `
  mutation updateNote($id: Int!, $noteType: String, $body: String, $status: String) {
    updateNote(id: $id, noteType: $noteType, body: $body, status: $status)
  }
`;

export const DELETE_NOTE = `
  mutation deleteNote($id: Int!) {
    deleteNote(id: $id)
  }
`;

