import { useQuery } from '@apollo/client';
import * as React from 'react';
import { GET_USER_TASKS } from '../../../graphql/queries/User';
import { TaskKind, TaskStatuses } from '../../../api-client';
import TaskCard from './TaskCard';
import { createTaskRecord } from '../../../redux/reducers/tasks';
import SnackBar from '../SnackBar';
import { DispatchMap } from '../../../redux/actions/interfaces';
import { ApiClientNames } from '../../apollo/ApolloContainer';

import { Task } from '../../../graphql/models/Task';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';
import { useSelector } from 'react-redux';
import { healthLogNotesFilterFlag, painUptickFilterFlag } from '../../../features';
import { RootState } from '../../../redux/reducers';

interface CompProps {
  userId: number;
}

type ConnectProps = DispatchMap<{
  highUtilTasksEnabled?: boolean;
}>;

interface GetUserTaskVariables {
  userId: number;
  status: TaskStatuses;
}

interface Response {
  taskList: { tasks: Task[] };
}

type Props = ConnectProps & CompProps;
const TaskCardContainer: React.FC<Props> = props => {
  const { loading, error, data } = useQuery<Response, GetUserTaskVariables>(GET_USER_TASKS, {
    variables: { userId: props.userId, status: TaskStatuses.Pending },
    fetchPolicy: 'network-only',
    context: { clientName: ApiClientNames.BFF }
  });
  const [tasks, setTasks] = React.useState<Task[] | undefined>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transform = (t: any) => createTaskRecord(t);
  const [message, setMessage] = React.useState({ m: [] });

  React.useEffect(() => {
    setTasks(data?.taskList.tasks);
  }, [data]);

  const healthLogNotesEnabled = useSelector(
    (state: RootState) => selectTreatmentValue(state.splitReducer, healthLogNotesFilterFlag) === 'on'
  );
  const painUptickEnabled = useSelector(
    (state: RootState) => selectTreatmentValue(state.splitReducer, painUptickFilterFlag) === 'on'
  );

  React.useEffect(() => {
    if (!healthLogNotesEnabled) {
      setTasks(prevTasks => prevTasks?.filter(t => t.kind !== TaskKind.healthLogNote));
    }
    if (!painUptickEnabled) {
      setTasks(prevTasks => prevTasks?.filter(t => t.kind !== TaskKind.painUptick));
    }
  }, [healthLogNotesEnabled, painUptickEnabled, setTasks, data]);

  return (
    <>
      {/* TODO remove any */}
      {/* eslint-disable */}
      {!error &&
        !loading &&
        tasks?.map((t: any) => {
          if (props.highUtilTasksEnabled) {
            return (
              <TaskCard
                key={t.id}
                showSnackBar={msg => {
                  message.m.unshift(msg as never);
                  setMessage({ m: message.m });
                }}
                userId={props.userId}
                task={transform(t)}
              />
            );
          } else {
            if ([TaskKind.newHighUtilizationUser].indexOf(t.kind as TaskKind) < 0) {
              return (
                <TaskCard
                  key={t.id}
                  showSnackBar={msg => {
                    message.m.unshift(msg as never);
                    setMessage({ m: message.m });
                  }}
                  userId={props.userId}
                  task={transform(t)}
                />
              );
            } else {
              return <></>;
            }
          }
        })}
      {/* tslint:enable */}
      {error && !loading && (
        <div
          style={{
            position: 'absolute',
            color: 'rgb(144, 164, 174)',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%',
            fontSize: '14px'
          }}
        >
          Unable to load the user task
        </div>
      )}
      {!loading && tasks?.length === 0 && (
        <div
          style={{
            position: 'relative',
            height: '100%'
          }}
        >
          <div
            style={{
              position: 'absolute',
              color: 'rgb(144, 164, 174)',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%',
              fontSize: '14px'
            }}
          >
            No Open Task
          </div>
        </div>
      )}
      {message.m.length > 0 && (
        <div style={{ position: 'absolute', bottom: 0 }}>
          {message.m.reverse().map((msg: string, index: number) => (
            <SnackBar index={index} message={msg} />
          ))}
        </div>
      )}
    </>
  );
};

export default React.memo(TaskCardContainer);
