import * as React from 'react';
import { connect } from 'react-redux';
import { WeeklyMetric, User, Screener } from '../../api-client';
import { userActions } from '../../redux/actions';
import { DispatchMap } from '../../redux/actions/interfaces';
import { selectUserWeeklyMetrics, selectUser, selectWeeklyAveragePain, selectScreener } from '../../redux/selectors';
import { RootState } from '../../redux/reducers';
import { Map } from 'immutable';
import EngagementChart from '../../components/charts/EngagementChart';
import { Segment, Header, Message } from 'semantic-ui-react';
import EngagementTable from '../../components/charts/EngagementTable';
import { Store } from 'redux';
import { generateWeekRange } from '../../utils/number';

interface EngagementStatsSegmentProps {
  store?: Store<RootState>;
  userId: number;
}

interface StateProps {
  user?: User;
  weeklyMetrics: Map<string, WeeklyMetric>;
  screener?: Screener;
  weeklyAveragePain: number[];
}

type DispatchProps = DispatchMap<{
  getPathwayStats: typeof userActions.getPathwayStats;
}>;

type Props = EngagementStatsSegmentProps & StateProps & DispatchProps;

class EngagementStatsSegment extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.getPathwayStats(this.props.userId);
  }

  render() {
    if (!this.props.user || !this.props.screener) return null;
    const currentActiveWeek = this.props.user?.team?.currentTeamWeekNumber || 0;

    // We choose currentActiveWeek or 0 because we don't want to generate
    // tables for users in prepathway weeks (week < 0)
    // this is horrible but I'd rather it live here than the backend.
    const weekRange = this.props.user.team ?
      generateWeekRange(Math.max(currentActiveWeek, 0)) : [0, 13];
    const headerText = this.props.user.firstName ? `${this.props.user.firstName}'s Engagement` : undefined;
    return (
      <Segment>
        <Header>{headerText}</Header>
        <EngagementChart
          startWeek={weekRange[0]}
          endWeek={weekRange[1]}
          weeklyMetrics={this.props.weeklyMetrics}
          user={this.props.user}
        />
        {this.props.screener
          ? <EngagementTable
            weeklyMetrics={this.props.weeklyMetrics}
            screener={this.props.screener}
            weeklyAveragePain={this.props.weeklyAveragePain}
          />
          : <Message error icon="exclamation" header="Screener data is missing!" />
        }
      </Segment>
    );
  }
}

const mapStateToProps = (state: RootState, props: EngagementStatsSegmentProps): StateProps => ({
  screener: selectScreener(state, props),
  user: selectUser(state, props),
  weeklyAveragePain: selectWeeklyAveragePain(state, props),
  weeklyMetrics: selectUserWeeklyMetrics(state, props)
});

const mapDispatchToProps = {
  getPathwayStats: userActions.getPathwayStats
};

export default connect(mapStateToProps, mapDispatchToProps)(EngagementStatsSegment);
