import * as React from 'react';

import MessageListItem from './MessageListItem';
import InfiniteScroller from '../ui/InfiniteScroller';
import { MessageRecord, UserRecord } from '../../redux/reducers/index';
import { isEqual } from 'lodash';

export interface MessageListProps {
  messages: MessageRecord[];
  user: UserRecord;
  conversationId: string;
  fetchPagedMessage(): void;
  currentPage: number;
  indexToRender: number;
  isLoading: boolean;
  totalPages?: number | null;
}
export class MessageList extends React.PureComponent<MessageListProps> {
  render() {
    return (
      <InfiniteScroller
        reversed={true}
        currentPage={this.props.currentPage}
        indexToRender={this.props.indexToRender}
        loadMore={this.props.fetchPagedMessage}
        isLoading={this.props.isLoading}
      >
        {this.props.messages.map((item, idx) => (
          <MessageListItem
            key={btoa(unescape(encodeURIComponent(JSON.stringify(item))))}
            conversationId={this.props.conversationId}
            messageId={item.id}
            user={this.props.user}
          />
        ))}
      </InfiniteScroller>
    );
  }
}

export default React.memo(MessageList, isEqual);
