import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux/reducers/index';
import { User, MassMessageTemplate, Team } from '../../api-client/interfaces';
import { selectTemplateUserIds, selectUser, selectTeam } from '../../redux/selectors';
import { DispatchMap } from '../../redux/actions/interfaces';
import { toggleSelectTemplateUserId } from '../../redux/actions/ui-state';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import './MassMessagingUserTable.scss';
import UserStatusLabelContainer from '../../containers/communications/UserStatusLabelContainer';

interface MassMessagingUserRowProps {
  userId: number;
  template: MassMessageTemplate;
}

type ConnectProps = DispatchMap<{
  user: User;
  team?: Team;
  selectedTemplateUserIds?: number[];
  toggleTemplateUserId: typeof toggleSelectTemplateUserId;
}>;

type Props = MassMessagingUserRowProps & ConnectProps;

const MassMessagingUserRow: React.FC<Props> = ({
  user,
  template,
  selectedTemplateUserIds,
  userId,
  team,
  toggleTemplateUserId
}) => {

  const addToSendList = () => toggleTemplateUserId(true, userId, template);

  const removeFromSendList = () => toggleTemplateUserId(false, userId, template);

  return (
    <tr className={selectedTemplateUserIds && selectedTemplateUserIds.indexOf(userId) < 0 ?
      'mass-messaging__recipients--excluded' : ''}>
      <td>
        {selectedTemplateUserIds && selectedTemplateUserIds.indexOf(userId) < 0 ?
          <CheckBoxOutlineBlank className='mass-messaging__recipients__checkmark' onClick={addToSendList}/> :
          <CheckBox className='mass-messaging__recipients__checkmark' onClick={removeFromSendList}/>}
      </td>
      <td>
        <div className='mass-messaging__recipients__user'>
          <span className='mass-messaging__recipients__user__name'>
            {user.firstName} {user.lastName}
          </span>
          <div className='mass-messaging__recipients__user__status'>
            <UserStatusLabelContainer userId={userId} uuid={user.uuid}/>
          </div>
        </div>
      </td>
      <td>
        {team?.name || ''}
      </td>
    </tr>
  );
};

const mapStateToProps = (state: RootState, props: MassMessagingUserRowProps) => {
  const userInState = selectUser(state, { userId: props.userId });

  return {
    user: selectUser(state, { userId: props.userId }),
    team: userInState.teamId ? selectTeam(state, { teamId: userInState.teamId }) : undefined,
    selectedTemplateUserIds: selectTemplateUserIds(state, props.template)
  };
};

const mapDispatchToProps = {
  toggleTemplateUserId: toggleSelectTemplateUserId
};

export default connect(mapStateToProps, mapDispatchToProps)(MassMessagingUserRow);
