import { TypedRecord, makeTypedFactory } from 'typed-immutable-record';
import { Map } from 'immutable';
import { Task, AnyTaskExtras } from '../../api-client';
import { RootAction } from '../actions';
import {
  ENTITIES_FETCHED,
  COMPLETE_TASK,
  REJECT_TASK
} from '../constants';
import { createEntityMap, mergeUpdate } from '../../utils/entity';

export interface TaskRecord extends TypedRecord<TaskRecord>, Task<AnyTaskExtras> { }

export const createTaskRecord = makeTypedFactory<Task<AnyTaskExtras>, TaskRecord>({
  id: NaN,
  coachId: NaN,
  kind: undefined,
  extras: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  status: undefined,
  resolutionNote: undefined,
  category: undefined,
  team: undefined,
  user: undefined
});

export type State = Map<number, TaskRecord>;

export const initialState: State = Map();

export const reducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case ENTITIES_FETCHED:
      return action.payload.entities.tasks
        ? mergeUpdate(state, createEntityMap(createTaskRecord, action.payload.entities.tasks))
        : state;
    case COMPLETE_TASK:
    case REJECT_TASK:
      const taskId = action.payload.id;
      if (taskId !== undefined) {
        return state.update(taskId, (t) => t && t.merge({
          resolutionNote: action.payload.message,
          status: action.payload.status
        }));
      }
      return state;
    default:
      return state;
  }
};
