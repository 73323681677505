import * as React from 'react';
import { RootState, UserRecord } from '../../../redux/reducers';
import { selectUser } from '../../../redux/selectors';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { RED, GREEN, GREY } from '../../../colors';
import { UserProfileRoutes } from '../../../utils/user/routes';

const TabMenu = styled(Menu) `
.active.item {
  background: #f2f2f2 !important;

  &:hover {
    background: #f2f2f2 !important;
  }
}

.item:hover {
  background: #f2f2f2 !important;
}
`;

interface ConnectProps {
  user?: UserRecord;
}

export interface StatsTabItemProps {
  small?: boolean;
}

export interface StatsTabValueProps {
  negative?: boolean;
  positive?: boolean;
}

interface StatsTabLabelProps {
  active?: boolean;
}

const StatsTabItem = styled.div`
  color: #000 !important;
  display: flex;
  flex-direction: column;
  font-size: ${(props: StatsTabItemProps) => (props.small ? '0.75' : '1')}em;
  font-weight: bold;
  height: 100%;
  justify-content: space-around;
  padding: 0.5rem;
`;

const StatsTabLabel = styled.div`
  border-bottom: ${(props: StatsTabLabelProps) =>
    props.active ? '2px solid black' : '0px'};
  color: ${(props: StatsTabLabelProps) =>
    props.active ? '#00bcd4' : '#000000'};
  text-transform: uppercase;
  margin-bottom: 0.5em;
`;

const StatsTabValue = styled.div`
  color: ${(props: StatsTabValueProps) =>
    (props.negative && RED) || (props.positive && GREEN) || 'inherit'};
  font-size: 1.5em;

  > small {
    color: ${GREY};
  }
`;

export interface PathwayStatsMenuProps {
  pathname: string;
  userId: number;
}

type Props = ConnectProps & PathwayStatsMenuProps;

const PathwayStatsMenu: React.FunctionComponent<Props> = ({
  user,
  pathname
}) => {

  const isMigratedFromLegacy = user?.tags?.includes('Leg2PHX');

  return (
    <TabMenu pointing>
      <Menu.Item
        link
        name="engagement"
        active={pathname.endsWith(UserProfileRoutes.Engagement)}
      >
        <Link to="engagement">
          <StatsTabItem>
            <StatsTabLabel
              active={pathname.endsWith(UserProfileRoutes.Engagement)}
            >
              Engagement
            </StatsTabLabel>
            <StatsTabValue />
          </StatsTabItem>
        </Link>
      </Menu.Item>
      <Menu.Item link name="pain" active={pathname.endsWith(UserProfileRoutes.Pain)}>
        <Link to="pain">
          <StatsTabItem>
            <StatsTabLabel
              active={pathname.endsWith(UserProfileRoutes.Pain)}
            >
              Pain
            </StatsTabLabel>
            <StatsTabValue />
          </StatsTabItem>
        </Link>
      </Menu.Item>
      {isMigratedFromLegacy &&
          <Menu.Item link name="legacy engagement" active={pathname.endsWith(UserProfileRoutes.LegacyEngagement)}>
            <Link to="legacy_engagement">
              <StatsTabItem>
                <StatsTabLabel
                  active={pathname.endsWith(UserProfileRoutes.LegacyEngagement)}
                >
                Legacy Engagement
                </StatsTabLabel>
                <StatsTabValue />
              </StatsTabItem>
            </Link>
          </Menu.Item>
      }
      <Menu.Item link name="" disabled />
      <Menu.Menu position="right">
        <Menu.Item link name="" disabled />
      </Menu.Menu>
    </TabMenu>
  );
};

const mapStateToProps = (
  state: RootState,
  props: PathwayStatsMenuProps
) => ({
  user: selectUser(state, { userId: props.userId })
});

export default connect(mapStateToProps)(PathwayStatsMenu);
