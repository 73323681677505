import * as initTrace from 'debug';

import { User, TeamResponse } from '../../api-client';
import { PayloadAction, VoidAction, ThunkAction } from './interfaces';
import {
  SELECT_USER,
  SELECT_ALL_USERS_IN_TEAM,
  SELECT_ALL_USERS,
  CLEAR_ALL_USERS,
  CLEAR_SELECTED_USER,
  SEARCH_USER, ENTITIES_FETCHED
} from '../constants';
import { getApiClient } from '../selectors';
import { GET_TEAMS_BFF } from '../../graphql/queries/Teams';

const debug = initTrace('ca:redux:actions:team-page');

const TEAMS_PER_PAGE = 10;

interface SingleUserAction {
  userId: number;
  teamName?: string;
}

interface BatchUserAction {
  userIds: number[];
  teamName?: string;
}

interface GetTeamsParams {
  page: number;
  itemsPerPage?: number;
}

export interface ChooseUserAction extends PayloadAction<SingleUserAction> {
  type: typeof SELECT_USER;
}

export interface ClearUserAction extends PayloadAction<SingleUserAction> {
  type: typeof CLEAR_SELECTED_USER;
}

export interface ChooseAllUserAction extends PayloadAction<BatchUserAction> {
  type: typeof SELECT_ALL_USERS;
}

export interface ClearAllUsersAction extends VoidAction {
  type: typeof CLEAR_ALL_USERS;
}

export interface ChooseAllUsersByTeam extends PayloadAction<BatchUserAction> {
  type: typeof SELECT_ALL_USERS_IN_TEAM;
}

export interface SearchUserAction extends PayloadAction<{ searchString: string; keys?: string[] }> {
  type: typeof SEARCH_USER;
}

export type TeamPageAction = ChooseUserAction | ClearUserAction | ChooseAllUserAction | ClearAllUsersAction
| SearchUserAction;

export const actionCreators = {
  chooseUser(id: number, teamName?: string): ChooseUserAction {
    debug(`selecting user: ${id} ${teamName ? `from team: ${teamName}` : ''}`);
    return { payload: { userId: id, teamName }, type: SELECT_USER };
  },
  chooseAllUsers(users: User[]): ChooseAllUserAction {
    return { payload: { userIds: users.map((u) => u.id) }, type: SELECT_ALL_USERS };
  },
  clearAllUsers(): VoidAction {
    return { type: CLEAR_ALL_USERS };
  },
  clearSelectedUser(id: number, teamName?: string): ClearUserAction {
    return { payload: { userId: id, teamName }, type: CLEAR_SELECTED_USER };
  },
  searchUser(searchString: string): SearchUserAction {
    return { payload: { searchString }, type: SEARCH_USER };
  },
  getTeams({ page, itemsPerPage }: GetTeamsParams): ThunkAction<Promise<TeamResponse>> {
    return (dispatch, getState) => {
      const api = getApiClient(getState());
      return api.teams.getTeams({
        query: GET_TEAMS_BFF,
        variables: { page, itemsPerPage: itemsPerPage || TEAMS_PER_PAGE }
      }).then((res) => {
        dispatch({ payload: res, type: ENTITIES_FETCHED });
        return res;
      });
    };
  }
};
