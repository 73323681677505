import * as React from 'react';
import * as luxon from 'luxon';
import styled from 'styled-components';
import AccessTimeIconBase from '@material-ui/icons/AccessTime';
import { Typography } from '@material-ui/core';

export interface Props {
  timezone: string;
}

const AccessTimeIcon = styled(AccessTimeIconBase)`
  && {
    height: 1rem;
    width: 1rem;
    margin-right: 2px;
  }
`;

const UserLocalTimeText: React.FC<Props> = ({ timezone }) => {
  const localTime = luxon.DateTime.now().setZone(timezone);
  const formattedTime = localTime.toFormat('h:mm a');
  const abbrTimeZone = localTime.toFormat('ZZZZ');

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
      <AccessTimeIcon />
      <div data-testid='local-time-text'>
        <Typography>
          Local time is <strong>{formattedTime}</strong> {abbrTimeZone}
        </Typography>
      </div>
    </div>
  );
};

export default UserLocalTimeText;
