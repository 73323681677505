import * as React from 'react';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import { Box, Tab, Tabs, ThemeContext } from 'grommet';
import { RootState, UserRecord } from '../../redux/reducers';
import { selectUser, selectUserTasksCount } from '../../redux/selectors';
import { selectInCtpIframe } from '../../redux/selectors/ui-state';
import { userActions, DispatchThunk } from '../../redux/actions';
import { connect, useDispatch } from 'react-redux';
import UserPosterManager from './UserPosterManager';
import ScreenerManager from './ScreenerManager';
import TaskCardContainer from '../tasks/messages/TaskCardContainer';
import UserNoteManager from './UserNoteManager';
import { GET_USER_TASKS } from '../../graphql/queries/User';
import { Task } from '../../graphql/models/Task';
import { EnrollmentStatus, Status, GetUserById } from '../../graphql/models/bff';
import * as C from '../../colors';
import './UserProfileCommunicationContainer.css';
import { DispatchMap } from '../../redux/actions/interfaces';
import { updateMultiplesTaskUserAndCount } from '../../redux/actions/ui-state';
import { TaskKind, TaskStatuses } from '../../api-client';
import { ApiClientNames } from '../apollo/ApolloContainer';
import { EngagementManager } from '../../components/communications/EngagementManager';
import { getEnrollmentStatus, inverseStatus } from '../../utils/user/enrollmentStatus';

interface UserProfileCommunicationContainerProps {
  userId: number;
}

type ConnectProps = DispatchMap<{
  count: number;
  user?: UserRecord;
  inCtpIframe?: boolean;
  highUtilTasksEnabled?: boolean;
  updateMultiTaskUserAndCount: typeof updateMultiplesTaskUserAndCount;
}>;

export type Props = ConnectProps & UserProfileCommunicationContainerProps;

const UserCommunicationTabStyles = {
  tabs: {
    panel: {
      extend: {
        height: '100%',
        overflowY: 'auto'
      }
    },
    extend: {
      width: '100%'
    }
  },
  tab: {
    active: {
      color: C.TAB_ACTIVE
    }
  }
};

interface TaskKindCount {
  [todoKey: string]: number;
}

interface UserPosterResponse {
  getUserById: GetUserById;
}

export const groupByTaskKind = (tasks?: Task[] | null) => {
  if (!tasks) return null;

  const taskKindAndCount: TaskKindCount = {};

  tasks.map(task => {
    if (!taskKindAndCount[task.kind]) {
      taskKindAndCount[task.kind] = 1;
    } else {
      taskKindAndCount[task.kind]++;
    }
  });

  return taskKindAndCount;
};

interface GetUserTaskVariables {
  userId: number;
  status: TaskStatuses;
}

interface Response {
  taskList: { tasks: Task[] };
}

export const ProfileContainer = styled.div`
  padding: 2rem 1rem 0 1rem;
  display: flex;
  justify-content: center;
`;

const UserProfileCommunicationContainer: React.FC<Props> = ({
  count,
  userId,
  user,
  inCtpIframe,
  highUtilTasksEnabled,
  updateMultiTaskUserAndCount
}) => {
  const dispatch: DispatchThunk = useDispatch();
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [enrollmentStatus, setEnrollmentStatus] = React.useState<EnrollmentStatus | null>(null);

  const { data, error } = useQuery<Response, GetUserTaskVariables>(GET_USER_TASKS, {
    variables: { userId, status: TaskStatuses.Pending },
    fetchPolicy: 'network-only',
    context: { clientName: ApiClientNames.BFF }
  });

  const { error: getUserByIdError, loading: getUserByIdLoading, data: getUserByIdData } = useQuery<UserPosterResponse>(
    gql`
      query getUserById($userId: ID!) {
        getUserById(userId: $userId) {
          id
          firstName
          lastName
          city
          stateOfResidence
          avatarUrl
          email
          phoneNumber
          contactMethod
          tags
          timezone
          uuid
          dateOfBirth
          enrollmentStatus {
            status
          }
          pathways {
            id
            uuid
            currentLevel
            currentWeek
            currentExerciseTherapyStream
            programIndication {
              identifier
              programName
              indicationName
            }
            etSessionGroupings {
              weekNumber
              etSessions {
                startedAt
                userId
                complete
                day
                definitionLevel
                pathwayWeek
                byod
                sensorless
              }
            }
            firstEtAt
            stage
          }
          team {
            id
            name
          }
          userFeatures {
            videoVisitEligibility
          }
          client {
            id
            name
          }
          coverages {
            physicalTherapist {
              role
              name
            }
            healthCoach {
              role
              name
            }
          }
        }
      }
    `,
    {
      variables: { userId },
      fetchPolicy: 'network-only',
      context: {
        clientName: ApiClientNames.BFF
      }
    }
  );

  React.useEffect(() => {
    if (getUserByIdData) {
      const currentEnrollmentStatusFromUser = getEnrollmentStatus(getUserByIdData.getUserById);
      setEnrollmentStatus(currentEnrollmentStatusFromUser);
    }
  }, [getUserByIdData]);

  const switchTabs = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  React.useEffect(() => {
    const groupedTasks = groupByTaskKind(data?.taskList?.tasks);

    if (groupedTasks) {
      if (highUtilTasksEnabled) {
        updateMultiTaskUserAndCount({ tasksWithCount: groupedTasks, userId });
      } else {
        const filteredGroupedTasks = Object.keys(groupedTasks).filter(
          t => [TaskKind.newHighUtilizationUser].indexOf(t as TaskKind) < 0
        );
        updateMultiTaskUserAndCount({
          tasksWithCount: filteredGroupedTasks.reduce((acc, task) => {
            acc[task] = groupedTasks[task];
            return acc;
          }, {} as { [key: string]: number }),
          userId
        });
      }
    }
  }, [data?.taskList?.tasks?.length]);

  const handleChangeEnrollmentStatus = (status: Status) => {
    dispatch(userActions.updateUserEnrollmentStatus(userId, inverseStatus(status))).then(res => {
      const updatedStatus = res.enrollmentStatus?.length ? res.enrollmentStatus[0] : null;

      setEnrollmentStatus(updatedStatus);
    });
  };

  if (!user || error || getUserByIdError) return null;

  return user ? (
    <Box
      className='user-profile-communication-container'
      style={{ flex: 3, minWidth: inCtpIframe ? '560px' : '225px', overflow: 'auto' }}
      alignSelf='start'
      flex
      fill='vertical'
    >
      <Box pad='none' style={{ minHeight: '100px', overflow: 'auto' }}>
        <UserPosterManager
          userData={getUserByIdData?.getUserById}
          enrollmentStatus={enrollmentStatus}
          changeEnrollmentStatus={handleChangeEnrollmentStatus}
        />
      </Box>
      <Box
        pad='none'
        style={{ padding: '15px 0 0 0', minHeight: inCtpIframe ? '300px' : '200px' }}
        flex
        fill='vertical'
      >
        <ThemeContext.Extend value={UserCommunicationTabStyles}>
          <Tabs onActive={switchTabs} activeIndex={activeTab} alignSelf='start' style={{ height: '100%' }}>
            <Tab title='Engagement'>
              <EngagementManager
                userId={user.id}
                userData={getUserByIdData?.getUserById}
                dataLoading={getUserByIdLoading}
              />
            </Tab>
            <Tab
              title={
                <span className={activeTab === 1 ? 'task-tab active' : 'task-tab'}>
                  {'Tasks '}
                  {count !== 0 && (
                    <span
                      className={activeTab === 1 ? 'count active' : 'count'}
                      style={{
                        borderRadius: '8px',
                        fontSize: '13px',
                        padding: '5px',
                        color: 'white'
                      }}
                    >
                      {count}
                    </span>
                  )}
                </span>
              }
            >
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <TaskCardContainer userId={user.id} />
              </div>
            </Tab>
            <Tab title='Notes'>
              <div style={{ position: 'relative', height: '100%' }}>
                <UserNoteManager userId={userId} userUuid={user.uuid} />
              </div>
            </Tab>
            <Tab title='Screener'>
              <ScreenerManager userId={user.id} userUuid={user.uuid} />
            </Tab>
            {/* TODO CT-1387: Add EngagementEvents to BE */}
            {/* <Tab title="Tidbits">*/}
            {/* <Box style={{ height: "100%", width: "486px" }}>*/}
            {/* <EngagementEvents user={user} />*/}
            {/* </Box>*/}
            {/* </Tab>*/}
          </Tabs>
        </ThemeContext.Extend>
      </Box>
    </Box>
  ) : null;
};

const mapStateToProps = (state: RootState, props: UserProfileCommunicationContainerProps) => ({
  count: selectUserTasksCount(state, props.userId),
  user: selectUser(state, { userId: props.userId }),
  inCtpIframe: selectInCtpIframe(state)
});

const mapDispatchToProps = {
  updateMultiTaskUserAndCount: updateMultiplesTaskUserAndCount
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileCommunicationContainer);
