import * as React from 'react';
import { NavLink, useRouteMatch, generatePath } from 'react-router-dom';

import { Team } from '../../graphql/models';
import { TEAM_PATH } from '../../routes';
import './TeamListItem.scss';

interface Props {
  team: Team;
  selected?: boolean;
  setSelectedTeamId(id: string): void;
  setSelectedTeam(team?: Team): void;
}

const TeamListItem: React.FC<Props> = props => {
  const { team } = props;
  const [selected, setSelected] = React.useState<boolean>(false);

  const match = useRouteMatch();

  const handleOnClick = React.useCallback(() => {
    props.setSelectedTeam(team);
    props.setSelectedTeamId(team.id);
  }, [team]);

  return (
    <NavLink
      to={generatePath(`${match?.url}${TEAM_PATH}`, { teamId: team.id })}
      className={(isActive) => {
        setSelected(isActive);
        return isActive ? 'team-list-item--selected' : '';
      }}
    >
      <div className={`team-list-item ${selected && 'team-list-item--selected'}`} onClick={handleOnClick}>
        <div className="team-list-item-name">{team.name}</div>
      </div>
    </NavLink>
  );
};

export default TeamListItem;
