import * as React from 'react';
import { Box } from 'grommet';
import {
  ScreenerRow,
  ScreenerCell,
  ScreenerText,
  ScreenerField,
  SCREENER_RESPONSES
} from '../../containers/communications/ScreenerManager';
import { FollowUps, Impact } from '../../graphql/models';
import { formatImactSurgeriesUnifiedUser, isNullOrUndefined } from '../../utils/helpers';

export interface UpcomingSurgeriesProps {
  follow_ups: Pick<FollowUps, 'upcoming_surgeries' | 'future_surgery'>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  impact?: Impact | any | null;
}


const UpcomingSurgeries: React.FC<UpcomingSurgeriesProps> = ({ follow_ups, impact }) => (
  <ScreenerRow>
    <ScreenerField size='1/4'>Upcoming surgeries or procedures</ScreenerField>
    <ScreenerCell>
      {formatImactSurgeriesUnifiedUser(impact).length  ?
        formatImactSurgeriesUnifiedUser(impact).length > 0 ?
          formatImactSurgeriesUnifiedUser(impact).map((item) => (<Box direction='column' flex>
            <ScreenerText>{`${item.indication} : ${item.type} : ${item.date}`}</ScreenerText>
          </Box>)) : '-'
        :  isNullOrUndefined(impact) || isNullOrUndefined(impact.upcoming_surgeries) ? (
          '-'
        ) : (
          <Box direction='column' flex>
            <ScreenerText>{`${SCREENER_RESPONSES[impact.upcoming_surgeries]}`}</ScreenerText>
            <ScreenerText>{follow_ups.upcoming_surgeries}</ScreenerText>
          </Box>
        )}
    </ScreenerCell>
  </ScreenerRow>
);


export default UpcomingSurgeries;
