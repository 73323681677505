import * as React from 'react';
import styled from 'styled-components';
import { Launch } from '@material-ui/icons';
import { GetUserById } from '../../graphql/models/bff';
import { getCurrentStream } from '../../graphql/models/bff/pathwayHelperUtil';
import { mixpanelTrack } from '../../mixpanel/mixpanel';
import { Typography, Grid, Box } from '@material-ui/core';
import { LABEL_GRAY, LABEL_BLACK } from '../../colors';
import { calculateAge } from '../../utils/helpers';
import { PathwayStage, ProgramName } from '../../api-client/interfaces';
import { PhysicalTherapistInfo } from '../phx/user/UserProfileBanner';
import { useMaternityWeek } from '../../utils/hooks/useMaternityWeek';
import { useLazyQuery } from '@apollo/client';
import { Indication } from '../../graphql/models/bff/MemberCondition';
import { GET_ALL_MEMBER_CONDITIONS } from '../../graphql/queries/User';
import { ApiClientNames } from '../../containers/apollo/ApolloContainer';
import { MSK_CONDITION_KIND, MemberConditionsResponse } from '../../containers/user/phx/UserProfileBannerContainer';

export const NON_UNIFIED_USER_DEFAULT_INDICATION_TEXT = 'N/A (Non Unified Member)';

export const InfoSectionDescription = styled(Grid)`
  && {
    color: ${LABEL_GRAY};
    min-width: 70px;
    max-width: 25%;
  }
`;

export const InfoSectionValue = styled(Grid)`
  && {
    color: ${LABEL_BLACK};
    max-width: 100%;
  }
`;

export const StyledLaunch = styled(Launch)`
  && {
    margin-left: 5px;
    height: 18px;
    vertical-align: text-bottom;
  }
`;

interface Props {
  user: GetUserById;
}

const getIndicationString = (indication: Indication): string => {
  const isPrimaryIndication = indication.priority === 0;
  const priorityString = isPrimaryIndication ? ' (P)' : '';
  return `${indication.kind}.${indication.bodyPart}.${indication.acuity} ${priorityString}`;
};

export const InfoSection: React.FC<{
  description: string;
  value?: string | JSX.Element | null;
}> = ({ description, value }) => (
  <>
    <Grid container direction='row' spacing={1}>
      <InfoSectionDescription item>
        <Typography variant='body2'>{description}</Typography>
      </InfoSectionDescription>
      <InfoSectionValue item>
        <Typography variant='body2' noWrap>
          {value || ''}
        </Typography>
      </InfoSectionValue>
    </Grid>
  </>
);

const UserPosterSummary: React.FC<Props> = props => {
  const { user } = props;
  const {
    client,
    team,
    pathways,
    contactMethod,
    phoneNumber,
    email,
    stateOfResidence,
    city,
    coverages,
    dateOfBirth
  } = user;

  const activePathway = pathways && pathways[0];
  const { isMaternity, maternityWeek } = useMaternityWeek(activePathway);

  const [indications, setIndications] = React.useState<Indication[]>([]);

  const [getAllMemberConditions, {
    data: indicationsData
  }] = useLazyQuery<MemberConditionsResponse>(GET_ALL_MEMBER_CONDITIONS);

  const currentLevel = activePathway?.currentLevel;
  const currentStream = getCurrentStream(activePathway);
  const docURL = 'https://docs.google.com/spreadsheets/d/14ogp1P3SA0OExULnwY7ISNu2YdqLI3SRTRYWtEwgGKA/edit#gid=0';
  const isVideoVisitEligible = user.userFeatures?.videoVisitEligibility;

  const isMultiIndicationUser = user.pathways?.some(pathway =>
    pathway.programIndication?.programName === ProgramName.UnifiedV1
  );

  const sendMixpanelEvent = () => {
    mixpanelTrack('Client Linked Out', {
      Origin: '',
      CompanyName: client && client.name
    });
  };

  React.useEffect(() => {
    if (indicationsData?.getAllMemberConditions?.[0]?.indications) {
      setIndications(indicationsData.getAllMemberConditions[0].indications);
    }
  }, [indicationsData]);

  React.useEffect(() => {
    if (user) {
      const { uuid } = user;

      getAllMemberConditions({
        variables: { memberId: uuid.toString(), kind: MSK_CONDITION_KIND },
        context: {
          clientName: ApiClientNames.NestBFF
        }
      });
    }
  }, [user]);

  const programIndicationsItems = React.useMemo(() => {
    if (isMultiIndicationUser) {
      return indications.map((indication) =>
        <Typography
          key={indication.urn}
          variant='body1'
          color='textPrimary'>
          {getIndicationString(indication)}
        </Typography>);
    }

    // If the user is not a multi-indication user, show a default indication text
    return [
      <Typography
        key={'non-unified-member-indication-text'}
        variant='body1'
        color='textPrimary'>
        {NON_UNIFIED_USER_DEFAULT_INDICATION_TEXT}
      </Typography>
    ];
  }, [indications, isMultiIndicationUser]);

  return (
    <Grid container spacing={1} direction='row'>
      <Grid item xs={6}>
        <InfoSection
          description='PT'
          value={
            coverages?.physicalTherapist ? (
              <PhysicalTherapistInfo
                name={coverages.physicalTherapist.name}
                isVideoVisitEligible={isVideoVisitEligible}
              />
            ) : (
              'Not available'
            )
          }
        />
        <InfoSection
          description='Coach'
          value={
            activePathway?.stage !== PathwayStage.Onboarding && coverages?.healthCoach?.name
              ? coverages?.healthCoach?.name
              : 'Not available'
          }
        />
        <InfoSection
          description='Company'
          value={
            client ? (
              <div onClick={sendMixpanelEvent}>
                <a style={{ color: 'inherit' }} target='_blank' href={docURL}>
                  {client && client.name}
                  <StyledLaunch />
                </a>
              </div>
            ) : (
              'Not available'
            )
          }
        />
        <InfoSection description='Team' value={team && team.name ? team.name : 'Not available'} />
        {isMaternity ? (
          maternityWeek && <InfoSection description='Mat. Week' value={`${maternityWeek}`} />
        ) : (
          <InfoSection description='Level' value={currentLevel ? `${currentLevel}` : 'Not available'} />
        )}
        <InfoSection description='Stream' value={currentStream} />
        {
          indications && indications.length > 0 &&
          <InfoSection
            description='Program Indication'
            value={<Box>{programIndicationsItems}</Box>}
          />
        }
      </Grid>
      <Grid item xs={6}>
        <InfoSection description='Preference' value={contactMethod || 'None'} />
        <InfoSection
          description='Location'
          value={stateOfResidence && city ? `${city}, ${stateOfResidence}` : 'Not available'}
        />
        <InfoSection description='Phone' value={phoneNumber || 'Not available'} />
        <InfoSection description='Email' value={email || 'Not available'} />
        <InfoSection description='Age' value={dateOfBirth ? calculateAge(dateOfBirth) : 'Not available'} />
      </Grid>
    </Grid>
  );
};

export default UserPosterSummary;
