import * as React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { UserProfileRoutes } from '../../utils/user/routes';
import { GetUserById, EnrollmentStatus, Status } from '../../graphql/models/bff';
import { TagList } from '../phx/user/UserProfileBanner';
import { renderTags } from '../phx/tasks/Tag';
import StrikeThrough from '../user/StrikeThrough';
import { isNullOrUndefined } from '../../utils/helpers';
import UserStatusFormContainer from '../../containers/communications/UserStatusFormContainer';
import UserStatusLabelContainer from '../../containers/communications/UserStatusLabelContainer';
import UserStatusDropdown from '../../containers/communications/UserStatusDropdown';
import DeleteUserStatusModal from '../../containers/communications/DeleteUserStatusModal';
import EnrollmentStatusModal from '../phx/user/EnrollmentStatusModal';
import { Grid, Avatar, Typography, Link } from '@material-ui/core';
import { HINGE_LINK, LABEL_BLACK } from '../../colors';
import { userOptedOut } from '../../utils/user/enrollmentStatus';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';
import { memberAlertsCutover } from '../../features';
import { getCareTeamProductsAppUrl } from '../../constant';

export const StyledNavLink = styled(NavLink)`
  :hover {
    text-decoration: underline;
    color: rgb(125, 76, 219);
  }
  color: inherit;
`;

export const UserName = styled(Typography)`
  && {
    color: ${LABEL_BLACK};
    font-size: 24px;
  }
`;

export const StyledAvatar = styled(Avatar)`
  && {
    border-radius: 50%;
  }
`;

export const UserId = styled(Typography)`
  && {
    color: ${HINGE_LINK};
    font-size: 14px;
    font-weight: 700;
  }
`;

interface Props {
  user: GetUserById;
  enrollmentStatus?: EnrollmentStatus | null;
  changeEnrollmentStatus: (status: Status) => unknown;
}

const UserPosterHeader: React.FC<Props> = ({
  enrollmentStatus,
  changeEnrollmentStatus,
  user: { id: userId, tags, avatarUrl, firstName, lastName, contactMethod, uuid }
}) => {
  const enrollmentStatuses = [enrollmentStatus] as EnrollmentStatus[];

  const id = typeof userId === 'string' ? parseInt(userId, 10) : userId;
  const userProfileURI = `/user/${id}/${UserProfileRoutes.Engagement}`;
  const carePlanURI = `${getCareTeamProductsAppUrl()}/member/${id}/care-plan`;
  const [showStatusModal, setShowStatusModal] = React.useState(false);
  const [deleteStatusModal, showDeleteStatusModal] = React.useState(false);
  const [showEnrollmentStatusModal, setShowEnrollmentStatusModal] = React.useState(false);

  const handleShowStatusModal = () => setShowStatusModal(true);
  const handleShowDeleteStatusModal = () => showDeleteStatusModal(true);
  const handleShowEnrollmentStatusModal = () => setShowEnrollmentStatusModal(!showEnrollmentStatusModal);

  const isMemberRecordLinkVisible = useSelector((state: RootState) =>
    selectTreatmentValue(state.splitReducer, memberAlertsCutover) === 'on'
  );

  return (
    <Grid container spacing={5} direction='row'>
      {showStatusModal && <UserStatusFormContainer userId={id} setShowStatusModal={setShowStatusModal} />}

      {deleteStatusModal && <DeleteUserStatusModal userId={id} showDeleteStatusModal={showDeleteStatusModal} />}
      {showEnrollmentStatusModal && (
        <EnrollmentStatusModal
          modalShown={showEnrollmentStatusModal}
          toggleModalShown={handleShowEnrollmentStatusModal}
          enrollmentStatus={enrollmentStatus}
          changeEnrollmentStatus={changeEnrollmentStatus}
        />
      )}
      <Grid item xs={1} style={{ paddingTop: '25px' }}>
        <StyledAvatar src={avatarUrl ? avatarUrl : ''} />
      </Grid>
      <Grid item xs={9} lg>
        <Grid container direction='column'>
          <Grid container spacing={3} alignItems='center'>
            <Grid item>
              <UserId>
                <StyledNavLink to={userProfileURI} target='_blank'>
                  {id ? id : null}
                </StyledNavLink>
              </UserId>
            </Grid>
            <Grid item>
              {isMemberRecordLinkVisible && (
                <UserId>
                  <Link
                    href={carePlanURI}
                    target='_blank'
                    rel='noopener noreferrer'
                    variant='body2'
                    color='inherit'
                    data-testid="ctp_care_plan_link"
                  >MEMBER RECORD (CTP)</Link>
                </UserId>
              )}
            </Grid>
          </Grid>
          <Grid container direction='row' style={{ paddingBottom: '5px' }} alignItems='center'>
            <Grid item style={{ paddingRight: '5px' }}>
              <UserName>
                <StyledNavLink to={userProfileURI} target='_blank'>
                  <StrikeThrough
                    enabled={isNullOrUndefined(contactMethod) || userOptedOut(enrollmentStatuses)}
                    data-testid='strike-through'
                  >
                    {`${firstName} ${lastName}`}
                  </StrikeThrough>
                </StyledNavLink>
              </UserName>
            </Grid>
            <UserStatusLabelContainer userId={id} uuid={uuid}/>
          </Grid>
          <Grid item>
            <TagList>
              {renderTags(tags)}
            </TagList>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <UserStatusDropdown
          userId={id}
          setStatusShowModal={handleShowStatusModal}
          deleteStatusShowModal={handleShowDeleteStatusModal}
          handleShowEnrollmentStatusModal={handleShowEnrollmentStatusModal}
          anchorPositionLeft={1520}
          anchorPositionTop={60}
          onlyShowEnrollmentOptions={false}
          enrollmentStatus={enrollmentStatus}
        />
      </Grid>
    </Grid>
  );
};

export default UserPosterHeader;
