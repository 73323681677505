import * as React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import {
  AnyTaskExtras,
  RuleDrivenExtras,
  SurgeryIncrExtras,
  YearTwoExtras,
  EnsoYearTwoExtras,
  SurgeryTrendsExtras,
  RuleDrivenTask,
  SurgeryIncrTask,
  TaskKind,
  TaskDescriptions,
  Team,
  Task,
  User
} from '../../../api-client/interfaces';
import { getKindColor } from '../../../utils/tasks';
import { isNumber } from '../../../utils/number';
import { Link } from 'react-router-dom';
import { UserProfileRoutes } from '../../../utils/user/routes';

import YearTwoTaskDetails from '../year-two-task-details/YearTwoTaskDetails';
import EnsoYearTwoTaskDetails from '../enso-task-details/EnsoYearTwoTaskDetails';

export const UserNameLink = styled.link`
  cursor: pointer;
  font-weight: 700;
  padding-right: 5px;
`;

export const StyledValuesContainer = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const StyledKindMessage = styled.h2`
  font-size: 1em;
  color: ${(props: { kind: TaskKind }) => getKindColor(props.kind)} !important;
  display: inline;
`;

export interface RuleDrivenTaskProps {
  task: RuleDrivenTask | SurgeryIncrTask | Task<YearTwoExtras>;
  extras?: SurgeryIncrExtras | SurgeryTrendsExtras | RuleDrivenExtras | null;
  kind?: TaskKind | null;
  className?: string;
  grouped?: boolean;
  inProfile?: boolean;
  user?: User;
  team: Team;
}

interface TaskDetailsProps {
  kind?: TaskKind | null;
  userId: number;
  seeMore?: boolean;
  user?: number | undefined | null;
  extras?: AnyTaskExtras | null;
}

const getSurgeryDiff = (reference: number | null | undefined, actual?: number | null): string => {
  if (isNumber(actual) && isNumber(reference) && actual >= reference) return 'INCREASE';
  if (isNumber(actual) && isNumber(reference) && actual < reference) return 'INSUFFICIENT DECREASE';
  return '';
};

const formatDiffDirection = (actual?: number | null) => {
  if (isNumber(actual) && actual > 0) return <Icon style={{margin: 0}} name="arrow down" />;
  if (isNumber(actual) && actual < 0) return <Icon style={{margin: 0}} name="arrow up" />;
  return <></>;
};

export const TaskDetails: React.FunctionComponent<TaskDetailsProps> = props => {
  const { kind, extras, userId } = props;
  const [seemore, setSeemore] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [seeMoreClicked, setSeeMoreClicked] = React.useState(false);
  const notesRef: React.RefObject<HTMLDivElement> =  React.createRef();
  React.useEffect(() => {
    if (!seeMoreClicked && notesRef?.current?.clientHeight && notesRef?.current?.clientHeight > 44) {
      if (!show) {
        setShow(true);
      }
      setSeemore(true);
    }
  }, [notesRef]);
  if (kind === TaskKind.healthLogNote && extras && 'note' in extras) {
    return (
      <div style={{height: '100%', color: 'rgb(38, 50, 56)', fontSize: '14px'}}>
        {extras?.note &&
        <div ref={notesRef} style={!seemore ? {height: 'auto',
                                               borderLeft: '5px solid rgb(207, 216, 220)', paddingLeft: '10px'} :
          {maxHeight: '44px', overflow: 'hidden', borderLeft: '5px solid rgb(207, 216, 220)', paddingLeft: '10px'}}>
          {extras?.note}
        </div>
        }
        <div style={{display: 'flex', marginTop: '5px', fontSize: '13px', cursor: 'pointer'}}>
          {seemore ? <div style={{color: 'rgb(0, 130, 121)', display: extras?.note && show ? 'block' : 'none'
          }} onClick={() => {
            setSeemore(false);
            setSeeMoreClicked(true);
          }}>See more</div> : <div style={{color: 'rgb(0, 130, 121)', display: extras?.note && show ? 'block' : 'none'}}
            onClick={() => {
              setSeemore(true);
              setSeeMoreClicked(false);
            }}>Show less</div>}
          <Link to={`/user/${userId}/${UserProfileRoutes.Pain}`}  target={'_blank'}
            style={{ textDecoration: 'none',
                     marginLeft: show ? '5px' : '0px', color: 'rgb(144, 164, 174)'}}>
            Pain Table
          </Link>
        </div>
      </div>
    );
  }
  if (kind === TaskKind.painUptick && extras && 'note' in extras) {
    return (
      <div style={{height: '100%', color: 'rgb(38, 50, 56)', fontSize: '14px'}}>
        <div style={{color: 'red'}}>Pain Increase: {extras?.increase}</div>
        {extras?.note &&
        <div style={{height: 'auto', margin: '10px 0 10px 0',
                     borderLeft: '5px solid rgb(207, 216, 220)', paddingLeft: '10px'}}>
          {extras?.note}
        </div>}
        <div style={{display: 'flex', marginTop: '5px', fontSize: '13px', cursor: 'pointer'}}>
          <Link to={`/user/${userId}/${UserProfileRoutes.Pain}`} target={'_blank'}
            style={{ textDecoration: 'none',
                     marginLeft: '0px', color: 'rgb(144, 164, 174)'}}>
            Pain Table
          </Link>
        </div>
      </div>
    );
  }
  if (kind === TaskKind.reEngager) {
    return <div style={{color: 'rgb(38, 50, 56)', fontSize: '14px'}}>
      <div style={{ marginBottom: '10px' }}>{TaskDescriptions[kind]}</div>
      <Link to={`/user/${userId}/${UserProfileRoutes.Engagement}`}  target={'_blank'}
        style={{ textDecoration: 'none', marginTop: '5px', color: 'rgb(144, 164, 174)'}}>
        Engagement Table
      </Link></div>;
  }
  if (kind === TaskKind.surgeryIncr) {
    const {
      diff,
      upcomingSurgeries,
      weekIndex,
      baselineLikelihood,
      currentLikelihood,
      referenceDiff,
      proceduresHaving
    } = extras as SurgeryTrendsExtras;
    const surgeryDiff = isNumber(diff) ? Math.abs(diff) : NaN;
    const refDiff = isNumber(referenceDiff) ? Math.abs(referenceDiff) : NaN;
    return (<div style={{ color: 'rgb(38, 50, 56)', fontSize: '14px' }}>
      <div style={{ color: 'rgb(144, 164, 174)', margin: '5px 0 5px 0', fontSize: '12px', fontWeight: 'bold' }}>
        {getSurgeryDiff(baselineLikelihood, currentLikelihood)}</div>
      <div style={{ color: 'rgb(38, 50, 56)', margin: '5px 0 5px 0', fontSize: '12px' }}>
        <span style={{ fontSize: '14px', marginRight: '5px' }}>{`${baselineLikelihood}`}</span>
        <Icon style={{ margin: '0 10px 0 10px', color: 'rgb(144, 164, 174)' }} name="long arrow alternate right" />
        <span style={{ fontSize: '14px', marginLeft: '5px' }}>{`${currentLikelihood}`}</span></div>
      <div style={{ margin: '5px 0 5px 0' }}> Actual: {formatDiffDirection(diff)}{`${Math.floor(surgeryDiff)}% `}</div>
      <div style={{ margin: '5px 0 10px 0' }}><span>{`Expected(wks ${weekIndex}): `}</span>
        <span style={{ fontSize: '14px' }}>{formatDiffDirection(referenceDiff)}{`${refDiff}%`}</span></div>
      {upcomingSurgeries ?
        <div>
          <div style={{ color: 'rgb(144, 164, 174)', margin: '5px 0 5px 0', fontSize: '12px', fontWeight: 'bold' }}>
            UPCOMING PROCEDURE
          </div>
          <div style={{ borderLeft: '5px solid rgb(207, 216, 220)', paddingLeft: '10px' }}>{proceduresHaving}</div>
        </div> : <></>
      }
    </div>);
  }

  const yearTwoTaskKinds = [
    TaskKind.yearTwoInactiveLastWeek,
    TaskKind.yearTwoActiveLastWeek,
    TaskKind.yearTwoActiveThisWeek
  ];

  if (extras && kind && yearTwoTaskKinds.includes(kind)) {
    return (
      <YearTwoTaskDetails
        userId={userId}
        staticDescription={TaskDescriptions[kind as string]}
        extras={extras as unknown as YearTwoExtras}
      />
    );
  }

  if (extras && kind && kind === TaskKind.ensoYearTwo) {
    return (
      <EnsoYearTwoTaskDetails
        userId={userId}
        staticDescription={TaskDescriptions[kind as string]}
        extras={extras as unknown as EnsoYearTwoExtras}
      />
    );
  }

  return <div style={{ color: 'rgb(38, 50, 56)', fontSize: '14px' }}>{TaskDescriptions[kind as string]}</div>;

};
