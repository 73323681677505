import * as React from 'react';
import { Location } from 'history';
import {
  Box,
  Grid
} from '@material-ui/core';

import { ERR_USER_ID_INVALID } from '../../errors';
import UserNavBar from '../../components/nav/UserNavBar';
import UserBioSections from './UserBioSections';
import UserGoalsSectionContainer from './UserGoalsSectionContainer';
import UserNoteManager from '../communications/UserNoteManager';
import UserProfileBannerContainer from '../user/phx/UserProfileBannerContainer';
import UserTasksContainer from './UserTasksContainer';

export interface UserOverviewContainerProps {
  location?: Location;
  userUuid?: string | null;
  id?: number;
}

class UserOverviewContainer extends React.PureComponent<UserOverviewContainerProps> {
  render() {
    const { id, userUuid, location } = this.props;
    if (id === undefined) throw new Error(ERR_USER_ID_INVALID);
    return (
      <div className="scrolling" style={{ width: '95%', margin: 'auto' }}>
        <UserProfileBannerContainer id={id} />
        <UserNavBar location={location} id={id} />
        <Grid container spacing={6} className='overview-grid'>
          <Grid item xs={6}>
            <UserNoteManager userId={id} userUuid={userUuid} view={'userOverview'} />
          </Grid>
          <Grid item xs={6}>
            <Box className='section'>
              <UserTasksContainer userId={id} className='section' />
            </Box>
            <Box className='section'>
              <UserGoalsSectionContainer userId={id} />
            </Box>
            <Box className='section'>
              <UserBioSections userId={id} />
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default UserOverviewContainer;
