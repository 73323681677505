import * as moment from 'moment-timezone';

import { UserResponse, PathwayStatsResponse, GetUserById, graphqlUsersParser } from '../../api-client';
import { UserRecord, createUserRecord } from '../reducers/user';

import { ThunkAction, PayloadAction } from './interfaces';
import {
  ADD_USER_TAG,
  REMOVE_USER_TAG,
  PUT_USER_NAME,
  PUT_USER_CONTACT_INFO,
  ENTITIES_FETCHED,
  PREPEND_OLD_USER_MESSAGES,
  APPEND_NEW_USER_MESSAGES,
  SET_USER_HAS_UNREAD,
  MARK_USER_ONBOARDED,
  RESET_USERS_ONBOARDED,
  MESSAGES_FETCHED,
  UPDATE_TEAM_RESULTS,
  UPDATE_USER_ENROLLMENT_STATUS
} from '../constants';
import { getApiClient, selectUser } from '../selectors';
import { ERR_PUT_USER, ERR_UPDATING_ENROLLMENT_STATUS, ERR_USERS_PARSE_FAIL } from '../../errors';
import { UpdateGoalAction, PostGoalAction, DeleteGoalAction } from './goals';

import { User as UserModel } from '../../graphql/models/User';
import { EnrollmentStatus, Status } from '../../graphql/models/bff';
import { Dispatch } from 'redux';
import {
  ADD_TAG,
  DELETE_TAG,
  EDIT_USER_INFO,
  CREATE_USER_ENROLLMENT_STATUS
} from '../../graphql/mutations';
import {
  GET_USERS,
  GET_USER_ACHIEVEMENTS,
  GET_MESSAGES_FOR_USER
} from '../../graphql/queries';
import {
  GetUsersByTeamParams,
  GetUserAchievementsParams,
  GetUserAchievementsResponse
} from '../../api-client/endpoints';
import { isNullOrUndefined } from '../../utils/helpers';
import { GET_USERS_BY_TEAM_ID } from '../../graphql/queries/Teams';

// number of simultaneous concurrent requests at a time
const USERS_PER_PAGE = 100;

interface UserFirstNameParams {
  user?: UserRecord;
  firstName?: string | null;
}

interface EditVacationInfo {
  vacationStartDate?: moment.Moment | null;
  vacationEndDate?: moment.Moment | null;
}

export interface EditContactParams {
  phoneNumber?: string | null;
  email?: string | null;
  addressOne?: string | null;
  city?: string | null;
  stateOfResidence?: string | null;
  postalCode?: string | null;
  contactMethod?: string | null;
  vacationInfo: EditVacationInfo;
}

interface UserContactInfoParams {
  userId: number;
  contactInfo: EditContactParams;
}

interface GetUsersParams {
  page: number;
  itemsPerPage?: number;
}

export interface UserTagAction extends PayloadAction<{ user: UserRecord; tag: string }> {
  type: typeof ADD_USER_TAG | typeof REMOVE_USER_TAG;
  payload: { user: UserRecord; tag: string };
}

export interface PutUserNameAction extends PayloadAction<{ editedUser: UserRecord }> {
  type: typeof PUT_USER_NAME;
  payload: { editedUser: UserRecord };
}

export interface PutUserContactInfoAction extends PayloadAction<{ editedUser: UserRecord }> {
  type: typeof PUT_USER_CONTACT_INFO;
  payload: { editedUser: UserRecord };
}

export interface UpdateOldUserMessageUuidsAction extends PayloadAction<{ user: UserRecord; messageUuids: string[] }> {
  type: typeof PREPEND_OLD_USER_MESSAGES;
}

export interface UpdateNewUserMessageUuidsAction extends PayloadAction<{ user: UserRecord; messageUuids: string[] }> {
  type: typeof APPEND_NEW_USER_MESSAGES;
}

export interface SetUserHasUnread extends PayloadAction<{ user: UserRecord; hasUnread: boolean }> {
  type: typeof SET_USER_HAS_UNREAD;
}

export interface MarkUserOnboarded extends PayloadAction<{ userId: number }> {
  type: typeof MARK_USER_ONBOARDED;
}

export interface ResetUserOnboarded extends PayloadAction<{}> {
  type: typeof RESET_USERS_ONBOARDED;
}

export interface UpdateUserEnrollmentStatus
  extends PayloadAction<{
    user: UserRecord;
    enrollmentStatus: [EnrollmentStatus];
  }> {
  type: typeof UPDATE_USER_ENROLLMENT_STATUS;
}

export type UserAction =
  | UserTagAction
  | UpdateGoalAction
  | PostGoalAction
  | DeleteGoalAction
  | PutUserNameAction
  | PutUserContactInfoAction
  | UpdateNewUserMessageUuidsAction
  | UpdateOldUserMessageUuidsAction
  | SetUserHasUnread
  | MarkUserOnboarded
  | ResetUserOnboarded
  | UpdateUserEnrollmentStatus;

export const actionCreators = {
  getUsers({ page, itemsPerPage }: GetUsersParams): ThunkAction<Promise<UserResponse>> {
    return (dispatch, getState) => {
      const api = getApiClient(getState());
      return api.users
        .getUsers(
          {
            query: GET_USERS,
            variables: { page, itemsPerPage: !isNullOrUndefined(itemsPerPage) ? itemsPerPage : USERS_PER_PAGE }
          },
          {}
        )
        .then(res => {
          dispatch({ payload: res, type: ENTITIES_FETCHED });
          return res;
        })
        .catch(e => {
          throw new Error(`${ERR_USERS_PARSE_FAIL}, error message: ${e.message}`);
        });
    };
  },
  getPathwayStats(id: number, start?: Date, end?: Date): ThunkAction<Promise<PathwayStatsResponse>> {
    return (dispatch, getState) => {
      const api = getApiClient(getState());
      return api.users.getPathwayStats({}, { id, start, end }).then(res => {
        dispatch({ payload: res, type: ENTITIES_FETCHED });
        return res;
      });
    };
  },
  addUserTag(tag: string, comment: string, userId: number): ThunkAction<Promise<{}>> {
    return (dispatch, getState) => {
      const api = getApiClient(getState());
      const user = selectUser(getState(), { userId });
      return api.users
        .addTag({
          query: ADD_TAG,
          variables: { tag, comment, userId }
        })
        .then(() =>
          dispatch<UserTagAction>({
            payload: { user, tag },
            type: ADD_USER_TAG
          })
        );
    };
  },
  removeUserTag(tag: string, comment: string, userId: number): ThunkAction<Promise<{}>> {
    return (dispatch, getState) => {
      const api = getApiClient(getState());
      const user = selectUser(getState(), { userId });
      return api.users
        .removeTag({
          query: DELETE_TAG,
          variables: { tag, comment, userId }
        })
        .then(() =>
          dispatch<UserTagAction>({
            payload: { user, tag },
            type: REMOVE_USER_TAG
          })
        );
    };
  },
  saveUsersToRedux(users: UserModel[]) {
    return (dispatch: Dispatch) => {
      const normalizedUsers = graphqlUsersParser(users);
      dispatch({ payload: normalizedUsers, type: ENTITIES_FETCHED });
    };
  },
  getUserMessagePreviewsByTask({
    taskUsers,
    adminUuid
  }: {
    taskUsers: number[];
    adminUuid: string;
  }): ThunkAction<Promise<boolean>> {
    return (dispatch: Dispatch, getState: Function) => {
      const state = getState();
      const api = getApiClient(state);
      const promises = taskUsers.map(taskUserId =>
        api.messagesV2.get({
          query: GET_MESSAGES_FOR_USER,
          variables: {
            userId: taskUserId,
            adminUuid,
            page: 0,
            itemsPerPage: 1
          }
        })
      );
      return Promise.all(promises)
        .then(taskUsersRes => {
          setUserMessagePreview(taskUsersRes, dispatch, getState);
          return true;
        })
        .catch(e => false);
    };
  },
  getUserMessagePreviewsByTeam({
    teamId,
    adminUuid,
    itemsPerPage = 1,
    page = 0
  }: GetUsersByTeamParams): ThunkAction<Promise<number[]>> {
    return (dispatch: Dispatch, getState: Function) => {
      const api = getApiClient(getState());
      return api.users
        .getUsersByTeam({
          query: GET_USERS_BY_TEAM_ID,
          variables: {
            teamId,
            adminUuid,
            itemsPerPage,
            page
          }
        })
        .then(res => {
          const teamUsers = res.users;
          const userIds = res.users.map((user: GetUserById) => +user.id);
          dispatch({
            type: UPDATE_TEAM_RESULTS,
            payload: { teamId, userIds }
          });
          setUserMessagePreview(teamUsers, dispatch, getState);
          return userIds;
        });
    };
  },
  updateUserEnrollmentStatus(
    userId: number,
    enrollmentStatus: Status
  ): ThunkAction<Promise<{ enrollmentStatus: EnrollmentStatus[] | null | undefined }>> {
    return (dispatch: Dispatch, getState: Function) => {
      const api = getApiClient(getState());
      const user = selectUser(getState(), { userId });
      return api.users
        .updateUserEnrollmentStatus({
          query: CREATE_USER_ENROLLMENT_STATUS,
          variables: {
            userUuid: user.uuid,
            status: enrollmentStatus
          }
        })
        .then(res => {
          // TODO ATL-673: This will be changed to no longer be an array
          const newEnrollmentStatus = [res.createStatus];
          dispatch({
            type: UPDATE_USER_ENROLLMENT_STATUS,
            payload: { user, enrollmentStatus: newEnrollmentStatus }
          });

          return { enrollmentStatus: newEnrollmentStatus };
        })
        .catch(e => {
          throw new Error(`${ERR_UPDATING_ENROLLMENT_STATUS}, error message: ${e.message}`);
        });
    };
  }
};

export const putUserFirstName = ({ user, firstName }: UserFirstNameParams): ThunkAction<Promise<void>> => {
  if (!user || !firstName) throw new Error(`${ERR_PUT_USER}, missing user and first name`);

  return (dispatch, getState) => {
    const state = getState();
    const api = getApiClient(state);

    const editedUser = user.set('firstName', firstName);

    return api.users
      .update({ query: EDIT_USER_INFO, variables: { user: { firstName, id: user.id } } })
      .then(() => {
        dispatch<PutUserNameAction>({ payload: { editedUser }, type: PUT_USER_NAME });
      })
      .catch(e => {
        throw new Error(`${ERR_PUT_USER}, user Id: ${editedUser.id}, error message: ${e.message}`);
      });
  };
};

export const putUserContactInfo = ({ userId, contactInfo }: UserContactInfoParams): ThunkAction<Promise<void>> => {
  if (!userId || !contactInfo) throw new Error(`${ERR_PUT_USER}, missing user and contact edit information`);

  return (dispatch, getState) => {
    const state = getState();
    const api = getApiClient(state);

    const editedUser = createUserRecord({ id: userId, ...contactInfo });

    return api.users
      .update({ query: EDIT_USER_INFO, variables: { user: { ...contactInfo, id: userId } } })
      .then(() => {
        dispatch<PutUserContactInfoAction>({ payload: { editedUser }, type: PUT_USER_CONTACT_INFO });
      })
      .catch(e => {
        throw new Error(`${ERR_PUT_USER}, user Id: ${editedUser.id}, error message: ${e.message}`);
      });
  };
};

export const getUserAchievements = ({
  userId
}: GetUserAchievementsParams): ThunkAction<Promise<GetUserAchievementsResponse>> => {
  if (!userId) throw new Error(`${ERR_PUT_USER}, missing userId`);
  return (_, getState) => {
    const api = getApiClient(getState());
    return api.users.getUserAchievements({ query: GET_USER_ACHIEVEMENTS, variables: { userId } }).then(res => res);
  };
};

const setUserMessagePreview = (users: GetUserById[], dispatch: Dispatch, getState: Function) => {
  // Saving users not stored in redux
  const usersToAdd = users
    .filter(user => {
      const storedUser = selectUser(getState(), { userId: +user.id });
      return !storedUser;
    })
    .map(user => ({ ...user, id: user.id.toString() } as UserModel));
  dispatch({
    type: ENTITIES_FETCHED,
    payload: graphqlUsersParser(usersToAdd)
  });
  users.forEach(user => {
    const messageData = user.messageData;
    const messagePreview = !!messageData && Array.isArray(messageData.messages) ? messageData.messages[0] : null;
    const storedUser = selectUser(getState(), { userId: +user.id });
    if (!messagePreview) return;

    if (!storedUser.messaging.messageUuids.includes(messagePreview.uuid)) {
      dispatch({
        type: APPEND_NEW_USER_MESSAGES,
        payload: { user: selectUser(getState(), { userId: +user.id }), messageUuids: [messagePreview?.uuid] }
      });
    }
    dispatch({
      type: MESSAGES_FETCHED,
      payload: { messages: { [messagePreview.uuid]: messagePreview } }
    });
    dispatch({
      type: SET_USER_HAS_UNREAD,
      payload: { user: selectUser(getState(), { userId: +user.id }), hasUnread: messageData?.hasUnread }
    });
  });
};

export const resetUsersOnboarded = (): ThunkAction<ResetUserOnboarded> => dispatch =>
  dispatch({ type: RESET_USERS_ONBOARDED, payload: {} });
