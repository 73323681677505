import jwt_decode from 'jwt-decode';

interface DecodedToken {
  exp: number;
  [key: string]: unknown;
}
/**
 * Returns remaining in seconds before the token expires.
 *
 * @param {string} token JWT token. When invalid format is passed, null will return.
 * @returns {number} of seconds remaining before the token expires.
 */
export const getRemainingTokenExpirationTime = (token: string): number | null => {
  const decodedToken = decodeToken(token);
  if (!decodedToken) {
    return null;
  }

  const expirationTime = decodedToken?.exp;
  const currTimeInSec = Math.floor(Date.now() / 1000);
  return expirationTime - currTimeInSec;
};

const decodeToken = (token: string ): DecodedToken | null => {
  try {
    return jwt_decode(token);
  } catch(err) {
    return null;
  }
};
