import * as React from 'react';
import {
  BarChart,
  XAxis,
  YAxis,
  Legend,
  Bar,
  ResponsiveContainer,
  LabelList,
  Tooltip
} from 'recharts';
import * as moment from 'moment-timezone';
import MultilineTick from '../../charts/MultilineTick';
import EngagementTooltip from './EngagementTooltip';
import { ORANGE, GREEN, BLUE, RED, PURPLE } from '../../../colors';
import { WeeklyEngagement, EngagementMetric } from '../../../graphql/models';
import { isNumber, formatToInt } from '../../../utils/number';
import { isNullOrUndefined } from 'util';
import { DEFAULT_TIMEZONE } from '../../../utils/user/timezone';
import { ENGAGEMENT_X_AXIS_RANGE_WEEKS, HANDLE_RESIZE_WAIT } from '../../../utils/chart';
import * as R from 'ramda';

export const BAR_PERCENTAGE_THRESHOLD = 100;
export const NUM_ENGAGEMENT_METRICS = 4;

interface EngagementChartProps {
  weeklyMetrics: WeeklyEngagement[];
  timezone?: string | null;
}

interface EngagementChartDatum {
  weekAndDate?: string;
  Playlist?: number | null;
  PlaylistCount?: number | null;
  PlaylistGoal?: number | null;
  ET?: number | null;
  ETCount?: number | null;
  ETGoal?: number | null;
  Edu?: number | null;
  EduCount?: number | null;
  EduGoal?: number | null;
  Logging?: number | null;
  LoggingCount?: number | null;
  LoggingGoal?: number | null;
  Inactive?: string;
}

export function selectWeeklyChartMetrics(
  props: EngagementChartProps
): WeeklyEngagement[] {
  const length = props.weeklyMetrics.length;

  const selectedMetrics =
    length >= ENGAGEMENT_X_AXIS_RANGE_WEEKS
      ? props.weeklyMetrics.slice(length - ENGAGEMENT_X_AXIS_RANGE_WEEKS)
      : props.weeklyMetrics;

  return selectedMetrics;
}

export function isMetricInactive(metric: EngagementMetric): boolean {
  return metric.count === 0;
}

export function findRangeStartDate(
  timezone: string,
  we?: WeeklyEngagement | null
): moment.Moment {
  const date = we && we.startDate ? we.startDate : undefined;
  return moment(date).tz(timezone);
}

export function findCompletionPercentage(
  count?: number | null,
  goal?: number | null
): number | null {
  if (isNumber(count) && isNumber(goal) && isNumber(count / goal)) {
    return count <= goal
      ? formatToInt(count / goal * BAR_PERCENTAGE_THRESHOLD)
      : BAR_PERCENTAGE_THRESHOLD;
  }
  return null;
}

export function selectWeek(we?: WeeklyEngagement | null): number {
  return we && we.pathwayWeek && isNumber(we.pathwayWeek)
    ? we.pathwayWeek
    : NaN;
}

export function hasCountAndGoal(
  metric?: EngagementMetric | null
): EngagementMetric {
  return metric && isNumber(metric.count) && isNumber(metric.weeklyGoal)
    ? metric
    : { count: 0, weeklyGoal: 0 };
}

export function generateChartData(
  props: EngagementChartProps
): EngagementChartDatum[] {
  const chartData = new Array<EngagementChartDatum>();
  const weeklyChartMetrics = selectWeeklyChartMetrics(props);
  const timezone = props.timezone || DEFAULT_TIMEZONE;
  const firstWeeklyMetric: WeeklyEngagement = weeklyChartMetrics[0];
  const weekStart = findRangeStartDate(timezone, firstWeeklyMetric);
  const lastWeeklyMetric = R.takeLast(1, weeklyChartMetrics);
  const currentWeekIndex = selectWeek(lastWeeklyMetric[0]);
  let inactiveWeeks = 0;
  let pathwayWeekNumber = firstWeeklyMetric?.pathwayWeek || 0;

  for (let i = 0; i < ENGAGEMENT_X_AXIS_RANGE_WEEKS; i++ , weekStart.add(1, 'weeks')) {
    const m = weeklyChartMetrics[i];
    const weekStartFormat = weekStart.format('M/D');
    let weekAndDate: string;

    if (isNullOrUndefined(m)) {
      weekAndDate = `W${pathwayWeekNumber}\n${weekStartFormat}`;
      chartData.push({
        Playlist: null,
        PlaylistCount: null,
        PlaylistGoal: null,
        ET: null,
        ETCount: null,
        ETGoal: null,
        Edu: null,
        EduCount: null,
        EduGoal: null,
        Logging: null,
        LoggingCount: null,
        LoggingGoal: null,
        Inactive: '',
        weekAndDate
      });
    } else {
      const pathwayWeek = isNumber(m.pathwayWeek) ? m.pathwayWeek : NaN;
      const playlists = hasCountAndGoal(m.playlists);
      const exerciseTherapySessions = hasCountAndGoal(m.exerciseTherapySessions);
      const articleReads = hasCountAndGoal(m.articleReads);
      const painReports = hasCountAndGoal(m.painReports);
      const surgerySurveySubmissions = hasCountAndGoal(
        m.surgerySurveySubmissions
      );
      const physicalActivities = hasCountAndGoal(m.physicalActivities);

      const loggingMetrics: EngagementMetric[] = [
        painReports,
        surgerySurveySubmissions,
        physicalActivities
      ];

      const engagementMetrics = [
        playlists,
        exerciseTherapySessions,
        articleReads
      ].concat(loggingMetrics);

      let loggingCount = 0;
      let loggingGoal = 0;

      loggingCount = loggingMetrics.reduce((acc, l) => l.count ? acc + l.count : acc, 0);

      loggingGoal = loggingMetrics.reduce((acc, l) => l.weeklyGoal ? acc + l.weeklyGoal : acc, 0);

      if (isNumber(m.weightGrams)) {
        loggingCount++;
        if (pathwayWeek !== 0) loggingGoal++;
      }

      if (
        engagementMetrics.every(isMetricInactive) &&
        !loggingCount &&
        pathwayWeek !== currentWeekIndex &&
        pathwayWeek >= 0
      ) {
        inactiveWeeks++;
      } else {
        inactiveWeeks = 0;
      }

      weekAndDate = isNumber(pathwayWeek)
        ? `W${pathwayWeek}\n${weekStartFormat}`
        : '';

      chartData.push({
        Playlist: findCompletionPercentage(playlists.count, playlists.weeklyGoal),
        PlaylistGoal: playlists.weeklyGoal,
        PlaylistCount: playlists.count,
        ET: findCompletionPercentage(
          exerciseTherapySessions.count,
          exerciseTherapySessions.weeklyGoal
        ),
        ETCount: exerciseTherapySessions.count,
        ETGoal: exerciseTherapySessions.weeklyGoal,
        Edu: findCompletionPercentage(
          articleReads.count,
          articleReads.weeklyGoal
        ),
        EduCount: articleReads.count,
        EduGoal: articleReads.weeklyGoal,
        Logging: findCompletionPercentage(loggingCount, loggingGoal),
        LoggingCount: loggingCount,
        LoggingGoal: loggingGoal,
        Inactive: inactiveWeeks === 0 ? '' : `W${inactiveWeeks}`,
        weekAndDate
      });
    }
    pathwayWeekNumber++;
  }
  return chartData;
}

const EngagementChart: React.FunctionComponent<EngagementChartProps> = props => {
  const MAX_HEIGHT = BAR_PERCENTAGE_THRESHOLD * NUM_ENGAGEMENT_METRICS;
  return (
    <ResponsiveContainer debounce={HANDLE_RESIZE_WAIT} width="100%" height={480}>
      <BarChart data={generateChartData(props)}>
        <XAxis
          dataKey="weekAndDate"
          type="category"
          interval={0}
          tick={<MultilineTick />}
          height={42}
        />
        <YAxis hide={true} domain={[0, MAX_HEIGHT]} />
        <Tooltip content={<EngagementTooltip />} />
        <Legend verticalAlign="top" align="right" />
        <Bar dataKey="Playlist" stackId="a" fill={BLUE} />
        <Bar dataKey="ET" stackId="a" fill={ORANGE} />
        <Bar isAnimationActive={false} dataKey="Edu" stackId="a" fill={GREEN}>
          <LabelList dataKey="Inactive" position="top" stroke={RED} />
        </Bar>
        <Bar dataKey="Logging" stackId="a" fill={PURPLE} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default EngagementChart;
