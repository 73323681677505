import * as React from 'react';
import * as moment from 'moment-timezone';
import styled from 'styled-components';
import { isNullOrUndefined } from 'util';
import { DEFAULT_TIMEZONE } from '../../utils/user/timezone';
import {
  achievementsConfig,
  isAchievementsConfigKey,
  getAchievementsByType,
  AchievementsByType
} from '../../utils/user/achievements';
import { Achievement } from '../../api-client/interfaces';
import { UserRecord } from '../../redux/reducers';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { WHITE } from '../../colors';

interface Props {
  user?: UserRecord;
  achievements?: Achievement[];
}

const listItemStyles = {
  flexFlow: 'column wrap',
  alignItems: 'start',
  paddingLeft: 20,
  paddingTop: 0
};

const StyledList = styled(List)`
  && {
    width: 100%;
    padding-top: 0;
    background-color: ${WHITE}
  }
`;

const StyledTypography = styled(Typography)`
  && {
    padding: 20px 0 20px 20px;
    font-size: 18px;
    margin: 0
  }
`;

const NoAchievementsAvailable = () => (
  <StyledList dense data-testid="no-achievements-available">
    <StyledTypography>Achievements</StyledTypography>
    <ListItem style={listItemStyles}>
      <ListItemText secondary='None' data-testid="no-achievements"/>
    </ListItem>
  </StyledList>
);

const AchievementsSectionList = ({
  sectionTitle,
  achievements,
  timezone
}: {
  sectionTitle: string;
  achievements: Achievement[];
  timezone: string;
}) => {
  if (achievements.length === 0) {
    return null;
  }

  const achievementsList = achievements.map(achievement => {
    if(!isAchievementsConfigKey(achievement.key)) {
      return <div key={achievement.key}>
        <ListItemText secondary='None' data-testid="no-achievements"/>
      </div>;
    }
    const config = achievementsConfig[achievement.key];
    const achievedDate = moment(achievement.achievedDate).tz(timezone).format('MMM D, YYYY');
    return (
      <div key={achievement.key}>
        <ListItemText primary={config.title} data-testid="achievement-info"/>
        <ListItemText secondary={achievedDate} data-testid="achievement-date"/>
      </div>
    );
  });

  return (
    <ListItem style={listItemStyles}>
      <ListItemText secondary={sectionTitle} data-testid="achievement-section-title"/>
      {achievementsList}
    </ListItem>
  );
};

const AllAchievementsContext: React.FC<Props> = ({
  user,
  achievements
}) => {
  if (!user) {
    return null;
  }

  const timezone = user.get('timezone') || DEFAULT_TIMEZONE;

  if (isNullOrUndefined(achievements) || achievements.length === 0) {
    return <NoAchievementsAvailable />;
  }

  const achievementsByType: AchievementsByType = getAchievementsByType(achievements);

  return (
    <StyledList dense data-testid="achievements-list">
      <StyledTypography>Achievements</StyledTypography>
      <AchievementsSectionList
        sectionTitle="Activity streak"
        achievements={achievementsByType.activityStreak}
        timezone={timezone}
      />
      <AchievementsSectionList
        sectionTitle="3x playlist streak"
        achievements={achievementsByType.threePlaylistStreak}
        timezone={timezone}
      />
      <AchievementsSectionList
        sectionTitle="Exercise reps"
        achievements={achievementsByType.exerciseReps}
        timezone={timezone}
      />
      <AchievementsSectionList
        sectionTitle="Celebrations"
        achievements={achievementsByType.celebrations}
        timezone={timezone}
      />
    </StyledList>
  );
};

export default AllAchievementsContext;
