import * as React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import ModalWrapper from '../../modal/ModalWrapper';
import { enrollmentStatusText } from '../../../utils/user/enrollmentStatus';
import { EnrollmentStatus, Status } from '../../../graphql/models/bff';

export const StyledTypography = styled(Typography)`
  && {
    margin-top: 18px;
  }
`;

export interface Props {
  modalShown: boolean;
  toggleModalShown: (show: boolean) => unknown;
  enrollmentStatus?: EnrollmentStatus | null;
  changeEnrollmentStatus: (status: Status) => unknown;
}

const EnrollmentStatusModal: React.FC<Props> = ({
  modalShown,
  toggleModalShown,
  enrollmentStatus,
  changeEnrollmentStatus
}) => {

  const status = enrollmentStatus?.status ?? Status.OPT_IN;

  const handleEnrollmentModalSubmit = () => {
    toggleModalShown(false);

    changeEnrollmentStatus(status);
  };

  const content = {
    title: enrollmentStatusText(status).title,
    body: (
      <>
        <Typography variant='body2'>{enrollmentStatusText(status).content}</Typography>
        <StyledTypography variant='body2'>{enrollmentStatusText(status).strikeThroughWarning}</StyledTypography>
      </>
    ),
    confirmBtnText: 'Confirm',
    dismissBtnText: 'Cancel'
  };

  return (
    <ModalWrapper
      modalContent={content}
      onConfirm={handleEnrollmentModalSubmit}
      onDismiss={() => toggleModalShown(false)}
      openModal={modalShown}
    />
  );
};

export default EnrollmentStatusModal;
