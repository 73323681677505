import { TypedRecord, makeTypedFactory } from 'typed-immutable-record';
import { Map } from 'immutable';

import { MessageV2 } from '../../api-client';
import { RootAction } from '../actions';
import { MessageStatusV2, MessagePartyType } from '../../api-client/interfaces';
import * as C from '../constants';

import {
  mergeUpdate,
  createStringEntityMap
} from '../../utils/entity';

export interface MessageV2Record extends TypedRecord<MessageV2Record>, MessageV2 {}

export type State = Map<string, MessageV2Record>;

export const initialState: State = Map();

export const createMessageV2Record = makeTypedFactory<MessageV2, MessageV2Record>({
  uuid: '',
  type: '',
  status: MessageStatusV2.Pending,
  body: '',
  isUnread: false,
  sender: { uuid: '', type: MessagePartyType.User },
  recipient: { uuid: '', type: MessagePartyType.Admin },
  sendAt: '',
  transmittedAt: '',
  createdAt: ''
});

export const reducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case C.MESSAGES_FETCHED:
      return action.payload.messages
        ? mergeUpdate(
          state,
          createStringEntityMap(
            createMessageV2Record,
            action.payload.messages
          )
        )
        : state;
    case C.DELETE_SCHEDULED_MESSAGE_V2:
      return action.payload.messageUuid
        ? state.delete(action.payload.messageUuid)
        : state;
    case C.UPDATE_MESSAGE: {
      const { uuid } = action.payload.updatedMessage;

      return state.update(uuid, (record) => record.merge(action.payload.updatedMessage));
    }
    case C.CREATE_MESSAGE: {
      const { uuid } = action.payload.newMessage;
      return state.set(uuid, createMessageV2Record(action.payload.newMessage));
    }
    default:
      return state;
  }
};
