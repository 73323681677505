import { calculateWeeksBetween } from '../../../utils/datetime';

export function formatEnsoProgress(
  totalProgramWeeks?: number,
  ensoStartsAtDate?: string
) {
  if (!ensoStartsAtDate || !totalProgramWeeks) return '';
  const progress = calculateWeeksBetween(ensoStartsAtDate);

  const message = `${progress} of ${totalProgramWeeks} weeks of Enso coaching.`;

  return message;
}
