import { gql } from '@apollo/client';

export const SET_USER_STATUS = gql`
  mutation SetUserStatus(
    $userId: ID!
    $name: String!
    $description: String
    $startsOn: String!
    $endsOn: String!
  ) {
    setUserStatus(
      userId: $userId
      name: $name
      description: $description
      startsOn: $startsOn
      endsOn: $endsOn
    ) {
      userStatus {
        userId
        name
        description
        startsOn
        endsOn
      }
    }
  }
`;

export const DELETE_USER_STATUS = gql`
  mutation DeleteUserStatus($userId: ID!) {
    deleteUserStatus(userId: $userId) {
      user {
        id
      }
      userStatus {
        userId
        name
        description
        startsOn
        endsOn
      }
    }
  }
`;
