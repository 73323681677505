import { useQuery } from '@apollo/client';
import * as React from 'react';
import { User } from '../../../graphql/models';
import { Text } from 'grommet';
import {
  GET_USER_ET_SESSION_CONTEXT,
  GET_USER_WORKOUT_CONTEXT
} from '../../../graphql/queries/User';
import { getUserActivePathway } from '../../../utils/user/getUserActivePathway';

interface EtSessionContextContainerProps {
  id: string;
  phoenix?: boolean;
}

interface EtSessionContextResponse {
  user?: User | null;
}

const TasksTableEt: React.FC<EtSessionContextContainerProps> = ({
  id, phoenix
}) => {
  const query = phoenix ? GET_USER_ET_SESSION_CONTEXT : GET_USER_WORKOUT_CONTEXT;
  const { data } = useQuery<EtSessionContextResponse, { id: string }>(query, { variables: { id }});

  if (data && data.user) {
    const activePathway = getUserActivePathway(data.user);

    if (!activePathway) return <div>N/A</div>;

    return activePathway.etSessionsContext ?
      <Text size="small">{activePathway.etSessionsContext?.currentWeek.length}</Text>
      : <Text size="small">{activePathway.legacyWorkoutContext?.currentWeek.length}</Text>;
  }
  return <div>N/A</div>;
};

export default TasksTableEt;
