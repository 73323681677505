import { TypedRecord, makeTypedFactory } from 'typed-immutable-record';
import { Map } from 'immutable';

import { WeeklyMetric } from '../../api-client';
import { RootAction } from '../actions';
import { ENTITIES_FETCHED } from '../constants';
import { createStringEntityMap, mergeUpdate } from '../../utils/entity';

export interface WeeklyMetricRecord
  extends TypedRecord<WeeklyMetricRecord>,
  WeeklyMetric {}

export const createWeeklyMetricRecord = makeTypedFactory<WeeklyMetric, WeeklyMetricRecord>({
  active: undefined,
  aerobic_workouts_goal: undefined,
  aerobic_workouts: undefined,
  begin_at: undefined,
  education_reads_goal: undefined,
  education_reads: undefined,
  exercises_goal: undefined,
  exercises: undefined,
  pain_log: undefined,
  points_earned: undefined,
  points_goal: undefined,
  week_index: undefined
});

export type State = Map<string, WeeklyMetricRecord>;

export const initialState: State = Map();

export const reducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case ENTITIES_FETCHED:
      return action.payload.entities.weeklyMetrics
        ? mergeUpdate(
          state,
          createStringEntityMap(
            createWeeklyMetricRecord,
            action.payload.entities.weeklyMetrics
          )
        )
        : state;
    default:
      return state;
  }
};
