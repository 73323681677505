import { VideoCall } from '../../graphql/models/VideoCall';

const CREATED_AT_HOUR_BUFFER = 1;

function isVideoCallRecentAndUnfinished(videoCall: VideoCall) {
  if(!videoCall.createdAt) {
    return false;
  }
  const createdAtDate = new Date(videoCall.createdAt);
  let bufferCreatedAtDate = new Date();
  bufferCreatedAtDate = new Date(bufferCreatedAtDate.setHours(bufferCreatedAtDate.getHours() - CREATED_AT_HOUR_BUFFER));
  return !videoCall.endedAt &&  createdAtDate.valueOf() >= bufferCreatedAtDate.valueOf();
}

function sortVideoCallByMostRecent(prev: VideoCall, next: VideoCall) {
  if(!prev.createdAt || !next.createdAt) {
    return -1;
  }
  return Date.parse(next.createdAt).valueOf() - Date.parse(prev.createdAt).valueOf();
}

export function getMostRecentUnfinishedVideoCall(videoCalls: VideoCall[]): VideoCall | undefined {
  return videoCalls.filter(isVideoCallRecentAndUnfinished).sort(sortVideoCallByMostRecent)[0];
};
