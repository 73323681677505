import * as React from 'react';
import styled from 'styled-components';
import {
  TextField,
  FormControl,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
import { CarePlanStatus } from '../../api-client/interfaces';

interface Props {
  carePlanName: string;
  onStatusChange(event: React.ChangeEvent<{ value: string }>): void;
  status: string;
  onCarePlanNameChange(event: React.ChangeEvent<{ value: string }>): void;
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledTextField = styled(TextField)`
  width: 200px;
`;

const StyledPlanStatusContainer = styled.div`
  padding-top: 16px;
`;

const CreateCarePlanModalContent: React.FC<Props> = ({
  carePlanName,
  onCarePlanNameChange,
  status,
  onStatusChange
}) => (
  <StyledDiv>
    <StyledTextField
      label='Care plan name'
      value={carePlanName}
      onChange={onCarePlanNameChange}
    />
    <StyledPlanStatusContainer>
      <FormControl>
        <Typography variant='caption' color='textSecondary'>Plan status</Typography>
        <RadioGroup name='plan status' value={status} onChange={onStatusChange}>
          <FormControlLabel
            value={CarePlanStatus.Open}
            label='Open'
            control={<Radio color='default' />}
          />
          <FormControlLabel
            value={CarePlanStatus.Closed}
            label='Closed'
            control={<Radio color='default' />}
          />
        </RadioGroup>
      </FormControl>
    </StyledPlanStatusContainer>
  </StyledDiv>
);

export default CreateCarePlanModalContent;
