import { MessageV2Record, RootState, UserRecord } from '../reducers';
import { selectUser } from './user';
import { createSelector } from 'reselect';
import { UserMessagePreview } from '../../api-client';

export const selectMessagesV2State = (state: RootState) => state.messagesV2;

export const selectMessageV2 = (
  state: RootState,
  props: { messageUuid: string }
) => state.messagesV2.get(props.messageUuid);

export const selectUserMessagesTotalPages = (
  state: RootState,
  { userId }: { userId: number }
) => selectUser(state, { userId }).messaging.totalPages;

export const selectUserMessagesPage = (
  state: RootState,
  { userId }: { userId: number }
) => selectUser(state, { userId }).messaging.page;

export const selectUserMessages = createSelector([selectMessagesV2State, selectUser], (messages, user) =>
  user.messaging.messageUuids.map(uuid => messages.get(uuid))
);

export interface MessageThreadPreview {
  user: UserRecord;
  message: UserMessagePreview;
  hasUnreadMessages: boolean;
};

export const selectUserMessagePreview = (state: RootState, { userId }: { userId: number }): MessageV2Record | null => {
  const user = selectUser(state, { userId });
  if (!user) return null;
  const { messageUuids } = user.messaging;
  return selectMessageV2(state, {
    messageUuid: messageUuids[messageUuids.length - 1]
  });
};

export function selectMessageThreadPreviews(
  state: RootState,
  { userIds }: { userIds: number[] }): MessageThreadPreview[] {

  return userIds.map((userId) => {
    const user = selectUser(state, {userId});
    const { messageUuids } = user.messaging;
    return {
      user,
      message: selectMessageV2(state,{messageUuid: messageUuids[messageUuids.length - 1]}),
      hasUnreadMessages: user.messaging.hasUnread || false
    };
  });
};
