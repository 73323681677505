import { useQuery } from '@apollo/client';
import * as React from 'react';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import { GET_USER_WEEKLY_ENGAGEMENT } from '../../../graphql/queries';
import EngagementChart from '../../../components/phx/charts/EngagementChart';
import EngagementTable from '../../../components/phx/charts/EngagementTable';
import { User } from '../../../graphql/models/User';
import { WeeklyEngagement } from '../../../graphql/models/Engagement';

const EMPTY_METRICS: WeeklyEngagement[] = [];
const EMPTY_TAGS: string[] = [];

interface Response {
  user: User;
}

interface EngagementStatsContainerProps {
  id: number;
}

const EngagementStatsContainer: React.FC<EngagementStatsContainerProps> = ({
  id
}) => {
  /* This query to return 2 additional weeks of data
   at the beginning of the specified timeframe in order to capture
   Weeks -2 and -1 for members in Weeks 0-10 */
  const { loading, data } = useQuery<Response, { id: string }>(
    GET_USER_WEEKLY_ENGAGEMENT,
    { variables: { id: id.toString() } }
  );

  return (
    <Segment>
      {loading && (
        <Dimmer.Dimmable dimmed>
          <Loader>Loading...</Loader>
        </Dimmer.Dimmable>
      )}
      {data && data.user && (
        <>
          <EngagementChart
            weeklyMetrics={data.user.weeklyEngagement || EMPTY_METRICS}
            timezone={data.user.timezone}
          />
          <EngagementTable
            weeklyMetrics={data.user.weeklyEngagement || EMPTY_METRICS}
            tags={data.user.tags || EMPTY_TAGS}
          />
        </>
      )}
    </Segment>
  );
};

export default EngagementStatsContainer;
