import { useMutation } from '@apollo/client';
import * as React from 'react';
import { Layer, Box, Heading, Text, Button } from 'grommet';
import { BACKGROUND_GRAY } from '../../colors';
import { DELETE_USER_STATUS } from '../../graphql/mutations';
import { GET_USER_STATUS } from '../../graphql/queries';
import { User, UserStatus } from '../../graphql/models';
import { mixpanelTrack } from '../../mixpanel/mixpanel';

interface Props {
  userId: number;
  showDeleteStatusModal(bool: boolean): void;
}

interface CachedQueryResponse {
  user: User;
}

interface CachedQueryVariables {
  id: string;
}

interface SetUserMutationData {
  user: User;
  userStatus: UserStatus;
}

interface MutationResponse {
  deleteUserStatus: SetUserMutationData;
}

interface Variables {
  userId: string;
}

const DeleteStatusModal: React.FC<Props> = ({
  userId,
  showDeleteStatusModal
}) => {
  const [deleteUserStatus, { error }] = useMutation<MutationResponse, Variables>(
    DELETE_USER_STATUS,
    {
      variables: { userId: userId.toString() },
      update(cache, { data }) {
        if (data?.deleteUserStatus) {
          const cachedData = cache.readQuery<CachedQueryResponse, CachedQueryVariables>({
            query: GET_USER_STATUS,
            variables: { id: userId.toString() }
          });

          if (cachedData) {
            const cachedUser = cachedData.user;

            cache.writeQuery({
              query: GET_USER_STATUS,
              variables: { id: userId.toString() },
              // TODO: CT-1816 update delete mutation to return nil user status response
              data: { user: { ...cachedUser, userStatus: null } }
            });
          }
        }
      }
    }
  );
  const dismissModal = () => showDeleteStatusModal(false);
  const handleRemoveStatus = () => {
    deleteUserStatus().then(() => {
      mixpanelTrack('User status deleted', { Origin: '/messaging' });

      showDeleteStatusModal(false);
    }).catch(() => {
      throw new Error('Unable to remove status');
    });
  };

  if (error) throw new Error('Unable to remove status');

  return (
    <Layer modal onEsc={dismissModal} onClickOutside={dismissModal}>
      <Box pad="medium">
        <Heading level={4}>Remove user status</Heading>
        <Box pad="small">
          <Text size="small">Are you sure you want to remove this status?</Text>
        </Box>
      </Box>
      <Box pad="small" direction="row" alignContent="center" justify="end">
        <Button
          style={{ fontSize: '12px', marginRight: '15px' }}
          type="button"
          color={BACKGROUND_GRAY}
          label="CANCEL"
          onClick={dismissModal}
        />
        <Button
          id="remove-status-button"
          style={{ fontSize: '12px' }}
          primary
          color="status-critical"
          type="submit"
          label="REMOVE"
          onClick={handleRemoveStatus}
        />
      </Box>
    </Layer>
  );
};

export default DeleteStatusModal;
