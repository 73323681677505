import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { WeeklyEngagement, EngagementMetric } from '../../../graphql/models/';
import { isNullOrUndefined } from 'util';
import { isNumber, convertGramsToLbs } from '../../../utils/number';

const NUM_COLUMNS_ALL = 10;
const NUM_COLUMNS_NO_OVERWEIGHT = 9;

interface EngagementTableProps {
  weeklyMetrics: WeeklyEngagement[];
  tags?: string[] | null;
}

const EngagementCell: React.FunctionComponent<{ metric: EngagementMetric }> = ({
  metric
}) => (
  <Table.Cell positive={isPositive(metric)} negative={isNegative(metric)}>
    {formattedResult(metric)}
  </Table.Cell>
);

export function createEngagementCell(metric?: EngagementMetric | null) {
  return isNullOrUndefined(metric) ? (
    <Table.Cell>{'-'}</Table.Cell>
  ) : (
    <EngagementCell metric={metric} />
  );
}

function isPositive(metric: EngagementMetric): boolean {
  return (
    !isNullOrUndefined(metric.count) &&
    !isNullOrUndefined(metric.weeklyGoal) &&
    metric.count >= metric.weeklyGoal &&
    metric.weeklyGoal > 0
  );
}

function isNegative(metric: EngagementMetric): boolean {
  return (
    !isNullOrUndefined(metric.count) &&
    !isNullOrUndefined(metric.weeklyGoal) &&
    metric.count === 0 &&
    metric.weeklyGoal > 0
  );
}

function formattedResult(metric: EngagementMetric): string {
  return `${metric.count}/${metric.weeklyGoal}`;
}

export function isTaggedOverweight(tags?: string[] | null): boolean {
  return !isNullOrUndefined(tags) && tags.indexOf('overweight') >= 0;
}

const EngagementTable: React.FunctionComponent<EngagementTableProps> = ({
  weeklyMetrics,
  tags
}) => (
  <Table celled columns={isTaggedOverweight(tags) ? NUM_COLUMNS_ALL : NUM_COLUMNS_NO_OVERWEIGHT}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Week</Table.HeaderCell>
        <Table.HeaderCell>Weekly Points</Table.HeaderCell>
        <Table.HeaderCell>Playlist Count</Table.HeaderCell>
        <Table.HeaderCell>ET Count</Table.HeaderCell>
        <Table.HeaderCell>Pacing</Table.HeaderCell>
        <Table.HeaderCell>Edu Count</Table.HeaderCell>
        <Table.HeaderCell>Pain Log</Table.HeaderCell>
        <Table.HeaderCell>Physical Activity</Table.HeaderCell>
        {isTaggedOverweight(tags) && (
          <Table.HeaderCell>Weight (Lbs) Log</Table.HeaderCell>
        )}
        <Table.HeaderCell>Health Surveys</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {weeklyMetrics
        .slice()
        .reverse()
        .map((m, i) => (
          <Table.Row key={i}>
            <Table.Cell>{`Week ${
              isNumber(m.pathwayWeek) ? m.pathwayWeek : ''
            }`}</Table.Cell>
            <Table.Cell>{m.pointsEarned || '-'}</Table.Cell>
            {createEngagementCell(m.playlists)}
            {createEngagementCell(m.exerciseTherapySessions)}
            {createEngagementCell(m.pacing)}
            {createEngagementCell(m.articleReads)}
            {createEngagementCell(m.painReports)}
            {createEngagementCell(m.physicalActivities)}
            {isTaggedOverweight(tags) && (
              <Table.Cell>{`${
                isNumber(m.weightGrams) ? convertGramsToLbs(m.weightGrams) : '-'
              }`}</Table.Cell>
            )}
            {createEngagementCell(m.surgerySurveySubmissions)}
          </Table.Row>
        ))}
    </Table.Body>
  </Table>
);

export default EngagementTable;
