import * as React from 'react';
import * as moment from 'moment-timezone';
import TableWrapper, { HeadCell, BodyRow, BodyColumn } from './TableWrapper';
import styled from 'styled-components';
import {Container, Typography, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/reducers';
import {useQuery, gql, NetworkStatus} from '@apollo/client';
import { selectAdmin } from '../redux/selectors';
import { GET_ADMIN_CARE_PLANS, GET_ADMIN } from '../graphql/queries';
import { CarePlan, CarePlanStatus, Admin } from '../api-client/interfaces';
import { ApiClientNames } from './apollo/ApolloContainer';
import { isPtRole } from '../utils/admin/roles';
import NotFoundPage from '../404';
import DataMessage from '../components/errors/DataMessage';
import {
  CarePlanStatusChip,
  RegulationColumn,
  RegulationColumnProps,
  processCarePlanItems,
  CarePlanActionsMenu,
  FinalizedRuleValues,
  visitCountWarning,
  parseVisitCount
} from '../components/user/CarePlan';
import UpdateCarePlanContainer from '../containers/user/UpdateCarePlanContainer';
import { UpdateCarePlanFields } from '../containers/user/CarePlansContainer';
import AdminCarePlansUserDisplay from './AdminCarePlansUserDisplay';

export const CARE_PLANS_PER_PAGE = 20;

export const emptyDataMessage = 'No care plans to display';

interface AdminCarePlansResponse {
  admin: {
    carePlanData: {
      carePlans: CarePlan[];
      hasNextPage: boolean;
    };
  };
}

export enum CarePlanRowId {
  Name = 'name',
  Title = 'title',
  Status = 'status',
  OpenedAt = 'openedAt',
  Duration = 'duration',
  VisitCount = 'videoVisit',
  InformHcp = 'informHcp',
  Actions = 'actions'
}

interface CarePlanColumn extends BodyColumn {
  status?: CarePlanStatus;
}

interface CarePlanRow {
  id: string;
  columns: CarePlanColumn[];
}

const sortByColumnType = (columnType: CarePlanRowId) => (a: CarePlanRow, b: CarePlanRow) => {
  const aColumn = a.columns.find(col => columnType === col.id);
  const bColumn = b.columns.find(col => columnType === col.id);

  if (!aColumn || !aColumn.sortValue) return 1;
  if (!bColumn || !bColumn.sortValue) return -1;

  switch (columnType) {
    case CarePlanRowId.Duration:
      if (bColumn.sortValue === FinalizedRuleValues.Na) return 1;
      if (aColumn.sortValue === FinalizedRuleValues.Na) return -1;
      break;
    case CarePlanRowId.VisitCount:
      const [aVisitCount, aVisitLimit] = parseVisitCount(aColumn.sortValue as string);
      const aWarnings = visitCountWarning(aColumn.sortValue as string);
      const aVisitsLeft = aVisitLimit - aVisitCount;

      const [bVisitCount, bVisitLimit] = parseVisitCount(bColumn.sortValue as string);
      const bWarnings = visitCountWarning(bColumn.sortValue as string);
      const bVisitsLeft = bVisitLimit - bVisitCount;

      if (aColumn.status === CarePlanStatus.Closed && bColumn.status === CarePlanStatus.Closed) {
        return aVisitsLeft - bVisitsLeft;
      }
      if (bColumn.status === CarePlanStatus.Closed) return -1;
      if (aColumn.status === CarePlanStatus.Closed) return 1;

      if (aWarnings?.warningTwo && bWarnings?.warningTwo) {
        return aVisitLimit - bVisitLimit;
      }
      if (bWarnings?.warningTwo) return 1;
      if (aWarnings?.warningTwo) return -1;

      if (aWarnings?.warningOne && bWarnings?.warningOne) {
        return aVisitLimit - bVisitLimit;
      }
      if (bWarnings?.warningOne) return 1;
      if (aWarnings?.warningOne) return -1;

      if (aVisitsLeft === bVisitsLeft) {
        return aVisitLimit - bVisitLimit;
      }

      return aVisitsLeft - bVisitsLeft;

    case CarePlanRowId.InformHcp:
      if (bColumn.sortValue === FinalizedRuleValues.Na) return 1;
      if (aColumn.sortValue === FinalizedRuleValues.Na) return -1;

      if (aColumn.sortValue === FinalizedRuleValues.Done && typeof bColumn.sortValue === 'number') {
        return -1;
      }

      if (bColumn.sortValue === FinalizedRuleValues.Done && typeof aColumn.sortValue === 'number') {
        return 1;
      }
      break;
    default:
      break;
  }

  if (aColumn.sortValue < bColumn.sortValue) {
    return 1;
  }

  if (aColumn.sortValue > bColumn.sortValue) {
    return -1;
  }

  return 0;
};

const headers: HeadCell[] = [
  {
    id: CarePlanRowId.Name,
    label: 'Name'
  },
  {
    id: CarePlanRowId.Title,
    label: 'Plan name'
  },
  {
    id: CarePlanRowId.Status,
    label: 'Status'
  },
  {
    id: CarePlanRowId.OpenedAt,
    label: 'Date opened',
    sortable: true
  },
  {
    id: CarePlanRowId.Duration,
    label: 'DA limit (days)',
    sortable: true,
    sortFn: sortByColumnType(CarePlanRowId.Duration),
    numeric: true
  },
  {
    id: CarePlanRowId.VisitCount,
    label: 'Visit count',
    sortable: true,
    sortFn: sortByColumnType(CarePlanRowId.VisitCount),
    numeric: true
  },
  {
    id: CarePlanRowId.InformHcp,
    label: 'Inform HCP (days)',
    sortable: true,
    sortFn: sortByColumnType(CarePlanRowId.InformHcp),
    numeric: true
  },
  {
    id: CarePlanRowId.Actions,
    label: 'Actions'
  }
];

const StyledHeader = styled(Typography)`
  padding: 24px;
`;

const StyledTableContainer = styled.div`
  padding: 24px 24px 46px 24px;
`;

const createBodyColumn = (
  id: string,
  label: string | number | JSX.Element,
  sortValue?: string | number | Date,
  carePlanStatus?: CarePlanStatus,
  columnProps?: { numeric?: boolean; disablePadding?: boolean }
): CarePlanColumn => ({
  id,
  label,
  sortValue,
  numeric: columnProps?.numeric,
  disablePadding: columnProps?.disablePadding,
  status: carePlanStatus
});

const RegulationCell: React.FC<RegulationColumnProps> = props => (
  <Grid container justify='flex-end'>
    <Grid xs={4}>
      <RegulationColumn {...props} />
    </Grid>
  </Grid>
);

const AdminCarePlansContainer: React.FC = () => {
  const [openUpdateCarePlan, setOpenUpdateCarePlan] = React.useState<boolean>(false);
  const [updateCarePlanFields, setUpdateCarePlanFields] = React.useState<UpdateCarePlanFields | null>(null);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [isFirstFetch, setIsFirstFetch] = React.useState<boolean>(true);
  const adminId = useSelector((state: RootState) => selectAdmin(state).id);
  const adminRes = useQuery<{ admin: Admin }, { id: number }>(
    gql`
      ${GET_ADMIN}
    `,
    {
      variables: { id: adminId },
      context: { clientName: ApiClientNames.NestBFF }
    }
  );
  const uuid = adminRes.data?.admin.uuid || '';

  const selectAndOpenUpdateCarePlan = (event: React.MouseEvent<{}>, fields: UpdateCarePlanFields): void => {
    event.stopPropagation();
    setOpenUpdateCarePlan(true);
    setUpdateCarePlanFields(fields);
  };

  const handleOpenUpdateCarePlan = (): void => setOpenUpdateCarePlan(true);

  const handleCloseUpdateCarePlan = () => setOpenUpdateCarePlan(false);

  const createCarePlanRows = (plans: CarePlan[]): BodyRow[] =>
    plans.map(plan => {
      const { id, title, status, items, openedAt, userId } = plan;
      const processedItems = processCarePlanItems(items, status);

      const { duration, videoVisit, informHcp } = processedItems;

      const carePlanActionFields = { id, status, items, userId };

      return {
        id,
        columns: [
          createBodyColumn(CarePlanRowId.Name, <AdminCarePlansUserDisplay userUuid={userId} />),
          createBodyColumn(CarePlanRowId.Title, <Typography variant='body2'>{title}</Typography>, title),
          createBodyColumn(CarePlanRowId.Status, <CarePlanStatusChip status={status} />, status),
          createBodyColumn(
            CarePlanRowId.OpenedAt,
            <Typography variant='body2'>{moment(openedAt).format('L')}</Typography>,
            openedAt
          ),
          createBodyColumn(CarePlanRowId.Duration, <RegulationCell {...duration} />, duration.value),
          createBodyColumn(CarePlanRowId.VisitCount, <RegulationCell {...videoVisit} />, videoVisit.value, status),
          createBodyColumn(CarePlanRowId.InformHcp, <RegulationCell {...informHcp} />, informHcp.value),
          createBodyColumn(
            CarePlanRowId.Actions,
            <CarePlanActionsMenu moreAction={selectAndOpenUpdateCarePlan} fields={carePlanActionFields} />
          )
        ]
      };
    });

  const { error, data, loading, fetchMore, networkStatus } = useQuery<
  AdminCarePlansResponse,
  {
    itemsPerPage: number;
    page: number;
  }
  >(GET_ADMIN_CARE_PLANS, {
    variables: { itemsPerPage: CARE_PLANS_PER_PAGE, page: 0 },
    context: { clientName: ApiClientNames.NestBFF },
    fetchPolicy: 'cache-and-network',
    displayName: 'GET_ADMIN_CARE_PLANS',
    notifyOnNetworkStatusChange: true
  });

  const { hasNextPage, carePlans: adminCarePlans } = data?.admin.carePlanData || {};
  const fetchMoreLoading = !isFirstFetch && (loading || networkStatus === NetworkStatus.fetchMore);

  const loadMore = () => {
    if(!hasNextPage || error || loading) return;
    setIsFirstFetch(false);
    const nextPageNumber = currentPage + 1;
    return fetchMore({
      variables: { itemsPerPage: CARE_PLANS_PER_PAGE, page: nextPageNumber },
      updateQuery: (prev, {fetchMoreResult}) => {
        setCurrentPage(nextPageNumber);
        if (!fetchMoreResult || !fetchMoreResult.admin.carePlanData.carePlans.length) {
          return prev;
        }
        return {
          admin: {
            __typename:'Admin',
            carePlanData: {
              __typename: 'CarePlanData',
              hasNextPage: fetchMoreResult.admin.carePlanData.hasNextPage,
              carePlans: Array.from(new Set(
                [...fetchMoreResult.admin.carePlanData.carePlans, ...prev.admin.carePlanData.carePlans]
              ))
            }
          }
        };
      }
    });
  };

  return adminRes?.data?.admin?.role && !isPtRole(adminRes.data.admin.role) ? (
    <NotFoundPage />
  ) : (
    <Container maxWidth='xl'>
      <StyledHeader variant='h4'>Care plans</StyledHeader>
      <StyledTableContainer>
        {adminCarePlans && adminCarePlans.length ? (
          <TableWrapper
            hasNextPage={hasNextPage}
            loading={fetchMoreLoading}
            loadMore={loadMore}
            headers={headers}
            rows={createCarePlanRows(adminCarePlans)}
            defaultOrderBy={CarePlanRowId.Duration}
            defaultOrder={'asc'}
          />
        ) : (
          <DataMessage message={emptyDataMessage} />
        )}
        {updateCarePlanFields && (
          <UpdateCarePlanContainer
            adminUuid={uuid}
            openUpdateCarePlan={openUpdateCarePlan}
            handleCloseUpdateCarePlan={handleCloseUpdateCarePlan}
            handleOpenUpdateCarePlan={handleOpenUpdateCarePlan}
            updateCarePlanFields={updateCarePlanFields}
          />
        )}
      </StyledTableContainer>
    </Container>
  );
};

export default AdminCarePlansContainer;
