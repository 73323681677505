import { Map } from 'immutable';
import { TypedRecord, recordify } from 'typed-immutable-record';

import * as C from '../constants';
import { GoalRecord, createGoalRecord } from './goals';
import { RootAction } from '../actions';
import { Goal } from '../../api-client/interfaces';

type EditingGoal = Map<string, GoalRecord>;

interface EditGoalProp<T extends keyof Goal> {
  key: T;
  value: Goal[T];
}

interface GoalEditorState {
  goals: EditingGoal;
  newGoal: GoalRecord;
}
export interface State extends TypedRecord<State>, GoalEditorState {}

export const initialState = recordify<GoalEditorState, State>({
  goals: Map(),
  newGoal: createGoalRecord({ id: '' })
});

const editGoalProp = (
  state: State,
  goalId: string,
  payload: EditGoalProp<keyof Goal>
) => {
  if (!goalId) {
    return state.setIn(['newGoal', payload.key], payload.value);
  }
  return state.getIn(['goals', goalId])
    ? state.setIn(['goals', goalId, payload.key], payload.value)
    : state;
};

export const reducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case C.POST_GOALS:
      return state.setIn(['newGoal'], createGoalRecord({ id: '' }));
    case C.EDIT_GOAL:
      return action.payload.goalToEdit
        ? state.setIn(
          ['goals', action.payload.goalToEdit.id],
          action.payload.goalToEdit
        )
        : state;
    case C.EDIT_GOAL_PROPS:
      const { key, value } = action.payload;
      return editGoalProp(state, action.payload.goalId, { key, value });
    case C.DELETE_GOAL:
      return action.payload.goalId
        ? state.setIn(
          ['goals'],
          state.get('goals').delete(action.payload.goalId)
        )
        : state;
    default:
      return state;
  }
};
