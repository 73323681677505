/* eslint-disable @typescript-eslint/no-explicit-any */
import { normalize, schema } from 'normalizr';

import {
  Entity,
  Admin,
  AnyTask,
  User,
  Goal,
  PainLog,
  Screener,
  Team,
  Workout,
  WeeklyMetric,
  SurgeryLog,
  Conversation,
  Message,
  MessageV2,
  GetUserById,
  MessageDirection,
  MessagePartyType
} from './interfaces';
import { Entity as GqlEntity } from '../graphql/models/index';
import { User as UserModel } from '../graphql/models/User';
import { Team as TeamModel } from '../graphql/models/Team';

import {
  ERR_PARSE_FAIL,
  ERR_GOALS_PARSE_FAIL,
  ERR_TASK_PARSE_FAIL,
  ERR_USERS_PARSE_FAIL,
  ERR_USER_PARSE_FAIL,
  ERR_PATHWAY_STATS_PARSE_FAIL,
  ERR_TEAM_PARSE_FAIL,
  ERR_CONVERSATIONS_PARSE_FAIL,
  ERR_MESSAGES_PARSE_FAIL,
  ERR_MESSAGE_PARSE_FAIL,
  ERR_TASK_USER_PARSE_FAIL,
  ERR_ENROLLMENT_USERS_PARSE_FAIL
} from '../errors';
import { messageComparator } from '../redux/selectors/messages';

export enum EntityKind {
  PAIN_LOGS = 'painLogs',
  SCREENERS = 'screeners',
  TASKS = 'tasks',
  USERS = 'users',
  WEEKLY_METRICS = 'weeklyMetrics',
  WORKOUTS = 'workouts',
  TEAMS = 'teams',
  SURGERY_LOGS = 'surgeryLogs',
  GOALS = 'goals',
  MESSAGES = 'messages',
  MESSAGES_V2 = 'messages',
  CONVERSATIONS = 'conversations'
}

const processGqlEntityIdToInt = (e: GqlEntity) => ({
  ...e,
  id: parseInt(e.id, 10)
});

const processEntityIdToString = (e: Entity) => ({ ...e, id: e.id.toString() });

const message = new schema.Entity(
  EntityKind.MESSAGES,
  {},
  {
    idAttribute: msg => msg.id.toString(),
    processStrategy: e => ({
      ...e,
      id: e.hasOwnProperty('id') ? e?.id?.toString() : undefined,
      createdAt: e.hasOwnProperty('createdAt') ? e.createdAt : e.created_at,
      scheduledFor: e.hasOwnProperty('scheduledFor') ? e.scheduledFor : e.scheduled_for,
      seenAt: e.hasOwnProperty('seenAt') ? e.seenAt : e.seen_at,
      transmittedAt: e.hasOwnProperty('transmittedAt') ? e.transmittedAt : e.transmitted_at,
      /* eslint-disable max-len */
      direction: e.direction
        ? e.direction
        : e.recipientType === MessagePartyType.Admin
          ? MessageDirection.In
          : MessageDirection.Out
    })
  }
);
const messages = new schema.Array(message);

const painLog = new schema.Entity(EntityKind.PAIN_LOGS);
const painLogs = new schema.Array(painLog);

const surgeryLog = new schema.Entity(EntityKind.SURGERY_LOGS);
const surgeryLogs = new schema.Array(surgeryLog);

const screener = new schema.Entity(EntityKind.SCREENERS);
const workout = new schema.Entity(EntityKind.WORKOUTS, {}, { processStrategy: processEntityIdToString });
const workouts = new schema.Array(workout);

const convertToStringArray = (data: number[] | undefined): string[] | undefined => {
  if (!data) return undefined;

  return data.map(item => item.toString());
};

const parseMessage = (msg: any): Message | undefined => {
  let parsedMessage: Message | undefined;

  // If the provided data is defined
  if (msg) {
    // Create the expected object
    const data = { message: msg };

    // Parse the message
    const normalizedMessage = messageParser(data);

    // Extract the parsed message from the result
    parsedMessage = Object.values(normalizedMessage.entities.messages)[0];
  }
  return parsedMessage;
};

const conversation = new schema.Entity(
  EntityKind.CONVERSATIONS,
  {
    unseenMessageIds: messages,
    messageIds: messages,
    mostRecentMessageId: message,
    scheduledMessageIds: messages
  },
  {
    idAttribute: convo => convo.id.toString(),
    processStrategy: e => {
      const unseenMessagesRaw = e.hasOwnProperty('unseenMessages') ? e.unseenMessages : e.unseen_messages;
      const unseenMessages: Message[] | undefined = !unseenMessagesRaw
        ? undefined
        : unseenMessagesRaw
          .map((m: unknown) => parseMessage(m))
        // Note: Previously, the messages were sorted by ID.  Now, messages are sorted by
        // timestamps (createdAt and transmittedAt).  Parts of the codebase rely on the
        // messages being sorted.  Thus, sort the messages as the conversation is parsed
          .sort(messageComparator)
          .reverse();

      const mostRecentMessageRaw = e.hasOwnProperty('mostRecentMessage') ? e.mostRecentMessage : e.most_recent_message;
      const mostRecentMessage = mostRecentMessageRaw?.id ? parseMessage(mostRecentMessageRaw) : undefined;

      return {
        ...e,
        id: e.hasOwnProperty('id') ? e?.id?.toString() : undefined,
        userId: e.hasOwnProperty('userId') ? e.userId : e.user_id,
        teamId: e.hasOwnProperty('teamId') ? e.teamId : e.team_id,
        unseenMessageIds: unseenMessages,
        mostRecentMessageId: mostRecentMessage,
        messageIds: convertToStringArray(e.messages),
        scheduledMessageIds: convertToStringArray(
          e.hasOwnProperty('scheduledMessages') ? e.scheduledMessages : e.scheduled_messages
        ),
        createdAt: e.hasOwnProperty('createdAt') ? e.createdAt : e.created_at,
        updatedAt: e.hasOwnProperty('updatedAt') ? e.updatedAt : e.updated_at,
        totalPages: undefined,
        currentPage: undefined
      };
    }
  }
);

const conversations = new schema.Array(conversation);

// Weekly metrics don't have unique IDs themselves, but we want to key them by a combination of user id and week index.
const weeklyMetric = new schema.Entity(
  EntityKind.WEEKLY_METRICS,
  {},
  {
    idAttribute: (value: WeeklyMetric, parent: User) => `${parent.id}:${value.week_index}`
  }
);
const weeklyMetrics = new schema.Array(weeklyMetric);

const goal = new schema.Entity(EntityKind.GOALS, {}, { processStrategy: processEntityIdToString });

const goals = new schema.Array(goal);
const user = new schema.Entity(
  EntityKind.USERS,
  {
    painLogs,
    goals,
    screener,
    surgeryLogs,
    weeklyMetrics,
    workouts,
    conversations
  },
  {
    processStrategy: e => ({
      ...e,
      addressOne: e.hasOwnProperty('addressOne') ? e.addressOne : e.address_one,
      addressTwo: e.hasOwnProperty('addressTwo') ? e.addressTwo : e.address_two,
      avatarUrl: e.hasOwnProperty('avatarUrl') ? e.avatarUrl : e.avatar_url,
      contactMethod: e.hasOwnProperty('contactMethod') ? e.contactMethod : e.contact_method,
      communicationPreferences: e.hasOwnProperty('communicationPreferences')
        ? e.communicationPreferences
        : e.communications_preferences,
      currentWeek: e.hasOwnProperty('currentWeek') ? e.currentWeek : e.current_week,
      dateOfBirth: e.hasOwnProperty('dateOfBirth') ? e.dateOfBirth : e.date_of_birth,
      engagementStreak: e.hasOwnProperty('engagementStreak') ? e.engagementStreak : e.engagement_streak,
      firstName: e.hasOwnProperty('firstName') ? e.firstName : e.first_name,
      lastActive: e.hasOwnProperty('lastActive') ? e.lastActive : e.last_active,
      lastSignInAt: e.hasOwnProperty('lastSignInAt') ? e.lastSignInAt : e.last_sign_in_at,
      lastName: e.hasOwnProperty('lastName') ? e.lastName : e.last_name,
      lastNps: e.hasOwnProperty('lastNps') ? e.lastNps : e.last_nps,
      painLogs: e.hasOwnProperty('painLogs') ? e.painLogs : e.pain_logs,
      phoneNumber: e.hasOwnProperty('phoneNumber') ? e.phoneNumber : e.phone_number,
      postalCode: e.hasOwnProperty('postalCode') ? e.postalCode : e.postal_code,
      surgeryLogs: e.hasOwnProperty('surgeryLogs') ? e.surgeryLogs : e.surgery_logs,
      stateOfResidence: e.hasOwnProperty('stateOfResidence') ? e.stateOfResidence : e.state_of_residence,
      weekIndex: e.hasOwnProperty('weekIndex') ? e.weekIndex : e.week_index,
      weeklyMetrics: e.hasOwnProperty('weeklyMetrics') ? e.weeklyMetrics : e.weekly_metrics,
      vacationInfo: e.hasOwnProperty('vacationInfo') ? e.vacationInfo : e.vacation_info,
      teamId: e.hasOwnProperty('teamId') ? e.teamId : e.team_id
    })
  }
);

const users = new schema.Array(user);

const team = new schema.Entity(
  EntityKind.TEAMS,
  {
    users
  },
  {
    processStrategy: e => ({
      ...e,
      avatarUrl: e.hasOwnProperty('avatarUrl') ? e.avatarUrl : e.avatar_url,
      currentTeamWeekNumber: e.hasOwnProperty('currentTeamWeekNumber')
        ? e.currentTeamWeekNumber
        : e.current_team_week_number,
      productType: e.hasOwnProperty('productType') ? e.productType : e.product_type,
      usersCount: e.hasOwnProperty('usersCount') ? e.usersCount : e.users_count,
      newestUnseenMessages: e.hasOwnProperty('newestUnseenMessages') ? e.newestUnseenMessages : e.newest_unseen_messages
    })
  }
);
const teams = new schema.Array(team);

const task = new schema.Entity(EntityKind.TASKS, { user, team });
const tasks = new schema.Array(task);

export interface NormalizedResponse {
  entities: {
    painLogs?: { [id: number]: PainLog };
    screeners?: { [id: number]: Screener };
    surgeryLogs?: { [id: number]: SurgeryLog };
    tasks?: { [id: number]: AnyTask };
    users?: { [id: number]: User };
    goals?: { [id: string]: Goal };
    teams?: { [id: number]: Team };
    messages?: { [id: string]: Message | MessageV2 };
    weeklyMetrics?: { [compositeId: string]: WeeklyMetric };
    workouts?: { [id: string]: Workout };
    conversations?: { [id: number]: Conversation };
  };
  result: number[];
}

export interface PagedResponse extends NormalizedResponse {
  page?: number;
  pages?: number;
  count?: number;
  hasNextPage?: boolean;
}

export interface PathwayStatsResponse extends NormalizedResponse {
  entities: {
    painLogs: { [id: number]: PainLog };
    screeners: { [id: number]: Screener };
    surgeryLogs: { [id: number]: SurgeryLog };
    users: { [id: number]: User };
    weeklyMetrics: { [compositeId: string]: WeeklyMetric };
    workouts: { [id: string]: Workout };
  };
}

export interface GoalsResponse extends NormalizedResponse {
  entities: {
    goals: { [id: string]: Goal };
  };
}

export interface TasksResponse extends PagedResponse {
  entities: {
    tasks: { [id: number]: AnyTask };
    users: { [id: number]: User };
    teams: { [id: number]: Team };
  };
}

export interface UserResponse extends PagedResponse {
  entities: {
    users: { [id: number]: User };
  };
}

export interface TeamResponse extends PagedResponse {
  entities: {
    teams: { [id: number]: Team };
  };
}

export interface AdminResponse {
  data: { admin: Admin } | false;
}

export interface MessagesResponse extends PagedResponse {
  entities: {
    messages: { [id: string]: Message };
  };
}

export interface ScheduledMessageResponse extends PagedResponse {
  entities: {
    messages: { [id: string]: Message };
  };
}

export interface ConversationsResponse extends PagedResponse {
  entities: {
    conversations: { [id: number]: Conversation };
    messages: { [id: string]: Message };
  };
}

export interface MessageResponse {
  entities: {
    messages: { [id: string]: Message };
  };
  result: number;
}

export interface TodoResults {
  teams: number[];
  users: number[];
  conversations: string[];
  messages: string[];
}

export interface TodoResponse {
  entities: {
    teams: { [id: number]: Team };
    users: { [id: number]: User };
    conversations: { [id: string]: Conversation };
    messages: { [id: string]: Message };
  };
  result: TodoResults;
}

export interface EnrollmentStatusResults {
  users: number[];
}

export interface EnrollmentStatusResponse {
  entities: {
    users: { [id: number]: User };
  };
  result: EnrollmentStatusResults;
}

export interface GetUsersByTeamResponse {
  id: number;
  name: string;
  currentWeek: number;
  users: GetUserById[];
}

export function identParser(data: any): {} {
  // This should at least be a valid object or array.
  if (data === undefined) throw new Error(ERR_PARSE_FAIL);
  return data as {};
}

export function taskCountParser(data: any): { taskCounts: { [key: string]: number } } {
  // This should at least be a valid object or array.
  if (data === undefined) throw new Error(ERR_PARSE_FAIL);
  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    taskCounts: data.data.taskCounts.reduce((obj: any, item: any) => {
      obj[item.kind] = item.count;
      return obj;
    }, {})
  };
}

export function tasksParser(data: any): TasksResponse {
  if (data === undefined) {
    throw new Error(ERR_TASK_PARSE_FAIL);
  }

  const { pageNumber, numberOfPages, totalCount } = data.data.taskList;

  if (data.data.taskList) {
    return {
      ...normalize(data.data.taskList.tasks, tasks),
      page: pageNumber,
      pages: numberOfPages,
      count: totalCount
    };
  }

  return normalize(data.data, tasks);
}

export function getUsersParser(data: any): UserResponse {
  if (data === undefined) throw new Error(ERR_USERS_PARSE_FAIL);

  const { pageNumber, numberOfPages, totalCount } = data.data.users;

  if (data.data.users) {
    return {
      ...normalize(data.data.users.users, users),
      page: pageNumber,
      pages: numberOfPages,
      count: totalCount
    };
  }

  return {
    ...normalize(data.data, users),
    page: pageNumber,
    pages: numberOfPages,
    count: totalCount
  };
}

export function userParser(data: any): UserResponse {
  if (data === undefined) throw new Error(ERR_USER_PARSE_FAIL);
  return normalize(
    {
      ...data,
      goals: data.goals ? data.goals.map(processEntityIdToString) : undefined,
      workouts: data.workouts ? data.workouts.map(processEntityIdToString) : null
    },
    user
  );
}

export function getTeamsParser(data: any): TeamResponse {
  if (data === undefined) throw new Error(ERR_TEAM_PARSE_FAIL);

  const { pageNumber, numberOfPages, totalCount } = data.data.teams;

  if (data.data.teams) {
    return {
      ...normalize(data.data.teams.teams, teams),
      page: pageNumber,
      pages: numberOfPages,
      count: totalCount
    };
  }
  return {
    ...normalize(data.data, teams),
    page: pageNumber,
    pages: numberOfPages,
    count: totalCount
  };
}

export function pathwayStatsParser(data: any): PathwayStatsResponse {
  if (data === undefined) throw new Error(ERR_PATHWAY_STATS_PARSE_FAIL);
  if (!data.user) throw new Error(ERR_USER_PARSE_FAIL);
  return normalize(
    {
      ...data.user,
      workouts: data.user.workouts ? data.user.workouts.map(processEntityIdToString) : undefined
    },
    user
  );
}

export function listGoalsParser(data: any): GoalsResponse {
  if (data === undefined) throw new Error(ERR_GOALS_PARSE_FAIL);
  if (data && !data.data) {
    return normalize(data.goals, goals);
  }
  return normalize(data.data.goals, goals);
}

export function postGoalParser(data: any): GoalsResponse {
  if (data === undefined) throw new Error(ERR_GOALS_PARSE_FAIL);
  const userGoal = {
    ...data.data.addGoal.userGoal,
    id: data.data.addGoal.userGoal.id.toString()
  };

  return normalize(userGoal, goal);
}

export function updateGoalParser(data: any): GoalsResponse {
  if (data === undefined) throw new Error(ERR_GOALS_PARSE_FAIL);
  const userGoal = {
    ...data.data.updateGoal.userGoal,
    id: data.data.updateGoal.userGoal.id.toString()
  };

  return normalize(userGoal, goal);
}

export function messagesParser(data: any): MessagesResponse {
  if (data === undefined) throw new Error(ERR_MESSAGES_PARSE_FAIL);

  const dataToParse = data.conversations || data.messages;
  const paginatedData = {
    page: data.pageNumber ?? data.page,
    pages: data.numberOfPages ?? data.pages,
    count: data.totalCount ?? data.messages?.length,
    hasNextPage: data?.hasNextPage
  };

  return { ...normalize(dataToParse, messages), ...paginatedData };
}

export function conversationsParser(data: any): ConversationsResponse {
  if (data === undefined) throw new Error(ERR_CONVERSATIONS_PARSE_FAIL);

  const { pageNumber, numberOfPages, totalCount } = data.data.conversations;
  if (data.data.conversations) {
    return {
      ...normalize(data.data.conversations.conversations, conversations),
      page: pageNumber,
      pages: numberOfPages,
      count: totalCount
    };
  }

  return {
    ...normalize(data.data, conversations),
    page: pageNumber,
    pages: numberOfPages,
    count: totalCount
  };
}

export function unseenMessageParser(data: any): TodoResponse {
  if (data === undefined) throw new Error(ERR_CONVERSATIONS_PARSE_FAIL);
  const responseSchema = new schema.Object({
    teams,
    users,
    conversations,
    messages
  });
  return normalize(data.data.allConversations, responseSchema);
}

export function postMessageParser(data: any): MessageResponse {
  if (data === undefined) throw new Error(ERR_MESSAGE_PARSE_FAIL);
  return normalize(data.data.postMessage.message, message);
}

export function messageParser(data: any): MessageResponse {
  if (data === undefined) throw new Error(ERR_MESSAGE_PARSE_FAIL);
  return normalize(data.message, message);
}

export function markAsReadParser(data: any): MessageResponse {
  if (data === undefined) throw new Error(ERR_MESSAGE_PARSE_FAIL);
  return normalize(data.data.markAsRead.message, message);
}

export function putMessageParser(data: any): MessageResponse {
  if (data === undefined) throw new Error(ERR_MESSAGE_PARSE_FAIL);
  return normalize(data.data.updateMessage.message, message);
}

export function graphqlUsersParser(usersResponse: UserModel[]): UserResponse {
  const processedUsersResponse = usersResponse.map(processGqlEntityIdToInt);
  return normalize(processedUsersResponse, users);
}

export function graphqlTeamsParser(teamsResponse: TeamModel[]): TeamResponse {
  const processedTeamsResponse = teamsResponse.map(processGqlEntityIdToInt);
  return normalize(processedTeamsResponse, teams);
}

export function deleteParser(data?: unknown) {
  return {};
}

export function todoUsersParser(data: any): TodoResponse {
  if (data === undefined) throw new Error(ERR_TASK_USER_PARSE_FAIL);

  const responseSchema = new schema.Object({
    teams,
    users,
    conversations
  });

  return normalize(data.data.taskList.tasks, responseSchema);
}

export function getUsersEnrollmentStatusParser(data: any): EnrollmentStatusResponse {
  if (data === undefined) throw new Error(ERR_ENROLLMENT_USERS_PARSE_FAIL);
  return normalize(data.data.getUsersByUuids, users);
}
