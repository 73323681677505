import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { selectAdmin } from '../../redux/selectors/admin';
import { selectUser } from '../../redux/selectors';
import { AdminRoles } from '../../utils/admin/roles';
import VideocamIcon from '@material-ui/icons/Videocam';
import { Button } from '@material-ui/core';
import './JoinCallButton.scss';
import { mixpanelTrack } from '../../mixpanel/mixpanel';

export interface JoinCallButtonProps {
  userId: number;
  userUuid: string | null | undefined;
  adminUuid: string | null;
}

export const JoinCallButton = (props: JoinCallButtonProps) => {
  const onStartVideoVisitClick = () => {
    mixpanelTrack('PT Clicks Start Video', { Origin: '', AdminUuid: props.adminUuid, UserUuid: props.userUuid });
    window.open(`/user/${props.userId}/videocall`);
  };

  return (
    <div onClick={onStartVideoVisitClick}>
      <Button
        className='join-call-button'
        id="createVideoCallButton"
      >
        <VideocamIcon />
      </Button>
      <span>
        Start video visit
      </span>
    </div>
  );
};

export interface JoinCallButtonWrapperProps {
  userId: number;
}

const JoinCallButtonWrapper = (props: JoinCallButtonWrapperProps) => {
  const user = useSelector((state: RootState) => selectUser(state, { userId: props.userId }));
  const admin = useSelector(selectAdmin);

  const shouldShowButton = !!(admin?.role === AdminRoles.physical_therapist
    && admin?.uuid
    && user?.uuid);

  if (shouldShowButton) {
    return <JoinCallButton userId={user.id} adminUuid={admin.uuid} userUuid={user.uuid} />;
  }
  return null;
};

export default JoinCallButtonWrapper;
