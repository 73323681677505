import * as React from 'react';
import './SnackBar.css';

interface Props {
  message: string;
  index: number;
}

const SnackBar: React.FC<Props> = (props) => {
  const [className, setClassName] = React.useState('snack-bar');
  setTimeout(() => setClassName('snack-bar disappear'), 3000);
  return (
    <div className={className}>{props.message}</div>
  );
};

export default SnackBar;
