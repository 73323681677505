import * as React from 'react';
import styled from 'styled-components';
import RecentExerciseTherapy from '../../components/communications/RecentExerciseTherapy';
import { CircularProgress } from '@material-ui/core';
import { Pathway } from '../../graphql/models/bff';
import { createEtSessionGroupingsHash } from '../../utils/user/createEtSessionGroupingsHash';
import { isNullOrUndefined } from '../../utils/helpers';

export const ErrorMessage = 'Failed to load.';
export const EmptyData = 'No data to display.';

interface RecentExerciseTherapyConProps {
  pathways?: Pathway[] | null;
  timezone?: string | null;
  dataLoading: boolean;
}

export const StyledDiv = styled.div`
  padding-left: 20px;
  font-size: 14px;
`;

export const RecentExerciseTherapyContainer: React.FC<RecentExerciseTherapyConProps> = ({
  pathways,
  timezone,
  dataLoading
}) => {
  if (dataLoading) {
    return <StyledDiv>
      <CircularProgress />
    </StyledDiv>;
  }

  const activePathway = pathways && pathways[0];
  const currentWeek = activePathway && activePathway.currentWeek;

  if (!pathways || !activePathway || isNullOrUndefined(currentWeek)) {
    return <StyledDiv>{EmptyData}</StyledDiv>;
  }

  const pastWeek = currentWeek - 1;
  const recentEtSessionGroupings =
  !activePathway.etSessionGroupings ?
    [] :
    activePathway.etSessionGroupings
      .filter((etSessionGroupings: { weekNumber: number }) =>
        (etSessionGroupings.weekNumber === currentWeek || etSessionGroupings.weekNumber === pastWeek))
      .map(etSessionGroupings => ({
        weekNumber: etSessionGroupings.weekNumber,
        etSessions: etSessionGroupings.etSessions.filter(etSession => !!etSession.startedAt)
      }));

  return (
    <RecentExerciseTherapy
      etSessionGroupings={createEtSessionGroupingsHash(recentEtSessionGroupings, currentWeek, pastWeek)}
      timezone={timezone}/>
  );
};
