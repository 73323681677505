import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Admin } from '../../api-client';
import { selectAdmin } from '../../redux/selectors/admin';
import { selectInCtpIframe } from '../../redux/selectors/ui-state';
import { RootState } from '../../redux/reducers';
import { DispatchMap } from '../../redux/actions/interfaces';
import NavBar from '../../containers/nav/NavBar';
import TopNavBar from '../../components/nav/TopNavBar';
import * as C from '../../colors';
import { actionCreators as authActions } from '../../redux/actions/auth';
import { mixpanelTrack } from '../../mixpanel/mixpanel';
import {
  HingeHealthSecurityContext,
  SecurityContext
} from '../../components/auth-shared/hinge-health-security-context';

export interface CoachNavLayoutProps {
  hideTopBarNav: boolean;
  children: React.ReactNode;
}

export type ConnectProps = DispatchMap<{
  admin: Admin;
  inCtpIframe?: boolean;
  logout: typeof authActions.logout;
}>;

interface State {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contextRef?: any;
}

type Props = CoachNavLayoutProps & ConnectProps;

const MainLayout = styled.div`
  display: flex;
  height: 100vh;
`;

const SideNav = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 100vh;
  background: ${C.NAV_BAR};
  width: 67px;
`;

const StickyWrapper = styled.div`
  z-index: 4;
  position: 'fixed';
`;

const MainContent = styled.div`
  flex-grow: 1;
  height: 100%;
`;

class CoachNavLayout extends React.PureComponent<Props, State> {
  static contextType = HingeHealthSecurityContext;

  constructor(props: Props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    mixpanelTrack('Page View', {
      Origin: (window.location && window.location.pathname) || ''
    });
  }

  async handleLogout() {
    const { hingeHealthAuthState, hingeHealthAuthClient } = this.context as SecurityContext;
    if (hingeHealthAuthState?.isAuthenticated) {
      await hingeHealthAuthClient.signOut();
    } else {
      this.props.logout().then(() => {
        window.location.replace(`${window.location.origin}/`);
      });
    }
  }

  render() {
    let activeNav;
    if (window.location.pathname.startsWith('/teams')) {
      activeNav = 'teams';
    } else if (window.location.pathname.startsWith('/tasks')) {
      activeNav = 'tasks';
    } else if (window.location.pathname.startsWith('/messaging')) {
      activeNav = 'messaging';
    } else if (window.location.pathname.startsWith('/care-plans')) {
      activeNav = 'care-plans';
    } else if (window.location.pathname.startsWith('/massmessaging')) {
      activeNav = 'massmessaging';
    }

    return (
      <MainLayout>
        {!this.props.inCtpIframe && (
          <SideNav>
            <StickyWrapper>
              <div>
                <NavBar activeItem={activeNav} />
              </div>
            </StickyWrapper>
          </SideNav>
        )}
        <MainContent>
          {!this.props.hideTopBarNav && (
            <TopNavBar notificationCount={0} onLogout={this.handleLogout} admin={this.props.admin} />
          )}
          {React.Children.only(this.props.children)}
        </MainContent>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  admin: selectAdmin(state),
  inCtpIframe: selectInCtpIframe(state)
});

const mapDispatchToProps = {
  logout: authActions.logout
};

export default connect(mapStateToProps, mapDispatchToProps)(CoachNavLayout);
