import * as React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, Message } from 'semantic-ui-react';

import { userActions } from '../../redux/actions';
import { DispatchMap } from '../../redux/actions/interfaces';
import { TextAreaOnChangeHandler } from '../../typings/semantic-ui-react';
import { mixpanelTrack } from '../../mixpanel/mixpanel';

export interface TagCommentModalProps {
  tag: string;
  open: boolean;
  isDelete: boolean;
  userId: number;
  onClose(): void;
}

type ConnectProps = DispatchMap<{
  addUserTag: typeof userActions.addUserTag;
  removeUserTag: typeof userActions.removeUserTag;
}>;

type Props = TagCommentModalProps & ConnectProps;

interface State {
  comment: string;
  submitDisabled: boolean;
  error: boolean;
}

class TagCommentModal extends React.PureComponent<Props, State> {
  state: State = { comment: '', submitDisabled: true, error: false };

  handleCommentInputChange: TextAreaOnChangeHandler = (_, el) => {
    const value = el.value as string;
    this.setState({ comment: value, submitDisabled: !(value.length > 0) });
  };

  handleSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    if (this.props.isDelete) {
      mixpanelTrack('tag removed', { TagType: this.props.tag, Origin: '/user' });
      this.handleRemoveTag();
    } else {
      mixpanelTrack('tag added', { TagType: this.props.tag, Origin: '/user' });
      this.handleAddTag();
    }
    this.props.onClose();
  };

  handleAddTag = () => {
    this.props.addUserTag(this.props.tag, this.state.comment, this.props.userId)
      .then(() => this.setState({ error: false}))
      .catch(() => this.setState({ error: true}));
  };

  handleRemoveTag = () => {
    this.props.removeUserTag(this.props.tag, this.state.comment, this.props.userId)
      .then(() => this.setState({ error: false}))
      .catch(() => this.setState({ error: true}));
  };

  render() {
    return (
      <Modal size="tiny" open={this.props.open}>
        <Modal.Header>
          {this.props.isDelete
            ? `Remove ${this.props.tag} tag?`
            : `Add ${this.props.tag} tag?`}
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit}>
            { this.state.error &&
            <Message error>An error occurred while ${this.props.isDelete ? 'removing' : 'adding'} the tag.</Message>
            }
            <Form.TextArea
              label="Leave a Comment"
              onChange={this.handleCommentInputChange}
              placeholder="Write your comment..."
              required />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.onClose} secondary>
            NEVER MIND
          </Button>
          <Button disabled={this.state.submitDisabled} onClick={this.handleSubmit} primary>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  addUserTag: userActions.addUserTag,
  removeUserTag: userActions.removeUserTag
};

export default connect(null, mapDispatchToProps)(TagCommentModal);
