import { ISplitState } from '@splitsoftware/splitio-redux/lib/types';
import { RootAction } from '../actions';
import { Action, AnyAction, Reducer } from 'redux';
import { SET_FEATURE_FLAG } from '../constants';

export function debugReducer(
  state: ISplitState,
  action: RootAction
): ISplitState {
  switch (action.type) {
    case SET_FEATURE_FLAG:
      const {flag, value} = action.payload;
      const treatments = state.treatments;
      // This reconstructs a treatment object in the SplitIO State
      const treatment = treatments[flag] || {
        default: {
          treatment: 'should_never_be_visible',
          config: null
        }
      };
      Object.values(treatment)[0].treatment = value ? 'on' : 'off';

      return {
        ...state,
        treatments: {
          ...treatments,
          [flag]: treatment
        }
      };

    default: return state;
  }
};

export function mergeReducers<S, A extends Action = AnyAction>(
  firstReducer: Reducer<S, A>,
  secondReducer: ( state: S, action: A ) => S
): Reducer<S,A> {
  return (state, action) => secondReducer(firstReducer(state, action), action);
}
