import * as React from 'react';
import styled from 'styled-components';
import * as moment from 'moment-timezone';
import { Grid, Header, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import TaskTitle from '../TaskTitle';
import { renderTags } from '../../phx/tasks/Tag';
import { mixpanelTrack } from '../../../mixpanel/mixpanel';
import {
  RuleDrivenTask,
  User,
  Team,
  TaskKind,
  RuleDrivenExtras,
  SurgeryIncrExtras,
  SurgeryTrendsExtras,
  SurgeryIncrTask,
  TaskDescriptions
} from '../../../api-client/interfaces';
import { getKindColor, getKindMessage } from '../../../utils/tasks';
import { formatToInt, isNumber } from '../../../utils/number';
import { isNullOrUndefined } from '../../../utils/helpers';
import { DEFAULT_TIMEZONE } from '../../../utils/user/timezone';
import { selectTaskCreatedAt } from '../../../redux/selectors';
import UserStatusLabel from '../../communications/UserStatusLabel';

export const UserNameLink = styled.link`
  cursor: pointer;
  font-weight: 700;
  padding-right: 5px;
`;

const StyledContainer = styled.div`
  padding-bottom: 0;
`;

const StyledUserRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TaskRow = styled.div`
  max-width: 100%;
  width: auto;
  display: flex;
  flex-direction: row;
  padding-bottom: 2px;
`;

const TeamInfo = styled.div`
  padding: 2px 5px;
  display: inline-block;
`;

export const StyledValuesContainer = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const StyledKindMessage = styled.h2`
  font-size: 1em;
  color: ${(props: { kind: TaskKind }) => getKindColor(props.kind)} !important;
  display: inline;
`;

const StyledSourceContainer = styled.div`
  padding-top: 10px;
`;

export interface RuleDrivenTaskProps {
  task: RuleDrivenTask | SurgeryIncrTask;
  extras?: RuleDrivenExtras | SurgeryIncrExtras | SurgeryTrendsExtras | null;
  kind?: TaskKind | null;
  className?: string;
  grouped?: boolean;
  inProfile?: boolean;
  user?: User;
  team: Team;
}

interface TaskValuesProps {
  kind?: TaskKind | null;
  extras?: RuleDrivenExtras | SurgeryIncrExtras | null;
}

const trackMixpanelProfileClick = (taskKind?: TaskKind | null) => {
  mixpanelTrack('Task Link clicked', {
    TaskType: taskKind,
    Origin: '/tasks',
    TaskLink: 'User Name'
  });
};

const getSurgeryDiffColor = (reference: number, actual?: number | null): string => {
  if (isNumber(actual) && actual >= reference) return 'green';
  if (isNumber(actual) && actual < reference) return 'red';
  return 'black';
};

const formatDiffDirection = (actual?: number | null) => {
  if (isNumber(actual) && actual > 0) return <Icon name="arrow down" />;
  if (isNumber(actual) && actual < 0) return <Icon name="arrow up" />;

  return <></>;
};

const getUserCurrentWeek = (weekIndex?: number | null) => {
  if (isNullOrUndefined(weekIndex)) return null;

  return weekIndex + 1;
};

export const TaskValues: React.FunctionComponent<TaskValuesProps> = props => {
  const { kind, extras } = props;

  if (kind === TaskKind.surgeryIncr) {
    const surgery_log = extras as SurgeryTrendsExtras;
    const {
      diff,
      upcomingSurgeries,
      weekIndex,
      baselineLikelihood,
      currentLikelihood,
      referenceDiff
    } = surgery_log;
    const surgeryDiff = isNumber(diff) ? Math.abs(diff) : NaN;
    const refDiff = isNumber(referenceDiff) ? Math.abs(referenceDiff) : NaN;
    return (
      <Grid style={{ marginTop: '5px', marginBottom: '5px' }}>
        <Grid.Row columns={2}>
          <Grid.Column>
            <span>{'1 Year: '}</span>
            <span style={{
              color: getSurgeryDiffColor(refDiff, diff), fontSize: '14px'
            }}>
              {formatDiffDirection(diff)}{`${Math.floor(surgeryDiff)}% `}</span>
          </Grid.Column>
          <Grid.Column>
            <span>{'Expected: '}<Icon name="arrow down" /></span>
            <span style={{ fontSize: '14px' }}>{`${refDiff}%`}</span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <span style={{ marginRight: '10px' }}>{`Week ${weekIndex}: `}</span>
            <span style={{ fontSize: '14px', marginRight: '5px' }}>{`${baselineLikelihood}`}</span>
            <Icon name="long arrow alternate right" />
            <span style={{ fontSize: '14px', marginLeft: '5px' }}>{`${currentLikelihood}`}</span>
          </Grid.Column>
          <Grid.Column />
        </Grid.Row>
        {upcomingSurgeries ?
          <Grid.Row columns={2} style={{ marginBottom: '5px' }}>
            <Grid.Column>{'Upcoming Procedure:'}</Grid.Column>
            <Grid.Column><strong style={{ color: 'red' }}>{'Yes'}</strong></Grid.Column>
          </Grid.Row> : <></>
        }
      </Grid >
    );
  }

  return (
    <StyledValuesContainer>
      <StyledKindMessage kind={kind ? kind : TaskKind.default}>
        {kind && getKindMessage(kind)}
        {extras && extras.increase ? ` +${formatToInt(extras.increase)}` : ''}
      </StyledKindMessage>
    </StyledValuesContainer>
  );
};

export const TaskExtras: React.FunctionComponent<TaskValuesProps> = props => {
  const { kind, extras } = props;

  if (kind === TaskKind.surgeryIncr && extras) {
    const surgExtras = extras as SurgeryTrendsExtras;
    if (surgExtras.proceduresHaving) {
      return <div>{`${surgExtras.proceduresHaving}`}</div>;
    }
  }
  if (!isNullOrUndefined(extras) && !isNullOrUndefined(extras.note)) {
    return <div>{extras.note}</div>;
  }

  return <div>{TaskDescriptions[kind as string]}</div>;
};

class RuleDrivenTaskContent extends React.PureComponent<RuleDrivenTaskProps> {
  trackProfileClick = () =>
    trackMixpanelProfileClick(this.props.kind);

  render() {
    const { user, task, team } = this.props;
    const currentWeek = getUserCurrentWeek(user?.weekIndex);

    return (
      <StyledContainer className={this.props.className}>
        {!this.props.grouped && (
          <Header as="h1" size="small">
            <TaskTitle kind={task.kind as string} />
          </Header>
        )}
        {!this.props.inProfile &&
          user && (
          <StyledUserRow className="ruleTaskUserRow">
            <TaskRow>
              <Link
                to={`/user/${task.user}`}
                style={{ cursor: 'pointer', fontWeight: 700, paddingRight: 5, paddingTop: 2 }}
                onClick={this.trackProfileClick}
              >
                {`${user.firstName ? user.firstName : ''} ${
                  user.lastName ? user.lastName[0] : ''
                }`}
              </Link>
              <UserStatusLabel status={user.userStatus}/>
            </TaskRow>
            {<TeamInfo>{`Week ${currentWeek} \u00b7 ${team?.name}`}</TeamInfo>}
            <TaskRow>
              {renderTags(user.tags, { size: 'mini' })}
            </TaskRow>
          </StyledUserRow>
        )}
        <TaskValues kind={task.kind} extras={task.extras} />
        <TaskExtras kind={task.kind} extras={task.extras} />
        <StyledSourceContainer>
          {moment.utc(selectTaskCreatedAt(task))
            .tz(DEFAULT_TIMEZONE)
            .startOf('hour')
            .fromNow()}
        </StyledSourceContainer>
      </StyledContainer>
    );
  }
}

export default RuleDrivenTaskContent;
