import * as React from 'react';
import { connect } from 'react-redux';
import { PainLog, User, Screener } from '../../api-client';
import { userActions } from '../../redux/actions';
import { DispatchMap } from '../../redux/actions/interfaces';
import {
  selectUser,
  selectScreener,
  selectSortedUserPainLogs,
  selectMaxDailyPainLogs
} from '../../redux/selectors';
import { RootState } from '../../redux/reducers';
import PainChart from '../../components/charts/PainChart';
import PainTable from '../../components/charts/PainTable';
import { Segment, Header, Message } from 'semantic-ui-react';
import { DEFAULT_TIMEZONE } from '../../utils/user/timezone';
import { Store } from 'redux';

interface PainStatsSegmentProps {
  store?: Store<RootState>;
  userId: number;
}

interface StateProps {
  user?: User;
  painLogs: PainLog[];
  screener?: Screener;
  maxPainLogs: PainLog[];
}

type DispatchProps = DispatchMap<{
  getPathwayStats: typeof userActions.getPathwayStats;
}>;

type Props = PainStatsSegmentProps & StateProps & DispatchProps;

function getCurrentWeek(user: User): number {
  if (user && user.team && user.team.currentTeamWeekNumber) return user.team.currentTeamWeekNumber;

  const endOfProgramWeek = 13;
  return endOfProgramWeek;
}

class PainStatsSegment extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.getPathwayStats(this.props.userId);
  }

  getProductType = (user: User) => (user.team && user.team.productType) ? user.team.productType : '';

  getTimezone = (user: User) => user.timezone || DEFAULT_TIMEZONE;

  render() {
    if (!this.props.user) return null;

    const headerText = this.props.user.firstName ? `${this.props.user.firstName}'s Pain` : undefined;
    const currentWeek = getCurrentWeek(this.props.user);
    const timezone = this.getTimezone(this.props.user);

    return (
      <Segment>
        <Header>{headerText}</Header>
        <PainChart
          startWeek={0}
          endWeek={currentWeek > 13 ? currentWeek : 13}
          user={this.props.user}
          maxPainLogs={this.props.maxPainLogs}
          screener={this.props.screener}
          timezone={timezone}
        />
        {this.props.screener
          ?
          <PainTable
            productType={this.getProductType(this.props.user)}
            painLogs={this.props.painLogs}
            screener={this.props.screener}
            timezone={timezone}
          />
          : <Message error icon="exclamation" header="Screener data is missing!" />
        }
      </Segment>
    );
  }
}

const mapStateToProps = (state: RootState, props: PainStatsSegmentProps): StateProps => ({
  screener: selectScreener(state, props),
  painLogs: selectSortedUserPainLogs(state, props),
  maxPainLogs: selectMaxDailyPainLogs(state, props),
  user: selectUser(state, props)
});

const mapDispatchToProps = {
  getPathwayStats: userActions.getPathwayStats
};

export default connect(mapStateToProps, mapDispatchToProps)(PainStatsSegment);
