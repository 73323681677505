import * as React from 'react';
import { Location } from 'history';
import styled from 'styled-components';

import UserNavBar from '../../components/nav/UserNavBar';
import UserProfileBannerContainer from '../user/phx/UserProfileBannerContainer';
import PathwayStatsMenu from './PathwayStatsMenu';
import SurgeryStatsSegment from './SurgeryStatsSegment';
import PathwayLinksSegment from './PathwayLinksSegment';
import { useUserId } from '../../utils/hooks/useUserId';

const Content = styled.div`
  margin-bottom: 2rem;
`;

export interface UserSurgeryContainerProps {
  location?: Location;
}

const UserSurgeryContainer: React.FunctionComponent<UserSurgeryContainerProps> = ({ location }) => {
  const userId = useUserId();

  return (
    <Content>
      <div style={{ width: '95%', margin: 'auto' }}>
        <UserProfileBannerContainer id={userId} />
        <UserNavBar location={location} id={userId} />
        <PathwayStatsMenu pathname={location ? location.pathname : ''} userId={userId} />
        <SurgeryStatsSegment userId={userId} />
        <PathwayLinksSegment userId={userId} />
      </div>
    </Content>
  );
};

export default UserSurgeryContainer;
