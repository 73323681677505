import * as React from 'react';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Typography, Divider, Button } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import { ApiClientNames } from '../apollo/ApolloContainer';
import { mixpanelTrack } from '../../mixpanel/mixpanel';
import { GET_LATEST_SHIPMENT_BY_USER_ID, GET_USER_PATHWAY_STAGE } from '../../graphql/queries/User';
import { TRANSITION_PATHWAY } from '../../graphql/mutations';
import { User } from '../../graphql/models/User';
import OnboardingTracking from '../../components/communications/OnboardingTracking';
import { PathwayStage } from '../../api-client/interfaces';
import { RootState } from '../../redux/reducers';
import { DispatchThunk } from '../../redux/actions/';
import { MarkUserOnboarded } from '../../redux/actions/user';
import { MARK_USER_ONBOARDED } from '../../redux/constants/user';
import { selectAdmin } from '../../redux/selectors';
import OnboardedTransitionConfirmModal from '../user/OnboardedTransitionConfirmModal';
import { GetUserById } from '../../graphql/models/bff';
import {
  StyledHeaderWrapper,
  StyledTypographySinceOnboarded
} from './ActivityEngagementContainer';
import { DateTimeFormatOptions } from 'luxon';

export const ShipmentErrorMessage = 'Failed to load tracking status.';
export const EmptyData = 'No data to display.';

interface OnboardingTrackingConProps {
  userId: number;
  userData?: GetUserById | null;
}

interface Response {
  getUserById: User;
}

const StyledDiv = styled.div`
  padding-left: 20px;
  font-size: 14px;
`;

const StyledTypographyOnboardingTitle = styled(Typography)`
  && {
    padding: 20px;
    font-size: 18px;
  }
`;

const StyledOnboardingSubtitleWrapper = styled.div`
  padding-top: 24px;
`;

const StyledButton = styled(Button)`
  && {
    margin: 24px 20px;
  }
`;

interface TransitionToCoachingResponse {
  transitionPathway: {} | false;
}

interface TransitionToCoachingVariables {
  uuid: string;
};

export const OnboardingTrackingContainer: React.FC<OnboardingTrackingConProps> = ({ userId, userData }) => {
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [modalErrorText, setModalErrorText] = React.useState<string | undefined>(undefined);

  const dispatch: DispatchThunk = useDispatch();

  const adminId = useSelector((state: RootState) => selectAdmin(state).id);

  const firstEtDate  = userData?.pathways && userData.pathways[0]?.firstEtAt;

  const { loading: shipmentLoading, error, data: shipmentData } = useQuery<Response, { userId: number }>(
    GET_LATEST_SHIPMENT_BY_USER_ID,
    {
      variables: { userId },
      context: { clientName: ApiClientNames.BFF }
    }
  );

  const { loading: pathwayLoading, data: pathwayData } = useQuery<Response, { userId: number }>(
    GET_USER_PATHWAY_STAGE,
    {
      variables: { userId },
      context: { clientName: ApiClientNames.BFF }
    }
  );

  const [transitionToCoaching, { data: transitionToCoachingRes, loading: transitionToCoachingLoading }] = useMutation<
  TransitionToCoachingResponse,
  TransitionToCoachingVariables
  >(TRANSITION_PATHWAY, { context: { clientName: ApiClientNames.BFF } });

  if (shipmentLoading || pathwayLoading) {
    return (
      <StyledDiv>
        <CircularProgress />
      </StyledDiv>
    );
  }

  if (!error && !shipmentData?.getUserById && !pathwayData?.getUserById) {
    return <StyledDiv>{EmptyData}</StyledDiv>;
  }

  const pathway = pathwayData?.getUserById.pathways && pathwayData?.getUserById.pathways[0];
  const pathwayUuid = pathway?.uuid as string;

  if (transitionToCoachingRes?.transitionPathway === false) {
    throw new Error(`Error transitioning pathway stage to coaching, pathway uuid: ${pathwayUuid}`);
  }

  const handleOpenConfirmModal = () => {
    mixpanelTrack('transfer_to_coach_clicked', {
      Origin: '/messaging',
      AdminId: adminId,
      UserId: userId
    });
    setShowConfirmModal(true);
  };

  const handleDismissModal = () => {
    mixpanelTrack('transfer_to_coach_cancelled', {
      Origin: '/messaging',
      AdminId: adminId,
      UserId: userId
    });
    setShowConfirmModal(false);
    if (modalErrorText) setModalErrorText(undefined);
  };

  const handleConfirmClick = () => {
    mixpanelTrack('transfer_to_coach_confirmed', {
      Origin: '/messaging',
      AdminId: adminId,
      UserId: userId
    });
    transitionToCoaching({ variables: { uuid: pathwayUuid } }).then(() => {
      setShowConfirmModal(false);
      dispatch<MarkUserOnboarded>({ payload: { userId }, type: MARK_USER_ONBOARDED });
    }).catch((transitionError) => {
      const errMessage = transitionError.message.includes('Pathway is not in onboarding stage') ?
        transitionError.message : 'Unable to transition pathway, please try again';
      mixpanelTrack('transfer_to_coach_failed', {
        Origin: '/messaging',
        AdminId: adminId,
        UserId: userId
      });
      setModalErrorText(errMessage);
      Sentry.captureException(transitionError);
    });
  };

  const formatDateAsReadableString = (dateToFormat: string) => {
    const options: DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateToFormat);
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <>
      <StyledHeaderWrapper>
        <StyledTypographyOnboardingTitle>Onboarding</StyledTypographyOnboardingTitle>
        {firstEtDate && (
          <StyledOnboardingSubtitleWrapper>
            <StyledTypographySinceOnboarded>
              Onboarded {formatDateAsReadableString(firstEtDate)}
            </StyledTypographySinceOnboarded>
          </StyledOnboardingSubtitleWrapper>
        )}
      </StyledHeaderWrapper>
      {shipmentData?.getUserById && !error && (
        <OnboardingTracking latestShipment={shipmentData?.getUserById.latestShipment} />
      )}
      {error && <StyledDiv>{ShipmentErrorMessage}</StyledDiv>}
      {pathwayUuid && pathway?.stage === PathwayStage.Onboarding && (
        <>
          <Divider light={true} variant='middle' />
          <StyledButton
            onClick={handleOpenConfirmModal}
            variant='outlined'
            color='primary'
            disabled={transitionToCoachingLoading}
          >
            Transfer to coach
          </StyledButton>
          <OnboardedTransitionConfirmModal
            open={showConfirmModal}
            onDismiss={handleDismissModal}
            onConfirm={handleConfirmClick}
            errorMessage={modalErrorText}
            disableConfirmButton={transitionToCoachingLoading}
          />
        </>
      )}
    </>
  );
};
