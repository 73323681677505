import * as React from 'react';
import { LegendProps } from 'recharts';
import { RED, DARKER_NAV_BAR } from '../../colors';

const finalStyle = {
  padding: 5,
  margin: 5,
  textAlign: 'right' as 'right'
};

const itemStyle = {
  display: 'inline-block',
  padding: 5
};

const svgStyle = {
  display: 'inline-block',
  verticalAlign: 'middle',
  marginRight: 4
};

const lineSize = 32;
const halfLineSize = lineSize / 2;
const refDasharray = '8 2';

const PainLegend: React.FunctionComponent<LegendProps> = (props) => {
  const { payload, iconSize } = props;

  return payload && payload[0] ?
    (
      <ul style={finalStyle}>
        <li style={itemStyle}>
          {<svg width={iconSize} height={iconSize} style={svgStyle} viewBox={'0 0 32 32'}>
            <line
              strokeWidth={4}
              fill="none"
              stroke={RED}
              x1={0}
              y1={halfLineSize}
              x2={lineSize}
              y2={halfLineSize}
            />
          </svg>}
          <span>{payload[0].value}</span>
        </li>
        <li style={itemStyle}>
          {<svg width={iconSize} height={iconSize} style={svgStyle} viewBox={'0 0 32 32'}>
            <line
              strokeWidth={4}
              fill="none"
              stroke={DARKER_NAV_BAR}
              strokeDasharray={refDasharray}
              x1={0}
              y1={halfLineSize}
              x2={lineSize}
              y2={halfLineSize}
            />
          </svg>}
          <span>{'Screener pain'}</span>
        </li>
      </ul>
    ) :
    null;
};

export default PainLegend;
