import { connect } from 'react-redux';

import GoalSegment from '../../components/user/GoalSegment';
import { Goal } from '../../api-client/index';
import { actionCreators as goalEditingActions } from '../../redux/actions/goals-editor';

export interface GoalSegmentContainerProps {
  userId: number;
  goal: Goal;
}

const mapDispatchToProps = {
  placeGoalToEdit: goalEditingActions.placeGoalToEdit
};

export default connect(null, mapDispatchToProps)(GoalSegment);
