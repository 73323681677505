import { Middleware, Dispatch, MiddlewareAPI } from 'redux';
import { LOCATION_CHANGE } from 'connected-react-router';

import * as mixpanel from './mixpanel';
import MixpanelEvents from './events';

export function trackPageView(pathname: string) {
  mixpanel.mixpanelTrack(MixpanelEvents.PageView, { Origin: pathname });
}

/**
 * Fires a mixpanel "Page" event when a LocationChangeAction is dispatched
 * by connected-react-router
 */
const mixpanelMiddleware: Middleware = (api: MiddlewareAPI) => (next: Dispatch) => action => {
  if (action.type === LOCATION_CHANGE) {
    const { pathname } = action.payload.location;
    trackPageView(pathname);
  }
  return next(action);
};

export default mixpanelMiddleware;
