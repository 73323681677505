import * as React from 'react';
import Helmet from 'react-helmet';
import { ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import Routes from './routes';

import CesarMilan from './containers/async/CesarMilan';
import ConnectionWrapper from './containers/async/ConnectionWrapper';
import { initWatchdog } from './watchdog';
import theme from './theme';

class App extends React.PureComponent {
  componentDidMount() {
    initWatchdog();
  }

  render() {
    return (
      <ConnectionWrapper>
        <Helmet
          title="Coach Tool App | Hinge Health"
          meta={[
            { name: 'description', content: 'Coach tools' },
            { name: 'keywords', content: 'coach tools' }
          ]}
        />
        <div
          style={{
            margin: '0 auto',
            height: '100vh',
            paddingTop: 0
          }}
        >
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
          {process.env.NODE_ENV !== 'production'}
          <CesarMilan />
        </div>
      </ConnectionWrapper>
    );
  }
}

export default Sentry.withProfiler(App);
