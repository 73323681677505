import * as React from 'react';
import { Table } from 'semantic-ui-react';

import { HealthLog, Pathway } from '../../../graphql/models';
import { formatDiff, isNumber } from '../../../utils/number';
import { getWeekStartDate } from './PainChart';
import * as moment from 'moment-timezone';
import * as R from 'ramda';
import { DEFAULT_TIMEZONE } from '../../../utils/user/timezone';
import { PRE_ZERO_WEEK_START } from '../../../utils/chart';

interface PainTableProps {
  pathways?: Pathway[] | null;
  healthLogs: HealthLog[];
  timezone?: string | null;
}

export const painDifference = (currentPain: number, baselinePain: number) =>
  isNumber(baselinePain) && isNumber(currentPain)
    ? currentPain - baselinePain
    : NaN;

export const formatDate = (
  date?: string | null,
  timezone?: string | null
): string => date
  ? moment(date)
    .tz(timezone || DEFAULT_TIMEZONE)
    .format('LLL z')
  : '–';

export const sortReportsReverseWeek = (a: HealthLog, b: HealthLog): number => (
  moment.utc(b.occurredAt).valueOf() - moment.utc(a.occurredAt).valueOf()
);

export const getWeekIndex = (h: HealthLog, start?: moment.Moment) => {
  const weeksDiff = h.occurredAt ? moment(h.occurredAt).diff(start, 'weeks') : NaN;
  return weeksDiff - PRE_ZERO_WEEK_START;
};

export const selectPainReport = (log?: HealthLog | null) =>
  log && log.painReports && log.painReports[0];

function renderTableRows(
  { healthLogs, timezone, pathways }: PainTableProps,
  baselineLog: HealthLog
) {
  let lastWeekIndex: number | null | undefined;
  const logsWithoutInitial = R.dropLast(1, healthLogs);
  const weekStartDate = getWeekStartDate(pathways && pathways[0]);

  return logsWithoutInitial.map(h => {
    const weekIndex = getWeekIndex(h, weekStartDate);
    const painReport = selectPainReport(h);
    const baselineReport = selectPainReport(baselineLog);
    const primaryPathwayPain = (painReport && painReport.pain) || NaN;
    const baselinePain = (baselineReport && baselineReport.pain) || NaN;
    const painDiff = painDifference(primaryPathwayPain, baselinePain);

    const r = (
      <Table.Row key={h.uuid}>
        <Table.Cell singleLine>
          {isNumber(weekIndex) && lastWeekIndex !== weekIndex
            ? `Week ${weekIndex}`
            : ''}
        </Table.Cell>
        <Table.Cell>
          {isNumber(primaryPathwayPain) ? primaryPathwayPain : '–'}
        </Table.Cell>
        <Table.Cell positive={painDiff <= -20} negative={painDiff >= 20}>
          {formatDiff(painDiff)}
        </Table.Cell>
        <Table.Cell singleLine={false}>{h.note}</Table.Cell>
        <Table.Cell singleLine>
          {formatDate(h.occurredAt || 'N/A', timezone)}
        </Table.Cell>
      </Table.Row>
    );
    lastWeekIndex = weekIndex;
    return r;
  });
}

export const renderBaselineRow = (
  baselineLog?: HealthLog | null,
  timezone?: string | null
) => {
  const baselineReport = selectPainReport(baselineLog);
  return (
    <Table.Row>
      <Table.Cell>Screener</Table.Cell>
      <Table.Cell>{(baselineReport && baselineReport.pain) || '–'}</Table.Cell>
      <Table.Cell>–</Table.Cell>
      <Table.Cell>–</Table.Cell>
      <Table.Cell>
        {formatDate(baselineLog && baselineLog.occurredAt, timezone) || '–'}
      </Table.Cell>
    </Table.Row>
  );
};

const PainTable: React.FunctionComponent<PainTableProps> = props => {
  const { timezone, pathways, healthLogs } = props;
  const logsSortedReverseWeek = healthLogs.slice().sort(sortReportsReverseWeek);
  const baselineLog = R.takeLast(1, logsSortedReverseWeek)[0];

  return (
    <Table celled striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Week</Table.HeaderCell>
          <Table.HeaderCell>Pain</Table.HeaderCell>
          <Table.HeaderCell>All-Time Pain</Table.HeaderCell>
          <Table.HeaderCell>Note</Table.HeaderCell>
          <Table.HeaderCell>Local Timestamp</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {renderTableRows(
          { healthLogs: logsSortedReverseWeek, timezone, pathways },
          baselineLog
        )}
        {renderBaselineRow(baselineLog, timezone)}
      </Table.Body>
    </Table>
  );
};

export default PainTable;
