export const CREATE_VIDEO_CALL = `
  mutation createVideoCall(
    $adminUuid: String!,
    $userUuid: String!,
    $basiliskPathwayId: Int!,
    $identifier: String!,
    $scheduledAt: String!) {
      createVideoCall(
        adminUuid: $adminUuid,
        userUuid: $userUuid,
        basiliskPathwayId: $basiliskPathwayId,
        identifier: $identifier,
        scheduledAt: $scheduledAt
    )
  }
`;

export const CREATE_JWT = `
  mutation createJwt($uuid: String!, $identifier: String!) {
    createJwt(uuid: $uuid, identifier: $identifier)
  }
`;
