import * as React from 'react';
import useNotification from './NotificationContext';
import { RootState } from '../../redux/reducers';
import { selectNotification } from '../../redux/selectors';
import { connect } from 'react-redux';
import { CloseRounded, InfoOutlined, RefreshRounded } from '@material-ui/icons';
import './Notification.scss';
import { NotificationType } from './NotificationProvider';
import { store } from '../../store';
import { SHOW_NOTIFICATION } from '../../redux/constants';

const Notification = (props: { notification: NotificationType }) => {
  const data = useNotification();
  const message = props.notification.message || data.notification.message;
  const httpStatus = data.notification.httpStatus || props.notification.httpStatus;
  const type = data.notification.type || props.notification.type;
  const handleNotification = () => {
    if (httpStatus === 401) {
      window.location.reload();
      return;
    }
    store.dispatch({
      payload: {
        message: '',
        httpStatus: 0,
        type: ''
      },
      type: SHOW_NOTIFICATION
    });
  };
  return (
    <div className='notification'
      style={message ? {display: 'block'}: {display: 'none'}}>
      <div className={`content ${type || 'error'}`}>
        <div className='info'>
          <div className='icon'><InfoOutlined fontSize='small'/></div>
          <div className='message'>{ message }</div>
        </div>
        <div className='info notification-action' onClick={handleNotification}>
          <div className='icon'>
            { httpStatus === 401 ? <RefreshRounded /> : <CloseRounded />}
          </div>
          <div className='message'>
            { httpStatus === 401 ? 'Refresh' : 'Close'}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  notification: selectNotification(state)
});
export default connect(mapStateToProps)(Notification);

