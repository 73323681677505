import * as React from 'react';
import styled from 'styled-components';
import { Menu, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { RED, GREEN, GREY } from '../../colors';
import { UserProfileRoutes } from '../../utils/user/routes';
import { isNumber, formatDiff } from '../../utils/number';
import {
  RootState,
  UserRecord,
  PainLogRecord,
  ScreenerRecord,
  WeeklyMetricRecord,
  SurgeryLogRecord
} from '../../redux/reducers';
import {
  selectCurrentWeekMetric,
  selectFirstPainLogFactory,
  selectScreener,
  selectUser,
  selectInitialSurgeryLogFactory,
  selectLastSurgeryLogFactory
} from '../../redux/selectors';
import { connect } from 'react-redux';
import InactivityLabel from '../../components/user/InactivityLabel';
import { userActions } from '../../redux/actions';
import { DispatchMap } from '../../redux/actions/interfaces';
import { isScreenerAndInitialLogSame } from './SurgeryStatsSegment';
import { DEFAULT_TIMEZONE } from '../../utils/user/timezone';

const TabMenu = styled(Menu)`
  .active.item {
    background: #f2f2f2 !important;

    &:hover {
      background: #f2f2f2 !important;
    }
  }

  .item:hover {
    background: #f2f2f2 !important;
  }
`;

export interface StatsTabItemProps {
  small?: boolean;
}

export interface StatsTabValueProps {
  negative?: boolean;
  positive?: boolean;
}

interface StatsTabLabelProps {
  active?: boolean;
}

const StatsTabItem = styled.div`
  color: #000 !important;
  display: flex;
  flex-direction: column;
  font-size: ${(props: StatsTabItemProps) => props.small ? '0.75' : '1'}em;
  font-weight: bold;
  height: 100%;
  justify-content: space-around;
  padding: 0.5rem;
`;

const StatsTabLabel = styled.div`
  border-bottom: ${(props: StatsTabLabelProps) => props.active ? '2px solid black' : '0px'};
  color: ${(props: StatsTabLabelProps) => props.active ? '#00bcd4' : '#000000'};
  text-transform: uppercase;
  margin-bottom: 0.5em;
`;

const StatsTabValue = styled.div`
  color: ${(props: StatsTabValueProps) => (props.negative && RED) || (props.positive && GREEN) || 'inherit'};
  font-size: 1.5em;

  > small {
    color: ${GREY};
  }
`;

export const SurgeryScreenerDiff = styled.span`
  color: ${(props: StatsTabValueProps) => (props.positive && GREEN) || (props.negative && RED) || 'inherit'} !important;
  font-size: 0.65em;
  padding-left: 3px;
`;

export interface PathwayStatsMenuProps {
  pathname: string;
  userId: number;
}

export type ConnectProps = DispatchMap<{
  currentWeekMetric: WeeklyMetricRecord | undefined;
  getPathwayStats: typeof userActions.getPathwayStats;
  screener: ScreenerRecord;
  user: UserRecord;
  firstPainLog: PainLogRecord;
  firstSurgeryLog: SurgeryLogRecord;
  lastSurgeryLog: SurgeryLogRecord;
}>;

function formatRelativeNumber(from: number | undefined | null, to: number | undefined | null) {
  if (from === undefined || from === null || to === undefined || to === null) return undefined;
  const d = to - from;
  return d <= 0 ? `${d}` : `+${d}`;
}

type Props = PathwayStatsMenuProps & ConnectProps;

export const formattedCurrentSurgeryNumber = (surgery_number?: number | null) =>
  isNumber(surgery_number) ? surgery_number : '–';

interface SurgeryDataValueProps {
  surgery_number?: number | null;
  screener_number?: number | null;
}
export const SurgeryDataValue: React.FunctionComponent<SurgeryDataValueProps> =
  ({ surgery_number, screener_number }) => {
    const diff = isNumber(surgery_number) && isNumber(screener_number) ? surgery_number - screener_number : NaN;

    return (
      <div>
        <span>{formattedCurrentSurgeryNumber(surgery_number)}</span>
        <SurgeryScreenerDiff
          positive={diff < 0}
          negative={diff > 0}
        >
          {formatDiff(diff)}
        </SurgeryScreenerDiff>
      </div>
    );
  };

interface SurgeryMenuItemProps {
  surgeryLog: SurgeryLogRecord;
  screener?: ScreenerRecord | SurgeryLogRecord;
  userId: number;
  active?: boolean;
}
export const SurgeryMenuItem: React.FunctionComponent<SurgeryMenuItemProps> =
  ({ active, surgeryLog, screener, userId }) => {
    const { surgery_likelihood_1yr, surgery_likelihood_2yr, surgery_likelihood_5yr } = surgeryLog;

    return screener ? (
      <Link to="surgery">
        <Grid columns={3}>
          <StatsTabItem>
            <StatsTabLabel active={active}>Surgery</StatsTabLabel>
            <StatsTabValue>
              <SurgeryDataValue
                surgery_number={surgery_likelihood_1yr}
                screener_number={screener.surgery_likelihood_1yr}
              />
              <small>1yr</small>
            </StatsTabValue>
          </StatsTabItem>
          <StatsTabItem>
            <StatsTabLabel>{'\u00a0'}</StatsTabLabel>
            <StatsTabValue>
              <SurgeryDataValue
                surgery_number={surgery_likelihood_2yr}
                screener_number={screener.surgery_likelihood_2yr}
              />
              <small>2yr</small>
            </StatsTabValue>
          </StatsTabItem>
          <StatsTabItem>
            <StatsTabLabel>{'\u00a0'}</StatsTabLabel>
            <StatsTabValue>
              <SurgeryDataValue
                surgery_number={surgery_likelihood_5yr}
                screener_number={screener.surgery_likelihood_5yr}
              />
              <small>5yr</small>
            </StatsTabValue>
          </StatsTabItem>
        </Grid>
      </Link>
    ) : null;
  };

class PathwayStatsMenu extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.getPathwayStats(this.props.userId);
  }

  render() {
    if (!this.props.user) return null;
    const { pathname, user, currentWeekMetric, firstPainLog, firstSurgeryLog, screener } = this.props;
    const timezone = user.timezone || DEFAULT_TIMEZONE;
    const surgeryScreener = isScreenerAndInitialLogSame(screener, firstSurgeryLog, timezone) ?
      firstSurgeryLog : screener;
    let exerciseValue;
    let painValue;
    let allTimePainValue;

    if (currentWeekMetric && currentWeekMetric.exercises_goal) {
      const { exercises, exercises_goal } = currentWeekMetric;

      const completed_exercises = exercises || 0;
      exerciseValue = `${completed_exercises}/${exercises_goal}`;
    }

    if (!isNaN(screener.id) && !isNaN(firstPainLog.id)) {
      painValue = `${firstPainLog.pain}`;
      allTimePainValue = formatRelativeNumber(screener.pain, firstPainLog.pain);
    }

    return (
      <TabMenu pointing>
        <Menu.Item link name="engagement" active={pathname.endsWith(UserProfileRoutes.Engagement)}>
          <Link to="engagement">
            <StatsTabItem>
              <StatsTabLabel active={pathname.endsWith(UserProfileRoutes.Engagement)}>Engagement</StatsTabLabel>
              <StatsTabValue><InactivityLabel user={user} /></StatsTabValue>
            </StatsTabItem>
          </Link>
        </Menu.Item>
        <Menu.Item link name="exercise" active={pathname.endsWith(UserProfileRoutes.Exercise)}>
          <Link to="exercise">
            <StatsTabItem>
              <StatsTabLabel active={pathname.endsWith(UserProfileRoutes.Exercise)}>Exercise Therapy</StatsTabLabel>
              <StatsTabValue>{exerciseValue}</StatsTabValue>
            </StatsTabItem>
          </Link>
        </Menu.Item>
        <Menu.Item link name="pain" active={pathname.endsWith(UserProfileRoutes.Pain)}>
          <Link to="pain" style={{ display: 'block' }}>
            <Grid columns={3}>
              <StatsTabItem>
                <StatsTabLabel active={pathname.endsWith(UserProfileRoutes.Pain)}>Pain</StatsTabLabel>
                <StatsTabValue>{painValue}</StatsTabValue>
              </StatsTabItem>
              <StatsTabItem small>
                <StatsTabLabel>All-time</StatsTabLabel>
                <StatsTabValue
                  negative={!!allTimePainValue && allTimePainValue[0] === '+'}
                  positive={!!allTimePainValue && allTimePainValue[0] === '-'}
                >{allTimePainValue}</StatsTabValue>
              </StatsTabItem>
            </Grid>
          </Link>
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item link name="surgery" active={pathname.endsWith(UserProfileRoutes.Surgery)}>
            <SurgeryMenuItem
              active={pathname.endsWith(UserProfileRoutes.Surgery)}
              userId={this.props.userId}
              screener={surgeryScreener}
              surgeryLog={this.props.lastSurgeryLog}
            />
          </Menu.Item>
          <Menu.Item>
            <StatsTabItem>
              <StatsTabLabel>NPS</StatsTabLabel>
              <StatsTabValue>
                {user.lastNps}
              </StatsTabValue>
            </StatsTabItem>
          </Menu.Item>
        </Menu.Menu>
      </TabMenu>
    );
  }
}

const mapStateToProps = (state: RootState, props: PathwayStatsMenuProps) => {
  const sel = { userId: props.userId };
  const selectFirstSurgeryLog = selectInitialSurgeryLogFactory();
  const selectLastSurgeryLog = selectLastSurgeryLogFactory();
  const selectFirstPainLog = selectFirstPainLogFactory();
  return {
    currentWeekMetric: selectCurrentWeekMetric(state, sel),
    firstPainLog: selectFirstPainLog(state, sel),
    pathname: props.pathname,
    screener: selectScreener(state, sel),
    user: selectUser(state, sel),
    firstSurgeryLog: selectFirstSurgeryLog(state, sel),
    lastSurgeryLog: selectLastSurgeryLog(state, sel)
  };
};

const mapDispatchToProps = {
  getPathwayStats: userActions.getPathwayStats
};

export default connect(mapStateToProps, mapDispatchToProps)(PathwayStatsMenu);
