import { useParams } from 'react-router-dom';

interface UserIdParams {
  id: string;
}

export function useUserId() {
  const { id } = useParams<UserIdParams>();
  const idAsNumber = parseInt(id || '', 10);

  return idAsNumber;
}
