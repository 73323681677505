import * as React from 'react';
import { Modal, Typography, Button } from '@material-ui/core';
import './ModalWrapper.scss';
import Alert from '../errors/Alert';

interface ModalContent {
  title: string;
  body: string | JSX.Element;
  confirmBtnText: string;
  dismissBtnText: string;
}

interface InlineStyleProps {
  [key: string]: string | number;
}

export interface ModalProps {
  modalContent: ModalContent;
  openModal: boolean;
  modalClassName?: string;
  onDismiss(): void;
  onBackdropClick?(): void;
  disableConfirmButton?: boolean;
  onConfirm(): void;
  disableBackDropClick?: boolean;
  container?: React.ReactInstance | null;
  inlineStyle?: InlineStyleProps;
  backdropStyle?: InlineStyleProps;
  errorContent?: string;
}

const ModalWrapper = ({
  modalContent,
  modalClassName,
  onConfirm,
  disableConfirmButton = false,
  onDismiss,
  onBackdropClick = onDismiss,
  disableBackDropClick = false,
  container,
  inlineStyle,
  backdropStyle,
  openModal,
  errorContent
}: ModalProps) => {
  const handleDismissClick = () => onDismiss();
  const handleConfirmClick = () => onConfirm();
  const handleBackDropClick = () => onBackdropClick();

  return (
    <Modal
      className={`${modalClassName || ''} modal-wrapper`}
      open={openModal}
      disableBackdropClick={disableBackDropClick}
      onBackdropClick={handleBackDropClick}
      container={container}
      style={inlineStyle}
      BackdropProps={{ style: backdropStyle }}
    >
      <div className='modal-wrapper__container'>
        <div className='modal-wrapper__title'>
          <Typography variant='h6'>{modalContent.title}</Typography>
        </div>
        {errorContent && <Alert variant='error'>{errorContent}</Alert>}
        <div className='modal-wrapper__body'>
          {typeof modalContent.body === 'string' ? (
            <Typography variant='body2'>{modalContent.body}</Typography>
          ) : (
            <>{modalContent.body}</>
          )}
        </div>
        <div className='modal-wrapper__button-container'>
          <Button
            data-testid='modal-wrapper-cancel-button'
            className='modal-wrapper__button'
            onClick={handleDismissClick}
            variant='text'
            disableElevation
            color='primary'
          >
            {modalContent.dismissBtnText}
          </Button>

          <Button
            data-testid='modal-wrapper-confirm-button'
            className='modal-wrapper__button'
            onClick={handleConfirmClick}
            variant='text'
            disableElevation
            color='primary'
            disabled={disableConfirmButton}
          >
            {modalContent.confirmBtnText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalWrapper;
