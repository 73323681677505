export const POST_MESSAGE = `
  mutation postMessage($conversationId: StringOrInt!, $message: NewMessageType!) {
    postMessage(conversationId: $conversationId, message: $message ) 
  }
`;

/* eslint-disable  max-len */
export const UPDATE_MESSAGE = `
  mutation updateMessage($conversationId: StringOrInt, $messageId: StringOrInt, $message: MessageBasiliskType!, $userUuid: String, $adminUuid: String) {
      updateMessage(conversationId: $conversationId, message: $message, messageId: $messageId, userUuid: $userUuid, adminUuid: $adminUuid)
    }
`;
/* eslint-enable */

export const DELETE_MESSAGE = `
  mutation deleteMessage($conversationId: StringOrInt!, $messageId: String!) {
    deleteMessage(conversationId: $conversationId, messageId: $messageId)
  }
`;

/* eslint-disable  max-len */
export const MARK_AS_READ = `
  mutation markAsRead($adminUuid: String, $conversationId: StringOrInt, $messageId: StringOrInt, $message: MessageBasiliskType!, $userUuid: String) {
    markAsRead(adminUuid: $adminUuid, conversationId: $conversationId, messageId: $messageId, message: $message, userUuid: $userUuid)
  }
`;
/* eslint-enable */
