import * as React from 'react';
import './ConnectionWrapper.scss';
import {
  Button,
  Image
} from 'semantic-ui-react';
import * as logo from '../../images/logo.png';

interface Props {
  children: React.ReactNode;
}

function ConnectionWrapper({children}: Props) {
  const [isDisconnected, setIsDisconnected] = React.useState(false);

  const handleConnectionChange = () => {
    setIsDisconnected(!navigator.onLine);
  };

  const removeListeners = () => {
    setIsDisconnected(false);
    window.removeEventListener('online', handleConnectionChange);
    window.removeEventListener('offline', handleConnectionChange);
  };

  React.useEffect(() => {
    handleConnectionChange();
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => removeListeners();
  }, []);

  return (
    <div className={isDisconnected ? 'connection-wrapper-disconnected' : ''}>
      {isDisconnected && (
        <div className="disconnected">
          <Image src={logo} width={80} centered />
          <h2 className="title">Internet Connection Lost</h2>
          <div className="message">
            <p>Please check your internet connection. You appear to be offline.</p>
            <p>Coach Tools will refresh automatically when your internet reconnects.</p>
            <p>
              If this page does not refresh automatically, you can return to Coach Tools without a connection,
              but your experience may be impacted.
            </p>
          </div>
          <Button className="online" primary onClick={removeListeners}>Return without connection</Button>
        </div>
      )}
      <div className={isDisconnected ? 'hide-ct' : ''}>
        {children}
      </div>
    </div>
  );
}

export default ConnectionWrapper;
