import * as React from 'react';
import HingeHealthSecurity from './hinge-health-security';
import { OKTA_CALLBACK_PATH } from '../../routes';
import { useHistory } from 'react-router-dom';

export interface SecurityContainerProps {
  children: React.ReactNode;
}

const SecurityContainer = ({
  children
}: SecurityContainerProps) => {
  const history = useHistory();
  const options = {
    oktaConfig: {
      clientId: process.env.OKTA_CLIENT_ID || '',
      issuer: process.env.OKTA_ISSUER || '',
      redirectUri: `${window.location.origin}${OKTA_CALLBACK_PATH}`,
      restoreOriginalUri: async(originalUri: string): Promise<void> => {
        history.push(originalUri || '/tasks');
      }
    },
    capabilitiesConfig: {
      enabled: false
    }
  };

  return (
    <HingeHealthSecurity options={options}>
      {children}
    </HingeHealthSecurity>
  );
};

export default SecurityContainer;
