import * as React from 'react';
import { OnboardingTrackingContainer } from '../../containers/communications/OnboardingTrackingContainer';
import { ActivityEngagementContainer } from '../../containers/communications/ActivityEngagementContainer';
import { RemindersContainer } from '../../containers/communications/RemindersContainer';
import AchievementsContextContainer from '../../containers/user/AchievementsContextContainer';
import { GetUserById } from '../../graphql/models/bff';

interface EngagementManagerProps {
  userId: number;
  userData?: GetUserById | null;
  dataLoading: boolean;
}

export const EngagementManager: React.FC<EngagementManagerProps> = ({
  userId,
  userData,
  dataLoading
}) => (
  <>
    <ActivityEngagementContainer
      userId={userId}
      userData={userData}
      dataLoading={dataLoading}
    />
    <RemindersContainer userId={userId} />
    <AchievementsContextContainer userId={userId} showRecent={true} displayV2Components />
    <OnboardingTrackingContainer userId={userId} userData={userData} />
  </>
);
