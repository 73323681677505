import * as R from 'ramda';

import { GoalsResponse } from '../../api-client';

import { GenericAction, ThunkAction } from './interfaces';
import * as C from '../constants';
import {
  getApiClient,
  selectAddGoal,
  selectEditedGoal,
  selectUser
} from '../selectors';
import { GoalRecord } from '../reducers/goals';
import { UserRecord } from '../reducers/user';

import { mixpanelTrack } from '../../mixpanel/mixpanel';
import { UPDATE_GOAL, ADD_GOAL } from '../../graphql/mutations';
import { DELETE_GOAL } from '../../graphql/mutations/Goals';

interface GoalPayload {
  userId: number;
  goal: GoalRecord;
}

interface GoalCreateDeletePayload {
  user: UserRecord;
  goalId: string;
}

export interface UpdateGoalAction extends GenericAction<GoalPayload, {}> {
  payload: GoalPayload;
  type: typeof C.UPDATE_GOAL;
}

export interface PostGoalAction
  extends GenericAction<GoalCreateDeletePayload, {}> {
  payload: GoalCreateDeletePayload;
  type: typeof C.POST_GOALS;
}

export interface DeleteGoalAction
  extends GenericAction<GoalCreateDeletePayload, {}> {
  payload: GoalCreateDeletePayload;
  type: typeof C.DELETE_GOAL;
}

export type GoalActions = UpdateGoalAction | PostGoalAction | DeleteGoalAction;

export const actionCreators = {
  getAll(userId: number): ThunkAction<Promise<GoalsResponse>> {
    return (dispatch, getState) => {
      const api = getApiClient(getState());
      return api.goals.get({}, { userId }).then(res => {
        dispatch({ payload: res, type: C.ENTITIES_FETCHED });
        return res;
      });
    };
  },
  put(userId: number, goalId: string): ThunkAction<Promise<void>> {
    return (dispatch, getState) => {
      const state = getState();
      const goal = selectEditedGoal(state, { goalId });
      if (goal === undefined) {
        return Promise.reject(new Error('Goal doesn\'t exist.'));
      }
      const payload = goal;
      const api = getApiClient(getState());

      return api.goals
        .update({ query: UPDATE_GOAL, variables: { goal: payload, userId } })
        .then(() => {
          mixpanelTrack('Goal Edited', {
            Origin: '/user',
            GoalId: goalId
          });
          dispatch<UpdateGoalAction>({
            payload: { userId, goal: payload },
            type: C.UPDATE_GOAL
          });
        });
    };
  },
  post(userId: number, _: string): ThunkAction<Promise<void>> {
    return (dispatch, getState) => {
      const state = getState();
      const goal = selectAddGoal(state);
      const payload = R.dissoc('id', goal.toJS());
      const api = getApiClient(state);
      const user = selectUser(state, { userId });

      return api.goals
        .post({
          query: ADD_GOAL,
          variables: {
            content: payload.content,
            doneBy: payload.done_by,
            userId
          }
        })
        .then(resp => {
          mixpanelTrack('Goal Created', {
            Origin: '/user'
          });
          dispatch({ payload: resp, type: C.ENTITIES_FETCHED });
          const goalId = R.last(Object.keys(resp.entities.goals)) || '';
          dispatch<PostGoalAction>({
            payload: { user, goalId },
            type: C.POST_GOALS
          });
        });
    };
  },
  deleteGoal(userId: number, goalId: string): ThunkAction<Promise<{}>> {
    return (dispatch, getState) => {
      const api = getApiClient(getState());
      const user = selectUser(getState(), { userId });
      return api.goals
        .delete({
          query: DELETE_GOAL,
          variables: {
            userId,
            goalId: parseInt(goalId, 10)
          }
        })
        .then(() => {
          mixpanelTrack('Goal Deleted', {
            Origin: '/user'
          });
          return dispatch<DeleteGoalAction>({
            payload: { user, goalId },
            type: C.DELETE_GOAL
          });
        });
    };
  }
};
