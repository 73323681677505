import { Box, Popover } from '@material-ui/core';
import { AccessTime, Pause, PriorityHigh } from '@material-ui/icons';
import * as React from 'react';
import { MemberAlert } from '../../api-client/interfaces';
import MemberAlertIconModal from './MemberAlertIconModal';
import { MEMBER_BANNER_TEST_IDS } from './constants/memberAlertIcon';
import styled from 'styled-components';
import { DateTime } from 'luxon';

interface MemberAlertIconProps {
  memberAlerts: (MemberAlert | null)[];
}

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const StyledPopover = styled(Popover)`
  pointer-events: none;
`;

const MemberAlertIcon: React.FC<MemberAlertIconProps> = ({ memberAlerts }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  if (!memberAlerts || memberAlerts.length === 0) return <></>;

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };

  const icon = () => {
    const today = DateTime.now().toString();
    let bannerIcon;

    const lastStartAt = memberAlerts[memberAlerts.length - 1]?.startAt;

    if (lastStartAt && lastStartAt >= today) {
      bannerIcon = (
        <AccessTime
          data-testid={MEMBER_BANNER_TEST_IDS.UPCOMING_ICON}
          style={{
            color: '#0A7D8F'
          }}
          fontSize={'small'}
        />
      );
    } else if (memberAlerts[memberAlerts.length - 1]?.paused) {
      bannerIcon = (
        <Pause
          data-testid={MEMBER_BANNER_TEST_IDS.PAUSED_ICON}
          style={{
            color: '#D61916'
          }}
        />
      );
    } else {
      bannerIcon = (
        <PriorityHigh
          data-testid={MEMBER_BANNER_TEST_IDS.ACTIVE_ICON}
          style={{
            color: '#0A7D8F'
          }}
        />
      );
    }

    return bannerIcon;
  };

  return (
    <StyledBox
      aria-haspopup='true'
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      data-testid={MEMBER_BANNER_TEST_IDS.ALERT_ICON}
    >
      {icon()}
      <StyledPopover
        open={open}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disableRestoreFocus
      >
        <MemberAlertIconModal memberAlerts={memberAlerts} icon={icon()} />
      </StyledPopover>
    </StyledBox>
  );
};

export default MemberAlertIcon;
