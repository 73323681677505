import { Entity, OptionalProperties } from '../';

export enum CarePlanStatus {
  Open = 'open',
  Closed = 'closed'
}

export interface CarePlanItem {
  id: string;
  key: string;
  currentValue: string;
}

export interface Icd10 {
  code: string;
  description: string;
}

export enum Prescription {
  None = 'None',
  Obtained = 'Obtained'
}

export interface CarePlan extends
  Entity,
  OptionalProperties<{
    userId: string;
    adminId: string;
    title: string;
    status: CarePlanStatus;
    icd10: Icd10[];
    items: CarePlanItem[];
    expectedDischargeAt: string | Date;
    openedAt: string | Date;
    closedAt: string | Date | null;
  }> {};
