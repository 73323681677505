import * as React from 'react';
import { ErrorOutline } from '@material-ui/icons';
import './Alert.scss';

interface AlertProps {
  variant: 'error' | 'success'; // union add'l variant types as needed
  children: string;
}

const Alert = ({ variant = 'error', children }: AlertProps) => (
  <div className={'alert' + ' alert--' + variant}>
    <div className={'alert__icon' + ' alert__icon--' + variant}><ErrorOutline/></div>
    <div className='alert__content'>{children}</div>
  </div>
);

export default Alert;
