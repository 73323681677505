import { RootState } from '../reducers';
import { createSelector } from 'reselect';

export const selectAuthConfig = (state: RootState) => state.auth;
export const selectAuthEmail = (state: RootState) => state.auth.email;

export const selectAuthPassword = (state: RootState) => state.auth.password;

export const getUid = (state: RootState) => state.auth.uid;

export const getAuthHeaders = createSelector(
  selectAuthConfig,
  (auth) => ({ 'access-token': auth['access-token'], client: auth.client, uid: auth.uid, uuid: auth.uuid })
);
