import { RootState } from '../reducers/index';
import { selectUser } from './index';
import { createSelector } from 'reselect';
import * as R from 'ramda';
import { WeeklyMetricRecord } from '../reducers/weekly-metric';
import { WeeklyMetric } from '../../api-client';
import { isNumber } from '../../utils/number';

export function selectWeeklyMetrics(state: RootState) {
  return state.weeklyMetric;
}

export const selectUserWeeklyMetrics = createSelector(
  selectWeeklyMetrics, (_: RootState, props: { userId: number }) =>
    props, (weeklyMetrics, { userId }) =>
    weeklyMetrics.filter((_?: WeeklyMetricRecord, key?: string) => {
      const userIdRegEx = RegExp(`^${userId}\:`);
      return !!(key && userIdRegEx.test(key));
    }).toMap());

export function selectWeeklyMetric(state: RootState, props: { userId: number; weekIndex: number }) {
  return state.weeklyMetric.get(`${props.userId}:${props.weekIndex}`);
}

export function selectCurrentWeekMetric(state: RootState, props: { userId: number }) {
  const user = selectUser(state, { userId: props.userId });
  const weekIndex = user?.team?.currentTeamWeekNumber;

  if (weekIndex === undefined || weekIndex === null) return undefined;

  return selectWeeklyMetric(state, { userId: props.userId, weekIndex });
}

export const selectWeeklyMetricRange = createSelector(
  selectWeeklyMetrics, (_: RootState, props: { userId: number; startWeek: number; endWeek: number }) => props,
  (weeklyMetrics, { userId, startWeek, endWeek }) =>
    R.range(startWeek, endWeek).reverse().map((i) => weeklyMetrics.get(`${userId}:${i}`))
);

export function sortWeeklyMetricReverseWeek(a: WeeklyMetric, b: WeeklyMetric): number {
  if (!isNumber(a.week_index)) return -1;
  if (!isNumber(b.week_index)) return 1;

  return b.week_index - a.week_index;
}
