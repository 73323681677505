import * as React from 'react';
import { Location } from 'history';
import styled from 'styled-components';
import { Box, Grid } from '@material-ui/core';

import { ERR_USER_ID_INVALID } from '../../../errors';
import UserNavBar from '../../../components/phx/nav/UserNavBar';
import UserBioSections from '../UserBioSections';
import UserGoalsSectionContainer from '../UserGoalsSectionContainer';
import UserNoteManager from '../../communications/UserNoteManager';
import UserProfileBanner from './UserProfileBannerContainer';
import UserTasksContainer from '../UserTasksContainer';
import AchievementsContextContainer from '../AchievementsContextContainer';
import './UserOverviewContainer.scss';

const Content = styled.div`
  margin-bottom: 2rem;
`;

export interface UserOverviewContainerProps {
  location?: Location;
  userUuid?: string | null;
  id?: number;
}

class UserOverviewContainer extends React.PureComponent<UserOverviewContainerProps> {
  render() {
    const { id, userUuid, location } = this.props;
    if (id === undefined) throw new Error(ERR_USER_ID_INVALID);
    return (
      <Content className='user-overview-container'>
        <div className='scrolling' style={{ width: '95%', margin: 'auto' }}>
          <UserProfileBanner id={id} />
          <UserNavBar location={location} id={id} />
          <Grid container spacing={6} className='overview-grid'>
            <Grid item xs={6}>
              <UserNoteManager userId={id} userUuid={userUuid} view={'userOverview'} />
            </Grid>
            <Grid item xs={6}>
              <Box className='section'>
                <UserTasksContainer userId={id} className='section' />
              </Box>
              <Box className='section'>
                <UserGoalsSectionContainer userId={id} />
              </Box>
              <Box className='section'>
                {/* Passing !displayV2Components is a until this feature is refactored to use new container styles */}
                <AchievementsContextContainer userId={id} displayV2Components={false} />
              </Box>
              <Box className='section'>
                <UserBioSections userId={id} />
              </Box>
            </Grid>
          </Grid>
        </div>
      </Content>
    );
  }
}

export default UserOverviewContainer;
