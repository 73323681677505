import * as React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { actionCreators as goalEditingActions } from '../../redux/actions/goals';
import { DispatchMap } from '../../redux/actions/interfaces';

export type GoalDeleteModalProps = DispatchMap<{
  deleteGoalId: string;
  userId: number;
  open: boolean;
  onClose(): void;
  deleteGoal: typeof goalEditingActions.deleteGoal;
}>;

interface DefaultProps {
  open: boolean;
}

type Props = GoalDeleteModalProps & DefaultProps;

class GoalDeleteModal extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    open: false
  };

  handleCancelSubmit = () => {
    this.props.onClose();
  };

  handleSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    this.submit();
  };

  submit = () => {
    this.props.deleteGoal(this.props.userId, this.props.deleteGoalId);
    this.props.onClose();
  };

  render() {
    return (
      <Modal size="tiny" open={this.props.open}>
        <Modal.Header>Are you sure you want to delete this goal?</Modal.Header>
        <Modal.Actions>
          <Button className="cancelButton" onClick={this.handleCancelSubmit} secondary>
            CANCEL
          </Button>
          <Button className="deleteButton" onClick={this.submit} negative>
            DELETE
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default GoalDeleteModal;
