import { RootAction } from '../actions';
import {
  UPDATE_TASK_COUNT,
  UPDATE_UNREAD_MESSAGE_COUNT,
  UPDATE_TASK_KIND_RESULTS,
  UPDATE_TASK_USER_COUNT,
  CLEAR_TASK_USER_COUNTS,
  ADD_UNREAD_MESSAGE_COUNT,
  RESET_DO_TASK_USER_COUNT,
  UPDATE_MULTI_TASK_USER_COUNT,
  UPDATE_MULTI_UNREAD_MESSAGE_COUNT,
  SHOW_NOTIFICATION,
  STORE_TEMPLATE_USER_IDS,
  TOGGLE_SELECT_TEMPLATE_USER_ID,
  UPDATE_MODAL_OPEN,
  REMOVE_TEMPLATE_USER_IDS,
  MARK_USER_ONBOARDED,
  RESET_USERS_ONBOARDED,
  UPDATE_TEAM_RESULTS,
  SET_IN_CTP_IFRAME
} from '../constants';
import { chain } from 'lodash';
import { DoTaskKinds } from '../actions/ui-state';
import { NotificationType } from '../../components/errors/NotificationProvider';
import { MassMessageTemplate } from 'src/api-client';

export interface TaskKindResults {
  teamIds: number[];
  userIds: number[];
  conversationIds: string[];
}

export interface TeamResults {
  userIds: number[];
}

export interface TaskKindUserCount {
  [id: number]: number;
}


export interface State {
  taskKind: { [key: string]: TaskKindUserCount };
  taskCounts: { [key: string]: number };
  hasMoreTasks: { [key: string]: boolean };
  showTodoRefresh: { [key: string]: boolean };
  taskKindResults: { [key: string]: TaskKindResults };
  teamResults: { [key: string]: TeamResults };
  notification: NotificationType;
  templateUserIds: { [key in MassMessageTemplate]?: number[] };
  modalOpen: boolean;
  onboardedUsers: number[];
  inCtpIframe: boolean;
}

export const initialState: State = {
  taskKind: {},
  taskKindResults: {},
  teamResults: {},
  hasMoreTasks: {},
  showTodoRefresh: {},
  taskCounts: {},
  notification: {message: '', httpStatus: 0, type: ''},
  templateUserIds: {},
  modalOpen: false,
  onboardedUsers: [],
  inCtpIframe: false
};

export const reducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case UPDATE_TASK_COUNT:
      const kinds = Object.keys(action.payload);
      const moreTasksUpdated = { ...state.hasMoreTasks };
      const todosRefresh = { ...state.showTodoRefresh };

      kinds.map((kind) => {
        if (!state.taskCounts[kind]) {
          moreTasksUpdated[kind] = false;
        }

        if (state.taskCounts[kind] < action.payload[kind]) {
          moreTasksUpdated[kind] = true;
        }

        if (state.taskCounts[kind] && state.taskCounts[kind] !== action.payload[kind]) {
          todosRefresh[kind] = true;
        }
      });

      return {
        ...state,
        taskCounts: {
          ...state.taskCounts,
          ...action.payload
        },
        hasMoreTasks: {
          ...moreTasksUpdated
        },
        showTodoRefresh: {
          ...todosRefresh
        }
      };
    case UPDATE_UNREAD_MESSAGE_COUNT:
      let hasMoreTasks = { ...state.hasMoreTasks };
      // refresh is shown only when the new message is received and the user is not in the list
      if(action.payload.count > 0
        && state.taskKind.unreadMessage
        && !state.taskKind.unreadMessage[action.payload.userId]
        && state.taskKind.unreadMessage[action.payload.userId] !== 0) {
        hasMoreTasks = {...hasMoreTasks, ...{ unreadMessage: true}};
      }
      return {
        ...state,
        hasMoreTasks: {
          ...hasMoreTasks
        },
        taskCounts: {
          ...state.taskCounts,
          unreadMessage: (state.taskCounts.unreadMessage || 0) + action.payload.count
        },
        taskKind: {
          ...state.taskKind,
          unreadMessage: {
            ...state.taskKind.unreadMessage,
            [action.payload.userId]:
            (state.taskKind.unreadMessage && state.taskKind.unreadMessage[action.payload.userId] || 0)
            + action.payload.count
          }
        }
      };
    case UPDATE_MULTI_UNREAD_MESSAGE_COUNT:
      const res = chain(action.payload)
        .keyBy('userId')
        .mapValues('count')
        .value();
      let moreTask = { ...state.hasMoreTasks };
      Object.keys(res).forEach((key) => {
        if(state.taskKind.unreadMessage
        && !state.taskKind.unreadMessage[Number(key)]
        && state.taskKind.unreadMessage[Number(key)] !== 0) {
          moreTask = {...moreTask, unreadMessage: true };
        }
      });
      return {
        ...state,
        hasMoreTasks: {
          ...state.hasMoreTasks,
          ...moreTask
        },
        taskCounts: {
          ...state.taskCounts,
          unreadMessage: Object.values(res).reduce((a, b) => a + b, 0)
        },
        taskKind: {
          ...state.taskKind,
          unreadMessage: {
            ...state.taskKind.unreadMessage,
            ...res
          }
        }
      };
    case ADD_UNREAD_MESSAGE_COUNT:
      const result = chain(action.payload)
        .keyBy('userId')
        .mapValues('count')
        .value();
      return {
        ...state,
        taskKind: {
          ...state.taskKind,
          unreadMessage: result
        },
        taskCounts: {
          ...state.taskCounts,
          unreadMessage: Object.values(result).reduce((a, b) => a + b, 0)
        },
        hasMoreTasks: {
          ...state.hasMoreTasks,
          unreadMessage: false
        }
      };
    case UPDATE_TASK_KIND_RESULTS:
      return {
        ...state,
        taskKindResults: {
          ...state.taskKindResults,
          [action.payload.kind]: {
            teamIds: action.payload.teamIds,
            userIds: action.payload.userIds,
            conversationIds: action.payload.conversationIds
          }
        }
      };
    case UPDATE_TASK_USER_COUNT: {
      const { todoKey, userId, count } = action.payload;
      return {
        ...state,
        taskKind: {
          ...state.taskKind,
          [todoKey]: {
            ...state.taskKind[todoKey],
            [userId]: count
          }
        }
      };
    }
    case UPDATE_MULTI_TASK_USER_COUNT: {
      const { tasksWithCount, userId } = action.payload;
      const taskKind = Object.keys(tasksWithCount).reduce((acc, kind) => ({
        ...acc,
        [kind]: {
          ...state.taskKind[kind],
          [userId]: tasksWithCount[kind]
        }
      }), {});
      return {
        ...state,
        taskKind: {
          ...state.taskKind,
          ...taskKind
        }
      };
    }
    case RESET_DO_TASK_USER_COUNT: {
      const userId  = action.payload.userId;
      const taskKind = DoTaskKinds.reduce((acc, kind) => ({
        ...acc,
        [kind]: {
          ...state.taskKind[kind],
          [userId]: 0
        }
      }), {});

      return {
        ...state,
        taskKind: {
          ...state.taskKind,
          ...taskKind
        }
      };
    }
    case CLEAR_TASK_USER_COUNTS: {
      return {
        ...state,
        taskKind: {
          ...state.taskKind,
          [action.payload.todoKey]: {}
        },
        hasMoreTasks: {
          ...state.hasMoreTasks,
          [action.payload.todoKey]: false
        },
        showTodoRefresh: {
          ...state.showTodoRefresh,
          [action.payload.todoKey]: false
        }
      };
    }
    case SHOW_NOTIFICATION: {
      return {
        ...state,
        notification: action.payload
      };
    }
    case STORE_TEMPLATE_USER_IDS: {
      return {
        ...state,
        templateUserIds: {
          ...state.templateUserIds,
          ...action.payload
        }
      };
    }
    case TOGGLE_SELECT_TEMPLATE_USER_ID: {
      const template = action.payload.template;
      const selectedUserIds = state.templateUserIds[template];
      if (!selectedUserIds) return state;

      if (action.payload.toggleSelect) {
        return {
          ...state,
          templateUserIds: {
            ...state.templateUserIds,
            [template]: selectedUserIds.concat([action.payload.userId])
          }
        };
      } else {
        return {
          ...state,
          templateUserIds: {
            ...state.templateUserIds,
            [template]: selectedUserIds.filter(id => id !== action.payload.userId)
          }
        };
      }
    }
    case UPDATE_MODAL_OPEN: {
      return {
        ...state,
        modalOpen: action.payload.openState
      };
    }
    case REMOVE_TEMPLATE_USER_IDS: {
      return {
        ...state,
        templateUserIds: {
          ...state.templateUserIds,
          [action.payload.template]: []
        }
      };
    }
    case UPDATE_TEAM_RESULTS: {
      const { teamId, userIds } = action.payload;
      return {
        ...state,
        teamResults: {
          ...state.teamResults,
          [teamId]: { userIds }
        }
      };
    }
    case MARK_USER_ONBOARDED: {
      const { userId } = action.payload;
      const taskKindResultsCopy = { ...state.taskKindResults };
      const refreshTasks = Object.keys(taskKindResultsCopy).reduce(
        (acc: { [key: string]: boolean }, kind: string) => {
          const containsId = taskKindResultsCopy[kind].userIds.includes(userId);
          if (containsId) {
            acc[kind] = true;
          }
          return acc;
        }, {}
      );

      return {
        ...state,
        onboardedUsers: [ ...state.onboardedUsers, userId ],
        showTodoRefresh: {
          ...state.showTodoRefresh,
          ...refreshTasks
        }
      };
    }
    case RESET_USERS_ONBOARDED: {
      return {
        ...state,
        onboardedUsers: []
      };
    }
    case SET_IN_CTP_IFRAME
    : {
      return {
        ...state,
        inCtpIframe: action.payload.flag
      };
    }
    default:
      return state;
  }
};
