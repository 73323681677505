import * as React from 'react';
import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import ConversationListContainer from '../../containers/communications/ConversationListContainer';
import { GET_TEAM } from '../../graphql/queries/Teams';
import { SHOW_NOTIFICATION } from '../../redux/constants';

export default function TeamMessagingUserList(props: { teamId?: string; isUserViewingCtm?: boolean }) {
  const { loading, error, data } = useQuery(GET_TEAM, { variables: { id: props.teamId } });
  const dispatch = useDispatch();

  const handleError = React.useCallback(() => {
    Sentry.setExtra('teamId', props.teamId);
    Sentry.captureException(error);

    dispatch({
      payload: {
        message: 'Unable to fetch team.',
        type: 'error'
      },
      type: SHOW_NOTIFICATION
    });
  }, [error, props.teamId]);

  if (!props.teamId) {
    return null;
  }

  if (loading) {
    return <CircularProgress data-testid='spinner' />;
  }

  if (error) {
    handleError();
    return <div data-testid='error' />;
  }

  return (
    <div data-testid='team-messaging-list-container'>
      {data.team && (
        <ConversationListContainer
          teamId={data.team.id}
          teamName={data.team.name || 'N/A'}
          currentWeekNumber={data.team.currentTeamWeekNumber || 0}
          isUserViewingCtm={props.isUserViewingCtm}
        />
      )}
    </div>
  );
}
