import * as React from 'react';
import { Table } from 'semantic-ui-react';
import * as moment from 'moment-timezone';
import { Workout } from '../../api-client';

interface ExerciseTableProps {
  workouts: Workout[];
  timezone: string;
}

class ExerciseTable extends React.PureComponent<ExerciseTableProps> {
  render() {
    return (
      <Table celled striped>
        <Table.Header>
          <Table.HeaderCell>Week</Table.HeaderCell>
          <Table.HeaderCell>Workout</Table.HeaderCell>
          <Table.HeaderCell>Points</Table.HeaderCell>
          <Table.HeaderCell>Local Timestamp</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {this.props.workouts.map((w) => (
            <Table.Row key={w.id}>
              <Table.Cell>Week {w.week_index}</Table.Cell>
              <Table.Cell>{w.name}</Table.Cell>
              <Table.Cell>{w.points_earned}/{w.points_max}</Table.Cell>
              <Table.Cell>{w.occurred_at ?
                moment(w.occurred_at).tz(this.props.timezone).format('LLL z') : 'N/A'}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default ExerciseTable;
