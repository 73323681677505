import * as React from 'react';
import { Segment, Header, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import * as qs from 'query-string';
import { User as PhxUser } from '../../graphql/models';
import { User, Screener } from '../../api-client';
import { RootState } from '../../redux/reducers';
import { selectUser, selectScreener } from '../../redux/selectors';
import { mixpanelTrack } from '../../mixpanel/mixpanel';
import { getApiUrl } from '../../constant';

interface PathwayLinksSegmentProps {
  userId: number;
}

interface ConnectProps {
  user?: User;
  screener?: Screener;
}

type Props = PathwayLinksSegmentProps & ConnectProps;

export const ADMIN_PANEL = `${getApiUrl()}/admin`;

export const trackClick = (page: string) => {
  mixpanelTrack('Admin Panel link clicked', {
    PathwayLinkType: page,
    Origin: '/user/pathway'
  });
};

/** Generate a query string that will filter an admin panel page to the provided user's email address. */
function makeEmailFilterQuery(user: User | PhxUser) {
  if (!user.email) return '?utf8=✓';
  return `?utf8=✓&q%5Buser_email_cont%5D=${encodeURIComponent(user.email)}&commit=Filter&order=id_desc`;
}

/** Generate a query string that will add or overwrite query params from the extraParams object   */
function makeEmailExtrasQuery(filteredQuery: string, extraParams?: object) {
  return extraParams ? `?${qs.stringify({ ...qs.parse(filteredQuery), ...extraParams })}` : filteredQuery;
}

export function makeScreenerUri(user: User, screener?: Screener) {
  return screener ? `${ADMIN_PANEL}/screenings/${screener.id}` : `${ADMIN_PANEL}/users/${user.id}`;
}

/**
 * Generate an admin panel URI with the user's email address pre-applied as a filter.
 *
 * @param {string} page The relative URI to the admin panel page to navigate to.
 * @param {User} user The user to derive the email address from.
 * @param {object} [extraParams] Optional extra query parameters to append to the end of the URI.
 */
export function makeEmailFilteredUri(page: string, user: User | PhxUser, extraParams?: object) {
  const filteredQuery = makeEmailFilterQuery(user);
  return `${ADMIN_PANEL}/${page}${makeEmailExtrasQuery(filteredQuery, extraParams)}`;
}

const PathwayLinksSegment: React.FunctionComponent<Props> = ({ user, screener }) => (
  (user && screener) ?
    <Segment padded="very">
      <Grid columns={4}>
        <Grid.Row>
          <Grid.Column><Header>Links</Header></Grid.Column>
        </Grid.Row>
        <Grid.Column><Header>
          <a target={'_blank'} onClick={() => trackClick('Weight logs')} href={makeEmailFilteredUri(
            'body_measurements', user)}>Weight logs</a>
        </Header></Grid.Column>
        <Grid.Column><Header>
          <a target={'_blank'} onClick={() => trackClick('Article reads')} href={makeEmailFilteredUri(
            'article_reads', user)}>Article reads</a>
        </Header></Grid.Column>
        <Grid.Column><Header>
          <a target={'_blank'} onClick={() => trackClick('Surgery')} href={makeEmailFilteredUri(
            'report_obligations', user, { scope: 'submitted', order: 'week_index_asc' })}>Surgery</a>
        </Header></Grid.Column>
        <Grid.Column><Header>
          <a target={'_blank'} onClick={() => trackClick('Workouts')} href={makeEmailFilteredUri(
            'workouts', user)}>Workouts</a>
        </Header></Grid.Column>
        <Grid.Column><Header>
          <a target={'_blank'} onClick={() => trackClick('PROMs')} href={makeEmailFilteredUri(
            'report_obligations', user, { scope: 'submitted' })}>PROMs</a>
        </Header></Grid.Column>
        <Grid.Column><Header>
          <a target={'_blank'} onClick={() => trackClick('CBT modules')} href={makeEmailFilteredUri(
            'cbt_sessions', user)}>CBT modules</a>
        </Header></Grid.Column>
        <Grid.Column><Header>
          <a target={'_blank'} onClick={() => trackClick('Screener')} href={makeScreenerUri(
            user, screener)}>Screener</a>
        </Header></Grid.Column>
        <Grid.Column><Header>
          <a target={'_blank'} onClick={() => trackClick('Workout sessions')} href={makeEmailFilteredUri(
            'workout_sessions', user)}>Workout sessions</a>
        </Header></Grid.Column>
      </Grid>
    </Segment> : null
);

const mapStateToProps = (state: RootState, props: PathwayLinksSegmentProps): ConnectProps => ({
  user: selectUser(state, props),
  screener: selectScreener(state, props)
});

export default connect(mapStateToProps)(PathwayLinksSegment);
