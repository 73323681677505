import * as React from 'react';
import styled from 'styled-components';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { PRIMARY_MAIN, WHITE } from '../../colors';
import { LatestShipment } from '../../graphql/models/User';
import ShipmentStatus from '../phx/user/ShipmentStatus';

interface OnboardingTrackingProps {
  latestShipment?: LatestShipment | null;
}

const StyledList = styled(List)`
  && {
    width: 100%;
    display: flex;
    padding-top: 0;
    background-color: ${WHITE}
  }
`;

const StyledListItemText = styled(ListItemText)`
  && {
    color: ${PRIMARY_MAIN}
  }
`;

// There's a TS error using styled-components with material-ui ListItem
const listItemStyles = {
  flexFlow: 'column wrap',
  alignItems: 'start',
  paddingLeft: 20,
  paddingTop: 0
};

const getTrackingListItemText = (latestShipment: LatestShipment | null | undefined) => {
  if(!latestShipment?.trackingNumber) return '';
  return latestShipment?.trackingUrl ?
    <a href={latestShipment.trackingUrl} target="_blank">{latestShipment.trackingNumber}</a> :
    latestShipment.trackingNumber;
};

const OnboardingTracking: React.FC<OnboardingTrackingProps> = ({ latestShipment }) => (
  <>
    <StyledList dense>
      <ListItem style={listItemStyles}>
        <ListItemText secondary="Kit Status"/>
        <ListItemText
          data-testid="trackingStatus"
          primary={
            <ShipmentStatus shipmentStatus={latestShipment?.trackingStatus} />
          }
        />
      </ListItem>
      <ListItem style={listItemStyles}>
        <ListItemText secondary="Tracking Number"/>
        <StyledListItemText
          data-testid="trackingNumber"
          primary={getTrackingListItemText(latestShipment)}
        />
      </ListItem>
    </StyledList>
  </>
);

export default OnboardingTracking;
