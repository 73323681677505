import * as React from 'react';
import * as moment from 'moment-timezone';
import { Grid, Segment, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { DEFAULT_TIMEZONE } from '../../utils/user/timezone';
import TaskTitle from '../tasks/TaskTitle';
import {
  User,
  RuleDrivenTask,
  TaskStatuses,
  TaskKind
} from '../../api-client/interfaces';
import { getKindColor } from '../../utils/tasks';
import { TaskValues, TaskExtras } from './TaskCards/RuleDrivenTaskContent';

interface HistoryTaskProps {
  task: RuleDrivenTask;
  user: User;
}

const getUserFriendlyTaskStatus = (status?: TaskStatuses | null) => {
  switch (status) {
    case TaskStatuses.Completed:
      return 'Completed';
    case TaskStatuses.Rejected:
      return 'Won\'t do';
    case TaskStatuses.Obsolete:
      return 'Automatically removed';
    case TaskStatuses.Expired:
      return 'Expired';
    default:
      return status;
  }
};

const StatusDiv = styled.div`
  font-size: 0.8em;
  color: grey;
`;
export const StatusColumn: React.FunctionComponent<{ task: RuleDrivenTask; user: User }> = ({ task, user }) => (
  <Grid.Column width={5}>
    <StatusDiv>
      Status: <b>{getUserFriendlyTaskStatus(task.status)}</b>
    </StatusDiv>
    <StatusDiv>
      Resolved at:{' '}
      <b>
        {task.updatedAt
          ? moment.utc(task.updatedAt)
            .tz(user.timezone || DEFAULT_TIMEZONE)
            .format('LLL z')
          : 'N/A'}
      </b>
    </StatusDiv>
  </Grid.Column>
);

const ResolutionDiv = styled.div`
  font-size: 0.8em;
  color: grey;
`;
const ResolutionNote: React.FunctionComponent<{ resolutionNote: string }> = ({
  resolutionNote
}) => (
  <Grid.Column>
    <ResolutionDiv>
      <b>Comment:</b>
    </ResolutionDiv>
    {resolutionNote}
  </Grid.Column>
);

const StyledContainer = styled.div`
  padding-bottom: 0;
`;
export const StyledKindMessage = styled.h2`
  font-size: 1em;
  color: ${(props: { kind: TaskKind }) => getKindColor(props.kind)} !important;
  display: inline;
`;

const TaskContent: React.FunctionComponent<HistoryTaskProps> = ({ task, user }) => {
  const { extras } = task;
  return (
    <StyledContainer>
      <Grid.Row>
        <Header as="h1" size="small">
          <TaskTitle kind={task.kind as string} />
        </Header>
        <TaskValues extras={extras} kind={task.kind} />
        <TaskExtras kind={task.kind} extras={task.extras} />
      </Grid.Row>
    </StyledContainer>
  );
};

const HistoryTaskCard: React.FunctionComponent<HistoryTaskProps> = props => {
  const { task, user } = props;
  return (
    <Grid
      divided="vertically"
      as={Segment}
      columns={2}
      style={{ padding: '1em' }}
    >
      <Grid.Row>
        <Grid.Column width={11}>
          <TaskContent task={task} user={user} />
        </Grid.Column>
        <StatusColumn task={task} user={user} />
      </Grid.Row>
      <Grid.Row style={{ padding: 0 }} columns={1}>
        {task.resolutionNote && (
          <ResolutionNote resolutionNote={task.resolutionNote} />
        )}
      </Grid.Row>
    </Grid>
  );
};

export default HistoryTaskCard;
