import * as React from 'react';
import styled from 'styled-components';
import { InputLabel, FormControl, Typography, Select, MenuItem, Divider, ListSubheader } from '@material-ui/core';
import { CarePlanActionOptions, CarePlanItemActionOptions, OptionWrapper } from './UpdateCarePlanContainer';

interface UpdateCarePlanModalContentProps {
  onSelectOption(option: CarePlanActionOptions | CarePlanItemActionOptions | null): void;
  selectedOption: CarePlanActionOptions | CarePlanItemActionOptions | null;
  updateOptions: OptionWrapper[];
}

const StyledDiv = styled.div`
  padding-bottom: 16px;
`;

const StyledFormControl = styled(FormControl)`
  .MuiSelect-select {
    min-width: 120px;
  }
`;

const StyledListSubheader = styled(ListSubheader)`
  padding-top: 6px;
  padding-bottom: 6px;
`;

const optionMap = (options: OptionWrapper[]) => (
  options.map((item) => {
    switch (item.type) {
      case 'divider':
        return (
          <Divider key={item.key}/>
        );
      case 'subheader':
        return (
          <StyledListSubheader key={item.key}>
            <Typography variant='subtitle1'>
              {item.text}
            </Typography>
          </StyledListSubheader>
        );
      default:
        return (
          <MenuItem key={item.key} value={item.text}>
            {item.text}
          </MenuItem>
        );
    }
  })
);

const UpdateCarePlanModalContent: React.FC<UpdateCarePlanModalContentProps> = ({
  updateOptions,
  onSelectOption,
  selectedOption
}) => {
  const handleSelectCarePlanUpdateOption = (event: React.ChangeEvent<{ value: unknown }>) =>
    onSelectOption(event.target.value as CarePlanActionOptions);

  return (
    <>
      <StyledDiv>
        <Typography variant="body1" color="textSecondary" align="left">
          Select the update you'd like to make:
        </Typography>
      </StyledDiv>
      <StyledFormControl>
        <InputLabel>Update</InputLabel>
        <Select
          value={selectedOption || ''}
          onChange={handleSelectCarePlanUpdateOption}
        >
          { optionMap(updateOptions) }
        </Select>
      </StyledFormControl>
    </>
  );
};

export default UpdateCarePlanModalContent;
