import * as React from 'react';
import { connect } from 'react-redux';
import { Typography, TextField } from '@material-ui/core';
import { isEqual } from 'lodash';
import { mixpanelTrack } from '../../mixpanel/mixpanel';
import { actionCreators as tasksActions } from '../../redux/actions/tasks';
import { RootState } from '../../redux/reducers';
import { updateTaskUserAndCount } from '../../redux/actions/ui-state';
import { DispatchMap } from '../../redux/actions/interfaces';
import { Admin, TaskKind } from '../../api-client/interfaces';
import { selectUserCountByTaskKind, isClinicalTaskKind, selectAdmin } from '../../redux/selectors';
import './TaskCompleteModal.scss';
import ModalWrapper from '../../components/modal/ModalWrapper';

export interface TaskCompleteModalProps {
  taskId?: number;
  userId?: number;
  taskIds?: number[];
  taskKind?: TaskKind | null;
  inProfile?: boolean;
  open: boolean;
  isRejection?: boolean;
  onClose(completed?: boolean): void;
  triggerUiComplete?(msg: string): void;
}

type ConnectProps = DispatchMap<{
  completeClinical: typeof tasksActions.complete;
  completeOutreach: typeof tasksActions.completeOutreachTask;
  taskKindUserCount?: number;
  updateTaskUserCount: typeof updateTaskUserAndCount;
  admin: Admin;
}>;

interface DefaultProps {
  open: boolean;
}

interface State {
  message: string;
  isSubmissionDisabled: boolean;
  error: boolean;
}

type Props = TaskCompleteModalProps & ConnectProps & DefaultProps;

// TODO: CA-33 wire this up in task overview
class TaskCompleteModal extends React.PureComponent<Props, State> {
  commentRef: React.RefObject<HTMLTextAreaElement> | null;

  state: State = { message: '', isSubmissionDisabled: false, error: false };
  static defaultProps: DefaultProps = {
    open: false
  };

  constructor(props: Props) {
    super(props);
    this.commentRef = React.createRef();
  }

  focusComment = () => {
    this.commentRef?.current?.focus();
  };

  handleCommentInputChange = (el: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ message: el.currentTarget.value });
  };

  handleCancelSubmit = () => {
    this.props.onClose();
    this.setState({ message: '' });
  };

  handleSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    this.submit();
  };

  handlePatchResolve = () => {
    this.props.onClose(true);
  };

  reportCompleteTask = (rejectStatus?: boolean) => {
    let origin = window.location.pathname || '/task';
    if (origin.indexOf('/user') >= 0) {
      origin = '/user';
    }
    rejectStatus ?
      mixpanelTrack('Task Closed', {
        TaskType: this.props.taskKind,
        CompletionMethod: 'Won\'t Do',
        Origin: origin,
        UserId: this.props.userId,
        AdminId: this.props.admin.id
      }) :
      mixpanelTrack('Task Closed', {
        TaskType: this.props.taskKind,
        CompletionMethod: 'Completed',
        Origin: origin,
        UserId: this.props.userId,
        AdminId: this.props.admin.id
      });
  };

  submit = () => {
    const snackBarMsg = this.props.isRejection ? 'Won\'t Do.' : 'Task completed with note.';

    const completeTask = isClinicalTaskKind(this.props.taskKind) ?
      this.props.completeClinical : this.props.completeOutreach;

    this.props.triggerUiComplete && this.props.triggerUiComplete(snackBarMsg);

    completeTask(this.state.message, this.props.taskId,
      this.props.isRejection).then(() => {
      this.props.onClose(true);
      this.setState({
        error: false
      });
      this.reportCompleteTask(this.props.isRejection);
      if (
        this.props.taskKindUserCount &&
        this.props.taskKind &&
        this.props.userId
      ) {
        this.props.updateTaskUserCount({
          todoKey: this.props.taskKind,
          count: this.props.taskKindUserCount - 1,
          userId: this.props.userId
        });
      }
    }).catch(() => this.setState({
      error: true
    }));
  };

  componentDidUpdate() {
    this.props.open && this.focusComment();
  }

  requiredKindComment: boolean = !!this.props.isRejection || this.props.taskKind === TaskKind.surgeryIncr;

  generateModalContent = () => {
    const modalContent = {
      title: this.props.isRejection ? 'Won\'t do' : 'Mark task as complete?',
      body: (
        <div key={this.props.isRejection ? 'Won\'t do' : 'Mark task as complete?'}>
          {this.state.error &&
            <Typography color='error'>An error occurred while completing the task.</Typography>}
          <div className='comment'>
            <Typography
              className={`comment__label ${this.requiredKindComment ? 'comment__label--required' : ''}`}
              style={{ paddingBottom: '10px' }}
            >
              Leave a Comment
            </Typography>
            <TextField
              multiline={true}
              rows={3}
              variant={'outlined'}
              required={this.requiredKindComment}
              onChange={this.handleCommentInputChange}
            />
          </div>
        </div>
      ),
      confirmBtnText: 'Complete',
      dismissBtnText: 'Nevermind'
    };
    return modalContent;
  };

  render() {
    return (
      <ModalWrapper
        modalContent={this.generateModalContent()}
        openModal={this.props.open}
        disableConfirmButton={this.requiredKindComment && this.state.message.length === 0}
        onConfirm={this.submit}
        onDismiss={this.handleCancelSubmit}
      />
    );
  }
}

const mapDispatchToProps = {
  completeClinical: tasksActions.complete,
  completeOutreach: tasksActions.completeOutreachTask,
  updateTaskUserCount: updateTaskUserAndCount
};

const mapStateToProps = (state: RootState, props: TaskCompleteModalProps) => ({
  taskKindUserCount: selectUserCountByTaskKind(state, {
    kind: props.taskKind,
    userId: props.userId
  }),
  admin: selectAdmin(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(TaskCompleteModal, isEqual));
