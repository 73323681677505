import { useMutation } from '@apollo/client';
import * as React from 'react';
import 'react-dates/initialize';
import '../../react-dates-overrides.css';
import * as moment from 'moment-timezone';
import * as Sentry from '@sentry/browser';

import styled from 'styled-components';
import { RadioButtonGroup, Box, Button, Heading, Text, Grid } from 'grommet';
import { useDispatch } from 'react-redux';

import { DateRangePicker } from 'react-dates';
import { StatusNames, DateRange } from '../../api-client/';
import { HINGE_BLUE, BACKGROUND_GRAY } from '../../colors';
import MessageField from './MessageField';
import { User, UserStatus } from '../../graphql/models';
import { isNullOrUndefined } from '../../utils/helpers';
import { SET_USER_STATUS } from '../../graphql/mutations';
import { GET_USER_STATUS } from '../../graphql/queries';
import { StatusTitleOptions } from '../../containers/communications/UserStatusFormContainer';
import { mixpanelTrack } from '../../mixpanel/mixpanel';
import { SHOW_NOTIFICATION } from '../../redux/constants';
import { DispatchThunk } from '../../redux/actions';

export interface UserStatusFormProps {
  title: StatusTitleOptions;
  userStatus: UserStatus;
  setShowStatusModal(status: boolean): void;
}

interface CachedQueryResponse {
  user: User;
}

interface ValidatedDateRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

interface CachedQueryVariables {
  id?: string | null;
}

interface SetUserMutationData {
  user: User;
  userStatus: UserStatus;
}

interface MutationResponse {
  setUserStatus: SetUserMutationData;
}

type SetUserStatusVariables = UserStatus;


type FocusedInput = 'startDate' | 'endDate';

export const NameOptions: { [key in StatusNames]: string } = {
  [StatusNames.vacation]: '🌴 On vacation',
  [StatusNames.sick]: '🤒 Sick',
  [StatusNames.family_emergency]: '⚠️ Family emergency',
  [StatusNames.technical_issue]: '🔧 Tech issue',
  [StatusNames.clinical_issue]: '🏥 Clinical issue',
  [StatusNames.other]: '❗️Other'
};

const OptionsNameMap = {
  [NameOptions[StatusNames.vacation]]: StatusNames.vacation,
  [NameOptions[StatusNames.sick]]: StatusNames.sick,
  [NameOptions[StatusNames.technical_issue]]: StatusNames.technical_issue,
  [NameOptions[StatusNames.clinical_issue]]: StatusNames.clinical_issue,
  [NameOptions[StatusNames.family_emergency]]: StatusNames.family_emergency,
  [NameOptions[StatusNames.other]]: StatusNames.other
};

export const StatusNameOptions = styled(RadioButtonGroup)`
  div {
    height: 20px;
    width: 20px;
  }
  svg {
    height: 20px;
    width: 20px;
  }
  font-size: 14px;
`;

const isValidDates = (
  range: DateRange | ValidatedDateRange
): range is ValidatedDateRange => (
  !isNullOrUndefined(range.startDate) && !isNullOrUndefined(range.endDate)
);

const DEFAULT_NAME_AND_OPTIONS: [StatusNames, string] = [StatusNames.vacation, NameOptions.vacation];

const DEFAULT_DATE = moment();

const UserStatusForm: React.FC<UserStatusFormProps> = ({
  title,
  userStatus,
  setShowStatusModal
}) => {
  const dispatchThunk: DispatchThunk = useDispatch();

  const [nameAndOption, setNameAndOption] = React.useState<{
    name: StatusNames;
    option: string;
  }>({
    name: userStatus.name || DEFAULT_NAME_AND_OPTIONS[0],
    option: userStatus.name ? NameOptions[userStatus.name] : DEFAULT_NAME_AND_OPTIONS[1]
  });
  const [submitDisabled, toggleSubmitDisable] = React.useState(false);
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: userStatus.startsOn ? moment(userStatus.startsOn) : DEFAULT_DATE,
    endDate: userStatus.endsOn ? moment(userStatus.endsOn) : DEFAULT_DATE
  });
  const [validDates, setValidDates] = React.useState<ValidatedDateRange>({
    startDate: userStatus.startsOn ? moment(userStatus.startsOn) : DEFAULT_DATE,
    endDate: userStatus.endsOn ? moment(userStatus.endsOn) : DEFAULT_DATE
  });
  const [focused, setFocusedInput] = React.useState<FocusedInput | null>(null);
  const [description, setDescription] = React.useState(userStatus.description);

  const [setUserStatus, { error }] = useMutation<MutationResponse, SetUserStatusVariables>(
    SET_USER_STATUS,
    {
      variables: {
        userId: userStatus.userId,
        name: nameAndOption.name,
        description,
        startsOn: validDates.startDate.toString(),
        endsOn: validDates.endDate.toString()
      },
      update(cache, { data }) {
        if (data?.setUserStatus) {
          const response = data.setUserStatus;

          const cachedData = cache.readQuery<CachedQueryResponse, CachedQueryVariables>({
            query: GET_USER_STATUS,
            variables: { id: response.userStatus.userId }
          });

          if (cachedData) {
            const cachedUser = cachedData.user;
            cache.writeQuery({
              query: GET_USER_STATUS,
              variables: { id: userStatus.userId },
              data: { user: { ...cachedUser, userStatus: response.userStatus } }
            });
          }
        }
      }
    }
  );

  const handleSelectNameOption = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const selectedNameOption = event.target.value;
    setNameAndOption({
      name: OptionsNameMap[selectedNameOption],
      option: selectedNameOption
    });
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => setDescription(event.target.value);

  const handleSetDateRange = (range: DateRange) => {
    setDateRange(range);

    if (isValidDates(range)) {
      setValidDates(range);
      toggleSubmitDisable(false);
    } else {
      toggleSubmitDisable(true);
    }
  };

  const handleError = (err: string) => {
    dispatchThunk({
      payload: {
        message: err,
        httpStatus: err || '500',
        type: 'error'
      },
      type: SHOW_NOTIFICATION
    });
  };

  const handleCancel = () => setShowStatusModal(false);

  const handleSubmit = () => {
    setUserStatus().then(() => {
      mixpanelTrack('User status set', { Origin: '/messaging', StatusType: nameAndOption.name });

      setShowStatusModal(false);
    }).catch((e) => {
      Sentry.captureException(e);
      handleError('Unable to submit status');
    });
  };

  if (error) handleError('Unable to save user status');

  return (
    <Box pad={{ horizontal: 'medium', vertical: 'small' }} height="500px" width="medium">
      <Heading level={4}>{title}</Heading>
      <Grid
        rows={['200px', '80px', '120px', 'auto']}
        columns={['auto']}
        areas={[
          { name: 'name-options', start: [0, 0], end: [0, 0] },
          { name: 'date', start: [0, 1], end: [0, 1] },
          { name: 'description', start: [0, 2], end: [0, 2] },
          { name: 'buttons', start: [0, 3], end: [0, 3] }
        ]}
      >
        <StatusNameOptions
          gridArea="name-options"
          name="statusNameSelect"
          options={[
            NameOptions[StatusNames.vacation],
            NameOptions[StatusNames.sick],
            NameOptions[StatusNames.family_emergency],
            NameOptions[StatusNames.technical_issue],
            NameOptions[StatusNames.clinical_issue],
            NameOptions[StatusNames.other]
          ]}
          value={nameAndOption.option}
          onChange={handleSelectNameOption}
        />
        <Box pad={{ top: 'small', bottom: 'xsmall' }} gridArea="date" direction="row" justify="between">
          <Box direction="column" flex justify="between">
            <Box width={'80%'} direction="row" flex justify="stretch">
              <div style={{ width: '50%', fontSize: '14px' }}>Start</div>
              <div style={{ width: '50%', fontSize: '14px' }}>End</div>
            </Box>
            <DateRangePicker
              startDateId="starts-on"
              startDate={dateRange.startDate}
              endDateId="ends-on"
              endDate={dateRange.endDate}
              onDatesChange={handleSetDateRange}
              focusedInput={focused}
              onFocusChange={setFocusedInput}
              minimumNights={0}
              customArrowIcon={' '}
              required
            />
          </Box>
        </Box>
        <Box gridArea="description" pad={{ vertical: 'xsmall' }}>
          <Box pad={{ vertical: 'xxsmall' }}>
            <Text size="small">Note</Text>
          </Box>
          <Box overflow="none" style={{ maxHeight: '120px' }}>
            <MessageField
              value={description || ''}
              onChange={handleDescriptionChange}
            />
          </Box>
        </Box>
        <Box
          style={{ height: '40px' }}
          direction="row"
          alignContent="center"
          justify="end"
        >
          <Button
            style={{ fontSize: '12px', marginRight: '15px' }}
            type="button"
            color={BACKGROUND_GRAY}
            label="CANCEL"
            onClick={handleCancel}
          />
          <Button
            id="submit-status-button"
            style={{ fontSize: '12px' }}
            primary
            color={HINGE_BLUE}
            type="submit"
            label="SAVE"
            disabled={submitDisabled}
            onClick={handleSubmit}
          />
        </Box>
      </Grid>
    </Box >
  );
};

export default UserStatusForm;
