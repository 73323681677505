import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import * as React from 'react';
import { Form, Button, Text, Box } from 'grommet';

import { selectUser, selectAdmin } from '../../redux/selectors';
import { RootState } from '../../redux/reducers';
import { actionCreators } from '../../redux/actions/messages.v2';
import { DispatchThunk } from '../../redux/actions';
import MessageField from '../../components/communications/MessageField';
import { HINGE_BLUE } from '../../colors';
import { CreateMessageV2Params } from '../../api-client/endpoints';
import { mixpanelTrack } from '../../mixpanel/mixpanel';
import ScheduleMessageModal from '../../components/communications/ScheduleMessageModal';
import { DEFAULT_TIMEZONE } from '../../utils/user/timezone';

export const MAX_CHARACTER_COUNT = 160;

interface MessageComposerContainerProps {
  userId: number;
}

const MessageComposerContainerV2: React.FC<MessageComposerContainerProps> = ({
  userId
}) => {
  const [characterCount, setCharacterCount] = React.useState(MAX_CHARACTER_COUNT);
  const [composedMessageBody, setComposedMessageBody] = React.useState('');
  const [textDisabled, setTextDisabled] = React.useState(true);
  const [showScheduleModal, setShowScheduleModal] = React.useState(false);

  const dispatch: DispatchThunk = useDispatch();

  const user = useSelector((state: RootState) => selectUser(state, { userId }));
  const admin = useSelector((state: RootState) => selectAdmin(state));

  const handleCharacterCount = (composedBody: string) => {
    setCharacterCount(MAX_CHARACTER_COUNT - composedBody.length);
  };

  const bodyUpdated = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const composedBody = e.target.value;

    setComposedMessageBody(composedBody);
    composedBody.length ? setTextDisabled(false) : setTextDisabled(true);
    handleCharacterCount(composedBody);
  };

  const handleSubmitMessage = () => {
    submitMessage(); // handleSubmitMessage is passed an 'event' arg, don't really need it
  };

  const handleScheduleMessage = (scheduledFor: moment.Moment) => {
    submitMessage(scheduledFor);
  };

  const submitMessage = (scheduledFor?: moment.Moment | void) => {
    if (!user.uuid || !user.phoneNumber) {
      throw new Error('need a uuid and phone number');
    }

    const message: CreateMessageV2Params = {
      userId,
      body: composedMessageBody,
      senderUuid: admin.uuid,
      recipientUuid: user.uuid,
      recipientNumber: user.phoneNumber,
      sendAt: (scheduledFor) ? scheduledFor.format('YYYY-MM-DD H:mm') : null
    };

    setTextDisabled(true);
    dispatch(actionCreators.createMessage(message)).then(() => {
      mixpanelTrack(`${scheduledFor ? 'Scheduled' : 'Sent'} Message`, {
        Origin: '/messaging'
      });

      setComposedMessageBody('');
      handleCharacterCount('');
      setTextDisabled(true);
      setShowScheduleModal(false);
    });
  };

  const handleScheduleClick = () => setShowScheduleModal(true);

  return (
    <Box justify="center" align="center">
      {showScheduleModal && (
        <ScheduleMessageModal
          userTimezone={user?.timezone || DEFAULT_TIMEZONE}
          scheduleMessage={handleScheduleMessage}
          showModal={showScheduleModal}
          setShowModal={setShowScheduleModal}
        />
      )}

      <Form style={{ width: '408px' }}>
        <MessageField
          onChange={bodyUpdated}
          value={composedMessageBody}
          focusIndicator={textDisabled}
        />

        <Box
          gap="50%"
          alignContent="around"
          width="100%"
          align="stretch"
          direction="row"
          style={{ paddingTop: '5px' }}
        >
          <Box justify="start">
            <Text size="small">{`Character count: ${characterCount}`}</Text>
          </Box>
          <Box justify="end" direction="row">
            <Button
              id="schedule-button"
              style={{ fontSize: '12px', marginRight: '15px' }}
              color={HINGE_BLUE}
              plain={true}
              label="SCHEDULE"
              disabled={textDisabled}
              onClick={handleScheduleClick}
            />
            <Button
              style={{ color: 'white', fontSize: '12px' }}
              primary
              color={HINGE_BLUE}
              type="submit"
              label="SEND"
              disabled={textDisabled}
              onClick={handleSubmitMessage}
            />
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

export default MessageComposerContainerV2;
