import * as React from 'react';

import { Table, TableBody, Box } from 'grommet';
import { FollowUps, Impact } from '../../graphql/models';
import {
  ScreenerHeader,
  ScreenerRow,
  ScreenerCell,
  ScreenerText,
  ScreenerField,
  SCREENER_RESPONSES
} from '../../containers/communications/ScreenerManager';
import { isNullOrUndefined } from '../../utils/helpers';
import { PerisurgicalDetail } from '../../graphql/models/bff/PerisurgicalDetail';
import UpcomingSurgeries from './UpcomingSurgeries';
import PerisurgicalDetails from './PerisurgicalDetails';

export interface ScreenerSurgeryProps {
  follow_ups: Pick<FollowUps, 'upcoming_surgeries' | 'future_surgery'>;
  impact?: Impact | null;
  isPerisurgicalMember: boolean;
  name?: string;
  perisurgicalDetails: PerisurgicalDetail[];
}

const ScreenerBackground: React.FC<ScreenerSurgeryProps> = ({
  impact, follow_ups, name, perisurgicalDetails, isPerisurgicalMember
}) => (
  <Box style={{ padding: '6px 20px' }}>
    <ScreenerHeader fill>
      <strong>Surgery</strong>
    </ScreenerHeader>
    <Table>
      <TableBody>{
        name === 'multi_shim'?
          (<UpcomingSurgeries impact={impact} follow_ups={follow_ups} />)
          : perisurgicalDetails.length ? (
            <PerisurgicalDetails perisurgicalDetails={perisurgicalDetails} />
          ) : (
            <UpcomingSurgeries impact={impact} follow_ups={follow_ups} />
          )
      }

      {!isPerisurgicalMember && (
        <ScreenerRow>
          <ScreenerField size='1/4'>{`Considering future ${name} surgery`}</ScreenerField>
          <ScreenerCell>
            {isNullOrUndefined(impact) || isNullOrUndefined(impact.future_surgery) ? (
              '-'
            ) : (
              <Box direction='column' flex>
                <ScreenerText>{`${SCREENER_RESPONSES[impact.future_surgery]}`}</ScreenerText>
                <ScreenerText>{follow_ups.future_surgery}</ScreenerText>
              </Box>
            )}
          </ScreenerCell>
        </ScreenerRow>
      )}
      {!isPerisurgicalMember && (
        <ScreenerRow style={{ border: 'none' }}>
          <ScreenerField size='1/4'>{`Chance of ${name} surgery in 1 year`}</ScreenerField>
          <ScreenerCell>
            {isNullOrUndefined(impact) || isNullOrUndefined(impact.future_surgery)
              ? '-'
              : `${impact.surgery_chance_next_year}%`}
          </ScreenerCell>
        </ScreenerRow>
      )}
      </TableBody>
    </Table>
  </Box>
);

export default ScreenerBackground;
