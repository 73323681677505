export const RED = '#FB4D3D';
export const ORANGE = '#F07038';
export const YELLOW = '#FFC43D';
export const OLIVE = '#B5CC18';
export const GREEN = '#56BA47';
export const TEAL = '#06D6A0';
export const BLUE = '#4EBCD4';
export const LIGHT_BLUE = 'rgba(204, 234, 250, 0.32)';
export const VIOLET = '#6435C9';
export const PURPLE = '#9A4ED1';
export const PINK = '#EF476F';
export const BROWN = '#A5673F';
export const GREY = '#CED3DC';
export const BLACK = '#1B1C1D';
export const NAV_BAR = '#628395';
export const DARKER_NAV_BAR = '#324148';
export const LIGHTERGREY = '#F9F9F9';
export const DARKERGREY = '#ededed';
export const GROMMET_LIGHT_GREY = '#f8f8f8';
export const LIGHTERRED = '#FFE5E2';
export const PHX_FLAG = '#FF1493';
export const BACK_TAG = '#B2D6FF';
export const KNEE_TAG = '#B5CC18';
export const WHITE = '#FFFFFF';
export const HINGE_BLUE = '#00BCD4';
export const BACKGROUND_GRAY = '#90A4AE';
export const BORDER_GRAY = '#CFD8DC';
export const LABEL_GRAY = '#455A64';
export const LABEL_BLACK = '#263238';
export const SCREENER_HEADER_GRAY = '#ECEFF1';
export const HINGE_LINK = '#008279';
export const CTM_DEFAULT_PRIMARY = '#008279';
export const BACKGROUND_RED = '#FCEFEF';
export const LIGHT_GREY_BORDER = 'rgb(207, 216, 220)';
export const BOX_SHADOW = 'rgba(38, 50, 56, 0.4)';
export const LOADING_BLUE = '#628495';

// Material Theme
export const PRIMARY = '#1976d2';
export const PRIMARY_MAIN = '#1976d2';
export const PRIMARY_DISABLED = '#000000';
export const SECONDARY = HINGE_BLUE;
export const BUTTON_DISABLED_TEXT = '#93999C';
export const INDIGO_500 = '#3F51B5';
export const TEXT_SECONDARY = 'rgba(0, 0, 0, 0.54)';
export const SUCCESS_ICON = '#388E3C';
export const SUCCESS_TEXT = '#1E4620';
export const WARNING_TEXT_ONE = '#663C00';
export const WARNING_TEXT_TWO = '#611A15';
export const WARNING_DARK = '#f57c00';
export const ERROR = '#f44336';
export const ERROR_ICON = '#D32F2F';
export const ERROR_TEXT = WARNING_TEXT_TWO;
export const ERROR_BG = 'rgb(253, 236, 234)';

// GROMMET
export const TAB_ACTIVE = '#0C9DB1';
export const TAB_INACTIVE = '#B7B7B7';

// TODO: BAT-1214 Update specific task colors to map to material ui palette
// Task color semantic overrides
export const TASK_ORANGE = '#F2711C';
export const TASK_GREEN = '#21BA45';
export const TASK_YELLOW = '#F4BA1C';
export const TASK_LIGHT_GREEN = '#C8EA96';
