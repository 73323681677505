import * as React from 'react';
import {
  Participant as ParticipantType,
  AudioTrack,
  VideoTrack
} from 'twilio-video';
import {
  convertAudioPublicationsToTracks,
  convertVideoPublicationsToTracks
} from './utils';
import { useAttachTrackToRef } from './hooks';
import { MicOff, VideocamOff } from '@material-ui/icons';

interface ParticipantProps {
  participant: ParticipantType;
  participantType: 'remote' | 'local';
}

const Participant = ({ participant, participantType }: ParticipantProps) => {
  const [remoteAudioEnabled, setRemoteAudioEnabled] = React.useState<boolean>(true);
  const [remoteVideoEnabled, setRemoteVideoEnabled] = React.useState<boolean>(true);
  const [videoTracks, setVideoTracks] = React.useState<Array<VideoTrack>>([]);
  const [audioTracks, setAudioTracks] = React.useState<Array<AudioTrack>>([]);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const audioRef = React.useRef<HTMLAudioElement>(null);

  React.useEffect(() => {
    if (participantType === 'remote') {
      participant.audioTracks.forEach(audioTrack => {
        audioTrack.on('trackDisabled', () => setRemoteAudioEnabled(false));
        audioTrack.on('trackEnabled', () => setRemoteAudioEnabled(true));
      });
    }
    return () => {
      if (participantType === 'remote') {
        participant.audioTracks.forEach(audioTrack => {
          audioTrack.removeAllListeners();
        });
      }
    };
  }, [participant.audioTracks]);

  React.useEffect(() => {
    if (participantType === 'remote') {
      participant.videoTracks.forEach(videoTrack => {
        videoTrack.on('trackDisabled', () => setRemoteVideoEnabled(false));
        videoTrack.on('trackEnabled', () => setRemoteVideoEnabled(true));
      });
    }
    return () => {
      if (participantType === 'remote') {
        participant.videoTracks.forEach(videoTrack => {
          videoTrack.removeAllListeners();
        });
      }
    };
  }, [participant.videoTracks]);

  React.useEffect(() => {
    const trackSubscribed = (track: AudioTrack | VideoTrack) => {
      if (track.kind === 'video') {
        setVideoTracks(currentVideoTracks => [...currentVideoTracks, track]);
      } else {
        setAudioTracks(currentAudioTracks => [...currentAudioTracks, track]);
      }
    };
    const trackUnsubscribed = (track: AudioTrack | VideoTrack) => {
      if (track.kind === 'video') {
        setVideoTracks(currentVideoTracks => currentVideoTracks.filter(v => v !== track));
      } else {
        setAudioTracks(currentAudioTracks => currentAudioTracks.filter(a => a !== track));
      }
    };
    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);
    const existingVideoTracks = convertVideoPublicationsToTracks(participant.videoTracks);
    const existingAudioTracks = convertAudioPublicationsToTracks(participant.audioTracks);
    setVideoTracks(existingVideoTracks);
    setAudioTracks(existingAudioTracks);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useAttachTrackToRef({ tracks: videoTracks, ref: videoRef });
  useAttachTrackToRef({ tracks: audioTracks, ref: audioRef });

  const renderRemoteParticipant = () => (
    <React.Fragment>
      {!remoteAudioEnabled && (
        <div className={`muted-indicator ${!remoteVideoEnabled && 'muted-indicator-off'}`}>
          <MicOff className='muted-icon' />
        </div>
      )}
      <video
        ref={videoRef}
        autoPlay={true}
        id={`${participant.identity}-video`}
        className={`participant-video ${remoteVideoEnabled ? 'remote-video-visible' : 'remote-video-hidden'}`}
      />
      {!remoteVideoEnabled && <VideocamOff className='video-off-icon' />}
    </React.Fragment>
  );

  return (
    <div className={`participant ${!remoteVideoEnabled ? 'video-off-container' : ''}`}>
      {
        participantType === 'local' && (
          <div className="crop">
            <video ref={videoRef} autoPlay={true} id={`${participant.identity}-video`} className='participant-video'/>
          </div>
        )
      }
      {participantType === 'remote' && renderRemoteParticipant()}
      <audio ref={audioRef} autoPlay={true} id={`${participant.identity}-audio`}/>
    </div>
  );
};

export default Participant;
