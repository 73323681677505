import {
  RED,
  HINGE_BLUE,
  TASK_GREEN,
  TASK_ORANGE,
  TASK_YELLOW,
  TASK_LIGHT_GREEN,
  INDIGO_500,
  BACKGROUND_GRAY
} from '../colors';
import { TaskKind, TaskStatuses } from '../api-client/interfaces';

// TODO: BAT-1214 Update specific task colors to map to material ui palette
const KIND_SEMANTIC_COLOR: { [kind: string]: string } = {
  [TaskKind.painUptick]: 'red',
  [TaskKind.surgeryIncr]: 'blue',
  [TaskKind.reEngager]: 'green',
  [TaskKind.healthLogNote]: 'orange',
  [TaskKind.newHighUtilizationUser]: 'violet'
};

const KINDCOLOR: { [kind: string]: string } = {
  [TaskKind.painUptick]: RED,
  [TaskKind.surgeryIncr]: HINGE_BLUE,
  [TaskKind.reEngager]: TASK_GREEN,
  [TaskKind.healthLogNote]: TASK_ORANGE,
  [TaskKind.newHighUtilizationUser]: INDIGO_500,
  [TaskKind.inactiveThisWeek]: TASK_YELLOW,
  [TaskKind.inactiveTwoWeeks]: TASK_YELLOW,
  [TaskKind.inactiveThreeWeeks]: TASK_YELLOW,
  [TaskKind.inactiveFourWeeks]: TASK_YELLOW,
  [TaskKind.inactiveFiveWeeks]: TASK_YELLOW,
  [TaskKind.oneWeekInactive]: TASK_YELLOW,
  [TaskKind.firstEtSessionThisWeek]: TASK_LIGHT_GREEN,
  [TaskKind.thirdEtSessionThisWeek]: TASK_YELLOW,
  [TaskKind.yearTwoInactiveLastWeek]: TASK_YELLOW,
  [TaskKind.yearTwoActiveLastWeek]: TASK_YELLOW,
  [TaskKind.yearTwoActiveThisWeek]: TASK_YELLOW,
  [TaskKind.ensoYearTwo]: TASK_YELLOW
};

// TODO: BAT-1214 Update specific task colors to map to material ui palette
export const getKindColor = (kind?: TaskKind | null, options?: { isSemantic: boolean }) => {
  if (options?.isSemantic) {
    return kind ? KIND_SEMANTIC_COLOR[kind] || 'grey' : 'grey';
  } else {
    return kind ? KINDCOLOR[kind] || BACKGROUND_GRAY : BACKGROUND_GRAY;
  }
};

interface KindInterface<T> {
  [kind: string]: T;
}

// This is driven by the 'kind' property in the task object
const KINDMESSAGE: KindInterface<string> = {
  [TaskKind.painUptick]: 'Pain',
  [TaskKind.surgeryIncr]: 'Surgery'
};

export const getKindMessage = (kind: TaskKind) => KINDMESSAGE[kind];

export const RESOLVED_TASKS_STATUSES = [
  TaskStatuses.Completed,
  TaskStatuses.Rejected,
  TaskStatuses.Expired,
  TaskStatuses.Obsolete
];
