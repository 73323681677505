import * as React from 'react';
import { isEqual } from 'lodash';
import './ConversationContainerV2.scss';
import MessageListContainerV2 from './MessageListContainerV2';
import MessageComposerContainerV2 from './../MessageComposerContainerV2';

interface ConversationContainerV2Props {
  userId: number;
}

const ConversationContainerV2: React.FC<ConversationContainerV2Props> = ({ userId }) => (
  <div className='message-content-container'>
    <div className='message-list-box'>
      <MessageListContainerV2 userId={userId} />
    </div>
    <div className='message-composer-box'>
      <MessageComposerContainerV2 userId={userId} />
    </div>
  </div>
);

export default React.memo(ConversationContainerV2, isEqual);
