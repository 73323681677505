export const UPDATE_CONVERSATION_MESSAGES = 'UPDATE_CONVERSATION_MESSAGES';
export const UPDATE_CONVERSATION_SCHEDULED_MESSAGES =
  'UPDATE_CONVERSATION_SCHEDULED_MESSAGES';
export const DELETE_SCHEDULED_MESSAGE = 'DELETE_SCHEDULED_MESSAGE';
export const FETCH_MESSAGE = 'FETCH_MESSAGE';
export const POST_MESSAGE = 'POST_MESSAGE';
export const PUT_MESSAGE = 'PUT_MESSAGE';
export const FETCH_NEW_MESSAGE = 'FETCH_NEW_MESSAGE';
export const NEW_CONVERSATION_MESSAGES = 'NEW_CONVERSATION_MESSAGES';
export const RECEIVE_UNSEEN_MESSAGE = 'RECEIVE_UNSEEN_MESSAGE';
export const RECEIVE_MESSAGE_STATUS = 'RECEIVE_MESSAGE_STATUS';
