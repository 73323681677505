import * as React from 'react';
import { Location } from 'history';
import styled from 'styled-components';
import UserNavBar from '../../components/phx/nav/UserNavBar';
import UserProfileBannerContainer from './phx/UserProfileBannerContainer';
import StateRegulationsContainer from './StateRegulationsContainer';
import { useUserId } from '../../utils/hooks/useUserId';

export interface Props {
  location?: Location;
}

const StyledContainer = styled.div`
  width: 95%;
  margin: auto;
`;

export const UserStateRegulationsContainer: React.FC<Props> = ({ location }) => {
  const userId = useUserId();

  return (
    <>
      <UserProfileBannerContainer id={userId} />
      <StyledContainer>
        <UserNavBar location={location} id={userId} />
        <StateRegulationsContainer userId={userId} />
      </StyledContainer>
    </>
  );
};

export default UserStateRegulationsContainer;
