export enum UserProfileRoutes {
  LegacyEngagement = 'pathway/legacy_engagement',
  Engagement = 'pathway/engagement',
  Exercise = 'pathway/exercise',
  Surgery = 'pathway/surgery',
  Pain = 'pathway/pain',
  History = 'history',
  VideoCall = 'videocall',
  StateRegulations = 'state-regulations',
  CarePlans = 'care-plans'
}
