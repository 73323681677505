import { EtSessionGroupings } from '../../graphql/models/bff';

export const createEtSessionGroupingsHash = (
  recentEtSessions: EtSessionGroupings[],
  currentWeek: number,
  pastWeek: number
) => {
  switch (recentEtSessions.length) {
    case 0:
      const newEtSessionGroupings = [
        {
          weekNumber: currentWeek,
          etSessions: []
        },
        {
          weekNumber: pastWeek,
          etSessions: []
        }
      ];
      return newEtSessionGroupings;
    case 1:
      const appendedEtSessionGroupings = [...recentEtSessions];
      const currentWeekFound = recentEtSessions.some(el => el.weekNumber === currentWeek);
      const missingEtSessionWeek = {
        weekNumber: currentWeekFound ? pastWeek : currentWeek,
        etSessions: []
      };
      appendedEtSessionGroupings.push(missingEtSessionWeek);
      return appendedEtSessionGroupings;
    default:
      return recentEtSessions;
  }
};
