import * as React from 'react';
import {
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  Legend,
  Area
} from 'recharts';
import MultilineTick from './MultilineTick';
import PainTooltip from './PainTooltip';
import PainLegend from './PainLegend';
import { DARKER_NAV_BAR, RED, LIGHTERRED } from '../../colors';
import { User, PainLog, Screener } from '../../api-client';
import { formatDiff, formatToInt, isNumber } from '../../utils/number';
import { HANDLE_RESIZE_WAIT } from '../../utils/chart';
import * as moment from 'moment-timezone';

interface PainChartDatum {
  dayLabel: string;
  allTimeChange: string | null;
  dayValue: number | null;
  weekPain?: number | null;
}

interface PainChartProps {
  startWeek: number;
  endWeek: number;
  user: User;
  maxPainLogs: PainLog[];
  screener?: Screener | null;
  timezone: string;
}

function findSameDayPainLogs(
  date: moment.Moment,
  timeZone: string,
  painLog: PainLog
): boolean {
  return date.isSame(moment.tz(painLog.created_at, timeZone), 'day');
}

export function generateChartData(props: PainChartProps): PainChartDatum[] {
  const {
    startWeek,
    endWeek,
    user,
    maxPainLogs,
    screener,
    timezone
  } = props;
  const screenerPain = screener && screener.pain ? screener.pain : null;
  const data = new Array<PainChartDatum>();

  if (!user.team || !isNumber(user.team.currentTeamWeekNumber)) return [];
  const currentWeek = user.team.currentTeamWeekNumber;
  const endWeekInclusive = endWeek + 1;

  const startDate = moment
    .tz(timezone)
    .startOf('isoWeek')
    .subtract(currentWeek, 'weeks')
    .add(startWeek, 'weeks');

  for (let i = 0; i < endWeekInclusive * 7; i++ , startDate.add(1, 'day')) {
    const weekIndex = Math.floor(i / 7);
    const maxPainLog = maxPainLogs.find(
      findSameDayPainLogs.bind(null, startDate, timezone)
    );

    if (!maxPainLog || !isNumber(maxPainLog.pain)) {
      data.push({
        dayLabel: `W${weekIndex}\n${startDate.format('M/D')}`,
        dayValue: null,
        allTimeChange: null
      });
    } else {
      const painDiff = isNumber(screenerPain)
        ? formatToInt(maxPainLog.pain - screenerPain)
        : NaN;

      data.push({
        dayLabel: `W${weekIndex}\n${startDate.format('M/D')}`,
        dayValue: formatToInt(maxPainLog.pain),
        allTimeChange: formatDiff(painDiff)
      });
    }
  }
  return data;
}

const PainChart: React.FunctionComponent<PainChartProps> = props => {
  const { endWeek, screener } = props;
  const screenerPain = screener && screener.pain ? screener.pain : undefined;

  return (
    <ResponsiveContainer debounce={HANDLE_RESIZE_WAIT} width="100%" height={480}>
      <ComposedChart data={generateChartData(props)}>
        <XAxis
          dataKey="dayLabel"
          type="category"
          interval={6}
          tick={<MultilineTick numWeeks={endWeek} />}
          height={42}
        />
        <YAxis type="number" domain={[0, 100]} ticks={[25, 50, 75, 100]} />
        <CartesianGrid />
        <Area
          connectNulls={true}
          dataKey="dayValue"
          name="Pain"
          stroke={RED}
          strokeWidth={'2'}
          fill={LIGHTERRED}
          legendType="line"
          dot={true}
          isAnimationActive={false}
        />
        <ReferenceLine
          strokeDasharray="8 2"
          y={screenerPain}
          stroke={DARKER_NAV_BAR}
        />
        <Tooltip
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          formatter={(v: any) => (typeof v === 'number' ? v : v[1] - v[0])}
          content={<PainTooltip painRefValue={screenerPain} />}
        />
        <Legend
          verticalAlign={'top'}
          align={'right'}
          content={<PainLegend iconSize={16} />}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default PainChart;
