import * as C from '../constants';
import * as Sentry from '@sentry/browser';
import { ThunkAction, PayloadAction } from './interfaces';
import { getApiClient } from '../selectors';
import { ERR_MESSAGE_PARSE_FAIL, ERR_SCHEDULED_MESSAGE_DELETE_FAIL } from '../../errors';
import {
  MessagesV2Params,
  UpdateMessageV2Params,
  CreateMessageV2Params,
  UpdatedMessageResponse
} from '../../api-client/endpoints';
import { GET_MESSAGES_FOR_USER, CREATE_MESSAGE } from '../../graphql/queries';
import { MessageV2 } from '../../api-client/interfaces';

export interface GetMessagesV2Payload {
  messages: {
    [uuid: string]: MessageV2;
  };
};

interface DeleteScheduledMessagePayload {
  messageUuid: string;
}

export type MessagingV2Actions =
  GetMessagesAction |
  DeleteScheduledMessageAction |
  UpdateMessageAction |
  CreateMessageAction;

export interface GetMessagesAction extends PayloadAction<GetMessagesV2Payload> {
  type: typeof C.MESSAGES_FETCHED;
}
export interface DeleteScheduledMessageAction extends PayloadAction<DeleteScheduledMessagePayload> {
  type: typeof C.DELETE_SCHEDULED_MESSAGE_V2;
}

export interface CreateMessageAction extends PayloadAction<{ newMessage: MessageV2 }> {
  type: typeof C.CREATE_MESSAGE;
}
export interface UpdateMessageAction extends PayloadAction<{ updatedMessage: UpdatedMessageResponse }> {
  type: typeof C.UPDATE_MESSAGE;
}

export const actionCreators = {
  getMessages({
    userId,
    adminUuid,
    page = 0,
    itemsPerPage = 25
  }: MessagesV2Params): ThunkAction<Promise<GetMessagesAction | void>> {
    return (dispatch, getState) => {
      const state = getState();
      const api = getApiClient(state);

      return api
        .messagesV2
        .get({
          query: GET_MESSAGES_FOR_USER,
          variables: {
            userId,
            adminUuid,
            page,
            itemsPerPage
          }
        }, { userId, adminUuid, page, itemsPerPage })
        .then((res) => {
          if (res.messageData && Array.isArray(res.messageData.messages)) {
            const messageUuids: string[] = [];
            const messages = res.messageData.messages
              .reduce((hash: { [key: string]: MessageV2 }, message: MessageV2) => {
                messageUuids.push(message.uuid);
                hash[message.uuid] = message;
                return hash;
              }, {});

            const user = state.user.get(userId);
            dispatch({ payload: { messages }, type: C.MESSAGES_FETCHED });
            dispatch({ payload: { user, messageUuids }, type: C.PREPEND_OLD_USER_MESSAGES });
          }
        })
        .catch(e => {
          Sentry.captureException(new Error(
            `${ERR_MESSAGE_PARSE_FAIL}, ` +
            `user id: ${userId}, admin id: ${adminUuid}, error message: ${e.message}`
          ));
          return Promise.reject(e);
        });
    };
  },
  getScheduledMessages(): ThunkAction<Promise<{}>> {
    return (_, __) => Promise.resolve({});
  },
  updateMessage(params: UpdateMessageV2Params): ThunkAction<Promise<UpdateMessageAction | void>> {
    return (dispatch, getState) => {
      const state = getState();
      const api = getApiClient(state);

      return api.messagesV2.update({}, params)
        .then((res) => dispatch({ payload: { updatedMessage: res.messageData }, type: C.UPDATE_MESSAGE }))
        .catch(e => {
          Sentry.captureException(new Error(
            `${ERR_MESSAGE_PARSE_FAIL}, message uuid: ${params.uuid}, error message: ${e.message}`
          ));
          return Promise.reject(e);
        });
    };
  },
  createMessage(params: CreateMessageV2Params): ThunkAction<Promise<CreateMessageAction | void>> {
    return (dispatch, getState) => {
      const state = getState();
      const api = getApiClient(state);

      return api.messagesV2.create({
        query: CREATE_MESSAGE,
        variables: { ...params }
      }, params)
        .then(res => {
          const newUuid = res.uuid;
          const user = state.user.get(params.userId);
          dispatch({ type: C.CREATE_MESSAGE, payload: { newMessage: res }});
          dispatch({ payload: { user, messageUuids: [newUuid] }, type: C.APPEND_NEW_USER_MESSAGES });
        })
        .catch(e => {
          Sentry.captureException(new Error(
            `${ERR_MESSAGE_PARSE_FAIL}, error message: ${e.message}`
          ));

          return Promise.reject(e);
        });
    };
  },
  deleteMessage(uuid: string): ThunkAction<Promise<DeleteScheduledMessageAction | void>> {
    return (dispatch, getState) => {
      const state = getState();
      const api = getApiClient(state);

      return api.messagesV2.delete({}, { uuid }).then((response) => {
        dispatch({
          payload: { messageUuid: uuid },
          type: C.DELETE_SCHEDULED_MESSAGE_V2
        });
      }).catch(e => {
        Sentry.captureException(new Error(
          `${ERR_SCHEDULED_MESSAGE_DELETE_FAIL}, message uuid: ${uuid}, error message: ${e.message}`
        ));

        return Promise.reject(e);
      });
    };
  }
};
