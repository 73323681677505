import * as Sentry from '@sentry/browser';

export type LOG_LEVEL = 'info' | 'debug' | 'fatal' | 'error' | 'log' | 'warning';
/**
 * To capture UI related debugging messages in sentry. Ideally Sumo/Splunk kind of solutions should be used
 * but in absense of those tools, use of this can help the app capture important messages from UI as needed.
 *
 * Note: If we decide to send message to something else , it will be one place change if this hook is used to
 * log messages.
 *
 * @param {string | Error } err for what needs to be captured.
 * @param { string } adminEmail here admin means HH internal user who's sesion we are trying to trace.
 * @param { LOG_LEVEL } level to set for log entry.
 */
export const logMessage = (err: string | Error, adminEmail?: string, level: LOG_LEVEL = 'info' ) => {
  Sentry.withScope(scope => {
    if (adminEmail) {
      scope.setUser({ email: adminEmail });
    }

    const messageToReport = err instanceof Error ? err.message : err ;
    Sentry.captureMessage(messageToReport, level);
  });
};
