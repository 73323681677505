import * as React from 'react';
import * as moment from 'moment-timezone';
import { Box, Typography} from '@material-ui/core';
import DateTimePicker from 'react-datetime-picker';
import ModalWrapper from '../modal/ModalWrapper';

interface ScheduleMessageModalProps {
  userTimezone: string;
  scheduleMessage(scheduledFor: moment.Moment): void;
  showModal: boolean;
  setShowModal(visible: boolean): void;
}

const ScheduleMessageModal: React.FC<ScheduleMessageModalProps> = ({
  userTimezone,
  scheduleMessage,
  showModal,
  setShowModal
}) => {
  const currentDate = new Date();

  const userLocalTime = moment(currentDate)
    .tz(userTimezone)
    .format('h:mm A z');

  const getDate = (curDate: Date) => {
    const dt = new Date(curDate);
    dt.setTime(dt.getTime() + dt.getTimezoneOffset() * 60 * 1000);
    const offset = moment().tz(userTimezone).utcOffset();
    return new Date(dt.getTime() + (offset + 5) * 60 * 1000);
  };

  const [date, setDate] = React.useState<Date>(getDate(currentDate));

  const [scheduledButtonDisabled, setScheduleButtonDisabled] = React.useState<boolean>(false);

  const isInFuture = (d: Date) => d >= getDate(currentDate);

  const handleDateTimeChange = (d: Date) => {
    if (isInFuture(d)) {
      setScheduleButtonDisabled(false);
    } else {
      setScheduleButtonDisabled(true);
    }
    setDate(d);
  };
  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.currentTarget.querySelectorAll('input').forEach((el: HTMLInputElement) => {
      if (!el.value) {
        setScheduleButtonDisabled(true);
      }
    });
  };

  const dismissModal = () => setShowModal(false);
  const handleSubmit = () => {
    const scheduledFor = moment(`${date && moment(date).format('YYYY-MM-DD')} ${moment(date).format('h:mm A')}`);
    setScheduleButtonDisabled(true);
    scheduleMessage(scheduledFor);
  };

  const modalContent = {
    title: 'Schedule a Message',
    body: (
      <Box>
        <Box marginBottom='10px'>
          <Typography variant='subtitle1' color='textSecondary'>
            User's local time: {userLocalTime}
          </Typography>
        </Box>
        <Box height='10px' marginBottom='15px' display='flex' justifyContent='center'>
          {!isInFuture(date) && (
            <Typography
              id="time-future-error"
              color="error"
              variant="subtitle2"
            >
              Please select a time at least 5 minutes in the future
            </Typography>
          )}
        </Box>
        <Box id='datetimepicker_box' width='100%' display='flex' justifyContent='center' onKeyUp={onKeyUp}>
          <DateTimePicker className="scheduling-dates" minDate={getDate(currentDate)}
            calendarIcon={null} required={true}
            minuteAriaLabel={'minute'} clearIcon={null} hourAriaLabel={'hour'}
            value={date} disableClock={true} onChange={handleDateTimeChange} />
        </Box>
      </Box>
    ),
    confirmBtnText: 'Schedule',
    dismissBtnText: 'Cancel'
  };

  return (
    <ModalWrapper
      modalContent={modalContent}
      disableConfirmButton={scheduledButtonDisabled}
      onConfirm={handleSubmit}
      onDismiss={dismissModal}
      openModal={showModal}
    />
  );
};

export default ScheduleMessageModal;
