import { useQuery } from '@apollo/client';
import * as React from 'react';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import { GET_USER_HEALTH_LOGS } from '../../../graphql/queries';
import PainChart from '../../../components/phx/charts/PainChart';
import PainTable from '../../../components/phx/charts/PainTable';
import { User } from '../../../graphql/models/User';
import { HealthLog } from '../../../graphql/models/HealthLog';

const EMPTY_LOGS: HealthLog[] = [];

interface Response {
  user: User;
}

interface PainStatsContainerProps {
  id: number;
}

const PainStatsContainer: React.FC<PainStatsContainerProps> = ({ id }) => {
  const { loading, data } = useQuery<Response, { id: string }>(
    GET_USER_HEALTH_LOGS,
    { variables: { id: id.toString() } }
  );
  return (
    <Segment>
      {loading && (
        <Dimmer.Dimmable dimmed>
          <Loader>Loading...</Loader>
        </Dimmer.Dimmable>
      )}

      {data && data.user && data.user && (
        <>
          {
            <PainChart
              user={data.user}
              pathways={data.user.pathways}
              healthLogs={data.user.healthLogs || EMPTY_LOGS}
            />
          }
          <PainTable
            healthLogs={data.user.healthLogs || EMPTY_LOGS}
            timezone={data.user.timezone}
            pathways={data.user.pathways}
          />
        </>
      )}
    </Segment>
  );
};

export default PainStatsContainer;
