import * as React from 'react';
import { EnsoUsage } from '../../graphql/models/User';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { getWeekDateRange } from '../../utils/datetime';
import { DateTime } from 'luxon';
import { TEXT_SECONDARY } from '../../colors';

interface EnsoUtilizationProps {
  ensoUsage: EnsoUsage;
}

const Title = styled(Typography)`
&& {
  font-size: 18px;
  padding: 20px 0 20px 0;
}
`;

const Wrapper = styled.div`
  padding: 0 20px 0 20px;
`;

const StyledGrid = styled(Grid)`
&& {
  padding-bottom: 20px;
}
`;

const FieldHeader = styled(Typography)(
  ({theme}) => `
  && {
    padding-bottom: 5px;
    color: ${TEXT_SECONDARY};
  }
  `
);

export const EnsoUtilization: React.FC<EnsoUtilizationProps> = ({ ensoUsage }) => {
  const { sessionsThisWeek, sessionsLastWeek, lastSessionDate }  = ensoUsage;

  const getSessionsDisplayText = (sessions: number) =>
    `${sessions} session${sessions === 1 ? '' : 's' }`;

  return (
    <Wrapper>
      <Title>{'Enso'}</Title>
      {
        lastSessionDate ? (
          <StyledGrid container>
            <StyledGrid item xs={6}>
              <FieldHeader>
                {`This week (${getWeekDateRange(DateTime.now().setZone('utc'))})`}
              </FieldHeader>
              <Typography>
                {getSessionsDisplayText(sessionsThisWeek)}
              </Typography>
            </StyledGrid>
            <StyledGrid item xs={6}>
              <FieldHeader>
                  Last session
              </FieldHeader>
              <Typography>
                {DateTime.fromISO(lastSessionDate).toLocaleString(DateTime.DATE_MED)}
              </Typography>
            </StyledGrid>
            <StyledGrid item xs={6}>
              <FieldHeader>
                {`Last week (${getWeekDateRange(DateTime.now().setZone('utc').minus({weeks: 1}))})`}
              </FieldHeader>
              <Typography>
                {getSessionsDisplayText(sessionsLastWeek)}
              </Typography>
            </StyledGrid>
          </StyledGrid>
        ) : (
          <StyledGrid container>
            <FieldHeader>
              No sessions completed
            </FieldHeader>
          </StyledGrid>
        )
      }
    </Wrapper>
  );
};
