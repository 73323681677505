import * as React from 'react';

/** A SVG graph tick that splits newline characters to span multiple lines. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MultilineTick: React.FunctionComponent<any> = (props) => {
  const { numWeeks, verticalAnchor, index, payload, ...textProps } = props;
  const { visibleTicksCount, ...requiredTextProps } = textProps;
  const lines: string[] = payload.value.split('\n');

  return (numWeeks > 13 && index % 4) ? (
    <tspan dy="1.2em">{''}</tspan>
  ) : (
    <text { ...requiredTextProps }>
      {lines.map((l, i) => (
        <tspan key={i} x={props.x} dy="1.2em">{l}</tspan>
      ))}
    </text>
  );
};

export default MultilineTick;
