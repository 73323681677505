import { RootState, MessageRecord } from '../reducers';
import { createSelector } from 'reselect';
import { selectConversation } from './conversations';
import { MessageDirection, Message } from '../../api-client/interfaces';

export const selectMessagesState = (state: RootState) => state.messages;

/**
 * Helper function to sort messages.
 * The messages should be sorted in ascending order.  Older messages
 * will be at the front of the array and newer messages will be at the end of the array
 * Ordering is based on:
 * - transmittedAt date for outbound messages
 * - createdAt date for inbound messages
 *
 * More information: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 *
 * @param a MessageRecord to compare
 * @param b MessageRecord to compare
 * @returns number indicating the determined ordering of the two input values
 */
export const messageComparator = (a: MessageRecord | Message, b: MessageRecord | Message): number => {
  // Note:
  // Outgoing messages have _both_ a createdAt date and a transmittedAt date
  // When a message is outgoing, the message is _first_ created and then transmitted

  // Incoming messages have _both_ a createdAt date and a transmittedat date
  // When a message is incoming, the message is _first_ transmitted and then created


  // Extract a relevant date from the MessageRecords
  // By default, if the message direction is IN, use the createdAt date
  // By default, if the message direction is OUT, use the createdAt date
  // If the default value is undefined, fallback to the createdAt date and then the transmittedAt date
  const aRelevantDate = (a.direction === MessageDirection.In ? a.createdAt : a.transmittedAt)
  || a.createdAt || a.transmittedAt;
  const bRelevantDate = (b.direction === MessageDirection.In ? b.createdAt : b.transmittedAt)
  || b.createdAt || b.transmittedAt;

  // If neither a nor b have dates attached, they should be considered equal / unsorted
  if(!aRelevantDate && !bRelevantDate) return 0;

  // If a has a relevant date, but b does not, b should come before a
  if(aRelevantDate && !bRelevantDate) return 1;

  // If b has a relevant date, but a does not, a should come before b
  if(!aRelevantDate && bRelevantDate) return -1;

  // If both a and b have relevant dates,
  if(aRelevantDate && bRelevantDate) {
    // find the difference between the relevant dates
    const ret = new Date(aRelevantDate).getTime() - new Date(bRelevantDate).getTime();

    // Normalize return value to 0, -1, and 1
    if(ret < 0) return -1;
    else if (ret > 0) return 1;
    else return 0;
  }
  return 0;
};

export const selectConversationMessages = createSelector(
  [selectMessagesState, selectConversation],
  (messages, conversation) => conversation && conversation.messageIds
    ? conversation.messageIds.map(id => messages.get(id)).sort(messageComparator)
    : []
);

export const selectConversationScheduledMessages = createSelector(
  [selectMessagesState, selectConversation],
  (messages, conversation) => conversation && conversation.scheduledMessageIds
    ? conversation.scheduledMessageIds.map(id => messages.get(id)).sort(messageComparator)
    : []
);

export const selectMessage = (
  state: RootState,
  props: { messageId: string }
) => state.messages.get(props.messageId);

export const isUnreadMessage = () =>
  createSelector(
    [selectMessage],
    message => message && !message.seenAt && message.direction === MessageDirection.In
  );
