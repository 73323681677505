import * as React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';

const StyledGridItem = styled(Grid)`
  && { 
    margin: 40px auto; 
  }
`;

interface TeamTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TeamTabPanel: React.FC<TeamTabPanelProps> = ({ children, index, value }) => (
  <StyledGridItem item md={12}>
    <div
      role='tabpanel'
      hidden={value !== index}
    >
      {children}
    </div>
  </StyledGridItem>
);

export default TeamTabPanel;
