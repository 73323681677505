import * as React from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { Achievement } from '../../api-client';
import { ERR_USER_PARSE_FAIL } from '../../errors';
import { DispatchMap } from '../../redux/actions/interfaces';
import { getUserAchievements } from '../../redux/actions/user';
import { selectUser } from '../../redux/selectors';
import { RootState, UserRecord } from '../../redux/reducers';
import AllAchievementsContextV1 from '../../components/user/AllAchievementsContext';
import AllAchievementsContextV2 from '../../components/user/AllAchievementsContextV2';
import RecentAchievementsContextV2 from '../../components/user/RecentAchievementsContextV2';
import RecentAchievementsContextV1 from '../../components/user/RecentAchievementsContext';
import { Divider } from '@material-ui/core';

type ConnectProps = DispatchMap<{
  fetchUserAchievements: typeof getUserAchievements;
  user?: UserRecord;
}>;

interface Props {
  userId: number;
  showRecent?: boolean;
  displayV2Components: boolean | undefined;
}

type fetchStatusState = 'loading' | 'error' | 'success';

const AchievementsContextContainer: React.FC<Props & ConnectProps> = ({
  userId,
  user,
  fetchUserAchievements,
  showRecent = false,
  displayV2Components
}) => {
  const [fetchStatus, setFetchStatus] = React.useState<fetchStatusState>('loading');
  const [fetchError, setFetchError] = React.useState('');
  const [achievements, setAchievements] = React.useState<Achievement[] | null>(null);

  React.useEffect(() => {
    const fetchData = async() => {
      await fetchUserAchievements({userId})
        .then((res) => {
          setAchievements(res.userAchievements);
          setFetchStatus('success');
        })
        .catch((err) => {
          setFetchError(err);
          setFetchStatus('error');
        });
    };

    fetchData();
  }, [fetchUserAchievements, userId]);

  if (fetchStatus === 'loading') {
    return <div><Loader active inline size="tiny" /></div>;
  }

  if (fetchStatus === 'error') {
    throw new Error(`${ERR_USER_PARSE_FAIL}: ${fetchError}`);
  }

  if (fetchStatus === 'success' && achievements) {

    const getRecentAchievementsContext = () => !displayV2Components ?
      <RecentAchievementsContextV1 achievements={achievements} user={user} />
      : <RecentAchievementsContextV2 achievements={achievements} user={user} />;

    const getAllAchievementsContext = () => !displayV2Components ?
      <AllAchievementsContextV1 achievements={achievements} user={user} />
      : <AllAchievementsContextV2 achievements={achievements} user={user} />;

    return (
      <>
        {showRecent ? getRecentAchievementsContext() : getAllAchievementsContext()}
        {!!displayV2Components && <Divider />}
      </>
    );
  }

  return null;
};

const mapStateToProps = (state: RootState, props: Props) => ({
  user: selectUser(state, { userId: props.userId })
});

const mapDispatchToProps = {
  fetchUserAchievements: getUserAchievements
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AchievementsContextContainer);
