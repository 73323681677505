import * as React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { userActions, DispatchThunk } from '../../../redux/actions';
import { Dimmer, Header, Icon, Loader } from 'semantic-ui-react';
import { User } from '../../../graphql/models';
import { GetUserById, Status, EnrollmentStatus } from '../../../graphql/models/bff';
import { Coverages, ProgramName } from '../../../api-client/interfaces';
import { GetUserCoveragesResponse } from '../../../api-client/endpoints';
import { ApiClientNames } from '../../apollo/ApolloContainer';
import {
  GET_ALL_MEMBER_CONDITIONS,
  GET_USER_COVERAGES,
  GET_USER_POSTER_WITH_ENROLLMENT_STATUS
} from '../../../graphql/queries/User';
import { inverseStatus, getEnrollmentStatus } from '../../../utils/user/enrollmentStatus';
import UserProfileBanner from '../../../components/phx/user/UserProfileBanner';
import { RootState } from '../../../redux/reducers';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';
import { zoomPhoneCallEnabled } from '../../../features';
import { Indication, MemberCondition } from '../../../graphql/models/bff/MemberCondition';

export const ProfileContainer = styled.div`
  padding: 2rem 1rem 0 1rem;
  display: flex;
  justify-content: center;
`;
export const MSK_CONDITION_KIND = 'msk';

interface UserPosterResponse {
  getUserById: GetUserById;
}

export interface MemberConditionsResponse {
  getAllMemberConditions: MemberCondition[];
}

interface UserProfileBannerContainerProps {
  id: number;
}

const UserProfileBannerContainer: React.FC<UserProfileBannerContainerProps> = ({ id }) => {
  let coverages: Coverages | undefined;

  const dispatch: DispatchThunk = useDispatch();
  const isZoomPhoneCallEnabled = useSelector((state: RootState) =>
    selectTreatmentValue(state.splitReducer, zoomPhoneCallEnabled) === 'on'
  );

  const [indications, setIndications] = React.useState<Indication[]>([]);

  const [enrollmentStatus, setEnrollmentStatus] = React.useState<EnrollmentStatus | null>(null);

  const { saveUsersToRedux, updateUserEnrollmentStatus } = userActions;

  const { loading, error, data } = useQuery<UserPosterResponse>(GET_USER_POSTER_WITH_ENROLLMENT_STATUS, {
    variables: { userId: id },
    fetchPolicy: 'network-only',
    context: {
      clientName: ApiClientNames.BFF
    }
  });

  const [getUserCoverages, { data: coverageData }] = useLazyQuery<GetUserCoveragesResponse>(GET_USER_COVERAGES);

  const [getAllMemberConditions, {
    data: indicationsData
  }] = useLazyQuery<MemberConditionsResponse>(GET_ALL_MEMBER_CONDITIONS);

  const isMultiIndicationUser = React.useMemo(() => Boolean(data?.getUserById?.pathways?.some(pathway =>
    pathway.programIndication?.programName === ProgramName.UnifiedV1
  )), [data?.getUserById]);

  React.useEffect(() => {
    if (indicationsData?.getAllMemberConditions?.[0]?.indications) {
      setIndications(indicationsData.getAllMemberConditions[0].indications);
    }
  }, [indicationsData]);

  React.useEffect(() => {
    if (data) {
      const currentEnrollmentStatusFromUser = getEnrollmentStatus(data.getUserById);
      setEnrollmentStatus(currentEnrollmentStatusFromUser);
    }
  }, [data]);

  React.useEffect(() => {
    if (data?.getUserById) {
      dispatch(saveUsersToRedux([data.getUserById as User]));
    }
  }, [data?.getUserById]);

  React.useEffect(() => {
    if (data?.getUserById) {
      const { uuid } = data.getUserById;

      getUserCoverages({ variables: { userUuid: uuid }, context: { clientName: ApiClientNames.BFF } });
      getAllMemberConditions({
        variables: { memberId: uuid.toString(), kind: MSK_CONDITION_KIND },
        context: {
          clientName: ApiClientNames.NestBFF
        }
      });
    }
  }, [data?.getUserById]);

  const handleChangeEnrollmentStatus = (status: Status) => {
    dispatch(updateUserEnrollmentStatus(id, inverseStatus(status))).then(res => {
      const updatedStatus = res.enrollmentStatus?.length ? res.enrollmentStatus[0] : null;

      setEnrollmentStatus(updatedStatus);
    });
  };

  if (error) {
    return (
      <Dimmer.Dimmable className='scrolling' as={ProfileContainer} dimmed={true}>
        <Header icon inverted>
          <Icon name='exclamation' />
          Failed to load user information.
        </Header>
      </Dimmer.Dimmable>
    );
  }

  if (loading) {
    return (
      <Dimmer.Dimmable className='scrolling' as={ProfileContainer} dimmed={true}>
        <Loader>
          Loading user ID {id}
          ...
        </Loader>
      </Dimmer.Dimmable>
    );
  }

  if (!data?.getUserById) {
    return (
      <Dimmer.Dimmable className='scrolling' as={ProfileContainer} dimmed={true}>
        <Header icon inverted>
          <Icon name='exclamation' />
          User data is not available.
        </Header>
      </Dimmer.Dimmable>
    );
  }

  if (coverageData?.getUserCoverages) {
    coverages = coverageData?.getUserCoverages?.coverages;
  }

  return (
    <Dimmer.Dimmable className='scrolling' as={ProfileContainer} dimmed={false}>
      <UserProfileBanner
        coverages={coverages}
        user={data.getUserById}
        enrollmentStatus={enrollmentStatus}
        changeEnrollmentStatus={handleChangeEnrollmentStatus}
        isZoomPhoneCallEnabled={isZoomPhoneCallEnabled}
        indications={indications}
        isMultiIndicationUser={isMultiIndicationUser}
      />
    </Dimmer.Dimmable>
  );
};

export default UserProfileBannerContainer;
