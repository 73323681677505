import { Pathway, StreamKind } from './';

export function getCurrentStream(pathway?: Pathway | null) {
  if (!pathway) {
    return 'Not available';
  }
  switch (pathway.currentExerciseTherapyStream) {
    case StreamKind.low_activity:
      return 'Low';
    case StreamKind.medium_activity:
      return 'Medium';
    case StreamKind.high_activity:
      return 'High';
    default:
      return 'Not available';
  }
}
