import * as React from 'react';
import {
  FormControl,
  Select,
  InputLabel
} from '@material-ui/core';
import { NoteType } from '../../api-client/interfaces';
import './UserNoteDropdown.scss';

export interface UserNoteDropdownProps {
  onChange(event: React.ChangeEvent<{ value: unknown }>): void;
  onOpen(event: object): void;
  onClose(event: object): void;
  noteTypeMenuItems: object[];
  noteTypeLabel?: NoteType | string;
  noteTypeDropdownOpen: boolean;
  isRequired?: boolean;
  inputLabel?: string;
}

const UserNoteDropdown = ({
  onChange,
  onOpen,
  onClose,
  noteTypeMenuItems,
  noteTypeLabel = '',
  noteTypeDropdownOpen = false,
  isRequired = false,
  inputLabel = ''
}: UserNoteDropdownProps) =>
  <FormControl
    className='note-type-dropdown-form-control'
    variant='outlined'
    required={isRequired}
    size='small'
  >
    {!noteTypeLabel && <InputLabel id='note-type-dropdown-label'>{inputLabel}</InputLabel>}
    <Select
      className='note-type-dropdown-select'
      labelId='note-type-dropdown-label'
      value={noteTypeLabel}
      onChange={onChange}
      onOpen={onOpen}
      onClose={onClose}
      open={noteTypeDropdownOpen}
    >
      {noteTypeMenuItems}
    </Select>
  </FormControl>;

export default UserNoteDropdown;
