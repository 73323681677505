import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import * as React from 'react';
import { HingeHealthSecurityContextProvider } from './index';

interface HingeHealthSecurityProps {
  children: React.ReactNode;
  options: {
    oktaConfig: {
      clientId: string;
      issuer: string;
      redirectUri: string;
      restoreOriginalUri: (originalUri: string) => Promise<void>;
    };
    capabilitiesConfig: {
      enabled?: boolean;
      bffUrl?: string;
    };
  };
}

const HingeHealthSecurity = ({
  children,
  options
}: HingeHealthSecurityProps): JSX.Element => {
  const {
    oktaConfig: { clientId, issuer, redirectUri, restoreOriginalUri },
    capabilitiesConfig: { enabled: capabilitiesEnabled = true, bffUrl }
  } = options;

  const oktaAuth: OktaAuth = React.useMemo(
    () =>
      new OktaAuth({
        clientId,
        issuer,
        redirectUri,
        scopes: ['openid', 'profile', 'email'],
        pkce: true
      }),
    [clientId, issuer, redirectUri]
  );

  const capabilitiesEndpoint = capabilitiesEnabled
    ? `${bffUrl}/v1/capabilities`
    : undefined;

  const transformedRestoreOriginalUri = async(
    _oktaAuth: OktaAuth,
    originalUri: string
  ): Promise<void> => restoreOriginalUri(originalUri);

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={transformedRestoreOriginalUri}
    >
      <HingeHealthSecurityContextProvider
        capabilitiesEndpoint={capabilitiesEndpoint}
      >
        {children}
      </HingeHealthSecurityContextProvider>
    </Security>
  );
};

export default HingeHealthSecurity;
