import { useQuery } from '@apollo/client';
import * as React from 'react';

import { Loader } from 'semantic-ui-react';
import { GET_USER_STATUS } from '../../graphql/queries';
import { StatusNames } from '../../api-client/interfaces';
import UserStatusForm, { UserStatusFormProps } from '../../components/communications/UserStatusForm';
import { User, UserStatus } from '../../graphql/models';
import * as moment from 'moment-timezone';
import { Layer } from 'grommet';
import { EnrollmentStatusTextMap } from '../../api-client';

export type StatusTitleOptions =
    'Set a status' |
    'Edit status' |
    'Remove status' |
    (EnrollmentStatusTextMap.OptInTitle | string) |
    (EnrollmentStatusTextMap.OptOutTitle | string);

interface Props {
  userId: number;
  setShowStatusModal(show: boolean): void;
}

interface Response {
  user: User;
}

interface GetUserStatusVariables {
  id: string;
}

export const initialStatusForm: Omit<UserStatus, 'userId'> = {
  name: StatusNames.vacation,
  description: '',
  startsOn: moment().toString(),
  endsOn: moment().toString()
};

const UserStatusFormContainer: React.FC<Props> = ({
  userId,
  setShowStatusModal
}) => {
  const { loading, error, data } = useQuery<Response, GetUserStatusVariables>(GET_USER_STATUS, {
    variables: { id: userId.toString() }
  });
  const dismissModal = () => setShowStatusModal(false);

  if (loading) return <Loader size="small" active />;

  if (error) throw new Error('Unable to fetch user status');

  const statusProps: UserStatusFormProps = data?.user.userStatus ?
    {
      setShowStatusModal,
      userStatus: data.user.userStatus,
      title: 'Edit status'
    } :
    {
      setShowStatusModal,
      userStatus: { ...initialStatusForm, userId: userId.toString() },
      title: 'Set a status'
    };

  return (
    <Layer modal onEsc={dismissModal} onClickOutside={dismissModal}>
      <UserStatusForm {...statusProps}/>
    </Layer>
  );
};

export default UserStatusFormContainer;
