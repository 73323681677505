import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Person } from '@material-ui/icons';
import {
  Avatar,
  Chip,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { User } from '../../../graphql/models';
import { UserProfileRoutes } from '../../../utils/user/routes';
import { isNumber } from '../../../utils/number';
import { sortTeamUsersByName } from '../../../utils/helpers';
import { isActiveThisWeek } from '../user/UserProfileBanner';
import StrikeThroughUser from '../../../components/user/StrikeThroughUser';
import DataMessage from '../../errors/DataMessage';
import EngagementStreakContainer from '../../../containers/teams/phx/EngagementStreakContainer';
import UserStatusLabel from '../../../components/communications/UserStatusLabel';

const StyledChip = styled(Chip)`
  && {
    margin: 3px 6px 3px 0;
    font-size: 14px;
  }  
`;

const StyledLabelContainer = styled.div`
  padding-left: 5px;
`;

const StyledPopover = styled(Popover)`
  pointer-events: none;
`;

const StyledTableCell = styled(TableCell)`
  && {
    padding: 7px 10px;
  }  
`;

const StyledTableRow = styled(TableRow)`
  && {
    padding: 7px 10px;
  }  
`;

const StyledAvatar = styled(Avatar)`
  && {
    height: 32px;
    width: 32px;
    margin-right: 16px;
  }
`;

const UserAvatarWrapper = styled.div`   
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AdditionalTags = styled.div`   
  display: flex;
  flex-direction: column;
  padding: 8px;;
`;

const MoreTagsCount = styled.span`   
  margin-left: 4px;
  width: 48px;
  height: 24px;
`;

const errorMessage = 'Failed to load users.';

interface TeamResultsTableProps {
  users: User[];
}

interface TableColumnHeader {
  title: string;
  align?: 'left' | 'right';
}

const columnHeaders: Array<TableColumnHeader> = [
  {
    title: 'Name'
  },
  {
    title: 'ID'
  },
  {
    title: 'Tags'
  },
  {
    title: 'Active this week?'
  },
  {
    title: 'Engagement'
  },
  {
    title: 'Week'
  }
];

export const getWeekIndex = (user: User): number | null | undefined => {
  if (user.phoenix) {
    return user.pathways && user.pathways[0] ? user.pathways[0].currentWeek : 0;
  }
  return user.team ? user.team.currentTeamWeekNumber : 0;
};

export const getMessagingLink = (user: User): string =>
  user.team? `/messaging/teams/${user.team.id}/users/${user.id}` : '';

const TeamResultsTable: React.FC<TeamResultsTableProps> = ({ users }) => {
  const [anchorEl, setAnchorEl] = React.useState(document.activeElement);
  const [popoverId, setPopoverId] = React.useState<string>('');

  const sortedUsers = users && users.slice().sort(sortTeamUsersByName);

  const renderUserTags = (tags: string[]) =>
    tags.map(tag => (
      <StyledChip
        size="small"
        key={tag}
        label={tag}
        variant="outlined"
      />
    ));

  const handlePopoverOpen = (
    event: React.MouseEvent,
    id: string
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(id);
  };

  const handlePopoverClose = (
    event: React.MouseEvent,
    id: string
  ) => {
    if (event.currentTarget.id === id) {
      setAnchorEl(null);
      setPopoverId('');
    }
  };

  if (!users || !users.length) return <DataMessage message={errorMessage} />;

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {columnHeaders.map(({ title, align = 'left' }) => (
              <React.Fragment key={title}>
                <StyledTableCell align={align}>
                  {title}
                </StyledTableCell>
              </React.Fragment>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedUsers &&
            sortedUsers.map(user => {
              const currentWeek = getWeekIndex(user);
              const messageLink = getMessagingLink(user);
              return (
                <StyledTableRow key={user.id}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                  >
                    <UserAvatarWrapper>
                      <StyledAvatar
                        alt={`${user.firstName} ${user.lastName}`}
                        src={user.avatarUrl as string}
                      >
                        <Person />
                      </StyledAvatar>
                      <Link
                        to={`/user/${user.id}/${UserProfileRoutes.Engagement}`}
                        data-testid="username-link"
                      >
                        <StrikeThroughUser user={user}>
                          {`${user.firstName} ${user.lastName}`}
                        </StrikeThroughUser>
                      </Link>
                      <StyledLabelContainer>
                        <UserStatusLabel data-testid="userstatus-icon" status={user.userStatus} />
                      </StyledLabelContainer>
                    </UserAvatarWrapper>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {messageLink ? (
                      <Link
                        to={messageLink}
                        data-testid="usermessaging-link"
                      >
                        {user.id}
                      </Link>
                    ) : (user.id)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {user.tags &&
                        user.tags.length &&
                        renderUserTags(user.tags.slice(0, 2))}
                    {user.tags && user.tags.length > 2 ? (
                      <>
                        <MoreTagsCount
                          id={user.id}
                          aria-owns={Boolean(anchorEl) ? 'additional-tags' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={e => handlePopoverOpen(e, user.id)}
                          onMouseLeave={e => handlePopoverClose(e, user.id)}
                        >
                          {`+${
                            user.tags && user.tags.length > 2
                              ? user.tags.length - 2
                              : ''
                          }`}
                        </MoreTagsCount>
                        <StyledPopover
                          id="additional-tags"
                          open={popoverId === user.id}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          <AdditionalTags>
                            {renderUserTags(user.tags.slice(2, user.tags.length))}
                          </AdditionalTags>
                        </StyledPopover>
                      </>
                    ) : null}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {isActiveThisWeek(user.lastActive, user.timezone)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {isNumber(currentWeek) && currentWeek >= 0 ? (
                    /* Passing !displayV2Components is temporary until this
                       feature is refactored to use new container styles */
                      <EngagementStreakContainer id={user.id} displayV2Components={false} />
                    ) : (
                      'N/A'
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {isNumber(currentWeek) ? currentWeek : 'N/A'}
                  </StyledTableCell>
                </StyledTableRow>
              );})}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(TeamResultsTable);
