import * as React from 'react';
import styled from 'styled-components';
import { Header, Segment, Image, Table } from 'semantic-ui-react';
import * as moment from 'moment-timezone';
import {
  Typography
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/index';
import { selectUser } from '../../redux/selectors/index';
import { UserRecord } from '../../redux/reducers/user';
import { GET_USER_SCREENING_INFO } from '../../graphql/queries/User';
import { User } from '../../graphql/models';
import FullScreenerLink from '../../components/communications/FullScreenerLink';

export interface UserBioSectionsProps {
  userId: number;
}

const LB_PER_KG = 2.20462;
const IN_PER_CM = 0.393701;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UserBioSections: React.FunctionComponent<UserBioSectionsProps> = ({ userId }) => {
  const user: UserRecord = useSelector((state: RootState) => selectUser(state, { userId }));

  const { data } = useQuery<{ user: User },{ id: string }>(GET_USER_SCREENING_INFO, {
    variables: { id: userId.toString() }
  });

  if (!user) return null;

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        About {user.firstName}
      </Typography>
      <Segment vertical>
        <p>{user.bio}</p>
      </Segment>
      <Segment vertical>
        <StyledDiv>
          <Typography variant="h5" gutterBottom>
            {user.firstName}'s Bio
          </Typography>
          { data?.user &&
            <FullScreenerLink user={data.user} size='small' />
          }
        </StyledDiv>
        <Table basic="very">
          <Table.Body>
            <Table.Row>
              <Table.Cell>Age</Table.Cell>
              <Table.Cell>
                {user.dateOfBirth
                  ? moment().diff(user.dateOfBirth, 'years')
                  : '…'
                }
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Gender</Table.Cell>
              <Table.Cell>
                {user.gender
                  ? user.gender
                  : '…'
                }
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Birthday</Table.Cell>
              <Table.Cell>
                {user.dateOfBirth
                  ? moment(user.dateOfBirth).format('LL')
                  : '…'
                }
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Weight</Table.Cell>
              <Table.Cell>
                {user.weight
                  ? `${Math.round(user.weight * LB_PER_KG)} lbs`
                  : '…'
                }
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>BMI</Table.Cell>
              <Table.Cell>{user.bmi}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Height</Table.Cell>
              <Table.Cell>
                {user.height
                  ? `${
                    Math.floor(user.height * IN_PER_CM / 12)
                  }' ${Math.floor((user.height * IN_PER_CM) % 12)}"`
                  : '…'
                }
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Company</Table.Cell>
              <Table.Cell>
                <Header size="tiny">
                  {user.client && <Image shape="circular" src={user.client.logo} />}
                  {user.client
                    ? ` ${user.client.name}`
                    : '…'
                  }
                </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Occupation</Table.Cell>
              <Table.Cell>{user.occupation}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Email</Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Address</Table.Cell>
              <Table.Cell>
                {user.addressOne}<br />
                {user.addressTwo}{user.addressTwo && <br />}
                {user.city}, {user.stateOfResidence} {user.postalCode}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    </div>
  );
};

export default UserBioSections;
