import { useQuery, useLazyQuery } from '@apollo/client';
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { User } from 'src/graphql/models';
import { isEqual } from 'lodash';
import { CircularProgress } from '@material-ui/core';
import { Team } from '../../graphql/models/Team';
import { GET_CONVERSATIONS_USERS } from '../../graphql/queries/Teams';
import { GET_BULK_USERS } from '../../graphql/queries/User';
import { ApiClientNames } from '../apollo/ApolloContainer';
import { convertArrayToHash } from '../../utils/helpers';
import TeamUserSearch from '../../components/communications/TeamUserSearch';
import { conversationActions, userActions } from '../../redux/actions';
import { DispatchMap } from '../../redux/actions/interfaces';
import { ConversationKind } from '../../utils/conversations/helpers';
import { resetUsersOnboarded } from '../../redux/actions/user';
import { LOADING_BLUE } from '../../colors';
import { getUserUuids } from '../../utils/bulkUsers';
import { selectFeatureFlags } from '../../redux/selectors';
import { useNewMessagingBffConversationsEndpoint } from '../../features';
import { RootState } from '../../redux/reducers';

export const ErrorMessage = 'Failed to load users.';
export const EmptyData = 'No data to display.';

const StyledDiv = styled.div`
  position: absolute;
  left: 60%;
  top: 50%;
  font-size: 14px;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  && {
    display: flex;
    color: ${LOADING_BLUE};
  }
`;

interface Response {
  team: Team;
}

interface BulkUsersResponse {
  getUsersByUuids: User[];
}

export interface CompProps {
  teamId: string;
  teamName: string;
  currentWeekNumber: number;
  isUserViewingCtm?: boolean;
  useNewMessagingBffEndpoint?: boolean;
}

type ConnectProps = DispatchMap<{
  saveUsersToRedux: typeof userActions.saveUsersToRedux;
  getConversations: typeof conversationActions.getConversations;
  resetUsersOnboarded: typeof resetUsersOnboarded;
}>;

type Props = CompProps & ConnectProps;

const emptyUsers: User[] = [];

const SUPPORTED_CONVERSATION_TYPES = [ConversationKind.SMS];

export const mergeUserData = (userData: Team[], bulkUsersData: User[]) => {
  const bulkUsersHash = convertArrayToHash(bulkUsersData, 'id');

  return userData.map(user => ({
    ...bulkUsersHash[user.id],
    ...user
  }));
};

const ConversationListContainer: React.FC<Props> = (props) => {
  const { loading, error, data, refetch } = useQuery(GET_CONVERSATIONS_USERS, {
    variables: { id: props.teamId },
    fetchPolicy: 'network-only'
  });

  const users = (data as Response)?.team?.users || emptyUsers;

  const [ getBulkUsers, { loading: bulkUsersLoading, error: bulkUsersError, data: bulkUsersData }] =
  useLazyQuery<BulkUsersResponse, { uuids: string[] }>(GET_BULK_USERS,
    {
      context: { clientName: ApiClientNames.BFF }
    });

  React.useEffect(() => {
    if (users.length > 0) {
      getBulkUsers({ variables: { uuids: getUserUuids(users)}});
    }
  }, [users]);

  const bulkUsers = bulkUsersData?.getUsersByUuids || emptyUsers;

  React.useEffect(() => {
    if (error) {
      props.saveUsersToRedux(emptyUsers);
    } else {
      const responseData = mergeUserData(users, bulkUsers);
      props.saveUsersToRedux(responseData);
      const userIds = responseData.map(user => parseInt(user.id, 10));
      const userUuids = responseData.filter(user => user?.uuid).map(user => user?.uuid as string);

      if (userIds.length > 0) {
        // TODO: ATL-466 Remove Pagination for conversations
        props.getConversations({ userIds, userUuids, teamId: Number(props.teamId), types: SUPPORTED_CONVERSATION_TYPES,
                                 itemsPerPage: 10000, callNewEndpoint: props.useNewMessagingBffEndpoint});
      }
    }
  }, [data?.team?.users, error, bulkUsers, bulkUsersError]);

  if (error || bulkUsersError) return <StyledDiv>{ErrorMessage}</StyledDiv>;

  if (loading || bulkUsersLoading) {
    return <StyledDiv>
      <StyledCircularProgress />
    </StyledDiv>;
  }

  if (!users || !bulkUsersData) return <StyledDiv>{EmptyData}</StyledDiv>;

  const handleRefresh = () => {
    props.resetUsersOnboarded();
    refetch();
  };

  return (
    <TeamUserSearch
      users={mergeUserData(users, bulkUsers)}
      teamId={props.teamId}
      teamName={props.teamName}
      currentWeekNumber={props.currentWeekNumber}
      refreshFn={handleRefresh}
      isUserViewingCtm={props.isUserViewingCtm}
    />
  );
};

const mapDispatchToProps = {
  saveUsersToRedux: userActions.saveUsersToRedux,
  getConversations: conversationActions.getConversations,
  resetUsersOnboarded
};


const mapStateToProps = (state: RootState, props: CompProps) => ({
  useNewMessagingBffEndpoint: selectFeatureFlags(state).get(useNewMessagingBffConversationsEndpoint)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ConversationListContainer, isEqual));
