import * as React from 'react';
import { TooltipProps } from 'recharts';
import { formatDiff, isNumber } from '../../utils/number';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface PainTooltipProps extends TooltipProps {
  painRefValue?: number;
  painValue?: number;
}

export const finalStyle = {
  margin: 0,
  padding: 10,
  backgroundColor: '#fff',
  border: '1px solid #ccc',
  whiteSpace: 'nowrap' as 'nowrap',
  lineHeight: '24px',
  left: 300
};

const PainTooltip: React.FunctionComponent<PainTooltipProps> = (props) => {
  const { active, payload, painRefValue, label } = props;

  if (active && payload && payload[0]) {
    const { value, name } = payload[0];
    const painValue = value as number;
    const painDiff = painRefValue && isNumber(painValue) && isNumber(painRefValue) ? painValue - painRefValue : NaN;

    return (
      <div style={finalStyle}>
        <p>{label}</p>
        <p>{`${name} : ${value}`}</p>
        <p>{`All-time pain change : ${formatDiff(painDiff)}`}</p>
      </div>
    );
  }
  return null;
};

export default PainTooltip;
