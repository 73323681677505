import * as React from 'react';
import TeamsResultsContainer from './TeamResultsContainer';
import SearchResultsContainer from './SearchResultsContainer';

interface TeamTableContainerProps {
  isSearching: boolean;
  onboardingView: boolean;
  searchValue: string;
}

const TeamTableContainer: React.FC<TeamTableContainerProps> = ({ isSearching, onboardingView, searchValue }) => (
  isSearching ? (
    <SearchResultsContainer searchValue={searchValue} />
  ) : (
    <TeamsResultsContainer onboardingView={onboardingView} />
  )
);

export default TeamTableContainer;
