import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { datadogRum } from '@datadog/browser-rum';

import ApolloContainer from './containers/apollo/ApolloContainer';
import { history } from './redux';
import SecurityContainer from './components/auth-shared/security-container';

import App from './App';

import './semantic/dist/semantic.min.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react-dates-overrides.css';
import 'raf/polyfill';
import { store } from './store';

function isErrorObj(error?: string | Error | null): error is Error {
  if (typeof error === 'string' || !error) return false;
  return 'message' in error;
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN || '',
  release: process.env.REACT_APP_RELEASE || '',
  environment: process.env.REACT_APP_ENV,
  integrations: [
    new Sentry.Integrations.GlobalHandlers({
      onerror: true,
      onunhandledrejection: true
    }),
    new BrowserTracing()
  ],
  beforeSend: (event, hint) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error: any = hint?.originalException;
    if (typeof error === 'string' && error.match(/401/)) {
      return null;
    } else if (isErrorObj(error) && error.message.match(/401/)) {
      return null;
    }

    return event;
  },
  // ATL-810 TODO: reenable with sampling
  // https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/
  ignoreErrors: [/401/],
  // Capture 100% of traces in pre-production and 10% in production
  tracesSampleRate: process.env.REACT_APP_ENV === 'production' ? 0.1 : 1.0
});

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: 'care-team-tools-app',
  env: process.env.REACT_APP_ENV,
  sampleRate: 100,
  trackInteractions: false,
  version: process.env.REACT_APP_RELEASE,
  useSecureSessionCookie: true
});

/* eslint-disable import/no-unassigned-import, @typescript-eslint/no-require-imports */
require('velocity-animate');
require('velocity-animate/velocity.ui');
/* eslint-enable import/no-unassigned-import, @typescript-eslint/no-require-imports */

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <SecurityContainer>
        <ApolloContainer>
          <App />
        </ApolloContainer>
      </SecurityContainer>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root') as HTMLElement
);
