import * as R from 'ramda';
import * as moment from 'moment-timezone';

import { RootState } from '../reducers/index';
import { createPainLogRecord } from '../reducers/pain-log';
import { selectUser } from './user';
import { createSelector } from 'reselect';
import { ERR_UNREACHABLE_CODE } from '../../errors';
import { PainLog } from '../../api-client/index';
import { selectScreener } from './screener';
import { isNumber, formatToInt } from '../../utils/number';
import { DEFAULT_TIMEZONE } from '../../utils/user/timezone';

export function sortPainReverseWeek(a: PainLog, b: PainLog) {
  if (!isNumber(a.week_index) || !a.created_at) return -1;
  if (!isNumber(b.week_index) || !b.created_at) return 1;

  if (b.week_index === a.week_index) {
    return moment.utc(b.created_at).valueOf() - moment.utc(a.created_at).valueOf();
  }

  return b.week_index - a.week_index;
}

export function selectPainLogs(state: RootState) {
  return state.painLog;
}

export const selectPainLogsByUser = createSelector(
  [selectPainLogs, selectUser],
  (painLogs, user) =>
    user && user.painLogs ? user.painLogs.map((id) => painLogs.get(id)) : []
);

export const selectSortedUserPainLogs = createSelector(
  [selectPainLogsByUser],
  (painLogs) => painLogs ? painLogs.sort(sortPainReverseWeek) : []
);

export const selectFirstPainLogFactory = () => createSelector(
  [selectSortedUserPainLogs],
  (painLogs) => painLogs[0] || createPainLogRecord({ id: NaN })
);

export const selectLastWeekAvgPain = createSelector(
  [selectUser, selectPainLogs],
  (user, painLogs) => {
    if (!user) return undefined;
    const weekIndex = user.team && user.team.currentTeamWeekNumber;

    if (weekIndex === undefined || weekIndex === null) return undefined;

    const lastWeekLogs = painLogs.filter((l) => !!l && l.week_index === weekIndex - 1);

    return lastWeekLogs.reduce((acc, l) => {
      if (acc === undefined || l === undefined) throw new Error(ERR_UNREACHABLE_CODE);
      return l.pain ? acc + l.pain / lastWeekLogs.count() : acc;
    }, 0);
  }
);

/**
 * Get a running average of pain logs per week. The output of this selector is an array of numbers, where the first
 * entry is the average pain for the first week (week 0) all the way up to the last week available. Gaps in the logs
 * are filled in with the previous week's average. If there are no pain logs recorded for week 0, the screener's pain
 * log will be used as the week 0 average.
 */
export const selectWeeklyAveragePain = createSelector(
  [selectPainLogsByUser, selectScreener],
  (painLogs, screener) => {
    let endWeek = 0;
    painLogs.forEach((l) => {
      if (l && isNumber(l.week_index) && l.week_index > endWeek) endWeek = l.week_index;
    });
    let lastPain = screener && screener.pain || 0;
    const averages = new Array<number>();
    for (let i = 0; i <= endWeek; i++) {
      const weekLogs = painLogs.filter((l) => !!l && l.week_index === i);
      if (weekLogs.length > 0) {
        lastPain = weekLogs.reduce(
          (acc, l) => (l && l.pain && isNumber(acc)) ? acc + l.pain / weekLogs.length : 0, 0);
      }
      averages.push(formatToInt(lastPain));
    }
    return averages;
  }
);

export const pickMaxPainLog = (logs: PainLog[]): PainLog => {
  if (logs.length === 1) return logs[0];

  let maxLog = logs[0];

  for (let i = 1; i < logs.length; i++) {
    const nextLog = logs[i];
    maxLog = isNumber(maxLog.pain) && isNumber(nextLog.pain) && maxLog.pain >= nextLog.pain ? maxLog : nextLog;
  }
  return maxLog;
};

export const groupBySameDay = (log: PainLog) => moment.tz(log.created_at, DEFAULT_TIMEZONE).format('M/D');

export const selectMaxDailyPainLogs = createSelector([selectSortedUserPainLogs, selectUser],
  (sortedLogs, user) => R.compose(R.values, R.groupBy(groupBySameDay))(sortedLogs)
    .map(pickMaxPainLog));
