import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import * as EmptyStatePlants from '../../images/emptyStatePlants.png';

const Image = styled.img`
    margin: 20px;
    max-width: 232px;
`;

const ImageWrapper = styled.div`
    width: 73.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyledTypography = styled(Typography)`
    color: rgba(0, 0, 0, 0.54);
`;

const EmptyCommunicationState: React.FC = () =>
  <ImageWrapper>
    <Image
      src={EmptyStatePlants}
      alt="watering pot and plants"
    />
    <StyledTypography
      align="center"
      variant="body2"
      data-testid="emptyStateText"
    >
        This user has been transferred <br/>
        to their primary coach
    </StyledTypography>
  </ImageWrapper>;

export default EmptyCommunicationState;
