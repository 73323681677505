import * as React from 'react';
import { RefreshRounded } from '@material-ui/icons';
import { connect } from 'react-redux';
import HHModal from './HHModal';
import TodoTeamList from './TodoTeamList';
import ConversationTabContainer from '../../containers/communications/ConversationTabContainer';
import ConversationContainerV2 from '../../containers/communications/v2/ConversationContainerV2';
import UserProfileCommunicationContainer from '../../containers/communications/UserProfileCommunicationContainer';
import EmptyCommunicationState from '../../components/communications/EmptyCommunicationState';
import './TodoCommunications.scss';
import {
  UnreadMessageTitles,
  Admin,
  TaskKind,
  OrderedDoTaskKindTitles
} from '../../api-client';
import { v4 as uuid } from 'uuid';
import { DispatchMap } from '../../redux/actions/interfaces';
import { RootState } from '../../redux/reducers';
import { getTaskUsers } from '../../redux/actions/tasks';
import { clearTaskUserCounts, updateIsOpen } from '../../redux/actions/ui-state';
import { resetUsersOnboarded } from '../../redux/actions/user';
import {
  selectAdmin,
  selectFeatureFlags,
  selectTodoTeamIds,
  selectHasMoreTasks,
  showTodoRefresh,
  selectFirstUserTodo,
  selectOnboardedUsers,
  ComputedSortedConversationItem
} from '../../redux/selectors';
import { getUnseenMessages } from '../../redux/actions/conversations';
import { isEqual } from 'lodash';
import { isSocketDisconnected } from '../../redux/selectors/pubsub';
import { communicationsServiceMessaging } from '../../features';
import { openMassMessagingTab } from '../../containers/massmessaging/MassMessagingContainer';

export interface TodoCommunicationsProps {
  todo: string;
}

type ConnectProps = DispatchMap<{
  teamIds: number[];
  onboardedUsers: number[];
  showRefresh: boolean;
  hasMoreTasks?: boolean;
  firstTeamConversationItem?: ComputedSortedConversationItem;
  fetchTaskUsers: typeof getTaskUsers;
  resetTaskUserCounts: typeof clearTaskUserCounts;
  fetchUnseenMessages: typeof getUnseenMessages;
  admin: Admin;
  socketDisconnect: boolean;
  updateOpenModal: typeof updateIsOpen;
  communicationsServiceMessagingEnabled?: boolean;
  resetOnboardedUsers: typeof resetUsersOnboarded;
}>;

type Props = TodoCommunicationsProps & ConnectProps;

const hasMassMessagingUsers = (todo: string) =>
  todo === TaskKind.inactiveFourWeeks || todo === TaskKind.inactiveFiveWeeks;

const contentProps = {
  title: 'Send Mass Message Draft(s)',
  body: <div>You must review one or more mass message drafts before completing this task for individual users.</div>,
  primaryBtnText: 'REVIEW',
  secondaryBtnText: '✓ DONE'
};

export const TodoCommunications: React.FC<Props> = ({
  todo,
  teamIds,
  fetchTaskUsers,
  showRefresh,
  hasMoreTasks,
  resetTaskUserCounts,
  firstTeamConversationItem,
  fetchUnseenMessages,
  admin,
  socketDisconnect,
  updateOpenModal,
  communicationsServiceMessagingEnabled,
  resetOnboardedUsers,
  onboardedUsers
}) => {
  const [selectedUserId, setSelectedUserId] = React.useState<number>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [selectedConversationId, setSelectedConversationId] = React.useState<string>();
  const firstLoad = React.useRef(true);
  const modalParentRef = React.useRef<HTMLDivElement>(null);
  const inlineStyleModalProps = { position: 'absolute' };
  const isUserOnboarded = selectedUserId && onboardedUsers.some(id => id === selectedUserId);

  const getTaskUserList = () => {
    if (todo !== 'unreadMessage') {
      fetchTaskUsers(todo).then(res => {
        setLoading(false);
        if (hasMassMessagingUsers(todo) && res.result.users.length) {
          updateOpenModal(true);
        }
      });
    } else {
      fetchUnseenMessages({}).then(data => {
        setLoading(false);
      });
    }
  };

  const refresh = () => {
    setLoading(true);
    resetTaskUserCounts({ todoKey: todo });
    resetOnboardedUsers();
    getTaskUserList();
  };

  React.useEffect(() => {
    getTaskUserList();
  }, [todo]);

  React.useEffect(() => {
    setSelectedUserId(firstTeamConversationItem?.user.id);
    setSelectedConversationId(firstTeamConversationItem?.conversation?.id);
  }, [loading, firstTeamConversationItem?.user.id]);

  React.useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }
    if (!socketDisconnect) {
      fetchUnseenMessages({}, true).then(data => {
        setLoading(false);
      });
    }
  }, [socketDisconnect]);

  const conditionallyRenderConversationContainer = () => {
    if (loading || !selectedUserId || isUserOnboarded) {
      return null;
    } else if (communicationsServiceMessagingEnabled) {
      return <ConversationContainerV2 userId={selectedUserId} />;
    } else if (selectedConversationId) {
      return (
        <ConversationTabContainer
          userId={selectedUserId}
          activeConversationId={selectedConversationId}
          setActiveConversationId={setSelectedConversationId}
        />
      );
    }

    return null;
  };

  const conditionallyRenderUserProfileContainer = () =>
    !loading &&
    selectedUserId &&
    !isUserOnboarded &&
    (communicationsServiceMessagingEnabled || selectedConversationId) && (
      <UserProfileCommunicationContainer userId={selectedUserId} key={selectedUserId} />
    );

  return (
    <div ref={modalParentRef} className='todo-communications-container'>
      {modalParentRef.current && hasMassMessagingUsers(todo) && (
        <HHModal
          modalClassName='mass-messaging'
          modalContent={contentProps}
          clickOkFn={openMassMessagingTab}
          disableBackDropClick={true}
          container={modalParentRef.current}
          inlineStyle={inlineStyleModalProps}
          backdropStyle={inlineStyleModalProps}
        />
      )}
      {!loading && (
        <div className='todo'>
          <div className={!teamIds || !teamIds.length ? 'todo--empty' : 'todo__header'}>
            <div className='todo__header__contents'>
              <h4>
                {UnreadMessageTitles[todo] || OrderedDoTaskKindTitles[todo]}
              </h4>
              <div className='todo__refresh' onClick={() => refresh()}>
                <div className={hasMoreTasks ? 'todo__refresh__text' : 'todo__refresh__text--hide'}>
                  {UnreadMessageTitles[todo] ? 'New messages' : 'New tasks'}
                </div>
                <div className={hasMoreTasks || showRefresh ? 'todo__refresh__icon' : 'todo__refresh__icon--hide'}>
                  <RefreshRounded color='inherit' />
                </div>
              </div>
            </div>
          </div>
          {!loading && teamIds && teamIds.length ? (
            <div className='todo__team-list'>
              {teamIds.map(teamId => (
                <TodoTeamList
                  key={uuid()}
                  teamId={teamId}
                  todo={todo}
                  selectedUserId={selectedUserId}
                  setSelectedUserId={setSelectedUserId}
                  setSelectedConversationId={setSelectedConversationId}
                />
              ))}
            </div>
          ) :
          // If state.loading = false && !teamIds, display the appropriate empty state
            (
              <div className='todo__team-list--empty'>
                {UnreadMessageTitles[todo] ? 'No users with unread messages' : 'No users with this task type.'}
              </div>
            )}
        </div>
      )}
      {conditionallyRenderConversationContainer()}
      {conditionallyRenderUserProfileContainer()}
      {!!isUserOnboarded && <EmptyCommunicationState />}
    </div>
  );
};

const mapStateToProps = (state: RootState, { todo }: TodoCommunicationsProps) => ({
  teamIds: selectTodoTeamIds(state, todo),
  hasMoreTasks: selectHasMoreTasks(state, todo),
  showRefresh: showTodoRefresh(state, todo),
  firstTeamConversationItem: selectFirstUserTodo(state, todo),
  admin: selectAdmin(state),
  socketDisconnect: isSocketDisconnected(state),
  communicationsServiceMessagingEnabled: selectFeatureFlags(state).get(communicationsServiceMessaging),
  onboardedUsers: selectOnboardedUsers(state)
});

const mapDispatchToProps = {
  fetchTaskUsers: getTaskUsers,
  resetTaskUserCounts: clearTaskUserCounts,
  fetchUnseenMessages: getUnseenMessages,
  updateOpenModal: updateIsOpen,
  resetOnboardedUsers: resetUsersOnboarded
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(TodoCommunications, isEqual));
