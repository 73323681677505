import { useQuery } from '@apollo/client';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { Team } from '../../graphql/models/Team';
import { GET_TEAMS } from '../../graphql/queries/Teams';
import { ERR_TEAM_PARSE_FAIL } from '../../errors';
import TeamList from '../../components/communications/TeamList';
import TodoList from '../../components/communications/TodoList';

export const TEAMS_PER_PAGE = 50;

interface Response {
  teams: Team[];
}

interface TeamListContainerProps {
  setSelectedTeam(team?: Team): void;
  setSelectedTeamId(teamId?: string): void;
  selectedTeam?: Team;
  setSelectedTodo(task?: string): void;
  selectedTodo?: string;
  selectedTeamId?: string;
}

const TeamListContainer: React.FC<TeamListContainerProps> = ({
  setSelectedTeam,
  setSelectedTeamId,
  selectedTeam,
  selectedTeamId,
  selectedTodo,
  setSelectedTodo }) => {
  const [page, setPage] = React.useState(0);
  const [hasNextPage, setHasNextPage] = React.useState<boolean>(true);
  const { loading, error, data, fetchMore } = useQuery<Response, {
    itemsPerPage: number;
    page: number;
  }>(GET_TEAMS, {
    variables: {
      itemsPerPage: TEAMS_PER_PAGE,
      page: 0
    },
    fetchPolicy: 'cache-and-network',
    displayName: 'GET_TEAMS',
    notifyOnNetworkStatusChange: true
  });

  const loadMore = () => {
    if(!hasNextPage || error || loading) return;
    const nextPageNumber = page + 1;
    return fetchMore({
      variables: {
        itemsPerPage: TEAMS_PER_PAGE,
        page: nextPageNumber
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        setPage(nextPageNumber);
        if (!fetchMoreResult || !fetchMoreResult.teams.length) {
          setHasNextPage(false);
          return prev;
        }
        return {
          ...prev,
          teams: [...prev.teams, ...fetchMoreResult.teams]
        };
      }
    });
  };

  const scrollParentRef = React.useRef(null);
  if (error) {
    <Redirect to="/" />;

    throw new Error(`${ERR_TEAM_PARSE_FAIL}: ${error}`);
  }
  if (loading && (!data?.teams || data.teams.length === 0)) return <Loader active size="small" />;
  if (!data || data.teams.length === 0) {
    return (
      <div style={{ color: 'white', width: '250px', padding: '10px' }}>
        No Teams
      </div>
    );
  }
  return (
    <div ref={scrollParentRef} style={{ backgroundColor: 'rgb(69, 90, 100)', overflowY: 'auto' }}>
      <TodoList
        selectedTeam={selectedTeam}
        setSelectedTeam={setSelectedTeam}
        setSelectedTeamId={setSelectedTeamId}
        selectedTodo={selectedTodo}
        setSelectedTodo={setSelectedTodo}
        selectedTeamId={selectedTeamId} />
      <TeamList
        parentNode={scrollParentRef}
        selectedTeam={selectedTeam}
        selectedTeamId={selectedTeamId}
        isLoading={false}
        currentPage={page}
        loadMore={loadMore}
        setSelectedTodo={setSelectedTodo}
        setSelectedTeam={setSelectedTeam}
        setSelectedTeamId={setSelectedTeamId}
        teams={data.teams}
      />
    </div>
  );
};

export default TeamListContainer;
