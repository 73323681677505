import { gql } from '@apollo/client';

export const GET_TEAMS = gql`
  query Teams($page: Int, $itemsPerPage: Int) {
    teams(page: $page, itemsPerPage: $itemsPerPage) {
      id
      name
      phoenix
      currentTeamWeekNumber
      userCount
    }
  }
`;

export const GET_TEAM = gql`
  query Team($id: ID!) {
    team(id: $id) {
      id
      name
      currentTeamWeekNumber
    }
  }
`;

export const GET_ONBOARDING_USERS_BY_TEAM_ID = gql`
  query GetTeam($id: ID!, $onboarding: Boolean) {
    team(id: $id, onboarding: $onboarding) {
      id
      name
      currentWeek
      users(onboarding: true) {
        id
        avatarUrl
        contactMethod
        firstName
        lastName
        tags
        uuid
        enrollmentStatus {
          status
        }
        etSessions {
          startedAt
          userId
          complete
        }
        latestShipment {
          estimatedDeliveryAt
          preparedAt
          id
          userId
          trackingStatus
          deliveredAt
          trackingNumber
          trackingUrl
        }
      }
    }
  }
`;

export const GET_ONBOARDING_TEAMS = gql`
  query teams($page: Int, $itemsPerPage: Int, $onboarding: Boolean) {
    teams(page: $page, itemsPerPage: $itemsPerPage, onboarding: $onboarding) {
      id
      name
      currentWeek
    }
  }
`;

export const GET_TEAMS_BFF = `
  query teams($page: Int!, $itemsPerPage: Int!) {
    teams(page: $page, itemsPerPage: $itemsPerPage)
  }
`;

export const GET_TEAM_USERS = gql`
  query Team($id: ID!) {
    team(id: $id) {
      users {
        id
        uuid
        phoenix
        firstName
        lastName
        avatarUrl
        tags
        lastActive
        phoneNumber
        contactMethod
        timezone
        pathways {
          currentWeek
        }
        team {
          id
          currentTeamWeekNumber
        }
        userStatus {
          userId
          name
          description
          startsOn
          endsOn
        }
      }
    }
  }
`;
/**
 * This query contains required attributes for downstream components:
 * id: queries rely on id to be present
 * phoenix: components rely on phoenix to make additional queries
 * contactMethod: determines whether the user's name is struck through.
 * conversationIds: components rely on conversation ids to make additional queries.
 */
export const GET_CONVERSATIONS_USERS = gql`
  query ConversationUsers($id: ID!) {
    team(id: $id) {
      users {
        id
        uuid
        phoenix
        firstName
        lastName
        avatarUrl
        timezone
        lastActive
        phoneNumber
        conversationIds
        contactMethod
        userStatus {
          userId
          name
          description
          startsOn
          endsOn
        }
        tags
      }
    }
  }
`;

export const GET_USERS_BY_TEAM_ID = `
  query getTeamUsers($teamId:ID!, $adminUuid: ID!, $itemsPerPage: Int!, $page: Int!) {
    team(id: $teamId) {
      id
      name
      currentWeek
      users(itemsPerPage: 1000) {
        id
        uuid
        phoenix
        firstName
        lastName
        avatarUrl
        timezone
        lastActive
        phoneNumber
        contactMethod
        messageData(adminUuid: $adminUuid, itemsPerPage: $itemsPerPage, page: $page) {
          messages {
            uuid
            type
            status
            body
            isUnread
            sender {
              uuid
              type
            }
            recipient {
              uuid
              type
            }
            createdAt
            updatedAt
            sendAt
            transmittedAt
          }
        }
      }
    }
  }
`;
