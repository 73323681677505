import * as React from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DispatchMap } from '../../redux/actions/interfaces';
import { RootState, UserRecord, TeamRecord, TaskRecord } from '../../redux/reducers';
import { selectUser, selectTeam, isRequiredCommentTaskKind } from '../../redux/selectors';
import { mixpanelTrack } from '../../mixpanel/mixpanel';
import { PlaylistAddCheck, Done } from '@material-ui/icons';
import './TaskCardContainer.scss';

import {
  Button,
  Icon,
  Grid,
  Message,
  SemanticCOLORS,
  Segment
} from 'semantic-ui-react';

import { LIGHTERGREY } from '../../colors';

import { getKindColor } from '../../utils/tasks';
import { UserProfileRoutes } from '../../utils/user/routes';
import { TaskKind } from '../../api-client';

// Task Actions
import TaskCompleteModal from './TaskCompleteModal';
import OverflowMenu from '../../components/tasks/OverflowMenu';

// Card types
import RuleDrivenTaskContent from '../../components/tasks/TaskCards/RuleDrivenTaskContent';
import { actionCreators as tasksActions } from '../../redux/actions/tasks';

export interface TaskCardContainerProps {
  task: TaskRecord;
  expanded: boolean;
  grouped?: boolean;
  inProfile?: boolean;
  completed?: boolean;
  showSnackBar?(msg: string): void;
}

type ConnectProps = DispatchMap<{
  user?: UserRecord;
  team: TeamRecord;
  completeClinical: typeof tasksActions.complete;
}>;

// needed for styled components
interface StyledCompProps {
  className?: string;
}

interface TaskCardProps {
  task: TaskRecord;
  user?: UserRecord;
  grouped?: boolean;
  inProfile?: boolean;
  completed?: boolean;
  actionFn?(): void;
  handleCheckClick(): void;
  triggerDone(): void;
  triggerModalOpen?(): void;
  triggerRejection(): void;
}

interface State {
  isRejection: boolean;
  taskCompleteModalOpened: boolean;
  rescheduleModalOpened: boolean;
  completed: boolean;
  ref: number;
}

type Props = TaskCardContainerProps & ConnectProps;

/**
 * GridContainer used for layout and left task color
 */
interface GridContainerProps extends StyledCompProps {
  kind: TaskKind;
  grouped?: boolean;
}
const GridContainer: React.FunctionComponent<GridContainerProps> = ({
  className,
  kind,
  children
}) => (
  <Grid
    as={Segment}
    padded={false}
    color={getKindColor(kind, { isSemantic: true })}
    className={className}
  >
    {children}
  </Grid>
);

const StyledGridContainer = styled(GridContainer)`
  max-width: 620px;
  margin: 0 auto !important;
  background-color: ${(props: { grouped: boolean }) => props.grouped ? LIGHTERGREY : 'white'} !important;
`;

export const trackedActionFn = (taskKind: string, fn?: () => string) => {
  fn &&
    fn() &&
    mixpanelTrack('Task Link clicked', {
      TaskType: taskKind,
      TaskLink: 'Button',
      Origin: '/tasks'
    });
};

export const renderActionButton = (
  kind: string,
  actionFn: () => string,
  buttonLabel?: string
) => {
  const trackProfileClick = () => trackedActionFn(kind, actionFn);
  return (
    <Button
      className="rule-task-action"
      size="mini"
      color="teal"
      compact
      circular
      onClick={trackProfileClick}
    >
      <Link to={actionFn()} style={{ textDecoration: 'none', color: 'white' }}>
        {buttonLabel}
      </Link>
    </Button>
  );
};

function cardHOC<T extends object>(
  WrappedComponent: React.ComponentType<T>,
  kind: TaskKind,
  actionFn?: () => string,
  buttonLabel?: string
) {
  const Card: React.FunctionComponent<TaskCardProps & T> = props => {
    const extraProps: { color?: SemanticCOLORS } = {};
    if (props.completed) {
      extraProps.color = 'green';
    }
    return (
      <StyledGridContainer kind={kind} grouped={!!props.grouped}>
        <Grid.Row columns={3}>
          <Grid.Column width={3} verticalAlign="top">
            <Button
              disabled={!!props.completed}
              style={{ padding: '5px', height: '32px', width: '32px' }}
              icon={isRequiredCommentTaskKind(props.task.kind) ? <PlaylistAddCheck />
                : <Done />}
              onClick={props.handleCheckClick}
              circular
              {...extraProps}
            />
            {!props.completed && (
              <OverflowMenu
                triggeredBy={
                  <Button
                    style={{ background: 'transparent' }}
                    compact
                    icon={<Icon
                      style={{ position: 'absolute', top: '10px' }}
                      name="ellipsis vertical"
                      bordered={false} />}
                  />
                }
                triggerModalOpen={props.triggerModalOpen}
                triggerRejection={props.triggerRejection}
                withoutNote={isRequiredCommentTaskKind(props.task.kind)}
              />
            )}
          </Grid.Column>
          <Grid.Column width={10}>
            <Grid.Row centered>
              <WrappedComponent {...props} />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column
            width={3}
            floated="right"
            textAlign="right"
            verticalAlign="top"
          >
            {actionFn && renderActionButton(kind, actionFn, buttonLabel)}
          </Grid.Column>
        </Grid.Row>
      </StyledGridContainer>
    );
  };
  return Card;
}

/**
 * HOW TO USE
 * Build your task card content component in the components/tasks/TaskCards directory.
 * Import your newly created component and add an enum Key/Value pair to the component map.
 * Import this TaskCardContainer anywhere you need a task card component.
 *
 * @param inProfile<boolean> - conditionally format the task if it's in profile
 * @param grouped<boolean> - conditionally formats the wrapped task if it's in an accordion
 */
class TaskCardContainer extends React.PureComponent<Props, State> {
  taskCardRef: React.RefObject<HTMLDivElement>;
  state: State = {
    taskCompleteModalOpened: false,
    isRejection: false,
    rescheduleModalOpened: false,
    completed: false,
    ref: 0
  };

  constructor(props: Props) {
    super(props);
    this.taskCardRef = React.createRef();
  }
  triggerUiComplete = (msg: string) => {
    this.setState({ completed: true });
    this.props.showSnackBar && this.props.showSnackBar(msg);
  };

  getTaskCardHeight = () => this.state.ref;

  handleCheckClick = () => {
    const resolveMsg = 'Task completed.';
    if (isRequiredCommentTaskKind(this.props.task.kind)) {
      this.setState({ taskCompleteModalOpened: true });
    } else {
      this.triggerUiComplete(resolveMsg);
      setTimeout(() => {
        this.props.completeClinical(resolveMsg, this.props.task.id, false);
      },
      300);
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (this.props.expanded && this.state.ref === 0) {
      this.setState({
        ref: this.taskCardRef.current?.offsetHeight || 0
      });
    }
  }

  triggerModalOpen = () => this.setState({ taskCompleteModalOpened: true });

  handleOpenRejectionClick = () => {
    this.setState({ taskCompleteModalOpened: true, isRejection: true });
  };

  handleCloseCheckModal = () =>
    this.setState({
      taskCompleteModalOpened: false,
      isRejection: false
    });

  getTaskCards = (kind?: TaskKind | null) => {
    switch (kind) {
      case TaskKind.painUptick:
      case TaskKind.healthLogNote:
        return cardHOC(
          RuleDrivenTaskContent,
          kind,
          this.handleRedirectToProfilePain,
          'PAIN TABLE'
        );
      case TaskKind.surgeryIncr:
        return cardHOC(
          RuleDrivenTaskContent,
          kind,
          this.handleRedirectToProfileSurg,
          'SURGERY TABLE'
        );
      case TaskKind.firstEverEtAttempted:
      case TaskKind.firstEverEtSessionCompleted:
      case TaskKind.readyForCoaching:
      case TaskKind.performSurgeryOutreach:
        return cardHOC(
          RuleDrivenTaskContent,
          kind
        );
      case TaskKind.reEngager:
      case TaskKind.newHighUtilizationUser:
        return cardHOC(
          RuleDrivenTaskContent,
          kind,
          this.handleRedirectToProfileEng,
          'USER PROFILE'
        );
      default:
        return undefined;
    }
  };

  handleRedirectToProfileEng = () => `/user/${this.props.task.user}/${UserProfileRoutes.Engagement}`;

  handleRedirectToProfilePain = () => `/user/${this.props.task.user}/${UserProfileRoutes.Pain}`;

  handleRedirectToProfileSurg = () => `/user/${this.props.task.user}/${UserProfileRoutes.Surgery}`;

  render() {
    const { task, inProfile } = this.props;
    const Comp = this.getTaskCards(task.kind);
    if (!Comp) {
      return <Message content="No task card created for that kind!" negative />;
    }
    let divStyle = {};
    if (this.state.completed) {
      divStyle = {
        height: 0,
        overflow: 'hidden'
      };
    } else {
      divStyle = {
        height: this.getTaskCardHeight() ? this.getTaskCardHeight() : 'auto',
        overflow: 'visible'
      };
    }
    return (
      <div style={divStyle} ref={this.taskCardRef}
        className={this.state.completed ? 'task-card-container--complete' : 'task-card-container'}>
        <TaskCompleteModal
          taskId={task.id}
          taskKind={task.kind}
          inProfile={inProfile}
          isRejection={this.state.isRejection}
          open={this.state.taskCompleteModalOpened}
          onClose={this.handleCloseCheckModal}
          triggerUiComplete={this.triggerUiComplete}
        />
        <Comp
          {...this.props}
          triggerDone={this.handleCheckClick}
          triggerRejection={this.handleOpenRejectionClick}
          triggerModalOpen={this.triggerModalOpen}
          handleCheckClick={this.handleCheckClick}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  completeClinical: tasksActions.complete
};

const mapStateToProps = (state: RootState, props: TaskCardContainerProps) => ({
  user: selectUser(state, { userId: props.task.user || NaN }),
  team: selectTeam(state, { teamId: props.task.team || NaN })
});


export default connect(mapStateToProps, mapDispatchToProps)(React.memo(TaskCardContainer, isEqual));
