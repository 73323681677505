import * as React from 'react';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Button,
  MenuItem,
  Divider,
  ListSubheader,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { ExpandMore, Edit, Close, DeleteOutline, SaveAlt } from '@material-ui/icons';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { markdownToDraft, draftToMarkdown } from 'markdown-draft-js';
import { debounce } from 'lodash';
import './UserNoteContainer.scss';
import UserNotesEditor from '../user/UserNotesEditor';
import UserNoteDropdown from '../../components/communications/UserNoteDropdown';
import { NoteType, NoteStatus, Note } from '../../api-client/interfaces';
import { DispatchThunk } from '../../redux/actions';
import { updateNote } from '../../redux/actions/notes';
import { RequestStatus } from '../../api-client/endpoints';
import ModalWrapper from '../../components/modal/ModalWrapper';

enum NoteTypeSectionTitle {
  Coaching = 'Coaching',
  PhysicalTherapy = 'Physical Therapy',
  EMO = 'EMO'
}

const noteTypeOptions = [
  {
    type: 'subheader',
    key: 'Coaching',
    text: NoteTypeSectionTitle.Coaching
  },
  {
    type: 'item',
    key: 'CoachCall',
    text: NoteType.CoachCall
  },
  {
    type: 'item',
    key: 'ResourceShared',
    text: NoteType.ResourceShared
  },
  {
    type: 'item',
    key: 'ParticipantOps',
    text: NoteType.ParticipantOps
  },
  {
    type: 'item',
    key: 'Goals',
    text: NoteType.Goals
  },
  {
    type: 'item',
    key: 'EmailHighlights',
    text: NoteType.EmailHighlights
  },
  {
    type: 'item',
    key: 'PersonalizationAndBarriers',
    text: NoteType.PersonalizationAndBarriers
  },
  {
    type: 'item',
    key: 'CoachClinicalNote',
    text: NoteType.CoachClinicalNote
  },
  {
    type: 'item',
    key: 'BehavioralHealthConcern',
    text: NoteType.BehavioralHealthConcern
  },
  {
    type: 'item',
    key: 'Miscellaneous',
    text: NoteType.Miscellaneous
  },
  {
    type: 'divider',
    key: 'CoachingDivider'
  },
  {
    type: 'subheader',
    key: 'PhysicalTherapy',
    text: NoteTypeSectionTitle.PhysicalTherapy
  },
  {
    type: 'item',
    key: 'PTEvaluation',
    text: NoteType.PTEvaluation
  },
  {
    type: 'item',
    key: 'PTFollowUpVisit',
    text: NoteType.PTFollowUpVisit
  },
  {
    type: 'item',
    key: 'PTDischargeNote',
    text: NoteType.PTDischargeNote
  },
  {
    type: 'item',
    key: 'PTPlanOfCare',
    text: NoteType.PTPlanOfCare
  },
  {
    type: 'item',
    key: 'PTConsultNote',
    text: NoteType.PTConsultNote
  },
  {
    type: 'item',
    key: 'PTCall',
    text: NoteType.PTCall
  },
  {
    type: 'item',
    key: 'PTCancellation',
    text: NoteType.PTCancellation
  },
  {
    type: 'item',
    key: 'PTNoCall',
    text: NoteType.PTNoCall
  },
  {
    type: 'item',
    key: 'PTReferralToHCP',
    text: NoteType.PTReferralToHCP
  },
  {
    type: 'divider',
    key: 'PTDivider'
  },
  {
    type: 'subheader',
    key: 'EMO',
    text: NoteTypeSectionTitle.EMO
  },
  {
    type: 'item',
    key: 'EMOIntakeCall',
    text: NoteType.EMOIntakeCall
  },
  {
    type: 'item',
    key: 'EMOReportSummary',
    text: NoteType.EMOReportSummary
  },
  {
    type: 'item',
    key: 'EMOAdministrativeNote',
    text: NoteType.EMOAdministrativeNote
  },
  {
    type: 'item',
    key: 'EMONPNote',
    text: NoteType.EMONPNote
  },
  {
    type: 'item',
    key: 'EMOPhysicianNote',
    text: NoteType.EMOPhysicianNote
  },
  {
    type: 'item',
    key: 'EMOCarePlan',
    text: NoteType.EMOCarePlan
  }
];

export const noteTypeMenuItems = noteTypeOptions.map(item => {
  switch (item.type) {
    case 'divider':
      return <Divider key={item.key} />;
    case 'subheader':
      return (
        <ListSubheader className='note-type-subheader' key={item.key}>
          <Typography variant='subtitle2'>{item.text}</Typography>
        </ListSubheader>
      );
    default:
      return (
        <MenuItem className='note-type-menu-item' key={item.key} value={item.text}>
          <Typography variant='subtitle2'>{item.text}</Typography>
        </MenuItem>
      );
  }
});

export interface UserNoteContainerProps {
  note: Note;
  onDeleteDraft(): void;
  onExpandNote(): void;
  onExportNote(): void;
  body?: string;
  isNewNote?: boolean;
}

export const AutoSaveLabel: React.FC<{ updateRequestStatus: RequestStatus | null }> = ({ updateRequestStatus }) => {
  if (!updateRequestStatus || updateRequestStatus === RequestStatus.ERROR) {
    return null;
  }

  return (
    <div className='note-details__autosave'>
      {updateRequestStatus === RequestStatus.PENDING ? 'Saving...' : 'Saved'}
    </div>
  );
};

const UserNoteContainer = ({
  note,
  isNewNote = false,
  onDeleteDraft,
  onExpandNote,
  onExportNote
}: UserNoteContainerProps) => {
  const { id, author, userUuid, noteType = '', status, finishedAt, body = '', pdfReady } = note;

  const [noteExpanded, setNoteExpanded] = React.useState<boolean>(isNewNote);
  const [editorBody, setEditorBody] = React.useState(() => {
    const editor = body
      ? EditorState.createWithContent(convertFromRaw(markdownToDraft(body, { preserveNewlines: true })))
      : EditorState.createEmpty();
    return EditorState.moveFocusToEnd(editor);
  });
  const [noteTypeLabel, setNoteTypeLabel] = React.useState<NoteType | string>(noteType);
  const [noteTypeDropdownOpen, setNoteTypeDropdownOpen] = React.useState<boolean>(false);
  const [noteFinishDisabled, setNoteFinishDisabled] = React.useState(
    !editorBody.getCurrentContent().hasText() || !noteTypeLabel
  );
  const [noteStatus, setNoteStatus] = React.useState<NoteStatus>(status);
  const [noteFinishedAt, setNoteFinishedAt] = React.useState(finishedAt);
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);

  const [noteUpdateRequestStatus, setNoteUpdateRequestStatus] = React.useState<RequestStatus | null>(null);
  const dispatchThunk: DispatchThunk = useDispatch();

  const isDraft = noteStatus === NoteStatus.Draft;
  const dateOptions = { month: 'long', day: 'numeric', year: 'numeric' } as const;
  const expansionPanelClass = `user-note-container ${isDraft ? ' user-note-container--expanded--draft' : ''}`;
  const summaryDateClass = `note-summary-item note-summary-item--date
    ${isDraft ? 'note-summary-item--date--draft' : ''}`;

  const deleteContentProps = {
    title: 'Confirm Delete Note Draft',
    body: 'Are you sure you want to delete this note draft?',
    confirmBtnText: 'DELETE',
    dismissBtnText: 'CANCEL'
  };

  React.useEffect(() => {
    const text = convertToRaw(editorBody.getCurrentContent()).blocks[0].text.trim();
    if (text.length > 0 && noteTypeLabel) {
      setNoteFinishDisabled(false);
    }
  }, [editorBody.getCurrentContent(), noteTypeLabel]);

  const saveNote = React.useCallback(
    debounce((editorState: EditorState) => {
      setNoteUpdateRequestStatus(RequestStatus.PENDING);

      dispatchThunk(
        updateNote({
          id,
          author,
          userUuid,
          body: draftToMarkdown(convertToRaw(editorState.getCurrentContent()), { preserveNewlines: true })
        })
      ).then(res => {
        setNoteUpdateRequestStatus(RequestStatus.SUCCESS);
      });
    }, 1000),
    []
  );

  const handleOnChangeBody = React.useCallback(
    (editorState: EditorState) => {
      setNoteUpdateRequestStatus(null);
      const currentBody = editorBody.getCurrentContent();
      if (currentBody !== editorState.getCurrentContent() && editorState.getSelection().getHasFocus()) {
        setEditorBody(editorState);
        saveNote(editorState);
      }
    },
    [noteTypeLabel]
  );

  const handleFinishDraftNote = () => {
    setTimeout(() => {
      dispatchThunk(
        updateNote({
          id,
          author,
          userUuid,
          body: draftToMarkdown(convertToRaw(editorBody.getCurrentContent()), { preserveNewlines: true }),
          noteType: noteTypeLabel,
          status: NoteStatus.Final
        })
      ).then(res => {
        setNoteStatus(res.status);
        setNoteTypeLabel(res.noteType);
        setNoteUpdateRequestStatus(RequestStatus.SUCCESS);
        setNoteFinishedAt(res.finishedAt);
      });
    }, 1000);
  };

  const handleCloseClick = () => {
    setNoteExpanded(false);
  };

  const draftExpandCollapseIcon = (isExpanded: boolean) => (isExpanded ? <Close /> : <Edit />);

  const handleNoteExpandCollapse = (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    if (isExpanded) {
      onExpandNote();
    }

    if (!(noteTypeDropdownOpen || openDeleteModal)) {
      setNoteExpanded(isExpanded);
    }
  };

  const handleNoteTypeChange = React.useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value) {
      setNoteUpdateRequestStatus(RequestStatus.PENDING);
      dispatchThunk(
        updateNote({
          id,
          author,
          userUuid,
          noteType: event.target.value as string
        })
      ).then(res => {
        setNoteTypeLabel(res.noteType);
        setNoteUpdateRequestStatus(RequestStatus.SUCCESS);
      });
    }
  }, []);

  const handleDropdownOnOpen = (event: object): void => {
    setNoteTypeDropdownOpen(true);
  };

  const handleDropdownOnClose = (event: object): void => {
    setNoteTypeDropdownOpen(false);
  };

  const handleDeleteModalOpen = (event: React.MouseEvent<{}>): void => {
    event.stopPropagation();
    setOpenDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setOpenDeleteModal(false);
  };

  const handleDeleteDraftClick = () => {
    if (isDraft) {
      onDeleteDraft();
    }
  };

  const handleExportClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (pdfReady) {
      onExportNote();
    }
  };

  return (
    <ExpansionPanel className={expansionPanelClass} expanded={noteExpanded} onChange={handleNoteExpandCollapse}>
      <ExpansionPanelSummary
        className='note-summary'
        expandIcon={isDraft ? draftExpandCollapseIcon(noteExpanded) : <ExpandMore />}
      >
        <div className='header'>
          <div className='header__title'>
            {isDraft && noteExpanded ? (
              <UserNoteDropdown
                onChange={handleNoteTypeChange}
                onOpen={handleDropdownOnOpen}
                onClose={handleDropdownOnClose}
                noteTypeLabel={noteTypeLabel}
                noteTypeDropdownOpen={noteTypeDropdownOpen}
                noteTypeMenuItems={noteTypeMenuItems}
                isRequired={true}
                inputLabel='Note Type'
              />
            ) : (
              <Typography variant='subtitle1' className='note-draft-title'>
                {noteTypeLabel || 'Note Draft'}
              </Typography>
            )}
          </div>
          {pdfReady && (
            <div className='header__export-icon'>
              <SaveAlt onClick={handleExportClick} />
            </div>
          )}

          {isDraft && (
            <div className='header__delete-icon'>
              <DeleteOutline onClick={handleDeleteModalOpen} />
              <ModalWrapper
                modalClassName='note-delete'
                modalContent={deleteContentProps}
                onConfirm={handleDeleteDraftClick}
                onDismiss={handleDeleteModalClose}
                openModal={openDeleteModal}
              />
            </div>
          )}
        </div>
        <div className={`note-summary-items-container${noteExpanded && isDraft ? '--hidden' : ''}`}>
          <div className='note-summary-item note-summary-item--name'>{author.name}</div>
          <div className={summaryDateClass}>
            {isDraft ? 'Unfinished' : new Date(noteFinishedAt as string).toLocaleDateString('en-US', dateOptions)}
          </div>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className='note-details'>
        <div
          className={`note-details__editor-container
        ${!isDraft ? 'note-details--final' : ''}`}
        >
          <UserNotesEditor noteStatus={noteStatus} noteBody={editorBody} onEditorChange={handleOnChangeBody} />
        </div>
        <div className='note-details__button-container'>
          {isDraft && (
            <>
              <AutoSaveLabel updateRequestStatus={noteUpdateRequestStatus} />
              <Button
                variant='text'
                disableElevation
                color='primary'
                onClick={handleCloseClick}
                className='note-details__button'
              >
                Close
              </Button>
              <Button
                variant='text'
                disableElevation
                color='primary'
                onClick={handleFinishDraftNote}
                disabled={noteFinishDisabled}
                className='note-details__button'
              >
                Finish
              </Button>
            </>
          )}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default UserNoteContainer;
