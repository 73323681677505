import { useOktaAuth } from '@okta/okta-react';
import * as React from 'react';
import {
  CapabilitiesContext,
  HingeHealthSecurityContext,
  SecurityContext,
  SignInOptions
} from './hinge-health-security-context';

interface HingeHealthSecurityContextProviderProps {
  children: React.ReactNode;
  capabilitiesEndpoint: string | undefined;
}

const produceError = (err: Error | string | unknown): Error => {
  if (err instanceof Error) {
    return err;
  } else if (typeof err === 'string') {
    return new Error(err);
  } else {
    return new Error('Unable to retrieve capabilities');
  }
};

const HingeHealthSecurityContextProvider = ({
  children,
  capabilitiesEndpoint
}: HingeHealthSecurityContextProviderProps): JSX.Element => {
  const { oktaAuth, authState } = useOktaAuth();
  const [capabilitiesContext, setCapabilitiesContext] =
    React.useState<CapabilitiesContext>({ userCapabilities: null });

  const hingeHealthAuthState = !!authState ? {
    isAuthenticated: !!authState?.isAuthenticated,
    accessToken: authState?.accessToken
  } : null;

  const securityContext: SecurityContext = {
    hingeHealthAuthState,
    hingeHealthAuthClient: {
      signInWithRedirect: (options?: SignInOptions): Promise<void> =>
        oktaAuth.signInWithRedirect(options),
      signOut: (): Promise<void> => oktaAuth.signOut(),
      isAuthenticated: () => oktaAuth.isAuthenticated()
    },
    capabilities: capabilitiesContext
  };

  React.useEffect(() => {
    const retrieveCapabilities = async(): Promise<void> => {
      try {
        const { capabilities } = await fetch(capabilitiesEndpoint as string, {
          method: 'GET',
          headers: {
            authorization: `Bearer ${
              authState?.accessToken?.accessToken as string
            }`
          }
        }).then(res => res.json());

        setCapabilitiesContext({ userCapabilities: new Set(capabilities) });
      } catch (err) {
        setCapabilitiesContext({
          userCapabilities: null,
          error: produceError(err)
        });
      }
    };
    if (capabilitiesEndpoint && authState?.accessToken?.accessToken) {
      retrieveCapabilities();
    } else {
      setCapabilitiesContext({ userCapabilities: null });
    }
  }, [authState?.accessToken?.accessToken, capabilitiesEndpoint]);

  return (
    <HingeHealthSecurityContext.Provider value={securityContext}>
      {children}
    </HingeHealthSecurityContext.Provider>
  );
};

export default HingeHealthSecurityContextProvider;
