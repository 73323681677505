import * as React from 'react';
import { CallEnd, Videocam, VideocamOff, Mic, MicOff } from '@material-ui/icons';
import { Fab, Button } from '@material-ui/core';
import './VideoCall.scss';

interface Props {
  hasConnected: boolean;
  canRejoin: boolean;
  videoEnabled: boolean;
  isMicOn: boolean;
  onToggleConnect: () => void;
  onToggleCamera: () => void;
  onToggleMute: () => void;
  joining: boolean;
}

export default function VideoCallControls({ hasConnected, onToggleConnect, onToggleMute, videoEnabled, isMicOn,
                                            onToggleCamera, joining, canRejoin }: Props) {
  return (
    <div className="video-call-controls-container">
      {hasConnected ?
        (
          <>
            <Fab
              size="large"
              color="inherit"
              onClick={onToggleCamera}
              id="toggleCameraButton"
              disabled={joining}
              className={videoEnabled ? 'video-call-button' : 'disabled-video-call-button' }
            >
              {videoEnabled ? <Videocam fontSize="large" /> : <VideocamOff fontSize="large"/> }
            </Fab>
            <Fab
              size="large"
              color="inherit"
              onClick={onToggleMute}
              id="toggleMuteButton"
              disabled={joining}
              className={isMicOn ? 'video-call-button' : 'disabled-video-call-button' }
            >
              {isMicOn ? <Mic fontSize="large" />: <MicOff fontSize="large"/>}
            </Fab>
            <Fab
              size="large"
              color="default"
              onClick={onToggleConnect}
              id="endVideoCallButton"
              disabled={joining}
              className="end-call-button"
            >
              <CallEnd fontSize="large" />
            </Fab>
          </>
        )
        : (
          <Button
            onClick={onToggleConnect}
            id="joinVideoCallButton"
            disabled={joining}
            className="join-video-call-button"
          >
            {canRejoin ? 'Rejoin visit' : 'Join visit'}
          </Button>
        )}
    </div>
  );
}

