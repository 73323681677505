import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { RootState, TaskRecord, TeamRecord, UserRecord } from '../../../redux/reducers';
import {
  selectTaskCreatedAt,
  selectTeam,
  selectUser,
  selectUserCountByTaskKind,
  selectUserTasksCount,
  isClinicalTaskKind,
  isRequiredCommentTaskKind,
  selectAdmin
} from '../../../redux/selectors';

import { Button, Icon } from 'semantic-ui-react';

import { getKindColor } from '../../../utils/tasks';
import { Admin, ClinicalTaskKindTitles, OrderedDoTaskKindTitles, TaskKind } from '../../../api-client';
// Task Actions
import TaskCompleteModal from '../TaskCompleteModal';
import OverflowMenu from '../../../components/tasks/OverflowMenu';
import DistributedOutreachMenu from '../../../components/tasks/DistributedOutreachMenu';

import * as moment from 'moment-timezone';
import { DEFAULT_TIMEZONE } from '../../../utils/user/timezone';
import { TaskDetails } from './TaskDetails';
import './../Tasks.scss';
import { actionCreators as tasksActions } from '../../../redux/actions/tasks';
import { DispatchMap } from '../../../redux/actions/interfaces';
import { mixpanelTrack } from '../../../mixpanel/mixpanel';
import { updateTaskUserAndCount } from '../../../redux/actions/ui-state';
import { PlaylistAddCheck, Done } from '@material-ui/icons';

export interface TaskCardMessageContainerProps {
  task: TaskRecord;
  userId: number;
  grouped?: boolean;
  inProfile?: boolean;
  showSnackBar?(msg: string): void;
}

type ConnectProps =   DispatchMap<{
  count: number;
  user: UserRecord;
  team: TeamRecord;
  completeOutreach: typeof tasksActions.completeOutreachTask;
  completeClinical: typeof tasksActions.complete;
  updateTaskUserCount: typeof updateTaskUserAndCount;
  taskKindUserCount?: number;
  admin: Admin;
}>;

interface State {
  isRejection: boolean;
  taskCompleteModalOpened: boolean;
  rescheduleModalOpened: boolean;
  completed: boolean;
  message?: string;
}

type Props = TaskCardMessageContainerProps & ConnectProps;

const TITLES: { [key: string]: string } = {
  ...ClinicalTaskKindTitles,
  ...OrderedDoTaskKindTitles
};

const DivWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const isDismissableOnMessageSent = (kind: string | null | undefined) => {
  if ((kind && kind === TaskKind.firstEverEtSessionCompleted) || kind === TaskKind.readyForCoaching) {
    return true;
  }
  return kind && !ClinicalTaskKindTitles[kind];
};

class TaskCard extends React.PureComponent<Props, State> {
  state: State = {
    taskCompleteModalOpened: false,
    isRejection: false,
    rescheduleModalOpened: false,
    completed: false,
    message: ''
  };

  handleTriggerModalOpen = () => this.setState({ taskCompleteModalOpened: true });

  completeTaskClick = (message?: string) => {
    const completeTask = isClinicalTaskKind(this.props.task.kind) ?
      this.props.completeClinical : this.props.completeOutreach;
    const resolveMsg = message || 'Task completed.';

    if (isRequiredCommentTaskKind(this.props.task.kind)) {
      this.setState({ taskCompleteModalOpened: true });
    } else {
      completeTask(resolveMsg, this.props.task.id, false);
      if (this.props.taskKindUserCount && this.props.task.kind) {
        this.props.updateTaskUserCount({
          todoKey: this.props.task.kind as string,
          count: this.props.taskKindUserCount - 1,
          userId: this.props.userId
        });
      }
      mixpanelTrack('Task Closed', {
        TaskType: this.props.task.kind,
        CompletionMethod: message,
        Origin: '/messaging',
        UserId: this.props.userId,
        AdminId: this.props.admin.id
      });
      this.triggerUiComplete(resolveMsg);
    }
  };

  handleCheckClick = () => this.completeTaskClick();

  triggerUiComplete = (msg: string) => {
    this.props.showSnackBar && this.props.showSnackBar(msg);
    this.setState({ completed: true });
  };

  handleModalOpen = () => this.setState({ taskCompleteModalOpened: true });

  handleOpenRejectionClick = (message?: string) => {
    this.setState({ taskCompleteModalOpened: true, isRejection: true });
  };

  handleCloseCheckModal = (completed?: boolean) => {
    this.setState({
      taskCompleteModalOpened: false,
      isRejection: false,
      completed: !!completed
    });
    if (!!completed && this.state.message) {
      this.props.showSnackBar && this.props.showSnackBar(this.state.message);
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (this.props.taskKindUserCount !== prevProps.taskKindUserCount && this.props.taskKindUserCount === 0 &&
      !this.state.completed &&
      isDismissableOnMessageSent(this.props.task.kind)
    ) {
      this.props.showSnackBar && this.props.showSnackBar('Task completed by outbound message.');
      this.setState({
        completed: true
      });
    }
  }

  render() {
    const { task, inProfile, userId } = this.props;
    return (
      <div>
        <TaskCompleteModal
          taskId={task.id}
          taskKind={task.kind}
          userId={userId}
          inProfile={inProfile}
          isRejection={this.state.isRejection}
          open={this.state.taskCompleteModalOpened}
          onClose={this.handleCloseCheckModal}
          triggerUiComplete={this.triggerUiComplete}
        />
        <DivWrapper>
          <div
            className={ this.state.completed ? 'animate move' : 'animate' }
            style={{borderLeft: `2px solid ${getKindColor(this.props.task.kind)}`}
            }>
            <div style={{height: '50%', display: 'flex', justifyContent: 'space-between'}}>
              <div>
                <span style={{color: 'rgb(38, 50, 56)', fontSize: '15px',
                              marginRight: '8px',
                              fontWeight: 'bold'}}>{TITLES[this.props.task.kind as string]}</span>
                <span style={{color: 'rgb(144, 164, 174)',
                              fontSize: '13px', fontWeight: 'normal'}}>{moment.utc(selectTaskCreatedAt(this.props.task))
                    .tz(DEFAULT_TIMEZONE)
                    .startOf('hour')
                    .fromNow()}</span>
              </div>
              { this.props.task.kind && ClinicalTaskKindTitles[this.props.task.kind] &&
              <div className='task-card__options'>
                <div className='task-card__options__complete'>
                  <Button
                    style={{ background: 'transparent', paddingTop: 0, paddingBottom: 0 }}
                    icon={isRequiredCommentTaskKind(this.props.task.kind) ?
                      <PlaylistAddCheck /> : <Done />}
                    onClick={this.handleCheckClick}
                  />
                </div>
                <OverflowMenu
                  triggeredBy={
                    <Button className={'task-vertical'}
                      icon={<Icon name="ellipsis vertical" bordered={false}/>}
                    />
                  }
                  triggerModalOpen={this.handleTriggerModalOpen}
                  triggerRejection={this.handleOpenRejectionClick}
                  withoutNote={isRequiredCommentTaskKind(this.props.task.kind)}
                />
              </div>
              }
              { !isClinicalTaskKind(this.props.task.kind) && !this.state.completed &&
              <div>
                <span>
                  <DistributedOutreachMenu
                    triggeredBy={
                      <Button  className={'task-vertical'}
                        icon={<Icon name="ellipsis vertical" bordered={false}/>}
                      />
                    }
                    triggerDone={(message: string) => this.completeTaskClick(message)}
                    triggerRejection={this.handleOpenRejectionClick}
                  />
                </span>
              </div>
              }
            </div>
            <TaskDetails userId={this.props.userId} extras={this.props.task.extras} kind={this.props.task.kind}/>
          </div>
        </DivWrapper>
      </div>
    );
  }
}

const mapDispatchToProps = {
  completeClinical: tasksActions.complete,
  completeOutreach: tasksActions.completeOutreachTask,
  updateTaskUserCount: updateTaskUserAndCount
};

const mapStateToProps = (state: RootState, props: TaskCardMessageContainerProps) => ({
  user: selectUser(state, { userId: props.task.user || NaN }),
  team: selectTeam(state, { teamId: props.task.team || NaN }),
  taskKindUserCount: selectUserCountByTaskKind(state, { kind: props.task.kind, userId: props.userId }),
  count: selectUserTasksCount(state, props.userId),
  admin: selectAdmin(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskCard);
