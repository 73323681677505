import * as React from 'react';
import * as ReactRedux from 'react-redux';

import { isNullOrUndefined } from '../../../utils/helpers';
import { usePrevious } from '../../../utils/hooks/usePrevious';
import { DispatchThunk, messageV2Actions } from '../../../redux/actions';
import { RootState } from '../../../redux/reducers';
import { isEqual } from 'lodash';

import MessageListV2 from '../../../components/communications/v2/MessageListV2';
import { selectAdmin, selectUser } from '../../../redux/selectors';
import {
  selectUserMessages,
  selectUserMessagesPage
} from '../../../redux/selectors/messages.v2';

// Pagination hardcoded values
export const INITIAL_PAGE = 0;
export const ITEMS_PER_PAGE = 25;

export interface MessageListContainerV2Props {
  userId: number;
}

interface FetchPagedMessagesParams {
  page: number;
  itemsPerPage: number;
  totalPages?: number;
}

const MessageListContainerV2: React.FC<MessageListContainerV2Props> = ({
  userId
}) => {
  const user = ReactRedux.useSelector((state: RootState) =>
    selectUser(state, { userId })
  );
  if (!user) return null;
  /** Redux hooks */
  const dispatch: DispatchThunk = ReactRedux.useDispatch();

  /** State */
  const [loading, setLoadingState] = React.useState(false);
  const [indexToRender, setIndexToRender] = React.useState(0);

  const userMessagingMetaData = user.messaging;
  const currentPage = ReactRedux.useSelector(
    (state: RootState) =>
      selectUserMessagesPage(state, { userId }) || INITIAL_PAGE
  );
  const messages = ReactRedux.useSelector((state: RootState) =>
    selectUserMessages(state, { userId })
  );
  const admin = ReactRedux.useSelector(selectAdmin);

  const prevPage = usePrevious(currentPage);

  /** Event handlers */
  const fetchPagedMessage = ({
    page,
    itemsPerPage
  }: FetchPagedMessagesParams) => {
    if (isNullOrUndefined(admin.uuid)) {
      return Promise.reject('Admin uuid required to fetch messages.');
    }
    setLoadingState(true);
    return dispatch(
      messageV2Actions.getMessages({
        page,
        itemsPerPage,
        userId,
        adminUuid: admin.uuid
      })
    ).then(() => {
      setLoadingState(false);
    });
  };

  const fetchNextPage = () => {
    const nextPage = currentPage + 1;
    fetchPagedMessage({ page: nextPage, itemsPerPage: ITEMS_PER_PAGE }).then(
      () => {
        // Updates the indexToRender to the previous top of the page on paginated calls
        const indexOfTopOfPage =
          messages.length - nextPage * ITEMS_PER_PAGE - 1;
        setIndexToRender(indexOfTopOfPage);
      }
    );
  };

  const fetchScheduledMessages = () => {
    setLoadingState(true);
    return dispatch(messageV2Actions.getScheduledMessages()).then(() => {
      setLoadingState(false);
    });
  };

  /** Lifecycle methods */
  React.useEffect(() => {
    // Initial render
    Promise.all([
      fetchPagedMessage({
        page: INITIAL_PAGE,
        itemsPerPage: ITEMS_PER_PAGE
      }),
      fetchScheduledMessages()
    ]).then(() => {
      // After initial layout, updates the indexToRender to the very bottom
      // of the message list.
      setIndexToRender(messages.length - 1);
    });
  }, []);

  React.useEffect(() => {
    // Updates the indexToRender if the admin creates a new outbound message
    // Or if there's a new inbound message from a realtime event.
    // Do not rerender if the last call was a paginated call.
    if (currentPage === prevPage) {
      setIndexToRender(messages.length - 1);
    }
  }, [messages.length]);
  /** Render */
  return (
    <MessageListV2
      deleteMessage={messageV2Actions.deleteMessage}
      fetchNextPage={fetchNextPage}
      indexToRender={indexToRender}
      isLoading={loading}
      markMessageAsRead={() => ({})} // TODO: BAT-1321 update with action creator
      messages={messages}
      page={currentPage}
      totalPages={userMessagingMetaData.totalPages}
      updateMessage={messageV2Actions.updateMessage}
      user={user}
    />
  );
};

export default React.memo(MessageListContainerV2, isEqual);
