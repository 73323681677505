import { AccessToken } from '@okta/okta-auth-js';
import * as React from 'react';

export interface SignInOptions {
  originalUri?: string;
}

export interface CapabilitiesContext {
  userCapabilities: Set<string> | null;
  error?: Error;
}

export interface SecurityContext {
  hingeHealthAuthState?: {
    isAuthenticated: boolean;
    accessToken?: AccessToken;
  } | null;
  hingeHealthAuthClient: {
    signInWithRedirect: (options?: SignInOptions) => Promise<void>;
    signOut: () => Promise<void>;
    isAuthenticated: () => Promise<boolean>;
  };
  capabilities: CapabilitiesContext;
}

/*
  Note from React docs: The defaultValue argument is only used when a component does not
  have a matching Provider above it in the tree. This default value can be helpful for
  testing components in isolation without wrapping them
*/

const defaultValues: SecurityContext = {
  hingeHealthAuthState: {
    isAuthenticated: false
  },
  hingeHealthAuthClient: {
    signInWithRedirect: () => Promise.resolve(),
    signOut: () => Promise.resolve(),
    isAuthenticated: () => Promise.resolve(false)
  },
  capabilities: {
    userCapabilities: null
  }
};

export const HingeHealthSecurityContext = React.createContext(defaultValues);

export const useHingeHealthSecurityContext = (): SecurityContext =>
  React.useContext(HingeHealthSecurityContext);
