import * as React from 'react';
import {
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  CartesianGrid,
  YAxis,
  ReferenceLine,
  Tooltip,
  Legend,
  Area
} from 'recharts';
import * as moment from 'moment-timezone';
import * as R from 'ramda';
import MultilineTick from '../../charts/MultilineTick';
import PainTooltip from '../../charts/PainTooltip';
import PainLegend from '../../charts/PainLegend';
import { DARKER_NAV_BAR, RED, LIGHTERRED } from '../../../colors';
import { HealthLog, Pathway } from '../../../graphql/models';
import { User } from '../../../graphql/models/User';
import { selectPainReport, sortReportsReverseWeek } from './PainTable';
import { HANDLE_RESIZE_WAIT, PAIN_X_AXIS_RANGE_WEEKS, PRE_ZERO_WEEK_START } from '../../../utils/chart';
import { DEFAULT_TIMEZONE } from '../../../utils/user/timezone';

interface PainChartDatum {
  pain?: number | null;
  weekAndDate: string;
  diff?: number | null;
}

interface PainChartProps {
  pathways?: Pathway[] | null;
  healthLogs: HealthLog[];
  user?: User;
}

export const getWeekStartDate = (pathway?: Pathway | null) => {
  const currentWeek = (pathway && pathway.currentWeek) || NaN;
  return moment()
    .startOf('isoWeek')
    .subtract((currentWeek + PRE_ZERO_WEEK_START), 'weeks');
};

const getEndWeek = (weekNumber?: number | null) => weekNumber && weekNumber > PAIN_X_AXIS_RANGE_WEEKS
  ? weekNumber
  : PAIN_X_AXIS_RANGE_WEEKS;

export const formatWeekAndDate = (week: number, date: moment.Moment) =>
  `W${week}\n${date.format('M/D')}`;

export const getHealthLogByDay = (
  date: moment.Moment,
  timeZone: string,
  report: HealthLog
): boolean => date.isSame(moment.tz(report.occurredAt, timeZone), 'day');

export function generateChartData(
  props: PainChartProps,
  baselineLog?: HealthLog | null
): PainChartDatum[] {
  const chartData = new Array<PainChartDatum>();
  const { pathways, healthLogs, user } = props;
  const activePathway = pathways && pathways[0];
  const currentWeek = activePathway && activePathway.currentWeek;
  const endWeek = getEndWeek(currentWeek);
  const endWeekInclusive = endWeek + 1;

  const startDate = getWeekStartDate(activePathway);

  const timezone = (user && user.timezone) || DEFAULT_TIMEZONE;

  const date = moment.tz(startDate, timezone);

  for (let i = 0; i < endWeekInclusive * 7; i++ , date.add(1, 'day')) {
    const weekIndex = Math.floor(i / 7) - PRE_ZERO_WEEK_START;
    const weekAndDate = formatWeekAndDate(weekIndex, date);
    const healthLog = healthLogs.find(
      getHealthLogByDay.bind(null, date, timezone)
    );

    if (!healthLog) {
      chartData.push({
        weekAndDate
      });
    } else {
      const chartDataPoint: PainChartDatum = { weekAndDate };
      const painReport = selectPainReport(healthLog);
      const screenerReport = selectPainReport(baselineLog);

      chartDataPoint.pain = (painReport && painReport.pain) || null;
      chartDataPoint.diff = (screenerReport && screenerReport.pain) || null;

      chartData.push(chartDataPoint);
    }
  }
  return chartData;
}

const PainChart: React.FunctionComponent<PainChartProps> = props => {
  const { pathways, healthLogs } = props;
  const sortedLogsReverseWeek = healthLogs.slice().sort(sortReportsReverseWeek);
  const baselineLog = R.takeLast(1, sortedLogsReverseWeek)[0];
  const activePathway = (pathways && pathways[0]) || null;
  const currentWeek = (activePathway && activePathway.currentWeek) || NaN;
  const endWeek = getEndWeek(currentWeek);
  const screenerPainReport = selectPainReport(baselineLog);
  const screenerPain =
    (screenerPainReport && screenerPainReport.pain) || undefined;
  const painData = generateChartData(props, baselineLog);
  return (
    <ResponsiveContainer debounce={HANDLE_RESIZE_WAIT} width="100%" height={480}>
      <ComposedChart data={painData}>
        <XAxis
          dataKey="weekAndDate"
          type="category"
          interval={6}
          tick={<MultilineTick numWeeks={endWeek} />}
          height={42}
        />
        <YAxis type="number" domain={[0, 100]} ticks={[25, 50, 75, 100]} />
        <CartesianGrid />
        <Area
          connectNulls={true}
          dataKey={'pain'}
          name="Pain"
          stroke={RED}
          strokeWidth={'2'}
          fill={LIGHTERRED}
          legendType="line"
          dot={true}
          isAnimationActive={false}
        />
        <ReferenceLine
          strokeDasharray="8 2"
          y={screenerPain}
          stroke={DARKER_NAV_BAR}
        />
        <Tooltip
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          formatter={(v: any) => (typeof v === 'number' ? v : v[1] - v[0])}
          content={<PainTooltip painRefValue={screenerPain} />}
        />
        <Legend
          verticalAlign={'top'}
          align={'right'}
          content={<PainLegend iconSize={16} />}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default PainChart;
