import * as React from 'react';
import { ThemeContext } from 'grommet';
import { connect } from 'react-redux';
import { Switch, Route, useRouteMatch, useLocation} from 'react-router-dom';

import * as C from '../../colors';
import { Team } from '../../graphql/models';
import TeamListContainer from '../../containers/communications/TeamListContainer';
import './CommunicationsLayout.scss';
import TodoCommunications from './TodoCommunications';
import { RootState } from '../../redux/reducers';
import TeamMessagingUserList from './TeamMessagingUserList';
import { MESSAGING_PATH, TEAM_PATH, USER_PATH } from '../../routes';
import { isSocketDisconnected } from '../../redux/selectors/pubsub';
import { getUnseenMessages } from '../../redux/actions/conversations';
import { DispatchMap } from '../../redux/actions/interfaces';
import { selectInCtpIframe} from '../../redux/selectors';

const TabStyles = {
  fontSize: '12px',
  color: C.TAB_INACTIVE,
  active: {
    color: C.TAB_ACTIVE
  },
  hover: {
    color: C.TAB_ACTIVE
  }
};

const GlobalTheme = {
  global: {
    colors: {
      brand: C.CTM_DEFAULT_PRIMARY
    }
  },
  tab: {
    ...TabStyles,
    border: {
      ...TabStyles,
      color: 'none',
      size: 'small'
    }
  }
};

type ConnectProps = DispatchMap<{
  fetchUnseenMessages: typeof getUnseenMessages;
  inCtpIframe?: boolean;
}>;

interface CommsLayoutProps {
  socketDisconnect: boolean;
}

type Props = ConnectProps & CommsLayoutProps;

export function CommunicationsLayout(props: Props) {
  const [selectedTeam, setSelectedTeam] = React.useState<Team | undefined>();
  const [selectedTodo, setSelectedTodo] = React.useState<string | undefined>();
  const [selectedTeamId, setSelectedTeamId] = React.useState<string | undefined>();
  const [isUserViewingCtm, setisUserViewingCtm] = React.useState<boolean>(false);

  const urlMatch = useRouteMatch(MESSAGING_PATH);
  const firstLoad = React.useRef(true);
  const currentPath = useLocation();

  if ( currentPath.pathname === '/messaging' && !isUserViewingCtm ) {
    setisUserViewingCtm(true);
  }

  /**
   * Fetching unseen messages is needed to update the unread messages
   * counter on initial load for a deep linked view.
   */
  React.useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }
    if (!props.socketDisconnect) {
      props.fetchUnseenMessages({}, true);
    }
  }, [props.socketDisconnect]);

  let todoCommunicationsComponent;
  if (selectedTodo) {
    todoCommunicationsComponent = <TodoCommunications todo={selectedTodo} />;
  } else {
    todoCommunicationsComponent = null;
  }

  return (
    <ThemeContext.Extend value={GlobalTheme}>
      <div className="communications-layout">
        {(!props.inCtpIframe || isUserViewingCtm) &&
          <div data-testid='team-list-container' className="team-list-container">
            <TeamListContainer
              selectedTodo={selectedTodo}
              setSelectedTodo={setSelectedTodo}
              selectedTeam={selectedTeam}
              setSelectedTeam={setSelectedTeam}
              selectedTeamId={selectedTeamId}
              setSelectedTeamId={setSelectedTeamId}
            />
          </div>
        }
        <div className="conversation-list-container">
          <Switch>
            <Route
              path={[`${urlMatch?.path}${TEAM_PATH}${USER_PATH}`, `${urlMatch?.path}${TEAM_PATH}`]}
              render={fullRoute => {
                const match = fullRoute?.match;
                const teamId = match?.params?.teamId;
                setSelectedTeamId(teamId);

                return <TeamMessagingUserList teamId={teamId} isUserViewingCtm={isUserViewingCtm}/>;
              }}
            />
            <Route path={MESSAGING_PATH} exact>
              {todoCommunicationsComponent}
            </Route>
          </Switch>
        </div>
      </div>
    </ThemeContext.Extend>
  );
}

const mapDispatchToProps = {
  fetchUnseenMessages: getUnseenMessages
};

const mapStateToProps = (state: RootState) => ({
  socketDisconnect: isSocketDisconnected(state),
  inCtpIframe: selectInCtpIframe(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationsLayout);
