import * as React from 'react';
import { Segment, Header, HeaderProps, Grid, Icon } from 'semantic-ui-react';
import * as moment from 'moment-timezone';
import styled from 'styled-components';
import GoalDeleteModalContainer from '../../containers/user/GoalDeleteModalContainer';
import GoalEditContainer from '../../containers/user/GoalEditContainer';
import { GoalStatus } from '../../api-client';
import { GoalRecord } from '../../redux/reducers';
import { DispatchMap } from '../../redux/actions/interfaces';
import { actionCreators as goalEditingActions } from '../../redux/actions/goals-editor';
import { Edit } from '@material-ui/icons';

export type GoalSegmentProps = DispatchMap<{
  goal: GoalRecord;
  userId: number;
  placeGoalToEdit: typeof goalEditingActions.placeGoalToEdit;
  editable?: boolean;
}>;

interface State {
  displayEdit: boolean;
  displayDeleteModal: boolean;
}

interface StrikeHeaderProps extends HeaderProps {
  strike: string;
}

export const StrikeHeader = styled(Header)`
  text-decoration: ${(props: StrikeHeaderProps) =>
    props.strike === 'true' ? 'line-through' : 'none'};
`;

const CloseButtonDiv = styled.div`
  float: left;
  margin-top: -15px;
`;

const DateSpan: React.FunctionComponent<{
  date: string;
  className?: string;
}> = props => (
  <span className={props.className}>{moment.utc(props.date).format('ll')}</span>
);

export const DueDate: React.FunctionComponent<{ done_by?: string | null }> = ({
  done_by
}) =>
  done_by ? (
    <DateSpan className="done_by" date={done_by} />
  ) : (
    <span>
      <em>No Due Date</em>
    </span>
  );

export const CompletedDate: React.FunctionComponent<{
  completed_at?: string | null;
}> = ({ completed_at }) =>
  completed_at ? (
    <DateSpan className="completedAt" date={completed_at} />
  ) : null;

class GoalSegment extends React.PureComponent<GoalSegmentProps, State> {
  state = {
    displayEdit: false,
    displayDeleteModal: false
  };

  componentDidMount() {
    this.props.placeGoalToEdit && this.props.placeGoalToEdit(this.props.goal);
  }

  displayGoalEdit = () => this.setState({ displayEdit: true });

  hideGoalEdit = () => this.setState({ displayEdit: false });

  handleDeleteModal = () => this.setState({ displayDeleteModal: true });

  handleCloseModal = () => this.setState({ displayDeleteModal: false });

  render() {
    if (!this.state.displayEdit) {
      return (
        <Segment padded>
          <CloseButtonDiv>
            <Icon
              className="close"
              name="close"
              onClick={this.handleDeleteModal}
            />
          </CloseButtonDiv>
          <Grid style={{ paddingTop: '20px', paddingBottom: '5px' }}>
            <Grid.Row columns={2}>
              <Grid.Column width={14}>
                <StrikeHeader
                  strike={(
                    this.props.goal.status === GoalStatus.DONE
                  ).toString()}
                  size="small"
                >
                  {this.props.goal.content}
                </StrikeHeader>
              </Grid.Column>
              <Grid.Column width={2} floated="right">
                <Edit onClick={this.displayGoalEdit} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <p>
            <span>
              {/* todo: ATL-312 will handle doneBy key inconsistency */}
              {/* this.props.goals.done_by can be removed once that ticket is resolved  */}
              Due: <DueDate done_by={this.props.goal.doneBy ?? this.props.goal.done_by} />
            </span>
            {this.props.goal.completed_at && (
              <span style={{ float: 'right' }}>
                Completed:
                <CompletedDate completed_at={this.props.goal.completed_at} />
              </span>
            )}
          </p>
          <GoalDeleteModalContainer
            userId={this.props.userId}
            deleteGoalId={this.props.goal.id}
            open={this.state.displayDeleteModal}
            onClose={this.handleCloseModal}
          />
        </Segment>
      );
    }
    return (
      <div className="goalEditor">
        <GoalEditContainer
          focusInput
          userId={this.props.userId}
          goalId={this.props.goal.id}
          onClose={this.hideGoalEdit}
        />
      </div>
    );
  }
}

export default GoalSegment;
