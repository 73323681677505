import * as Sentry from '@sentry/browser';
import { debounce } from 'lodash';
import { io, Socket } from 'socket.io-client';
import { getNestBffUrl } from '../../constant';
import { parseCookie } from '../../utils/cookie';
import { getOktaAccessToken } from '../../utils/helpers';
import * as C from '../constants';
import { selectAdmin } from '../selectors/admin';
import { isSocketDisconnected } from '../selectors/pubsub';
import { actionCreators as authActions } from './auth';
import { PayloadAction, ThunkAction } from './interfaces';
import { MessageSocketResponse, receiveMessageSocketEvent } from './messages';
import { actionCreators as uiStateActions, receiveTaskSocketEvent, TaskSocketResponse } from './ui-state';
import { getRemainingTokenExpirationTime } from '../../utils/auth.utils';
import { logMessage } from '../../utils/logs/sentry-log';

export const OKTA_ACCESS_TOKEN_KEY = 'okta-access-token';
const RECONNECT_ATTEMPT_THRESHOLD = 500;

export let socket: Socket;

export interface SetConnectionStatusPayload {
  disconnected: boolean;
}

export interface SetConnectionStatusAction
  extends PayloadAction<SetConnectionStatusPayload> {
  payload: SetConnectionStatusPayload;
  type: typeof C.SET_CONNECTION_STATUS;
}

export type PubsubActions = SetConnectionStatusAction;

export const setConnectionStatus = (status: boolean) => ({
  type: C.SET_CONNECTION_STATUS,
  payload: { disconnected: status }
});

const getAccessToken = (): string => {
  const oktaToken = getOktaAccessToken();
  const cookie = parseCookie(document.cookie);
  const authServiceToken = cookie['auth-access-token'];

  return oktaToken || authServiceToken;
};

export const actionCreators = {
  createConnection(): ThunkAction<SetConnectionStatusAction> {
    return (dispatch, getState) => {
      const state = getState();

      socket = io(getNestBffUrl(), {
        autoConnect: true,
        reconnection: true,
        forceNew: true,
        secure: true,
        timeout: 5000,
        reconnectionDelay: 5000,
        reconnectionDelayMax: 5000,
        transports: ['websocket'],
        auth: {
          token: getAccessToken()
        }
      });

      let attemptNumber = 0;
      let sentryEventSent = false;

      const disconnectStatusUpdate = debounce(() => {
        dispatch<SetConnectionStatusAction>({
          type: C.SET_CONNECTION_STATUS,
          payload: { disconnected: true }
        });

        if(attemptNumber % RECONNECT_ATTEMPT_THRESHOLD === 0 && !sentryEventSent && attemptNumber !== 0) {
          sentryEventSent = true;
          const adminEmail = selectAdmin(state).email;
          attemptNumber = 0;

          Sentry.withScope(scope => {
            scope.setUser({ email: adminEmail });
            Sentry.captureException(
              `Failed to establish websocket connection. Number of retry attempts: ${adminEmail}`
            );
          });
        }

        attemptNumber++;
        socket.removeListener('message');
        socket.removeListener('task');
      }, 5000, {leading: true});

      const logToSentry = (message: string) => {
        const adminEmail = selectAdmin(state).email;
        logMessage(message, adminEmail);
      };

      const logExpiredTokenMsgToSentry =
      (socketEvent: 'connect_error' | 'reconnect_attempt', err: string | number | Error) => {
        const token = getAccessToken();
        const remainingTokenExpiration = getRemainingTokenExpirationTime(token) || 0;
        if (remainingTokenExpiration <= 0 ) {
          logToSentry(`SocketIO Client ${socketEvent} handler,
                    (Token Expiration time remaining:${remainingTokenExpiration} sec): ${err}`);
        }
      };

      socket.on('connect', () => {
        attemptNumber = 0;
        const socketDisconnected = isSocketDisconnected(state);
        if (socketDisconnected) {
          dispatch(uiStateActions.getTaskCountByKind());
        }
        socket.on('message', function(message: MessageSocketResponse) {
          dispatch(receiveMessageSocketEvent(message));
        });
        socket.on('task', function(task: TaskSocketResponse) {
          dispatch(receiveTaskSocketEvent(task));
        });

        dispatch<SetConnectionStatusAction>({
          type: C.SET_CONNECTION_STATUS,
          payload: { disconnected: false }
        });
      });

      // unauthorized event occurs when the attempt to connect to the server with UUID fails.
      socket.on('unauthorized', async(err) => {

        try {
          dispatch(authActions.refresh());
          disconnectStatusUpdate();
        } catch {
          socket.removeListener('unauthorized');
        }
      });

      // disconnect event occurs when connection fails after it has already been established.
      socket.on('disconnect', (err) => {
        disconnectStatusUpdate();
      });

      // connect_error event occurs when web socket connection fails.
      // This can happen when an invalid JWT is passed to the server
      socket.on('connect_error', (err) => {
        logExpiredTokenMsgToSentry('connect_error', err);

        // Handle the scenario where cookie has expired and there is no access token when
        // a connection errors out
        if (!getAccessToken()) {
          dispatch(authActions.refresh()).then(() => {
            // @ts-expect-error socket type says auth is private, but docs recommend this pattern
            socket.auth.token = getAccessToken();
          });
        } else {
          // @ts-expect-error socket type says auth is private, but docs recommend this pattern
          socket.auth.token = getAccessToken();
        }

        disconnectStatusUpdate();
      });

      socket.io?.on('reconnect_attempt', (err) => {
        logExpiredTokenMsgToSentry('reconnect_attempt', err);

        // @ts-expect-error socket type says auth is private, but docs recommend this pattern
        socket.auth.token = getAccessToken();
      });

      return dispatch<SetConnectionStatusAction>({
        type: C.SET_CONNECTION_STATUS,
        payload: { disconnected: false }
      });
    };
  },
  destroyConnection(): ThunkAction<SetConnectionStatusAction> {
    socket?.close();
    return dispatch => dispatch<SetConnectionStatusAction>({
      type: C.SET_CONNECTION_STATUS,
      payload: { disconnected: true }
    });
  }
};
