import { TypedRecord, makeTypedFactory } from 'typed-immutable-record';
import { Map } from 'immutable';

import { SurgeryLog } from '../../api-client';
import { RootAction } from '../actions';
import { ENTITIES_FETCHED } from '../constants';
import { createEntityMap, mergeUpdate } from '../../utils/entity';

export interface SurgeryLogRecord extends TypedRecord<SurgeryLogRecord>, SurgeryLog { }

export const createSurgeryLogRecord = makeTypedFactory<SurgeryLog, SurgeryLogRecord>({
  id: NaN,
  created_at: undefined,
  surgery_free_text: undefined,
  submitted_at: undefined,
  week_index: undefined,
  surgery_likelihood_1yr: undefined,
  surgery_likelihood_2yr: undefined,
  surgery_likelihood_5yr: undefined
});

export type State = Map<number, SurgeryLogRecord>;

export const initialState: State = Map();

export const reducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case ENTITIES_FETCHED:
      return action.payload.entities.surgeryLogs
        ? mergeUpdate(state, createEntityMap(createSurgeryLogRecord, action.payload.entities.surgeryLogs))
        : state;
    default:
      return state;
  }
};
