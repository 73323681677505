import { ProgramIndication, PathwayStage } from '../../../api-client/interfaces';
import { EtSessionGroupings } from './EtSession';
import { OptionalProperties } from '../index';

export enum StreamKind {
  low_activity = 'LOW_ACTIVITY',
  medium_activity = 'MEDIUM_ACTIVITY',
  high_activity = 'HIGH_ACTIVITY'
}
export type Pathway = OptionalProperties<{
  __typename: string | null;
  id: string;
  uuid: string;

  currentExerciseTherapyStream: StreamKind | null;
  currentLevel: number | null;
  currentWeek: number | null;
  etSessionGroupings: EtSessionGroupings[] | null;
  programIndication: ProgramIndication;
  stage: PathwayStage | null;
  firstEtAt: string | null;
}>;
