import * as React from 'react';
import * as moment from 'moment-timezone';
import styled from 'styled-components';

import { RED, GREEN } from '../../colors';
import { User } from '../../api-client/index';
import { DEFAULT_TIMEZONE } from '../../utils/user/timezone';

export const LabelSpan = styled.span`
  color: ${(props: { color?: string }) => props.color || 'inherit'};
`;

interface InactivityLabelProps {
  user: User;
}

export function generateInactivityLabels(user: User): [string, string] {
  if (!user.team || !user.lastSignInAt) {
    return ['Never logged in', RED];
  }
  const currentWeekIndex = user.team.currentTeamWeekNumber;
  const startWeek1 = moment.tz(user.timezone || DEFAULT_TIMEZONE)
    .startOf('isoWeek').subtract(currentWeekIndex || 0, 'weeks').add(1, 'weeks');

  let lastActiveLocal = user.lastActive ?
    moment(user.lastActive).tz(user.timezone || DEFAULT_TIMEZONE) : startWeek1;
  const preWeek1Screen = startWeek1.diff(lastActiveLocal, 'seconds');

  if (preWeek1Screen >= 0) {
    lastActiveLocal = startWeek1;
  }

  const currentMonday = moment.tz(user.timezone || DEFAULT_TIMEZONE).startOf('isoWeek');
  const secondsInactive = currentMonday.diff(lastActiveLocal, 'seconds');
  const weeksInactive = currentMonday.diff(lastActiveLocal, 'weeks');

  if (secondsInactive < 0) {
    return ['Active this week', GREEN];
  } else if (Math.floor(weeksInactive) < 1) {
    return ['Inactive this week', RED];
  } else {
    return [`Inactive ${Math.floor(weeksInactive)}W`, RED];
  }
}

const InactivityLabel: React.FunctionComponent<InactivityLabelProps> = ({ user }) => {
  const labelText = generateInactivityLabels(user)[0];
  const labelColor = generateInactivityLabels(user)[1];

  return (
    <LabelSpan color={labelColor}>{labelText}</LabelSpan>
  );
};

export default InactivityLabel;
