import * as React from 'react';
import * as moment from 'moment';
import styled from 'styled-components';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { WHITE } from '../../colors';
import { Reminder } from '../../graphql/models/';
import { formatCronValuesToDays } from '../../utils/days';
import { isNullOrUndefined } from 'util';

interface RemindersProps {
  reminders: Reminder[] | null | undefined;
}

export const getLatestReminder =
  (reminders: Reminder[] | null | undefined) => reminders && reminders[reminders.length - 1];

const StyledList = styled(List)`
  && {
    width: 100%;
    display: flex;
    padding-top: 0;
    background-color: ${WHITE}
    flex-wrap: wrap;
  }
`;

// There's a TS error using styled-components with material-ui ListItem
const listItemStyles = {
  flexFlow: 'column wrap',
  alignItems: 'start',
  padding: '0 0 20px 20px',
  width: '50%'
};

const RemindersContext: React.FC<RemindersProps> = ({ reminders }) => {
  const {
    afterEvent,
    days,
    hour,
    minute
  } = getLatestReminder(reminders) || {};

  const formattedDays = days ? formatCronValuesToDays(days) : 'N/A';
  const habitTrigger = afterEvent ? `After ${afterEvent}` : 'N/A';
  const momentTime = !isNullOrUndefined(hour) && !isNullOrUndefined(minute) ? moment({
    hour,
    minute
  }).format('h:mm A') : 'N/A';

  return (
    <StyledList dense>
      <ListItem style={listItemStyles}>
        <ListItemText secondary='Days Selected'/>
        <ListItemText
          data-testid="reminders-days"
          primary={formattedDays}
        />
      </ListItem>
      <ListItem style={listItemStyles}>
        <ListItemText secondary='Time'/>
        <ListItemText
          data-testid="reminders-time"
          primary={momentTime}
        />
      </ListItem>
      <ListItem style={listItemStyles}>
        <ListItemText secondary='Habit Trigger'/>
        <ListItemText
          data-testid="reminders-trigger"
          primary={habitTrigger}
        />
      </ListItem>
    </StyledList>
  );
};
export default RemindersContext;
