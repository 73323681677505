import * as React from 'react';
import { User, ArticleRead, Article } from '../../graphql/models';
import { getApiUrl } from '../../constant';
import styled from 'styled-components';
import { TEXT_SECONDARY, PRIMARY_MAIN, WHITE } from '../../colors';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { formatDate_MMM_D_YYYY } from '../../utils/datetime';
import { getUserActivePathway } from '../../utils/user/getUserActivePathway';
interface EducationContextProps {
  user: User;
}

const Title = styled.p`
  color: ${TEXT_SECONDARY};
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 32px;
`;

const Wrapper = styled.div`
  padding: 0 20px 0 20px;
`;

const StyledList = styled(List)`
  && {
    width: 100%;
    display: flex;
    padding-top: 0;
    background-color: ${WHITE}
  }
`;

const StyledListItemText = styled(ListItemText)`
  && {
    color: ${PRIMARY_MAIN}
  }
`;

const StyledUpNextListItemText = styled(ListItemText)`
  && {
    flex: 0 1 auto;
  }
`;

const listItemStyles = {
  flexFlow: 'column wrap',
  alignItems: 'start',
  paddingLeft: 0,
  paddingTop: 0
};

const educationContextTitle = 'EDUCATION';

export const constructArticleLink = (urlEdu?: string | null) => urlEdu ? `${getApiUrl()}${urlEdu}` : undefined;

export const ArticleLink: React.FC<{ article: ArticleRead | Article }> = ({
  article
}) => (
  <a
    href={constructArticleLink(article.contentUrl)}
    target='_blank'
  >
    {article.name}
  </a>
);


const EducationContext: React.FC<EducationContextProps> = ({ user }) => {
  const activePathway = getUserActivePathway(user);

  if (!activePathway || !activePathway.educationContext) return null;

  const { educationContext } = activePathway;
  const { lastClosedAtArticle, nextArticle } = educationContext;

  const lastReadArticle = lastClosedAtArticle ? (
    <ArticleLink article={lastClosedAtArticle} />
  ) : (
    'N/A'
  );
  const lastReadDate = lastClosedAtArticle && lastClosedAtArticle.closedAt ? (
    formatDate_MMM_D_YYYY(lastClosedAtArticle.closedAt)
  ) : null;

  const upNextArticle = nextArticle ? (
    <StyledListItemText
      primary={<ArticleLink article={nextArticle} />}
    />
  ) : (
    <ListItemText
      primary={user.phoenix
        ? 'None (syllabus completed)'
        : 'None (week completed)'}
    />
  );

  return (
    <Wrapper>
      <Title>{educationContextTitle}</Title>
      <StyledList dense>
        <ListItem style={listItemStyles}>
          <ListItemText secondary='Last Read' />
          <ListItemText primary={lastReadArticle} />
          <ListItemText secondary={lastReadDate} />
        </ListItem>
        <ListItem style={listItemStyles}>
          <StyledUpNextListItemText secondary='Up Next' />
          {upNextArticle}
        </ListItem>
      </StyledList>
    </Wrapper>
  );
};

export default EducationContext;
