import { calculateWeeksBetween } from '../../../utils/datetime';

export function formatYearTwoProgress(
  totalProgramWeeks?: number,
  yearTwoStartsAtDate?: string
) {
  if (!yearTwoStartsAtDate || !totalProgramWeeks) return '';
  const progress = calculateWeeksBetween(yearTwoStartsAtDate);

  const message = `Has received ${progress} of ${totalProgramWeeks} weeks of Y2 coaching.`;

  return message;
}
