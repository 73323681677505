import * as React from 'react';
import {
  Link,
  Button
} from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import { getApiUrl } from '../../constant';
import { User } from '../../graphql/models';
import { getUserActivePathway } from '../../utils/user/getUserActivePathway';

interface FullScreenerLinkProps {
  user: User;
  size?: 'small' | 'medium';
};

const FullScreenerLink = ({ user, size = 'medium' }: FullScreenerLinkProps) => {
  const pathway = getUserActivePathway(user);
  const screeningId = pathway?.screening?.id;
  const screenerLink = screeningId ? `${getApiUrl()}/admin/screenings/${screeningId}` : undefined;

  return (
    <>
      { screenerLink &&
      <Link target="_blank" href={screenerLink}>
        <Button
          color="primary"
          size={size}
          startIcon={<OpenInNew />}
          variant='text'
        >
          FULL SCREENER
        </Button>
      </Link>
      }
    </>
  );
};

export default FullScreenerLink;
