import * as React from 'react';
import { Participant as ParticipantType} from 'twilio-video';
import { MicOff } from '@material-ui/icons';
import Participant from './Participant';

interface Props {
  localParticipant: ParticipantType;
  remoteParticipants: Array<ParticipantType>;
  isMicOn: boolean;
}


export default function VideoCall({ localParticipant, remoteParticipants, isMicOn }: Props) {
  const renderRemoteParticipant = (remoteParticipant: ParticipantType) => (
    <Participant participant={remoteParticipant} key={remoteParticipant.identity} participantType='remote' />
  );

  return (
    <div className='participants'>
      <div className='local-participant'>
        <Participant participant={localParticipant} key={localParticipant.identity} participantType="local"/>
        {
          isMicOn
            ? null
            : <div className='mute-indicator'>
              <MicOff fontSize="large"/>
            </div>
        }
      </div>
      <div className='remote-participants'>{remoteParticipants.map(renderRemoteParticipant)}</div>
    </div>
  );
}
