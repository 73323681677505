
export const GET_MESSAGES = `
query getMessages(
  $userId: String,
  $coachId: String,
  $itemsPerPage: Int,
  $page: Int,
  $conversationId: StringOrInt
) {
  getMessages(
    userId: $userId,
    conversationId: $conversationId,
    coachId: $coachId,
    itemsPerPage: $itemsPerPage,
    page: $page
  )
}
`;

export const GET_SCHEDULED_MESSAGES = `
query getScheduledMessages(
  $conversationId: StringOrInt,
	$userId: String,
	$coachId: String,
	$scheduled: Boolean,
  $itemsPerPage: Int,
  $page: Int
) {
  getScheduledMessages(
    conversationId: $conversationId,
    userId: $userId,
    coachId: $coachId,
    scheduled: $scheduled,
    itemsPerPage: $itemsPerPage,
    page: $page
  )
}
`;
