import * as React from 'react';
import { Box, Popper, Typography } from '@material-ui/core';
import { Videocam, VideocamOff } from '@material-ui/icons';
import styled from 'styled-components';

interface VideoVisitIconProps {
  isEligible: boolean | undefined;
}

export const CenteredContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

const PopperContent = styled(Box)`
  min-height: 40px;
  max-width: 300px;
  padding: 10px;
  display: flex;
  background-color: white;
  flex-direction: column;
`;

export const VideoVisitIcon = (props: VideoVisitIconProps) => {
  const { isEligible } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLDivElement>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const icon = isEligible
    ? <Videocam data-testid='video-on'/>
    : <VideocamOff color='disabled' data-testid='video-off'/>;

  const popperText = isEligible ? 'Video visit eligible' : 'Video visit ineligible';

  const open = Boolean(anchorEl);

  return (
    <CenteredContainer
      className={'centered-container'}
      data-testid={'centered-container'}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {icon}
      <Popper
        anchorEl={anchorEl}
        placement={'top-end'}
        open={open}
      >
        <PopperContent boxShadow={2}>
          <Typography variant="body2">{popperText}</Typography>
        </PopperContent>
      </Popper>
    </CenteredContainer>
  );
};
