import * as React from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Segment,
  Message,
  StrictInputProps
} from 'semantic-ui-react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import * as logo from '../../images/logo.png';

type SemanticOnChange = StrictInputProps['onChange'];

export interface LoginFormProps {
  email?: string;
  errorContent?: string;
  errorHeader?: string;
  loginPending?: boolean;
  password?: string;
  hasAuth?: boolean;
  onSubmit(ev: React.FormEvent<HTMLElement>): void;
  onChangeEmail(email: string): void;
  onChangePassword(password: string): void;
  title: string;
}

const StyledContainer = styled.div`
  height: 100%;
  text-align: center;
  max-width: 450px;
`;

class LoginForm extends React.PureComponent<LoginFormProps> {
  render() {
    const hasError =
      this.props.errorContent !== undefined || this.props.errorHeader !== undefined;
    if (this.props.hasAuth) {
      return <Redirect to="/tasks" />;
    }
    return (
      <StyledContainer>
        <Grid
          verticalAlign="middle"
          textAlign="center"
          style={{ height: '100%' }}
        >
          <Header as="h3">
            {/* Need to use Image as icons are really designed only be a single color */}
            <Image src={logo} />
            {`"${this.props.title}"`}
          </Header>
          <Grid.Column style={{ height: '100%', width: '300px' }}>
            <Form
              size="large"
              className="loginForm"
              loading={this.props.loginPending}
              onSubmit={this.props.onSubmit}
              error={hasError}
            >
              <Segment stacked>
                {hasError && (
                  <Message
                    error
                    header={this.props.errorHeader}
                    content={this.props.errorContent}
                  />
                )}
                <Form.Input
                  fluid
                  id="email"
                  icon="user"
                  iconPosition="left"
                  placeholder="Email address"
                  onChange={this.handleEmailChange}
                  value={this.props.email}
                />
                <Form.Input
                  fluid
                  id="password"
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  onChange={this.handlePasswordChange}
                  value={this.props.password}
                />
                <Button id="login-button" size="large" primary fluid>
                  Login
                </Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </StyledContainer>
    );
  }

  handlePasswordChange: SemanticOnChange = (_, data) => this.props.onChangePassword(data.value);
  handleEmailChange: SemanticOnChange = (_, data) => this.props.onChangeEmail(data.value);
}

export default LoginForm;
