export const UPDATE_GOAL = `
  mutation updateGoal($goal: LegacyGoalType!, $userId: Int!) {
    updateGoal(goal: $goal, userId: $userId) 
  }
`;

export const ADD_GOAL = `
  mutation addGoal($content: String!, $doneBy: String!, $userId: Int!) {
    addGoal(content: $content, doneBy: $doneBy, userId: $userId)
  }
`;

export const DELETE_GOAL = `
  mutation deleteGoal($goalId: Int!, $userId: Int!) {
    deleteGoal(goalId: $goalId, userId: $userId)
  }
`;
