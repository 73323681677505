import * as R from 'ramda';
import * as Sentry from '@sentry/browser';

import {
  MessagesResponse,
  MessageResponse,
  Message,
  MessageSocketData,
  MessageDirection,
  ScheduledMessageResponse,
  messageParser
} from '../../api-client';
import { GetMessageParams, GetScheduledMessagesParams, MessageURIParams } from '../../api-client/endpoints';

import { ThunkAction, PayloadAction } from './interfaces';
import { getApiClient, selectAdmin, selectConversation } from '../selectors';
import * as C from '../constants';
import { ConversationRecord, createConversationRecord } from '../reducers/conversations';
import { TeamRecord } from '../reducers';
import { ERR_MESSAGE_PARSE_FAIL, ERR_SCHEDULED_MESSAGE_DELETE_FAIL, ERR_MESSAGE_SEND_FAIL } from '../../errors';
import { ReceiveUnseenMessageCountAction } from './ui-state';
import { POST_MESSAGE, UPDATE_MESSAGE, DELETE_MESSAGE, MARK_AS_READ } from '../../graphql/mutations';
import { mixpanelTrack } from '../../mixpanel/mixpanel';
import { GET_MESSAGES, GET_SCHEDULED_MESSAGES } from '../../graphql/queries';

export interface MessageComposed {
  body: string;
  adminUuid?: string | null;
  userUuid?: string | null;
  userPhoneNumber?: string | null;
  scheduledFor?: string;
  userTimezone?: string | null;
}

interface ConversationMessagesPayload {
  conversation?: ConversationRecord;
  messages: string[];
  most_recent_message?: Message;
  page?: number;
  pages?: number;
}

interface ConversationScheduledMessagesPayload {
  conversation?: ConversationRecord;
  scheduled_messages: string[];
}

interface ConversationDeleteScheduledMessagePayload {
  conversation?: ConversationRecord;
  messageId: string;
}

interface PostMessagePayload {
  conversation?: ConversationRecord;
  message: Message;
}

export interface ReceiveMessageStatusData {
  message: MessageSocketData;
  conversation_id: string;
}

export interface ReceiveMessageData {
  message: MessageSocketData;
  user_id: number;
  team_id: number;
  conversation_id: string;
}

export enum MessageSocketEvent {
  MessageReceived = 'message_received',
  MessageStatusUpdate = 'message_status_update'
}

export interface MessageSocketResponse {
  event: MessageSocketEvent;
  timestamp: string;
  data: ReceiveMessageData;
}

export interface MarkAsSeenMessagePayload {
  message: Message;
  team: TeamRecord;
  conversation?: ConversationRecord;
}

export interface ReceiveUnseenMessagePayload {
  message: Message;
  team_id: number;
  conversation: ConversationRecord;
}

export interface ReceiveMessageStatusPayload {
  message: Message;
  conversation?: ConversationRecord;
}

export interface UpdateConversationMessagesAction extends PayloadAction<ConversationMessagesPayload> {
  payload: ConversationMessagesPayload;
  type: typeof C.UPDATE_CONVERSATION_MESSAGES;
}

export interface UpdateConversationScheduledMessagesAction extends PayloadAction<ConversationScheduledMessagesPayload> {
  payload: ConversationScheduledMessagesPayload;
  type: typeof C.UPDATE_CONVERSATION_SCHEDULED_MESSAGES;
}

export interface DeleteMessageAction extends PayloadAction<{}> {
  payload: ConversationDeleteScheduledMessagePayload;
  type: typeof C.DELETE_SCHEDULED_MESSAGE;
}

export interface FetchMessageAction extends PayloadAction<MessageResponse> {
  payload: MessageResponse;
  type: typeof C.FETCH_MESSAGE;
}

export interface PostMessageAction extends PayloadAction<PostMessagePayload> {
  payload: PostMessagePayload;
  type: typeof C.POST_MESSAGE;
}

export interface UpdateMessageAction extends PayloadAction<PostMessagePayload> {
  payload: PostMessagePayload;
  type: typeof C.PUT_MESSAGE;
}

export interface GetNewMessagesAction extends PayloadAction<MessageResponse> {
  payload: MessageResponse;
  type: typeof C.FETCH_NEW_MESSAGE;
}

export interface ReceiveUnseenMessageAction extends PayloadAction<ReceiveUnseenMessagePayload> {
  payload: ReceiveUnseenMessagePayload;
  type: typeof C.RECEIVE_UNSEEN_MESSAGE;
}

export interface ReceiveMessageStatusAction extends PayloadAction<ReceiveMessageStatusPayload> {
  payload: ReceiveMessageStatusPayload;
  type: typeof C.RECEIVE_MESSAGE_STATUS;
}

export type MessagingAction =
  | UpdateConversationMessagesAction
  | UpdateConversationScheduledMessagesAction
  | DeleteMessageAction
  | FetchMessageAction
  | PostMessageAction
  | GetNewMessagesAction
  | UpdateMessageAction
  | ReceiveMessageStatusAction
  | ReceiveUnseenMessageAction;

export const receiveUnseenMessage = (resp: ReceiveMessageData): ThunkAction<ReceiveUnseenMessageAction> => (
  dispatch,
  getState
) => {
  const state = getState();
  const { team_id, conversation_id, message, user_id } = resp;

  const selectedConversation = selectConversation(state, {
    conversationId: conversation_id.toString()
  });

  const conversation = selectedConversation
    ? selectedConversation
    : createConversationRecord({ id: conversation_id.toString() });

  const messageParsed = messageParser({
    message: { ...message, direction: MessageDirection.In }
  }).entities.messages[message.id];
  dispatch<ReceiveUnseenMessageCountAction>({
    payload: { userId: user_id, count: 1 },
    type: C.UPDATE_UNREAD_MESSAGE_COUNT
  });
  return dispatch<ReceiveUnseenMessageAction>({
    type: C.RECEIVE_UNSEEN_MESSAGE,
    payload: {
      team_id,
      conversation,
      message: messageParsed
    }
  });
};

export const receiveMessageStatus = (resp: ReceiveMessageStatusData): ThunkAction<ReceiveMessageStatusAction> => (
  dispatch,
  getState
) => {
  const state = getState();
  const { conversation_id, message } = resp;

  const conversation = selectConversation(state, {
    conversationId: conversation_id.toString()
  });

  const messageParsed = messageParser({
    message: { ...message }
  }).entities.messages[message.id];

  return dispatch<ReceiveMessageStatusAction>({
    type: C.RECEIVE_MESSAGE_STATUS,
    payload: {
      conversation,
      message: messageParsed
    }
  });
};

export const receiveMessageSocketEvent = (
  params: MessageSocketResponse
): ThunkAction<ReceiveUnseenMessageAction | ReceiveMessageStatusAction> => {
  const { data, event } = params;

  if (event === MessageSocketEvent.MessageReceived) {
    return receiveUnseenMessage(data);
  }

  return receiveMessageStatus(data);
};

export const actionCreators = {
  getMessages(params: GetMessageParams, callNewEndpoint?: boolean): ThunkAction<Promise<MessagesResponse | void>> {
    if (params.page === undefined) {
      params.page = 0;
    }
    if (params.itemsPerPage === undefined) {
      params.itemsPerPage = 25;
    }

    return async(dispatch, getState) => {
      const state = getState();
      const api = getApiClient(state);
      const adminUuid = selectAdmin(state)?.uuid;

      // Make API call to fetch messages.  Either call the legacy getMessages endpoint or the new getMessages endpoint
      const getMessagesEndpoint = callNewEndpoint ? api.messages.getMessagesNew : api.messages.getMessages;
      return getMessagesEndpoint({
        query: GET_MESSAGES,
        variables: { ...params, coachId: adminUuid }
      })
        .then((res: MessagesResponse) => {
          const messageIds: string[] = res.result.map(id => id.toString());
          const mostRecentMessageId = messageIds[0];

          // Store the messages from the API response in the store
          dispatch({ payload: res, type: C.ENTITIES_FETCHED });

          // If a conversation ID was provided and there are messages in the response
          if (params.conversationId && res.entities.messages) {
            // Pull the most recent message from the response
            const mostRecentMessage = res.entities.messages[mostRecentMessageId];

            // Update the conversation with some of the new data
            const conversation = selectConversation(state, {
              conversationId: params.conversationId
            });
            dispatch<UpdateConversationMessagesAction>({
              payload: {
                page: params.page,
                pages: res.pages,
                conversation,
                messages: messageIds,
                most_recent_message: mostRecentMessage
              },
              type: C.UPDATE_CONVERSATION_MESSAGES
            });
          }
          return res;
        })
        .catch(e => {
          Sentry.captureException(
            new Error(
              `${ERR_MESSAGE_PARSE_FAIL},
                coachId=${params.coachId}, userId=${params.userId},
                conversationId=${params.conversationId}, error message: ${e.message}`
            )
          );
          return Promise.reject(e);
        });
    };
  },
  getScheduledMessages(
    params: GetScheduledMessagesParams,
    callNewEndpoint?: boolean
  ): ThunkAction<Promise<ScheduledMessageResponse | void>> {
    return (dispatch, getState) => {
      const state = getState();
      const api = getApiClient(state);
      const adminUuid = selectAdmin(state)?.uuid;

      const getScheduledMessagesEndpoint = callNewEndpoint ?
        api.messages.getScheduledMessagesNew : api.messages.getScheduledMessages;

      return getScheduledMessagesEndpoint({
        query: GET_SCHEDULED_MESSAGES,
        variables: { ...params, coachId: adminUuid }
      })
        .then((res: ScheduledMessageResponse) => {
          const scheduledMessageIds: string[] = res.result.map(id => id.toString());

          dispatch({ payload: res, type: C.ENTITIES_FETCHED });

          const conversation = selectConversation(state, {
            conversationId: params.conversationId
          });

          dispatch<UpdateConversationScheduledMessagesAction>({
            payload: {
              conversation,
              scheduled_messages: scheduledMessageIds
            },
            type: C.UPDATE_CONVERSATION_SCHEDULED_MESSAGES
          });

          return res;
        });
    };
  },
  updateMessage(
    userId: number,
    message: Message,
    params: MessageURIParams,
    userUuid?: string,
    callNewEndpoint?: boolean
  ): ThunkAction<Promise<MessageResponse | void>> {
    const messageFieldsToUpdate = {
      id: message.id,
      body: message.body,
      scheduledFor: message.scheduledFor,
      seenAt: message.seenAt
    };

    return (dispatch, getState) => {
      const state = getState();
      const api = getApiClient(state);
      const adminUuid = selectAdmin(state).uuid;

      // Make API call to update the message. Either call the legacy endpoint or the new endpoint
      const updateMessageEndpoint = callNewEndpoint ? api.messages.updateNew : api.messages.update;
      return updateMessageEndpoint({
        query: UPDATE_MESSAGE,
        variables: {
          conversationId: params.conversationId,
          message: messageFieldsToUpdate,
          messageId: params.id,
          userUuid,
          adminUuid
        }
      })
        .then(res => {
          // Send the response from the api call above to the store
          dispatch<FetchMessageAction>({
            payload: res,
            type: C.FETCH_MESSAGE
          });

          if (res.entities && res.entities.messages) {
            // Pull the last message off of the res.entities.messages obj
            const messageToUpdate = R.last(Object.values(res.entities.messages));

            const conversation = selectConversation(state, {
              conversationId: params.conversationId
            });

            // If there is a message to update, send conversation and message to store to update the state
            if (messageToUpdate) {
              dispatch<UpdateMessageAction>({
                payload: {
                  conversation,
                  message: messageToUpdate
                },
                type: C.PUT_MESSAGE
              });
            }
          }
          // If the seen_at field is being updated, decrement the number of unread message for that user
          if (messageFieldsToUpdate.seenAt) {
            dispatch<ReceiveUnseenMessageCountAction>({
              payload: { userId, count: -1 },
              type: C.UPDATE_UNREAD_MESSAGE_COUNT
            });
          }
          return res;
        })
        .catch(e => {
          Sentry.captureException(
            new Error(
              `${ERR_MESSAGE_PARSE_FAIL},
            message id: ${params.id}, conversation id: ${params.conversationId}, error message: ${e.message}`
            )
          );
          return Promise.reject(e);
        });
    };
  },
  postMessage(
    conversationId: string,
    messageComposed: MessageComposed,
    callNewEndpoint?: boolean
  ): ThunkAction<Promise<void>> {
    return (dispatch, getState) => {
      const state = getState();
      const api = getApiClient(state);

      // Make API call to fetch messages. Either call the legacy getMessages endpoint or the new getMessages endpoint
      const postMessagesEndpoint = callNewEndpoint ? api.messages.postNew : api.messages.post;
      return postMessagesEndpoint({ query: POST_MESSAGE, variables: { conversationId, message: messageComposed } })
        .then((res: MessageResponse) => {
          dispatch<FetchMessageAction>({
            payload: res,
            type: C.FETCH_MESSAGE
          });

          const message = R.last(Object.values(res.entities.messages)) || {
            id: ''
          };

          const conversation = selectConversation(state, { conversationId });

          dispatch<PostMessageAction>({
            payload: {
              conversation,
              message
            },
            type: C.POST_MESSAGE
          });
        })
        .catch(e => {
          Sentry.captureException(
            new Error(
              `${ERR_MESSAGE_SEND_FAIL}, conversation id: ${conversationId}, error name: ${e?.name},
            error message: ${e?.message}`
            )
          );
          return Promise.reject(e);
        });
    };
  },
  deleteMessage(
    messageId: string,
    conversationId: string,
    callNewEndpoint?: boolean
  ): ThunkAction<Promise<DeleteMessageAction>> {
    return (dispatch, getState) => {
      const state = getState();
      const api = getApiClient(state);
      const conversation = selectConversation(state, {
        conversationId
      });

      // Make API call to update the message. Either call the legacy endpoint or the new endpoint
      const deleteMessageEndpoint = callNewEndpoint ? api.messages.deleteNew : api.messages.delete;
      return deleteMessageEndpoint({
        query: DELETE_MESSAGE,
        variables: {
          messageId: messageId.toString(),
          conversationId
        }
      })
        .then((res: {}) => {
          mixpanelTrack('Scheduled Message Deleted', {
            Origin: window.location.pathname,
            MessageId: messageId,
            ConversationId: conversationId
          });

          return dispatch<DeleteMessageAction>({
            type: C.DELETE_SCHEDULED_MESSAGE,
            payload: { messageId, conversation }
          });
        })
        .catch(e => {
          Sentry.captureException(
            new Error(
              `${ERR_SCHEDULED_MESSAGE_DELETE_FAIL}, message id: ${messageId}, conversation id: ${conversationId},
            error message: ${e?.message}`
            )
          );
          return Promise.reject(
            `${ERR_SCHEDULED_MESSAGE_DELETE_FAIL}, message id: ${messageId}, conversation id: ${conversationId},
            error message: ${e?.message}`
          );
        });
    };
  },
  markAsRead(
    userId: number,
    message: Message,
    params: MessageURIParams,
    userUuid?: string,
    callNewEndpoint?: boolean
  ): ThunkAction<Promise<MessageResponse | void>> {
    const messageFieldsToUpdate = {
      id: message.id,
      body: message.body,
      seenAt: message.seenAt
    };

    return (dispatch, getState) => {
      const state = getState();
      const api = getApiClient(state);
      const admin = selectAdmin(state);

      // Either call the legacy endpoint or the new endpoint
      const markAsReadEndpoint = callNewEndpoint ? api.messages.markAsReadNew : api.messages.markAsRead;
      return markAsReadEndpoint({
        query: MARK_AS_READ,
        variables: {
          adminUuid: admin.uuid,
          conversationId: params.conversationId,
          message: messageFieldsToUpdate,
          messageId: params.id,
          userUuid
        }
      })
        .then(res => {
          dispatch<FetchMessageAction>({
            payload: res,
            type: C.FETCH_MESSAGE
          });

          dispatch<ReceiveUnseenMessageCountAction>({
            payload: { userId, count: -1 },
            type: C.UPDATE_UNREAD_MESSAGE_COUNT
          });
          return res;
        })
        .catch(e => {
          Sentry.captureException(
            new Error(
              `${ERR_MESSAGE_PARSE_FAIL},
            message id: ${params.id}, conversation id: ${params.conversationId}, error message: ${e.message}`
            )
          );
          return Promise.reject(e);
        });
    };
  }
};
