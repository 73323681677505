import { TypedRecord, makeTypedFactory } from 'typed-immutable-record';
import { Map } from 'immutable';

import { Screener } from '../../api-client';
import { RootAction } from '../actions';
import { ENTITIES_FETCHED } from '../constants';
import { createEntityMap, mergeUpdate } from '../../utils/entity';

export interface ScreenerRecord extends TypedRecord<ScreenerRecord>, Screener { }

export const createScreenerRecord = makeTypedFactory<Screener, ScreenerRecord>({
  id: NaN,
  created_at: undefined,
  submitted_at: undefined,
  pain: undefined,
  surgery_likelihood_1yr: undefined,
  surgery_likelihood_2yr: undefined,
  surgery_likelihood_5yr: undefined,
  secondary_metric: undefined,
  surgery_free_text: undefined
});

export type State = Map<number, ScreenerRecord>;

export const initialState: State = Map();

export const reducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case ENTITIES_FETCHED:
      return action.payload.entities.screeners
        ? mergeUpdate(state, createEntityMap(createScreenerRecord, action.payload.entities.screeners))
        : state;
    default:
      return state;
  }
};
