import * as React from 'react';
import { isNullOrUndefined } from 'util';
import './EngagementStreak.scss';

interface EngagementStreakProps {
  engagementStreak?: number | null;
}

class EngagementStreak extends React.PureComponent<EngagementStreakProps> {
  render() {
    if (isNullOrUndefined(this.props.engagementStreak) || this.props.engagementStreak === 0) {
      return <span>N/A</span>;
    }
    return (
      <>
        {
          this.props.engagementStreak > 0
            ? <div className='engagement_streak--active'>
              {`Active ${this.props.engagementStreak}W`}
            </div>
            :
            <div className='engagement_streak--inactive'>{`Inactive ${Math.abs(this.props.engagementStreak)}W`}</div>
        }
      </>
    );
  }
}

export default EngagementStreak;

