import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { UserProfileRoutes } from './utils/user/routes';
import PrivateRoute from './containers/auth/PrivateRoute';

import TaskOverviewContainer from './containers/tasks/TaskOverviewContainer';
import TeamsContainer from './containers/teams/phx/TeamsContainer';
import AuthCallbackContainer from './containers/AuthCallbackContainer';
// legacy user
import UserOverviewContainer from './containers/user/UserOverviewContainer';
import UserHistoryContainer from './containers/user/UserHistoryContainer';
import UserEngagementContainer from './containers/user/UserEngagementContainer';
import UserExerciseContainer from './containers/user/UserExerciseContainer';
import UserPainContainer from './containers/user/UserPainContainer';
import UserSurgeryContainer from './containers/user/UserSurgeryContainer';
// phoenix user
import UserTypeContainer from './containers/user/phx/UserTypeContainer';
import { default as PhxUserOverviewContainer } from './containers/user/phx/UserOverviewContainer';
import { default as PhxUserHistoryContainer } from './containers/user/phx/UserHistoryContainer';
import PhxUserEngagementContainer from './containers/user/phx/PhxUserEngagementContainer';
import PhxUserPainContainer from './containers/user/phx/PhxUserPainContainer';
import PhxUserLegacyEngagementContainer from './containers/user/phx/PhxUserLegacyEngagmentContainer';
import UserStateRegulationsContainer from './containers/user/UserStateRegulationsContainer';
import UserCarePlansContainer from './containers/user/UserCarePlansContainer';

import CommunicationsLayout from './components/communications/CommunicationsLayout';

import NotFoundPage from './404';
import OAuthLoginPage from './components/auth/OAuthLoginPage';
import MassMessagingContainer from './containers/massmessaging/MassMessagingContainer';

import VideoCallContainer from './containers/videocall/VideoCallContainer';
import AdminCarePlansContainer from './containers/AdminCarePlansContainer';

export const MESSAGING_PATH = '/messaging';
export const MASS_MESSAGING_PATH = '/mass-messaging';
export const CARE_PLANS_PATH = '/care-plans';
export const OKTA_CALLBACK_PATH = '/okta/callback';
export const TEAM_PATH = '/teams/:teamId';
export const USER_PATH = '/users/:userId';

export default () => (
  <Switch>
    <Route exact path="/">
      <OAuthLoginPage />
    </Route>
    <Route exact path="/login">
      <OAuthLoginPage />
    </Route>
    <Route
      exact
      path={OKTA_CALLBACK_PATH}
      component={AuthCallbackContainer}
    />
    <PrivateRoute exact path="/tasks" component={TaskOverviewContainer} />
    <PrivateRoute exact path="/teams" component={TeamsContainer} />

    <PrivateRoute
      isGrommet
      path={MESSAGING_PATH}
      component={CommunicationsLayout}
    />
    <PrivateRoute
      exact
      path={MASS_MESSAGING_PATH}
      component={MassMessagingContainer}
    />
    <PrivateRoute exact path="/user/:id">
      <UserTypeContainer
        phoenix={PhxUserOverviewContainer}
        legacy={UserOverviewContainer}
      />
    </PrivateRoute>
    <PrivateRoute exact path="/user/:id/history">
      <UserTypeContainer
        phoenix={PhxUserHistoryContainer}
        legacy={UserHistoryContainer}
      />
    </PrivateRoute>
    <PrivateRoute exact path={`/user/:id/${UserProfileRoutes.Engagement}`}>
      <UserTypeContainer
        phoenix={PhxUserEngagementContainer}
        legacy={UserEngagementContainer}
      />
    </PrivateRoute>
    <PrivateRoute
      exact
      path={`/user/:id/${UserProfileRoutes.LegacyEngagement}`}
    >
      <UserTypeContainer
        phoenix={PhxUserLegacyEngagementContainer}
        legacy={UserEngagementContainer}
      />
    </PrivateRoute>
    <PrivateRoute
      exact
      path={`/user/:id/${UserProfileRoutes.Exercise}`}
      component={UserExerciseContainer}
    />
    <PrivateRoute exact path={`/user/:id/${UserProfileRoutes.Pain}`}>
      <UserTypeContainer
        phoenix={PhxUserPainContainer}
        legacy={UserPainContainer}
      />
    </PrivateRoute>
    <PrivateRoute
      exact
      path={`/user/:id/${UserProfileRoutes.Surgery}`}
      component={UserSurgeryContainer}
    />
    <PrivateRoute
      exact
      path={`/user/:id/${UserProfileRoutes.VideoCall}`}
      component={VideoCallContainer}
    />
    <PrivateRoute
      exact
      path={`/user/:id/${UserProfileRoutes.StateRegulations}`}
      component={UserStateRegulationsContainer}
    />
    <PrivateRoute
      exact
      path={`/user/:id/${UserProfileRoutes.CarePlans}`}
      component={UserCarePlansContainer}
    />
    <PrivateRoute
      exact
      path={CARE_PLANS_PATH}
      component={AdminCarePlansContainer}
    />
    <PrivateRoute component={NotFoundPage} />
  </Switch>
);
