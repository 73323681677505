import { CreateVideoCallResponse,
  CreateJwtMutationVariables,
  CreateJwtResponse,
  GetVideoCallsResponse
} from 'src/api-client/endpoints';
import { GET_VIDEO_CALLS } from '../../graphql/queries/VideoCalls';
import { CREATE_VIDEO_CALL, CREATE_JWT } from '../../graphql/mutations';
import { getApiClient } from '../selectors';
import { ThunkAction } from './interfaces';

interface CreateVideoCallMutationVariables {
  adminUuid: string;
  userUuid: string;
  identifier: string;
  scheduledAt: string;
  basiliskPathwayId: number | null | undefined;
}

interface GetVideoCallsVariables {
  userUuid: string;
}

export const actionCreators = {
  createVideoCall: ({
    adminUuid,
    userUuid,
    identifier,
    scheduledAt,
    basiliskPathwayId
  }: CreateVideoCallMutationVariables): ThunkAction<Promise<CreateVideoCallResponse>> =>
    (_, getState) => {
      const state = getState();
      const api = getApiClient(state);

      return api.videoCall.post({
        query: CREATE_VIDEO_CALL,
        variables: {
          adminUuid,
          userUuid,
          basiliskPathwayId,
          identifier,
          scheduledAt
        }
      });
    },
  createJwt: ({uuid, identifier}: CreateJwtMutationVariables): ThunkAction<Promise<CreateJwtResponse>> =>
    (_, getState) => {
      const state = getState();
      const api = getApiClient(state);

      return api.videoCall.createJwt({
        query: CREATE_JWT,
        variables: {
          uuid,
          identifier
        }
      });
    },
  getVideoCalls: ({userUuid}: GetVideoCallsVariables): ThunkAction<Promise<GetVideoCallsResponse>> =>
    (_, getState) => {
      const state = getState();
      const api = getApiClient(state);

      return api.videoCall.getVideoCalls({
        query: GET_VIDEO_CALLS,
        variables: {
          userUuid
        }
      });
    }
};
