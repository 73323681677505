import { isNullOrUndefined } from '../helpers';
import { User as LegacyUser } from '../../api-client';
import { User } from '../../graphql/models/User';

export enum ContactMethod {
  Sms = 'sms',
  Native = 'native',
  Email = 'email',
  Call = 'call'
}

function isLegacyUser(user: User | LegacyUser): user is LegacyUser {
  return user.phoenix ?? false;
}

export function contactMethodExists(user: User | LegacyUser, method: string) {
  if (isLegacyUser(user)) {
    return user.contactMethod && user.contactMethod.indexOf(method) > -1;
  }
  return user.contactMethod && user.contactMethod.indexOf(method) > -1;
}

export function getContactText(user: User | LegacyUser) {
  return contactMethodExists(user, ContactMethod.Sms) && ContactMethod.Sms;
}

export function getContactNative(user: User | LegacyUser) {
  return contactMethodExists(user, ContactMethod.Native) && ContactMethod.Native;
}

export function getContactEmail(user: User | LegacyUser) {
  return contactMethodExists(user, ContactMethod.Email) && ContactMethod.Email;
}

export function getContactCall(user: User | LegacyUser) {
  return contactMethodExists(user, ContactMethod.Call) && ContactMethod.Call;
}

export function doNotContact(user: User | LegacyUser) {
  if (isLegacyUser(user)) return isNullOrUndefined(user.contactMethod);
  return isNullOrUndefined(user.contactMethod);
}
