import { Chip, Grid, Typography } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import { MemberAlert } from '../../api-client/interfaces';
import { toShortDate } from '../../utils/datetime';
import { toNormalizedWords } from '../../utils/string';
import { MEMBER_BANNER_TEST_IDS } from './constants/memberAlertIcon';

interface MemberAlertIconModalProps {
  memberAlerts: (MemberAlert | null)[];
  icon: JSX.Element;
}

const StlyedMemberAlertIconGrid = styled(Grid)`
  padding: 16px;
  width: 300px;
`;

const StyledDateRangeGrid = styled(Grid)`
  padding-left: 8px;
`;

const StyledTopRowGrid = styled(Grid)`
  padding-bottom: 8px;
`;

const StyledDescriptionRow = styled(Grid)`
  padding-bottom: 8px;
`;

const StyledHasMoreGrid = styled(Grid)`
  border-top: 1px solid #C4C4C4;
  padding-top: 8px;
`;

const MemberAlertIconModal: React.FC<MemberAlertIconModalProps> = ({
  memberAlerts,
  icon
}) => {
  if (!memberAlerts || memberAlerts.length === 0) return <></>;

  const firstMemberAlert = memberAlerts[memberAlerts.length -1];
  const category = firstMemberAlert?.category || '';
  const startAt = firstMemberAlert?.category
    ? toShortDate(firstMemberAlert?.startAt)
    : '';
  const endAt = firstMemberAlert?.endAt
    ? toShortDate(firstMemberAlert?.endAt)
    : '';
  const description = firstMemberAlert?.description
    ? firstMemberAlert?.description.trim()
    : '';
  const remindAt = firstMemberAlert?.remindAt
    ? `Reminder task: ${toShortDate(firstMemberAlert?.remindAt)}`
    : null;

  const dateRange = `${startAt} - ${endAt}`;
  const hasMore =
    memberAlerts.length > 1 ? `+${memberAlerts.length - 1} more` : false;

  return (
    <StlyedMemberAlertIconGrid
      container
      direction="column"
      data-testid={MEMBER_BANNER_TEST_IDS.ALERT_ICON_MODAL}
    >
      <StyledTopRowGrid
        container
        spacing={2}
        alignItems="center"
      >
        <Grid item>
          <Chip
            variant="outlined"
            label={toNormalizedWords(category)}
            size="small"
            icon={icon}
            color={firstMemberAlert?.paused ? 'secondary' : 'primary'}
            data-testid={MEMBER_BANNER_TEST_IDS.ALERT_ICON_CHIP}
          />
        </Grid>
        <StyledDateRangeGrid item>
          <Typography variant="body2">{dateRange}</Typography>
        </StyledDateRangeGrid>
      </StyledTopRowGrid>
      <StyledDescriptionRow item>
        <Typography variant="body1">{description}</Typography>
      </StyledDescriptionRow>
      <Grid item>
        {remindAt ? (
          <Typography variant="body2">{remindAt}</Typography>
        ): null}
      </Grid>
      {hasMore ? (
        <StyledHasMoreGrid item>
          <Typography
            variant="inherit"
            data-testid={MEMBER_BANNER_TEST_IDS.ALERT_MODAL_HAS_MORE}
          >{hasMore}</Typography>
        </StyledHasMoreGrid>
      ): null}
    </StlyedMemberAlertIconGrid>
  );
};

export default MemberAlertIconModal;
