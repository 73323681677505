import * as React from 'react';
import { User } from 'src/graphql/models';
import { User as APIUser } from '../../api-client/interfaces';
import { UserRecord } from 'src/redux/reducers';
import { enableUserStrikeThrough } from '../../utils/user/enrollmentStatus';
import StrikeThrough from './StrikeThrough';

interface Props {
  user: User | UserRecord | APIUser;
}

const StrikeThroughUser: React.FC<Props> = ({ user }) => {
  const { id, firstName, lastName } = user;

  const enableStrikeThrough = enableUserStrikeThrough(user);

  return (
    <StrikeThrough
      data-testid={`user-strike-through-${id}`}
      enabled={enableStrikeThrough}
    >{`${firstName} ${lastName}`}</StrikeThrough>
  );
};

export default StrikeThroughUser;
