export const ERR_NO_AUTH = 'CA-E1 Authentication information not found.';
export const ERR_MISCONFIGURED_ENDPOINT =
  'CA-E2 Endpoint configuration is invalid; must specify at least a uriPath or uriTemplate.';
export const ERR_GET_JSON =
  'CA-E3 Cannot send a JSON payload with a GET request.';
export const ERR_MISSING_URI_PARAMS =
  'CA-E4 Missing required URI Parameters for this API call.';
export const ERR_PARSE_FAIL = 'CA-E5 Failed to parse response data.';
export const ERR_TASK_PARSE_FAIL = 'CA-E6 Failed to parse tasks payload.';
export const ERR_USER_PARSE_FAIL = 'CA-E7 Failed to parse user data.';
export const ERR_USER_ID_INVALID =
  'CA-E8 User ID is not valid; a number must be provided.';
export const ERR_DROPDOWN_FAIL =
  'CA-E9 Invariant Violation: Got a non-string value from dropdown callback.';
export const ERR_PUT_USER =
  'CA-E10 Invariant Violation: Failed to update user information';
export const ERR_NO_EDITED_USER =
  'CA-E11 Invariant Violation: No user selected for editing.';
export const ERR_WATCHDOG_FAIL = 'CA-E13 Watchdog initialization failure.';
export const ERR_UNREACHABLE_CODE = 'CA-E14 Unreachable code detected.';
export const ERR_USERS_PARSE_FAIL = 'CA-E15 Failed to parse user member list.';
export const ERR_PATHWAY_STATS_PARSE_FAIL =
  'CA-E16 Failed to parse user pathway stats data.';
export const ERR_CONNECTION_FAIL = 'CA-E17 Connection failure.';
export const ERR_UNAUTHORIZED =
  'CA-E18 Unauthorized (401) reply received from the API.';
export const ERR_FORBIDDEN =
  'CA-E19 Forbidden (403) reply received from the API.';
export const ERR_TAG_NO_USER =
  'CA-E20 Invariant violoation: Cannot add tag to a non-existent user.';
export const ERR_TEAM_PARSE_FAIL = 'CA-E21 Failed to parse teams payload.';
export const ERR_GOALS_PARSE_FAIL = 'CA-E22 Failed to parse goals payload.';
export const ERR_CONTEXT_STATS_PARSE_FAIL =
  'CA-E23 Failed to parse user context stats payload.';
export const ERR_WORKOUTS_PARSE_FAIL =
  'CA-E24 Failed to parse workouts payload.';
export const ERR_EDUCATION_PARSE_FAIL =
  'CA-E25 Failed to parse education reads payload.';
export const ERR_WEIGHT_LOG_PARSE_FAIL =
  'CA-E26 Failed to parse weight log payload';
export const ERR_USER_TYPE_FAIL = 'CA-E27 Failed to parse user type data';
export const E28_ERR_FAILED_TO_LOGOUT = 'CA-E28 Failed to logout';
export const E29_ERR_FAILED_TO_OMNIAUTH =
  'CA-E29 Failed to login via the oauth endpoint';
export const ERR_CONVERSATIONS_PARSE_FAIL =
  'CA-E30 Failed to parse conversations payload';
export const ERR_MESSAGES_PARSE_FAIL =
  'CA-E31 Failed to parse messages payload.';
export const ERR_MESSAGE_PARSE_FAIL = 'CA-E32 Failed to parse message payload';
export const ERR_ADMIN_PARSE_FAIL = 'CA-E33 Failed to parse admin payload';
export const ERR_MESSAGE_SEND_FAIL = 'CA-E34 Failed to send message';
export const ERR_POLLING_FAIL = 'CA-E35 Failed to poll endpoint with message: ';
export const ERR_SCHEDULED_MESSAGE_DELETE_FAIL =
  'CA-E36 Failed to delete scheduled message';
export const ERR_CREATE_NOTE = 'CA-E38 Failed to create note';
export const ERR_JOIN_CALL = 'BN-583 Failed to join call';
export const ERR_CONNECT_CALL = 'BN-620 Failed to connect to video call';
export const ERR_GET_NOTES = 'CA-E39 Failed to fetch notes';
export const ERR_DELETE_NOTE = 'CA-E41 An error occured. Unable to delete note.';
export const ERR_USER_UUID_INVALID =
  'CA-E43 User UUID is not valid; a string must be provided';
export const ERR_UPDATING_ENROLLMENT_STATUS =
  'CA-E44 Failed to update user\'s Enrollment Status.';
export const ERR_TASK_USER_PARSE_FAIL = 'CA-E37 Failed to fetch users by task kind';
export const ERR_ENROLLMENT_USERS_PARSE_FAIL = 'CA-E46 Failed to parse user enrollment status';
export const ERR_SAVING_NOTE = 'CA-E40 Failed to update/save note';
