/* eslint-disable @typescript-eslint/no-explicit-any */
import { Impact, User } from '../graphql/models';
import * as luxon from 'luxon';
import { selectFeatureFlags } from '../redux/selectors';
import { store } from '../store';

export function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
  return obj === undefined || obj === null;
}

export function sortTeamUsersByName(a: User, b: User) {
  if (!a.firstName) return -1;
  if (!b.firstName) return 1;

  let nameA = a.firstName.toUpperCase();
  let nameB = b.firstName.toUpperCase();

  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;

  if (!a.lastName) return -1;
  if (!b.lastName) return 1;

  nameA = a.lastName.toUpperCase();
  nameB = b.lastName.toUpperCase();

  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
}

// The follow two sorts, sorts the Teams by current week then by their id
export function sortTeamByCurrentWeek(a: { currentWeek: number; id: string }, b: { currentWeek: number; id: string }) {
  const sortByWeek = a.currentWeek - b.currentWeek;
  if (sortByWeek !== 0) {
    return sortByWeek;
  }
  return parseInt(a.id, 10) - parseInt(b.id, 10);
}

export function sortTeamByCurrentTeamWeekNumber(
  a: { currentTeamWeekNumber: number; id: string },
  b: { currentTeamWeekNumber: number; id: string }
) {
  const sortByWeek = a.currentTeamWeekNumber - b.currentTeamWeekNumber;
  if (sortByWeek !== 0) {
    return sortByWeek;
  }
  return parseInt(a.id, 10) - parseInt(b.id, 10);
}

export function convertArrayToHash<T, P extends keyof T>(array: T[], prop: P): Record<string | number, T> {
  const hash: Record<string | number, T> = {};
  array.forEach(object => {
    if (typeof object === 'object') {
      const value = object[prop];

      if (typeof value === 'string' || typeof value === 'number') hash[value] = object;
    }
  });
  return hash;
}

export function calculateAge(dateOfBirth: string) {
  const diffOfYears = luxon.DateTime.fromISO(dateOfBirth).diffNow('years').years;

  return Math.abs(Math.trunc(diffOfYears)).toString();
}

export function getOktaAccessToken(): string | null {
  const oktaStore = JSON.parse(localStorage.getItem('okta-token-storage') || '{}');
  return oktaStore.accessToken?.accessToken || null;
}

export function getFeatureFlagValueFromReduxStore(featureFlag: string): boolean {
  return selectFeatureFlags(store.getState()).get(featureFlag);
}

export const formatAuthorizationHeader = (oktaAccessToken: string) => `Bearer ${oktaAccessToken}`;

export const formatImactSurgeriesUnifiedUser = (impact: any) =>
  Object.keys(impact)
    .filter(key => key.includes('upcoming_surgery_type'))
    .map(key => {
      const indicationKey = key.split(':')[1];
      return {
        indication: indicationKey,
        type: impact[`upcoming_surgery_type:${indicationKey}` as keyof Impact],
        date: impact[`upcoming_surgery_date:${indicationKey}` as keyof Impact]
      };
    });
