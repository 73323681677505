import * as React from 'react';
import { Menu, Label, Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { Admin } from '../../api-client';

interface TopNavBarProps {
  notificationCount: number;
  onLogout(): void;
  admin: Admin;
}

export const NotSoFarLabel = styled(Label) `
  position: absolute;
  z-index: 100;
  top: .75em;
  left: 100%;
  margin: 0 0 0 -2.5em !important;
`;

const TopNavBar: React.FunctionComponent<TopNavBarProps> = (props) => (
  <Menu borderless>
    <Menu.Menu position="right">
      <Menu.Item position="right">
        <Dropdown item text={props.admin.email || 'Coach'}>
          <Dropdown.Menu>
            <Dropdown.Item id="logout" data-testid="logout" onClick={props.onLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </Menu.Menu>
  </Menu>
);

export default TopNavBar;
