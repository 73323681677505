import { LoginCallback } from '@okta/okta-react';
import * as React from 'react';

interface AuthRedirectCallbackProps {
  loadingComponent?: JSX.Element;
  errorComponent?: React.ComponentType<{
    error: Error;
  }>;
}

const AuthRedirectCallback = (
  props: AuthRedirectCallbackProps
): JSX.Element => (
  <LoginCallback
    loadingElement={props.loadingComponent}
    errorComponent={props.errorComponent}
  />
);

export default AuthRedirectCallback;
