import { Map } from 'immutable';
import { Entity, CompositeEntity } from '../api-client';
import { compose, toPairs, map, lensIndex, over } from 'ramda';

type RecordCtor<T, U> = (object: T) => U;

export interface EntityCollection<T> {
  [id: number]: T;
}

interface StringEntityCollection<T> {
  [id: string]: T;
}

export function createSingleEntityMap<T extends Entity, U>(createRecord: RecordCtor<T, U>, entity: T): Map<number, U> {
  return Map([[entity.id, createRecord(entity)]]);
}

export function createSingleStringEntityMap<T extends CompositeEntity, U>(
  createRecord: RecordCtor<T, U>,
  entity: T): Map<string, U> {
  return Map([[entity.id, createRecord(entity)]]);
}

/**
 * Turn a object full of entities from a normalized response into an Immutable map keyed by the ID. This is a bit more
 * complicated than it needs to be due to the fact that the IDs are numbers, yet all object keys are always strings.
 */
export const createEntityMap: <T, U>(createRecord: RecordCtor<T, U>, entities: EntityCollection<T>) => Map<number, U> =
  // Issue with typescript https://github.com/Microsoft/TypeScript/issues/9366 and ramda
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  compose(Map, map(over(lensIndex(0), (x) => parseInt(x as string, 10))), toPairs, map) as any;

/**
 * Turn an object full of entities _with string IDs_ from a normalized response into an Immutable map keyed by the ID.
 *
 * Not all entities use numeric IDs, in particular entities with composite IDs. This (simpler) version of
 * createEntityMap is suitable for those.
 */
export const createStringEntityMap: <T, U>(
  createRecord: RecordCtor<T, U>, entities: StringEntityCollection<T>) => Map<string, U> =
  // Issue with typescript https://github.com/Microsoft/TypeScript/issues/9366 and ramda
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  compose(Map, map) as any;

/**
 * Use this method along with `mergeWith` or `mergeDeepWith` to avoid clobbering existing values with `undefined`.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function update(oldVal: any, newVal: any) {
  if (oldVal && oldVal.mergeDeepWith) return oldVal.mergeDeepWith(newVal);
  if (newVal === undefined) return oldVal;
  return newVal;
}

/**
 * A composition of `mergeDeepWith` and `update`.
 */
export function mergeUpdate<K, V>(state: Map<K, V>, next: Map<K, V>) {
  return state.mergeDeepWith(update, next);
}
