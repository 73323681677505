import { TypedRecord, makeTypedFactory } from 'typed-immutable-record';
import { Map } from 'immutable';

import { Team } from '../../api-client';
import { RootAction } from '../actions';
import { ENTITIES_FETCHED } from '../constants';
import { createEntityMap, mergeUpdate } from '../../utils/entity';

export interface TeamRecord extends TypedRecord<TeamRecord>, Team {}

export const createTeamRecord = makeTypedFactory<Team, TeamRecord>({
  id: NaN,
  name: undefined,
  avatarUrl: undefined,
  currentTeamWeekNumber: undefined,
  productType: undefined,
  usersCount: undefined,
  users: undefined,
  newestUnseenMessages: undefined
});

export type State = Map<number, TeamRecord>;

export const initialState: State = Map();

export const reducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case ENTITIES_FETCHED:
      return action.payload.entities.teams
        ? mergeUpdate(
          state,
          createEntityMap(createTeamRecord, action.payload.entities.teams)
        )
        : state;
    default:
      return state;
  }
};
