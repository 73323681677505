import * as moment from 'moment';
import {
  VideoTrackPublication,
  AudioTrackPublication,
  AudioTrack,
  VideoTrack
} from 'twilio-video';

export const convertAudioPublicationsToTracks = (publicationsMap: Map<string, AudioTrackPublication>) => {
  const publications = Array.from(publicationsMap);
  const audioTracks: Array<AudioTrack> = [];
  publications.forEach((publication) => {
    if (publication[1].track) {
      const audioTrack: AudioTrack = publication[1].track;
      audioTracks.push(audioTrack);
    }
  });
  return audioTracks;
};

export const convertVideoPublicationsToTracks = (publicationsMap: Map<string, VideoTrackPublication>) => {
  const publications = Array.from(publicationsMap);
  const videoTracks: Array<VideoTrack> = [];
  publications.forEach((publication) => {
    if (publication[1].track) {
      const videoTrack: VideoTrack = publication[1].track;
      videoTracks.push(videoTrack);
    }
  });
  return videoTracks;
};

export const createRoomIdentifier = (userUuid: string, adminUuid: string, timestamp: Date) => {
  const timestampFormatted = moment(timestamp).format('MM/D/YYYY');
  const identifierFormatted = `${userUuid}:${adminUuid}:${timestampFormatted}`;
  return `room-${btoa(identifierFormatted)}`;
};
