import * as React from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Container, Button, Typography } from '@material-ui/core';
import { GET_USER_CARE_PLANS, GET_USER_DATA_FOR_CARE_PLANS } from '../../graphql/queries/User';
import { ApiClientNames } from '../apollo/ApolloContainer';
import { GET_CARE_PLAN_CONFIG } from '../../graphql/queries/index';
import CreateCarePlanContainer from './CreateCarePlanContainer';
import UpdateCarePlanContainer from './UpdateCarePlanContainer';
import { Icd10, CarePlan } from '../../api-client/interfaces';
import CarePlanCard from '../../components/user/CarePlan';
import { Pathway } from '../../graphql/models/Pathway';

export const CARE_PLANS_PER_PAGE = 25;
// TODO: move this to state when paginating
export const currentPage = 0;

const StyledContainer = styled(Container)`
  padding: 24px;
  height: 400px;
  display: flex;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 24px 0;
`;

const EmptyCarePlansContainer = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

interface UserCarePlansResponse {
  user: {
    carePlans: CarePlan[];
  };
}
interface UserDataForCarePlansResponse {
  getUserById: {
    uuid: string;
    stateOfResidence: string;
    pathways: Pathway[];
  };
}

export enum DischargeReasons {
  MetAllOrMostGoals = 'Met all/most goals and is appropriate for independent HEP',
  NotProgressingWithProgram = 'Not progressing with HH Program',
  NotAppropriateForProgram = 'Not appropriate for HH program and/or referred to HCP',
  DidNotFollowUp = 'Did not follow up to continue care',
  IneligibleActiveWorkersCompCase = 'Ineligible - Active Worker\'s Comp Case',
}

export type UpdateCarePlanFields = Pick<CarePlanProps, 'id' | 'status' | 'items' | 'userId'>;

export interface CarePlanProps extends Omit<CarePlan, 'icd10'> {
  diagnoses: string[];
}

export interface PtLegalRequirements {
  informHcp: number | boolean;
  daLimit: number | false;
  ptVisitLimit: number;
}

const CarePlansContainer: React.FC<{ userUuid: string; adminUuid: string }> = ({ userUuid, adminUuid }) => {
  const [openCreateCarePlan, setOpenCreateCarePlan] = React.useState<boolean>(false);
  const [openUpdateCarePlan, setOpenUpdateCarePlan] = React.useState<boolean>(false);
  const [updateCarePlanFields, setUpdateCarePlanFields] = React.useState<UpdateCarePlanFields | null>(null);

  const { data: userCarePlans } = useQuery<
  UserCarePlansResponse,
  {
    uuid: string;
    itemsPerPage: number;
    page: number;
  }
  >(GET_USER_CARE_PLANS, {
    variables: { uuid: userUuid, itemsPerPage: CARE_PLANS_PER_PAGE, page: currentPage },
    context: { clientName: ApiClientNames.NestBFF }
  });

  const { data: userDataForCarePlans, loading } = useQuery<
  UserDataForCarePlansResponse,
  {
    userUuid: string;
  }
  >(GET_USER_DATA_FOR_CARE_PLANS, {
    variables: { userUuid },
    context: { clientName: ApiClientNames.BFF }
  });

  const { data } = useQuery<{
    carePlanConfig: {
      icd10: Icd10[];
    };
  }>(GET_CARE_PLAN_CONFIG, {
    context: { clientName: ApiClientNames.NestBFF }
  });

  const diagnosesOptions = data?.carePlanConfig?.icd10 || [];

  const handleOpenCreateCarePlan = (event: React.MouseEvent<{}>): void => {
    event.stopPropagation();
    setOpenCreateCarePlan(true);
  };

  const handleCloseCreateCarePlan = () => {
    setOpenCreateCarePlan(false);
  };

  const selectAndOpenUpdateCarePlan = (event: React.MouseEvent<{}>, fields: UpdateCarePlanFields): void => {
    event.stopPropagation();
    setOpenUpdateCarePlan(true);
    setUpdateCarePlanFields(fields);
  };

  const handleOpenUpdateCarePlan = (): void => setOpenUpdateCarePlan(true);

  const handleCloseUpdateCarePlan = () => setOpenUpdateCarePlan(false);

  const buildCarePlanProps = (carePlan: CarePlan): CarePlanProps => ({
    ...carePlan,
    diagnoses: carePlan.icd10.map(icd10Item => icd10Item.description)
  });

  const carePlansProps: CarePlanProps[] | null | undefined =
    userCarePlans?.user.carePlans && userCarePlans?.user.carePlans.map(buildCarePlanProps);

  return (
    <StyledContainer maxWidth='sm'>
      {!!diagnosesOptions.length && (
        <StyledButtonContainer>
          <Button variant='contained' color='primary' onClick={handleOpenCreateCarePlan}>
            Create
          </Button>
        </StyledButtonContainer>
      )}
      {openCreateCarePlan && userDataForCarePlans && !!diagnosesOptions.length && (
        <CreateCarePlanContainer
          openCreateCarePlan={openCreateCarePlan}
          closeCarePlanContainer={handleCloseCreateCarePlan}
          diagnosesOptions={diagnosesOptions}
          adminUuid={adminUuid}
          userUuid={userDataForCarePlans.getUserById.uuid}
          stateOfResidence={userDataForCarePlans.getUserById.stateOfResidence}
          pathway={userDataForCarePlans.getUserById.pathways[0]}
        />
      )}
      {updateCarePlanFields && (
        <UpdateCarePlanContainer
          adminUuid={adminUuid}
          openUpdateCarePlan={openUpdateCarePlan}
          handleCloseUpdateCarePlan={handleCloseUpdateCarePlan}
          handleOpenUpdateCarePlan={handleOpenUpdateCarePlan}
          updateCarePlanFields={updateCarePlanFields}
        />
      )}
      {!carePlansProps || !carePlansProps.length ? (
        <EmptyCarePlansContainer>
          <Typography data-testid='emptyCarePlans' variant='body2' color='textSecondary'>
            {!loading && 'No care plans'}
          </Typography>
        </EmptyCarePlansContainer>
      ) : (
        carePlansProps.map(carePlanProps => (
          <CarePlanCard key={carePlanProps.id} carePlan={carePlanProps} moreAction={selectAndOpenUpdateCarePlan} />
        ))
      )}
    </StyledContainer>
  );
};

export default CarePlansContainer;
