import { RootState, GoalRecord } from '../reducers';
import { selectUser } from './user';
import { createSelector } from 'reselect';
import { isNumber } from '../../utils/number';
import * as moment from 'moment-timezone';
import { GoalStatus } from '../../api-client/index';

// select entire goals state
function selectGoalsState(state: RootState) {
  return state.goals;
}

export function sortUserGoalsByDueDate(a: GoalRecord, b: GoalRecord) {
  if (b.status === GoalStatus.DONE && a.status === GoalStatus.ONGOING) {
    return -1;
  }
  if (a.status === GoalStatus.DONE && b.status === GoalStatus.ONGOING) return 1;
  if (!a.done_by && b.done_by) return -1;
  if (!b.done_by && a.done_by) return 1;
  if (!a.done_by || !b.done_by) return 0;

  const aDate = moment.utc(a.done_by);
  const bDate = moment.utc(b.done_by);

  return aDate.valueOf() - bDate.valueOf();
}

/** Selects all goals for the user */
export const makeSelectUserGoalFactory = () =>
  createSelector(
    [selectUser, selectGoalsState],
    (user, goals): GoalRecord[] => {
      if (!user || !user.goals || !isNumber(user.get('id'))) return [];
      const userGoals = user.goals.map(goalId => goals.get(goalId));
      return userGoals.sort(sortUserGoalsByDueDate);
    }
  );
