import * as React from 'react';
import {
  BarChart,
  XAxis,
  YAxis,
  Legend,
  Bar,
  ResponsiveContainer,
  LabelList,
  Tooltip
} from 'recharts';
import styled from 'styled-components';
import * as moment from 'moment-timezone';
import { Map } from 'immutable';
import MultilineTick from './MultilineTick';
import { ORANGE, GREEN, BLUE, RED } from '../../colors';
import { WeeklyMetric, User } from '../../api-client';
import { isNumber, formatToInt } from '../../utils/number';
import { DEFAULT_TIMEZONE } from '../../utils/user/timezone';
import { HANDLE_RESIZE_WAIT } from '../../utils/chart';

interface EngagementChartProps {
  endWeek: number;
  startWeek: number;
  weeklyMetrics: Map<string, WeeklyMetric>;
  user: User;
}

interface ChartDatum {
  ET: number;
  Edu: number;
  Other: number;
  Inactive: string;
  weekLabel: string;
}

export function generateChartData(props: EngagementChartProps): ChartDatum[] {
  const { endWeek, startWeek, user, weeklyMetrics } = props;

  if (!user.team || !isNumber(user.team.currentTeamWeekNumber)) return [];

  const data: ChartDatum[] = [];
  const startDate = moment.tz(user.timezone || DEFAULT_TIMEZONE)
    .startOf('isoWeek')
    .subtract(user.team.currentTeamWeekNumber, 'weeks')
    .add(startWeek, 'weeks');
  let inactiveWeeks = 0;

  for (let i = startWeek; i < endWeek; i++ , startDate.add(1, 'week')) {
    const weekLabel = `W${i}\n${startDate.format('M/D')}`;
    const metrics = weeklyMetrics.get(`${user.id}:${i}`);

    if (!metrics) {
      data.push({ ET: 0, Edu: 0, Other: 0, Inactive: '', weekLabel });
      continue;
    }

    let exerciseCompletion = 0;
    let eduCompletion = 0;
    let pointsCompletion = 0;
    let aerobicCompletion = 0;

    if (
      isNumber(metrics.exercises) &&
      isNumber(metrics.exercises_goal) &&
      metrics.exercises_goal
    ) {
      exerciseCompletion = metrics.exercises / metrics.exercises_goal;
    }
    if (
      isNumber(metrics.education_reads) &&
      isNumber(metrics.education_reads_goal) &&
      metrics.education_reads_goal
    ) {
      eduCompletion = metrics.education_reads / metrics.education_reads_goal;
    }

    if (
      isNumber(metrics.points_earned) &&
      isNumber(metrics.points_goal) &&
      metrics.points_goal
    ) {
      pointsCompletion = metrics.points_earned / metrics.points_goal;
    }
    if (
      isNumber(metrics.aerobic_workouts) &&
      isNumber(metrics.aerobic_workouts_goal) &&
      metrics.aerobic_workouts_goal
    ) {
      aerobicCompletion =
        metrics.aerobic_workouts / metrics.aerobic_workouts_goal;
    }

    if (
      exerciseCompletion === 0 &&
      eduCompletion === 0 &&
      pointsCompletion === 0 &&
      aerobicCompletion === 0
    ) {
      inactiveWeeks++;
    } else {
      inactiveWeeks = 0;
    }

    data.push({
      ET: formatToInt(exerciseCompletion * 100),
      Edu: formatToInt(eduCompletion * 100),
      Other: formatToInt((pointsCompletion + aerobicCompletion) * 50),
      Inactive: inactiveWeeks === 0 ? '' : `W${inactiveWeeks}`,
      weekLabel
    });
  }

  return data;
}

export const C = styled(BarChart)`
  margin: 0 auto;
`;

const EngagementChart: React.FunctionComponent<EngagementChartProps> = props => {
  const data = generateChartData(props);

  return (
    <ResponsiveContainer debounce={HANDLE_RESIZE_WAIT} width="100%" height={480}>
      <BarChart data={data}>
        <XAxis
          dataKey="weekLabel"
          type="category"
          interval={0}
          tick={<MultilineTick />}
          height={42}
        />
        <YAxis ticks={[0, 25, 50, 75, 100]} tickFormatter={(n: string) => `${n}%`} />
        <Tooltip />
        <Legend verticalAlign="top" align="right" />
        <Bar dataKey="ET" fill={ORANGE} />
        <Bar dataKey="Edu" fill={GREEN}>
          <LabelList dataKey="Inactive" position="top" stroke={RED} />
        </Bar>
        <Bar dataKey="Other" fill={BLUE} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default EngagementChart;
