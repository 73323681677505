import * as React from 'react';
import styled from 'styled-components';
import * as doggo404 from './images/404.jpg';

const BlackPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
`;

const NotFoundPage: React.FunctionComponent = () => (
  <BlackPage>
    <img src={doggo404} />
  </BlackPage>
);

export default NotFoundPage;
