import { TypedRecord, makeTypedFactory } from 'typed-immutable-record';
import { Map } from 'immutable';
import * as R from 'ramda';
import {
  Conversation,
  Message, MessageDirection,
  MessageStatus
} from '../../api-client/interfaces';
import { RootAction } from '../actions';
import {
  ENTITIES_FETCHED,
  UPDATE_CONVERSATION_MESSAGES,
  UPDATE_CONVERSATION_SCHEDULED_MESSAGES,
  POST_MESSAGE,
  PUT_MESSAGE,
  RECEIVE_UNSEEN_MESSAGE,
  RECEIVE_MESSAGE_STATUS,
  DELETE_SCHEDULED_MESSAGE,
  ADD_UNSEEN_MESSAGES_TO_LIST,
  ADD_UNSEEN_MESSAGES
} from '../constants';
import {
  mergeUpdate,
  createStringEntityMap,
  createSingleStringEntityMap
} from '../../utils/entity';
import { messageParser, TodoResponse } from '../../api-client';
import { map, uniq } from 'lodash';
import { messageComparator } from '../selectors';

export interface ConversationRecord
  extends TypedRecord<ConversationRecord>,
  Conversation { }

export const createConversationRecord = makeTypedFactory<Conversation, ConversationRecord>({
  id: '',
  userId: undefined,
  teamId: undefined,
  type: undefined,
  subject: undefined,
  unseenMessageIds: undefined,
  mostRecentMessageId: undefined,
  messageIds: undefined,
  scheduledMessageIds: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  totalPages: undefined,
  currentPage: undefined
});

export type State = Map<number | string, ConversationRecord>;

type PostMessage = (
  state: State,
  message: Message,
  conversation?: ConversationRecord
) => State;
const postMessage: PostMessage = (state, message, conversation) => {
  if (!conversation) return state;

  if (message.status === MessageStatus.Scheduled && message.scheduledFor) {
    return state.set(
      conversation.id,
      conversation.update('scheduledMessageIds', (messages?: string[]) => {
        if (!messages) return [message.id];
        return messages.concat([message.id]);
      })
    );
  }

  const newConversation = conversation.set(
    'mostRecentMessageId',
    message.id
  );

  return state.set(
    conversation.id,
    newConversation.update('messageIds', (messages?: string[]) => {
      if (!messages) return [message.id];
      return messages.concat([message.id]);
    })
  );
};

type ReceiveUnseenMessage = (
  state: State,
  message: Message,
  team_id: number,
  conversation: ConversationRecord
) => State;
const receiveUnseenMessage: ReceiveUnseenMessage = (
  state,
  message,
  team_id,
  conversation
) => {
  const conversationWithTeamId = conversation.set('teamId', team_id);

  const messageIds = conversationWithTeamId.get('messageIds');

  for (let i = messageIds?.length - 1; i >= 0; i -= 1) {
    if (messageIds[i] === message.id) return state;
  }

  const conversationMostRecentUpdated = conversationWithTeamId.set(
    'mostRecentMessageId',
    message.id
  );

  const conversationUnseenMessagesUpdated = conversationMostRecentUpdated.update(
    'unseenMessageIds',
    (unseenMessages?: string[]) => {
      if (!unseenMessages) return [message.id];
      return [message.id].concat(unseenMessages);
    }
  );
  const conversationUpdated = conversationUnseenMessagesUpdated.update(
    'messageIds',
    (messages?: string[]) => {
      if (!messages) return [message.id];
      return messages.concat([message.id]);
    }
  );

  return state.set(conversation.id, conversationUpdated);
};

type ReceiveUnseenMessages = (
  state: State,
  message: Message[],
  team_id: number,
  conversation: ConversationRecord
) => State;
const receiveUnseenMessages: ReceiveUnseenMessages = (
  state,
  msgs,
  team_id,
  conversation
) => {
  const conversationWithTeamId = conversation.set('teamId', team_id);

  // Sort the messages in ascending order
  msgs.sort(messageComparator);

  // Select the newest item in the list (the last item)
  const conversationMostRecentUpdated = conversationWithTeamId.set(
    'mostRecentMessageId',
    msgs[msgs.length - 1]
  );
  const conversationUnseenMessagesUpdated = conversationMostRecentUpdated.update(
    'unseenMessageIds',
    (unseenMessages?: number[]) => {
      if (!unseenMessages) return map(msgs, 'id');
      return map(msgs, 'id');
    }
  );
  const conversationUpdated = conversationUnseenMessagesUpdated.update(
    'messageIds',
    (messages?: string[]) => {
      if (!messages) return map(msgs, 'id');
      return uniq(messages.concat(map(msgs, 'id')));
    }
  );
  return state.set(conversation.id, conversationUpdated);
};

type UpdateMostRecentAndUnseenMessages = (
  state: State,
  payload: { message?: Message; conversation?: ConversationRecord }
) => State;
const updateMostRecentAndUnseenMessages: UpdateMostRecentAndUnseenMessages = (
  state,
  { message, conversation }
) => {
  if (!message || !conversation) return state;

  const mostRecentMessage = conversation.mostRecentMessageId ||
    (conversation.unseenMessageIds?.length ? conversation?.unseenMessageIds[0] : undefined);

  const conversationMostRecentUpdated =
    mostRecentMessage && mostRecentMessage === message.id
      ? conversation.set('mostRecentMessageId', message.id)
      : conversation;

  const conversationUnseenMessagedUpdated = conversationMostRecentUpdated.update(
    'unseenMessageIds',
    (unseenMessages?: string[]) => {
      if (!unseenMessages) return [];

      return unseenMessages.filter(
        unseenMessage => unseenMessage !== message.id
      );
    }
  );

  return state.set(conversation.id, conversationUnseenMessagedUpdated);
};

const sortDescUnseenMessagesToAsc = (descUnseenIds?: string[] | null) => {
  if (!descUnseenIds) return undefined;

  return descUnseenIds.slice().reverse();
};

const filterOldUnseenIds = (unseenIds?: string[]) => (
  conversation: ConversationRecord
) => {
  if (!unseenIds || !conversation.messageIds) return undefined;

  if (!conversation.messageIds.length) return unseenIds;

  const lastMsgId = conversation.messageIds[conversation.messageIds.length - 1];

  return unseenIds.filter(unseenId => unseenId > lastMsgId);
};

const addNewUnseenIds = (conversation: ConversationRecord) => (newUnseenIds?: string[]) => {
  if (!newUnseenIds) return conversation;

  return conversation.update('messageIds', (messageIds?: string[]) => messageIds?.concat(newUnseenIds));
};

type AddUnseenMessagesToList = (
  state: State,
  payload: TodoResponse
) => State;
export const addUnseenMessagesToList: AddUnseenMessagesToList = (
  state: State,
  { result }
) => {
  if (!result || !result.conversations.length) return state;

  return state.withMutations(s => {
    result.conversations
      .filter(id => state.get(id)).forEach(conversationId => s.update(conversationId, conversationInState => {
        const sortedUnseenIds = sortDescUnseenMessagesToAsc(
          conversationInState.unseenMessageIds
        );

        return conversationInState.withMutations(conversation => {
          const fn = R.compose(
            addNewUnseenIds(conversation),
            filterOldUnseenIds(sortedUnseenIds)
          );

          return fn(conversation);
        });
      }));
  });
};

type UpdateScheduledAndMessageList = (
  state: State,
  payload: { message?: Message; conversation?: ConversationRecord }
) => State;

const updateScheduledAndMessageList: UpdateScheduledAndMessageList = (
  state,
  { message, conversation }
) => {
  if (!message || !conversation) return state;

  const conversationWithScheduledMessageUpdate = conversation.update(
    'scheduledMessageIds',
    (scheduledMessageIds?: string[] | null) => {
      if (!scheduledMessageIds) return [];

      return scheduledMessageIds.filter(
        scheduledMessageId => scheduledMessageId !== message.id
      );
    }
  );

  const conversationWithMessageListUpdated = conversationWithScheduledMessageUpdate.update(
    'messageIds',
    (messages: string[]) => {
      if (!messages) return [message.id];

      if (messages.includes(message.id)) {
        return messages;
      }

      return messages.concat([message.id]);
    }
  );

  const conversationMostRecentMessageUpdated = conversationWithMessageListUpdated.set(
    'mostRecentMessageId',
    message.id
  );

  return mergeUpdate(
    state,
    createSingleStringEntityMap(
      createConversationRecord,
      conversationMostRecentMessageUpdated
    )
  );
};

export const initialState: State = Map();

export const reducer = (state = initialState, action: RootAction) => {
  switch (action.type) {
    case ENTITIES_FETCHED:
      return action.payload.entities.conversations
        ? mergeUpdate(
          state,
          createStringEntityMap(
            createConversationRecord,
            action.payload.entities.conversations
          )
        )
        : state;
    case UPDATE_CONVERSATION_MESSAGES:
      return action.payload.messages && action.payload.conversation
        ? mergeUpdate(
          state,
          createSingleStringEntityMap(
            createConversationRecord,
            action.payload.conversation
              .update('messageIds', (messages?: number[]) => {
                const reversedMessages = action.payload.messages.reverse();

                if (action.payload.page === 0 || !messages) {
                  return reversedMessages;
                }
                return [...reversedMessages, ...messages];
              })
              .set('totalPages', action.payload.pages)
              .set('currentPage', action.payload.page)
          )
        )
        : state;
    case UPDATE_CONVERSATION_SCHEDULED_MESSAGES:
      return action.payload.scheduled_messages && action.payload.conversation
        ? mergeUpdate(
          state,
          createSingleStringEntityMap(
            createConversationRecord,
            action.payload.conversation.update(
              'scheduledMessageIds',
              (messages?: number[]) => action.payload.scheduled_messages
            )
          )
        )
        : state;
    case DELETE_SCHEDULED_MESSAGE:
      return action.payload.messageId && action.payload.conversation
        ? mergeUpdate(
          state,
          createSingleStringEntityMap(
            createConversationRecord,
            action.payload.conversation.update(
              'scheduledMessageIds',
              (messages?: string[]) => {
                if (!messages) return [];
                return messages.filter(id => id !== action.payload.messageId);
              }
            )
          )
        )
        : state;
    case POST_MESSAGE:
      return postMessage(
        state,
        action.payload.message,
        action.payload.conversation
      );
    case RECEIVE_UNSEEN_MESSAGE:
      return receiveUnseenMessage(
        state,
        action.payload.message,
        action.payload.team_id,
        action.payload.conversation
      );
    case ADD_UNSEEN_MESSAGES:
      let tempState = state;
      action.payload.result.conversations.forEach((conversationId) => {
        if (tempState.get(conversationId)) {
          tempState = receiveUnseenMessages(
            tempState,
            ((action.payload.entities.conversations[conversationId]).unseenMessageIds as string[])
              .map((id) => messageParser({
                message: { ...action.payload.entities.messages[Number(id)], direction: MessageDirection.In }
              }).entities.messages[Number(id)]),
            action.payload.entities.conversations[conversationId].teamId as number,
            tempState.get(conversationId)
          );
        }
      });
      return tempState;
    case RECEIVE_MESSAGE_STATUS:
      return updateScheduledAndMessageList(state, action.payload);
    case PUT_MESSAGE:
      return updateMostRecentAndUnseenMessages(state, action.payload);
    case ADD_UNSEEN_MESSAGES_TO_LIST:
      return addUnseenMessagesToList(state, action.payload);
    default:
      return state;
  }
};
