import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const AddGoalSpan = styled.span`
  font-size: 1rem;
  float: right;
  cursor: pointer;
`;

interface ToggleGoalAddButtonProps {
  isAddFormDisplayed: boolean;
  onOpen(): void;
  onClose(): void;
}

const ToggleGoalAddButton: React.FunctionComponent<ToggleGoalAddButtonProps> = props => (
  <AddGoalSpan>
    {props.isAddFormDisplayed
      ?
      <span onClick={props.onClose}>
        <Icon name="hide" link />
        HIDE ADD GOAL
      </span>
      : <span onClick={props.onOpen}>
        <Icon name="plus" link />
        NEW GOAL
      </span>
    }
  </AddGoalSpan>
);

export default ToggleGoalAddButton;
