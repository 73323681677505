import * as React from 'react';

import { Location } from 'history';
import styled from 'styled-components';
import { ERR_USER_ID_INVALID } from '../../errors';
import UserNavBar from '../../components/nav/UserNavBar';
import UserProfileBannerContainer from '../user/phx/UserProfileBannerContainer';
import PathwayStatsMenu from './PathwayStatsMenu';
import EngagementStatsSegment from './EngagementStatsSegment';
import PathwayLinksSegment from './PathwayLinksSegment';

const Content = styled.div`
  margin-bottom: 2rem;
`;

export interface UserEngagementContainerProps {
  location?: Location;
  id?: number;
}

const UserEngagementContainer: React.FunctionComponent<UserEngagementContainerProps> = ({
  location,
  id
}) => {
  if (id === undefined) throw new Error(ERR_USER_ID_INVALID);
  return (
    <Content>
      <div style={{ width: '95%', margin: 'auto' }}>
        <UserProfileBannerContainer id={id} />
        <UserNavBar location={location} id={id} />

        <PathwayStatsMenu
          pathname={location ? location.pathname : ''}
          userId={id}
        />
        <EngagementStatsSegment userId={id} />
        <PathwayLinksSegment userId={id} />
      </div>
    </Content>
  );
};

export default UserEngagementContainer;
