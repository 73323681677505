import * as React from 'react';
import { Text } from 'grommet';

const TableHeader: React.FC<{ title: string}> = ({ title }) => <Text style={{
  fontSize: '15px',
  fontFamily: 'Lato',
  color: 'rgba(0, 0, 0, 0.87)',
  fontWeight: 'bold'
}}>{title}</Text>;

export default TableHeader;
