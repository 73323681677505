import { gql } from '@apollo/client';

export const CREATE_CARE_PLAN = gql`
  mutation CreateCarePlan(
    $userId: ID!
    $pathwayId: String!
    $adminId: String!
    $title: String!
    $locale: String!
    $program: String!
    $indication: String!
    $status: String!
    $icd10: [String!]!
    $expectedDischargeAt: String!
    $dischargeReason: String
    $prescription: String!
    $openedAt: String!
    $closedAt: String
  ) {
    createCarePlan(
      userId: $userId
      pathwayId: $pathwayId
      adminId: $adminId
      title: $title
      locale: $locale
      program: $program
      indication: $indication
      status: $status
      icd10: $icd10
      expectedDischargeAt: $expectedDischargeAt
      dischargeReason: $dischargeReason
      prescription: $prescription
      openedAt: $openedAt
      closedAt: $closedAt
    ) {
      id
      title
      status
      icd10 {
        code
        description
      }
      expectedDischargeAt
      dischargeReason
      openedAt
      closedAt
      items {
        id
        key
        currentValue
      }
    }
  }
`;

export const UPDATE_CARE_PLAN = gql`
  mutation UpdateCarePlan(
    $carePlanId: ID!, 
    $userId: String!, 
    $expectedDischargeAt: String, 
    $closedAt: String, 
    $dischargeReason: String
  ) {
    updateCarePlan(
      carePlanId: $carePlanId
      userId: $userId
      expectedDischargeAt: $expectedDischargeAt
      closedAt: $closedAt
      dischargeReason: $dischargeReason
    ) {
      id
      userId
      adminId
      title
      status
      icd10 {
        code
        description
      }
      expectedDischargeAt
      openedAt
      closedAt
      dischargeReason
      items {
        id
        key
        currentValue
      }
    }
  }
`;

export const CREATE_CARE_PLAN_ITEM = gql`
  mutation CreateCarePlanItem($carePlanId: ID!, $userId: String!, $adminId: String!, $type: String!) {
    createCarePlanItem(carePlanId: $carePlanId, userId: $userId, adminId: $adminId, type: $type) {
      id
      userId
      adminId
      title
      status
      icd10 {
        code
        description
      }
      expectedDischargeAt
      openedAt
      closedAt
      items {
        id
        key
        currentValue
      }
    }
  }
`;

export const UPDATE_CARE_PLAN_ITEM = gql`
  mutation UpdateCarePlanItem($carePlanId: ID!, $userId: String!, $id: ID!, $status: String!) {
    updateCarePlanItem(carePlanId: $carePlanId, userId: $userId, id: $id, status: $status) {
      id
      userId
      adminId
      title
      status
      icd10 {
        code
        description
      }
      expectedDischargeAt
      openedAt
      closedAt
      items {
        id
        key
        currentValue
      }
    }
  }
`;
