import { Endpoint, EndpointConfig } from './Endpoint';
import * as P from './parsers';
import * as qs from 'query-string';
import {
  TaskStatuses,
  Achievement,
  MassMessage,
  MassMessageTemplate,
  SendMassMessageUser,
  Note,
  NoteType,
  NoteStatus,
  Admin,
  Coverages,
  MessageV2,
  GetUserById
} from './interfaces';
import { ConversationKind } from '../utils/conversations/helpers';
import { VideoCall } from '../../src/graphql/models/VideoCall';
import { EnrollmentStatus, Status } from '../../src/graphql/models/bff';

export enum RequestStatus {
  PENDING = 'pending',
  ERROR = 'error',
  SUCCESS = 'success'
}

export const bffPath = '/graphql';
// Login
export type LoginEndpoint = Endpoint<undefined, { data: Admin }>;
export const login: EndpointConfig<undefined, { data: Admin }> = {
  authenticated: false,
  method: 'POST',
  parse: res => res,
  uriPath: '/auth/password-login',
  bff: true
};

// Validate
export type BffValidateEndpoint = Endpoint<undefined, {}>;
export const bffValidate: EndpointConfig<undefined, {}> = {
  authenticated: false,
  method: 'GET',
  parse: P.identParser,
  uriPath: '/auth/validate',
  bff: true
};

// Refresh
export type RefreshEndpoint = Endpoint<undefined, {}>;
export const refresh: EndpointConfig<undefined, {}> = {
  authenticated: false,
  method: 'POST',
  parse: res => res,
  uriPath: '/auth/refresh',
  bff: true
};

// Logout
export type LogoutEndpoint = Endpoint<undefined, {}>;
export const logout: EndpointConfig<undefined, {}> = {
  authenticated: false,
  method: 'GET',
  parse: P.identParser,
  uriPath: '/auth/logout',
  bff: true
};

// Get Admin
export interface AdminURIParams {
  id: number;
}
export type GetAdminEndpoint = Endpoint<AdminURIParams, P.AdminResponse>;
export const getAdmin: EndpointConfig<AdminURIParams, P.AdminResponse> = {
  method: 'POST',
  parse: res => res,
  bff: true,
  uriPath: bffPath,
  isQuery: true
};

// List Tasks
export interface ListTaskParams {
  status?: TaskStatuses;
  userId?: number | number[];
  itemsPerPage?: number;
}

export type ListTasksEndpoint = Endpoint<ListTaskParams, P.TasksResponse>;
export const listTasks: EndpointConfig<ListTaskParams, P.TasksResponse> = {
  method: 'POST',
  parse: P.tasksParser,
  bff: true,
  uriPath: bffPath,
  isQuery: true
};

// complete tasks
export type CompleteTaskEndpoint = Endpoint<{ id: number }, {}>;
export const completeTask: EndpointConfig<{ id: number }, {}> = {
  method: 'PATCH',
  parse: P.identParser,
  uriTemplate: ({ id }) => `/api/v3/tasks/${id}/complete`
};

// complete outreach tasks
export type CompleteOutreachTaskEndpoint = Endpoint<{ id: number }, {}>;
export const completeOutreachTask: EndpointConfig<{ id: number }, {}> = {
  method: 'PATCH',
  parse: P.identParser,
  uriTemplate: ({ id }) => `/api/v3/tasks/${id}/update_outreach`
};

// tasks by count
export type TasksByCountEndpoint = Endpoint<{}, { taskCounts: { [key: string]: number } }>;
export const tasksByCount: EndpointConfig<{}, { taskCounts: { [key: string]: number } }> = {
  method: 'GET',
  parse: P.taskCountParser,
  uriPath: '/api/v3/tasks/counts'
};

// users and messages by task kind
export type TaskUsersEndpoint = Endpoint<{ kind: string }, P.TodoResponse>;
export const getTaskUsers: EndpointConfig<{ kind: string }, P.TodoResponse> = {
  method: 'POST',
  parse: P.todoUsersParser,
  bff: true,
  uriPath: bffPath,
  isQuery: true
};

// Get Goals
export type GetGoalsEndpoint = Endpoint<{ userId: number }, P.GoalsResponse>;
export const getGoals: EndpointConfig<{ userId: number }, P.GoalsResponse> = {
  method: 'GET',
  parse: P.listGoalsParser,
  uriTemplate: ({ userId }) => `/api/v3/users/${userId}/goals`
};

// Post Goal
export type PostGoalEndpoint = Endpoint<{ userId: number }, P.GoalsResponse>;
export const postGoal: EndpointConfig<{ userId: number }, P.GoalsResponse> = {
  method: 'POST',
  parse: P.postGoalParser,
  bff: true,
  uriPath: bffPath,
  isMutation: true
};

// Update Goal
export type UpdateGoalEndpoint = Endpoint<{ userId: number; goalId: string }, P.GoalsResponse>;
export const updateGoal: EndpointConfig<{ userId: number; goalId: string }, P.GoalsResponse> = {
  method: 'POST',
  parse: P.updateGoalParser,
  bff: true,
  uriPath: bffPath,
  isMutation: true
};

// Delete Goal
export type DeleteGoalEndpoint = Endpoint<{ userId: number; goalId: string }, {}>;
export const deleteGoal: EndpointConfig<{ userId: number; goalId: string }, {}> = {
  method: 'POST',
  parse: P.identParser,
  bff: true,
  uriPath: bffPath,
  isMutation: true
};

// Get User
export type GetUserEndpoint = Endpoint<{ id: number }, P.UserResponse>;
export const getUser: EndpointConfig<{ id: number }, P.UserResponse> = {
  parse: P.userParser,
  uriTemplate: ({ id }) => `/api/v3/users/${id}`
};

// Put User
export type UpdateUserEndpoint = Endpoint<{ id: number }, {}>;
export const updateUser: EndpointConfig<{ id: number }, {}> = {
  method: 'POST',
  parse: P.identParser,
  bff: true,
  uriPath: bffPath,
  isMutation: true
};

// Get User List
export interface GetUsersParams {
  tags?: string[];
  page?: number;
  items_per_page?: number;
}
export type GetUsersEndpoint = Endpoint<GetUsersParams, P.UserResponse>;
export const getUsers: EndpointConfig<GetUsersParams, P.UserResponse> = {
  method: 'POST',
  parse: P.getUsersParser,
  bff: true,
  uriPath: bffPath,
  isQuery: true
};

// Get Conversation users by teamId
export interface GetUsersByTeamParams {
  teamId: number;
  adminUuid: string;
  itemsPerPage?: number; // Defaults to 1
  page?: number; // Defaults to 0
}
export type GetUsersByTeamEndpoint = Endpoint<GetUsersByTeamParams, P.GetUsersByTeamResponse>;
export const getUsersByTeam: EndpointConfig<GetUsersByTeamParams, P.GetUsersByTeamResponse> = {
  method: 'POST',
  parse: res => res.data.team,
  bff: true,
  isQuery: true,
  uriPath: bffPath
};

export interface UpdateUserEnrollmentStatusParams {
  userUuid: string;
  status: Status;
}
export type UpdateUserEnrollmentStatus = Endpoint<UpdateUserEnrollmentStatusParams, { createStatus: EnrollmentStatus }>;
export const updateUserEnrollmentStatus: EndpointConfig<
UpdateUserEnrollmentStatusParams,
{ createStatus: EnrollmentStatus }
> = {
  method: 'POST',
  parse: res => res.data,
  bff: true,
  isMutation: true,
  uriPath: bffPath
};

export interface GetUsersEnrollmentStatusParams {
  uuids: number[];
}
export type GetUsersEnrollmentStatus = Endpoint<GetUsersEnrollmentStatusParams, P.EnrollmentStatusResponse>;
export const getUsersEnrollmentStatus: EndpointConfig<GetUsersEnrollmentStatusParams, P.EnrollmentStatusResponse> = {
  method: 'POST',
  parse: P.getUsersEnrollmentStatusParser,
  bff: true,
  uriPath: bffPath,
  isQuery: true
};

// Get User Pathway Stats
export interface GetUserPathwayStatsParams {
  end?: Date;
  id: number;
  start?: Date;
}
export type GetUserPathwayStatsEndpoint = Endpoint<GetUserPathwayStatsParams, P.PathwayStatsResponse>;
export const getUserPathwayStats: EndpointConfig<GetUserPathwayStatsParams, P.PathwayStatsResponse> = {
  method: 'GET',
  parse: P.pathwayStatsParser,
  uriTemplate: ({ end, id, start }) => {
    const q = qs.stringify({
      end: end && end.toISOString(),
      start: start && start.toISOString()
    });
    return `/api/v3/users/${id}/pathway_stats${q.length > 0 ? '?' + q : ''}`;
  }
};

// Get User Coverages
export interface GetUserCoveragesResponse {
  getUserCoverages:
  | {
    coverages: Coverages;
  }
  | false;
}

/** Team Members */
// Get team members
export interface GetTeamListParams {
  page?: number;
  items_per_page?: number;
}
export type GetTeamsEndpoint = Endpoint<GetTeamListParams, P.TeamResponse>;
export const getTeams: EndpointConfig<GetTeamListParams, P.TeamResponse> = {
  method: 'POST',
  parse: P.getTeamsParser,
  bff: true,
  uriPath: bffPath,
  isQuery: true
};

// Add user tag
export interface UserTagParams {
  userId: number;
  tag?: string;
}
export type AddUserTag = Endpoint<UserTagParams, {}>;
export const addUserTag: EndpointConfig<UserTagParams, {}> = {
  method: 'POST',
  parse: P.identParser,
  bff: true,
  uriPath: bffPath,
  isMutation: true
};

export type DeleteUserTag = Endpoint<UserTagParams, {}>;
export const deleteUserTag: EndpointConfig<UserTagParams, {}> = {
  method: 'POST',
  parse: P.identParser,
  bff: true,
  uriPath: bffPath,
  isMutation: true
};

// Get conversations from a list of user ids
export interface ConversationsListParams {
  userIds?: number[];
  userUuids?: string[];
  teamIds?: number[];
  page?: number;
  itemsPerPage?: number;
  teamId?: number;
  types?: ConversationKind[];
  callNewEndpoint?: boolean;
}
export type GetConversationsEndpoint = Endpoint<ConversationsListParams, P.ConversationsResponse>;
export const getConversations: EndpointConfig<ConversationsListParams, P.ConversationsResponse> = {
  method: 'POST',
  parse: P.conversationsParser,
  bff: true,
  uriPath: bffPath,
  isQuery: true
};

export const getConversationsNew: EndpointConfig<ConversationsListParams, P.ConversationsResponse> = {
  method: 'POST',
  parse: P.conversationsParser,
  bff: false,
  nestBff: true,
  uriPath: bffPath,
  isQuery: true
};

export type GetUnseenMessagesEndpoint = Endpoint<ConversationsListParams, P.TodoResponse>;

export const getUnseenMessages: EndpointConfig<ConversationsListParams, P.TodoResponse> = {
  method: 'POST',
  parse: P.unseenMessageParser,
  bff: true,
  uriPath: bffPath,
  isQuery: true
};

/** messages */
export interface MessagesParams {
  page?: number;
  id?: number;
  items_per_page?: number;
  conversationId?: string;
}

export interface GetMessageParams {
  conversationId: string;
  // Optional - new types for GraphQL endpoint
  userId?: string;
  coachId?: string;
  // Optional - sets to default in the BFF
  page?: number;
  itemsPerPage?: number;
}

export interface GetScheduledMessagesParams extends GetMessageParams {
  scheduled?: boolean;
}

export interface MessagesV2Params {
  userId: number;
  itemsPerPage: number;
  page: number;
  adminUuid: string;
}

export interface UpdateMessageV2Params {
  uuid: string;
  body?: string;
  sendAt?: string | null;
}

export interface CreateMessageV2Params {
  userId: number;
  senderUuid: string;
  recipientUuid: string;
  recipientNumber: string;
  body: string;
  sendAt?: string | null;
}

export type UpdatedMessageResponse = Pick<MessageV2, 'uuid' | 'body' | 'sendAt'>;

export interface MessageURIParams {
  id: number | string;
  conversationId: string;
}

export type GetMessagesEndpoint = Endpoint<GetMessageParams, P.MessagesResponse>;
export const getMessages: EndpointConfig<GetMessageParams, P.MessagesResponse> = {
  method: 'POST',
  parse: res => P.messagesParser(res.data.getMessages),
  bff: true,
  uriPath: bffPath,
  isQuery: true
};

export const getMessagesNew: EndpointConfig<GetMessageParams, P.MessagesResponse> = {
  method: 'POST',
  parse: res => P.messagesParser(res.data.getMessages),
  bff: false,
  nestBff: true,
  uriPath: bffPath,
  isQuery: true
};

export type GetScheduledMessagesEndpoint = Endpoint<GetScheduledMessagesParams, P.MessagesResponse>;
export const getScheduledMessages: EndpointConfig<GetScheduledMessagesParams, P.MessagesResponse> = {
  method: 'POST',
  parse: res => P.messagesParser(res.data.getScheduledMessages),
  bff: true,
  uriPath: bffPath,
  isQuery: true
};

export const getScheduledMessagesNew: EndpointConfig<GetScheduledMessagesParams, P.MessagesResponse> = {
  method: 'POST',
  parse: res => P.messagesParser(res.data.getScheduledMessages),
  bff: false,
  nestBff: true,
  uriPath: bffPath,
  isQuery: true
};

// TODO: BAT-1312 Wire up with GQL query
export type GetMessagesEndpointV2 = Endpoint<MessagesV2Params, GetUserById>;
export const getMessagesV2: EndpointConfig<MessagesV2Params, GetUserById> = {
  method: 'POST',
  parse: res => res.data.getUserById,
  bff: true,
  uriPath: bffPath,
  isQuery: true
};

// TODO: BAT-1315 Wire up with GQL mutation
export type UpdateMessagesEndpointV2 = Endpoint<UpdateMessageV2Params, { messageData: UpdatedMessageResponse }>;
export const updateMessageV2: EndpointConfig<UpdateMessageV2Params, { messageData: UpdatedMessageResponse }> = {
  method: 'POST',
  parse: res => res,
  bff: true,
  uriPath: bffPath,
  isMutation: true
};

export type CreateMessageEndpointV2 = Endpoint<CreateMessageV2Params, MessageV2>;
export const createMessageV2: EndpointConfig<CreateMessageV2Params, MessageV2> = {
  method: 'POST',
  parse: res => res.data.createMessage,
  bff: true,
  uriPath: bffPath,
  isMutation: true
};

export type UpdateMessageEndpoint = Endpoint<MessageURIParams, P.MessageResponse>;
export const updateMessage: EndpointConfig<MessageURIParams, P.MessageResponse> = {
  method: 'POST',
  parse: P.putMessageParser,
  bff: true,
  uriPath: bffPath,
  isMutation: true
};

export const updateMessageNew: EndpointConfig<MessageURIParams, P.MessageResponse> = {
  method: 'POST',
  parse: P.putMessageParser,
  bff: false,
  nestBff: true,
  uriPath: bffPath,
  isMutation: true
};

export type MarkAsReadEndpoint = Endpoint<MessageURIParams, P.MessageResponse>;
export const markAsRead: EndpointConfig<MessageURIParams, P.MessageResponse> = {
  method: 'POST',
  parse: P.markAsReadParser,
  bff: true,
  uriPath: bffPath,
  isMutation: true
};

export const markAsReadNew: EndpointConfig<MessageURIParams, P.MessageResponse> = {
  method: 'POST',
  parse: P.markAsReadParser,
  bff: false,
  nestBff: true,
  uriPath: bffPath,
  isMutation: true
};

export type PostMessageEndpoint = Endpoint<MessagesParams, P.MessageResponse>;
export const postMessage: EndpointConfig<MessagesParams, P.MessageResponse> = {
  method: 'POST',
  parse: P.postMessageParser,
  bff: true,
  uriPath: bffPath,
  isMutation: true
};

export const postMessageNew: EndpointConfig<MessagesParams, P.MessageResponse> = {
  method: 'POST',
  parse: P.postMessageParser,
  bff: false,
  nestBff: true,
  uriPath: bffPath,
  isMutation: true
};

export interface DeleteMessageUriParams {
  messageId: number;
  conversationId: string;
}
export type DeleteMessageEndpoint = Endpoint<DeleteMessageUriParams, {}>;
export const deleteMessage: EndpointConfig<DeleteMessageUriParams, {}> = {
  method: 'POST',
  parse: P.deleteParser,
  bff: true,
  uriPath: bffPath,
  isMutation: true
};

export const deleteMessageNew: EndpointConfig<DeleteMessageUriParams, {}> = {
  method: 'POST',
  parse: P.deleteParser,
  bff: false,
  nestBff: true,
  uriPath: bffPath,
  isMutation: true
};

// TODO BAT-1373 Flip this over to the BFF
export type DeleteMessageEndpointV2 = Endpoint<{ uuid: string }, {}>;
export const deleteMessageV2: EndpointConfig<{ uuid: string }, {}> = {
  method: 'POST',
  parse: response => response,
  bff: true,
  uriPath: bffPath,
  isMutation: true
};

// Get User Achievements
export interface GetUserAchievementsParams {
  userId: number;
}
export interface GetUserAchievementsResponse {
  userAchievements: Achievement[];
}
export type GetUserAchievementsEndpoint = Endpoint<GetUserAchievementsParams, GetUserAchievementsResponse>;
export const getUserAchievements: EndpointConfig<GetUserAchievementsParams, GetUserAchievementsResponse> = {
  method: 'POST',
  parse: res => res.data,
  bff: true,
  uriPath: bffPath,
  isQuery: true
};

export interface MassMessageTemplateResponse {
  data: {
    massMessagingTemplates: MassMessage[];
  };
}

// Get Mass Message Templates
export type GetMassMessageTemplatesEndpoint = Endpoint<{}, MassMessageTemplateResponse>;
export const getMassMessageTemplates: EndpointConfig<{}, MassMessageTemplateResponse> = {
  method: 'POST',
  parse: res => res,
  uriPath: bffPath,
  bff: true,
  isQuery: true
};

// Send Mass Messages
export interface MassMessageTemplateParams {
  template: MassMessageTemplate;
  users: SendMassMessageUser[];
}
export type SendMassMessagesEndpoint = Endpoint<MassMessageTemplateParams, {}>;
export const sendMassMessages: EndpointConfig<MassMessageTemplateParams, {}> = {
  method: 'POST',
  parse: res => res,
  uriPath: bffPath,
  bff: true,
  isMutation: true
};

// Create Video Call
export interface CreateVideoCallParams {
  adminUuid: string;
  userId: number;
  identifier: string;
  basiliskPathwayId: number;
  scheduledAt: string;
}

interface ErrorResponse {
  error: {
    statusCode: number;
    message: string;
  };
}

export interface CreateVideoCallResponse {
  createVideoCall: VideoCall | ErrorResponse;
}

export type CreateVideoCallEndpoint = Endpoint<CreateVideoCallParams, CreateVideoCallResponse>;
export const createVideoCall: EndpointConfig<CreateVideoCallParams, CreateVideoCallResponse> = {
  method: 'POST',
  parse: res => res.data,
  uriPath: bffPath,
  bff: true,
  isMutation: true
};

// Create JWT Token
export interface CreateJwtMutationVariables {
  uuid: string;
  identifier: string;
}

interface Token {
  token: string;
}

export interface CreateJwtResponse {
  createJwt: Token | ErrorResponse;
}

export type CreateJwtEndpoint = Endpoint<CreateJwtMutationVariables, CreateJwtResponse>;
export const createJwt: EndpointConfig<CreateJwtMutationVariables, CreateJwtResponse> = {
  method: 'POST',
  parse: res => res.data,
  uriPath: bffPath,
  bff: true,
  isMutation: true
};

// Get Video Calls
interface GetVideoCallsVariables {
  userUuid: string;
}

export interface GetVideoCallsResponse {
  getVideoCalls: VideoCall[] | ErrorResponse;
}

export type GetVideoCallsEndpoint = Endpoint<GetVideoCallsVariables, GetVideoCallsResponse>;
export const getVideoCalls: EndpointConfig<GetVideoCallsVariables, GetVideoCallsResponse> = {
  method: 'POST',
  parse: res => res.data,
  uriPath: bffPath,
  bff: true,
  isQuery: true
};

// Create note
export interface CreateNoteParams {
  adminUuid: string;
  userUuid: string;
}

export interface CreateNoteResponse {
  createNote: Note | false;
}

export type CreateNoteEndpoint = Endpoint<CreateNoteParams, CreateNoteResponse>;
export const createNote: EndpointConfig<CreateNoteParams, CreateNoteResponse> = {
  method: 'POST',
  parse: res => res.data,
  uriPath: bffPath,
  bff: true,
  isMutation: true
};

// Get notes
export interface GetNotesParams {
  userUuid?: string | null;
  page: number;
  itemsPerPage: number;
  userId: number;
}

export interface GetNotesResponse {
  getNotes:
  | {
    data: Note[];
  }
  | false;
}

export type GetNotesEndpoint = Endpoint<GetNotesParams, GetNotesResponse>;
export const getNotes: EndpointConfig<GetNotesParams, GetNotesResponse> = {
  method: 'POST',
  parse: res => res.data,
  uriPath: bffPath,
  bff: true,
  isQuery: true
};

// Update note
export interface UpdateNoteParams {
  id: number;
  author: {
    name: string;
    uuid: string;
  };
  userUuid?: string | null;
  body?: string;
  noteType?: NoteType | string;
  status?: NoteStatus;
}

export interface UpdateNoteResponse {
  updateNote: Note | false;
}

export type UpdateNoteEndpoint = Endpoint<UpdateNoteParams, UpdateNoteResponse>;
export const updateNote: EndpointConfig<UpdateNoteParams, UpdateNoteResponse> = {
  method: 'POST',
  parse: res => res.data,
  uriPath: bffPath,
  bff: true,
  isMutation: true
};

// Delete note
export interface DeleteNoteParams {
  id: number;
}

export interface DeleteNoteResponse {
  deleteNote: {};
}

export type DeleteNoteEndpoint = Endpoint<DeleteNoteParams, DeleteNoteResponse>;
export const deleteNote: EndpointConfig<DeleteNoteParams, DeleteNoteResponse> = {
  method: 'POST',
  parse: res => res.data,
  uriPath: bffPath,
  bff: true,
  isMutation: true
};

// Export Note PDF
export interface ExportNoteParams {
  id: number;
}

export interface ExportNoteResponse {
  exportNote: {
    presignedUrl: string; // TODO: BAT-1139
  };
}

export type ExportNoteEndpoint = Endpoint<ExportNoteParams, ExportNoteResponse>;
export const exportNote: EndpointConfig<ExportNoteParams, ExportNoteResponse> = {
  method: 'POST',
  parse: res => res.data,
  uriPath: bffPath,
  bff: true,
  isQuery: true
};
