import * as React from 'react';
import './TeamList.scss';
import { Team } from '../../graphql/models/Team';
import InfiniteScroller from '../ui/InfiniteScroller';
import TeamListItem from './TeamListItem';


interface Props {
  loadMore(): void;
  currentPage: number;
  isLoading: boolean;
  teams: Team[];
  selectedTeam?: Team;
  selectedTeamId?: string;
  parentNode: React.RefObject<HTMLDivElement>;
  setSelectedTeam(team?: Team): void;
  setSelectedTeamId(teamId?: string): void;
  setSelectedTodo(task?: string): void;
}

const TeamsList: React.FC<Props> = ({
  teams,
  parentNode,
  setSelectedTodo,
  setSelectedTeam,
  setSelectedTeamId,
  selectedTeamId,
  selectedTeam,
  ...props
}) => {
  const [show, setShow] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);
  const firstLoad = React.useRef(true);

  React.useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }
  }, []);

  React.useEffect(() => {
    setShow(true);
  }, []);
  const handleOnClick = React.useCallback((team) => {
    setSelectedTeam(team);
    setSelectedTodo(undefined);
  }, []);
  const showHideTeamList = () => {
    setCollapsed(!collapsed);
  };
  return (
    <div className="team-list">
      <div className="team-list-title" onClick={showHideTeamList}>
        <div>TEAMS</div>
        <div className={collapsed ? 'arrow collapsed' : 'arrow'}></div>
      </div>
      {show && parentNode.current && !collapsed &&
        <InfiniteScroller
          style={{ overflowY: 'initial' }}
          loadMore={props.loadMore}
          currentPage={props.currentPage}
          isLoading={false}
          parentRef={parentNode}
        >
          {teams.map(team => (
            <TeamListItem
              selected={!!selectedTeam && selectedTeamId === team.id}
              setSelectedTeamId={setSelectedTeamId}
              setSelectedTeam={handleOnClick}
              key={team.id}
              team={team}
            />
          ))}
        </InfiniteScroller>
      }
    </div>
  );
};

export default TeamsList;
