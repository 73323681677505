import * as React from 'react';
import { Snackbar } from '@material-ui/core';
import ModalWrapper from '../../components/modal/ModalWrapper';
import UpdateCarePlanModalContent from './UpdateCarePlanModalContent';
import UpdateCarePlanModalConfirm from './UpdateCarePlanModalConfirm';
import { DischargeReasons, UpdateCarePlanFields } from './CarePlansContainer';
import { CarePlan, CarePlanStatus } from '../../api-client/interfaces';
import * as Moment from 'moment';
import { useMutation } from '@apollo/client';
import { ApiClientNames } from '../apollo/ApolloContainer';
import {
  UPDATE_CARE_PLAN,
  CREATE_CARE_PLAN_ITEM,
  UPDATE_CARE_PLAN_ITEM
} from '../../graphql/mutations/CarePlan';

export const SNACKBAR_HIDE_DURATION = 4000;

export interface Props {
  adminUuid: string;
  openUpdateCarePlan: boolean;
  handleCloseUpdateCarePlan(): void;
  handleOpenUpdateCarePlan(): void;
  updateCarePlanFields: UpdateCarePlanFields;
}

export enum CarePlanActionOptions {
  CloseCarePlan = 'Discharge and close plan',
  ChangeExpectedDischargeAt = 'Change expected discharge',
  LogPTVisit = 'Log a PT visit'
}

export enum CarePlanItemActionOptions {
  HCPInformed = 'HCP Informed',
  PrescriptionObtained = 'Prescription obtained',
  InPersonHCPVisitCompleted = 'In-person HCP visit completed'
}

const CarePlanItemKeyMap: { [key: string]: string } = {
  [CarePlanItemActionOptions.HCPInformed]: 'inform_hcp',
  [CarePlanItemActionOptions.PrescriptionObtained]: 'prescription_obtained',
  [CarePlanItemActionOptions.InPersonHCPVisitCompleted]: 'in_person_hcp_visit'
};

export interface DateUpdateProps {
  placeholder: string;
  selectedUpdateDate: Moment.Moment | null;
  handleSetSelectedUpdateDate(date: Moment.Moment | null): void;
  caption?: string;
}

export interface DischargeReasonProps {
  selectedDischargeReason: DischargeReasons | null;
  handleSetSelectedDischargeReason(event: React.ChangeEvent<{ value: unknown }>): void;
}

export interface CarePlanUpdateConfirmProps<T> {
  title: string;
  name: T;
  description: string;
  dateProps?: DateUpdateProps;
  dischargeReasonProps?: DischargeReasonProps;
}

export interface OptionWrapper {
  type: string;
  key: string;
  text: string;
}

const formatOption = (type: string, name: string): OptionWrapper => (
  {
    type,
    key: name,
    text: name
  }
);

export const generateOptionsList = (status: CarePlanStatus ) => (
  status === CarePlanStatus.Open ? [
    formatOption('item', CarePlanActionOptions.LogPTVisit),
    formatOption('item', CarePlanActionOptions.CloseCarePlan),
    formatOption('item', CarePlanActionOptions.ChangeExpectedDischargeAt),
    formatOption('divider', 'CarePlanDivider'),
    formatOption('subheader', 'Confirm:'),
    formatOption('item', CarePlanItemActionOptions.HCPInformed),
    formatOption('item', CarePlanItemActionOptions.PrescriptionObtained),
    formatOption('item', CarePlanItemActionOptions.InPersonHCPVisitCompleted)
  ] : [
    formatOption('item', CarePlanItemActionOptions.HCPInformed)
  ]
);

const updateTitle = {
  [CarePlanActionOptions.CloseCarePlan]: 'Discharge and close plan',
  [CarePlanActionOptions.ChangeExpectedDischargeAt]: 'Change expected discharge date',
  [CarePlanActionOptions.LogPTVisit]: 'Log a PT visit',
  [CarePlanItemActionOptions.HCPInformed]: 'Confirm HCP has been informed',
  [CarePlanItemActionOptions.PrescriptionObtained]: 'Confirm prescription obtained',
  [CarePlanItemActionOptions.InPersonHCPVisitCompleted]: 'Confirm in-person visit completed'
};

const updateDescription = {
  /* eslint-disable max-len */
  [CarePlanActionOptions.CloseCarePlan]: 'Please indicate the date closed and discharge reason. Once closed, this care plan cannot be reopened.',
  [CarePlanActionOptions.ChangeExpectedDischargeAt]: 'Please indicate the new expected discharge date for this care plan.',
  [CarePlanActionOptions.LogPTVisit]: 'Please confirm that you\'d like to add 1 visit to the total number of PT visits. This action cannot be undone.',
  [CarePlanItemActionOptions.HCPInformed]: 'Please confirm that the HCP has been informed. This action cannot be undone.',
  [CarePlanItemActionOptions.PrescriptionObtained]: 'Please confirm a prescription has been obtained. This action cannot be undone.',
  [CarePlanItemActionOptions.InPersonHCPVisitCompleted]: 'Please confirm that an in-person HCP visit has been completed. This action cannot be undone.'
  /* eslint-enable max-len */
};

export const updateSnackBarMsgs = {
  /* eslint-disable max-len */
  [CarePlanActionOptions.CloseCarePlan]: 'Care plan closed',
  [CarePlanActionOptions.ChangeExpectedDischargeAt]: 'Expected discharge date updated',
  [CarePlanActionOptions.LogPTVisit]: 'PT visit logged',
  [CarePlanItemActionOptions.HCPInformed]: 'Informed HCP confirmed',
  [CarePlanItemActionOptions.PrescriptionObtained]: 'Obtained prescription confirmed',
  [CarePlanItemActionOptions.InPersonHCPVisitCompleted]: 'In-person HCP visit confirmed'
  /* eslint-enable max-len */
};

const dateActionSpecificProps = {
  [CarePlanActionOptions.CloseCarePlan]: {
    caption: 'Date closed',
    placeholder: ''
  },
  [CarePlanActionOptions.ChangeExpectedDischargeAt]: {
    placeholder: 'Expected discharge'
  }
};

interface BaseCarePlanVariables {
  carePlanId: string;
  userId: string;
}

interface UpdateCarePlanItemVariables extends BaseCarePlanVariables {
  id: string;
  status: string;
}

interface UpdateCarePlanVariables extends BaseCarePlanVariables {
  closedAt?: string;
  expectedDischargeAt?: string;
  dischargeReason?: DischargeReasons | null;
}

interface CreateCarePlanItemVariables extends BaseCarePlanVariables {
  adminId: string;
  type: string;
}

interface UpdateCarePlanResponse {
  updateCarePlan: CarePlan | false;
}

interface CreateCarePlanItemResponse {
  createCarePlanItem: CarePlan;
}

interface UpdateCarePlanItemResponse {
  updateCarePlanItem: CarePlan;
}

export enum UpdateCarePlanActionType {
  UpdateCarePlan = 'UpdateCarePlan',
  CreateCarePlanItem = 'CreateCarePlanItem',
  UpdateCarePlanItem = 'UpdateCarePlanItem'
}

function isErrorObj(error: unknown): error is Error {
  // this is needed because one can technically throw an error string
  // instead of an error object
  return error instanceof Error;
}

const UpdateCarePlanContainer: React.FC<Props> = ({
  adminUuid,
  openUpdateCarePlan,
  handleCloseUpdateCarePlan,
  handleOpenUpdateCarePlan,
  updateCarePlanFields
}) => {
  const { id, userId, status, items } = updateCarePlanFields;
  const [openUpdateConfirmModal, setOpenUpdateConfirmModal] = React.useState<boolean>(false);
  const [selectedUpdateAction, setSelectedUpdateAction] = React.useState<
  CarePlanActionOptions | CarePlanItemActionOptions | null>(null);
  const [selectedUpdateDate, setSelectedUpdateDate] = React.useState<Moment.Moment | null>(null);
  const [selectedDischargeReason, setSelectedDischargeReason] = React.useState<DischargeReasons | null>(null);
  const [modalErrorText, setModalErrorText] = React.useState<string | undefined>(undefined);
  const [openSnackbar, setOpenSnackBar] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState('');

  const [updateCarePlan] = useMutation<UpdateCarePlanResponse, UpdateCarePlanVariables>(
    UPDATE_CARE_PLAN,
    { context: { clientName: ApiClientNames.NestBFF } }
  );

  const [createCarePlanItem] = useMutation<CreateCarePlanItemResponse, CreateCarePlanItemVariables>(
    CREATE_CARE_PLAN_ITEM,
    { context: { clientName: ApiClientNames.NestBFF } }
  );

  const [updateCarePlanItem] = useMutation<UpdateCarePlanItemResponse, UpdateCarePlanItemVariables>(
    UPDATE_CARE_PLAN_ITEM,
    { context: { clientName: ApiClientNames.NestBFF } }
  );

  const update = async(
    variables: UpdateCarePlanVariables | CreateCarePlanItemVariables | UpdateCarePlanItemVariables,
    actionType: UpdateCarePlanActionType
  ) => {
    let updateResponse;
    let error;
    let res;

    switch(actionType) {
      case UpdateCarePlanActionType.UpdateCarePlan:
        res = await updateCarePlan({ variables: variables as UpdateCarePlanVariables });
        if (!res.data) {
          error = true;
        }
        updateResponse = res?.data?.updateCarePlan;
        break;
      case UpdateCarePlanActionType.CreateCarePlanItem:
        res = await createCarePlanItem({ variables: variables as CreateCarePlanItemVariables });
        if (!res.data) {
          error = true;
        }
        updateResponse = res?.data?.createCarePlanItem;
        break;
      case UpdateCarePlanActionType.UpdateCarePlanItem:
        res = await updateCarePlanItem({ variables: variables as UpdateCarePlanItemVariables });
        if (!res.data) {
          error = true;
        }
        updateResponse = res?.data?.updateCarePlanItem;
        break;
      default:
    }

    if (error) {
      throw new Error('Error updating care plan');
    }

    return updateResponse;
  };

  const options = generateOptionsList(status);

  const handleOpenSnackbar = () => setOpenSnackBar(true);
  const handleCloseSnackbar = () => {
    setOpenSnackBar(false);
    setSnackBarMsg('');
  };

  const handleUpdateConfirmModalOpen = () => {
    handleCloseUpdateCarePlan();
    setOpenUpdateConfirmModal(true);
  };

  const handleCloseSelectUpdateModal = () => {
    handleCloseUpdateCarePlan();
    setSelectedUpdateAction(null);
    setSelectedDischargeReason(null);
  };

  const handleReopenUpdateCarePlanModal = () => {
    handleOpenUpdateCarePlan();
    setOpenUpdateConfirmModal(false);
    setSelectedUpdateDate(null);
    setSelectedDischargeReason(null);
  };

  const handleSetSelectedDischargeReason =
    React.useCallback((event: React.ChangeEvent<{ value: DischargeReasons }>) => {
      if (event.target.value) {
        setSelectedDischargeReason(event.target.value);
      }
    }, []);

  const handleConfirmAction = async() => {
    selectedUpdateAction && setSnackBarMsg(updateSnackBarMsgs[selectedUpdateAction]);
    if (
      selectedUpdateAction === CarePlanActionOptions.CloseCarePlan &&
      selectedDischargeReason === null
    ) {
      setModalErrorText('Please select a discharge reason');
    } else if (
      (selectedUpdateAction === CarePlanActionOptions.CloseCarePlan ||
      selectedUpdateAction === CarePlanActionOptions.ChangeExpectedDischargeAt) &&
      selectedUpdateDate === null
    ) {
      setModalErrorText('Please select a date');
    } else {
      setModalErrorText(undefined);

      let updateVariables;
      let actionType: UpdateCarePlanActionType | null;

      switch(selectedUpdateAction) {
        case CarePlanActionOptions.CloseCarePlan:
        case CarePlanActionOptions.ChangeExpectedDischargeAt:
          const dateFieldName = selectedUpdateAction === CarePlanActionOptions.CloseCarePlan
            ? 'closedAt'
            : 'expectedDischargeAt';
          updateVariables = {
            [dateFieldName]: selectedUpdateDate?.toISOString(),
            dischargeReason: selectedDischargeReason
          };
          actionType = UpdateCarePlanActionType.UpdateCarePlan;
          break;
        case CarePlanActionOptions.LogPTVisit:
          updateVariables = {
            adminId: adminUuid,
            type: 'video_visit'
          };
          actionType = UpdateCarePlanActionType.CreateCarePlanItem;
          break;
        case CarePlanItemActionOptions.HCPInformed:
        case CarePlanItemActionOptions.PrescriptionObtained:
        case CarePlanItemActionOptions.InPersonHCPVisitCompleted:
          const selectedActionItem = items.find((item) => item.key === CarePlanItemKeyMap[selectedUpdateAction]);

          if (!selectedActionItem) throw new Error('No associated care plan item found');

          updateVariables = {
            id: selectedActionItem.id,
            status: 'complete'
          };

          actionType = UpdateCarePlanActionType.UpdateCarePlanItem;
          break;
        default:
          actionType = null;
          setModalErrorText('Something went wrong, please try again.');
      }

      if (updateVariables && actionType) {
        try {
          await update({ ...updateVariables, carePlanId: id, userId }, actionType);
          closeBothModals();
          handleOpenSnackbar();
        } catch (error) {
          if (isErrorObj(error)) {
            const errMessage = error.message.includes('does not have coverage over user')
              ? 'You do not have coverage of this user'
              : 'Unable to update care plan, please try again';
            setModalErrorText(errMessage);
          } else {
            throw error;
          }
        }
      }
    }
  };

  const closeBothModals = () => {
    handleCloseUpdateCarePlan();
    setOpenUpdateConfirmModal(false);
    setSelectedUpdateAction(null);
    setSelectedUpdateDate(null);
  };

  const createConfirmProps = (actionOption: CarePlanActionOptions | CarePlanItemActionOptions | null):
  CarePlanUpdateConfirmProps<CarePlanActionOptions | CarePlanItemActionOptions> | undefined => {
    if (actionOption === null) return undefined;

    const initialProps = {
      name: actionOption,
      title: updateTitle[actionOption],
      description: updateDescription[actionOption]
    };

    const dateUpdateProps = {
      selectedUpdateDate,
      handleSetSelectedUpdateDate: setSelectedUpdateDate
    };

    const dischargeReasonProps =
      actionOption === CarePlanActionOptions.CloseCarePlan ?
        {
          selectedDischargeReason,
          handleSetSelectedDischargeReason
        }
        : undefined;

    switch (actionOption) {
      case CarePlanActionOptions.CloseCarePlan:
      case CarePlanActionOptions.ChangeExpectedDischargeAt:
        return {
          ...initialProps,
          dateProps: {
            ...dateUpdateProps,
            ...dateActionSpecificProps[actionOption]
          },
          dischargeReasonProps
        };
      case CarePlanActionOptions.LogPTVisit:
      case CarePlanItemActionOptions.HCPInformed:
      case CarePlanItemActionOptions.PrescriptionObtained:
      case CarePlanItemActionOptions.InPersonHCPVisitCompleted:
        return initialProps;
      default: {
        return undefined;
      }
    }
  };

  const selectUpdateModalProps = {
    title: 'Update care plan',
    body: (
      <UpdateCarePlanModalContent
        updateOptions={options}
        onSelectOption={setSelectedUpdateAction}
        selectedOption={selectedUpdateAction}
      />
    ),
    confirmBtnText: 'NEXT',
    dismissBtnText: 'CANCEL'
  };

  const updateConfirmProps = createConfirmProps(selectedUpdateAction);

  const disableUpdateConfirm =
    (updateConfirmProps?.dateProps && !selectedUpdateDate) ||
    (updateConfirmProps?.dischargeReasonProps && !selectedDischargeReason);

  return (
    <>
      <ModalWrapper
        modalContent={selectUpdateModalProps}
        openModal={openUpdateCarePlan}
        onConfirm={handleUpdateConfirmModalOpen}
        onDismiss={handleCloseSelectUpdateModal}
        disableConfirmButton={!selectedUpdateAction}
      />
      {selectedUpdateAction && updateConfirmProps && openUpdateConfirmModal && (
        <ModalWrapper
          modalContent={{
            title: updateConfirmProps.title,
            body: <UpdateCarePlanModalConfirm {...updateConfirmProps} />,
            confirmBtnText: 'CONFIRM',
            dismissBtnText: 'BACK'
          }}
          openModal={openUpdateConfirmModal}
          onConfirm={handleConfirmAction}
          onDismiss={handleReopenUpdateCarePlanModal}
          onBackdropClick={closeBothModals}
          disableConfirmButton={disableUpdateConfirm}
          errorContent={modalErrorText}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={openSnackbar}
        autoHideDuration={SNACKBAR_HIDE_DURATION}
        onClose={handleCloseSnackbar}
        message={snackBarMsg}
      />
    </>
  );
};

export default UpdateCarePlanContainer;
