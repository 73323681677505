import * as React from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Team } from '../../../graphql/models/Team';
import { ApiClientNames } from '../../../containers/apollo/ApolloContainer';
import styled from 'styled-components';
import {
  Badge,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { GREY } from '../../../colors';
import { GET_BULK_USERS, GET_ONBOARDING_USERS_BY_TEAM_ID, GET_TEAM_USERS } from '../../../graphql/queries';
import { actionCreators as userActions } from '../../../redux/actions/user';
import { DispatchThunk } from '../../../redux/actions';
import { RootDispatch } from '../../../redux/actions/interfaces';
import { User } from '../../../graphql/models';
import { getUserUuids, mergeUserData } from '../../../utils/bulkUsers';
import { sortTeamUsersByName } from '../../../utils/helpers';
import OnboardingTeamTable from './OnboardingTeamTable';
import TeamResultsTable from './TeamResultsTable';

const StyledDiv = styled.div`
  color: grey;
  position: absolute;
  left: 50%;
  top: 60%;
`;

const TeamText = styled(Typography)`
  && {
    flex-shrink: 0;
  }
`;

const StyledBadge = styled(Badge)`
  && {
    position: relative;
    top: -10px;
    left: 20px;
    .MuiBadge-badge {
      background-color: ${GREY};
    }
  }
`;

// For ATL-166
// const OnboardedText = styled(Typography)`
//   && {
//     color: grey;
//     margin-left: 16px;
//   }
// `;

export const ErrorMessage = 'Failed to load users.';
export const EmptyTeams = 'No users to display.';
const DEFAULT_TEAM_USERS: User[] = [];

interface TeamAccordionProps {
  onboardingView: boolean;
  teams: Team[];
}

interface TeamUserResponse {
  team: Team;
}

interface BulkUsersResponse {
  getUsersByUuids: User[];
}

interface ExpansionPanelContentProps {
  users: User[];
  loading: boolean;
  render(sortedUsers: User[]): void;
  error?: ApolloError;
}

export const ExpansionPanelContent = ({ users, error, loading, render }: ExpansionPanelContentProps): JSX.Element => {
  const dispatch: DispatchThunk = useDispatch<RootDispatch>();

  const [getBulkUsers, { loading: bulkUsersLoading, error: bulkUsersError, data: bulkUsersData }] = useLazyQuery<
  BulkUsersResponse,
  { uuids: string[] }
  >(GET_BULK_USERS, {
    context: { clientName: ApiClientNames.BFF },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network'
  });

  /**
   * The ExpansionPanel onChange method gets fired on expand and on collapse
   * As such, if you expand and collapse between multiple different teams
   * there's a state in between different expand/collapse states that causes
   * the users array to be empty. When the array is empty, it is passed to the
   * get bulk users query and results with an apollo query error. To guard against this
   * the getBulkUsers query is wrapped in a useeffect hook with a check against the
   * users array length.
   */
  React.useEffect(() => {
    if (users.length > 0) {
      getBulkUsers({ variables: { uuids: getUserUuids(users) } });
    }
  }, [users]);

  const bulkUsers = bulkUsersData?.getUsersByUuids || DEFAULT_TEAM_USERS;
  const mergedSortedUsers = mergeUserData(users, bulkUsers)
    .slice()
    .sort(sortTeamUsersByName);

  /**
   * There are dependent components that rely on user state in redux
   */
  React.useEffect(() => {
    dispatch(userActions.saveUsersToRedux(mergedSortedUsers));
  }, [mergedSortedUsers]);

  if (loading || bulkUsersLoading) {
    return (
      <StyledDiv>
        <CircularProgress data-testid='team-expansion-panel-loading' size={28} />
      </StyledDiv>
    );
  }

  if (error || bulkUsersError) {
    return <StyledDiv data-testid='team-expansion-panel-error'>{ErrorMessage}</StyledDiv>;
  }

  return <>{render(mergedSortedUsers)}</>;
};

const TeamAccordion: React.FC<TeamAccordionProps> = ({ onboardingView, teams }) => {
  const [selectedTeamId, setSelectedTeamId] = React.useState<string>('');

  const [getTeamUsers, { loading, error, data }] = useLazyQuery<TeamUserResponse, { id: string }>(GET_TEAM_USERS, {
    fetchPolicy: 'network-only'
  });

  const [
    getOnboardingUsersByTeamId,
    { loading: onboardingLoading, data: onboardingUserData, error: onboardingError }
  ] = useLazyQuery<TeamUserResponse>(GET_ONBOARDING_USERS_BY_TEAM_ID, { fetchPolicy: 'network-only' });

  const teamUsers = data?.team?.users || DEFAULT_TEAM_USERS;
  const onboardedUsers = onboardingUserData?.team?.users || DEFAULT_TEAM_USERS;

  const handlePanelChange = (id: string) => {
    const teamId = id === selectedTeamId ? '' : id;
    setSelectedTeamId(teamId);

    if (onboardingView) {
      getOnboardingUsersByTeamId({
        variables: { id, onboarding: true },
        context: { clientName: ApiClientNames.BFF }
      });
    } else {
      getTeamUsers({
        variables: { id }
      });
    }
  };

  return (
    <>
      {teams.map(team => {
        const teamText = `${team.name} \u0387 Wk`;
        const weekNumber = typeof team.currentWeek === 'number' ? team.currentWeek : team.currentTeamWeekNumber;
        const fullTeamText = `${teamText} ${weekNumber}`;
        return (
          <ExpansionPanel
            key={team.id}
            expanded={team.id === selectedTeamId}
            onChange={() => handlePanelChange(team.id)}
            TransitionProps={{ unmountOnExit: true, timeout: 0 }}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <TeamText>{fullTeamText}</TeamText>
              {!onboardingView && (
                <StyledBadge
                  badgeContent={team.userCount || 0}
                  showZero
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <></>
                </StyledBadge>
              )}
              {/* Add ATL-166 work here. https://hingehealth.atlassian.net/browse/ATL-166*/}
              {/* <OnboardedText>onboarded</OnboardedText>*/}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {selectedTeamId === team.id && (
                <ExpansionPanelContent
                  error={onboardingView ? onboardingError : error}
                  loading={onboardingView ? onboardingLoading : loading}
                  users={onboardingView ? onboardedUsers : teamUsers}
                  render={sortedUsers => {
                    if (onboardingView) {
                      return <OnboardingTeamTable users={sortedUsers} />;
                    } else {
                      return <TeamResultsTable users={sortedUsers} />;
                    }
                  }}
                />
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </>
  );
};

export default TeamAccordion;
