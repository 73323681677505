import { gql } from '@apollo/client';
import { TEAM_FRAGMENT } from '../fragments/team';

export const GET_USERS = `
  query users($itemsPerPage: Int!, $page: Int!) {
    users(itemsPerPage: $itemsPerPage, page: $page)
  }
`;

export const GET_USER_TYPE = gql`
  query UserType($id: ID!) {
    user(id: $id) {
      id
      uuid
      phoenix
    }
  }
`;

// CT-2096 use typedefs to make cherry picking fields
// off the user type more manageable
export const GET_PATHWAYS = gql`
  query UserType($id: ID!) {
    user(id: $id) {
      id
      pathways {
        id
        createdAt
        currentLevel
        currentWeek
        startsAt
        currentExerciseTherapyStream
        indication {
          id
          name
        }
      }
    }
  }
`;

export const GET_ENGAGEMENT_EVENTS = gql`
  query UserEngagementEvents($id: ID!) {
    engagementEvents(userId: $id) {
      sourceType
      data
      createdAt
    }
  }
`;

export const GET_USER_ENGAGEMENT_STREAK = gql`
  query UserEngagementStreak($id: ID!) {
    user(id: $id) {
      id
      engagementStreak
    }
  }
`;

export const GET_USER_TASKS = gql`
  query taskList($userId: Int!, $status: String) {
    taskList(status: $status, userId: $userId)
  }
`;

export const GET_USER_INFO = gql`
  query UserInfo($id: ID!) {
    user(id: $id) {
      id
      uuid
      phoenix
      firstName
      lastName
      email
      dateOfBirth
      gender
      phoneNumber
      addressOne
      city
      stateOfResidence
      postalCode
      timezone
      team {
        ...Team
      }
      client {
        id
        name
      }
      avatarUrl
      pathways {
        id
        createdAt
        currentLevel
        currentWeek
        currentExerciseTherapyStream
        indication {
          id
          name
        }
      }
      tags
      contactMethod
      vacationInfo {
        vacationStartDate
        vacationEndDate
      }
      lastActive
      engagementStreak
      userStatus {
        userId
        name
        description
        startsOn
        endsOn
      }
    }
  }
  ${TEAM_FRAGMENT}
`;

export const SEARCH_USERS = gql`
  query SearchUsers($query: String!) {
    userSearch(query: $query) {
      id
      phoenix
      firstName
      lastName
      avatarUrl
      tags
      lastActive
      timezone
      contactMethod
      uuid
      team {
        id
        currentTeamWeekNumber
      }
      pathways {
        currentWeek
      }
      userStatus {
        userId
        name
        description
        startsOn
        endsOn
      }
    }
  }
`;

export const GET_USER_WEEKLY_ENGAGEMENT = gql`
  query UserWeeklyEngagement($id: ID!, $startDate: String, $endDate: String) {
    user(id: $id) {
      id
      tags
      timezone
      weeklyEngagement(startDate: $startDate, endDate: $endDate) {
        pathwayWeek
        startDate
        pointsEarned
        weightGrams
        playlists {
          count
          weeklyGoal
        }
        exerciseTherapySessions {
          count
          weeklyGoal
        }
        pacing {
          count
          weeklyGoal
        }
        articleReads {
          count
          weeklyGoal
        }
        painReports {
          count
          weeklyGoal
        }
        physicalActivities {
          count
          weeklyGoal
        }
      }
    }
  }
`;

export const GET_USER_HEALTH_LOGS = gql`
  query UserHealthLogs($id: ID!) {
    user(id: $id) {
      id
      timezone
      healthLogs {
        uuid
        occurredAt
        note
        painReports {
          uuid
          pain
        }
      }
      pathways {
        id
        currentWeek
        indication {
          id
          name
        }
      }
    }
  }
`;

export const GET_USER_EDUCATION_CONTEXT = gql`
  query UserEduContext($id: ID!) {
    user(id: $id) {
      id
      phoenix
      timezone
      pathways {
        id
        educationContext {
          lastClosedAtArticle {
            id
            name
            slug
            contentUrl
            closedAt
          }
          nextArticle {
            id
            name
            slug
            contentUrl
          }
        }
      }
    }
  }
`;

export const GET_USER_ET_SESSION_CONTEXT = gql`
  query UserETSessionContext($id: ID!) {
    user(id: $id) {
      id
      phoenix
      timezone
      pathways {
        id
        currentWeek
        etSessionsContext {
          currentWeek {
            day
            definitions {
              level
            }
          }
          pastWeek {
            day
            definitions {
              level
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_WORKOUT_CONTEXT = gql`
  query UserWorkoutContext($id: ID!) {
    user(id: $id) {
      id
      phoenix
      timezone
      currentWeek
      pathways {
        id
        legacyWorkoutContext {
          currentWeek {
            day
            definitions {
              name
            }
          }
          pastWeek {
            day
            definitions {
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_REMINDER_CONTEXT = gql`
  query UserReminderContext($id: ID!) {
    user(id: $id) {
      id
      reminders {
        active
        days
        hour
        minute
        timezoneName
        deliveryMethod
        afterEvent
      }
    }
  }
`;

export const GET_USER_SCREENING_INFO = gql`
  query UserScreening($id: ID!) {
    user(id: $id) {
      id
      bio
      coachingQuestions
      pathways {
        id
        indication {
          id
          name
        }
        screening {
          id
          stratification
          resolvedAt
        }
      }
    }
  }
`;

export const GET_USER_STATUS = gql`
  query UserStatus($id: ID!) {
    user(id: $id) {
      id
      userStatus {
        userId
        name
        description
        startsOn
        endsOn
      }
    }
  }
`;

export const GET_USER_POSTER = gql`
  query getUserById($userId: ID!) {
    getUserById(userId: $userId) {
      id
      firstName
      lastName
      city
      occupation
      stateOfResidence
      addressOne
      avatarUrl
      email
      phoneNumber
      contactMethod
      tags
      timezone
      uuid
      dateOfBirth
      engagementStreak
      gender
      lastActive
      postalCode
      userFeatures {
        videoVisitEligibility
      }
      userStatus {
        name
        description
        startsOn
        endsOn
      }
      vacationInfo {
        vacationStartDate
        vacationEndDate
      }
      pathways {
        id
        uuid
        currentLevel
        currentWeek
        currentExerciseTherapyStream
        programIndication {
          indicationName
        }
      }
      team {
        id
        name
      }
      client {
        id
        name
      }
      coverages {
        physicalTherapist {
          role
          name
        }
      }
    }
  }
`;

export const GET_PREGNANCY_DETAILS = gql`
  query pregnancyDetailsGet($pregnancyDetailsGetInput: PregnancyDetailsGetInput!) {
    pregnancyDetailsGet(pregnancyDetailsGetInput: $pregnancyDetailsGetInput) {
      id
      maternityWeek
    }
  }
`;

export const GET_ALL_MEMBER_CONDITIONS = gql`
  query getAllMemberConditions($kind: String!, $memberId: String!) {
    getAllMemberConditions(kind: $kind, memberId: $memberId) {
        memberId
        urn
        indications {
            acuity
            bodyPart
            createdAt
            isActive
            kind
            priority
            updatedAt
            urn
        }
    }
  }
`;

export const GET_USER_POSTER_WITH_ENROLLMENT_STATUS = gql`
  query getUserById($userId: ID!) {
    getUserById(userId: $userId) {
      id
      firstName
      lastName
      city
      occupation
      stateOfResidence
      addressOne
      addressTwo
      country
      bio
      bmi
      height
      weight
      teamTimezone
      surgeryLikelihoodNextYear
      surgeryLikelihoodNext2Years
      completedWeeklyActionablesCount
      nps
      weeksInactive
      avatarUrl
      email
      phoneNumber
      contactMethod
      tags
      timezone
      uuid
      dateOfBirth
      engagementStreak
      gender
      lastActive
      lastSignInAt
      postalCode
      perisurgicalDetails {
        surgeryType
        surgeryDate
    }
      userFeatures {
        videoVisitEligibility
      }
      userStatus {
        name
        description
        startsOn
        endsOn
      }
      vacationInfo {
        vacationStartDate
        vacationEndDate
      }
      enrollmentStatus {
        status
      }
      goals {
        id
        userId
        content
        status
        completedAt
        doneBy
      }
      pathways {
        id
        uuid
        currentLevel
        currentWeek
        currentExerciseTherapyStream
        programIndication {
          programName
          indicationName
        }
        firstEtAt
      }
      team {
        id
        name
      }
      client {
        id
        name
        logo
      }
      coverages {
        physicalTherapist {
          role
          name
        }
      }
    }
  }
`;

export const GET_LATEST_SHIPMENT_BY_USER_ID = gql`
  query getUserById($userId: ID!) {
    getUserById(userId: $userId) {
      id
      latestShipment {
        estimatedDeliveryAt
        preparedAt
        id
        userId
        trackingStatus
        deliveredAt
        trackingNumber
        trackingUrl
      }
    }
  }
`;

export const GET_ET_SESSION_GROUPINGS = gql`
  query getUserById($userId: ID!) {
    getUserById(userId: $userId) {
      id
      timezone
      pathways {
        id
        currentWeek
        etSessionGroupings {
          weekNumber
          etSessions {
            startedAt
            userId
            complete
            day
            definitionLevel
            pathwayWeek
            byod
            sensorless
          }
        }
      }
    }
  }
`;

export const GET_USER_ACHIEVEMENTS = gql`
  query userAchievements($userId: Int!) {
    userAchievements(userId: $userId) {
      id
      key
      achievedDate
    }
  }
`;

export const GET_USER_COVERAGES = gql`
  query getUserCoverages($userUuid: String!) {
    getUserCoverages(userUuid: $userUuid)
  }
`;

export const GET_USER_STATE_OF_RESIDENCE = gql`
  query getUserStateOfResidence($userId: ID!) {
    getUserById(userId: $userId) {
      stateOfResidence
    }
  }
`;

export const GET_STATE_REGULATIONS = gql`
  query getStateRegulations($stateOfResidence: String!) {
    stateRegulations(stateOfResidence: $stateOfResidence) {
      key
      value
    }
  }
`;

export const GET_USER_UUID = gql`
  query GetUserUuid($userId: ID!) {
    getUserById(userId: $userId) {
      uuid
    }
  }
`;

export const GET_CARE_PLAN_CONFIG = gql`
  query GetCarePlanConfig {
    carePlanConfig {
      icd10 {
        code
        description
      }
    }
  }
`;

export const GET_USER_CARE_PLANS = gql`
  query GetUserCarePlans($uuid: ID!, $itemsPerPage: Int, $page: Int) {
    user(uuid: $uuid) {
      carePlans(itemsPerPage: $itemsPerPage, page: $page) {
        id
        userId
        title
        status
        items {
          id
          key
          currentValue
        }
        icd10 {
          code
          description
        }
        expectedDischargeAt
        dischargeReason
        openedAt
        closedAt
      }
    }
  }
`;

export const GET_USER_DATA_FOR_CARE_PLANS = gql`
  query GetUserDataForCarePlans($userUuid: ID!) {
    getUserById(userUuid: $userUuid) {
      uuid
      stateOfResidence
      pathways {
        id
        uuid
        programIndication {
          programName
          indicationName
          identifier
        }
      }
    }
  }
`;

export const GET_USER_BASIC_INFO = gql`
  query GetUserBasicInfo($userUuid: ID!) {
    getUserById(userUuid: $userUuid) {
      id
      firstName
      lastName
      avatarUrl
    }
  }
`;

export const GET_USER_PATHWAY_STAGE = gql`
  query GetUserPathwayStage($userId: ID!) {
    getUserById(userId: $userId) {
      id
      pathways {
        uuid
        stage
      }
    }
  }
`;

export const GET_BULK_USERS = gql`
  query getUsersByUuids($uuids: [ID!]!) {
    getUsersByUuids(uuids: $uuids) {
      id
      enrollmentStatus {
        status
      }
    }
  }
`;

export const GET_USER_PERISURGICAL_DETAILS = gql`
  query getUserPerisurgicalDetails($userId: ID!) {
    getUserById(userId: $userId) {
      id
      perisurgicalDetails {
        surgeryType
        surgeryDate
      }
      pathways {
        programIndication {
          programName
        }
      }
    }
  }
`;

export const GET_USER_ENSO_USAGE = gql`
  query getUserEnsoUsage($userUuid: ID!) {
    ensoTwoWeekUsage(userUuid: $userUuid) {
      sessionsThisWeek
      sessionsLastWeek
      lastSessionDate
    }
  }
`;
