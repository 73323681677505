import { connect } from 'react-redux';

import { RootState } from '../../redux/reducers';
import GoalSegmentEditor from '../../components/user/GoalSegmentEditor';
import { actionCreators as goalActions } from '../../redux/actions/goals';
import { actionCreators as goalEditorActions } from '../../redux/actions/goals-editor';
import { selectAddGoal } from '../../redux/selectors/goals-editor';

export interface GoalAddContainerProps {
  userId: number;
  onClose?(): void;
}

const mapStateToProps = (state: RootState, props: GoalAddContainerProps) => ({
  goal: selectAddGoal(state)
});

const mapDispatchToProps = {
  saveGoal: goalActions.post,
  editGoalContent: goalEditorActions.editGoalContent,
  editGoalDueDate: goalEditorActions.editGoalDueDate
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalSegmentEditor);
