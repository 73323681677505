import * as React from 'react';

import { Box, Table, TableBody } from 'grommet';
import { CoachingQuestions } from '../../graphql/models';
import {
  ScreenerHeader,
  ScreenerRow,
  ScreenerCell,
  ScreenerField
} from '../../containers/communications/ScreenerManager';
import { isNullOrUndefined } from '../../utils/helpers';

interface ScreenerBackgroundProps extends CoachingQuestions {
  bio?: string | null;
}

export const REMOVED_BARRIER_OPTION = 'other';

export const filterBarrierOption = (barrier: string) =>
  barrier !== REMOVED_BARRIER_OPTION && barrier.length;

export const formatBarrier = (barrier: string) =>
  barrier.match('-')
    ? barrier.charAt(0).toUpperCase() +
      barrier
        .slice(1)
        .split('-')
        .join(' ')
    : barrier;

export const ShowBarriers: React.FC<{ barriers: string | string[] }> = ({
  barriers
}) => {
  const formattedBarriers = Array.isArray(barriers)
    ? barriers
      .filter(filterBarrierOption)
      .map(formatBarrier)
      .join(', ')
    : barriers;
  return <ScreenerCell>{formattedBarriers}</ScreenerCell>;
};

const ScreenerBackground: React.FC<ScreenerBackgroundProps> = ({
  bio,
  key_reasons,
  imagine_free,
  foresee_barriers
}) => (
  <Box style={{ padding: '6px 20px' }}>
    <ScreenerHeader fill>
      <strong>Background</strong>
    </ScreenerHeader>
    <Table>
      <TableBody>
        <ScreenerRow>
          <ScreenerField size="1/4">Bio</ScreenerField>
          <ScreenerCell>{bio || 'N/A'}</ScreenerCell>
        </ScreenerRow>
        <ScreenerRow>
          <ScreenerField size="1/4">Key reasons</ScreenerField>
          <ScreenerCell>{key_reasons || 'N/A'}</ScreenerCell>
        </ScreenerRow>
        <ScreenerRow>
          <ScreenerField size="1/4">Imagine pain free</ScreenerField>
          <ScreenerCell>{imagine_free || 'N/A'}</ScreenerCell>
        </ScreenerRow>
        <ScreenerRow style={{ border: 'none' }}>
          <ScreenerField size="1/4">Forsee any barriers</ScreenerField>
          {isNullOrUndefined(foresee_barriers) ? (
            <ScreenerCell>{'N/A'}</ScreenerCell>
          ) : (
            <ShowBarriers barriers={foresee_barriers} />
          )}
        </ScreenerRow>
      </TableBody>
    </Table>
  </Box>
);

export default ScreenerBackground;
